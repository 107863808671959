import { memo } from "react";

import { Controller } from "react-hook-form";

import StatusToggle from "../../../../../../../../common/components/item/StatusToggle";
import { useAuthenticatedUser } from "../../../../../../../../common/hooks/useAuthenticatedUser";
import { IAlertFormBase } from "../AlertForm";
import AlertToggleSection from "./AlertToggleSection";

interface EmailAlertToggleSectionProps extends IAlertFormBase {
  everySwithcIsOff: boolean;
}

const EmailAlertToggleSectionComponent = ({
  formValues,
  control,
  everySwithcIsOff,
}: EmailAlertToggleSectionProps) => {
  const { user } = useAuthenticatedUser();

  return (
    <AlertToggleSection
      toggleChecked={formValues.isEmailToggleEnabled}
      title={`Email (${user?.attributes?.email})`}
      hasError={everySwithcIsOff}
      ToggleComponent={
        <Controller
          name="isEmailToggleEnabled"
          control={control}
          render={({ field }) => (
            <StatusToggle
              {...field}
              checked={field.value}
              handleChange={field.onChange}
            />
          )}
        />
      }
    />
  );
};

const EmailAlertToggleSection = memo(EmailAlertToggleSectionComponent);

export default EmailAlertToggleSection;
