import { LinearProgress, TableContainer, useTheme } from "@mui/material";
import { DataGrid, GridRowHeightReturnValue } from "@mui/x-data-grid";

import MediaModal from "../../../../../common/components/media/MediaModal";
import Thumbnail from "../../../../../common/components/media/Thumbnail";
import TableNoDataOverlay from "../../../../../common/components/table/TableNoDataOverlay";
import { dataGridServerStyles } from "../../../../../common/providers/theme/design-tokens/DataGrid/dataGridServer.styles";
import { DEFAULT_COLUMN_HEADER_HEIGHT } from "../../../../../common/variables/common";
import { TablePaperContainer } from "../../../../../leak-finder/components/VideosTable";
import { useRowClickTLM } from "../../../hooks/useRowClickTLM";
import {
  DEFAULT_EVENTS_LIMIT,
  DEFAULT_EVENTS_PAGE_SIZE_OPTIONS,
} from "../common/events.constants";
import { useGetTlmTableColumns } from "./hooks/useGetTlmTableColumns";
import { useGetTlmTableRows } from "./hooks/useGetTlmTableRows";

const TlmEventsServerTableContainer = (): JSX.Element => {
  const theme = useTheme();

  const { columns } = useGetTlmTableColumns();

  const {
    rows,
    loading,
    paginationModel,
    handlePaginationModelChange,
    hasNextPage,
    isActivePageChange,
  } = useGetTlmTableRows();

  const {
    open,
    tanksData,
    mediaOutput,
    timestamp,
    handleRowClick,
    closeDialog,
  } = useRowClickTLM();

  return (
    <TableContainer component={TablePaperContainer}>
      {open && (
        <MediaModal
          mediaOutput={mediaOutput}
          open={open}
          onClose={closeDialog}
          maxWidth="md"
        >
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ width: 480 }}>
              <div>{timestamp}</div>
              <Thumbnail controls mediaOutput={mediaOutput} />
            </div>
          </div>
          {tanksData.map((tankDatum: any): any => {
            return (
              <div
                key={tankDatum.tank_id}
                style={{
                  display: "flex",
                  flexDirection: "row", // Display components in a row
                  alignItems: "center",
                  justifyContent: "flex-start", // Adjust alignment as needed
                  width: "100%", // Set a percentage width for the container
                  padding: "10px",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  boxShadow: "0px 0px 5px #ccc",
                  backgroundColor: theme.palette.background.default,
                  marginBottom: "10px", // Add spacing between rows if needed
                }}
              >
                <div
                  style={{
                    marginRight: "20px",
                    color: theme.palette.text.primary,
                  }}
                >{`Tank Id: ${tankDatum.TankID}`}</div>
                <div
                  style={{ marginRight: "20px" }}
                >{`Tank Number: ${tankDatum.TankNumber}`}</div>
                <div style={{ marginRight: "20px" }}>{`Tank Level: ${Math.round(
                  tankDatum.TankLevel
                )}`}</div>
                <div>{`Level Percent: ${tankDatum.LevelPercent}`}</div>
              </div>
            );
          })}
        </MediaModal>
      )}
      <DataGrid
        sx={{
          ...dataGridServerStyles.sx,
          "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
            py: "8px",
          },
          "& .MuiButtonBase-root.MuiIconButton-root:last-child": {
            pointerEvents: isActivePageChange ? "auto" : "none",
            cursor: isActivePageChange ? "pointer" : "none",
            backgroundColor: "transparent",
            color: theme =>
              isActivePageChange ? "inherit" : theme.palette.action.disabled,
          },
        }}
        columnHeaderHeight={DEFAULT_COLUMN_HEADER_HEIGHT}
        rows={rows}
        columns={columns}
        slots={{
          loadingOverlay: () => <LinearProgress />,
          noRowsOverlay: TableNoDataOverlay,
        }}
        rowCount={-1}
        onRowClick={handleRowClick}
        autoHeight
        getRowHeight={(): GridRowHeightReturnValue => 96}
        rowHeight={96}
        disableRowSelectionOnClick
        hideFooterSelectedRowCount
        getRowId={(row: { rowId: string }): string => row.rowId}
        loading={loading}
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={handlePaginationModelChange}
        paginationMeta={{ hasNextPage }}
        pageSizeOptions={DEFAULT_EVENTS_PAGE_SIZE_OPTIONS}
        sortingOrder={["desc", "asc"]}
        initialState={{
          sorting: {
            sortModel: [
              {
                field: "timestamp",
                sort: "desc",
              },
            ],
          },
          pagination: {
            paginationModel: {
              pageSize: DEFAULT_EVENTS_LIMIT,
              page: 0,
            },
            rowCount: -1,
          },
        }}
      />
    </TableContainer>
  );
};

export default TlmEventsServerTableContainer;
