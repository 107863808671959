import { ApolloError, useMutation } from "@apollo/client";

import type {
  UpdateLocationInput,
  UpdateLocationMutation,
  UpdateLocationMutationVariables,
} from "../../../../../API";
import { UPDATE_LOCATION } from "../../../../../common/operations/mutations";
import { errorNotification } from "../../../../../common/variables/notification";

export const useUpdateLocation = () => {
  const [putLocation, { data, loading }] = useMutation<
    UpdateLocationMutation,
    UpdateLocationMutationVariables
  >(UPDATE_LOCATION);

  const updateLocation = async (input: UpdateLocationInput) => {
    return await putLocation({
      variables: {
        input,
      },
      onError: (error: ApolloError): void => {
        console.error(error);

        errorNotification(error.message);
      },
    });
  };

  return { updateLocation, data, loading };
};
