import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import AlertPageBreadcrumb from "./components/breadcrumb/AlertPageBreadcrumb";
import CreateAlertContainer from "./components/form/CreateAlertContainer";

const CreateAlertPage = () => {
  return (
    <div>
      <Box sx={{ marginBottom: "1em" }}>
        <AlertPageBreadcrumb />
      </Box>
      <Typography variant="h3" mb="0.5em">
        Add new alert
      </Typography>
      <CreateAlertContainer />
    </div>
  );
};

export default CreateAlertPage;
