import { Button } from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CreateCategoryDialog from "../plate-category/CreateCategoryDialog";
import { useDialog } from "../../../../../../common/components/dialog/useDialog";
import PaperWrapperCentered from "../../../../../../common/components/item/PaperWrapperCentered";
import TypographyHeader5 from "../../../../../../common/components/item/TypographyHeader5";
import TypographyBody1Regular from "../../../../../../common/components/item/TypographyBody1Regular";

const EmptyPlatesCard = () => {
  const {
    open: openCreateCategoryDialog,
    handleDialogOpen,
    handleDialogClose,
  } = useDialog();

  return (
    <PaperWrapperCentered>
      <TypographyHeader5>There is no plates numbers added</TypographyHeader5>
      <TypographyBody1Regular>
        Add category and plate numbers to manage them
      </TypographyBody1Regular>
      <Button
        sx={{
          marginTop: "24px",
        }}
        variant="contained"
        onClick={handleDialogOpen}
        startIcon={<AddOutlinedIcon />}
      >
        New plate category
      </Button>

      <CreateCategoryDialog
        isOpened={openCreateCategoryDialog}
        closeDialog={handleDialogClose}
      />
    </PaperWrapperCentered>
  );
};

export default EmptyPlatesCard;
