import { useNavigate } from "react-router-dom";

import { Box, Button, Typography } from "@mui/material";

import { AddNodeInput, NodeLevel } from "../../../../API";
import {
  errorNotification,
  successNotification,
} from "../../../../common/variables/notification";
import NodeForm from "../../components/NodeForm";
import { useCreateNode } from "../../hooks/useCreateNode";
import {
  createNodeActiveStepIndexVariable,
  createNodeInitialActiveStepIndex,
  nodeScriptVariable,
  useCreateNodeActiveStepIndex,
} from "../../variables/createNode.variable";
import SimplePaperWrapper from "../../../../common/components/item/SimplePaperWrapper";
import { useForm } from "react-hook-form";
import { AutocompleteOptionType } from "../../../../common/models/autocomplete";
import { useSelectedLocationDropdown } from "../../../../common/variables/selectedLocationDropdown";
import { useEffect } from "react";
import uuid from "react-uuid";
import { useCustomerExternalIdGuard } from "../../../../common/hooks/useCustomerExternalIdGuard";

type NodeFormStepProps = {
  onBoardScript?: boolean;
  closeDialog?: () => void;
};

export type FormValues = {
  nodeId: string;
  referenceId: string;
  location: AutocompleteOptionType;
  nodeIP: string;
};

const CreateNodeFormStep = ({
  onBoardScript,
  closeDialog,
}: NodeFormStepProps): JSX.Element => {
  const navigate = useNavigate();

  const activeStep = useCreateNodeActiveStepIndex();
  const selectedLocation = useSelectedLocationDropdown();
  const customerExternalId = useCustomerExternalIdGuard();

  const { createNode } = useCreateNode();

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<FormValues>({ defaultValues: { nodeId: uuid().split("-")[0] } });

  const handleResetActiveStep = (): void => {
    reset();

    createNodeActiveStepIndexVariable(createNodeInitialActiveStepIndex);

    if (onBoardScript && closeDialog) {
      closeDialog();

      return;
    }

    navigate("/nodes");
  };

  useEffect(() => {
    setValue("location", selectedLocation);
  }, [selectedLocation]);

  const saveNode = (formData: FormValues): void => {
    if (!formData.location?.value) {
      errorNotification("Location id is not added correctly");

      return;
    }

    const input: AddNodeInput = {
      customerExternalId,
      locationId: formData.location?.value,
      nodeName: formData.nodeId,
      referenceId: formData.referenceId,
      nodeIP: formData.nodeIP,
      level: NodeLevel.NODE,
      tags: [], // todo: ask user for tags and add to input
    };

    createNode(input)
      .then((response): void => {
        if (response?.data) {
          successNotification("Device node is added");

          nodeScriptVariable(response.data.addNode?.onboardCommand ?? "");

          createNodeActiveStepIndexVariable(activeStep + 1);
        }

        if (response.errors) {
          errorNotification();
        }
      })
      .catch((error): void => {
        errorNotification();

        console.error(error);
      });
  };

  if (onBoardScript) {
    return (
      <form onSubmit={handleSubmit(saveNode)}>
        <Typography
          sx={{
            textAlign: "center",
          }}
          variant="h3"
        >
          Add new node
        </Typography>

        <Box
          sx={{
            borderRadius: "0.75em",
            padding: "1em",
            marginBottom: "1em",
          }}
        >
          <NodeForm
            control={control}
            errors={errors}
            fullWidth={onBoardScript}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "1em",
          }}
        >
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleResetActiveStep}
          >
            Cancel
          </Button>
          <Button variant="contained" type="submit">
            Add new node
          </Button>
        </Box>
      </form>
    );
  }

  return (
    <form
      onSubmit={handleSubmit((formData: FormValues) => {
        saveNode(formData);
      })}
    >
      <Typography
        sx={{
          marginBottom: "1em",
        }}
        variant="h3"
      >
        Add new node
      </Typography>

      <SimplePaperWrapper
        sx={{
          borderRadius: "0.75em",
          padding: "2em",
          marginBottom: "1.5em",
        }}
      >
        <NodeForm control={control} errors={errors} />
      </SimplePaperWrapper>

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "1em",
        }}
      >
        <Button
          variant="outlined"
          sx={{
            textTransform: "none",
          }}
          color="secondary"
          onClick={handleResetActiveStep}
        >
          Cancel
        </Button>
        <Button
          sx={{
            textTransform: "none",
          }}
          variant="contained"
          type="submit"
        >
          Add new node
        </Button>
      </Box>
    </form>
  );
};

export default CreateNodeFormStep;
