import { useState } from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";

import StatusCircle from "../../../../common/components/badge/StatusCircle";
import { IDeploymentHealthTableRow } from "../../hooks/useDeploymentHealthTableRows";
import useLocationFromCache from "../../../../common/hooks/useLocationFromCache";

interface ITableHeadRowProps {
  row: IDeploymentHealthTableRow;
}

const DeploymentHealthTableBodyRow = ({
  row,
}: ITableHeadRowProps): JSX.Element => {
  const theme = useTheme();

  const { getCachedLocation } = useLocationFromCache();
  const locationCache = getCachedLocation(row.locationId);

  const [open, setOpen] = useState(!row.locationStatus);

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton onClick={(): void => setOpen(!open)} size="small">
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <StatusCircle
            color={
              row.locationStatus
                ? theme.palette.primary.main
                : theme.palette.error.main
            }
          />
        </TableCell>
        <TableCell>{locationCache?.name ?? row.locationName}</TableCell>
        <TableCell>
          <StatusCircle
            color={
              row.nodeStatus
                ? theme.palette.primary.main
                : theme.palette.error.main
            }
          />
        </TableCell>
        <TableCell>
          <StatusCircle
            color={
              row.deviceStatus
                ? theme.palette.primary.main
                : theme.palette.error.main
            }
          />
        </TableCell>
        <TableCell>
          <StatusCircle
            color={
              row.serviceStatus
                ? theme.palette.primary.main
                : theme.palette.error.main
            }
          />
        </TableCell>
      </TableRow>

      <TableRow
        sx={{
          visibility: open ? "visible" : "collapse",
        }}
      >
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell
          sx={{
            verticalAlign: "top",
          }}
        >
          {row.nodes.map((node): JSX.Element => {
            return (
              <Box
                key={node.nodeId}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <StatusCircle
                  color={
                    node.status
                      ? theme.palette.primary.main
                      : theme.palette.error.main
                  }
                />
                <Typography variant="body2">{node.nodeName}</Typography>
                {/* <Typography variant="body2">{node.uptime}%</Typography> */}
              </Box>
            );
          })}
        </TableCell>
        <TableCell
          sx={{
            verticalAlign: "top",
          }}
        >
          {row.devices.map((device): JSX.Element => {
            return (
              <Box
                key={device.deviceId}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <StatusCircle
                  color={
                    device.status
                      ? theme.palette.primary.main
                      : theme.palette.error.main
                  }
                />
                <Typography variant="body2">{device.deviceName}</Typography>
                {/* <Typography variant="body2">{device.uptime}%</Typography> */}
              </Box>
            );
          })}
        </TableCell>
        <TableCell
          sx={{
            verticalAlign: "top",
          }}
        >
          {row.services.map((service): JSX.Element => {
            return (
              <Box
                key={service.serviceId}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <StatusCircle
                  color={
                    service.status
                      ? theme.palette.primary.main
                      : theme.palette.error.main
                  }
                />
                <Typography variant="body2">{service.serviceType}</Typography>
                {/* <Typography variant="body2">{service.uptime}%</Typography> */}
              </Box>
            );
          })}
        </TableCell>
      </TableRow>
    </>
  );
};

export default DeploymentHealthTableBodyRow;
