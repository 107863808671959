import { ApolloError, useMutation } from "@apollo/client";

import {
  UpdateLicensePlate,
  UpdateLicensePlateWithNewCategoryMutation,
  UpdateLicensePlateWithNewCategoryMutationVariables,
} from "../../../../../API";
import { UPDATE_LICENSE_PLATE_WITH_NEW_CATEGORY } from "../../../../../common/operations/mutations";
import { useCustomerIdGuard } from "../../../../../common/hooks/useCustomerIdGuard";

type UpdateLicensePlateExtented = UpdateLicensePlate & {
  newCategoryId: string;
};

export const useUpdateLicensePlate = () => {
  const customerId = useCustomerIdGuard();

  const [
    updateLicensePlateWithNewCategoryMutation,
    { data: licensePlateData, loading: licensePlateLoading },
  ] = useMutation<
    UpdateLicensePlateWithNewCategoryMutation,
    UpdateLicensePlateWithNewCategoryMutationVariables
  >(UPDATE_LICENSE_PLATE_WITH_NEW_CATEGORY);

  const updateLicensePlate = async (input: UpdateLicensePlateExtented) => {
    input.customerId = customerId;

    return await updateLicensePlateWithNewCategoryMutation({
      variables: {
        input,
      },
      onError: (error: ApolloError): void => {
        console.error(error);
      },
      update: (cache): void => {
        // cache.modify({
        //   fields: {
        //     getLicensePlatesByCustomer(existing) {
        //       const newLicensePlateRef = cache.writeFragment({
        //         data: response.data?.updateLicensePlateWithNewCategory,
        //         fragment: gql`
        //           fragment NewLicensePlate on LicensePlate {
        //             categoryId
        //             company
        //             driverName
        //             id
        //             licensePlate
        //           }
        //         `,
        //       });

        //       if (!existing) {
        //         return response?.data;
        //       }

        //       return {
        //         items: [...existing.items, newLicensePlateRef],
        //       };
        //     },
        //   },
        // });
        const normalizedId = cache.identify({
          id: input.licensePlateId,
          __typename: "LicensePlate",
        });

        cache.evict({
          id: normalizedId,
        });
      },
    });
  };

  return {
    updateLicensePlate,
    data: licensePlateData,
    loading: licensePlateLoading,
  };
};
