import { useEffect, useState } from "react";

import { Breakpoint } from "@mui/material/styles";

import StyledDialog from "../dialog/StyledDialog";
import Thumbnail from "./Thumbnail";

interface MediaModalInterface {
  mediaOutput: string;
  open: boolean;
  title?: string;
  maxWidth?: Breakpoint | false;
  fullScreen?: boolean;
  children?: React.ReactNode;

  onClose: () => void;
}

const MediaModal = ({
  mediaOutput,
  open,
  onClose,
  maxWidth = "md",
  fullScreen = true,
  title = "View Media",
  children,
}: MediaModalInterface): JSX.Element => {
  const [parsedMediaOutput, setParsedMediaOutput] = useState("");

  useEffect((): void => {
    if (mediaOutput) {
      let mediaUrl = mediaOutput;

      try {
        mediaUrl = JSON.parse(mediaUrl);
      } catch (error) {
        console.log(`mediaOutput is already parsed`);
      }

      setParsedMediaOutput(mediaUrl);
    }
  }, [mediaOutput]);

  const content = children ?? (
    <Thumbnail mediaOutput={parsedMediaOutput} controls />
  );

  return (
    <StyledDialog
      maxWidth={!fullScreen ? maxWidth : false}
      open={open}
      title={title}
      fullScreen={fullScreen}
      onClose={onClose}
      showSubmitButton={false}
    >
      {content}
    </StyledDialog>
  );
};

export default MediaModal;
