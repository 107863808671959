import { Button } from "@mui/material";
import { useConfirm } from "material-ui-confirm";

import {
  errorNotification,
  successNotification,
} from "../../../common/variables/notification";
import { usePublishNode } from "../../devices/hooks/usePublishNode";
import confirmDialogStyleOptions from "../../../common/helpers/confirmDialogParams";
import { useDeleteService } from "../hooks/useDeleteService";
import { useCustomerIdGuard } from "../../../common/hooks/useCustomerIdGuard";

const DeleteServiceDialogContainer = ({
  serviceId,
  deviceId,
  nodeId,
  serviceType,
}: {
  serviceId: string;
  nodeId: string;
  deviceId: string;
  serviceType: string;
}): JSX.Element => {
  const confirm = useConfirm();

  const customerId = useCustomerIdGuard();
  const { deleteService, loading } = useDeleteService();
  const { publishNode } = usePublishNode();

  const handleDeleteClick = (): void => {
    confirm({
      title: "Are you sure you want to delete this model instance?",
      confirmationText: "Delete",
      cancellationText: "Cancel",
      ...confirmDialogStyleOptions,
      confirmationButtonProps: {
        ...confirmDialogStyleOptions.confirmationButtonProps,
        disabled: loading,
      },
    }).then(removeServiceFromDDB);
  };

  const removeServiceFromDDB = async (): Promise<void> => {
    await deleteService({
      nodeId,
      serviceId,
      // temporary fix before we make the change of adding C# to deviceId upon device creation
      deviceId: customerId + "#" + deviceId,
    });

    publishNode({
      message: JSON.stringify({
        TARGET: "MODEL",
        ACTION: "STOP",
        model_name: serviceType,
        instance_name: nodeId.split("DE#").at(-1),
        DATA: {
          serviceType: serviceType,
        },
      }),
      nodeId,
    })
      .then((response): void => {
        if (response.data) {
          successNotification();
        }
      })
      .catch((error): void => {
        errorNotification("Something went wrong when publishing node");

        console.error(error);
      });
  };

  return (
    <Button color="error" onClick={handleDeleteClick}>
      Delete
    </Button>
  );
};

export default DeleteServiceDialogContainer;
