import {
  Button,
  Divider,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { useConfirm } from "material-ui-confirm";
import { useEffect, useState } from "react";
import { UpdateCustomerInput } from "../../../../API";
import EditIconButton from "../../../../common/components/icons/EditIconButton";
import StatusToggle from "../../../../common/components/item/StatusToggle";
import confirmDialogStyleOptions from "../../../../common/helpers/confirmDialogParams";
import { convertTo12Hour } from "../../../../common/helpers/convertTo12Hour";
import { convertToLocalTime } from "../../../../common/helpers/convertToLocalTime";
import { convertToUTC } from "../../../../common/helpers/convertToUTC";
import { useCustomerIdGuard } from "../../../../common/hooks/useCustomerIdGuard";
import { useUpdateCustomer } from "../../../../common/hooks/useUpdateCustomer";
import {
  errorNotification,
  successNotification,
} from "../../../../common/variables/notification";
import { useSelectedCustomerData } from "../../../../common/variables/selectedCustomerData";
import { INVALID_DATE_ERROR_MESSAGE } from "../../../human-validator/constants";
import { useCustomerExternalIdGuard } from "../../../../common/hooks/useCustomerExternalIdGuard";

const AlertManagementGateGuardTab = (): JSX.Element => {
  const customer = useSelectedCustomerData();

  const selectedCustomerId = useCustomerIdGuard();
  const selectedCustomerExternalId = useCustomerExternalIdGuard();

  const { updateCustomer } = useUpdateCustomer();

  const confirm = useConfirm();

  const theme = useTheme();

  const [fromError, setFromError] = useState("");
  const [toError, setToError] = useState("");
  const [customerData, setCustomerData] = useState(customer);
  const [alertSwitch, setAlertSwitch] = useState(false);
  const [editIconSwitch, setEditIconSwitch] = useState(false);
  const [fromValue, setFromValue] = useState<string>("00:00");
  const [toValue, setToValue] = useState<string>("00:00");

  useEffect(() => {
    if (customer?.aggAlertingData) {
      setCustomerData(customer);

      const aggAlertingData = JSON.parse(customer?.aggAlertingData);
      const isTimedAlertingEnabled =
        aggAlertingData.isTimedAlertingEnabled ?? false;
      const startTime = aggAlertingData.startTime ?? "";
      const endTime = aggAlertingData.endTime ?? "";
      const fromValueLocal = convertToLocalTime(startTime);
      const toValueLocal = convertToLocalTime(endTime);

      setAlertSwitch(isTimedAlertingEnabled);

      setFromValue(fromValueLocal);

      setToValue(toValueLocal);
    }
  }, [customer]);

  const handleAlertSwitchChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newAlertSwitch = event.target.checked;

    setAlertSwitch(newAlertSwitch);

    const fromValueUTC = convertToUTC(fromValue);
    const toValueUTC = convertToUTC(toValue);

    if (customerData.aggAlertingData) {
      updateCustomer({
        id: selectedCustomerId,
        customerExternalId: selectedCustomerExternalId,
        aggAlertingData: JSON.stringify({
          endTime: toValueUTC,
          isTimedAlertingEnabled: newAlertSwitch,
          startTime: fromValueUTC,
        }),
      } as UpdateCustomerInput)
        .then((response): void => {
          if (!response.errors) {
            successNotification(
              newAlertSwitch ? "Alerts are paused" : "Alerts are unpaused"
            );

            setEditIconSwitch(false);
          }
        })
        .catch((error: Error): void => {
          errorNotification(
            newAlertSwitch
              ? "Something went wrong, alerts aren't paused"
              : "Something went wrong, alerts aren't unpaused"
          );
        });
    }
  };

  const handleFromTimeChange = (newValue: dayjs.Dayjs | null) => {
    setFromValue(newValue ? newValue.format("HH:mm") : "");
  };

  const handleToTimeChange = (newValue: dayjs.Dayjs | null) => {
    setToValue(newValue ? newValue.format("HH:mm") : "");
  };

  const savePauseAlerts = (): void => {
    const fromValueUTC = convertToUTC(fromValue);
    const toValueUTC = convertToUTC(toValue);

    if (
      !fromValue ||
      !toValue ||
      fromValue === INVALID_DATE_ERROR_MESSAGE ||
      toValue === INVALID_DATE_ERROR_MESSAGE
    ) {
      errorNotification("Please provide valid start and end times.");

      return;
    }

    updateCustomer({
      id: selectedCustomerId,
      aggAlertingData: JSON.stringify({
        endTime: toValueUTC,
        isTimedAlertingEnabled: alertSwitch,
        startTime: fromValueUTC,
      }),
    } as UpdateCustomerInput)
      .then((response): void => {
        if (!response.errors) {
          successNotification(
            `Alerts are paused for ${convertTo12Hour(
              fromValue
            )} - ${convertTo12Hour(toValue)} `
          );

          setEditIconSwitch(false);
        }
      })
      .catch((error: Error): void => {
        errorNotification(error.message);
      });
  };

  const confirmCancel = (): void => {
    confirm({
      title: "Are you sure you want to cancel editing?",
      description: "Go back to save changes",
      confirmationText: "Cancel",
      cancellationText: "Back",
      ...confirmDialogStyleOptions,
      confirmationButtonProps: {
        ...confirmDialogStyleOptions.confirmationButtonProps,
        color: "primary",
      },
    }).then((): void => {
      const aggAlertingData = JSON.parse(customer?.aggAlertingData);

      const startTime = aggAlertingData.startTime ?? "";
      const endTime = aggAlertingData.endTime ?? "";
      const fromValueLocal = convertToLocalTime(startTime);
      const toValueLocal = convertToLocalTime(endTime);

      setFromValue(fromValueLocal);

      setToValue(toValueLocal);

      setEditIconSwitch(false);
    });
  };

  const handleCancel = (): void => {
    confirmCancel();
  };

  return (
    <Paper
      elevation={0}
      variant="outlined"
      style={{
        padding: 20,
        borderRadius: "12px",
        border: `1px solid ${theme.palette.otherOutlineBorder.main}`,
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Typography variant="body1Bold">
          Set time period when alerts shouldn't be received
        </Typography>

        <StatusToggle
          checked={alertSwitch}
          handleChange={handleAlertSwitchChange}
          label={""}
        />
      </Stack>

      {!customerData.aggAlertingData && alertSwitch && (
        <>
          <Divider sx={{ my: 1 }} />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={3}
              sx={{ marginTop: 5, marginBottom: 2 }}
            >
              <TimePicker
                label="From"
                value={dayjs(`2024-08-17T${fromValue}`)}
                onChange={handleFromTimeChange}
                onError={newError => {
                  if (newError) {
                    setFromError("Please provide a valid time");
                  } else {
                    setFromError("");
                  }
                }}
                slotProps={{
                  textField: {
                    helperText: fromError,
                  },
                }}
              />
              <TimePicker
                label="To"
                value={dayjs(`2024-08-17T${toValue}`)}
                onChange={handleToTimeChange}
                onError={newError => {
                  if (newError) {
                    setToError("Please provide a valid time");
                  } else {
                    setToError("");
                  }
                }}
                slotProps={{
                  textField: {
                    helperText: toError,
                  },
                }}
              />
              <Button
                sx={{
                  marginLeft: "auto",
                }}
                variant="contained"
                size="medium"
                onClick={savePauseAlerts}
              >
                Save time period
              </Button>
            </Stack>
          </LocalizationProvider>
        </>
      )}

      {customerData.aggAlertingData && alertSwitch && (
        <>
          <Divider sx={{ my: 1 }} />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={3}
                sx={{ marginTop: 5, marginBottom: 2 }}
              >
                <TimePicker
                  label="From"
                  disabled={!editIconSwitch}
                  value={dayjs(`2024-08-17T${fromValue}`)}
                  onChange={handleFromTimeChange}
                  onError={newError => {
                    if (newError) {
                      setFromError("Please provide a valid time");
                    } else {
                      setFromError("");
                    }
                  }}
                  slotProps={{
                    textField: {
                      helperText: fromError,
                    },
                  }}
                />
                <TimePicker
                  label="To"
                  disabled={!editIconSwitch}
                  value={dayjs(`2024-08-17T${toValue}`)}
                  onChange={handleToTimeChange}
                  onError={newError => {
                    if (newError) {
                      setToError("Please provide a valid time");
                    } else {
                      setToError("");
                    }
                  }}
                  slotProps={{
                    textField: {
                      helperText: toError,
                    },
                  }}
                />

                <EditIconButton
                  onClick={() => {
                    setEditIconSwitch(!editIconSwitch);
                  }}
                  disabled={editIconSwitch}
                ></EditIconButton>
              </Stack>
              {editIconSwitch && (
                <Stack direction="row" justifyContent="flex-start" spacing={2}>
                  <Button
                    sx={{
                      marginLeft: "auto",
                    }}
                    color="secondary"
                    variant="outlined"
                    size="small"
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    sx={{
                      marginLeft: "auto",
                    }}
                    variant="contained"
                    size="small"
                    onClick={savePauseAlerts}
                  >
                    Save Changes
                  </Button>
                </Stack>
              )}
            </Stack>
          </LocalizationProvider>
        </>
      )}
    </Paper>
  );
};

export default AlertManagementGateGuardTab;
