import { useEffect } from "react";

import { useLazyQuery } from "@apollo/client";

import {
  GetNodesByCustomerQuery,
  GetNodesByCustomerQueryVariables,
} from "../../../../API";
import client from "../../../../configs/apolloClient";
import { useCustomerIdGuard } from "../../../hooks/useCustomerIdGuard";
import { GET_NODES_BY_CUSTOMER } from "../../../operations/queries";
import { errorNotification } from "../../../variables/notification";

export const useGetNodesByCustomer = () => {
  const selectedCustomerId = useCustomerIdGuard();

  useEffect((): void => {
    if (selectedCustomerId) {
      fetchNodes(selectedCustomerId);
    }
  }, [selectedCustomerId]);

  const handleRequestError = async (): Promise<void> => {
    await client.cache.reset();

    errorNotification();
  };

  const [getNodes, { data, loading }] = useLazyQuery<
    GetNodesByCustomerQuery,
    GetNodesByCustomerQueryVariables
  >(GET_NODES_BY_CUSTOMER, {
    fetchPolicy: "network-only",
  });

  const fetchNodes = (customerId: string, nextToken?: string): void => {
    getNodes({
      variables: {
        limit: 500,
        customerId,
        nextToken,
      },
    })
      .then(async (response): Promise<void> => {
        if (response.data?.getNodesByCustomer.nextToken) {
          fetchNodes(customerId, response.data?.getNodesByCustomer.nextToken);
        }

        if (response.error) {
          await handleRequestError();
        }
      })
      .catch(async (): Promise<void> => {
        await handleRequestError();
      });
  };

  return { data, loading };
};
