import { ApolloError, useMutation } from "@apollo/client";

import {
  DeleteZoneThresholdMutation,
  DeleteZoneThresholdMutationVariables,
} from "../../../../../../../API";
import { DELETE_ZONE_THRESHOLD_ITEM } from "../../../../../../../common/operations/mutations";
import {
  errorNotification,
  successNotification,
} from "../../../../../../../common/variables/notification";

export const useDeleteZoneThresholdItem = () => {
  const [deleteZoneThresholdMutation, { data, loading }] = useMutation<
    DeleteZoneThresholdMutation,
    DeleteZoneThresholdMutationVariables
  >(DELETE_ZONE_THRESHOLD_ITEM);
  const deleteZoneThresholdItem = async (id: string, thresholdName: string) => {
    return await deleteZoneThresholdMutation({
      variables: {
        id,
      },
      onCompleted: response => {
        if (response.deleteZoneThreshold) {
          successNotification(`${thresholdName} is deleted`);
        }
      },
      onError: (error: ApolloError): void => {
        errorNotification("Something went wrong, zone type is not deleted");

        console.error(error.message);
      },
      update: (cache, { data }) => {
        if (data?.deleteZoneThreshold) {
          const normalizedId = cache.identify({
            id,
            __typename: "ZoneThreshold",
          });

          cache.evict({ id: normalizedId });

          cache.gc();

          cache.modify({
            fields: {
              getZonesByDevice(existingZones = [], { readField }) {
                return existingZones.items.filter(
                  (zoneRef: any) => id !== readField("zoneThresholdId", zoneRef)
                );
              },
            },
          });
        }
      },
    });
  };

  return { deleteZoneThresholdItem, data, loading };
};
