import { useNavigate } from "react-router-dom";

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import Button from "@mui/material/Button";

import usePlateManagementNavigationRoute from "../../../../../common/hooks/usePlateManagementNavigationRoute";
import { RouteEnum } from "../../../../../common/models/enums";

const NewPlateNumberButton = () => {
  const navigate = useNavigate();

  const { getNavigationRoute } = usePlateManagementNavigationRoute();

  const navRoute = getNavigationRoute(
    RouteEnum.RecentActivitiesPlatesManagementCreatePlateNumber,
    RouteEnum.SensoryRecentActivitiesPlatesManagementCreatePlateNumber
  );

  return (
    <Button
      variant="outlined"
      size="small"
      sx={{
        textTransform: "none",
        marginBottom: "0.5em",
      }}
      startIcon={<AddOutlinedIcon />}
      onClick={(): void => navigate(navRoute)}
    >
      New plate number
    </Button>
  );
};

export default NewPlateNumberButton;
