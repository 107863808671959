import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const StatisticsNoDataView = (): JSX.Element => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "288px",
        gap: "0.5em",
      }}
    >
      <Typography
        variant="body1Bold"
        color="otherTextTertiary.main"
        component="div"
      >
        No data to display
      </Typography>
    </Box>
  );
};

export default StatisticsNoDataView;
