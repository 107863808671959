import { useMutation } from "@apollo/client";

import {
  GetAllAlertsQuery,
  UpdateAlertStatusInput,
  UpdateAlertStatusMutation,
  UpdateAlertStatusMutationVariables,
} from "../../../../../API";
import { UPDATE_ALERT_STATUS } from "../../../../../common/operations/mutations";
import { GET_ALL_ALERTS } from "../../../../../common/operations/queries";

export const useUpdateAlertStatus = () => {
  const [updateAlertMutation, { data, loading }] = useMutation<
    UpdateAlertStatusMutation,
    UpdateAlertStatusMutationVariables
  >(UPDATE_ALERT_STATUS);

  const updateAlert = (input: UpdateAlertStatusInput) => {
    return updateAlertMutation({
      variables: {
        input,
      },
      update: (cache, { data }) => {
        if (!data?.updateAlertStatus) {
          return;
        }

        const rows = cache.readQuery<GetAllAlertsQuery>({
          query: GET_ALL_ALERTS,
        });

        if (!rows?.getAllAlerts.items.length) {
          return cache.writeQuery({
            query: GET_ALL_ALERTS,
            data: {
              ...rows?.getAllAlerts,
              getAllAlerts: {
                items: [data?.updateAlertStatus],
                limit: rows?.getAllAlerts.limit,
                nextToken: rows?.getAllAlerts.nextToken,
              },
            },
          });
        }

        const updatedItems = rows.getAllAlerts.items.map(item => {
          if (item?.id === data?.updateAlertStatus?.id) {
            return data?.updateAlertStatus;
          }

          return item;
        });

        cache.writeQuery({
          query: GET_ALL_ALERTS,
          data: {
            ...rows.getAllAlerts,
            getAllAlerts: {
              items: updatedItems,
              limit: rows.getAllAlerts.limit,
              nextToken: rows.getAllAlerts.nextToken,
            },
          },
        });
      },
    });
  };

  return { updateAlert, data, loading };
};
