import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { Button, Typography } from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";

import StyledDialogNew from "../../../../../../../common/components/dialog/StyledDialogNew";
import { useDialog } from "../../../../../../../common/components/dialog/useDialog";
import {
  errorNotification,
  successNotification,
} from "../../../../../../../common/variables/notification";
import { useCreateZoneThreshold } from "../hooks/useCreateZoneThreshold";
import { IZoneThresholdForm } from "../variables/zoneThresholdData";
import AddNewZoneThresholdForm from "./AddNewZoneThresholdForm";

const CreateZoneThresholdDialog = () => {
  const { open, handleDialogOpen, handleDialogClose } = useDialog();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IZoneThresholdForm>();

  const { createZoneThreshold, loading } = useCreateZoneThreshold();

  const openDialog = (): void => {
    handleDialogOpen();
  };

  const closeDialog = (): void => {
    handleDialogClose();
  };

  const onSubmit: SubmitHandler<IZoneThresholdForm> = data => {
    if (errors.name || errors.value) {
      return;
    }

    createZoneThreshold(data.name, data.value)
      .then((result): void => {
        if (result.errors) {
          errorNotification(`Something went wrong, new zone type isn’t added`);

          console.error(result.errors);

          return;
        }

        successNotification(`Zone type ${data.name} is added`);

        reset();

        closeDialog();
      })
      .catch((error: Error): void => {
        errorNotification(`Something went wrong, new zone type isn’t added`);

        console.error(error);
      });
  };

  return (
    <>
      <Button
        sx={{
          backgroundColor: "transparent",
          "&:hover": { backgroundColor: "transparent" },
        }}
        variant="text"
        color="inherit"
        size="small"
        disableRipple
        startIcon={<AddOutlinedIcon />}
        onClick={openDialog}
      >
        <Typography variant="buttonMedium">New zone type</Typography>
      </Button>
      <StyledDialogNew
        open={open}
        title="Add new zone type"
        submitButtonTitle="Add new zone type"
        onClose={closeDialog}
        SubmitButtonProps={{
          loading,
          onSubmit: handleSubmit(onSubmit),
        }}
      >
        <AddNewZoneThresholdForm control={control} errors={errors} />
      </StyledDialogNew>
    </>
  );
};

export default CreateZoneThresholdDialog;
