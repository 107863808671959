import { useQuery } from "@apollo/client";

import {
  GetZoneThresholdByIdQuery,
  GetZoneThresholdByIdQueryVariables,
} from "../../../../../../../API";
import { useCustomerIdGuard } from "../../../../../../../common/hooks/useCustomerIdGuard";
import { GET_ZONE_THRESHOLD_BY_ID } from "../../../../../../../common/operations/queries";

export const useGetZoneThresholdById = (thresholdId: string) => {
  const selectedCustomerId = useCustomerIdGuard();

  const { data, loading, refetch } = useQuery<
    GetZoneThresholdByIdQuery,
    GetZoneThresholdByIdQueryVariables
  >(GET_ZONE_THRESHOLD_BY_ID, {
    variables: {
      input: {
        thresholdId,
        customerId: selectedCustomerId,
      },
    },
    skip: !thresholdId || !selectedCustomerId,
  });

  return { data, loading, refetch };
};
