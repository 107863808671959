import { useTheme } from "@mui/material";

const GateGuardDataHubIcon = (): JSX.Element => {
  const theme = useTheme();

  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="40"
        height="40"
        rx="8"
        fill={theme.palette.otherBackground.main}
      />
      <path
        d="M22 25.5V13.5C22 13.2239 21.7761 13 21.5 13H10.5C10.2239 13 10 13.2239 10 13.5V25.5C10 25.7761 10.2239 26 10.5 26H12M22 25.5C22 25.7761 21.7761 26 21.5 26H16M22 25.5V16.5C22 16.2239 22.2239 16 22.5 16H25.7929C25.9255 16 26.0527 16.0527 26.1464 16.1464L29.8536 19.8536C29.9473 19.9473 30 20.0745 30 20.2071V25.5C30 25.7761 29.7761 26 29.5 26H28M22 25.5C22 25.7761 22.2239 26 22.5 26H24M12 26C12 27.1046 12.8954 28 14 28C15.1046 28 16 27.1046 16 26M12 26C12 24.8954 12.8954 24 14 24C15.1046 24 16 24.8954 16 26M24 26C24 27.1046 24.8954 28 26 28C27.1046 28 28 27.1046 28 26M24 26C24 24.8954 24.8954 24 26 24C27.1046 24 28 24.8954 28 26"
        stroke={theme.palette.text.primary}
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default GateGuardDataHubIcon;
