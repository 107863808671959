import { useMemo, useState } from "react";

import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import { useConfirm } from "material-ui-confirm";

import { useLazyGetPaginatedLocations } from "../../../../pages/customer-settings/tabs/locations/hooks/useLazyGetPaginatedLocations";
import CreateNodeStepperContainer from "../../../../pages/nodes/nodes-create/components/CreateNodeStepperContainer";
import {
  createNodeActiveStepIndexVariable,
  createNodeInitialActiveStepIndex,
  useCreateNodeActiveStepIndex,
} from "../../../../pages/nodes/variables/createNode.variable";
import {
  nodeFormVariables,
  useNodeFormVariables,
} from "../../../../pages/nodes/variables/nodes";
import confirmDialogStyleOptions from "../../../helpers/confirmDialogParams";
import StyledDialog from "../../dialog/StyledDialog";
import { useDialog } from "../../dialog/useDialog";
import withCustomPaper from "../CustomPaperActionButton";
import { useGetNodesByCustomer } from "./useGetNodesByCustomer";

interface INodeSelectOption {
  title: string | null | undefined;
  value: string | null | undefined;
  location: string | null | undefined;
  locationId: string | null | undefined;
  nodeIP: string | null | undefined;
}

interface INodeSelectProps {
  nodeId: string | null | undefined;
  locationId?: string;
  label?: string;
  hasError?: boolean;
  disableClearable?: boolean;
  disabled?: boolean;
  isAddNodeButton?: boolean;
  onNodeChange: (value: string, title?: string, nodeIP?: string) => void;
}

const filterOptions = createFilterOptions({
  matchFrom: "any",
  stringify: (option: INodeSelectOption): string =>
    (option.location ?? "") + (option.title ?? ""),
});

const NodeSelect = ({
  nodeId,
  locationId,
  label,
  hasError,
  disableClearable,
  disabled,
  onNodeChange,
  isAddNodeButton,
}: INodeSelectProps): JSX.Element => {
  const { data, loading } = useGetNodesByCustomer();
  const { locations } = useLazyGetPaginatedLocations();
  const { open, handleDialogOpen, handleDialogClose } = useDialog();

  const [inputValue, setInputValue] = useState("");

  const nodeForm = useNodeFormVariables();
  const activeStep = useCreateNodeActiveStepIndex();
  const confirm = useConfirm();

  const openAddNodeModal = (): void => {
    handleDialogOpen();
  };

  const closeDialog = (): void => {
    nodeFormVariables({
      ...nodeForm,
      nodeName: "",
    });

    handleDialogClose();
  };

  const closeAddNodeModal = (onBackgroundClick = false): void => {
    if (activeStep === 1 && onBackgroundClick) {
      confirm({
        title: "Make sure you run the node code before moving to the next step",
        confirmationText: "Sure, I've run it",
        cancellationText: "Back to node code",
        content:
          "If you leave this page you won’t be able to run the node code anymore",
        ...confirmDialogStyleOptions,
        confirmationButtonProps: {
          ...confirmDialogStyleOptions.confirmationButtonProps,
          color: "primary",
        },
      }).then((): void => {
        closeDialog();

        createNodeActiveStepIndexVariable(createNodeInitialActiveStepIndex);
      });

      return;
    }

    closeDialog();
  };

  const memoizedOptions = useMemo((): Array<INodeSelectOption> => {
    let options = (
      data?.getNodesByCustomer?.items
        .filter(node => node?.nodeIP)
        .map((node): INodeSelectOption => {
          const locationId = node?.locationId
            ?.replace("C_", "C#")
            .replace("_L_", "#L#");
          const location = locations?.find(
            (item): boolean => item?.id === locationId
          );

          const nodeId = node?.nodeId
            ?.replace("C_", "C#")
            .replace("_L_", "#L#")
            .replace("_N_", "#N#");

          return {
            title: node?.nodeName,
            value: nodeId,
            location: location?.name ?? "",
            locationId,
            nodeIP: node?.nodeIP,
          };
        }) ?? []
    )?.sort((a, b): number =>
      b?.location ? -b?.location?.localeCompare(a?.location ?? "") : 1
    );

    if (locationId) {
      options = options.filter((option): boolean => {
        return option.locationId === locationId;
      });
    }

    return options;
  }, [data?.getNodesByCustomer?.items, locationId]);

  const memoizedValue = useMemo((): INodeSelectOption | null => {
    if (!nodeId) {
      return null;
    }

    return (
      memoizedOptions.find((option): boolean => option?.value === nodeId) ??
      null
    );
  }, [nodeId, memoizedOptions]);

  const customPaper = isAddNodeButton
    ? withCustomPaper({
        text: "New node",
        onClick: openAddNodeModal,
      })
    : undefined;

  return (
    <>
      <Autocomplete
        PaperComponent={customPaper}
        disabled={disabled}
        disableClearable={disableClearable}
        loading={loading}
        value={memoizedValue}
        options={memoizedOptions}
        filterOptions={filterOptions}
        groupBy={(option): string => option?.location ?? ""}
        getOptionLabel={(option): string => option?.title ?? ""}
        onChange={(_event: any, newValue: INodeSelectOption | null): void => {
          if (newValue) {
            onNodeChange(
              newValue?.value ?? "",
              newValue?.title ?? "",
              newValue?.nodeIP ?? ""
            );
          }
        }}
        inputValue={inputValue}
        onInputChange={(_event, newInputValue): void => {
          setInputValue(newInputValue);
        }}
        renderInput={(params): JSX.Element => (
          <TextField
            {...params}
            label={label ?? "Node"}
            error={hasError && !memoizedValue?.value}
            helperText={
              hasError && !memoizedValue?.value
                ? "This field is required"
                : disabled && "The node can't be changed"
            }
            InputProps={{
              ...params.InputProps,
              sx: { borderRadius: "0.5em" },
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />

      <StyledDialog
        showSubmitButton={false}
        open={open}
        onClose={(): void => closeAddNodeModal(true)}
      >
        <CreateNodeStepperContainer
          onBoardScript
          closeDialog={closeAddNodeModal}
        />
      </StyledDialog>
    </>
  );
};

export default NodeSelect;
