import { useTheme } from "@mui/material/styles";

import { themeMode } from "../../providers/theme/MuiThemeProvider";

const DeviceMenuIconFilled = (): JSX.Element => {
  const theme = useTheme();

  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="16" fill={theme.palette.primary.main} />
      <path
        d="M7.6665 15.5833C7.6665 12.8437 7.6665 11.4739 8.42314 10.5519C8.56165 10.3832 8.71641 10.2284 8.88519 10.0899C9.80715 9.33325 11.1769 9.33325 13.9165 9.33325C16.6561 9.33325 18.0259 9.33325 18.9478 10.0899C19.1166 10.2284 19.2714 10.3832 19.4099 10.5519C20.1665 11.4739 20.1665 12.8437 20.1665 15.5833V16.4166C20.1665 19.1562 20.1665 20.5259 19.4099 21.4479C19.2714 21.6167 19.1166 21.7714 18.9478 21.91C18.0259 22.6666 16.6561 22.6666 13.9165 22.6666C11.1769 22.6666 9.80715 22.6666 8.88519 21.91C8.71641 21.7714 8.56165 21.6167 8.42314 21.4479C7.6665 20.5259 7.6665 19.1562 7.6665 16.4166V15.5833Z"
        stroke={
          theme.palette.mode === themeMode.light
            ? theme.palette.otherBackgroundLight.main
            : theme.palette.text.primary
        }
        strokeWidth="1.5"
      />
      <path
        d="M20.1665 13.9167L20.7151 13.6423C22.3367 12.8316 23.1475 12.4262 23.7403 12.7926C24.3332 13.159 24.3332 14.0654 24.3332 15.8784V16.1216C24.3332 17.9346 24.3332 18.841 23.7403 19.2074C23.1475 19.5738 22.3367 19.1684 20.7151 18.3576L20.1665 18.0833V13.9167Z"
        stroke={
          theme.palette.mode === themeMode.light
            ? theme.palette.otherBackgroundLight.main
            : theme.palette.text.primary
        }
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default DeviceMenuIconFilled;
