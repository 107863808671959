import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import PlateCategoryCards from "./components/card/PlateCategoryCards";
import LicensePlatesSearchInput from "./components/LicensePlatesSearchInput";
import NewPlateNumberButtonSystemSettings from "./components/NewPlateNumberButtonSystemSettings";

const PlatesManagementSystemSettings = (): JSX.Element => {
  return (
    <Box sx={{ padding: "1em 1.5em" }}>
      <Typography sx={{ margin: "1em 0" }} variant="h5">
        Plates Management
      </Typography>

      <Box sx={{ display: "flex", flex: 1 }}>
        <LicensePlatesSearchInput />
        <Box sx={{ ml: "auto" }}>
          <NewPlateNumberButtonSystemSettings />
        </Box>
      </Box>

      <PlateCategoryCards />
    </Box>
  );
};

export default PlatesManagementSystemSettings;
