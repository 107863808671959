import { ChangeEvent, FC, useEffect, useRef } from "react";

import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField, { TextFieldProps } from "@mui/material/TextField";

import CloseIcon from "../icons/CloseIcon";
import SearchIconSmall from "./SearchIconSmall";

interface SearchInputProps {
  searchValue: string;
  handleSearchValueChange: (event: ChangeEvent<HTMLInputElement>) => void;
  clearSearchValue: () => void;
}

type Props = TextFieldProps & SearchInputProps;

const SearchInput: FC<Props> = props => {
  const inputRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    const interval = setInterval(() => {
      if (inputRef.current && props.autoFocus) {
        inputRef.current.focus();

        inputRef.current.autofocus = true;
      } else {
        clearInterval(interval);
      }
    }, 300);

    return () => clearInterval(interval);
  }, [inputRef.current, props.autoFocus]);

  return (
    <TextField
      id="searchInput"
      sx={{
        ...props.sx,
        input: {
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          marginLeft: "1.25em",
          "&::placeholder": {
            opacity: 1,
            color: theme => theme.palette.otherTextTertiary.main,
          },
        },
      }}
      placeholder={props.placeholder ?? "Search..."}
      disabled={props.disabled}
      variant={props.variant}
      value={props.searchValue}
      onChange={props.handleSearchValueChange}
      size={props.size}
      autoFocus={props.autoFocus}
      InputProps={{
        inputRef: inputRef,
        autoFocus: props.autoFocus,
        disableUnderline: true,
        startAdornment: (
          <InputAdornment position="start">
            <SearchIconSmall />
          </InputAdornment>
        ),
        endAdornment: (
          <IconButton
            onClick={props.clearSearchValue}
            disableRipple
            sx={{
              visibility: props.searchValue ? "visible" : "hidden",
            }}
          >
            <CloseIcon
              sx={{
                color: theme => theme.palette.otherTextTertiary.main,
                fontSize: "0.75em",
              }}
            />
          </IconButton>
        ),
      }}
    />
  );
};

export default SearchInput;
