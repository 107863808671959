import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import { Control, Controller, FieldErrors } from "react-hook-form";

import AllLocationsFilterDropdownContainer from "../../../../../../../../common/components/locations/AllLocationsFilterDropdownContainer";
import { AutocompleteOptionType } from "../../../../../../../../common/models/autocomplete";
import { IAlertForm } from "../CreateAlertContainer";

interface ILocationAlertFormProps {
  formValues: IAlertForm;
  control: Control<IAlertForm>;
  errors: FieldErrors<IAlertForm>;
  onLocationChange: (location: AutocompleteOptionType) => void;
  onLocationTagChange: (tagId: string) => void;
}

const LocationAlertForm = ({
  formValues,
  control,
  errors,
  onLocationChange,
  onLocationTagChange,
}: ILocationAlertFormProps) => {
  const tagId = formValues.tagId;

  let controllerName: "locationId" | "tagId" = "locationId";

  if (tagId) {
    controllerName = "tagId";
  }

  return (
    <Controller
      name={controllerName}
      control={control}
      rules={{
        required: "This field is required",
      }}
      render={({ field }) => (
        <FormControl
          error={!!errors.locationId}
          sx={{
            width: "100%",
          }}
        >
          <AllLocationsFilterDropdownContainer
            {...field}
            setLocation={onLocationChange}
            setTagId={onLocationTagChange}
            size="medium"
            hasError={!!errors.locationId}
            ignoreDefaultValue
          />
          {errors.locationId && (
            <FormHelperText error>{errors.locationId.message}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
};

export default LocationAlertForm;
