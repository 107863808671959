import { Box, Typography } from "@mui/material";

const SystemSettingsHeader = (): JSX.Element => {
  return (
    <Box padding="0" marginBottom="1em">
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h3">System settings</Typography>
      </Box>
    </Box>
  );
};

export default SystemSettingsHeader;
