import { Paper, Stack } from "@mui/material";
import EmailNotificationsPageContent from "./components/EmailNotificationsPageContent";
import EmailNotificationsPageHeader from "./components/EmailNotificationsPageHeader";
import EmailNotificationsPageTable from "./components/EmailNotificationsPageTable";

const EmailNotificationsPage = (): JSX.Element => {
  return (
    <Stack spacing={4} direction="column">
      <Paper sx={{ padding: "1em" }} elevation={3}>
        <EmailNotificationsPageHeader />
      </Paper>
      <Paper sx={{ padding: "1em" }} elevation={3}>
        <EmailNotificationsPageContent />
        <EmailNotificationsPageTable />
      </Paper>
    </Stack>
  );
};

export default EmailNotificationsPage;
