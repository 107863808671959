import { useConfirm } from "material-ui-confirm";
import { useNavigate, useParams } from "react-router-dom";

import confirmDialogStyleOptions from "../../../common/helpers/confirmDialogParams";
import { useDeleteZone } from "../zones-setup-page/hooks/useDeleteZone";
import { ReactNode } from "react";
import { Box } from "@mui/material";

type DeleteZoneProps = {
  zoneDeviceId: string;
  zoneId: string;
  serviceId: string;
  zoneName: string;
  noRedirection?: boolean;
  children: ReactNode;
};

const DeleteZoneContainer = ({
  zoneId,
  zoneName,
  zoneDeviceId,
  serviceId,
  noRedirection,
  children,
}: DeleteZoneProps): JSX.Element => {
  const { deviceId = "" } = useParams<{ deviceId: string }>();

  const { deleteZone, loading } = useDeleteZone();

  const confirm = useConfirm();
  const navigate = useNavigate();

  const handleDeviceDetailsPageClick = (): void => {
    confirm({
      title: `Are you sure you want to delete the ${zoneName} zone?`,
      description: "All data will be deleted and can't be restored",
      confirmationText: "Delete",
      cancellationText: "Cancel",
      ...confirmDialogStyleOptions,
      confirmationButtonProps: {
        ...confirmDialogStyleOptions.confirmationButtonProps,
        disabled: loading,
      },
    }).then(async (): Promise<void> => {
      await deleteZone({
        deviceId: zoneDeviceId,
        zoneId,
        serviceId,
        name: zoneName,
      });

      if (noRedirection) return;

      navigate(`/device/${encodeURIComponent(deviceId)}`);
    });
  };

  return (
    <Box
      sx={{ padding: "0", display: "flex" }}
      onClick={handleDeviceDetailsPageClick}
    >
      {children}
    </Box>
  );
};

export default DeleteZoneContainer;
