import { useTheme } from "@mui/material/styles";

const NotificationsIconButton = ({
  disabled,
  onClick,
}: {
  disabled?: boolean;
  onClick?: () => void;
}): JSX.Element => {
  const theme = useTheme();

  const handleClick = () => {
    if (!disabled && onClick) {
      onClick();
    }
  };

  const stroke = disabled
    ? theme.palette.primary.shades?.["30p"]
    : theme.palette.primary.main;

  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`deleteIcon ${disabled ? "disabled" : ""}`}
      onClick={handleClick}
    >
      <rect
        x="0.5"
        y="0.5"
        width="31"
        height="31"
        rx="7.5"
        fill={theme.palette.otherBackgroundLight.main}
      />
      <rect
        x="0.5"
        y="0.5"
        width="31"
        height="31"
        rx="7.5"
        stroke={theme.palette.otherOutlineBorder.main}
      />
      <path
        d="M21.6243 14.0914V13.5042C21.6243 10.2803 19.1062 7.66675 16 7.66675C12.8938 7.66675 10.3757 10.2803 10.3757 13.5042V14.0914C10.3757 14.796 10.1748 15.4849 9.79817 16.0712L8.8753 17.508C8.03235 18.8204 8.67587 20.6042 10.142 21.0192C13.9773 22.1048 18.0227 22.1048 21.858 21.0192C23.3241 20.6042 23.9676 18.8204 23.1247 17.508L22.2018 16.0712C21.8252 15.4849 21.6243 14.796 21.6243 14.0914Z"
        stroke={stroke}
        strokeWidth="1.5"
      />
      <path
        d="M12.25 21.8333C12.7959 23.2897 14.2687 24.3333 16 24.3333C17.7313 24.3333 19.2041 23.2897 19.75 21.8333"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default NotificationsIconButton;
