import { useCallback, useEffect, useState } from "react";

import LinearProgress from "@mui/material/LinearProgress";
import {
  DataGrid,
  GridColDef,
  GridRowModel,
  GridRowModesModel,
  GridRowSelectionModel,
} from "@mui/x-data-grid";
import isEqual from "lodash/isEqual";

import TableNoDataOverlay from "../../../../../../../common/components/table/TableNoDataOverlay";
import { dataGridServerStyles } from "../../../../../../../common/providers/theme/design-tokens/DataGrid/dataGridServer.styles";
import { DEFAULT_COLUMN_HEADER_HEIGHT } from "../../../../../../../common/variables/common";
import {
  errorNotification,
  successNotification,
} from "../../../../../../../common/variables/notification";
import { useUpdateZone } from "../../../../../../devices/zones-setup-page/hooks/useUpdateZone";
import { IZonesByDeviceTableRow } from "../hooks/useGetZonesByDeviceTableRow";
import { useZoneFormValidation } from "../../../../../../devices/zones-setup-page/hooks/useZoneFormValidation";
import { validateZoneName } from "../../../../../../../common/helpers/validateZoneName";

interface IZoneListTableProps {
  rows: IZonesByDeviceTableRow[];
  columns: GridColDef[];
  loading: boolean;
  editMode?: boolean;
  rowModesModel?: GridRowModesModel;
  setRowModesModelChange?: (newRowModesModel: GridRowModesModel) => void;
  setHasZoneBeenUpdated?: (value: boolean) => void;
}

const ZoneListTable = ({
  rows,
  columns,
  loading,
  editMode,
  rowModesModel,
  setRowModesModelChange,
  setHasZoneBeenUpdated,
}: IZoneListTableProps): JSX.Element => {
  const { updateZone, loading: updateZoneLoading } = useUpdateZone();
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);

  const { validatePanTilt, validateZoom } = useZoneFormValidation();

  const ZONES_SELECT_ITEMS = 10;

  useEffect((): void => {
    if (rows) {
      setRowSelectionModel([rows[0]?.id ?? ""]);
    }
  }, [rows]);

  if (editMode) {
    const processRowUpdate = useCallback(
      async (
        newRow: GridRowModel,
        oldRow: GridRowModel
      ): Promise<GridRowModel> => {
        if (isEqual(newRow, oldRow)) {
          return oldRow;
        }

        const validName = validateZoneName(newRow?.name);

        if (!validName) {
          return oldRow;
        }

        const isValidPanTilt = validatePanTilt(newRow?.pan, newRow?.tilt);
        const isValidZoom = validateZoom(newRow?.zoom);

        if (!isValidPanTilt) {
          errorNotification(
            "Pan must be between -180 to 180 and Tilt must be between -90 to 35"
          );

          return oldRow;
        }

        if (!isValidZoom) {
          errorNotification("Zoom must be between 0 and 100");

          return oldRow;
        }

        const input = {
          deviceId: rows.find((row): boolean => row.id === newRow.id)
            ?.deviceId as string,
          zoneId: newRow?.id ?? "",
          name: newRow?.name ?? "",
          inspectTime: newRow?.inspectTime ?? "",
          pan: newRow?.pan ?? "",
          tilt: newRow?.tilt ?? "",
          status: newRow?.status ?? false,
          zoom: newRow?.zoom ?? "",
          shouldNotify: newRow?.shouldNotfiy ?? true,
        };

        const result = await updateZone(input);

        if (result.errors) {
          errorNotification();

          return oldRow;
        }

        successNotification();

        if (setHasZoneBeenUpdated) {
          setHasZoneBeenUpdated(true);
        }

        return newRow;
      },
      [updateZone]
    );

    const handleProcessRowUpdateError = (): void => {
      errorNotification();
    };

    return (
      <DataGrid
        sx={{
          ...dataGridServerStyles.sx,
          "& .MuiDataGrid-row--editing": {
            boxShadow:
              "rgba(0, 0, 0, 0.2) -1px -1px 6px 2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px",
          },
        }}
        onRowSelectionModelChange={(
          newSelection: GridRowSelectionModel
        ): void => {
          setRowSelectionModel(newSelection);
        }}
        rowSelectionModel={rowSelectionModel}
        pageSizeOptions={[ZONES_SELECT_ITEMS]}
        rows={rows}
        columns={columns}
        slots={{
          loadingOverlay: () => <LinearProgress />,
          noRowsOverlay: TableNoDataOverlay,
        }}
        columnHeaderHeight={DEFAULT_COLUMN_HEADER_HEIGHT}
        autoHeight
        hideFooterSelectedRowCount
        getRowId={(row: { rowId?: string }): string => row?.rowId ?? ""}
        loading={loading || updateZoneLoading}
        rowCount={rows.length}
        sortingOrder={["desc", "asc"]}
        editMode="row"
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={handleProcessRowUpdateError}
        rowModesModel={rowModesModel}
        onRowModesModelChange={setRowModesModelChange}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: ZONES_SELECT_ITEMS,
            },
          },
        }}
      />
    );
  }

  return (
    <>
      <DataGrid
        sx={dataGridServerStyles.sx}
        rows={rows}
        columns={columns}
        slots={{
          loadingOverlay: () => <LinearProgress />,
          noRowsOverlay: TableNoDataOverlay,
        }}
        columnHeaderHeight={DEFAULT_COLUMN_HEADER_HEIGHT}
        autoHeight
        hideFooterSelectedRowCount
        getRowId={(row: { rowId: string }): string => row.rowId}
        loading={loading}
        rowCount={ZONES_SELECT_ITEMS}
        sortingOrder={["desc", "asc"]}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: ZONES_SELECT_ITEMS,
            },
          },
        }}
      />
    </>
  );
};

export default ZoneListTable;
