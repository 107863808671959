import { makeVar, useReactiveVar } from "@apollo/client";

export type AlertType = "EMAIL" | "SYSTEM" | "USER" | "SMS";

export type AlertTypeField =
  | "isEmailEnabled"
  | "isSystemEnabled"
  | "isSMSEnabled";

export enum AlertTypeEnum {
  EMAIL = "EMAIL",
  SYSTEM = "SYSTEM",
  NON_ADMIN = "NON_ADMIN",
  USER = "USER",
  SMS = "SMS",
}

export interface IAlertDialog {
  isOpen: boolean;
  rowId: string;
  alertType: AlertType | null;
}

export const defaultAlertDialogVariables: IAlertDialog = {
  isOpen: false,
  rowId: "",
  alertType: null,
};

export const alertDialogVariables = makeVar<IAlertDialog>(
  defaultAlertDialogVariables
);

export const useAlertDialog = () => useReactiveVar(alertDialogVariables);
