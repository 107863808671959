import { useConfirm } from "material-ui-confirm";

import { ZoneThreshold } from "../../../../../../../API";
import DeleteIcon from "../../../../../../../common/components/icons/DeleteIcon";
import confirmDialogStyleOptions from "../../../../../../../common/helpers/confirmDialogParams";
import { useDeleteZoneThresholdItem } from "../hooks/useDeleteZoneThreshoItem";

type DeleteThresholdItemContainerProps = {
  threshold: ZoneThreshold;
};

const DeleteThresholdItemContainer = ({
  threshold,
}: DeleteThresholdItemContainerProps) => {
  const confirm = useConfirm();

  const { deleteZoneThresholdItem } = useDeleteZoneThresholdItem();

  const onDeleteThresholdHandler = () => {
    confirm({
      title: `Confirm deleting the ${threshold.name} zone type`,
      description:
        "If you delete this zone type, it’ll be deleted for all zones that have selected this zone type",
      confirmationText: "Delete",
      cancellationText: "Cancel",
      ...confirmDialogStyleOptions,
      confirmationButtonProps: {
        ...confirmDialogStyleOptions.confirmationButtonProps,
      },
    }).then(() => {
      deleteZoneThresholdItem(threshold.id, threshold.name);
    });
  };

  return (
    <DeleteIcon onClick={onDeleteThresholdHandler} width={22} height={22} />
  );
};

export default DeleteThresholdItemContainer;
