import { Stack, Typography } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { UpdateCustomerInput } from "../../../API";
import StyledDialogNew from "../../../common/components/dialog/StyledDialogNew";
import StatusToggle from "../../../common/components/item/StatusToggle";
import { useCustomerIdGuard } from "../../../common/hooks/useCustomerIdGuard";
import { useUpdateCustomer } from "../../../common/hooks/useUpdateCustomer";
import {
  errorNotification,
  successNotification,
} from "../../../common/variables/notification";

import EditIconButtonNoBorder from "../../../common/components/icons/EditIconButtonNoBorder";
import { useDialog } from "../../../common/components/dialog/useDialog";
import { convertToUTC } from "../../../common/helpers/convertToUTC";
import { convertToLocalTime } from "../../../common/helpers/convertToLocalTime";
import { convertTo12Hour } from "../../../common/helpers/convertTo12Hour";
import { INVALID_DATE_ERROR_MESSAGE } from "../../human-validator/constants";
import { useGetCustomer } from "../../../common/hooks/useGetCustomer";
import { useCustomerExternalIdGuard } from "../../../common/hooks/useCustomerExternalIdGuard";

const EditPauseAlertsDialog = (): JSX.Element => {
  const { customer, refetch } = useGetCustomer();
  const { open, handleDialogOpen, handleDialogClose } = useDialog();
  const selectedCustomerId = useCustomerIdGuard();
  const selectedCustomerExternalId = useCustomerExternalIdGuard();

  const { updateCustomer } = useUpdateCustomer();

  const [alertSwitch, setAlertSwitch] = useState(false);
  const [fromError, setFromError] = useState("");
  const [toError, setToError] = useState("");
  const [fromValue, setFromValue] = useState<string>("00:00");
  const [toValue, setToValue] = useState<string>("00:00");
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");

  const openDialog = (): void => {
    handleDialogOpen();
  };

  const closeDialog = (): void => {
    handleDialogClose();
  };

  useEffect(() => {
    try {
      if (customer?.aggAlertingData) {
        const aggAlertingData = JSON.parse(customer?.aggAlertingData);
        const isTimedAlertingEnabled =
          aggAlertingData.isTimedAlertingEnabled ?? false;
        const startTime = aggAlertingData.startTime ?? "";
        const endTime = aggAlertingData.endTime ?? "";
        const fromValueLocal = convertToLocalTime(startTime);
        const toValueLocal = convertToLocalTime(endTime);

        setAlertSwitch(isTimedAlertingEnabled);

        setFromValue(fromValueLocal);

        setToValue(toValueLocal);

        setStartDate(convertTo12Hour(fromValueLocal));

        setEndDate(convertTo12Hour(toValueLocal));
      }
    } catch (error) {
      console.error(error);
    }
  }, [customer]);

  const handleAlertSwitchChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newAlertSwitch = event.target.checked;

    setAlertSwitch(newAlertSwitch);

    const fromValueUTC = convertToUTC(fromValue);
    const toValueUTC = convertToUTC(toValue);

    updateCustomer({
      id: selectedCustomerId,
      customerExternalId: selectedCustomerExternalId,
      aggAlertingData: JSON.stringify({
        endTime: toValueUTC,
        isTimedAlertingEnabled: newAlertSwitch,
        startTime: fromValueUTC,
      }),
    } as UpdateCustomerInput)
      .then((response): void => {
        if (!response.errors) {
          successNotification(
            newAlertSwitch ? "Alerts are paused" : "Alerts are unpaused"
          );
        }
      })
      .catch((error: Error): void => {
        errorNotification(
          newAlertSwitch
            ? "Something went wrong, alerts aren't paused"
            : "Something went wrong, alerts aren't unpaused"
        );
      });
  };

  const handleFromTimeChange = (newValue: dayjs.Dayjs | null) => {
    setFromValue(newValue ? newValue.format("HH:mm") : "");
  };

  const handleToTimeChange = (newValue: dayjs.Dayjs | null) => {
    setToValue(newValue ? newValue.format("HH:mm") : "");
  };

  const savePauseAlerts = async (): Promise<void> => {
    const fromValueUTC = convertToUTC(fromValue);
    const toValueUTC = convertToUTC(toValue);

    if (
      !fromValue ||
      !toValue ||
      fromValue === INVALID_DATE_ERROR_MESSAGE ||
      toValue === INVALID_DATE_ERROR_MESSAGE
    ) {
      errorNotification("Please provide valid start and end times.");

      return;
    }

    try {
      const response = await updateCustomer({
        id: selectedCustomerId,
        customerExternalId: selectedCustomerExternalId,
        aggAlertingData: JSON.stringify({
          endTime: toValueUTC,
          isTimedAlertingEnabled: alertSwitch,
          startTime: fromValueUTC,
        }),
      } as UpdateCustomerInput);

      if (!response.errors) {
        successNotification("Changes are saved");

        await refetch();

        closeDialog();
      } else {
        throw new Error("Something went wrong, changes aren't saved");
      }
    } catch (error) {
      errorNotification("Something went wrong, changes aren't saved");

      console.error(error);
    }
  };

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography sx={{ color: "#465870" }}>Pause alerts</Typography>
        {alertSwitch && (
          <>
            <Typography>
              {startDate}-{endDate}
            </Typography>
            <EditIconButtonNoBorder onClick={openDialog} />
          </>
        )}
        <StatusToggle
          checked={alertSwitch}
          handleChange={handleAlertSwitchChange}
          label={""}
        />
      </Stack>
      <StyledDialogNew
        open={open}
        title="Edit time period when alerts shouldn't be received"
        label="This will pause alerts for every location and every user"
        submitButtonTitle="Save changes"
        onClose={closeDialog}
        SubmitButtonProps={{
          onSubmit: savePauseAlerts,
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={3}
            sx={{ marginTop: 5, marginBottom: 2 }}
          >
            <TimePicker
              label="From"
              value={dayjs(`2024-08-17T${fromValue}`)}
              onChange={handleFromTimeChange}
              onError={newError => {
                if (newError) {
                  setFromError("Please provide a valid time");
                } else {
                  setFromError("");
                }
              }}
              slotProps={{
                textField: {
                  helperText: fromError,
                },
              }}
            />
            <TimePicker
              label="To"
              value={dayjs(`2024-08-17T${toValue}`)}
              onChange={handleToTimeChange}
              onError={newError => {
                if (newError) {
                  setToError("Please provide a valid time");
                } else {
                  setToError("");
                }
              }}
              slotProps={{
                textField: {
                  helperText: toError,
                },
              }}
            />
          </Stack>
        </LocalizationProvider>
      </StyledDialogNew>
    </>
  );
};

export default EditPauseAlertsDialog;
