import { ReactNode, useState } from "react";
import {
  ListItemButton,
  Collapse,
  Box,
  List,
  useTheme,
  Typography,
} from "@mui/material";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";

type LocationItemProps = {
  location: string;
  children: ReactNode;
};

const GLVLocationItem: React.FC<LocationItemProps> = ({
  location,
  children,
}): JSX.Element => {
  const theme = useTheme();
  const borderStyle = `1px solid ${theme.palette.otherOutlineBorder.main}`;

  const [isOpen, setIsOpen] = useState(false);

  const handleCityClick = (): void => {
    setIsOpen(!isOpen);
  };

  return (
    <Box
      sx={{ borderTop: borderStyle, borderBottom: borderStyle }}
      key={location}
    >
      <ListItemButton
        sx={{
          "&:hover": {
            backgroundColor: theme.palette.otherOutlineBorder.main,
          },
          padding: "0.25em 0",
          fontWeight: "bold",
          ...(isOpen && {
            color: theme.palette.primary.main,
          }),
        }}
        onClick={handleCityClick}
      >
        <Typography sx={{ width: "100%" }} variant="body2Bold">
          {location}
        </Typography>

        {isOpen ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />}
      </ListItemButton>

      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {children}
        </List>
      </Collapse>
    </Box>
  );
};

export default GLVLocationItem;
