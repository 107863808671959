import { useEffect } from "react";
import { Navigate } from "react-router-dom";

type AnyRouteHandlerProps = {
  userMainLink: string;
};

export const AnyRouteHandler: React.FC<AnyRouteHandlerProps> = ({
  userMainLink,
}) => {
  useEffect(() => {
    if (userMainLink && !userMainLink.startsWith("/")) {
      window.location.href = userMainLink;
    }
  }, [userMainLink]);

  if (userMainLink && userMainLink.startsWith("/")) {
    return <Navigate to={userMainLink} replace />;
  }

  // window.location.href = APPLICATION_LINKS.ADMINISTRATING;

  return null;
};
