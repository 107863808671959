import { Box } from "@mui/material";
import CustomerSettingsHeader from "./CustomerSettingsHeader";
import CustomerSettingsTabs from "./CustomerSettingsTabs";

const CustomerSettingsPage = (): JSX.Element => {
  return (
    <Box>
      <CustomerSettingsHeader />
      <CustomerSettingsTabs />
    </Box>
  );
};

export default CustomerSettingsPage;
