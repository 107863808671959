import { useState } from "react";

import { Box, Paper, Typography } from "@mui/material";
import isEqual from "lodash/isEqual";

import { TimePeriod } from "../../../API";
import StyledChart, {
  apexChartsTooltipStyles,
} from "../../../common/components/apexchart/StyledChart";
import AllLocationsFilterDropdownContainer from "../../../common/components/locations/AllLocationsFilterDropdownContainer";
import CircularLoading from "../../../common/components/progress/CircularLoading";
import { AutocompleteOptionType } from "../../../common/models/autocomplete";
import { useAllAlertsChart } from "../hooks/useAllAlertsChart";
import { useLocationsFilterDropdown } from "../hooks/useLocationsFilterDropdown";
import StatisticsNoDataView from "./StatisticsNoDataView";
import TimePeriodFilter from "./TimePeriodFilter";

const AllAlertsContainer = (): JSX.Element => {
  const [activeTimePeriod, setActiveTimePeriod] = useState<TimePeriod>(
    TimePeriod.ONE_WEEK
  );

  const {
    location,
    handleLocationChange,
    tagLocations,
    handleTagLocationsChange,
  } = useLocationsFilterDropdown();

  const handleTimePeriodChange = (timePeriod: TimePeriod): void => {
    if (timePeriod !== activeTimePeriod) {
      setActiveTimePeriod(timePeriod);
    }
  };

  const { showNoData, series, options, loading, refetching, refetchChartData } =
    useAllAlertsChart(
      activeTimePeriod,
      location?.value
        ? [location?.value]
        : tagLocations?.map(l => l?.value ?? "") ?? null
    );

  const setLocationFilter = (locationValue: AutocompleteOptionType): void => {
    if (locationValue?.value !== location?.value) {
      handleLocationChange(locationValue, activeTimePeriod, refetchChartData);
    }
  };

  const setTagFilter = (locations: AutocompleteOptionType[] | null): void => {
    if (!isEqual(locations, tagLocations)) {
      handleTagLocationsChange(locations, activeTimePeriod, refetchChartData);
    }
  };

  return (
    <Paper
      sx={{
        padding: "1.5em",
        height: "428px",
      }}
    >
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Typography variant="h5">All alerts</Typography>
        <Box
          sx={{
            display: "flex",
            gap: "1em",
            marginLeft: "auto",
          }}
        >
          <Box
            sx={{
              width: "180px",
            }}
          >
            <AllLocationsFilterDropdownContainer
              setLocation={setLocationFilter}
              setTagLocations={setTagFilter}
            />
          </Box>
          <TimePeriodFilter
            activePeriod={activeTimePeriod}
            setActivePeriod={handleTimePeriodChange}
          />
        </Box>
      </Box>
      {(loading || refetching) && <CircularLoading />}
      {!loading && !showNoData && (
        <Box sx={apexChartsTooltipStyles}>
          <StyledChart options={options} series={series} height={300} />
        </Box>
      )}
      {showNoData && <StatisticsNoDataView />}
    </Paper>
  );
};

export default AllAlertsContainer;
