import { useEffect } from "react";

import { InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";

import { DeviceData } from "../../../../API";
import { TDeviceConfig } from "../../variables/devices";

interface Entry {
  value: any;
  display: string;
}

interface DeviceDynamicDropdownInputProps {
  label: string;
  field: Exclude<keyof DeviceData, "__typename">;
  entries: Entry[] | null | undefined;
  deviceConfig: TDeviceConfig;
  setDeviceConfig: (
    field: Exclude<keyof DeviceData, "__typename">,
    value: string
  ) => void;
}

const DeviceDynamicDropdownInput = ({
  label,
  field,
  entries,
  deviceConfig,
  setDeviceConfig,
}: DeviceDynamicDropdownInputProps): JSX.Element | null => {
  const handleEntryChange = (e: SelectChangeEvent<any>): void => {
    setDeviceConfig(field, e.target.value as string);
  };

  useEffect((): void => {
    const value = deviceConfig[field];

    const isValueExist = entries?.some(entry => entry.value === value);

    if (!isValueExist && entries && entries?.length) {
      setDeviceConfig(field, entries[0]?.value ?? "");
    }
  }, []);

  const value = deviceConfig[field];

  return (
    <div>
      <InputLabel id={`${label}-label`}>{label}</InputLabel>
      <Select
        labelId={`${label}-label`}
        fullWidth
        id={label}
        value={value}
        onChange={handleEntryChange}
      >
        {entries &&
          entries.map(
            (entry, index): JSX.Element => (
              <MenuItem key={index} value={entry.value}>
                {entry.display}
              </MenuItem>
            )
          )}
      </Select>
    </div>
  );
};

export default DeviceDynamicDropdownInput;
