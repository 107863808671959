const createS3KeyForZone = (
  nodeId: string,
  zoneName: string,
  deviceName: string
): string => {
  const customerId = nodeId.split("#L#")[0];
  const customerIdReformatted = customerId.replace("#", "_");

  const nodeIdReformatted = nodeId
    .replace("C#", "C_")
    .replace("#L#", "_L_")
    .replace("#N#", "_N_");

  const stringWithoutApostrophes = zoneName.replaceAll(/'/g, "");
  const encodedZoneNameString = encodeURIComponent(stringWithoutApostrophes);

  return `${customerIdReformatted}/${nodeIdReformatted}/keyframes/${deviceName}_${encodedZoneNameString}_kf.jpg`;
};

export default createS3KeyForZone;
