import { FC } from "react";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import WarningBox from "../../../../common/components/box/WarningBox";
import MainPaperWrapper from "../../../../common/components/item/MainPaperWrapper";
import { allModelsOptions } from "../../../../common/components/select/ServiceSelect/ModelsToRunMultiselect";
import { IDeviceTableRow } from "../../hooks/useDeviceTableRows";
import DeviceModels from "./DeviceModels";

interface IDeviceModelProps {
  device: IDeviceTableRow;
}

const DeviceModelsContainer: FC<IDeviceModelProps> = ({
  device,
}): JSX.Element => {
  const model = allModelsOptions.find((option): boolean => {
    return device.modelsToRun.some(
      (modelToRun): boolean =>
        modelToRun === option.value || modelToRun === option.name
    );
  });

  if (!model) {
    return <></>;
  }

  return (
    <MainPaperWrapper>
      <Stack direction="column" spacing={2}>
        <Typography variant="h5">Models</Typography>

        {!device.isOnline && (
          <WarningBox>
            The device is offline now. Models will be monitored when the device
            is online
          </WarningBox>
        )}

        <DeviceModels device={device} />
      </Stack>
    </MainPaperWrapper>
  );
};

export default DeviceModelsContainer;
