import { gql, useMutation } from "@apollo/client";

import type {
  AddZoneInput,
  AddZoneMutation,
  AddZoneMutationVariables,
} from "../../../../API";
import { ADD_ZONE } from "../../../../common/operations/mutations";

export const useCreateZone = () => {
  const [saveZone, { data, loading }] = useMutation<
    AddZoneMutation,
    AddZoneMutationVariables
  >(ADD_ZONE);

  const createZone = async (input: AddZoneInput) => {
    return await saveZone({
      variables: {
        input,
      },
      update(cache, response) {
        cache.modify({
          fields: {
            getZones(existing) {
              const newZoneRef = cache.writeFragment({
                data: response.data?.addZone,
                fragment: gql`
                  fragment NewZone on Zone {
                    inspectTime
                    deviceId
                    id
                    name
                    pan
                    serviceId
                    status
                    tilt
                    shouldNotify
                  }
                `,
              });

              if (!existing) {
                return response?.data;
              }

              return {
                ...existing,
                items: [...existing.items, newZoneRef],
              };
            },
          },
        });
      },
    });
  };

  return { createZone, data, loading };
};
