import { FC } from "react";

import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import { useConfirm } from "material-ui-confirm";

import { Category } from "../../../../../../API";
import DeleteIconButton from "../../../../../../common/components/icons/DeleteIconButton";
import confirmDialogStyleOptions from "../../../../../../common/helpers/confirmDialogParams";
import { useDeleteCategory } from "../../hooks/useDeleteCategory";

interface IDeleteCategoryButtonProps {
  category: Category;
  disabled?: boolean;
}

const DeleteCategoryButton: FC<IDeleteCategoryButtonProps> = ({
  category,
  disabled,
}) => {
  const confirm = useConfirm();

  const { deleteCategory } = useDeleteCategory();

  const handleDeleteCategory = (): void => {
    confirm({
      title: `Are you sure you want to delete the ${category.categoryName} category?`,
      confirmationText: "Delete",
      cancellationText: "Cancel",
      ...confirmDialogStyleOptions,
      confirmationButtonProps: {
        ...confirmDialogStyleOptions.confirmationButtonProps,
      },
    }).then(async (): Promise<void> => {
      await deleteCategory(category.id as string);
    });
  };

  return (
    <>
      {disabled ? (
        <Tooltip
          TransitionComponent={Zoom}
          title={"This category contains license plates"}
        >
          <div style={{ height: "32px" }}>
            <DeleteIconButton disabled />
          </div>
        </Tooltip>
      ) : (
        <Tooltip TransitionComponent={Zoom} title={"Delete"}>
          <div style={{ height: "32px" }}>
            <DeleteIconButton onClick={handleDeleteCategory} />
          </div>
        </Tooltip>
      )}
    </>
  );
};

export default DeleteCategoryButton;
