import EventsTableServer from "../common/EventsTableServer";
import { useGetGasLeakEventsTableColumns } from "./hooks/useGetGasLeakEventsTableColumns";
import { useGetGasLeakEventsTableRows } from "./hooks/useGetGasLeakEventsTableRows";

const GasLeakTableServerContainer = () => {
  const { columns } = useGetGasLeakEventsTableColumns();

  const {
    rows,
    loading,
    handlePaginationModelChange,
    hasNextPage,
    isActivePageChange,
    paginationModel,
  } = useGetGasLeakEventsTableRows();

  return (
    <EventsTableServer
      columns={columns}
      rows={rows}
      loading={loading}
      onPaginationModelChange={handlePaginationModelChange}
      hasNextPage={hasNextPage}
      isActivePageChange={isActivePageChange}
      paginationModel={paginationModel}
    />
  );
};

export default GasLeakTableServerContainer;
