import { useEffect, useMemo, useState } from "react";

import { useLazyQuery } from "@apollo/client";

import {
  GetCustomerHealthQuery,
  GetCustomerHealthQueryVariables,
} from "../../../API";
import { useCustomerIdGuard } from "../../../common/hooks/useCustomerIdGuard";
import { GET_CUSTOMER_HEALTH } from "../../../common/operations/queries";
import { errorNotification } from "../../../common/variables/notification";
import client from "../../../configs/apolloClient";
import { useListenToDeviceHealthDataChange } from "./useListenToDeviceHealthDataChange";
import { useListenToNodeOnlineStatusChange } from "./useListenToNodeHealthDataChange";
import { useListenToServiceHealthDataChange } from "./useListenToServiceHealthDataChange";
import { ONLINE_STATUS_THRESHOLD_MS } from "../../../common/variables/common";

interface IHealthStatus {
  status: boolean;
  uptime: string;
}

interface IParsedNodeHealthCheckStatus extends IHealthStatus {
  nodeId: string;
  nodeName: string;
}

interface IParsedDeviceHealthCheckStatus extends IHealthStatus {
  deviceId: string;
  deviceName: string;
}

interface IParsedServiceHealthCheckStatus extends IHealthStatus {
  serviceType: string;
  serviceId: string;
}

export interface IParsedDeploymentHealthRow {
  locationId: string;
  locationName: string;
  nodes: IParsedNodeHealthCheckStatus[];
  devices: IParsedDeviceHealthCheckStatus[];
  services: IParsedServiceHealthCheckStatus[];
}

interface IUseGetCustomerHealth {
  rows?: IParsedDeploymentHealthRow[];
  loading: boolean;
  isHealthDataLoaded: boolean;
}

export const useGetCustomerHealth = (): IUseGetCustomerHealth => {
  const selectedCustomerId = useCustomerIdGuard();
  const { data: deviceHealthData, isOnline: isDeviceOnline } =
    useListenToDeviceHealthDataChange();
  const { data: nodeHealthData } = useListenToNodeOnlineStatusChange();
  const { data: serviceHealthData, isOnline: isServiceOnline } =
    useListenToServiceHealthDataChange();

  const [isHealthDataLoaded, setIsHealthDataLoaded] = useState(false);

  const handleRequestError = async (): Promise<void> => {
    await client.cache.reset();

    errorNotification();
  };

  const [getCustomerHealth, { data, loading, refetch }] = useLazyQuery<
    GetCustomerHealthQuery,
    GetCustomerHealthQueryVariables
  >(GET_CUSTOMER_HEALTH, {
    fetchPolicy: "network-only",
    refetchWritePolicy: "merge",
    onError: handleRequestError,
  });

  useEffect((): void => {
    if (selectedCustomerId) {
      getCustomerHealth({
        variables: {
          customerId: selectedCustomerId,
        },
      });
    }
  }, [selectedCustomerId]);

  useEffect(() => {
    const intervalId = setInterval(refetch, ONLINE_STATUS_THRESHOLD_MS);

    return () => clearInterval(intervalId);
  }, []);

  const rows = useMemo(() => {
    if (!data?.getCustomerHealth) return [];

    try {
      const { getCustomerHealth } = data;

      return JSON.parse(getCustomerHealth ?? "[]");
    } catch (error) {
      console.error(error);

      return [];
    }
  }, [data]);

  const [customerHealthRows, setCustomerHealth] = useState(rows);

  useEffect(() => {
    if (!loading && data) {
      setIsHealthDataLoaded(true);
    }

    setCustomerHealth(rows);
  }, [rows]);

  useEffect(() => {
    if (
      !deviceHealthData?.listenToDeviceHealthData &&
      !nodeHealthData?.listenToNodeOnlineStatus &&
      !serviceHealthData?.listenToServiceHealthData
    ) {
      return;
    }

    const result = customerHealthRows.map(
      (row: IParsedDeploymentHealthRow) => ({
        ...row,
        nodes: row.nodes.map((node: IParsedNodeHealthCheckStatus) => {
          if (node.nodeId === nodeHealthData?.listenToNodeOnlineStatus?.id) {
            return {
              ...node,
              status: nodeHealthData?.listenToNodeOnlineStatus.isOnline,
            };
          }

          return node;
        }),
        devices: row.devices.map((device: IParsedDeviceHealthCheckStatus) => {
          if (
            device.deviceId === deviceHealthData?.listenToDeviceHealthData?.id
          ) {
            return {
              ...device,
              status: isDeviceOnline,
            };
          }

          return device;
        }),
        services: row.services.map(
          (service: IParsedServiceHealthCheckStatus) => {
            if (
              service.serviceId ===
              serviceHealthData?.listenToServiceHealthData?.id
            ) {
              return {
                ...service,
                status: isServiceOnline,
              };
            }

            return service;
          }
        ),
      })
    );

    setCustomerHealth(result);
  }, [deviceHealthData, nodeHealthData, serviceHealthData]);

  return { rows: customerHealthRows, loading, isHealthDataLoaded };
};
