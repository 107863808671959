import { useTheme } from "@mui/material/styles";

const ImportIcon = ({
  disabled,
  size = 16,
  onClick,
}: {
  disabled?: boolean;
  onClick?: () => void;
  size?: number;
}): JSX.Element => {
  const theme = useTheme();

  const stroke = disabled
    ? theme.palette.grey[400]
    : theme.palette.common.black;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <g clipPath="url(#clip0_12434_46527)">
        <path
          d="M11.334 6.00134C12.784 6.00941 13.5693 6.07372 14.0815 6.58597C14.6673 7.17176 14.6673 8.11457 14.6673 10.0002V10.6669C14.6673 12.5525 14.6673 13.4953 14.0815 14.0811C13.4957 14.6669 12.5529 14.6669 10.6673 14.6669H5.33398C3.44837 14.6669 2.50556 14.6669 1.91977 14.0811C1.33398 13.4953 1.33398 12.5525 1.33398 10.6669L1.33398 10.0002C1.33398 8.11457 1.33398 7.17176 1.91977 6.58597C2.43203 6.07371 3.2173 6.00941 4.66732 6.00134"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M8 1.33337L8 10M8 10L6 7.66671M8 10L10 7.66671"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_12434_46527">
          <rect
            width="16"
            height="16"
            rx="5"
            fill={theme.palette.common.white}
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ImportIcon;
