import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";

import AllLocationsFilterDropdownContainer from "../../../common/components/locations/AllLocationsFilterDropdownContainer";
import { AutocompleteOptionType } from "../../../common/models/autocomplete";
import {
  filterByOfflineVariable,
  selectedDeploymentHealthLocationVariable,
  selectedDeploymentHealthTagLocationsVariable,
  useFilterByOffline,
} from "../variables/deploymentHealth";

const DeploymentHealthPageHeader = (): JSX.Element => {
  const offlineFilter = useFilterByOffline();

  const handleChange = (
    _event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ): void => {
    filterByOfflineVariable(checked);
  };

  const handleLocationChange = (
    locationValue: AutocompleteOptionType
  ): void => {
    selectedDeploymentHealthLocationVariable(locationValue);
  };

  const handleTagLocationsChange = (
    tagLocations: AutocompleteOptionType[] | null
  ): void => {
    selectedDeploymentHealthTagLocationsVariable(tagLocations);
  };

  return (
    <Box padding="0" marginBottom="1em">
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "1em",
        }}
      >
        <Typography variant="h3">Health Status</Typography>
        <Box sx={{ marginLeft: "auto", minWidth: "240px" }}>
          <AllLocationsFilterDropdownContainer
            setLocation={handleLocationChange}
            setTagLocations={handleTagLocationsChange}
          />
        </Box>
        <FormControlLabel
          control={
            <Checkbox
              checked={offlineFilter}
              onChange={handleChange}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label="Offline"
        />
      </Box>
    </Box>
  );
};

export default DeploymentHealthPageHeader;
