import Box from "@mui/material/Box";

import S3Image from "./S3Image";
import S3Video from "./S3Video";
import { allowedFileTypes, extractFileNameExt } from "./fileHelper";

interface ThumbnailInterface {
  mediaOutput: string;
  controls?: boolean;
  zoneCard?: boolean;
  handleOnCLick?: () => void;
}

const Thumbnail = ({
  mediaOutput,
  controls,
  handleOnCLick,
}: ThumbnailInterface): JSX.Element => {
  // TODO: debug why this is not working
  const extractedFileExtension = extractFileNameExt(mediaOutput)?.toLowerCase();

  const isImage = allowedFileTypes.images.some(
    (allowedImageExtension): boolean => {
      return allowedImageExtension === extractedFileExtension;
    }
  );

  const isVideo = allowedFileTypes.videos.some(
    (allowedVideoExtension): boolean => {
      return allowedVideoExtension === extractedFileExtension;
    }
  );

  return (
    <Box sx={{ cursor: "pointer" }} onClick={handleOnCLick}>
      {isImage && mediaOutput && (
        <S3Image s3Key={mediaOutput} sx={{ width: "100%" }} />
      )}

      {isVideo && mediaOutput && (
        <S3Video s3Key={mediaOutput} controls={controls} />
      )}
    </Box>
  );
};

export default Thumbnail;
