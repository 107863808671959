import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { useConfirm } from "material-ui-confirm";

import { AddServiceInput, ModelType } from "../../../API";
import ConfigurationComponent from "../../../common/components/box/ConfigurationComponent";
import FullPageLoader from "../../../common/components/item/FullPageLoader";
import ItemDetailHeader from "../../../common/components/item/ItemDetailHeader";
import ItemDetailTable from "../../../common/components/item/ItemDetailTable";
import MainPaperWrapper from "../../../common/components/item/MainPaperWrapper";
import LiveViewContainer from "../../../common/components/live-view/LiveViewContainer";
import NodeSelect from "../../../common/components/select/NodeSelect/NodeSelect";
import BreadcrumbNavigation from "../../../common/components/tabs/BreadcrumbNavigation";
import confirmDialogStyleOptions from "../../../common/helpers/confirmDialogParams";
import { useCustomerIdGuard } from "../../../common/hooks/useCustomerIdGuard";
import { useStreamProcesses } from "../../../common/hooks/useStreamProcesses";
import StyledLoadingButton from "../../../common/providers/theme/design-tokens/LoadingButton/StyledLoadingButton";
import {
  errorNotification,
  successNotification,
} from "../../../common/variables/notification";
import { useCreateService } from "../../model-manager/hooks/useCreateService";
import {
  ServiceTypeEnum,
  configurationParametersByModelType,
} from "../../model-manager/variables/modelManager";
import { IDeviceTableRow } from "../hooks/useDeviceTableRows";
import useGetDeviceByUrl from "../hooks/useGetDeviceByUrl";
import { useSyncShadow } from "../../model-manager/hooks/useSyncShadow";

const AddModelPage = (): JSX.Element => {
  const { modelToRun = "" } = useParams();

  const [deviceDetail, setDeviceDetail] = useState<IDeviceTableRow | null>(
    null
  );

  const [selectedNode, setSelectedNode] = useState(deviceDetail?.node.id);
  const { syncShadowsForNode, loading: syncShadowsLoading } = useSyncShadow();

  const { fetchDevice, getServicesLoading, loading } = useGetDeviceByUrl();
  const customerId = useCustomerIdGuard();
  const { createService, loading: createServiceLoading } = useCreateService();

  const navigate = useNavigate();
  const confirm = useConfirm();

  const deviceDetailPageLink = `/device/${encodeURIComponent(
    deviceDetail?.rowId ?? ""
  )}`;

  let correctConfigurations = configurationParametersByModelType[modelToRun];
  let currentModel = "";
  let dockerModelName = modelToRun; // correct are [gasleak | firesmoke | hardhat | gateguard | tanklevel]

  switch (modelToRun) {
    case ModelType.gasleak:
      currentModel = ServiceTypeEnum.LEAK;

      dockerModelName = "gasleak";
      break;

    case ModelType.liquidleak:
      currentModel = ServiceTypeEnum.LIQUID_LEAK;

      dockerModelName = "gasleak";
      break;

    case ModelType.agg:
      currentModel = ServiceTypeEnum.GATE_GUARD;

      dockerModelName = "gateguard";
      break;

    case ModelType.fire:
    case ModelType.smoke:
    case "firesmoke":
      currentModel = ServiceTypeEnum.FIRE_SMOKE;

      dockerModelName = "firesmoke";
      break;

    case ModelType.hardhat:
      currentModel = ServiceTypeEnum.HARD_HAT;

      dockerModelName = "hardhat";
      break;

    case ModelType.tlm:
      currentModel = ServiceTypeEnum.TANK_LEVEL;

      dockerModelName = "tanklevel";
      break;

    default:
      navigate(deviceDetailPageLink);
      break;
  }

  correctConfigurations = {
    docker_image: `534256877503.dkr.ecr.us-east-1.amazonaws.com/ccai-${dockerModelName}:latest`,
    ...(correctConfigurations as object),
  };

  const [configurationParams, setConfigurationParams] = useState({
    editMode: false,
    value: JSON.stringify(correctConfigurations),
  });

  const { startStreaming, stopStreaming } = useStreamProcesses();

  useEffect((): void => {
    fetchDevice().then((device): void => {
      if (device) {
        setDeviceDetail(device);

        setSelectedNode(device.node.id);

        startStreaming(device);
      }
    });
  }, [getServicesLoading]);

  useEffect((): void => {
    if (deviceDetail) {
      stopStreaming(deviceDetail);
    }
  }, [deviceDetail]);

  useEffect((): void => {
    const modelIsAssigned = deviceDetail?.models.find(
      (model): boolean => model.serviceName === currentModel
    );

    if (modelIsAssigned) {
      navigate(deviceDetailPageLink);
    }
  }, [deviceDetail]);

  const keyFrameOrRecentFrame = useMemo(() => {
    if (deviceDetail?.deviceData.hasPanTilt) {
      return deviceDetail.deviceData.recentFrame ?? "";
    }

    return deviceDetail?.deviceData.keyFrame ?? "";
  }, [deviceDetail]);

  const confirmCancel = (): void => {
    confirm({
      title: "Are you sure you want to cancel model adding?",
      confirmationText: "Confirm",
      cancellationText: "Cancel",
      ...confirmDialogStyleOptions,
      confirmationButtonProps: {
        ...confirmDialogStyleOptions.confirmationButtonProps,
        disabled: loading,
        color: "primary",
      },
    }).then((): void => {
      navigate(deviceDetailPageLink);
    });
  };

  const onAddModelHandler = (): void => {
    const input: AddServiceInput = {
      serviceType:
        currentModel === ServiceTypeEnum.LEAK
          ? ServiceTypeEnum.GAS_LEAK
          : currentModel,
      nodeId: selectedNode ?? "",
      deviceId: deviceDetail?.rowId ?? "",
      locationId: deviceDetail?.location.id ?? "",
      customerId,
      configuration: configurationParams.value,
    };

    createService(input)
      .then(response => {
        if (response?.data?.addService) {
          const service = response.data.addService;

          return service;
        }

        if (response.errors) {
          errorNotification();
        }
      })
      .then(service => {
        if (!service) return;

        syncShadowsForNode(service.nodeId);

        successNotification(`${currentModel} model is added`);

        navigate(
          `${deviceDetailPageLink}/model/${encodeURIComponent(currentModel)}`
        );
      })
      .catch((error): void => {
        errorNotification();

        console.error(error);
      });
  };

  const cancelConfigurationEdit = (): void => {
    setConfigurationParams({ ...configurationParams, editMode: false });
  };

  const saveConfigurationEdit = (value: string): void => {
    setConfigurationParams({ value, editMode: false });
  };

  const onNodeChangeHandler = (value: string): void => {
    setSelectedNode(value);
  };

  const tableData = [
    {
      label: "CONFIGURATION PARAMETERS",
      value: configurationParams.value,
      jsonFormat: true,
      onEditHandler: (): void =>
        setConfigurationParams({ ...configurationParams, editMode: true }),
    },
  ];

  const breadcrumbItems = [
    { label: "Devices", path: "/devices" },
    {
      label: deviceDetail?.name ?? "",
      path: "/device/" + encodeURIComponent(deviceDetail?.rowId ?? ""),
    },
    { label: `Add ${currentModel.toLowerCase()} model` },
  ];

  if (loading || getServicesLoading || !deviceDetail) {
    return <FullPageLoader />;
  }

  return (
    <>
      <BreadcrumbNavigation items={breadcrumbItems} />
      <ItemDetailHeader name={`Add ${currentModel.toLowerCase()} model`} />

      <MainPaperWrapper>
        <Box
          gap="1.5em"
          sx={{
            "@media (min-width: 1440px)": {
              display: "grid",
              alignItems: "flex-start",
              gridTemplateColumns: "1fr 1fr",
            },
          }}
        >
          <Box>
            <ItemDetailTable
              data={[
                {
                  label: "NODE",
                  labelStyles: {
                    display: "flex",
                    alignItems: "center",
                    padding: 0,
                  },
                  value: (
                    <NodeSelect
                      label=""
                      nodeId={selectedNode}
                      onNodeChange={onNodeChangeHandler}
                    />
                  ),
                },
              ]}
            />

            {!configurationParams.editMode && (
              <>
                <Divider sx={{ margin: "0.5em 0", opacity: "0.5" }} />
                <ItemDetailTable data={tableData} />
              </>
            )}

            {configurationParams.editMode && (
              <ConfigurationComponent
                model={modelToRun}
                onCancelHandler={cancelConfigurationEdit}
                onSaveHandler={saveConfigurationEdit}
                params={configurationParams.value}
              />
            )}
          </Box>
          <Box>
            <Box
              sx={{
                height: "100%",
              }}
            >
              <LiveViewContainer
                s3Key={keyFrameOrRecentFrame}
                showPtButton={deviceDetail?.deviceData?.hasPanTilt}
                device={deviceDetail}
              />
            </Box>
          </Box>
        </Box>
      </MainPaperWrapper>

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "1em",
        }}
      >
        <StyledLoadingButton
          sx={{
            marginTop: "1.5em",
          }}
          loading={false}
          variant="outlined"
          color="inherit"
          onClick={(): void => confirmCancel()}
        >
          Back
        </StyledLoadingButton>

        <StyledLoadingButton
          sx={{
            marginTop: "1.5em",
          }}
          loading={createServiceLoading || syncShadowsLoading}
          variant="contained"
          color="primary"
          onClick={onAddModelHandler}
        >
          Add model
        </StyledLoadingButton>
      </Box>
    </>
  );
};

export default AddModelPage;
