import { useEffect, useState } from "react";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import uuid from "react-uuid";

import { Box } from "@mui/material";
import { useConfirm } from "material-ui-confirm";

import { VideoInput, VideoMethod } from "../../API";
import CustomStepper from "../../common/components/instructions/CustomStepper";
import FullPageLoader from "../../common/components/item/FullPageLoader";
import ItemDetailHeader from "../../common/components/item/ItemDetailHeader";
import BreadcrumbNavigation, {
  BreadcrumbItem,
} from "../../common/components/tabs/BreadcrumbNavigation";
import confirmDialogStyleOptions from "../../common/helpers/confirmDialogParams";
import useBeforeUnload from "../../common/hooks/useBeforeUnload";
import { useCustomerIdGuard } from "../../common/hooks/useCustomerIdGuard";
import { RouteEnum } from "../../common/models/enums";
import { useAddBatchVideosDetails } from "../hooks/useCreateBatchVideosDetails";
import useDeleteS3File from "../hooks/useDeleteS3File";
import useAddFile from "../hooks/useAddFile";
import { successNotification } from "../../common/variables/notification";
import { PointsFormat } from "../../common/components/canvas/utils";
import { useGetClient } from "../hooks/useGetClient";
import { useLazyGetUser } from "../hooks/useLazyGetUser";
import UploadVideoStepComponent from "./upload-video-group/components/UploadVideoStepComponent";
import VideoDetailsStepComponent from "./upload-video-group/components/VideoDetailsStepComponent";
import { useCreateClientGroup } from "../hooks/useCreateGroup";

export enum VideoProcessingSteps {
  UPLOAD_VIDEO_STEP,
  VIDEO_DETAILS_STEP,
}

export type AnnotatedVideoFile = File & {
  preview: string;
};

export type SimpleAnnotation = {
  name: string;
  points: PointsFormat;
};

export type LeakFinderAnnotation = {
  focusAreas?: SimpleAnnotation[];
};

export type VideoAnalysisData = {
  uploadLink?: string;
  video: AnnotatedVideoFile;
  videoMethod?: VideoMethod;
  angle?: number;
  height?: number;
  keyframeTime?: number;
  distance?: number;
  annotations?: LeakFinderAnnotation;
  cameraFov?: number;
  windspeed?: number;
  notes?: string;
};

export enum UnitsEnum {
  MPH = "mph",
  KPH = "kph",
}

export interface VideoAnalysisFormState {
  videos: VideoAnalysisData[];
  groupName: string;
  groupId: string;
  unit: UnitsEnum;
}

export const UploadVideoGroupPage = () => {
  const [searchParams] = useSearchParams();
  const [activeFormStep, setActiveFormStep] = useState(
    VideoProcessingSteps.UPLOAD_VIDEO_STEP
  );
  const [videosDetailStep, setVideosDetailStep] = useState(0);
  const [activeVideosStep, setActiveVideosStep] = useState(1);

  const [isLoading, setIsLoading] = useState(false);
  const [shouldPreventUnload, setShouldPreventUnload] = useState(false);

  const navigate = useNavigate();
  const confirm = useConfirm();

  const selectedCustomerId = useCustomerIdGuard();

  const { addBatchVideosDetails } = useAddBatchVideosDetails();
  const { createClientGroup } = useCreateClientGroup();

  const { deleteFile } = useDeleteS3File();
  const { addFile, waitForAllUploads, loading: videoLoading } = useAddFile();

  const { loading: userInfoLoading, getParsedUserInfo } = useLazyGetUser();

  const getUserInfo = async () => {
    const response = await getParsedUserInfo();

    return {
      windspeedUnit: response?.windspeedUnit as UnitsEnum,
    };
  };

  const {
    control,
    setValue,
    watch,
    reset,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm<VideoAnalysisFormState>({
    defaultValues: async () => {
      return {
        videos: [],
        groupName: "",
        groupId: selectedCustomerId + "#G#" + Date.now(),
        unit: (await getUserInfo()).windspeedUnit,
      };
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "videos",
  });

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () =>
      fields.forEach(file => URL.revokeObjectURL(file.video.preview));
  }, []);

  useBeforeUnload(shouldPreventUnload);

  const clientId = searchParams.get("clientId") ?? "";

  const { data, loading } = useGetClient(clientId);

  const clientData = data?.getClientById;

  if (loading || !clientData || userInfoLoading) {
    return <FullPageLoader />;
  }

  const confirmCancel = (path = RouteEnum.LeakFinderClientUploads): void => {
    confirm({
      title: "Are you sure you want to cancel video adding?",
      description: "If you leave, the new informations won’t be saved",
      confirmationText: "Leave",
      cancellationText: "Cancel",
      ...confirmDialogStyleOptions,
      confirmationButtonProps: {
        ...confirmDialogStyleOptions.confirmationButtonProps,
        color: "primary",
      },
    }).then((): void => {
      navigate(path);
    });
  };

  const steps = ["Upload videos", "Additional video details"];

  const setStepHandler = (step: number) => setActiveFormStep(step);

  const preventUpload = (state: boolean) => setShouldPreventUnload(state);

  const onHandleCancel = (): void => {
    confirmCancel();
  };

  const setVideoDetailStepHandler = (step: VideoProcessingSteps) =>
    setVideosDetailStep(step);

  const handleNext = () => {
    setActiveVideosStep(prevActiveStep => prevActiveStep + 1);
  };

  const onBackVideo = () => {
    setActiveVideosStep(prevActiveStep => prevActiveStep - 1);
  };

  const prepareVideoData = (
    formValues: VideoAnalysisData[],
    selectedCustomerId: number,
    clientId: string,
    groupId: string
  ) => {
    return formValues.flatMap(form => {
      const geometry = JSON.stringify({
        distance: form.distance,
        angle: form.angle,
        height: form.height,
      });

      const baseVideoData = {
        customerId: String(selectedCustomerId),
        clientId,
        groupId,
        annotations: JSON.stringify(form.annotations),
        fov: form.cameraFov,
        geometry,
        windspeed: Number(form.windspeed),
        videoName: form.video.name.trim().replace(/\s+/g, "_"),
        videoMethod: form.videoMethod as VideoMethod,
        notes: form.notes,
        isVideoProcessed: false,
        sourceS3Key: form.uploadLink as string, // Math.random().toString(36).substring(7),
      };

      if (form.annotations?.focusAreas?.length) {
        return form.annotations.focusAreas.map(focusArea => ({
          ...baseVideoData,
          annotations: JSON.stringify({ focusArea }),
        }));
      } else {
        return [
          { ...baseVideoData, annotations: JSON.stringify({ focusArea: {} }) },
        ];
      }
    });
  };

  const chunkArray = (array: VideoInput[], chunkSize: number) => {
    const results = [];

    for (let index = 0; index < array.length; index += chunkSize) {
      results.push(array.slice(index, index + chunkSize));
    }

    return results;
  };

  const processChunks = async (chunks: VideoInput[][]) => {
    for (const chunk of chunks) {
      try {
        await addBatchVideosDetails(chunk);
      } catch (error) {
        console.error("Error processing a batch of videos:", error);
      }
    }
  };

  const addVideoToDddbProcess = async (
    formData: VideoAnalysisData,
    index: number
  ) => {
    const videoId = uuid();
    const videoFileFromForm = formData.video;
    const groupId = watch("groupId");

    const uploadVideoPath =
      `${selectedCustomerId}/${clientId}/${groupId}/#V#${videoId}#V#${videoFileFromForm.name}`.replaceAll(
        "#",
        "_"
      );

    if (formData.uploadLink) {
      return handleNext();
    }

    await addFile(uploadVideoPath, videoFileFromForm);

    await setValue(`videos.${index}.uploadLink`, uploadVideoPath);

    if (activeVideosStep === watch("videos").length) {
      return;
    } else {
      handleNext();
    }
  };

  const onFinishProcess = async () => {
    setIsLoading(prevState => !prevState);

    const formValues = watch("videos");
    const groupName = watch("groupName");
    const groupId = watch("groupId");
    const formData = formValues[activeVideosStep - 1];

    await createClientGroup({
      groupName,
      clientId,
      groupId: groupId,
    });

    if (!formData.uploadLink) {
      await addVideoToDddbProcess(formData, activeVideosStep - 1);
    }

    await waitForAllUploads();

    const preparedVideosToSend = prepareVideoData(
      formValues,
      selectedCustomerId,
      clientId,
      groupId
    );

    const chunks = chunkArray(preparedVideosToSend, 25);

    try {
      await processChunks(chunks);

      reset();
    } catch (error) {
      console.error("Failed to process all video chunks:", error);
    }

    navigate(RouteEnum.LeakFinderClientUploads);
  };

  const handleOnFinish = () => {
    handleSubmit(onFinishProcess)();
  };

  const onNextVideo: SubmitHandler<VideoAnalysisFormState> = async (
    data: VideoAnalysisFormState
  ) => {
    const realIndex = activeVideosStep - 1;
    const formData = data.videos[realIndex];

    addVideoToDddbProcess(formData, realIndex);
  };

  const processOfDeleting = async (formData: VideoAnalysisData) => {
    if (formData.uploadLink) {
      await deleteFile(formData.uploadLink);
    }

    remove(activeVideosStep - 1);

    const isCountOfVideosZero = watch("videos").length === 0;
    const stayOnTheSameIndex = !isCountOfVideosZero && activeVideosStep === 1;

    if (isCountOfVideosZero) {
      setStepHandler(VideoProcessingSteps.UPLOAD_VIDEO_STEP);

      setActiveVideosStep(1);

      return;
    }

    if (stayOnTheSameIndex) return;

    onBackVideo();
  };

  const onDeleteVideoHandler = () => {
    const realIndex = activeVideosStep - 1;
    const formData = watch("videos")[realIndex];

    const isFormNotEmpty = Boolean(
      formData.angle ||
        formData.annotations ||
        formData.cameraFov ||
        formData.distance ||
        formData.windspeed ||
        formData.notes
    );

    if (isFormNotEmpty) {
      return confirm({
        title:
          "You have filled details about this video. Do you wan’t to delete it?",
        description: "This video won’t be analysed if you delete it",
        confirmationText: "Delete",
        cancellationText: "Cancel",
        ...confirmDialogStyleOptions,
      }).then(async () => await processOfDeleting(formData));
    } else {
      return confirm({
        title: "Are you sure you want to delete this video?",
        description: "This video won’t be analysed if you delete it",
        confirmationText: "Delete",
        cancellationText: "Cancel",
        ...confirmDialogStyleOptions,
      }).then(async () => await processOfDeleting(formData));
    }
  };

  const handleDeleteVideoFromUploadList = async (index: number) => {
    const formData = watch("videos")[index];

    const isFormNotEmpty = Boolean(
      formData.angle ||
        formData.annotations ||
        formData.cameraFov ||
        formData.distance ||
        formData.windspeed ||
        formData.notes
    );

    if (isFormNotEmpty) {
      if (formData.uploadLink) {
        await deleteFile(formData.uploadLink);
      }

      remove(index);

      successNotification("The video is deleted");
    } else {
      remove(index);

      successNotification("The video is deleted");
    }
  };

  const mainIntroText = `Upload videos to ${clientData?.clientName}`;

  const breadcrumbItems: BreadcrumbItem[] = [
    { label: "Client uploads", onClick: onHandleCancel },
    { label: mainIntroText },
  ];

  return (
    <Box>
      <BreadcrumbNavigation items={breadcrumbItems} />
      <ItemDetailHeader name={mainIntroText} />

      <CustomStepper activeStep={activeFormStep} steps={steps} />

      {activeFormStep === VideoProcessingSteps.UPLOAD_VIDEO_STEP && (
        <UploadVideoStepComponent
          handleSubmit={handleSubmit}
          errors={errors}
          watch={watch}
          control={control}
          preventUpload={preventUpload}
          addNewVideoField={append}
          removeVideoField={handleDeleteVideoFromUploadList}
          setFormValue={setValue}
          setStepHandler={setStepHandler}
          handleCancel={onHandleCancel}
        />
      )}

      {activeFormStep === VideoProcessingSteps.VIDEO_DETAILS_STEP && (
        <VideoDetailsStepComponent
          key={activeVideosStep}
          activeStep={activeVideosStep}
          onNextVideo={onNextVideo}
          onBackVideo={onBackVideo}
          countOfVideos={fields.length}
          watch={watch}
          control={control}
          clientID={clientId}
          videoIsLoading={videoLoading || isLoading}
          setFormValue={setValue}
          clearErrors={clearErrors}
          handleSubmit={handleSubmit}
          handleOnFinish={handleOnFinish}
          setStepHandler={setStepHandler}
          onDeleteVideoHandler={onDeleteVideoHandler}
          videoDetailStep={videosDetailStep}
          setVideoDetailStep={setVideoDetailStepHandler}
        />
      )}
    </Box>
  );
};
