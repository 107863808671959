import { AppBar } from "@mui/material";
import { ReactNode } from "react";
import { TOP_BAR_HEIGHT } from "./MenuItem";
import { Z_INDEX_PRIORITY } from "../../variables/common";

type AppBarCommonProps = {
  children: ReactNode;
};

export const AppBarCommon: React.FC<AppBarCommonProps> = ({ children }) => {
  return (
    <AppBar
      sx={{
        height: `${TOP_BAR_HEIGHT}px`,
        display: "flex",
        zIndex: Z_INDEX_PRIORITY.HEADER_LEFT_BAR,
        justifyContent: "center",
      }}
      enableColorOnDark
      color="primary"
      position="fixed"
    >
      {children}
    </AppBar>
  );
};
