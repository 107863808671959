import { ReactNode } from "react";

import { Typography } from "@mui/material";

const TypographyBody1Regular = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  return <Typography variant="body1Regular">{children}</Typography>;
};

export default TypographyBody1Regular;
