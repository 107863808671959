import {
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import isEmpty from "lodash/isEmpty";

import TableNoDataOverlay from "../../../common/components/table/TableNoDataOverlay";
import { useDeploymentHealthTableRows } from "../hooks/useDeploymentHealthTableRows";
import DeploymentHealthTableBodyRow from "./table/DeploymentHealthTableBodyRow";
import DeploymentHealthTableHeadRow from "./table/DeploymentHealthTableHeadRow";

const DeploymentHealthTable = (): JSX.Element => {
  const { data, loading } = useDeploymentHealthTableRows();

  return (
    <Paper>
      <Table>
        <DeploymentHealthTableHeadRow />
        <TableBody>
          {data &&
            !loading &&
            data.map((row): JSX.Element => {
              return (
                <DeploymentHealthTableBodyRow key={row.locationId} row={row} />
              );
            })}

          {loading && (
            <TableRow>
              <TableCell colSpan={6}>
                {[...Array(6)].map(
                  (_, index): JSX.Element => (
                    <Skeleton
                      key={index}
                      variant="rectangular"
                      sx={{ my: 3 }}
                    />
                  )
                )}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {isEmpty(data) && !loading && <TableNoDataOverlay />}
    </Paper>
  );
};

export default DeploymentHealthTable;
