import { UserSettingsInput } from "../../API";
import {
  DEFAULT_CAMERA_FOV,
  DEFAULT_WINDSPEED_UNIT,
  DEFAULT_VIDEO_SPEED,
} from "../../leak-finder/hooks/useLazyGetUser";
import { useAuthenticatedUser } from "./useAuthenticatedUser";
import useUserFromCache from "./useUserFromCache";

const useUserSettingsFromCache = () => {
  const { user } = useAuthenticatedUser();

  const { getCachedUser } = useUserFromCache();

  const getUserSettings = (userId?: string) => {
    if (!userId) {
      return null;
    }

    let leakFinderSettings: UserSettingsInput | null = null;

    const normalizedId = `U#${user?.attributes?.sub as string}`;

    const cachedUser = getCachedUser(normalizedId);

    try {
      if (cachedUser?.userSettings) {
        const settings = JSON.parse(cachedUser.userSettings);

        leakFinderSettings = {
          defaultCameraFov: settings?.defaultCameraFov ?? DEFAULT_CAMERA_FOV,
          defaultWindspeedUnit:
            settings?.defaultWindspeedUnit ?? DEFAULT_WINDSPEED_UNIT,
          defaultVideoSpeed: settings?.defaultVideoSpeed ?? DEFAULT_VIDEO_SPEED,
        };

        return leakFinderSettings;
      }
    } catch (error) {
      console.error(error);

      return null;
    }
  };

  const userSettingsFromCache = getUserSettings(user?.username);

  return { userSettings: userSettingsFromCache };
};

export default useUserSettingsFromCache;
