import { useTheme } from "@mui/material/styles";

const InfoIconGreen = ({
  size = 16,
}: {
  disabled?: boolean;
  onClick?: () => void;
  size?: number;
}): JSX.Element => {
  const theme = useTheme();

  const stroke = theme.palette.primary.main;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_12434_46529)">
        <circle
          cx="8.00065"
          cy="8.00004"
          r="6.66667"
          stroke={stroke}
          strokeWidth="1.5"
        />
        <path
          d="M8 11.3334V7.33337"
          stroke="#4CAF50"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <circle
          cx="0.666667"
          cy="0.666667"
          r="0.666667"
          transform="matrix(1 0 0 -1 7.33398 6)"
          fill={stroke}
        />
      </g>
      <defs>
        <clipPath id="clip0_12434_46529">
          <rect width="16" height="16" fill={theme.palette.common.white} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default InfoIconGreen;
