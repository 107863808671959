import AWS from "aws-sdk";

import aws from "../../aws-exports.json";
import { getJwtToken } from "./getJwtToken";

export class SecretsManagerHelper {
  public static initialized = false;
  private static secretsManager: AWS.SecretsManager;

  private static async setAWSConfig() {
    if (!this.secretsManager) {
      const jwt = await getJwtToken();

      AWS.config.region = aws.aws_user_files_s3_bucket_region;

      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: aws.aws_cognito_identity_pool_id,
        Logins: {
          ["cognito-idp.us-east-1.amazonaws.com/" + aws.aws_user_pools_id]: jwt,
        },
      });

      this.secretsManager = new AWS.SecretsManager();

      this.initialized = !!this.secretsManager;
    }
  }

  public static async setSecret(secret: any) {
    if (!this.secretsManager) {
      await this.setAWSConfig();
    }

    const response = await this.secretsManager.createSecret(secret).promise();

    console.log(response);
    try {
      console.log("secrets");
    } catch (e) {
      console.log(e);
    }
  }
}
