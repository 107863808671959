import { useEffect, useState } from "react";

import { Box, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import SelectedApplicationsIcon from "../../icons/SelectedApplicationsIcon";
import SelectApplicationsIcon from "../../icons/SelectApplicationsIcon";
import { AuthApiClient } from "../../../api/AuthApiClient";
import { AvailableApps } from "../../../types/apps";
import { RoleEnum, RouteEnum } from "../../../models/enums";
import { useNavigate } from "react-router-dom";
import { useAuthenticatedUser } from "../../../hooks/useAuthenticatedUser";
import CircularLoading from "../../progress/CircularLoading";
import { getRolePath } from "../../../helpers/userLinkHelper";

interface AppOption {
  label: string;
  path: string;
  id: string | AvailableApps;
}

const APP_OPTIONS: AppOption[] = [
  {
    label: "Luminary",
    path: RouteEnum.Luminary,
    id: AvailableApps.luminary,
  },
  {
    label: "Sensory",
    path: RouteEnum.Home,
    id: AvailableApps.sensory,
  },
  {
    label: "Leak Finder",
    path: RouteEnum.LeakFinderClientUploads,
    id: AvailableApps.leakfinder,
  },
  {
    label: "EnerGPT Connected",
    path: RouteEnum.EnerGPT,
    id: AvailableApps.energpt,
  },
  {
    label: "ProveZero Marketplace",
    path: RouteEnum.Marketplace,
    id: AvailableApps.marketplace,
  },
];

const APP_ROOT_OPTIONS: AppOption[] = [
  {
    label: "Human Validator",
    path: RouteEnum.HumanValidator,
    id: "Human Validator",
  },
  {
    label: "Human Validator Review",
    path: RouteEnum.HumanValidatorReview,
    id: "Human Validator Review",
  },
];

const ApplicationSelect = (): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [options, setOptions] = useState<AppOption[]>([]);

  const { role } = useAuthenticatedUser();

  const authApiClient = new AuthApiClient();

  const navigate = useNavigate();

  useEffect(() => {
    async function getTenants() {
      const user = await authApiClient.getCurentUser();

      let availableOptions = APP_OPTIONS.filter(option =>
        user.tenant.available_apps.includes(option.id as AvailableApps)
      );

      if (role === RoleEnum.ROOT || role === RoleEnum.CC_OPS_ADMIN) {
        availableOptions = [...availableOptions, ...APP_ROOT_OPTIONS];
      }

      setOptions(availableOptions);
    }

    if (role) getTenants();
  }, [role]);

  const isOpen = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (path: string) => {
    setAnchorEl(null);

    const userMainLink = getRolePath(role as RoleEnum);

    if (path.startsWith("/")) {
      if (
        window.location.pathname === userMainLink &&
        path === RouteEnum.Home
      ) {
        return;
      }

      if (path === RouteEnum.Home) {
        navigate(userMainLink, { replace: true });

        return;
      }

      navigate(path, { replace: true });
    } else {
      window.location.href = path;
    }
  };

  return (
    <Box
      sx={{
        dislpay: "flex",
        borderRadius: "8px",
        alighItems: "center",
        justifyContent: "center",
      }}
    >
      <IconButton
        sx={{ p: 0 }}
        onClick={handleClick}
        aria-controls={isOpen ? "app-selector-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? "true" : undefined}
      >
        {isOpen ? <SelectedApplicationsIcon /> : <SelectApplicationsIcon />}
      </IconButton>

      <Menu
        id="app-selector-menu"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        MenuListProps={{ "aria-labelledby": "app-selector-button" }}
        sx={{
          ".MuiList-padding": {
            p: 0,
          },
          ".MuiPaper-root": {
            borderRadius: "0.5em",
            boxShadow: "none",
            border: theme =>
              `1px solid ${theme.palette.otherOutlineBorder.main}`,
          },
        }}
      >
        <Typography
          variant="body2Bold"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            height: "36px",
            width: "100%",
            pl: 2,
            py: 0,
            marginBottom: "4px",
            borderBottom: 1,
            borderColor: "divider",
          }}
        >
          Available products
        </Typography>

        {options.length ? (
          options.map(({ label, path }) => (
            <MenuItem
              key={path}
              onClick={() => handleMenuItemClick(path)}
              sx={{
                height: "36px",
                "&:hover": {
                  backgroundColor: theme =>
                    theme.palette.primary.shades?.["8p"],
                  "&::before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    left: 0,
                    top: 0,
                    bottom: 0,
                    width: 4,
                    backgroundColor: theme => theme.palette.primary.main,
                  },
                },
              }}
            >
              <Typography variant="body2Regular">{label}</Typography>
            </MenuItem>
          ))
        ) : (
          <CircularLoading />
        )}

        <MenuItem
          key={"Administration"}
          onClick={() => handleMenuItemClick(RouteEnum.Administration)}
          sx={{
            height: "36px",
            marginTop: "4px",
            pl: 2,
            py: 0,
            borderTop: 1,
            borderColor: "divider",
            "&:hover": {
              backgroundColor: theme => theme.palette.primary.shades?.["8p"],
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                left: 0,
                top: 0,
                bottom: 0,
                width: 4,
                backgroundColor: theme => theme.palette.primary.main,
              },
            },
          }}
        >
          <Typography variant="body2Regular">{"Administration"}</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default ApplicationSelect;
