import React, { useEffect, useState } from "react";

import Tooltip from "@mui/material/Tooltip";

import { Zone } from "../../../API";
import { useUpdateZone } from "../../../pages/devices/zones-setup-page/hooks/useUpdateZone";
import StatusToggle from "../item/StatusToggle";

type ZoneStatusToggleProps = {
  params: Zone;
  setOutsideState?: () => void;
};

const ZoneStatusToggle: React.FC<ZoneStatusToggleProps> = ({
  params,
  setOutsideState,
}) => {
  const [status, setStatus] = useState(params.status);

  useEffect(() => {
    setStatus(params.status);
  }, [params.status]);

  const { updateZone } = useUpdateZone();

  const onChangeStatusHandler = () => {
    const input = {
      deviceId: params.deviceId,
      inspectTime: params.inspectTime,
      name: params.name,
      pan: params.pan,
      serviceId: params.serviceId,
      status: !status,
      tilt: params.tilt,
      zoneId: params.id,
      zoom: params.zoom,
      shouldNotify: params.shouldNotify ?? true,
    };

    updateZone(input);

    setStatus(!status);

    if (setOutsideState) {
      setOutsideState();
    }
  };

  return (
    <Tooltip placement="top" title={status ? "Disable zone" : "Enable zone"}>
      <div>
        <StatusToggle checked={status} handleChange={onChangeStatusHandler} />
      </div>
    </Tooltip>
  );
};

export default ZoneStatusToggle;
