import { Box, ListItemButton, Typography, useTheme } from "@mui/material";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import { IStatisticsResponseModel } from "../../../../pages/statistics/models/chart";
import { GasLeakContainerSteps } from "../../../../pages/statistics/components/GasLeakContainer";

type DeviceItemProps = {
  onChangeHandler: (step: number, device: IStatisticsResponseModel) => void;
  device: IStatisticsResponseModel;
};

const GLVDeviceItem: React.FC<DeviceItemProps> = ({
  device,
  onChangeHandler,
}): JSX.Element => {
  const theme = useTheme();

  return (
    <ListItemButton
      onClick={(): void =>
        onChangeHandler(GasLeakContainerSteps.PolarAreaChart, device)
      }
      sx={{
        padding: "0.25em 0",
        pl: 4,
        borderTop: `1px solid ${theme.palette.otherOutlineBorder.main}`,
        "&:hover": {
          backgroundColor: theme.palette.otherOutlineBorder.main,
          color: theme.palette.primary.main,
        },
        display: "flex",
        justifyContent: "space-between",
      }}
      key={device.deviceName + String(Math.random() * 50 + 3)}
    >
      <Typography
        sx={{ "&::first-letter": { textTransform: "uppercase" } }}
        variant="body2Bold"
      >
        {device.deviceName}
      </Typography>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          variant="body2Bold"
        >
          {device.volume}
        </Typography>
        <KeyboardArrowRightRoundedIcon />
      </Box>
    </ListItemButton>
  );
};

export default GLVDeviceItem;
