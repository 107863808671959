import { FC, useEffect, useState } from "react";
import Highlighter from "react-highlight-words";

import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Zoom from "@mui/material/Zoom";

import { Category } from "../../../../../../API";
import EditIconButton from "../../../../../../common/components/icons/EditIconButton";
import RouterLink from "../../../../../../common/components/RouterLink";
import { RouteEnum } from "../../../../../../common/models/enums";
import { ILicensePlatesRow } from "../../hooks/useGetPaginatedLicensePlates";
import { useLicensePlatesTableVariables } from "../../variables/licensePlatesTable";
import CategoryAlertingSwitch from "./CategoryAlertingSwitch";
import DeleteCategoryButton from "./DeleteCategoryButton";
import LicensePlatesTable, {
  ILicensePlatesTableProps,
} from "./LicensePlatesTable";
import AddIconButton from "../../../../../../common/components/icons/AddIconButton";
import { useDialog } from "../../../../../../common/components/dialog/useDialog";
import AddPlateToCategoryDialog from "../plate-category/AddPlateToCategoryDialog";
import usePlateManagementNavigationRoute from "../../../../../../common/hooks/usePlateManagementNavigationRoute";

interface IPlateCategoryCardProps extends ILicensePlatesTableProps {
  category: Category;
  data: ILicensePlatesRow[];
  opened: boolean;
  isUnknownCategory: boolean;
}

const PlateCategoryCard: FC<IPlateCategoryCardProps> = ({
  category,
  data,
  opened,
  isUnknownCategory,
}) => {
  const theme = useTheme();

  const licensePlatesSearchVariable = useLicensePlatesTableVariables();

  const [expanded, setExpanded] = useState(false);

  const {
    open: openCreateCategoryDialog,
    handleDialogOpen,
    handleDialogClose,
  } = useDialog();

  useEffect(() => {
    if (opened !== expanded) {
      setExpanded(opened);
    }
  }, [opened, category]);

  const toggleAccordion = () => {
    if (!data.length) return;

    setExpanded(prev => !prev);
  };

  const queryParams = new URLSearchParams({
    categoryId: category?.id,
  }).toString();

  const { getNavigationRoute } = usePlateManagementNavigationRoute();

  const route = getNavigationRoute(
    RouteEnum.RecentActivitiesPlatesManagementEditCategory,
    RouteEnum.SensoryRecentActivitiesPlatesManagementEditCategory
  );
  const editCategoryLink = `${route}?${queryParams}`;

  return (
    <>
      <AddPlateToCategoryDialog
        isOpened={openCreateCategoryDialog}
        closeDialog={handleDialogClose}
        categoryName={category.categoryName}
        categoryId={category.id}
      />
      <Paper
        elevation={0}
        sx={{
          border: `1px solid ${theme.palette.otherOutlineBorder.main}`,
          padding: "0.75em 1.5em",
          borderRadius: "12px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {!expanded &&
            (data.length > 0 ? (
              <KeyboardArrowRightRoundedIcon
                onClick={toggleAccordion}
                sx={{ cursor: "pointer" }}
              />
            ) : (
              <Tooltip title="There is no license plate added">
                <KeyboardArrowRightRoundedIcon
                  onClick={toggleAccordion}
                  sx={{
                    color: theme => theme.palette.otherStandardInputLine.main,
                  }}
                />
              </Tooltip>
            ))}
          {expanded && (
            <KeyboardArrowUpRoundedIcon
              onClick={toggleAccordion}
              sx={{ cursor: "pointer" }}
            />
          )}

          <Box
            onClick={toggleAccordion}
            sx={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              flex: 1,
              gap: "0.25em",
            }}
          >
            <Typography variant="body2Bold">
              <Highlighter
                searchWords={[licensePlatesSearchVariable.filterValue]}
                autoEscape
                textToHighlight={category.categoryName}
              />
            </Typography>
            <Typography variant="inputLabel" color="textTertiary.main">
              ({data?.length} plates)
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              ml: "auto",
              cursor: "pointer",
              pointerEvents: "auto",
            }}
          >
            {!isUnknownCategory && (
              <Box sx={{ marginRight: "0.5em" }}>
                <Tooltip
                  TransitionComponent={Zoom}
                  title={"Add plate number to this category"}
                >
                  <span>
                    <AddIconButton onClick={handleDialogOpen} />
                  </span>
                </Tooltip>
              </Box>
            )}
            {!isUnknownCategory && (
              <RouterLink sx={{ marginRight: "0.5em" }} to={editCategoryLink}>
                <Tooltip TransitionComponent={Zoom} title={"Edit"}>
                  <span>
                    <EditIconButton />
                  </span>
                </Tooltip>
              </RouterLink>
            )}
            {!isUnknownCategory && (
              <DeleteCategoryButton
                category={category}
                disabled={data.length > 0}
              />
            )}
            <CategoryAlertingSwitch category={category} />
          </Box>
        </Box>

        <Collapse in={expanded}>
          {data.length > 0 && (
            <Box sx={{ mt: "1em" }}>
              <LicensePlatesTable data={data} />
            </Box>
          )}
        </Collapse>
      </Paper>
    </>
  );
};

export default PlateCategoryCard;
