import Chip, { ChipProps } from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import { CSSObject, styled } from "@mui/material/styles";

const StyledFilledChip = styled(Chip)(
  ({ theme }): CSSObject => ({
    "&.MuiChip-root": {
      color: theme.palette.warning.main,
      fontSize: ".9rem",
      fontWeight: 500,
      borderRadius: "10px",
      backgroundColor: theme.palette.warning.shades?.["4p"],
      border: `1px solid ${theme.palette.warning.shades?.["12p"]}`,
      width: "400px",
      maxWidth: "100%",

      padding: "4px 8px",
      "& .MuiChip-label": {
        padding: "0",
      },
    },
  })
);

const ChipFilledWarning = ({ ...props }: ChipProps): JSX.Element => {
  return (
    <StyledFilledChip
      label={<Typography variant="tag">{props.label}</Typography>}
      {...props}
    />
  );
};

export default ChipFilledWarning;
