import { CSSProperties, ReactNode, useEffect } from "react";

import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

import { TDeviceConfig } from "../../variables/devices";

interface Entry {
  value: boolean;
  display: ReactNode;
}

interface DeviceDynamicRadioButtonInputProps {
  label: string;
  field: string;
  entries: Entry[] | null | undefined;
  deviceConfig: TDeviceConfig;
  disabled?: boolean;
  setDeviceConfig: (field: string, value: boolean) => void;
}

const boldLabelStyle: CSSProperties = {
  fontWeight: "bold",
};

const DeviceDynamicRadioButtonInput = ({
  label,
  field,
  entries,
  deviceConfig,
  setDeviceConfig,
  disabled,
}: DeviceDynamicRadioButtonInputProps): JSX.Element | null => {
  const handleEntryChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const booleanValue = e.target.value === "true";

    setDeviceConfig(field, booleanValue);
  };

  useEffect((): void => {
    const value = deviceConfig[field];

    const isValueExist = entries?.some(entry => entry.value === value);

    if (!isValueExist && entries && entries.length) {
      setDeviceConfig(field, entries[0]?.value ?? false);
    }
  }, []);

  const value = deviceConfig[field];

  return (
    <div>
      <FormControl component="fieldset">
        <FormLabel component="legend" style={boldLabelStyle}>
          {label}
        </FormLabel>
        <RadioGroup name={label} value={value} onChange={handleEntryChange}>
          {entries &&
            entries.map(
              (entry, index): JSX.Element => (
                <FormControlLabel
                  key={index}
                  value={entry.value}
                  control={<Radio />}
                  label={entry.display}
                  disabled={disabled}
                />
              )
            )}
        </RadioGroup>
      </FormControl>
    </div>
  );
};

export default DeviceDynamicRadioButtonInput;
