import { ReactNode } from "react";

import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
// eslint-disable-next-line no-restricted-imports
import { type CSSProperties } from "@mui/material/styles/createMixins";

const StyledDatePicker = styled(Box)(
  ({ theme }): CSSProperties => ({
    "& .react-datepicker": {
      fontFamily: theme.typography.fontFamily,
      border: "0",
      backgroundColor: theme.palette.otherBackground.main,
      borderRadius: "12px",
      boxShadow: `0 4px 19px 0 #0716271A`,
    },

    "& .react-datepicker__current-month": {
      color: theme.palette.text.primary,
    },

    "& .react-datepicker__navigation-icon::before": {
      borderWidth: "1.5px 1.5px 0 0",
    },
    "& .react-datepicker__triangle": {
      display: "none",
    },
    "& .react-datepicker__week": {
      display: "flex",
      marginBottom: "2px",
    },
    "& .react-datepicker-popper": {
      zIndex: 7,
    },
    "& .react-datepicker__header": {
      backgroundColor: theme.palette.otherBackground.main,
      border: "none",
    },
    "& .react-datepicker-wrapper": {
      position: "relative",
      width: "100%",
    },
    "& .react-datepicker__day": {
      display: "flex",
      justifyContent: "center",
      color: theme.palette.text.primary,
      alignItems: "center",
      width: "34px",
      height: "34px",
      margin: "0",
      padding: "4px",
      "&:hover": {
        borderRadius: "0",
        backgroundColor: theme.palette.primary.main,
      },
      "&--keyboard-selected": {
        backgroundColor: theme.palette.otherBackground.main,
      },
      "&--today:hover": {
        color: theme.palette.text.primary,
      },
      "&--outside-month": {
        color: theme.palette.text.secondary,
      },
      "&--in-range": {
        borderRadius: "0",
        backgroundColor: theme.palette.primary.shades?.["12p"],
        color: theme.palette.text.primary,
        "&:hover": {
          borderRadius: "0",
          backgroundColor: theme.palette.primary.main,
        },
        "& .react-datepicker__day--today": {
          color: theme.palette.primary.main,
        },
      },
      "&--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range)":
        {
          borderRadius: "0",
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.text.primary,
        },
      "&--selected": {
        backgroundColor: theme.palette.primary.main,
      },
      "&--range-start": {
        borderRadius: "50% 0 0 50%",
        backgroundColor: theme.palette.primary.main,
      },
      "&--range-end": {
        borderRadius: "0 50% 50% 0",
        backgroundColor: theme.palette.primary.main,
      },
      "&--range-start.react-datepicker__day--range-end": {
        borderRadius: "0",
      },
    },
    "& .react-datepicker__day--disabled": {
      color: theme.palette.text.disabled,
    },
    "& .react-datepicker__day--disabled:hover": {
      backgroundColor: "inherit",
    },
    "& .react-datepicker__input-container input": {
      outline: "none",
      width: "100%",
      height: "40px",
      border: `1px solid ${theme.palette.otherStandardInputLine.main}`,
      borderRadius: "4px",
      backgroundColor: "inherit",
      padding: "0 12px 0 40px",
      fontSize: theme.typography.inputText.fontSize,
      "&:focus": {
        border: `1px solid ${theme.palette.primary.main}`,
      },
      "&:hover": {
        borderColor: theme.palette.primary.main,
      },
    },
    "& .react-datepicker__view-calendar-icon svg": {
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      left: "10px",
    },
    "& .react-datepicker__day-name": {
      fontSize: theme.typography.caption.fontSize,
      color: theme.palette.text.secondary,
    },
    "& .react-datepicker__day--today": {
      color: theme.palette.primary.main,
    },
    "& .react-datepicker__day--in-range.react-datepicker__day--today ": {
      color: theme.palette.text.primary,
    },
    "& .react-datepicker__close-icon:after": {
      backgroundColor: theme.palette.primary.main,
    },
  })
);

const StyledDatePickerWrapper = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  return <StyledDatePicker>{children}</StyledDatePicker>;
};

export default StyledDatePickerWrapper;
