import { GridPaginationModel } from "@mui/x-data-grid";

/**
 * Get row number based element index and offset calculated using page and pageSize
 * @param {GridPaginationModel} paginationModel contains values for page and pageSize
 * @param {number} index of the row
 * @return {number} row index
 */
export const getRowIndex = (
  paginationModel: GridPaginationModel,
  index: number
) => {
  const offset = paginationModel.page * paginationModel.pageSize;

  return paginationModel.page === 0 ? index + 1 : index + offset + 1;
};
