interface DynamicQueryDropdownInputProps {
  name: string;
  itemToQuery: string | null | undefined;
  serviceConfig: any;
  setServiceConfig: (serviceConfigValue: any) => void;
}

const DynamicQueryDropdownInput = ({
  // eslint-disable-next-line
  name,
  // eslint-disable-next-line
  itemToQuery,
  // eslint-disable-next-line
  serviceConfig,
  // eslint-disable-next-line
  setServiceConfig,
}: DynamicQueryDropdownInputProps): JSX.Element | null => {
  //   const handleEntryChange = (e: {
  //     target: { value: React.SetStateAction<string> };
  //   }) => {
  //     setServiceConfig(e.target.value);
  //   };
  //   if (!itemToQuery) return null;
  return null;
  // TODO: PE-502 DYNAMIC_DROPDOWN field type
  //   return (
  //     <Grid item>
  //       <InputLabel id="label">{name}</InputLabel>
  //       <Select
  //         fullWidth
  //         id={name + "-id"}
  //         labelId="label"
  //         value={serviceConfig[name]}
  //         onChange={(e) =>
  //           setServiceConfig((prevServiceConfig: any) => {
  //             console.log(prevServiceConfig);
  //             return {
  //               ...prevServiceConfig,
  //               [name]: e.target.value,
  //             };
  //           })
  //         }
  //         onChange={handleEntryChange}
  //       >
  //         {entries.map((entry: string) => (
  //               <option key={entry} value={entry}>
  //                 {entry}
  //               </option>
  //             ))}
  //       </Select>
  //     </Grid>
  //   );
};

export default DynamicQueryDropdownInput;
