import { AutocompleteOptionType } from "../../../../../../common/models/autocomplete";
import {
  useSelectedDataHubCategory,
  useSelectedDataHubCompany,
  useSelectedDataHubLicensePlate,
  useSelectedDataHubLocation,
  useSelectedDataHubTagLocations,
} from "../../../dataHub.variable";
import { MappedDataItem } from "./useGetEventsTableRows";

interface IUseEventsFilters {
  locationVariable: AutocompleteOptionType;
  tagLocationsVariable: AutocompleteOptionType[] | null;
  companyVariable: AutocompleteOptionType;
  categoryVariable: AutocompleteOptionType;
  licensePlateVariable: AutocompleteOptionType;
  applyFiltersForEventModel: <T extends MappedDataItem>(eventModel: T[]) => T[];
}

export const useEventsFilters = (): IUseEventsFilters => {
  const locationVariable = useSelectedDataHubLocation();
  const locationId = locationVariable?.value ?? "";

  const tagLocationsVariable = useSelectedDataHubTagLocations();

  const companyVariable = useSelectedDataHubCompany();
  const companyName = companyVariable?.value ?? "";

  const categoryVariable = useSelectedDataHubCategory();
  const categoryName = categoryVariable?.title ?? "";

  const licensePlateVariable = useSelectedDataHubLicensePlate();
  const licensePlateValue = licensePlateVariable?.value ?? "";

  const applyFiltersForEventModel = <T extends MappedDataItem>(
    eventModel: T[]
  ): T[] => {
    const result = [...eventModel].filter((item: T) => {
      if (
        !locationId &&
        !tagLocationsVariable &&
        !companyName &&
        !licensePlateValue &&
        !categoryName
      )
        return true;

      const nameOfLocation = item.location.includes("#")
        ? locationVariable?.value ?? ""
        : locationVariable?.title ?? "";

      const locations = tagLocationsVariable?.map(
        (tag: AutocompleteOptionType) => {
          if (item.location.includes("#L#")) {
            return tag?.value?.toLowerCase() ?? "";
          }

          return tag?.title?.toLowerCase() ?? "";
        }
      );

      const locationMatch =
        item.location.toLowerCase() === nameOfLocation.toLowerCase() ||
        !nameOfLocation;

      const tagLocationsMatch =
        (locations && locations.includes(item.location.toLowerCase())) ||
        !tagLocationsVariable;

      const licensePlateMatch =
        item.licensePlate === licensePlateValue || !licensePlateValue;

      const companyNameMatch =
        item.company_name === companyName || !companyName;

      const categoryNameMatch =
        item.category_name === categoryName || !categoryName;

      return (
        locationMatch &&
        tagLocationsMatch &&
        licensePlateMatch &&
        companyNameMatch &&
        categoryNameMatch
      );
    });

    return result.map((item, index) => ({
      ...item,
      rowIndex: index + 1,
    }));
  };

  return {
    locationVariable,
    tagLocationsVariable,
    companyVariable,
    categoryVariable,
    licensePlateVariable,
    applyFiltersForEventModel,
  };
};
