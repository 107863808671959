import React, { useEffect, useState } from "react";

import Tooltip from "@mui/material/Tooltip";

import { Zone } from "../../../API";
import { useUpdateZone } from "../../../pages/devices/zones-setup-page/hooks/useUpdateZone";
import StatusToggle from "../item/StatusToggle";

type ZoneAlertToggleProps = {
  params: Zone;
};

const ZoneAlertToggle: React.FC<ZoneAlertToggleProps> = ({ params }) => {
  const [shouldNotify, setShouldNotify] = useState(params.shouldNotify ?? true);

  useEffect(() => {
    setShouldNotify(params.shouldNotify ?? true);
  }, [params.shouldNotify, params.status]);

  const { updateZone } = useUpdateZone();

  const onChangeStatusHandler = () => {
    const input = {
      deviceId: params.deviceId,
      inspectTime: params.inspectTime,
      name: params.name,
      pan: params.pan,
      serviceId: params.serviceId,
      status: params.status,
      tilt: params.tilt,
      zoneId: params.id,
      zoom: params.zoom,
      shouldNotify: !shouldNotify,
    };

    updateZone(input);

    setShouldNotify(!shouldNotify);
  };

  const isDisabled = !params.status;

  return (
    <Tooltip
      placement="top"
      title={
        isDisabled
          ? "Please enable zone to update alert status"
          : shouldNotify
          ? "Switch off alerts for all users"
          : "Switch on alerts for all users"
      }
    >
      <div>
        <StatusToggle
          checked={shouldNotify}
          handleChange={onChangeStatusHandler}
          disabled={isDisabled}
        />
      </div>
    </Tooltip>
  );
};

export default ZoneAlertToggle;
