import { ApolloError, gql, useMutation } from "@apollo/client";

import type {
  AddCategoryInput,
  BatchAddCategoryMutation,
  BatchAddCategoryMutationVariables,
} from "../../../../../API";
import { BATCH_ADD_CATEGORY } from "../../../../../common/operations/mutations";
import { errorNotification } from "../../../../../common/variables/notification";

export const useCreateBatchCategory = () => {
  const [saveBatchCategory, { data, loading }] = useMutation<
    BatchAddCategoryMutation,
    BatchAddCategoryMutationVariables
  >(BATCH_ADD_CATEGORY);

  const batchCreateCategory = async (input: AddCategoryInput[]) => {
    return await saveBatchCategory({
      variables: {
        input,
      },
      awaitRefetchQueries: true,
      onError: (error: ApolloError): void => {
        errorNotification(error.message);

        console.error(error.message);
      },
      update: async (cache, response) => {
        cache.modify({
          fields: {
            getCategoriesByCustomer(existing) {
              const newCategoriesRefs =
                response?.data?.batchAddCategory?.map(lp => {
                  return cache.writeFragment({
                    data: lp,
                    fragment: gql`
                      fragment newCategory on Category {
                        id
                        categoryName
                        isAlertEnabled
                      }
                    `,
                  });
                }) ?? [];

              if (!existing) {
                return response?.data;
              }

              return {
                ...existing,
                items: [...existing.items, ...newCategoriesRefs],
              };
            },
          },
        });
      },
    });
  };

  return {
    batchCreateCategory,
    data,
    loading,
  };
};
