import React from "react";

import { Box, Paper, Typography } from "@mui/material";

import CircularLoading from "../../../common/components/progress/CircularLoading";
import { useSelectedLocationDropdown } from "../../../common/variables/selectedLocationDropdown";
import CreateDeviceDialogContainer from "./CreateDeviceDialogContainer";

interface INodesTableNoRowsOverlayProps {
  loading: boolean;
}

/* TODO: Refactoring of DevicesTableNoRowsOverlay
    1. Implement reusable component
    2. Rewrite CreateDeviceDialogContainer to redirect button
    3. Rewrite CircularLoading, move it over Paper
*/

const DevicesTableNoRowsOverlayComponent = ({
  loading,
}: INodesTableNoRowsOverlayProps): JSX.Element => {
  const selectedLocation = useSelectedLocationDropdown();

  return (
    <Paper
      sx={{
        height: "240px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        {loading ? (
          <CircularLoading />
        ) : (
          <>
            <Typography variant="h4">
              There is no devices
              {selectedLocation?.title ? ` in ${selectedLocation?.title}` : ""}
            </Typography>
            <Typography
              style={{ marginBottom: 24 }}
              variant="body1Regular"
              color="text.secondary"
            >
              Add devices to manage them
            </Typography>

            <CreateDeviceDialogContainer overlay />
          </>
        )}
      </Box>
    </Paper>
  );
};

const DevicesTableNoRowsOverlay = React.memo(
  DevicesTableNoRowsOverlayComponent
);

export default DevicesTableNoRowsOverlay;
