import React from "react";

import PhotoCameraOutlinedIcon from "@mui/icons-material/PhotoCameraOutlined";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { IDeviceTableRow } from "../../../pages/devices/hooks/useDeviceTableRows";
import { Stack, Tooltip } from "@mui/material";
import { ViewMode, ViewModeEnum } from "../live-view/LiveViewContainer";
import { captureKeyframe } from "../../helpers/captureKeyframe";
import { usePublishNode } from "../../../pages/devices/hooks/usePublishNode";
import { captureKeyframeForAllZones } from "../../helpers/captureKeyframeForAllZones";

interface ZoneHeaderProps {
  device: IDeviceTableRow;
  hasPanTilt?: boolean;
  currentViewMode?: ViewMode;
  title: string;
  onAddZoneClick?: () => void;
}

const ZoneHeader: React.FC<ZoneHeaderProps> = ({
  device,
  hasPanTilt,
  currentViewMode,
  title,
  onAddZoneClick,
}): JSX.Element => {
  const theme = useTheme();
  const { publishNode } = usePublishNode();

  const tooltipTitle =
    "The model will be stopped while key images are capturing one by one. After new images are captured, the model will be restarted";

  const isCaptureKeyFramesDisabled =
    !device?.isOnline ||
    !device?.deviceData.recentFrame ||
    currentViewMode === ViewModeEnum.LIVE;

  const handleCaptureKeyframe = (): void => {
    if (device) {
      captureKeyframe(device, publishNode);
    }
  };

  const handleCaptureKeyframeForAllZones = (): void => {
    if (device) {
      captureKeyframeForAllZones(device, publishNode);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Typography variant="h5">{title}</Typography>
      </Box>

      <Stack
        direction="row"
        spacing={2}
        sx={{
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <Tooltip title={tooltipTitle} placement="top">
          <Box sx={{ marginRight: "0.5em" }}>
            {hasPanTilt ? (
              <Button
                disabled={isCaptureKeyFramesDisabled}
                variant="text"
                size="small"
                startIcon={<PhotoCameraOutlinedIcon />}
                color="inherit"
                onClick={handleCaptureKeyframeForAllZones}
                sx={{
                  "&.Mui-disabled": {
                    backgroundColor: "transparent",
                    color: theme.palette.text.disabled,
                  },
                  ":hover": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                Capture new key images for all zones
              </Button>
            ) : (
              <Button
                disabled={isCaptureKeyFramesDisabled}
                variant="text"
                size="small"
                startIcon={<PhotoCameraOutlinedIcon />}
                color="inherit"
                onClick={handleCaptureKeyframe}
                sx={{
                  "&.Mui-disabled": {
                    backgroundColor: "transparent",
                    color: theme.palette.text.disabled,
                  },
                  ":hover": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                Capture a new key image
              </Button>
            )}
          </Box>
        </Tooltip>

        {onAddZoneClick && (
          <Box sx={{ display: "flex" }}>
            <Button
              variant="outlined"
              size="small"
              onClick={onAddZoneClick}
              startIcon={<AddOutlinedIcon />}
            >
              New zone
            </Button>
          </Box>
        )}
      </Stack>
    </Box>
  );
};

export default ZoneHeader;
