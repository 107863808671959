import { useTheme } from "@mui/material/styles";

const LiveViewMenuIcon = (): JSX.Element => {
  const theme = useTheme();

  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.66602 15.5834C7.66602 12.8438 7.66602 11.474 8.42265 10.5521C8.56116 10.3833 8.71592 10.2285 8.8847 10.09C9.80666 9.33337 11.1764 9.33337 13.916 9.33337C16.6556 9.33337 18.0254 9.33337 18.9473 10.09C19.1161 10.2285 19.2709 10.3833 19.4094 10.5521C20.166 11.474 20.166 12.8438 20.166 15.5834V16.4167C20.166 19.1563 20.166 20.5261 19.4094 21.448C19.2709 21.6168 19.1161 21.7716 18.9473 21.9101C18.0254 22.6667 16.6556 22.6667 13.916 22.6667C11.1764 22.6667 9.80666 22.6667 8.8847 21.9101C8.71592 21.7716 8.56116 21.6168 8.42265 21.448C7.66602 20.5261 7.66602 19.1563 7.66602 16.4167V15.5834Z"
        stroke={theme.palette.text.primary}
        strokeWidth="1.5"
      />
      <path
        d="M20.166 13.9167L20.7146 13.6423C22.3362 12.8316 23.147 12.4262 23.7398 12.7926C24.3327 13.159 24.3327 14.0654 24.3327 15.8784V16.1216C24.3327 17.9346 24.3327 18.841 23.7398 19.2074C23.147 19.5738 22.3362 19.1684 20.7146 18.3576L20.166 18.0833V13.9167Z"
        stroke={theme.palette.text.primary}
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default LiveViewMenuIcon;
