import { Chip, useTheme } from "@mui/material";
import React from "react";

type TagChipProps = {
  tag: string;
};

const TagChip: React.FC<TagChipProps> = ({ tag }) => {
  const theme = useTheme();

  return (
    <Chip
      sx={{
        height: "24px",
        borderRadius: "34px",
        border: `1px solid ${theme.palette.otherOutlineBorder.main}`,
        backgroundColor: theme.palette.otherBackground.main,
      }}
      label={`#${tag}`}
    />
  );
};

export default TagChip;
