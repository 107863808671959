import { useTheme } from "@mui/material";

const TlmDataHubIcon = (): JSX.Element => {
  const theme = useTheme();

  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="40"
        height="40"
        rx="8"
        fill={theme.palette.otherBackground.main}
      />
      <path
        d="M12 26V14"
        stroke={theme.palette.text.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M28 14V26"
        stroke={theme.palette.text.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M20 18C24.4183 18 28 16.2091 28 14C28 11.7909 24.4183 10 20 10C15.5817 10 12 11.7909 12 14C12 16.2091 15.5817 18 20 18Z"
        stroke={theme.palette.text.primary}
        strokeWidth="1.5"
      />
      <path
        d="M28 20C28 22.2091 24.4183 24 20 24C15.5817 24 12 22.2091 12 20"
        stroke={theme.palette.text.primary}
        strokeWidth="1.5"
      />
      <path
        d="M28 26C28 28.2091 24.4183 30 20 30C15.5817 30 12 28.2091 12 26"
        stroke={theme.palette.text.primary}
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default TlmDataHubIcon;
