import React from "react";
import { Link, useNavigate } from "react-router-dom";

import { Toolbar, Box, Stack, Divider, IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";

import { RouteEnum } from "../../models/enums";
import UserSettingsPopover from "./components/UserSettingsPopover";

import LogoLight from "./logo/LogoLight";
import LogoDark from "./logo/LogoDark";
import ApplicationSelect from "./components/ApplicationSelect";
import CustomerSelect from "./components/CustomerSelect";
import { UserMenu } from "./components/UserMenu";

import { AppBarCommon } from "./AppBarCommon";
import { TOP_BAR_HEIGHT } from "./MenuItem";

interface CommonTopBarProps {
  showLeakFinderSettings?: boolean;
  showCustomerSelect?: boolean;
}

function CommonTopBar({
  showCustomerSelect = false,
  showLeakFinderSettings = false,
}: CommonTopBarProps) {
  const theme = useTheme();

  const navigate = useNavigate();

  const onNavigate = (path: string) => {
    navigate(path);
  };

  return (
    <AppBarCommon>
      <Toolbar sx={{ minHeight: TOP_BAR_HEIGHT, display: "flex", gap: "1em" }}>
        <ApplicationSelect />

        <Link to={RouteEnum.Home}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {theme.palette.mode === "light" ? <LogoLight /> : <LogoDark />}
          </Box>
        </Link>

        {showCustomerSelect && (
          <Box sx={{ marginLeft: "1.5em" }}>
            <CustomerSelect size="small" />
          </Box>
        )}

        <Box sx={{ marginLeft: "auto", display: "flex" }}>
          <Stack spacing={2} direction="row">
            <UserSettingsPopover />
          </Stack>

          {showLeakFinderSettings && (
            <Box
              sx={{
                display: "flex",
                "& .MuiDivider-root": {
                  borderRightWidth: "2px",
                  margin: "8px 15px 0",
                  height: "24px",
                },
              }}
            >
              <Divider orientation="vertical" flexItem />

              <IconButton
                onClick={() => onNavigate(RouteEnum.LeakFinderUserPreferences)}
              >
                <SettingsOutlinedIcon
                  sx={{
                    color: theme => theme.palette.text.secondary,
                  }}
                />
              </IconButton>

              <Divider orientation="vertical" flexItem />
            </Box>
          )}
        </Box>

        <Box sx={{ display: "flex", gap: 4, alignItems: "center" }}>
          <UserMenu />
        </Box>
      </Toolbar>
    </AppBarCommon>
  );
}

export default React.memo(CommonTopBar);
