import { ReactElement } from "react";

import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import {
  GridActionsCellItem,
  GridActionsCellItemProps,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import Highlighter from "react-highlight-words";

import ChipFilledDefault from "../../../../../common/components/chip/ChipFilledDefault";
import NotificationsIconButton from "../../../../../common/components/icons/NotificationsIconButton";
import TableCellTruncated from "../../../../../common/components/table/TableCellTruncated";
import { useTestSendEmail } from "../../../../../common/hooks/useTestSendEmail";
import { successNotification } from "../../../../../common/variables/notification";
import { AlertManagementTabsEnum } from "../../../../../common/variables/tabsSettings";
import ToggleAlertNotification from "../components/actions/ToggleAlertNotification";
import DeleteAlertContainer from "../components/DeleteAlertContainer";
import { AlertTypeEnum } from "../variables/alerts";
import { IAlertsTableRow } from "./useAlertsTableRow";
import { useLazyGetPaginatedLocations } from "../../locations/hooks/useLazyGetPaginatedLocations";
import { useLazyGetPaginatedAllTagsByCustomer } from "../../../../system-settings/tags/hooks/useLazyGetPaginatedAllTagsByCustomer";

function extractLocationNameFromId(id: string): string {
  const names: string[] = id.split("#");
  const valueName: string = names[names.length - 1];

  return valueName;
}

function getLocationColumn(): GridColDef<IAlertsTableRow> {
  return {
    field: "location",
    headerName: "Location",
    flex: 1,
    minWidth: 180,
    maxWidth: 300,
    hideable: false,
    disableColumnMenu: true,
    renderCell: (
      params: GridRenderCellParams<IAlertsTableRow>
    ): JSX.Element | null => {
      if (!params) {
        return null;
      }

      const { locations } = useLazyGetPaginatedLocations("cache-only");
      const { tags } = useLazyGetPaginatedAllTagsByCustomer("cache-only");

      const location = params?.row?.location;
      const locationFromCache = locations.find(l => l.id === location);

      if (!locationFromCache) {
        return null;
      }

      const tag = params?.row?.tag;
      const tagFromCache = tags.find(t => t.id === tag);

      if (!locationFromCache) {
        return null;
      }

      const tagOrLocation = tagFromCache
        ? `#${tagFromCache.tagName}`
        : locationFromCache.name;

      if (tagFromCache) {
        return <ChipFilledDefault label={tagOrLocation} />;
      }

      return (
        <TableCellTruncated
          tooltipText={tagOrLocation}
          text={tagOrLocation}
          TypographyProps={{
            color: theme =>
              params?.row.notificationsDiasbled
                ? theme.palette.otherTextTertiary.main
                : theme.palette.text.primary,
          }}
        />
      );
    },
  };
}

export const useAlertsTableColumns = (
  isAdminAccess: boolean,
  selectedTab?: AlertManagementTabsEnum,
  searchValue?: string
): { columns: GridColDef[] } => {
  const { fetchSendEmail } = useTestSendEmail();

  const handleClick = (email: string): void => {
    fetchSendEmail(email);

    successNotification("Test Alert Sent!");
  };

  // TODO: Implement navigate to edit alert pageO
  // const handleEdit = (row: IAlertsTableRow): void => {
  //   console.log(row);
  // };

  const columns: GridColDef<IAlertsTableRow>[] = [
    {
      field: "index",
      headerName: "#",
      hideable: false,
      maxWidth: 60,
      disableColumnMenu: true,
      sortComparator: (index1, index2): number =>
        Number(index1) - Number(index2),
      renderCell: (params): JSX.Element | null =>
        params ? (
          <TableCellTruncated
            tooltipText={""}
            text={params?.row.index}
            TypographyProps={{
              color: theme =>
                params?.row.notificationsDiasbled
                  ? theme.palette.otherTextTertiary.main
                  : theme.palette.text.primary,
            }}
          />
        ) : null,
    },
    {
      field: "alertName",
      headerName: "Alert Name",
      minWidth: 300,
      flex: 1,
      hideable: false,
      disableColumnMenu: true,
      renderCell: (params): JSX.Element | null =>
        params ? (
          <TableCellTruncated
            tooltipText={params?.row.alertName}
            text={params?.row.alertName}
            TypographyProps={{
              color: theme =>
                params?.row.notificationsDiasbled
                  ? theme.palette.otherTextTertiary.main
                  : theme.palette.text.primary,
            }}
          >
            <Highlighter
              searchWords={[searchValue ?? ""]}
              autoEscape
              textToHighlight={params?.row.alertName}
            />
          </TableCellTruncated>
        ) : null,
    },
    {
      field: "recipient",
      headerName: "Recipient",
      minWidth: 360,
      flex: 1,
      hideable: false,
      disableColumnMenu: true,
      renderCell: (params): JSX.Element | null => {
        const recipient = params?.row.recipient;

        const isEmailAlertType = params?.row.alertType === AlertTypeEnum.EMAIL;

        const alertRecipient = isEmailAlertType
          ? recipient?.email
          : recipient?.phoneNumber;

        return params ? (
          <TableCellTruncated
            tooltipText={alertRecipient}
            text={alertRecipient}
            TypographyProps={{
              color: theme =>
                params?.row.notificationsDiasbled
                  ? theme.palette.otherTextTertiary.main
                  : theme.palette.text.primary,
            }}
          />
        ) : null;
      },
    },
    {
      field: "notificationSettings",
      headerName: "Alert",
      width: 80,
      hideable: false,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params): JSX.Element | null => {
        const alertType = params?.row.alertType;

        const isEmailAlert = alertType === AlertTypeEnum.EMAIL;

        const isSmsAlert = alertType === AlertTypeEnum.SMS;

        const emailAlertStatus =
          params?.row.notificationSettings?.isEmailEnabled;

        const smsAlertStatus = params?.row.notificationSettings?.isSMSEnabled;

        return params ? (
          <Box>
            {isEmailAlert && (
              <ToggleAlertNotification
                row={params.row}
                alertType={AlertTypeEnum.EMAIL}
                notificationSettingsField="isEmailEnabled"
                status={emailAlertStatus}
                isAdminAccess={isAdminAccess}
                disabled={params.row.alertType === AlertTypeEnum.SMS}
              />
            )}
            {isSmsAlert && (
              <ToggleAlertNotification
                row={params.row}
                alertType={AlertTypeEnum.SMS}
                notificationSettingsField="isSMSEnabled"
                status={smsAlertStatus}
                isAdminAccess={isAdminAccess}
                disabled={!params.row.phoneNumber}
              />
            )}
          </Box>
        ) : null;
      },
    },
    {
      field: "actions",
      type: "actions",
      minWidth: 160,
      getActions: (params): ReactElement<GridActionsCellItemProps>[] => [
        <GridActionsCellItem
          key={`test-alert-${params.row?.rowId}`}
          icon={
            <Tooltip
              TransitionComponent={Zoom}
              arrow
              disableInteractive
              placement="top"
              title="Test alert"
            >
              <Box
                component="span"
                sx={{
                  height: "32px",
                }}
              >
                <NotificationsIconButton
                  onClick={(): void => handleClick(params?.row.email)}
                />
              </Box>
            </Tooltip>
          }
          label="Test alert"
          className="actionButton"
          disableRipple
        />,
        // <GridActionsCellItem
        //   key={`edit-${params.row?.rowId}`}
        //   icon={<EditIconButton onClick={(): void => handleEdit(params.row)} />}
        //   label="Edit"
        //   className="actionButton"
        //   disableRipple
        // />,
        <GridActionsCellItem
          key={`delete-${params.row?.rowId}`}
          icon={<DeleteAlertContainer row={params.row} />}
          label="Delete"
          className="actionButton"
          disableRipple
        />,
      ],
    },
  ];

  if (selectedTab === AlertManagementTabsEnum.LOCATION) {
    const locationColumn = getLocationColumn();

    columns.splice(2, 0, locationColumn);
  }

  if (selectedTab === AlertManagementTabsEnum.MODEL) {
    const locationColumn = getLocationColumn();

    columns.splice(2, 0, locationColumn);

    columns.splice(3, 0, {
      field: "model",
      headerName: "Model",
      flex: 1,
      minWidth: 180,
      maxWidth: 220,
      hideable: false,
      disableColumnMenu: true,
      renderCell: (params): JSX.Element | null => {
        if (!params) {
          return null;
        }

        const model = params?.row?.model;
        const modelName: string = extractLocationNameFromId(model);

        return (
          <TableCellTruncated
            tooltipText={modelName}
            text={modelName}
            TypographyProps={{
              color: theme =>
                params?.row.notificationsDiasbled
                  ? theme.palette.otherTextTertiary.main
                  : theme.palette.text.primary,
            }}
          />
        );
      },
    });
  }

  return { columns };
};
