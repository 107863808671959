import React, { useState } from "react";

import DeviceModelChipDropdown from "../../../common/components/chip/DeviceModelChipDropdown";
import { IDeviceTableRow } from "../hooks/useDeviceTableRows";

type Props = {
  device: IDeviceTableRow;
};

const DeviceModelContainer: React.FC<Props> = ({ device }): JSX.Element => {
  const [deviceModels, setDeviceModels] = useState(device.models);

  const setModelsHundler = (modelId: string): void => {
    const filteredModels = deviceModels.filter(
      (model): boolean => model.serviceId !== modelId
    );

    setDeviceModels(filteredModels);
  };

  return (
    <>
      {deviceModels.map((model): JSX.Element => {
        return (
          <DeviceModelChipDropdown
            key={model.serviceId}
            onDeleteModelHandler={setModelsHundler}
            data={device}
            model={model}
          />
        );
      })}
    </>
  );
};

export default DeviceModelContainer;
