import { useEffect, useState } from "react";

import { useLazyQuery } from "@apollo/client";

import { GET_NODES } from "../../../common/operations/queries";
import { errorNotification } from "../../../common/variables/notification";
import { useSelectedLocationDropdown } from "../../../common/variables/selectedLocationDropdown";
import client from "../../../configs/apolloClient";
import type { GetNodesQuery, GetNodesQueryVariables } from "./../../../API";
import { DEFAULT_LIMIT_COUNT } from "../../../common/variables/common";

interface GetNodesDataInterface {
  data?: GetNodesQuery;
  loading: boolean;
}

export const NODES_TABLE_LIMIT = 10;

export const useGetNodes = (): GetNodesDataInterface => {
  const locationVariable = useSelectedLocationDropdown();

  const [isLoading, setIsLoading] = useState(true);

  useEffect((): void => {
    fetchNodes(locationVariable?.value ?? "");
  }, [locationVariable?.value]);

  const handleRequestError = async (): Promise<void> => {
    await client.cache.reset();

    errorNotification();
  };

  const [getNodes, { data, loading }] = useLazyQuery<
    GetNodesQuery,
    GetNodesQueryVariables
  >(GET_NODES, {
    fetchPolicy: "network-only",
  });

  const fetchNodes = (locationId: string, nextToken?: string): void => {
    getNodes({
      variables: {
        limit: DEFAULT_LIMIT_COUNT,
        locationId,
        nextToken,
      },
    })
      .then(async (response): Promise<void> => {
        if (response.data?.getNodes.nextToken) {
          fetchNodes(locationId, response.data?.getNodes.nextToken);

          setIsLoading(false);
        } else if (!response.data?.getNodes.nextToken) {
          setIsLoading(false);
        }

        if (
          (response.data?.getNodes?.items?.length ?? 0) > 0 &&
          !response.data?.getNodes.nextToken
        ) {
          setIsLoading(false);
        }

        if (
          (response.data?.getNodes?.items?.length ?? 0) > 0 &&
          !response.data?.getNodes.nextToken
        ) {
          setIsLoading(false);
        }

        if (response.error) {
          await handleRequestError();

          setIsLoading(false);
        }
      })
      .catch(async (): Promise<void> => {
        await handleRequestError();

        setIsLoading(false);
      });
  };

  return { data, loading: isLoading || loading };
};
