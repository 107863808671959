import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { Box, Stack, Typography } from "@mui/material";

import { allModelsOptions } from "../../../common/components/select/ServiceSelect/ModelsToRunMultiselect";
import {
  MAIN_LOCATIONS_DROPDOWN_STORAGE_KEY,
  setLocationToLocalStorage,
} from "../../../common/helpers/getLocationFromLocalStorage";
import { useAuthenticatedUser } from "../../../common/hooks/useAuthenticatedUser";
import { useGetCustomer } from "../../../common/hooks/useGetCustomer";
import { AutocompleteOptionType } from "../../../common/models/autocomplete";
import { RoleEnum } from "../../../common/models/enums";
import { selectedCustomerDataVariable } from "../../../common/variables/selectedCustomerData";
import { ServiceTypeEnum } from "../../model-manager/variables/modelManager";
import PlatesManagementButton from "../../system-settings/tabs/plates-management/components/PlatesManagementButton";
import DefaultFilters from "./DefaultFilters";
import EditPauseAlertsDialog from "./EditPauseAlertsDialog";
import GateGuardFilters from "./GateGuardFilters";
import PauseAlertsDialog from "./PauseAlertsDialog";
import {
  selectedDataHubCategoryVariable,
  selectedDataHubCompanyVariable,
  selectedDataHubLicensePlateVariable,
  selectedDataHubLocationTagVariable,
  selectedDataHubLocationVariable,
  useSelectedDataHubCategory,
  useSelectedDataHubCompany,
  useSelectedDataHubLicensePlate,
  useSelectedDataHubLocation,
} from "./dataHub.variable";
import useExportAggServerData from "./models/exports/useExportAggServerData";
import useExportFireSmokeHardhatData from "./models/exports/useExportFireSmokeHardhatData";
import useExportGasleakData from "./models/exports/useExportGasleakData";
import useExportLiquidleakData from "./models/exports/useExportLiquidleakData";
import useExportTlmData from "./models/exports/useExportTlmData";

const DataHubPageTitle = (): JSX.Element => {
  const { role } = useAuthenticatedUser();

  const { customer } = useGetCustomer();

  const { downloadGasleakData, loading: gasleakLoading } =
    useExportGasleakData();

  const { exportFireSmokeHardhatData, loading: fireSmokeHardhatLoading } =
    useExportFireSmokeHardhatData();

  const { exportLiquidleakData, loading: liquidleakLoading } =
    useExportLiquidleakData();

  const { exportTlmData, loading: tlmLoading } = useExportTlmData();

  const { exportAggData, loading: aggLoading } = useExportAggServerData();

  const loading =
    gasleakLoading ||
    fireSmokeHardhatLoading ||
    liquidleakLoading ||
    tlmLoading ||
    aggLoading;

  const showPlatesManagementButton = [
    RoleEnum.ROOT,
    RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
    RoleEnum.CUSTOMER_OPS_TEAM,
    RoleEnum.CUSTOMER_HSE,
    RoleEnum.CUSTOMER_SECURITY,
    RoleEnum.PARTNER_ADMIN,
    RoleEnum.CUSTOMER_ADMIN,
  ].includes(role);
  const { model } = useParams();

  const locationVariable = useSelectedDataHubLocation();

  const companyVariable = useSelectedDataHubCompany();

  const categoryVariable = useSelectedDataHubCategory();

  const licensePlateVariable = useSelectedDataHubLicensePlate();

  useEffect(() => {
    if (
      customer?.aggAlertingData &&
      customer?.allowLeakFinder &&
      customer?.domains &&
      customer?.id &&
      customer?.name &&
      customer?.totalLeakFinderLimit &&
      customer?.usageLeakFinderCount
    ) {
      selectedCustomerDataVariable(customer);
    }
  }, [customer]);

  const handleLocationChange = (
    locationValue: AutocompleteOptionType
  ): void => {
    selectedDataHubLocationVariable(locationValue);

    if (locationValue) {
      setLocationToLocalStorage(
        MAIN_LOCATIONS_DROPDOWN_STORAGE_KEY,
        locationValue
      );
    }
  };

  const handleCategoryChange = (
    categoryValue: AutocompleteOptionType
  ): void => {
    selectedDataHubCategoryVariable(categoryValue);
  };

  const handleLicensePlateChange = (
    licensePlateValue: AutocompleteOptionType
  ): void => {
    selectedDataHubLicensePlateVariable(licensePlateValue);
  };

  const handleCompanyChange = (companyValue: AutocompleteOptionType): void => {
    selectedDataHubCompanyVariable(companyValue);
  };

  const handleDownloadClick = async () => {
    let downloadFunction;

    if (model === "gasleak") {
      downloadFunction = downloadGasleakData;
    } else if (model === "fire" || model === "smoke" || model === "hardhat") {
      downloadFunction = () => exportFireSmokeHardhatData(model);
    } else if (model === "liquidleak") {
      downloadFunction = exportLiquidleakData;
    } else if (model === "tlm") {
      downloadFunction = exportTlmData;
    } else if (model === "agg") {
      downloadFunction = exportAggData;
    }

    if (downloadFunction) {
      await downloadFunction();
    }
  };

  const handleTagLocationsChange = (
    tagLocations: AutocompleteOptionType[] | null
  ): void => {
    selectedDataHubLocationTagVariable(tagLocations);
  };

  let pageTitle =
    allModelsOptions.find((item): boolean => item.value === model)?.name ??
    "Dashboard";

  if (pageTitle === ServiceTypeEnum.LEAK) {
    pageTitle = ServiceTypeEnum.GAS_LEAK;
  }

  return (
    <Stack direction="column" spacing={2}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={2}
      >
        <Typography variant="h3">{pageTitle + " events"}</Typography>
        {model === "agg" && (
          <Box>
            <Stack
              direction={"row"}
              justifyContent="center"
              alignItems="center"
            >
              {!customer?.aggAlertingData && <PauseAlertsDialog />}
              {customer?.aggAlertingData && <EditPauseAlertsDialog />}
              {showPlatesManagementButton && <PlatesManagementButton />}
            </Stack>
            {}
          </Box>
        )}
      </Stack>

      {model === "agg" && (
        <GateGuardFilters
          locationVariable={locationVariable}
          handleLocationChange={handleLocationChange}
          handleTagLocationsChange={handleTagLocationsChange}
          licensePlateVariable={licensePlateVariable}
          handleLicensePlateChange={handleLicensePlateChange}
          companyVariable={companyVariable}
          handleCompanyChange={handleCompanyChange}
          categoryVariable={categoryVariable}
          handleCategoryChange={handleCategoryChange}
          handleDownloadClick={handleDownloadClick}
          loading={loading}
        />
      )}

      {model !== "agg" && (
        <DefaultFilters
          locationVariable={locationVariable}
          handleLocationChange={handleLocationChange}
          handleTagLocationsChange={handleTagLocationsChange}
          handleDownloadClick={handleDownloadClick}
          loading={loading}
        />
      )}
    </Stack>
  );
};

export default DataHubPageTitle;
