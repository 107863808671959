import { ReactElement } from "react";
import { useNavigate } from "react-router-dom";

import { Grid, Stack, Typography } from "@mui/material";
import {
  GridActionsCellItem,
  GridActionsCellItemProps,
  GridColDef,
} from "@mui/x-data-grid";

import EditIconButton from "../../../../../common/components/icons/EditIconButton";
import ConnectedDevicesColumn from "../components/ConnectedDevicesColumn";
import DeleteLocationContainer from "../components/DeleteLocationContainer";
import { locationFormVariables } from "../variables/locationData";
import { LocationRowInterface } from "./useLocationsTableData";
import TagChip from "../../../../../common/components/chip/TagChip";
import { Tag } from "../../../../../API";
import { useAuthenticatedUser } from "../../../../../common/hooks/useAuthenticatedUser";
import { AdminRoles } from "../../../../../common/variables/tabsSettings";
import { RouteEnum } from "../../../../../common/models/enums";

export const useLocationsTableColumns = (): { columns: GridColDef[] } => {
  const navigate = useNavigate();

  const { role } = useAuthenticatedUser();

  const isItAdminRole = AdminRoles.includes(role);

  const handleEdit = (row: LocationRowInterface): void => {
    const editLocationLink = isItAdminRole
      ? `/manage-locations/edit/${row.rowId}`
      : `${RouteEnum.SensoryEditLocation}${row.rowId}`;

    locationFormVariables({
      locationName: row.name,
      latitude: row.coordinates.lat,
      longitude: row.coordinates.lon,
      referenceId: row.referenceId,
      tags: row.tags ?? [],
      rowId: row.rowId,
      timeZone: (row.timeZone as string) ?? null,
    });

    navigate(editLocationLink, {
      state: { existingRowName: row.name },
    });
  };

  const columns: GridColDef[] = [
    {
      field: "sequenceNumber",
      headerName: "#",
      flex: 0.1,
      minWidth: 50,
      hideable: false,
      disableColumnMenu: true,
      renderCell: (params): JSX.Element | null =>
        params ? (
          <Typography variant="body2">
            {parseInt(params.row.sequenceNumber)}
          </Typography>
        ) : null,
    },
    {
      field: "name",
      headerName: "LOCATION",
      flex: 0.4,
      minWidth: 200,
      hideable: false,
      disableColumnMenu: true,
      renderCell: (params): JSX.Element | null =>
        params ? (
          <Typography variant="body2Bold">{params?.row.name}</Typography>
        ) : null,
    },
    {
      field: "coordinates",
      headerName: "COORDINATES",
      flex: 0.5,
      minWidth: 140,
      hideable: false,
      disableColumnMenu: true,
      renderCell: (params): JSX.Element | null =>
        params ? (
          <Typography variant="body2">
            {`N: ${
              parseFloat(params?.row?.coordinates.lat).toFixed(4) ?? ""
            }, W: ${parseFloat(params?.row?.coordinates.lon).toFixed(4) ?? ""}`}
          </Typography>
        ) : null,
    },
    {
      field: "devices",
      headerName: "CONNECTED DEVICES",
      flex: 1,
      minWidth: 300,
      disableColumnMenu: true,
      hideable: false,
      sortable: false,
      renderCell: (params): JSX.Element => {
        return <ConnectedDevicesColumn params={{ ...params, isItAdminRole }} />;
      },
    },
    {
      field: "tags",
      headerName: "LOCATION TAG",
      flex: 0.5,
      minWidth: 180,
      hideable: false,
      disableColumnMenu: true,
      renderCell: (params): JSX.Element | null => {
        return (
          <Grid container>
            <Grid item xs={12} sx={{ paddingY: 2 }}>
              <Stack direction="row" sx={{ flexWrap: "wrap", gap: "0.7em" }}>
                {params.row.tags.map((tag: Tag) => {
                  return <TagChip tag={tag.tagName} key={tag.id} />;
                })}
              </Stack>
            </Grid>
          </Grid>
        );
      },
    },
    {
      field: "actions",
      type: "actions",
      width: 100,
      getActions: (params): ReactElement<GridActionsCellItemProps>[] => [
        <GridActionsCellItem
          key={`edit-${params.row?.rowId}`}
          icon={<EditIconButton onClick={(): void => handleEdit(params.row)} />}
          label="Edit"
          className="actionButton"
          disableRipple
        />,
        isItAdminRole ? (
          <GridActionsCellItem
            key={`delete-${params.row?.rowId}`}
            icon={
              <DeleteLocationContainer
                locationId={params.row?.rowId}
                name={params.row?.name}
                devices={params.row.devices}
              />
            }
            label="Delete"
            className="actionButton"
            disableRipple
          />
        ) : (
          <></>
        ),
      ],
    },
  ];

  const referenceIdField: GridColDef = {
    field: "referenceId",
    headerName: "REFERENCE ID",
    flex: 1,
    minWidth: 180,
    disableColumnMenu: true,
    hideable: false,
    sortable: false,
    renderCell: (params): JSX.Element | null => (
      <Typography variant="body2">{params?.row.referenceId}</Typography>
    ),
  };

  columns.splice(5, 0, referenceIdField);

  return { columns };
};
