import Typography from "@mui/material/Typography";

import RouterLink from "../../../../common/components/RouterLink";
import { RouteEnum } from "../../../../common/models/enums";
import HardHatDataHubIcon from "../icons/HardHatDataHubIcon";
import DataHubGridItem from "./DataHubGridItem";
import { IModelGridItemProps } from "./GasLeakGridItem";

const HardHatDataGridItem = ({
  nonAdmin,
}: IModelGridItemProps): JSX.Element => {
  return (
    <RouterLink to={nonAdmin ? RouteEnum.SensoryHardHat : RouteEnum.HardHat}>
      <DataHubGridItem>
        <HardHatDataHubIcon />
        <Typography variant="body2Bold">Hard Hat</Typography>
      </DataHubGridItem>
    </RouterLink>
  );
};

export default HardHatDataGridItem;
