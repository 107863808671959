import { Box, Typography } from "@mui/material";
import BreadcrumbNavigation from "../../../common/components/tabs/BreadcrumbNavigation";
import { useLocation } from "react-router-dom";

const EdgeIntegrationPage = (): JSX.Element => {
  const location = useLocation();

  const integrationPath = location.pathname.includes("sensory")
    ? "/sensory/system-settings/integrations"
    : "/system-settings/integrations";

  const breadcrumbItems = [
    { label: "Integrations", path: integrationPath },
    {
      label: "Edge",
    },
  ];

  return (
    <Box>
      <BreadcrumbNavigation items={breadcrumbItems} />

      <Box padding="0" margin="1em 0">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "1em",
          }}
        >
          <Typography variant="h3">Edge</Typography>
        </Box>
        <Box></Box>
      </Box>
    </Box>
  );
};

export default EdgeIntegrationPage;
