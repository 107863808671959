import { ChangeEvent, useEffect } from "react";

import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";

import {
  defaultLocationFormValidationState,
  defaultLocationFormVariables,
  locationFormRules,
  locationFormValidationStateVariable,
  locationFormVariables,
  useLocationFormValidationState,
  useLocationFormVariables,
} from "../variables/locationData";
import TagsManagementMultidropdown from "../../../../../common/components/select/LocationTagSelect/TagsManagementMultidropdown";
import { useLazyGetPaginatedAllTagsByCustomer } from "../../../../system-settings/tags/hooks/useLazyGetPaginatedAllTagsByCustomer";
import FullPageLoader from "../../../../../common/components/item/FullPageLoader";
import { Tag } from "../../../../../API";
import { useAuthenticatedUser } from "../../../../../common/hooks/useAuthenticatedUser";
import { NonAdminRoles } from "../../../../../common/variables/tabsSettings";

const timeZones = [
  { label: "Pacific Time (PT)", value: "Pacific Time (PT)" },
  { label: "Mountain Time (MT)", value: "Mountain Time (MT)" },
  { label: "Central Time (CT)", value: "Central Time (CT)" },
  { label: "Eastern Time (ET)", value: "Eastern Time (ET)" },
  { label: "Japan Standard Time (JST)", value: "Japan Standard Time (JST)" },
];

interface ILocationFormProps {
  fullWidthInput?: boolean;
  edit?: boolean;
}

const LocationForm = ({
  fullWidthInput,
  edit = false,
}: ILocationFormProps): JSX.Element => {
  const form = useLocationFormVariables();
  const { role } = useAuthenticatedUser();

  const validation = useLocationFormValidationState();

  const { tags, loading } = useLazyGetPaginatedAllTagsByCustomer();

  const isItNonAdminRole = NonAdminRoles.includes(role);

  useEffect((): (() => void) => {
    return (): void => {
      locationFormVariables(defaultLocationFormVariables);

      locationFormValidationStateVariable(defaultLocationFormValidationState);
    };
  }, []);

  const setLocationName = (event: ChangeEvent<HTMLInputElement>): void => {
    const locationName = event.target.value;

    locationFormVariables({
      ...form,
      locationName,
    });

    let hasError = !locationName.match(locationFormRules.locationName.pattern);
    let errorMessage = "";

    if (hasError) {
      errorMessage = "Valid characters: A-Z, a-z, 0-9, _, - ";
    }

    // NOTE: This rule is handled on submit
    if (hasError && !locationName) {
      hasError = false;

      errorMessage = "";
    }

    locationFormValidationStateVariable({
      ...validation,
      locationName: {
        hasError,
        errorMessage,
      },
    });
  };

  const setReferenceId = (event: ChangeEvent<HTMLInputElement>): void => {
    const referenceId = event.target.value;

    locationFormVariables({
      ...form,
      referenceId,
    });
  };

  const setLatitude = (event: ChangeEvent<HTMLInputElement>): void => {
    const latitude = event.target.value;

    locationFormVariables({
      ...form,
      latitude: latitude,
    });

    let hasError = !latitude.match(locationFormRules.latitude.pattern);
    let errorMessage = "";

    if (hasError) {
      errorMessage = "Valid characters: 0-9";
    }

    // NOTE: This rule is handled on submit
    if (hasError && !latitude) {
      hasError = false;

      errorMessage = "";
    }

    locationFormValidationStateVariable({
      ...validation,
      latitude: {
        hasError,
        errorMessage,
      },
    });
  };

  const setLongitude = (event: ChangeEvent<HTMLInputElement>): void => {
    const longitude = event.target.value;

    locationFormVariables({
      ...form,
      longitude: longitude,
    });

    let hasError = !longitude.match(locationFormRules.longitude.pattern);
    let errorMessage = "";

    if (hasError) {
      errorMessage = "Valid characters: 0-9";
    }

    // NOTE: This rule is handled on submit
    if (hasError && !longitude) {
      hasError = false;

      errorMessage = "";
    }

    locationFormValidationStateVariable({
      ...validation,
      longitude: {
        hasError,
        errorMessage,
      },
    });
  };

  const setTimezone = (event: SelectChangeEvent<string>): void => {
    const timezone = event.target.value;

    locationFormVariables({
      ...form,
      timeZone: timezone,
    });

    locationFormValidationStateVariable({
      ...validation,
      timeZone: {},
    });
  };

  const updateLocationFormVariable = (
    key: string,
    value: string[] | Tag[]
  ): void => {
    locationFormVariables({
      ...form,
      [key]: value,
    });

    locationFormValidationStateVariable({
      ...validation,
      [key]: {
        hasError: false,
        errorMessage: "",
      },
    });
  };

  const onSetTagsHandler = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: string[]
  ): void => {
    const tagsToSave = [...tags].filter(tag => newValue.includes(tag.tagName));

    updateLocationFormVariable("tags", tagsToSave);
  };

  const sm = fullWidthInput ? 12 : 6;

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={sm}>
          <TextField
            size="medium"
            id="locationName"
            label="Location name"
            disabled={isItNonAdminRole}
            fullWidth
            error={validation?.locationName.hasError}
            helperText={
              validation?.locationName.hasError &&
              validation?.locationName.errorMessage
            }
            value={form.locationName}
            onChange={setLocationName}
          />
        </Grid>
        <Grid item xs={12} sm={sm}>
          <TextField
            size="medium"
            id="referenceId"
            disabled={isItNonAdminRole}
            label="Reference ID (optional)"
            fullWidth
            value={form.referenceId}
            onChange={setReferenceId}
          />
        </Grid>
        <Grid item xs={12} sm={sm}>
          <TextField
            size="medium"
            id="latitude"
            label="Latitude"
            disabled={isItNonAdminRole}
            fullWidth
            error={validation?.latitude.hasError}
            helperText={
              validation?.latitude.hasError && validation?.latitude.errorMessage
            }
            value={form.latitude}
            onChange={setLatitude}
          />
        </Grid>
        <Grid item xs={12} sm={sm}>
          <FormControl fullWidth>
            <InputLabel disabled={isItNonAdminRole} id="timeZone-label">
              Select timezone
            </InputLabel>
            <Select
              label="Select timezone"
              disabled={isItNonAdminRole}
              labelId="timeZone-label"
              id="timeZone"
              value={form.timeZone}
              onChange={setTimezone}
              error={validation?.timeZone.hasError}
            >
              {timeZones.map(
                (timezone): JSX.Element => (
                  <MenuItem key={timezone.value} value={timezone.value}>
                    {timezone.label}
                  </MenuItem>
                )
              )}
            </Select>
            {validation?.timeZone.hasError && (
              <FormHelperText error={validation?.timeZone.hasError}>
                {validation?.timeZone.errorMessage}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={sm}>
          <TextField
            size="medium"
            id="longitude"
            label="Longitude"
            disabled={isItNonAdminRole}
            fullWidth
            error={validation?.longitude.hasError}
            helperText={
              validation?.longitude.hasError &&
              validation?.longitude.errorMessage
            }
            value={form.longitude}
            onChange={setLongitude}
          />
        </Grid>
        <Grid item xs={12} sm={sm}>
          <TagsManagementMultidropdown
            entityTags={form.tags}
            allTags={tags}
            onChange={onSetTagsHandler}
          />
        </Grid>
      </Grid>

      {loading && <FullPageLoader />}
    </>
  );
};

export default LocationForm;
