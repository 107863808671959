import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import ModelManagerPageHeader from "./components/ModelManagerPageHeader";
import ModelManagerTable from "./components/ModelManagerTable";

const ModelManagerPage = (): JSX.Element => {
  return (
    <Box>
      <Stack
        spacing={3}
        direction="column"
        sx={{
          paddingBottom: "1.5em",
        }}
      >
        <ModelManagerPageHeader />
        <ModelManagerTable />
      </Stack>
    </Box>
  );
};

export default ModelManagerPage;
