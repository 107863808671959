import { ReactElement } from "react";

import { SvgIconProps, useTheme } from "@mui/material";

const SelectedApplicationsIcon = (props: SvgIconProps): ReactElement => {
  const theme = useTheme();

  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z"
        fill={theme.palette.primary.shades?.["12p"]}
      />
      <path
        d="M20 15C20.4603 15 20.8334 14.6269 20.8334 14.1667C20.8334 13.7064 20.4603 13.3333 20 13.3333C19.5398 13.3333 19.1667 13.7064 19.1667 14.1667C19.1667 14.6269 19.5398 15 20 15Z"
        stroke={theme.palette.primary.main}
        stroke-width="1.65"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20 20.8333C20.4603 20.8333 20.8334 20.4602 20.8334 20C20.8334 19.5398 20.4603 19.1667 20 19.1667C19.5398 19.1667 19.1667 19.5398 19.1667 20C19.1667 20.4602 19.5398 20.8333 20 20.8333Z"
        stroke={theme.palette.primary.main}
        stroke-width="1.65"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20 26.6667C20.4603 26.6667 20.8334 26.2936 20.8334 25.8333C20.8334 25.3731 20.4603 25 20 25C19.5398 25 19.1667 25.3731 19.1667 25.8333C19.1667 26.2936 19.5398 26.6667 20 26.6667Z"
        stroke={theme.palette.primary.main}
        stroke-width="1.65"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M25.8334 15C26.2936 15 26.6667 14.6269 26.6667 14.1667C26.6667 13.7064 26.2936 13.3333 25.8334 13.3333C25.3731 13.3333 25 13.7064 25 14.1667C25 14.6269 25.3731 15 25.8334 15Z"
        stroke={theme.palette.primary.main}
        stroke-width="1.65"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M25.8334 20.8333C26.2936 20.8333 26.6667 20.4602 26.6667 20C26.6667 19.5398 26.2936 19.1667 25.8334 19.1667C25.3731 19.1667 25 19.5398 25 20C25 20.4602 25.3731 20.8333 25.8334 20.8333Z"
        stroke={theme.palette.primary.main}
        stroke-width="1.65"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M25.8334 26.6667C26.2936 26.6667 26.6667 26.2936 26.6667 25.8333C26.6667 25.3731 26.2936 25 25.8334 25C25.3731 25 25 25.3731 25 25.8333C25 26.2936 25.3731 26.6667 25.8334 26.6667Z"
        stroke={theme.palette.primary.main}
        stroke-width="1.65"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.1667 15C14.6269 15 15 14.6269 15 14.1667C15 13.7064 14.6269 13.3333 14.1667 13.3333C13.7065 13.3333 13.3334 13.7064 13.3334 14.1667C13.3334 14.6269 13.7065 15 14.1667 15Z"
        stroke={theme.palette.primary.main}
        stroke-width="1.65"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.1667 20.8333C14.6269 20.8333 15 20.4602 15 20C15 19.5398 14.6269 19.1667 14.1667 19.1667C13.7065 19.1667 13.3334 19.5398 13.3334 20C13.3334 20.4602 13.7065 20.8333 14.1667 20.8333Z"
        stroke={theme.palette.primary.main}
        stroke-width="1.65"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.1667 26.6667C14.6269 26.6667 15 26.2936 15 25.8333C15 25.3731 14.6269 25 14.1667 25C13.7065 25 13.3334 25.3731 13.3334 25.8333C13.3334 26.2936 13.7065 26.6667 14.1667 26.6667Z"
        stroke={theme.palette.primary.main}
        stroke-width="1.65"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default SelectedApplicationsIcon;
