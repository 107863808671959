import { Box, Typography } from "@mui/material";

const CustomerSettingsHeader = (): JSX.Element => {
  return (
    <Box padding="0" marginBottom="1em">
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h3">Customer settings</Typography>
      </Box>
    </Box>
  );
};

export default CustomerSettingsHeader;
