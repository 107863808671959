import { useState } from "react";

import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import Box from "@mui/material/Box";
import ButtonGroup from "@mui/material/ButtonGroup";
import IconButton from "@mui/material/IconButton";
import {
  GridColDef,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
} from "@mui/x-data-grid";

import {
  IModelOption,
  allModelsOptions,
} from "../../../../../common/components/select/ServiceSelect/ModelsToRunMultiselect";
import { DEVICE_TYPES } from "../components/CreateDeviceMake";
import { useDeleteDeviceMake } from "./useDeleteDeviceMake";

export const useDevicesMakesTableColumns = (): {
  columns: GridColDef[];
  rowModesModel: GridRowModesModel;
  setRowModesModelChange: (newRowModesModel: GridRowModesModel) => void;
} => {
  const { deleteDeviceMake } = useDeleteDeviceMake();

  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  const setRowModesModelChange = (
    newRowModesModel: GridRowModesModel
  ): void => {
    setRowModesModel(newRowModesModel);
  };

  const resetEditMode = (id: GridRowId, ignoreModifications: boolean): void => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications },
    });
  };

  const handleEditClick =
    (id: GridRowId): (() => void) =>
    (): void => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

  const handleDeleteClick =
    (id: GridRowId): (() => void) =>
    (): void => {
      deleteDeviceMake(id.toString()).then((response): void => {
        console.log(response);
      });
    };

  const handleCancelClick =
    (id: GridRowId): (() => void) =>
    (): void => {
      resetEditMode(id, true);
    };

  const handleSaveClick =
    (id: GridRowId): (() => void) =>
    (): void => {
      resetEditMode(id, false);
    };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Make",
      minWidth: 240,
      flex: 1,
      hideable: false,
      disableColumnMenu: true,
      editable: true,
    },
    {
      field: "model",
      headerName: "Model",
      minWidth: 180,
      hideable: false,
      disableColumnMenu: true,
      editable: false,
      cellClassName: "cursor-not-allowed",
    },
    {
      field: "type",
      headerName: "Type",
      minWidth: 180,
      hideable: false,
      disableColumnMenu: true,
      editable: true,
      type: "singleSelect",
      valueOptions: DEVICE_TYPES,
    },
    {
      field: "rtspTemplate",
      headerName: "RTSP Template",
      minWidth: 300,
      flex: 1,
      disableColumnMenu: true,
      editable: false,
    },
    {
      field: "hasPanTilt",
      headerName: "Pan Tilt Option",
      width: 100,
      hideable: false,
      disableColumnMenu: true,
      editable: false,
      renderCell: (params): JSX.Element => {
        const activeRow = params.row;

        return <>{activeRow?.hasPanTilt ? "Yes" : "No"}</>;
      },
    },
    {
      field: "modelsToRun",
      headerName: "Models to run",
      hideable: false,
      width: 200,
      disableColumnMenu: true,
      editable: false,
      renderCell: (params): JSX.Element => {
        const selected = params.row?.modelsToRun.map((model: string) => {
          if (model === "tanklevel") return "Tank Level";

          const modelOption = allModelsOptions.find(
            (op: IModelOption) => op.value === model
          );

          if (modelOption) {
            return modelOption.name;
          }

          return model;
        });

        return (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected?.join(", ")}
          </Box>
        );
      },
    },
    {
      field: "action",
      headerName: "Actions",
      disableColumnMenu: true,
      hideable: false,
      sortable: false,
      renderCell: (params): JSX.Element => {
        const activeRow = rowModesModel[params.id]?.mode;

        return (
          <ButtonGroup variant="text" color="secondary">
            {!activeRow && (
              <>
                <IconButton
                  aria-label="edit"
                  onClick={handleEditClick(params.id)}
                >
                  <EditRoundedIcon />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  onClick={handleDeleteClick(params.id)}
                >
                  <DeleteRoundedIcon />
                </IconButton>
              </>
            )}
            {activeRow && (
              <>
                <IconButton
                  aria-label="cancel"
                  onClick={handleCancelClick(params.id)}
                >
                  <CloseRoundedIcon />
                </IconButton>
                <IconButton
                  aria-label="save"
                  onClick={handleSaveClick(params.id)}
                >
                  <CheckRoundedIcon />
                </IconButton>
              </>
            )}
          </ButtonGroup>
        );
      },
    },
  ];

  return { columns, rowModesModel, setRowModesModelChange };
};
