import { ChangeEvent } from "react";

import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { Control, Controller, FieldError } from "react-hook-form";

import { IAlertForm } from "../CreateAlertContainer";

interface AlertEntitiesRadioGroupProps {
  control: Control<IAlertForm>;
  entityError: FieldError | undefined;
  onEntityChange: (value: string) => void;
}

const AlertEntitiesRadioGroup = ({
  control,
  entityError,
  onEntityChange,
}: AlertEntitiesRadioGroupProps) => {
  const radioGroupValues = ["Customer", "Location", "Model"];
  const radioGroupLabels = radioGroupValues;

  return (
    <Controller
      name="entity"
      rules={{
        required: "It’s required to select at least one option",
      }}
      control={control}
      render={({ field }) => {
        const handleChange = (
          _event: ChangeEvent<HTMLInputElement>,
          value: string
        ) => onEntityChange(value);

        return (
          <FormControl error={!!entityError}>
            <RadioGroup
              aria-labelledby="alert-entity-group"
              {...field}
              onChange={handleChange}
            >
              {radioGroupLabels.map((radioLabel, index) => (
                <FormControlLabel
                  key={radioLabel}
                  label={radioLabel}
                  value={radioGroupValues[index]}
                  control={
                    <Radio
                      sx={{
                        "& .MuiSvgIcon-root": {
                          color: theme =>
                            entityError ? theme.palette.error.main : "",
                        },
                      }}
                    />
                  }
                />
              ))}
            </RadioGroup>

            {entityError && (
              <FormHelperText sx={{ color: theme => theme.palette.error.main }}>
                {entityError.message}
              </FormHelperText>
            )}
          </FormControl>
        );
      }}
    />
  );
};

export default AlertEntitiesRadioGroup;
