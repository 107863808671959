import { makeVar, useReactiveVar } from "@apollo/client";
import { AutocompleteOptionType } from "../../../../../common/models/autocomplete";

export interface LocationTableParameters {
  filterValue: string;
}

export const locationsTableVariable = makeVar<LocationTableParameters>({
  filterValue: "",
});

export const useLocationsTableVariables = () =>
  useReactiveVar(locationsTableVariable);

export const locationsTableSelectedFilterVariable =
  makeVar<AutocompleteOptionType>(null);

export const useLocationsTableSelectedFilterVariable = () =>
  useReactiveVar(locationsTableSelectedFilterVariable);

export const locationsTableSelectedTagLocationsVariable = makeVar<
  AutocompleteOptionType[] | null
>(null);

export const useLocationsTableSelectedTagLoactionsVariable = () =>
  useReactiveVar(locationsTableSelectedTagLocationsVariable);
