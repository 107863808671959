import { useEffect, useMemo } from "react";

import { useLazyQuery, WatchQueryFetchPolicy } from "@apollo/client";

import type {
  GetTagsByCustomerQuery,
  GetTagsByCustomerQueryVariables,
  Tag,
} from "../../../../API";
import { useCustomerIdGuard } from "../../../../common/hooks/useCustomerIdGuard";
import { GET_TAGS_BY_CUSTOMER } from "../../../../common/operations/queries";
import { errorNotification } from "../../../../common/variables/notification";
import client from "../../../../configs/apolloClient";

export const useLazyGetPaginatedAllTagsByCustomer = (
  policy: WatchQueryFetchPolicy = "cache-and-network"
) => {
  const customerId = useCustomerIdGuard();

  const [getTags, { data, loading }] = useLazyQuery<
    GetTagsByCustomerQuery,
    GetTagsByCustomerQueryVariables
  >(GET_TAGS_BY_CUSTOMER, {
    fetchPolicy: policy,
  });

  useEffect((): void => {
    if (customerId) {
      fetchTagsByCustomer();
    }
  }, [customerId]);

  const handleRequestError = async (): Promise<void> => {
    await client.cache.reset();

    errorNotification();
  };

  const fetchTagsByCustomer = (nextToken?: string): void => {
    getTags({
      variables: {
        input: {
          customerId,
          nextToken,
        },
      },
    })
      .then(async (response): Promise<void> => {
        if (response.data?.getTagsByCustomer.nextToken) {
          fetchTagsByCustomer(response.data?.getTagsByCustomer.nextToken);
        }

        if (response.error) {
          await handleRequestError();
        }
      })
      .catch(async (): Promise<void> => {
        await handleRequestError();
      });
  };

  const tags: Tag[] = useMemo(
    (): Tag[] => (data?.getTagsByCustomer.items as Tag[]) || [],
    [data?.getTagsByCustomer.items]
  );

  return { tags, loading };
};
