import { FC, ReactNode } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

interface InstructionListProps {
  instructions: string[];
  anotherChildren?: ReactNode;
  indexOfChild?: number;
}

const InstructionList: FC<InstructionListProps> = ({
  instructions,
  anotherChildren,
  indexOfChild,
}): JSX.Element => {
  return (
    <Box
      component="ol"
      sx={{
        display: "flex",
        flexDirection: "column",
        margin: 0,
        padding: 0,
        listStylePosition: "inside",
        "& > li": {
          marginBottom: "10px",
        },
      }}
    >
      {instructions.map(
        (instruction, index): JSX.Element => (
          <li key={instruction}>
            <Typography
              variant="body2Regular"
              sx={{
                color: "text.primary",
                paddingBottom: index === instructions.length - 1 ? 0 : "0.75em",
              }}
            >
              {instruction}
            </Typography>

            {index === indexOfChild && anotherChildren}
          </li>
        )
      )}
    </Box>
  );
};

export default InstructionList;
