import type { GridColDef } from "@mui/x-data-grid";

export const useEmailNotificationsTableColumns = (): {
  columns: GridColDef[];
} => {
  const columns: GridColDef[] = [
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      hideable: false,
    },
    {
      field: "customer",
      headerName: "Customer",
      flex: 1,
      hideable: false,
      // TO DO: Figure out how do we want to view coordinates on UI
      // Hardcoded north and west for readablilty
    },
    {
      field: "location",
      headerName: "Location",
      flex: 1,
      hideable: false,
    },
    {
      field: "model",
      headerName: "Model",
      flex: 1,
      disableColumnMenu: true,
      hideable: false,
      sortable: false,
    },
  ];

  return { columns };
};
