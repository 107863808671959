import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import AllLocationsFilterDropdownContainer from "../../../common/components/locations/AllLocationsFilterDropdownContainer";
import { AutocompleteOptionType } from "../../../common/models/autocomplete";
import {
  selectedLocationAIManagerVariable,
  selectedTagLocationAIManagerVariable,
} from "../variables/modelManager";
import CreateServiceDialog from "./CreateServiceDialog";

const ModelManagerPageHeader = (): JSX.Element => {
  const handleLocationChange = (
    locationValue: AutocompleteOptionType
  ): void => {
    selectedLocationAIManagerVariable(locationValue);
  };

  const handleTagLocationsChange = (
    tagLocations: AutocompleteOptionType[] | null
  ): void => {
    selectedTagLocationAIManagerVariable(tagLocations);
  };

  return (
    <Box padding="0" marginBottom="1em">
      <Stack spacing={4} direction="row" alignItems="center">
        <Typography variant="h3" padding={0} sx={{ flexGrow: 1 }}>
          AI manager
        </Typography>
        <Box sx={{ maxWidth: 400, width: 280 }}>
          <AllLocationsFilterDropdownContainer
            setLocation={handleLocationChange}
            setTagLocations={handleTagLocationsChange}
          />
        </Box>
        <CreateServiceDialog />
      </Stack>
    </Box>
  );
};

export default ModelManagerPageHeader;
