import { RoleEnum } from "../models/enums";

function isLocal(): boolean {
  if (typeof window === "undefined") return false;

  const { hostname } = window.location;

  return (
    hostname === "localhost" ||
    hostname === "127.0.0.1" ||
    hostname.startsWith("192.168.") ||
    hostname.startsWith("0.0.0.")
  );
}

export const isLocalEnvironment = isLocal();

export function getRolePath(role: RoleEnum) {
  switch (role) {
    case RoleEnum.ROOT:
    case RoleEnum.PARTNER_ADMIN:
    case RoleEnum.CC_OPS_ADMIN:
    case RoleEnum.CC_OPS:
      return "/devices";

    case RoleEnum.CC_PROCTORS:
      return "/human-validator";

    case RoleEnum.LEAKFINDER_DIRECTOR:
      return "/leak-finder/client-uploads";

    case RoleEnum.CUSTOMER_OPERATOR_FOREMAN:
      return "/sensory/recent-activities";

    default:
      return "/";
  }
}
