import { useTheme } from "@mui/material/styles";

const DataHubMenuIcon = (): JSX.Element => {
  const theme = useTheme();

  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.25 18.9166C17.25 17.3452 17.25 16.5596 17.7382 16.0714C18.2263 15.5833 19.012 15.5833 20.5833 15.5833C22.1547 15.5833 22.9404 15.5833 23.4285 16.0714C23.9167 16.5596 23.9167 17.3452 23.9167 18.9166V20.5833C23.9167 22.1546 23.9167 22.9403 23.4285 23.4284C22.9404 23.9166 22.1547 23.9166 20.5833 23.9166C19.012 23.9166 18.2263 23.9166 17.7382 23.4284C17.25 22.9403 17.25 22.1546 17.25 20.5833V18.9166Z"
        stroke={theme.palette.text.primary}
        strokeWidth="1.5"
      />
      <path
        d="M7.6665 13.0833C7.6665 14.6546 7.6665 15.4403 8.15466 15.9284C8.64281 16.4166 9.42849 16.4166 10.9998 16.4166C12.5712 16.4166 13.3569 16.4166 13.845 15.9284C14.3332 15.4403 14.3332 14.6546 14.3332 13.0833V11.4166C14.3332 9.84524 14.3332 9.05956 13.845 8.57141C13.3569 8.08325 12.5712 8.08325 10.9998 8.08325C9.42849 8.08325 8.64281 8.08325 8.15466 8.57141C7.6665 9.05956 7.6665 9.84524 7.6665 11.4166V13.0833Z"
        stroke={theme.palette.text.primary}
        strokeWidth="1.5"
      />
      <path
        d="M17.25 10.5833C17.25 9.80668 17.25 9.4184 17.3769 9.11211C17.546 8.70373 17.8705 8.37928 18.2789 8.21012C18.5851 8.08325 18.9734 8.08325 19.75 8.08325H21.4167C22.1932 8.08325 22.5815 8.08325 22.8878 8.21012C23.2962 8.37928 23.6206 8.70373 23.7898 9.11211C23.9167 9.4184 23.9167 9.80668 23.9167 10.5833C23.9167 11.3598 23.9167 11.7481 23.7898 12.0544C23.6206 12.4628 23.2962 12.7872 22.8878 12.9564C22.5815 13.0833 22.1932 13.0833 21.4167 13.0833H19.75C18.9734 13.0833 18.5851 13.0833 18.2789 12.9564C17.8705 12.7872 17.546 12.4628 17.3769 12.0544C17.25 11.7481 17.25 11.3598 17.25 10.5833Z"
        stroke={theme.palette.text.primary}
        strokeWidth="1.5"
      />
      <path
        d="M7.6665 21.4167C7.6665 22.1933 7.6665 22.5816 7.79337 22.8879C7.96253 23.2963 8.28698 23.6207 8.69536 23.7899C9.00165 23.9167 9.38993 23.9167 10.1665 23.9167H11.8332C12.6097 23.9167 12.998 23.9167 13.3043 23.7899C13.7127 23.6207 14.0371 23.2963 14.2063 22.8879C14.3332 22.5816 14.3332 22.1933 14.3332 21.4167C14.3332 20.6402 14.3332 20.2519 14.2063 19.9456C14.0371 19.5372 13.7127 19.2128 13.3043 19.0436C12.998 18.9167 12.6097 18.9167 11.8332 18.9167H10.1665C9.38993 18.9167 9.00165 18.9167 8.69536 19.0436C8.28698 19.2128 7.96253 19.5372 7.79337 19.9456C7.6665 20.2519 7.6665 20.6402 7.6665 21.4167Z"
        stroke={theme.palette.text.primary}
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default DataHubMenuIcon;
