import Typography from "@mui/material/Typography";

const NoZoneTypes = () => {
  return (
    <Typography
      component="div"
      variant="caption"
      color={theme => theme.palette.otherTextTertiary.main}
      p="1.15em"
      fontSize="0.875em"
    >
      No zone types are added
    </Typography>
  );
};

export default NoZoneTypes;
