import { useEffect, useState } from "react";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import uniq from "lodash/uniq";

import { ModelType, TimePeriod, eventType } from "../../../API";
import ApexOptions from "../../../common/components/apexchart/ApexChartModels";
import { IChartProps, IStatisticsResponseModel } from "../models/chart";
import { useGetStatisticsData } from "./useGetStatisticsData";
import { useTheme } from "@mui/material";

export const DEFAULT_STATISTICS_DATE_FORMAT = "MMM DD, YY";

export const useAllAlertsChart = (
  timePeriod: TimePeriod,
  locations: string[] | null
): IChartProps => {
  const { loading, data, refetching, refetchChartData } = useGetStatisticsData<
    IStatisticsResponseModel[]
  >({
    eventType: eventType.allAlerts,
    models: [],
    locations,
    timePeriod,
  });

  const theme = useTheme();

  const options: ApexOptions = {
    chart: {
      stacked: false,
      width: "100%",
      fontFamily: "Inter",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 4,
    },
    theme: {
      mode: theme.palette.mode,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      showForNullSeries: false,
      showForZeroSeries: false,
      showForSingleSeries: true,
      fontFamily: "Inter",
    },
    colors: [
      "#F150FF", // agg
      "#EB5564", // fire
      "#735EC9", // gasleak
      "#3E8DF3", // hardhat
      "#F3B344", // liquidLeak
      "#4EB100", // smoke
      "#67E09C", // tlm
    ],
    yaxis: {
      opposite: true,
    },
  };

  const [chartSeries, setChartProps] = useState<any>([]);
  const [chartOptions, setChartOptions] = useState<any>(options);

  useEffect(() => {
    const series = generateChartSeries(data as IStatisticsResponseModel[]);
    const extendedOptions = generateChartCategories(
      data as IStatisticsResponseModel[]
    );

    setChartProps(series);

    setChartOptions(extendedOptions);
  }, [data, theme.palette.mode]);

  const generateChartSeries = (
    response: IStatisticsResponseModel[]
  ): ApexOptions["series"] => {
    if (!Array.isArray(response) || response.length === 0) {
      return [];
    }

    const labels = uniq(response.map(el => new Date(el.date).getTime()));

    const series = Object.values(ModelType).map((model: string) => {
      const seriesData = labels.map(date => {
        const timestamp = new Date(date).getTime();
        const matchingEntry = response.find(
          entry =>
            entry.model === model &&
            new Date(entry.date).getTime() === timestamp
        );

        return matchingEntry ? parseInt(matchingEntry.record_count, 10) : null;
      });

      return {
        name: model,
        data: seriesData,
        type:
          seriesData.filter(el => el !== null)?.length === 1
            ? "scatter"
            : "line",
      };
    });

    return series;
  };

  const generateChartCategories = (
    response: IStatisticsResponseModel[]
  ): ApexOptions => {
    if (!Array.isArray(response) || response.length === 0) {
      return options;
    }

    const labels = uniq(
      response.map(s => dayjs(s.date).format(DEFAULT_STATISTICS_DATE_FORMAT))
    );

    const extendedOptions: ApexOptions = {
      ...options,
      tooltip: {
        custom: ({ series, seriesIndex, dataPointIndex, w }) => {
          const hoverXaxis = w.globals.seriesX[seriesIndex][dataPointIndex];
          const hoverIndexes = w.globals.seriesX.map((seriesX: any[]) => {
            return seriesX.findIndex((xData: any) => xData === hoverXaxis);
          });
          let hoverList = "";

          hoverIndexes.forEach(
            (hoverIndex: number, seriesEachIndex: string | number) => {
              if (hoverIndex >= 0) {
                const value =
                  w.globals.yLabelFormatters[0](
                    series[seriesEachIndex][hoverIndex]
                  ) ?? 0;

                if (value !== 0) {
                  hoverList += `
                    <div class="apexcharts-tooltip-series-group apexcharts-active" style="display: flex;">
                      <span class="apexcharts-tooltip-marker" style="background-color: ${
                        w.globals.markers.colors[seriesEachIndex]
                      };"></span>
                      <div class="apexcharts-tooltip-text">
                        <div class="apexcharts-tooltip-y-group">
                          <span class="apexcharts-tooltip-text-y-label">${
                            w.globals.seriesNames[seriesEachIndex]
                          }: </span>
                          <span class="apexcharts-tooltip-text-y-value">${
                            w.globals.yLabelFormatters[0](
                              series[seriesEachIndex][hoverIndex]
                            ) ?? 0
                          }</span>
                        </div>
                      </div>
                    </div>
                  `;
                }
              }
            }
          );

          dayjs.extend(utc);

          const date1 = dayjs
            .utc(new Date(hoverXaxis))
            .format(DEFAULT_STATISTICS_DATE_FORMAT);
          const date2 = dayjs
            .utc(new Date(w.globals.labels[dataPointIndex]))
            .format(DEFAULT_STATISTICS_DATE_FORMAT);

          const date3 = w.globals.categoryLabels[dataPointIndex];

          let date;

          if (!date1.includes("70")) {
            date = date1;
          }

          if (!date && !date2.includes("70")) {
            date = date2;
          }

          if (!date && !date3.includes("70")) {
            date = date3;
          }

          if (
            dayjs(new Date()).format(DEFAULT_STATISTICS_DATE_FORMAT) === date
          ) {
            date = "Today";
          }

          return `
            ${hoverList}
            <hr>
            <div class="tooltip-date">${date}</div>
          `;
        },
      },
      stroke: {
        ...options.stroke,
        width: chartSeries.length === 1 ? 4 : 2,
      },
      xaxis: {
        type: "datetime",
        categories: labels,
        tickAmount: timePeriod === TimePeriod.ALL_TIME ? 24 : undefined,
        tooltip: {
          enabled: false,
        },
        labels: {
          rotate: -70,
          showDuplicates: false,
          formatter: function (value: string) {
            return dayjs(value).format(DEFAULT_STATISTICS_DATE_FORMAT);
          },
        },
      },
    };

    return extendedOptions;
  };

  return {
    series: chartSeries,
    options: chartOptions,
    loading,
    refetching,
    showNoData:
      !loading && !refetching && data?.filter(d => d.record_count).length === 0,
    refetchChartData,
  };
};
