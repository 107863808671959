import { useTheme } from "@mui/material/styles";

const AddIconButton = ({
  disabled,
  onClick,
}: {
  disabled?: boolean;
  onClick?: () => void;
}): JSX.Element => {
  const theme = useTheme();

  const stroke = disabled
    ? theme.palette.primary.shades?.["30p"]
    : theme.palette.primary.main;

  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      style={{ cursor: "pointer" }}
      xmlns="http://www.w3.org/2000/svg"
      className={`addIcon ${disabled ? "disabled" : ""}`}
      onClick={onClick}
    >
      <rect x="0.5" y="0.5" width="31" height="31" rx="7.5" fill="white" />
      <rect x="0.5" y="0.5" width="31" height="31" rx="7.5" stroke="#E1E6EF" />
      <path
        d="M16.0007 9.33337V22.6667M22.6673 16L9.33398 16"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default AddIconButton;
