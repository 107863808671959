import { useMemo } from "react";

import { LinearProgress, TableContainer } from "@mui/material";
import { DataGrid, GridRowHeightReturnValue } from "@mui/x-data-grid";

import { dataGridServerStyles } from "../../../common/providers/theme/design-tokens/DataGrid/dataGridServer.styles";
import { DEFAULT_COLUMN_HEADER_HEIGHT } from "../../../common/variables/common";
import { TablePaperContainer } from "../../../leak-finder/components/VideosTable";
import { useDeviceTableColumns } from "../hooks/useDeviceTableColumns";
import { useDeviceTableRows } from "../hooks/useDeviceTableRows";
import { DEVICES_TABLE_LIMIT } from "../hooks/useGetDevices";
import DevicesTableNoRowsOverlay from "./DevicesTableNoRowsOverlay";

const DevicesTable = (): JSX.Element => {
  const { rows, loading } = useDeviceTableRows();

  const { columns } = useDeviceTableColumns();

  const { noContentOverlayParam, heightOfNoRowsOverlay } = useMemo(() => {
    const heightOfNoRowsOverlay = "240px !important";
    const noContentOverlayParam = (rows.length === 0 && !loading) || loading;

    return { heightOfNoRowsOverlay, noContentOverlayParam };
  }, [rows.length, loading]);

  return (
    <TableContainer component={TablePaperContainer}>
      <DataGrid
        sx={{
          ...dataGridServerStyles.sx,
          ".MuiDataGrid-overlayWrapperInner, .MuiDataGrid-virtualScrollerContent":
            {
              height: noContentOverlayParam ? heightOfNoRowsOverlay : "",
            },
        }}
        columnHeaderHeight={DEFAULT_COLUMN_HEADER_HEIGHT}
        autoHeight
        pageSizeOptions={[10, 25, 50, 100]}
        rows={rows}
        columns={columns}
        loading={loading}
        slots={{
          loadingOverlay: () => <LinearProgress />,
          noRowsOverlay: (): JSX.Element => (
            <DevicesTableNoRowsOverlay loading={loading} />
          ),
        }}
        getRowHeight={(): GridRowHeightReturnValue => "auto"}
        disableRowSelectionOnClick
        hideFooterSelectedRowCount
        getRowId={(row: { rowId?: string }): string => row?.rowId ?? ""}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: DEVICES_TABLE_LIMIT,
            },
          },
        }}
        sortingOrder={["desc", "asc"]}
      />
    </TableContainer>
  );
};

export default DevicesTable;
