import { FC, Fragment, SyntheticEvent, useMemo } from "react";

import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

import { useGetPaginatedLicensePlates } from "../../../../pages/system-settings/tabs/plates-management/hooks/useGetPaginatedLicensePlates";
import { AutocompleteOptionType } from "../../../models/autocomplete";

interface ICompanyFilterDropdownProps {
  selectedValue: AutocompleteOptionType;
  setSelectedValue: (value: AutocompleteOptionType) => void;
}

const CompanyFilterDropdown: FC<ICompanyFilterDropdownProps> = ({
  selectedValue,
  setSelectedValue,
}) => {
  const { licensePlates: companies, loading } = useGetPaginatedLicensePlates();

  const handleOnChange = async (
    _event: SyntheticEvent<Element, Event>,
    optionValue: AutocompleteOptionType
  ): Promise<void> => {
    setSelectedValue(optionValue);
  };

  const options = useMemo<AutocompleteOptionType[]>(() => {
    const filteredCompanies: any[] = [];

    if (companies) {
      for (const key in companies) {
        if (Array.isArray(companies[key])) {
          companies[key].forEach(plate => {
            filteredCompanies.push(plate);
          });
        }
      }
    }

    const seenCompanies = new Set<string>();

    const companyOptions =
      filteredCompanies
        .map(company => {
          if (
            selectedValue?.value &&
            !selectedValue?.title &&
            selectedValue?.value === company?.company
          ) {
            selectedValue.title = company.company;
          }

          return {
            title: company?.company ?? "",
            value: company?.company ?? "",
          };
        }) // filter out duplicate entries
        .filter(option => {
          if (seenCompanies.has(option.value)) {
            return false;
          } else {
            seenCompanies.add(option.value);

            return true;
          }
        }) ?? [];

    return companyOptions;
  }, [companies, selectedValue]);

  return (
    <Autocomplete
      size="small"
      fullWidth
      loading={loading}
      options={options}
      getOptionLabel={(option: AutocompleteOptionType): string =>
        option?.title ?? ""
      }
      isOptionEqualToValue={(option, optionValue): boolean =>
        option?.value === "" ? true : option?.value === optionValue?.value
      }
      value={selectedValue}
      onChange={handleOnChange}
      popupIcon={<ExpandMoreOutlinedIcon />}
      renderOption={(props, option): JSX.Element => (
        <Fragment key={option?.value}>
          {option?.value && (
            <Box {...props} component="li">
              {option?.title}
            </Box>
          )}
        </Fragment>
      )}
      ListboxProps={{
        style: {
          maxHeight: "362px", // or 363px
        },
      }}
      renderInput={(params): JSX.Element => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <TextField
            sx={{ height: "60px" }}
            {...params}
            variant="outlined"
            label="Company"
          />
        </Box>
      )}
    />
  );
};

export default CompanyFilterDropdown;
