import { useEffect, useMemo, useState } from "react";

import { useLazyQuery } from "@apollo/client";

import {
  Device,
  GetDevicesQuery,
  GetDevicesQueryVariables,
} from "../../../../../API";
import { useCustomerIdGuard } from "../../../../../common/hooks/useCustomerIdGuard";
import { GET_DEVICES } from "../../../../../common/operations/queries";
import { DEFAULT_LIMIT_COUNT } from "../../../../../common/variables/common";
import { errorNotification } from "../../../../../common/variables/notification";
import client from "../../../../../configs/apolloClient";
import { useSelectedDataHubLocation } from "../../dataHub.variable";

export const useGetLiveViewData = () => {
  const selectedCustomerId = useCustomerIdGuard();
  const locationVariable = useSelectedDataHubLocation();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (selectedCustomerId) {
      setLoading(true);

      fetchDevices(selectedCustomerId, undefined, locationVariable?.value);
    }
  }, [selectedCustomerId, locationVariable?.value]);

  const handleRequestError = async (): Promise<void> => {
    await client.cache.reset();

    errorNotification();
  };

  const [getDevices, { data }] = useLazyQuery<
    GetDevicesQuery,
    GetDevicesQueryVariables
  >(GET_DEVICES, {
    fetchPolicy: "cache-first",
  });

  const fetchDevices = (
    customerId: string,
    nextToken?: string,
    locationId?: string
  ) => {
    getDevices({
      variables: {
        limit: DEFAULT_LIMIT_COUNT,
        customerId,
        locationId,
        nextToken,
      },
    })
      .then(async (response): Promise<void> => {
        if (response.data?.getDevices.nextToken) {
          fetchDevices(
            customerId,
            response.data?.getDevices.nextToken,
            locationId
          );
        }

        if (response.data?.getDevices.nextToken === null) {
          setLoading(false);
        }

        if (response.error) {
          await handleRequestError();

          setLoading(false);
        }
      })
      .catch(async (error): Promise<void> => {
        setLoading(false);

        console.error("Error fetching Live View data", error);

        await handleRequestError();
      });
  };

  const devices: Device[] = useMemo(
    (): Device[] => (data?.getDevices.items as Device[]) || [],
    [data?.getDevices.items]
  );

  return { devices, loading };
};
