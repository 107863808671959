import { FC, useState } from "react";

import { Stack } from "@mui/material";
import Grid from "@mui/material/Grid";

import ChipComponent from "../../../common/components/chip/ChipComponent";
import { Device } from "../../../API";

interface IConnectedDevicesColumnProps {
  devices: Device[];
}

const NodeConnectedDevicesColumn: FC<IConnectedDevicesColumnProps> = ({
  devices,
}) => {
  const [isShowMore, setIsShowMore] = useState(false);

  const onSetShowMoreHandler = () => setIsShowMore(prev => !prev);

  return (
    <Grid sx={{ margin: "12px 0" }} container spacing={1}>
      {devices?.map((device, index) => {
        if (index > 1 && !isShowMore) {
          return null;
        }

        return (
          <Grid item key={index}>
            <Stack spacing={1} direction="row" alignItems="center">
              {index === 1 && !isShowMore ? (
                <ChipComponent
                  menuBoxStyles={{
                    padding: "2px 0.5em",
                    color: theme => theme.typography.inputLabel.color,
                  }}
                  onClick={onSetShowMoreHandler}
                  label={`+${devices.length - 1}`}
                />
              ) : (
                <ChipComponent
                  menuBoxStyles={{
                    padding: "2px 0.5em",
                  }}
                  label={device?.name ?? ""}
                  routeLink={`/device/${encodeURIComponent(device?.id ?? "")}`}
                />
              )}

              {index === devices?.length - 1 && isShowMore && (
                <ChipComponent
                  menuBoxStyles={{
                    padding: "2px 0.5em",
                    color: theme => theme.palette.primary.dark,
                  }}
                  onClick={onSetShowMoreHandler}
                  label={"Show less"}
                />
              )}
            </Stack>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default NodeConnectedDevicesColumn;
