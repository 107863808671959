import { useTheme } from "@mui/material/styles";

const CustomerSettingsMenuIcon = (): JSX.Element => {
  const theme = useTheme();

  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="16.0001"
        cy="10.9993"
        r="3.33333"
        stroke={theme.palette.text.primary}
        strokeWidth="1.5"
      />
      <ellipse
        cx="16.0001"
        cy="20.1673"
        rx="5.83333"
        ry="3.33333"
        stroke={theme.palette.text.primary}
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default CustomerSettingsMenuIcon;
