import { FC, Fragment, SyntheticEvent, useMemo } from "react";

import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

import { useGetPaginatedPlateCategories } from "../../../../pages/system-settings/tabs/plates-management/hooks/useGetPaginatedPlateCategories";
import { AutocompleteOptionType } from "../../../models/autocomplete";

interface IPlatesCategoryDropdownProps {
  selectedValue: AutocompleteOptionType;
  setSelectedValue: (value: AutocompleteOptionType) => void;
}

const PlatesCategoryFilterDropdown: FC<IPlatesCategoryDropdownProps> = ({
  selectedValue,
  setSelectedValue,
}) => {
  const { categories, loading } = useGetPaginatedPlateCategories();

  const handleOnChange = async (
    _event: SyntheticEvent<Element, Event>,
    optionValue: AutocompleteOptionType
  ): Promise<void> => {
    setSelectedValue(optionValue);
  };

  const options = useMemo<
    AutocompleteOptionType[]
  >((): AutocompleteOptionType[] => {
    const categoriesOptions =
      categories?.items?.map((category): AutocompleteOptionType => {
        if (
          selectedValue?.value &&
          !selectedValue?.title &&
          selectedValue?.value === category?.id
        ) {
          selectedValue.title = category.categoryName;
        }

        const unknownCondition =
          !category?.categoryName || category?.categoryName === "UNKNOWN";

        return {
          title: unknownCondition ? "Unknown" : category?.categoryName,
          value: category?.id ?? "",
        };
      }) ?? [];

    return categoriesOptions;
  }, [categories, selectedValue]);

  return (
    <Autocomplete
      size="small"
      fullWidth
      loading={loading}
      options={options}
      getOptionLabel={(option: AutocompleteOptionType): string =>
        option?.title ?? ""
      }
      isOptionEqualToValue={(option, optionValue): boolean =>
        option?.value === "" ? true : option?.value === optionValue?.value
      }
      value={selectedValue}
      onChange={handleOnChange}
      popupIcon={<ExpandMoreOutlinedIcon />}
      renderOption={(props, option): JSX.Element => (
        <Fragment key={option?.value}>
          {option?.value && (
            <Box {...props} component="li">
              {option?.title}
            </Box>
          )}
        </Fragment>
      )}
      ListboxProps={{
        style: {
          maxHeight: "362px", // or 363px
        },
      }}
      renderInput={(params): JSX.Element => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <TextField
            sx={{ height: "60px" }}
            {...params}
            variant="outlined"
            label="Select category"
          />
        </Box>
      )}
    />
  );
};

export default PlatesCategoryFilterDropdown;
