import { useGetLazyPaginatedDevicesMakes } from "./useGetLazyPaginatedDevicesMakes";

export const useDevicesMakesTableRows = () => {
  const { data, loading } = useGetLazyPaginatedDevicesMakes();

  const rows =
    data?.getDevicesMakes?.items.map((item, index) => {
      return {
        id: item?.model,
        rowId: item?.id ?? `${index}`,
        name: item?.name,
        model: item?.model,
        type: item?.type,
        defaultUserPwd: item?.defaultUserPwd,
        rtspTemplate: item?.rtspTemplate,
        hasPanTilt: item?.hasPanTilt,
        hasZoom: item?.hasZoom,
        modelsToRun: item?.modelsToRun,
        deviceTypeShortName: item?.deviceTypeShortName,
      };
    }) ?? [];

  return { rows, loading };
};
