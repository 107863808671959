import { FC } from "react";

import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";

import { ZoneThreshold } from "../../../../../../../../API";
import CircularLoading from "../../../../../../../../common/components/progress/CircularLoading";
import EditZoneThresholdDialog from "../EditZoneThresholdDialog";
import NoZoneTypes from "./NoZoneTypes";
import DeleteThresholdItemContainer from "../DeleteThresholdItemContainer";

interface IZoneTypesListProps {
  zoneTypes: ZoneThreshold[];
  loading?: boolean;
  onClick?: (id: string) => void;
}

const ZoneTypesList: FC<IZoneTypesListProps> = ({
  zoneTypes,
  loading,
  onClick,
}) => {
  const handleClick = (id: string) => {
    if (onClick) {
      onClick(id);
    }
  };

  if (loading) {
    return <CircularLoading />;
  }

  if (!zoneTypes || zoneTypes?.length === 0) {
    return <NoZoneTypes />;
  }

  return (
    <List
      dense
      sx={{
        height: "145px",
        overflowY: "auto",
        py: 0,
      }}
    >
      {zoneTypes?.map(zoneThreshold => (
        <ListItem
          key={zoneThreshold.id}
          sx={{
            paddingY: 0,
            height: "35px",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: theme => theme.palette.primary.shades?.["8p"],
            },
            "& .edit": {
              visibility: "hidden",
            },
            "&:hover .edit": {
              visibility: "visible",
            },
          }}
          secondaryAction={
            <Box
              className="edit"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <EditZoneThresholdDialog thresholdId={zoneThreshold.id} />
              <DeleteThresholdItemContainer threshold={zoneThreshold} />
            </Box>
          }
        >
          <ListItemText
            primary={
              <Box
                onClick={() => handleClick(zoneThreshold.id)}
                display="flex"
                gap="0.5em"
                alignItems="center"
              >
                <Typography
                  variant="caption"
                  color={theme => theme.palette.text.secondary}
                >
                  {zoneThreshold.value} MCF/d
                </Typography>
                <Typography
                  variant="body2Regular"
                  color={theme => theme.palette.text.primary}
                >
                  {zoneThreshold.name}
                </Typography>
              </Box>
            }
          />
        </ListItem>
      ))}
    </List>
  );
};

export default ZoneTypesList;
