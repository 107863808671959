import { Box } from "@mui/material";

import SystemSettingsHeader from "./SystemSettingsHeader";
import SystemSettingsTabs from "./SystemSettingsTabs";

const SystemSettingsPage = (): JSX.Element => {
  return (
    <Box>
      <SystemSettingsHeader />
      <SystemSettingsTabs />
    </Box>
  );
};

export default SystemSettingsPage;
