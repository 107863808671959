import { ApolloError, useMutation } from "@apollo/client";

import {
  DeleteDeviceMutation,
  DeleteDeviceMutationVariables,
} from "../../../API";
import { useCustomerIdGuard } from "../../../common/hooks/useCustomerIdGuard";
import { DELETE_DEVICE } from "../../../common/operations/mutations";
import {
  errorNotification,
  successNotification,
} from "../../../common/variables/notification";

export const useDeleteDevice = (deviceName: string) => {
  const selectedCustomerId = useCustomerIdGuard();

  const [deleteDeviceMutation, { data, loading }] = useMutation<
    DeleteDeviceMutation,
    DeleteDeviceMutationVariables
  >(DELETE_DEVICE);

  const deleteDevice = async (deviceId: string, locationId: string) => {
    return await deleteDeviceMutation({
      variables: {
        customerId: selectedCustomerId,
        deviceId: deviceId,
      },
      onCompleted: response => {
        if (response.deleteDevice) {
          successNotification(deviceName + " is deleted");
        }
      },
      onError: (error: ApolloError): void => {
        errorNotification(error.message);

        console.error(error.message);
      },
      update(cache) {
        const id = `Device:${locationId}#${deviceId}`;

        cache.evict({ id });

        cache.gc();
      },
    });
  };

  return { deleteDevice, data, loading };
};
