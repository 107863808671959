import { ChangeEvent, FC, useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import {
  Alert,
  Box,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useConfirm } from "material-ui-confirm";

import FullPageLoader from "../../../common/components/item/FullPageLoader";
import ItemDetailHeader from "../../../common/components/item/ItemDetailHeader";
import MainPaperWrapper from "../../../common/components/item/MainPaperWrapper";
import TimeInputFields from "../../../common/components/item/TimeInputFields";
import ControlTable from "../../../common/components/stream/ControlTable";
import BreadcrumbNavigation from "../../../common/components/tabs/BreadcrumbNavigation";
import confirmDialogStyleOptions from "../../../common/helpers/confirmDialogParams";
import useBeforeUnload from "../../../common/hooks/useBeforeUnload";
import { useStreamProcesses } from "../../../common/hooks/useStreamProcesses";
import StyledLoadingButton from "../../../common/providers/theme/design-tokens/LoadingButton/StyledLoadingButton";
import { errorNotification } from "../../../common/variables/notification";
import HLSStreamPlayer from "../../data-hub/components/liveview/components/cameraView/HLSStreamPlayer";
import { IDeviceTableRow } from "../hooks/useDeviceTableRows";
import useGetDeviceByUrl from "../hooks/useGetDeviceByUrl";
import { useGetZone } from "../hooks/useGetZone";
import { usePublishNode } from "../hooks/usePublishNode";
import { useUpdateZone } from "../zones-setup-page/hooks/useUpdateZone";
import {
  ICreateZoneValidationState,
  useZoneFormValidation,
} from "../zones-setup-page/hooks/useZoneFormValidation";
import { useCustomerIdGuard } from "../../../common/hooks/useCustomerIdGuard";
import { useSetIsRunningServiceStatus } from "../hooks/useSetIsRunningServiceStatus";
import { useSyncShadow } from "../../model-manager/hooks/useSyncShadow";
import { PAN_TILT_PATTERN } from "../../../common/variables/common";
import createS3KeyForZone from "../../../common/helpers/createS3KeyForZone";
import { useListenToDeleteZone } from "../zones-setup-page/hooks/useListenToDeleteZone";
import { validateZoneName } from "../../../common/helpers/validateZoneName";

const EditZonePage: FC = (): JSX.Element => {
  const customerId = useCustomerIdGuard();

  const { updateZone, loading } = useUpdateZone(true);

  const location = useLocation();
  const navigate = useNavigate();
  const { publishNode } = usePublishNode();
  const confirm = useConfirm();

  // TODO: deviceId from params is DE# without C#
  const param = useParams<{ deviceId: string; zoneId: string }>();
  const { deviceId = "", zoneId = "" } = param;
  const {
    data,
    getZone,
    loading: getZoneLoading,
  } = useGetZone(deviceId, zoneId);
  const [searchParams] = useSearchParams();

  const deviceName =
    location.state?.deviceName ?? searchParams.get("deviceName");
  const nodeId = location.state?.nodeId ?? searchParams.get("nodeId");

  const [minutesState, secondsState] = location.state?.inspectTime?.split(
    ":"
  ) ?? ["", ""];

  const [deviceDetail, setDeviceDetail] = useState<IDeviceTableRow | null>(
    null
  );
  const [status, setStatus] = useState(location.state?.status ?? {});
  const [shouldNotify, setShouldNotify] = useState(
    location.state?.shouldNotify ?? true
  );
  const [minutes, setMinutes] = useState(minutesState);
  const [seconds, setSeconds] = useState(secondsState);
  const [zoneName, setZoneName] = useState(location.state?.name ?? "");
  const [pan, setPan] = useState(location.state?.pan ?? "");
  const [tilt, setTilt] = useState(location.state?.tilt ?? "");
  const [zoom, setZoom] = useState(location.state?.zoom ?? "");

  const { fetchDevice, getServicesLoading } = useGetDeviceByUrl();
  const { startStreaming, stopStreaming } = useStreamProcesses();

  const { setIsRunningServiceStatus } = useSetIsRunningServiceStatus();
  const { syncShadowsForNode } = useSyncShadow();

  const { data: deletedZoneData } = useListenToDeleteZone(zoneId);

  const updateRunningStatus = () => {
    if (data?.getZone.nodeId && data.getZone.serviceId) {
      setIsRunningServiceStatus({
        nodeId: data.getZone.nodeId,
        runStatus: true,
        serviceId: data.getZone.serviceId,
      }).then(() => syncShadowsForNode(data.getZone.nodeId));
    }
  };

  useEffect((): void => {
    if (getServicesLoading) return;

    if (deviceId && customerId) {
      fetchDevice().then((device): void => {
        if (device) {
          setDeviceDetail(device);

          startStreaming(device);
        }
      });
    }
  }, [deviceId, customerId, getServicesLoading]);

  useEffect((): void => {
    if (deviceDetail) {
      stopStreaming(deviceDetail);
    }
  }, [deviceDetail]);

  const {
    resetValidationState,
    setValidationState,
    validateCreateZoneForm,
    validatePanTilt,
    validateZoom,
    validationState,
  } = useZoneFormValidation(data?.getZone.name);

  useBeforeUnload();

  const saveImage = (): void => {
    console.log("SAVE IMAGE");
    const device_name = deviceName.replace("DE#", "");

    publishNode({
      message: JSON.stringify({
        ACTION: "SAVE_IMAGE",
        s3_key: createS3KeyForZone(nodeId, zoneName, device_name), // generate s3_key e.g C_cleanconnect/C_cleanconnect_L_tp_N_solartrailer-agx4/keyframes/tp-ch4_keyframe.jpg // also need to save it to zone item
        TARGET: "CAMERA_MANAGER",
        device_id: device_name,
      }),
      nodeId,
    }).catch((error): void => {
      errorNotification("Something went wrong when saving image to S3");

      console.error(error);
    });
  };

  // temporary fix until we fix DDB schemas with new C# prefix for querying zones, annotations, etc. (this would involve adding the prefix for device and service creation)
  // note: there is a concern that when we update a zone to share the same name as an existing zone.
  // This will lead to a conflict of keyframe names in S3.
  // However there is preliminary steps to make sure zone names are unique, so there is less urgency to fix it from a S3 key perspective.
  const updateZoneHandler = (): void => {
    const isValid = validateCreateZoneForm(
      zoneName,
      pan,
      tilt,
      zoom,
      minutes,
      seconds
    );

    if (!isValid) return;

    const input = {
      deviceId: customerId + "#" + deviceId,
      zoneId: zoneId,
      name: zoneName,
      inspectTime: `${
        minutes.length === 1 ? "0" + minutes : minutes
      }:${seconds}`,
      status,
      pan,
      tilt,
      zoom,
      shouldNotify,
    };

    const validName = validateZoneName(zoneName);

    if (!validName) {
      return;
    }

    updateZone(input).then((): void => {
      updateRunningStatus();

      saveImage();

      navigate(
        `/device/${encodeURIComponent(deviceId)}/${encodeURIComponent(
          zoneId
        )}?deviceName=${deviceName}&nodeId=${encodeURIComponent(nodeId)}`
      );
    });
  };

  const zoneLink = `/device/${encodeURIComponent(
    deviceId
  )}/${encodeURIComponent(
    zoneId
  )}?deviceName=${deviceName}&nodeId=${encodeURIComponent(nodeId)}`;

  const confirmCancel = (): void => {
    confirm({
      title: "Are you sure you want to cancel Zone editing?",
      confirmationText: "Cancel",
      cancellationText: "Back",
      ...confirmDialogStyleOptions,
      confirmationButtonProps: {
        ...confirmDialogStyleOptions.confirmationButtonProps,
        disabled: loading,
        color: "primary",
      },
    }).then(async (): Promise<void> => {
      updateRunningStatus();

      resetValidationState();

      navigate(zoneLink);
    });
  };

  useEffect((): void => {
    if (!location.state && customerId) {
      getZone();
    }
  }, [customerId]);

  useEffect(() => {
    if (deletedZoneData?.listenToDeleteZone?.id === zoneId) {
      navigate(`/device/${encodeURIComponent(deviceId)}`);
    }
  }, [deletedZoneData]);

  useEffect((): void => {
    if (data?.getZone) {
      const [minutes, seconds] = data.getZone.inspectTime?.split(":") ?? [
        "",
        "",
      ];

      setStatus(data.getZone.status);

      setShouldNotify(data.getZone.shouldNotify ?? true);

      setZoneName(data.getZone.name);

      setMinutes(minutes);

      setSeconds(seconds);

      setPan(data.getZone.pan ?? "");

      setTilt(data.getZone.tilt ?? "");

      setZoom(data.getZone.zoom ?? "");
    }
  }, [data]);

  const mainZoneName = data?.getZone?.name ?? "";

  const breadcrumbItems = [
    { label: "Devices", path: "/devices" },
    {
      label: deviceName,
      path: "/device/" + encodeURIComponent(deviceId),
    },
    { label: zoneName, path: zoneLink },
    { label: `Edit ${mainZoneName}` },
  ];

  const setPanHandler = (pan = ""): void => {
    setValidationState(
      (prev): ICreateZoneValidationState => ({
        ...prev,
        pan: {
          hasError: false,
          errorMessage: "",
        },
        panValue: {
          hasError: false,
          errorMessage: "",
        },
      })
    );

    if (PAN_TILT_PATTERN.test(pan)) {
      setPan(pan);
    }
  };

  const setTiltHandler = (tilt = ""): void => {
    setValidationState(
      (prev): ICreateZoneValidationState => ({
        ...prev,
        tilt: {
          hasError: false,
          errorMessage: "",
        },
        tiltValue: {
          hasError: false,
          errorMessage: "",
        },
      })
    );

    if (PAN_TILT_PATTERN.test(tilt)) {
      setTilt(tilt);
    }
  };

  const setZoomHandler = (zoom = ""): void => {
    setValidationState(
      (prev): ICreateZoneValidationState => ({
        ...prev,
        zoom: {
          hasError: false,
          errorMessage: "",
        },
        zoomValue: {
          hasError: false,
          errorMessage: "",
        },
      })
    );

    setZoom(zoom);
  };

  const handleGoToPt = (pan: any, tilt: any, zoom: any): void => {
    const isValid = validatePanTilt(pan, tilt);

    const isValidZoom = validateZoom(zoom);

    if (!isValid && !isValidZoom) return;

    publishNode({
      message: JSON.stringify({
        TARGET: "PANTILT",
        ACTION: "GOTO_PT",
        device_name: deviceName,
        pan: pan,
        tilt: tilt,
        zoom: zoom,
      }),
      nodeId,
    });
  };

  const adjustByStep = {
    setHorizontalUp(): void {
      if (+pan > 179.9) return;

      setPan(`${(+pan + 0.1).toFixed(1)}`);
    },

    setHorizontalDown(): void {
      setPan(`${(+pan - 0.1).toFixed(1)}`);
    },

    setVerticalUp(): void {
      setTilt(`${(+tilt + 0.1).toFixed(1)}`);
    },

    setVerticalDown(): void {
      setTilt(`${(+tilt - 0.1).toFixed(1)}`);
    },
  };

  const setMinutesHandler = (e: ChangeEvent<HTMLInputElement>): void => {
    setValidationState(
      (prev): ICreateZoneValidationState => ({
        ...prev,
        minutes: {
          hasError: false,
          errorMessage: "",
        },
      })
    );

    setMinutes(`${e.target.value}`);
  };

  const setSecondsHandler = (e: ChangeEvent<HTMLInputElement>): void => {
    setValidationState(
      (prev): ICreateZoneValidationState => ({
        ...prev,
        seconds: {
          hasError: false,
          errorMessage: "",
        },
      })
    );

    setSeconds(`${e.target.value}`);
  };

  if (getZoneLoading || !data) {
    return <FullPageLoader />;
  }

  return (
    <>
      <BreadcrumbNavigation items={breadcrumbItems} />

      <ItemDetailHeader name={"Edit " + mainZoneName} />

      <MainPaperWrapper>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                width: "100%",
              }}
            >
              <TextField
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "8px",
                  },
                }}
                label="Zone name"
                value={zoneName}
                error={validationState.locationName.hasError}
                helperText={validationState.locationName.errorMessage}
                onChange={(e): void => setZoneName(e.target.value)}
              />
              <Typography sx={{ margin: "32px 0 8px 0" }} variant="subtitle1">
                Set up the camera position
              </Typography>

              {validationState.panValue.hasError && (
                <Alert severity="error">
                  {validationState.panValue.errorMessage}
                </Alert>
              )}
              {validationState.tiltValue.hasError && (
                <Alert severity="error">
                  {validationState.tiltValue.errorMessage}
                </Alert>
              )}
              {validationState.zoomValue.hasError && (
                <Alert severity="error">
                  {validationState.zoomValue.errorMessage}
                </Alert>
              )}

              <Box>
                <ControlTable
                  pan={pan}
                  tilt={tilt}
                  zoom={zoom}
                  validation={validationState}
                  handlePanInputChange={setPanHandler}
                  handleGoToPt={handleGoToPt}
                  handleTiltInputChange={setTiltHandler}
                  handleZoomInputChange={setZoomHandler}
                  movePtLeft={adjustByStep.setHorizontalDown}
                  movePtRight={adjustByStep.setHorizontalUp}
                  movePtUp={adjustByStep.setVerticalUp}
                  movePtDown={adjustByStep.setVerticalDown}
                  hasZoom={deviceDetail?.deviceData?.hasZoom}
                />
              </Box>

              <Divider sx={{ margin: "0.5em 0", opacity: "0.5" }} />

              <TimeInputFields
                validation={validationState}
                minutes={minutes}
                seconds={seconds}
                setMinutesHandler={setMinutesHandler}
                setSecondsHandler={setSecondsHandler}
              />

              <Divider sx={{ margin: "0.5em 0", opacity: "0.5" }} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                height: "100%",
              }}
            >
              <HLSStreamPlayer />
            </Box>
          </Grid>
        </Grid>
      </MainPaperWrapper>

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "1em",
        }}
      >
        <StyledLoadingButton
          sx={{
            marginTop: "1.5em",
          }}
          loading={loading}
          variant="outlined"
          color="inherit"
          onClick={confirmCancel}
        >
          Cancel
        </StyledLoadingButton>

        <StyledLoadingButton
          sx={{
            marginTop: "1.5em",
          }}
          loading={loading}
          variant="contained"
          color="primary"
          onClick={updateZoneHandler}
        >
          Save changes
        </StyledLoadingButton>
      </Box>
    </>
  );
};

export default EditZonePage;
