import React from "react";

import { Box, Stack } from "@mui/material";

import ExportDataButton from "../../../common/components/button/ExportDataButton";
import ReactDatePicker from "../../../common/components/datePicker/ReactDatePicker";
import CompanyFilterDropdown from "../../../common/components/layout/components/CompanyFilterDropdown";
import LicensePlatesFilterDropdown from "../../../common/components/layout/components/LicensePlatesFilterDropdown";
import PlatesCategoryFilterDropdown from "../../../common/components/layout/components/PlatesCategoryFilterDropdown";
import AllLocationsEventsFilterDropdownContainer from "../../../common/components/locations/recent-events/AllLocationsEventsFilterDropdownContainer";
import { AutocompleteOptionType } from "../../../common/models/autocomplete";

interface GateGuardFiltersProps {
  locationVariable: AutocompleteOptionType;
  handleLocationChange: (locationValue: AutocompleteOptionType) => void;
  handleTagLocationsChange: (
    tagLocations: AutocompleteOptionType[] | null
  ) => void;
  licensePlateVariable: AutocompleteOptionType;
  handleLicensePlateChange: (licensePlateValue: AutocompleteOptionType) => void;
  companyVariable: AutocompleteOptionType;
  handleCompanyChange: (companyValue: AutocompleteOptionType) => void;
  categoryVariable: AutocompleteOptionType;
  handleCategoryChange: (categoryValue: AutocompleteOptionType) => void;
  handleDownloadClick: () => void;
  loading: boolean;
}

const GateGuardFilters: React.FC<GateGuardFiltersProps> = ({
  handleLocationChange,
  handleTagLocationsChange,
  licensePlateVariable,
  handleLicensePlateChange,
  companyVariable,
  handleCompanyChange,
  categoryVariable,
  handleCategoryChange,
  handleDownloadClick,
  loading,
}) => (
  <Stack
    direction="row"
    justifyContent="space-between"
    alignItems="flex-start"
    spacing={2}
  >
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
      spacing={2}
    >
      <Box sx={{ minWidth: "180px" }}>
        <ReactDatePicker placeholderText="All time" />
      </Box>
      <Box sx={{ minWidth: "180px" }}>
        <AllLocationsEventsFilterDropdownContainer
          enhancedCapabilities
          setLocation={handleLocationChange}
          setTagLocations={handleTagLocationsChange}
        />
      </Box>
      <Box sx={{ minWidth: "180px" }}>
        <LicensePlatesFilterDropdown
          selectedValue={licensePlateVariable}
          setSelectedValue={handleLicensePlateChange}
        />
      </Box>
      <Box sx={{ minWidth: "180px" }}>
        <CompanyFilterDropdown
          selectedValue={companyVariable}
          setSelectedValue={handleCompanyChange}
        />
      </Box>
      <Box sx={{ minWidth: "180px" }}>
        <PlatesCategoryFilterDropdown
          selectedValue={categoryVariable}
          setSelectedValue={handleCategoryChange}
        />
      </Box>
    </Stack>

    <ExportDataButton
      sx={{
        color: theme => theme.palette.text.primary,
        marginLeft: "26px",
        "&.Mui-disabled": {
          color: "inherit",
          backgroundColor: "transparent",
        },
      }}
      onClick={handleDownloadClick}
      loading={loading}
    >
      Export Data
    </ExportDataButton>
  </Stack>
);

export default GateGuardFilters;
