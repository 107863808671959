import { ApolloError, useMutation } from "@apollo/client";
import {
  DeleteCategoryMutation,
  DeleteCategoryMutationVariables,
} from "../../../../../API";
import { DELETE_CATEGORY } from "../../../../../common/operations/mutations";
import { useCustomerIdGuard } from "../../../../../common/hooks/useCustomerIdGuard";
import {
  errorNotification,
  successNotification,
} from "../../../../../common/variables/notification";

export const useDeleteCategory = () => {
  const selectedCustomerId = useCustomerIdGuard();

  const [deleteCategoryMutation, { data, loading }] = useMutation<
    DeleteCategoryMutation,
    DeleteCategoryMutationVariables
  >(DELETE_CATEGORY);

  const deleteCategory = async (categoryId: string) => {
    return await deleteCategoryMutation({
      variables: {
        input: {
          categoryId: categoryId,
          customerId: selectedCustomerId,
        },
      },
      onCompleted: response => {
        if (response.deleteCategory) {
          const categoryName = response.deleteCategory?.categoryName;

          successNotification(categoryName + " is deleted");
        }
      },
      update(cache) {
        const normalizedId = cache.identify({
          id: categoryId,
          __typename: "Category",
        });

        cache.evict({ id: normalizedId });

        cache.gc();
      },
      onError: (error: ApolloError): void => {
        errorNotification(error.message);
      },
    });
  };

  return { deleteCategory, data, loading };
};
