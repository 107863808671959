import { useEffect, useMemo } from "react";

import { useLazyQuery } from "@apollo/client";

import type {
  AlertWildCardSubscription,
  GetAllWildCardsForSubscriptionEntityQuery,
  GetAllWildCardsForSubscriptionEntityQueryVariables,
} from "../../../API";
import { useCustomerIdGuard } from "../../../common/hooks/useCustomerIdGuard";
import { GET_ALL_WILDCARD_ALERT_EMAIL_SUBSCRIPTIONS } from "../../../common/operations/queries";
import { errorNotification } from "../../../common/variables/notification";
import client from "../../../configs/apolloClient";
import { DEFAULT_LIMIT_COUNT } from "../../../common/variables/common";

export const LIMIT = 25;

interface LazyGetWildCardSubscriptionsInterface {
  alertSubscriptions: AlertWildCardSubscription[];
  loading: boolean;
}

export const useLazyGetPaginatedAlertSubscriptions =
  (): LazyGetWildCardSubscriptionsInterface => {
    const selectedCustomerId = useCustomerIdGuard();

    const [getAlertSubscriptions, { data, loading }] = useLazyQuery<
      GetAllWildCardsForSubscriptionEntityQuery,
      GetAllWildCardsForSubscriptionEntityQueryVariables
    >(GET_ALL_WILDCARD_ALERT_EMAIL_SUBSCRIPTIONS, {
      fetchPolicy: "network-only",
    });

    useEffect((): void => {
      fetchLocations(selectedCustomerId);
    }, [selectedCustomerId]);

    const handleRequestError = async (): Promise<void> => {
      await client.cache.reset();

      errorNotification();
    };

    const fetchLocations = (customerId?: string, nextToken?: string): void => {
      getAlertSubscriptions({
        variables: {
          limit: DEFAULT_LIMIT_COUNT,
          nextToken,
        },
      })
        .then(async (response): Promise<void> => {
          if (response.data?.getAllWildCardsForSubscriptionEntity.nextToken) {
            fetchLocations(
              customerId,
              response.data?.getAllWildCardsForSubscriptionEntity.nextToken
            );
          }

          if (response.error) {
            await handleRequestError();
          }
        })
        .catch(async (): Promise<void> => {
          await handleRequestError();
        });
    };

    const alertSubscriptions: AlertWildCardSubscription[] = useMemo(
      (): AlertWildCardSubscription[] =>
        (data?.getAllWildCardsForSubscriptionEntity
          .items as AlertWildCardSubscription[]) || [],
      [data?.getAllWildCardsForSubscriptionEntity.items]
    );

    return { alertSubscriptions, loading };
  };
