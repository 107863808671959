import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import AddRoundedIcon from "@mui/icons-material/AddRounded";
import ErrorOutline from "@mui/icons-material/ErrorOutline";
import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormGroup from "@mui/material/FormGroup";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { CSSObject, styled, useTheme } from "@mui/material/styles";

import DeleteIconButton from "../../../../common/components/icons/DeleteIconButton";
import StatusToggle from "../../../../common/components/item/StatusToggle";
import { IModelOption } from "../../../../common/components/select/ServiceSelect/ModelsToRunMultiselect";
import { ServiceTypeEnum } from "../../../model-manager/variables/modelManager";
import { IModel } from "../../hooks/useDeviceTableRows";
import { useGetAnnotations } from "../../hooks/useGetAnnotations";

const DeviceModelGridItem = styled(Paper)<{
  isLeakAndLiquidLeakRunning: boolean;
}>(
  ({ theme, isLeakAndLiquidLeakRunning }): CSSObject => ({
    border: `1px solid ${
      isLeakAndLiquidLeakRunning
        ? theme.palette.error.main
        : theme.palette.otherStandardInputLine.main
    }`,
    height: "72px",
    minWidth: "330px",
    padding: "0.75em",
    display: "flex",
    alignItems: "center",
    borderRadius: "12px",
  })
);

interface IDeviceModelItemProps {
  deleteModelLoading: boolean;
  updateStatusLoading: boolean;
  model: IModelOption;
  currentModel: IModel;
  deviceWithPanTilt: boolean;
  runningLeakModels: string[];
  onStatusChange: (newStatus: boolean) => Promise<void>;
  onDeleteModel: () => Promise<void>;
}

const DeviceModelItem: FC<IDeviceModelItemProps> = ({
  deleteModelLoading,
  updateStatusLoading,
  model,
  currentModel,
  deviceWithPanTilt,
  runningLeakModels,
  onStatusChange,
  onDeleteModel,
}): JSX.Element => {
  const navigate = useNavigate();
  const theme = useTheme();

  const [runStatus, setRunStatus] = useState(!!currentModel?.isRunning);
  const [allowStartTlmModel, setAllowStartTlmModel] = useState(false);

  const { data: annotationsData, loading: annotationsDataLoading } =
    useGetAnnotations({
      serviceId: currentModel?.serviceId ?? "",
    });

  useEffect(() => {
    if (!annotationsDataLoading && annotationsData?.getAnnotations.items) {
      setAllowStartTlmModel(annotationsData?.getAnnotations.items.length > 0);
    }
  }, [annotationsData?.getAnnotations.items]);

  const addModelRedirection = (): void => {
    if (!model.value) return;

    navigate(`add-model/${model.value}`);
  };

  const modelOverviewRedirection = (): void => {
    if (!currentModel) return;

    navigate(`model/${encodeURIComponent(currentModel.serviceName)}`);
  };

  const handleDeleteModel = async (): Promise<void> => {
    await onDeleteModel();
  };

  const handleStatusChange = async (): Promise<void> => {
    if (updateStatusLoading) return;

    await onStatusChange(!runStatus);

    setRunStatus(!runStatus);
  };

  const notRunningClassName = "device-not-running";

  const loading = updateStatusLoading || deleteModelLoading;

  const isLeakOrLiquidLeakRunning =
    runningLeakModels.length === 1 &&
    (runningLeakModels.includes(ServiceTypeEnum.LEAK) ||
      runningLeakModels.includes(ServiceTypeEnum.GAS_LEAK) ||
      runningLeakModels.includes(ServiceTypeEnum.LIQUID_LEAK));

  const isLeakAndLiquidLeakRunning = runningLeakModels.length === 2;

  return (
    <DeviceModelGridItem
      elevation={0}
      isLeakAndLiquidLeakRunning={isLeakAndLiquidLeakRunning}
    >
      {currentModel ? (
        <Box
          onClick={modelOverviewRedirection}
          sx={{
            gap: "1em",
            display: "flex",
            alignItems: "center",
            opacity: loading ? 0.6 : 1,
            cursor: "pointer",
            pointerEvents: loading ? "none" : "auto",
            "&:hover": {
              color: theme.palette.primary.dark,
            },
            "&.device-not-running:hover": {
              pointerEvents: "none",
              color: theme.palette.text.primary,
              opacity: 1,
            },
          }}
        >
          {model.icon}

          <Typography variant="body2Bold" className={notRunningClassName}>
            {model.name}
          </Typography>
        </Box>
      ) : (
        <Box
          onClick={modelOverviewRedirection}
          sx={{
            gap: "1em",
            display: "flex",
            alignItems: "center",
            opacity: loading ? 0.6 : 1,
          }}
        >
          {model.icon}

          <Typography variant="body2Bold" className={notRunningClassName}>
            {model.name}
          </Typography>
        </Box>
      )}

      {!currentModel && (
        <Button
          className={notRunningClassName}
          onClick={addModelRedirection}
          sx={{
            marginLeft: "auto",
            textTransform: "none",
            cursor: "pointer",
            opacity: loading ? 0.6 : 1,
            color: theme.palette.primary.main,
            "&.device-not-running span": {
              opacity: 1,
              pointerEvents: "auto",
              color: theme.palette.primary.main,
            },
          }}
          disableRipple
          variant="text"
          startIcon={<AddRoundedIcon />}
        >
          Add model
        </Button>
      )}

      {currentModel && (
        <Box
          sx={{
            marginLeft: "auto",
            display: "flex",
            gap: "1em",
            alignItems: "center",
            cursor: "pointer",
            opacity: updateStatusLoading ? 0.6 : 1,
          }}
        >
          <IconButton
            disableRipple
            onClick={handleDeleteModel}
            className={notRunningClassName}
          >
            {deleteModelLoading ? (
              <CircularProgress size={14} thickness={6} />
            ) : (
              <Box>
                <DeleteIconButton />
              </Box>
            )}
          </IconButton>

          <FormGroup
            sx={{
              cursor: "pointer",
              "& .MuiBox-root": {
                pointerEvents: loading ? "none" : "auto",
              },
            }}
          >
            <Tooltip
              title={
                currentModel?.serviceName === ServiceTypeEnum.TANK_LEVEL &&
                !allowStartTlmModel
                  ? "TLM model can't be started if Tanks have not been defined"
                  : !deviceWithPanTilt
                  ? ""
                  : runStatus
                  ? "Disable model. Zones will not be monitored while disabled"
                  : isLeakOrLiquidLeakRunning
                  ? `Leak and Liquid Leak cannot run at the same time. Please disable ${runningLeakModels[0]} model before starting ${currentModel?.serviceName} model.`
                  : "Enable model. In case you enable the model, it will be monitored"
              }
              arrow
              placement="top"
            >
              <span>
                <StatusToggle
                  loading={updateStatusLoading && !deleteModelLoading}
                  disabled={
                    loading ||
                    (currentModel?.serviceName === ServiceTypeEnum.TANK_LEVEL &&
                      !allowStartTlmModel) ||
                    (isLeakOrLiquidLeakRunning &&
                      runningLeakModels[0] !== currentModel?.serviceName)
                  }
                  checked={runStatus}
                  handleChange={handleStatusChange}
                />
              </span>
            </Tooltip>
          </FormGroup>
          {isLeakAndLiquidLeakRunning && (
            <Tooltip
              title={
                "Leak and Liquid Leak models are running at the same time. Please disable one of them"
              }
              arrow
              placement="top"
            >
              <span>
                <ErrorOutline sx={{ color: "error.main" }} />
              </span>
            </Tooltip>
          )}
        </Box>
      )}
    </DeviceModelGridItem>
  );
};

export default DeviceModelItem;
