import dayjs from "dayjs";

import { TimePeriod } from "../../../API";
import { DEFAULT_STATISTICS_DATE_FORMAT } from "../hooks/useAllAlertsChart";
import { IStatisticsResponseModel } from "../models/chart";

function convertDatesHelper(
  dates: IStatisticsResponseModel[],
  timePeriod: TimePeriod
) {
  if (timePeriod === TimePeriod.ONE_DAY && dates.length) {
    return dates.map(el => {
      const date = new Date(el.date);
      const hours = date.getHours();
      const minutes = date.getMinutes();

      return `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}`;
    });
  }

  return dates.map(el => {
    return dayjs(el.date).format(DEFAULT_STATISTICS_DATE_FORMAT);
  });
}

export default convertDatesHelper;
