import { ChangeEvent, FC, useEffect, useMemo, useState } from "react";

import Box from "@mui/material/Box";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import InputAdornment from "@mui/material/InputAdornment";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import IconButton from "@mui/material/IconButton";
import ExpandBox from "../../../../../../../../common/components/expandBox/ExpandBox";
import CloseIcon from "../../../../../../../../common/components/icons/CloseIcon";
import CloseIconButton from "../../../../../../../../common/components/icons/CloseIconButton";
import DoneIconButton from "../../../../../../../../common/components/icons/DoneIconButton";
import SearchInput from "../../../../../../../../common/components/search/SearchInput";
import { useGetPaginatedZonesThresholds } from "../../hooks/useGetPaginatedZonesThresholds";
import { ThresholdColumnStateEnum } from "../../hooks/useGetZonesByDeviceTableColumns";
import { ZoneThresholdItem } from "../../hooks/useGetZonesByDeviceTableRow";
import { useUpdateZoneThresholdValue } from "../../hooks/useUpdateZoneThresholdValue";
import CreateZoneThresholdDialog from "../CreateZoneThresholdDialog";
import ZoneTypesList from "./ZoneTypesList";

interface IZoneTypesSelectState {
  id: string;
  value: string;
  name: string;
}

const defaultZoneTypesSelectState: IZoneTypesSelectState = {
  id: "",
  value: "",
  name: "",
};

interface IZoneTypesSelectProps {
  zoneId: string;
  zoneName: string;
  deviceId: string;
  threshold: ZoneThresholdItem | null;
  setMode: (mode: ThresholdColumnStateEnum) => void;
  addMode?: boolean;
}

const ZoneTypesSelect: FC<IZoneTypesSelectProps> = ({
  zoneId,
  zoneName,
  deviceId,
  threshold,
  setMode,
  addMode,
}) => {
  const { updateZoneThreshold } = useUpdateZoneThresholdValue();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [isPopperOpen, setIsPopperOpen] = useState(true);
  const [thresholdValue, setThresholdValue] = useState<IZoneTypesSelectState>(
    defaultZoneTypesSelectState
  );
  const [searchValue, setSearchValue] = useState("");

  const { zonesThresholds, loading: zoneThresholdsLoading } =
    useGetPaginatedZonesThresholds();

  useEffect(() => {
    const newValue = threshold?.value
      ? {
          id: threshold.id,
          value: threshold.value.toString(),
          name: threshold.name,
        }
      : defaultZoneTypesSelectState;

    if (
      threshold?.value !== thresholdValue.value ||
      threshold?.name !== thresholdValue.name
    ) {
      setThresholdValue({
        ...newValue,
      });
    }
  }, [threshold?.value, threshold?.name]);

  const memoizedThresholds = useMemo(() => {
    if (!zonesThresholds) {
      return [];
    }

    if (!searchValue) {
      return zonesThresholds.items;
    }

    return zonesThresholds.items.filter(zoneThreshold =>
      zoneThreshold.name.toLowerCase().includes(searchValue.toLowerCase())
    );
  }, [zonesThresholds?.items, searchValue]);

  useEffect(() => {
    const domElement = document.getElementById(zoneId);

    setAnchorEl(domElement);
  }, []);

  const clickAwayHandler = () => {
    setMode(ThresholdColumnStateEnum.READ_MODE);
  };

  const onPopupSwitch = () => setIsPopperOpen(prev => !prev);

  const handleThresholdInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let formattedValue = threshold?.value.toString() ?? "";

    let valueToSave: string[] = [];

    if (threshold?.name) {
      formattedValue = `${threshold?.value} (${threshold?.name})`;

      valueToSave = event.target.value.split(formattedValue);

      if (valueToSave[1]) {
        event.target.value = valueToSave[1];
      }
    }

    const floatMatch = event.target.value.match(/^\d*\.?\d{0,2}$/);

    if (!floatMatch) {
      return;
    }

    let newValue = "";

    if (floatMatch) {
      newValue = floatMatch[0];

      event.target.value = newValue;
    }

    setThresholdValue({
      id: "",
      value: newValue,
      name: "",
    });
  };

  const handleThresholdChange = (value: string) => {
    const zoneThreshold = memoizedThresholds.find(
      zoneThreshold => zoneThreshold.id === value
    );

    if (zoneThreshold) {
      setThresholdValue({
        id: zoneThreshold.id,
        value: zoneThreshold.value.toString(),
        name: zoneThreshold.name,
      });
    } else {
      setThresholdValue({
        id: "",
        value: parseFloat(value).toString(),
        name: "",
      });
    }
  };

  const handleUpdateZoneThreshold = () => {
    let newThresholdValue = thresholdValue?.value.toString() ?? "0";

    if (
      thresholdValue &&
      thresholdValue?.id !== "" &&
      thresholdValue?.id.includes("ZT#")
    ) {
      newThresholdValue = thresholdValue.id;
    }

    if (
      newThresholdValue.includes("ZT#") &&
      newThresholdValue !== threshold?.id
    ) {
      updateZoneThreshold(
        deviceId,
        zoneId,
        zoneName,
        newThresholdValue,
        addMode
      );
    }

    if (
      !newThresholdValue.includes("ZT#") &&
      newThresholdValue !== threshold?.value
    ) {
      updateZoneThreshold(
        deviceId,
        zoneId,
        zoneName,
        newThresholdValue,
        addMode
      );
    }

    setMode(ThresholdColumnStateEnum.READ_MODE);
  };

  const handleSearchValueChange = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    setSearchValue(event.target.value);
  };

  const clearSearchValue = (): void => {
    setSearchValue("");
  };

  const clearInputValue = (): void => {
    setThresholdValue(defaultZoneTypesSelectState);

    const domElement = document.getElementById(zoneId);

    if (domElement) {
      domElement.focus();
    }
  };

  const open = Boolean(anchorEl);

  const id = open ? `popper-${zoneId}` : undefined;

  let formattedValue = thresholdValue?.value.toString();

  if (thresholdValue?.name) {
    formattedValue = `${thresholdValue?.value} (${thresholdValue.name})`;
  }

  return (
    <Box
      sx={{
        display: "flex",
        gap: "0.5em",
        alignItems: "center",
      }}
    >
      <ClickAwayListener onClickAway={clickAwayHandler}>
        <Box>
          <TextField
            id={zoneId}
            margin="dense"
            size="small"
            color="primary"
            autoFocus
            value={formattedValue}
            onChange={handleThresholdInputChange}
            InputProps={{
              sx: { fontSize: "14px" },
              endAdornment: (
                <InputAdornment position="end">
                  MCF/d
                  <IconButton
                    onClick={clearInputValue}
                    disableRipple
                    sx={{
                      display: formattedValue ? "flex" : "none",
                    }}
                  >
                    <CloseIcon
                      sx={{
                        color: theme => theme.palette.otherTextTertiary.main,
                        fontSize: "0.75em",
                      }}
                    />
                  </IconButton>
                  {isPopperOpen ? (
                    <ExpandBox onClick={onPopupSwitch} type="less" />
                  ) : (
                    <ExpandBox onClick={onPopupSwitch} type="more" />
                  )}
                </InputAdornment>
              ),
            }}
          />
          <Popper
            id={id}
            open={open && isPopperOpen}
            anchorEl={anchorEl}
            placement="bottom-start"
            sx={{ zIndex: 7 }}
          >
            <Paper
              elevation={1}
              sx={{
                backgroundColor: theme => theme.palette.otherBackground.main,
                borderRadius: "8px",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    p: "1em 1.5em",
                  }}
                  component="div"
                  variant="caption"
                  color={theme => theme.palette.otherTextTertiary.main}
                >
                  Select zone type or type custom threshold
                </Typography>
                <Tooltip
                  placement="top"
                  title={
                    memoizedThresholds.length === 0 && !zoneThresholdsLoading
                      ? "The search is unavailable as there is no zone types in the list"
                      : null
                  }
                >
                  <div>
                    <SearchInput
                      sx={{
                        display: "flex",
                        backgroundColor: theme =>
                          theme.palette.otherBackgroundLight.main,
                        pl: "1em",
                        height: "40px",
                        "& .MuiInputBase-root": {
                          height: "40px",
                          "& .MuiInputAdornment-root": {
                            top: "19px",
                          },
                        },
                      }}
                      disabled={
                        memoizedThresholds.length === 0 &&
                        !zoneThresholdsLoading
                      }
                      variant="standard"
                      value={searchValue}
                      searchValue={searchValue}
                      handleSearchValueChange={handleSearchValueChange}
                      clearSearchValue={clearSearchValue}
                    />
                  </div>
                </Tooltip>

                <ZoneTypesList
                  zoneTypes={memoizedThresholds}
                  onClick={handleThresholdChange}
                  loading={zoneThresholdsLoading}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  width: "100%",
                  backgroundColor: theme =>
                    theme.palette.otherBackgroundLight.main,
                }}
              >
                <CreateZoneThresholdDialog />
              </Box>
            </Paper>
          </Popper>
        </Box>
      </ClickAwayListener>

      <DoneIconButton onClick={handleUpdateZoneThreshold} />
      <CloseIconButton
        onClick={() => setMode(ThresholdColumnStateEnum.READ_MODE)}
      />
    </Box>
  );
};

export default ZoneTypesSelect;
