import { FC, memo, useCallback, useEffect, useState } from "react";
import DatePicker from "react-datepicker";

import CalendarIcon from "./CalendarIcon";
import { dateRangeVariable, useDateRange } from "./modelsDatePickers";
import StyledDatePickerWrapper from "./StyledDatePicker";

import "react-datepicker/dist/react-datepicker.css";

export type Dates = [Date | null, Date | null];

type Props = {
  placeholderText?: string;
  dateFormat?: string;
};

const ReactDatePickerContainer: FC<Props> = ({
  placeholderText,
}): JSX.Element => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const handleDateChange = useCallback(
    (dates: Dates): void => {
      const [start, end] = dates;

      setStartDate(start);

      setEndDate(end);

      if (start && end) {
        const endOfDay = end;

        endOfDay.setHours(23);

        endOfDay.setMinutes(59);

        endOfDay.setSeconds(59);

        dateRangeVariable({
          from: start,
          to: new Date(endOfDay),
        });
      }

      if (!start && !end) {
        dateRangeVariable({
          from: null,
          to: null,
        });
      }
    },
    [dateRangeVariable]
  );

  const dateRange = useDateRange();

  useEffect((): void => {
    if (dateRange) {
      setStartDate(dateRange.from);

      setEndDate(dateRange.to);
    } else {
      setStartDate(null);

      setEndDate(null);
    }
  }, [dateRange]);

  useEffect((): (() => void) => {
    return (): void => {
      setStartDate(null);

      setEndDate(null);

      dateRangeVariable(null);
    };
  }, []);

  return (
    <StyledDatePickerWrapper>
      <DatePicker
        selected={startDate}
        onChange={handleDateChange}
        icon={<CalendarIcon />}
        showIcon
        startDate={startDate}
        endDate={endDate}
        maxDate={new Date()}
        monthsShown={2}
        formatWeekDay={(nameOfDay: string): string => nameOfDay.slice(0, 3)}
        placeholderText={placeholderText}
        selectsRange
        isClearable
      />
    </StyledDatePickerWrapper>
  );
};

const ReactDatePicker = memo(ReactDatePickerContainer);

export default ReactDatePicker;
