import Chip, { ChipProps } from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import { CSSObject, styled } from "@mui/material/styles";

const StyledFilledChip = styled(Chip)(
  ({ theme }): CSSObject => ({
    "&.MuiChip-root": {
      borderRadius: "10px",
      backgroundColor: theme.palette.primary.shades?.["8p"],
      border: `1px solid ${theme.palette.primary.shades?.["30p"]}`,
      padding: "4px 8px",
      "& .MuiChip-label": {
        padding: "0",
        color: theme.palette.primary.dark,
        fontSize: "0.75rem",
        fontWeight: 500,
      },
    },
  })
);

const ChipFilledPrimary = ({ ...props }: ChipProps): JSX.Element => {
  return (
    <StyledFilledChip
      label={<Typography variant="tag">{props.label}</Typography>}
      {...props}
    />
  );
};

export default ChipFilledPrimary;
