const S3Icon = (): JSX.Element => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="8" fill="#F7F8FA" />
      <path
        d="M23.9368 31.856L12.8984 34.4954V14.5L23.9368 17.0594V31.856Z"
        fill="#E05243"
      />
      <path
        d="M23.6143 31.936L34.3304 34.4954V14.5L23.6143 17.0594V31.936Z"
        fill="#8C3123"
      />
      <path
        d="M23.6143 9.14453V16.9028L28.2875 18.0225V11.464L23.6143 9.14453ZM34.3304 14.5033V34.4987L36.4253 33.459V15.4631L34.3304 14.5033ZM23.6143 20.6619V28.4201L28.2875 27.7803V21.2218L23.6143 20.6619ZM28.2875 30.9795L23.6143 32.0993V39.8575L28.2875 37.538V30.9795Z"
        fill="#E05243"
      />
      <path
        d="M23.6143 39.8555V32.0972L18.9411 30.9775V37.536L23.6143 39.8555ZM12.8981 34.4967L12.8981 14.5013L10.8033 15.541L10.8033 33.5369L12.8981 34.4967ZM23.6143 28.3381L23.6143 20.5799L18.9411 21.2197L18.9411 27.7782L23.6143 28.3381ZM18.9411 18.0205L23.6143 16.9007V9.14251L18.9411 11.462V18.0205Z"
        fill="#8C3123"
      />
      <path
        d="M28.2878 18.02L23.6146 18.8998L18.9414 18.02L23.6146 16.8203L28.2878 18.02Z"
        fill="#5E1F18"
      />
      <path
        d="M28.2878 30.9775L23.6146 30.0977L18.9414 30.9775L23.6146 32.2572L28.2878 30.9775Z"
        fill="#F2B0A9"
      />
    </svg>
  );
};

export default S3Icon;
