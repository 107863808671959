import { ApolloError, useMutation } from "@apollo/client";

import {
  UpdateZoneInput,
  UpdateZoneMutation,
  UpdateZoneMutationVariables,
} from "../../../../API";
import { UPDATE_ZONE } from "../../../../common/operations/mutations";
import { useCustomerIdGuard } from "../../../../common/hooks/useCustomerIdGuard";
import { successNotification } from "../../../../common/variables/notification";

export const useUpdateZone = (showNotification = false) => {
  const selectedCustomerId = useCustomerIdGuard();

  const [updateZoneMutation, { data, loading }] = useMutation<
    UpdateZoneMutation,
    UpdateZoneMutationVariables
  >(UPDATE_ZONE, {
    fetchPolicy: "no-cache",
    onCompleted: response => {
      if (response.updateZone && showNotification) {
        const { name } = response.updateZone;

        successNotification(`Zone ${name} is updated`);
      }
    },
    onError: (error: ApolloError): void => {
      console.error(error);
    },
  });

  const updateZone = async (input: Omit<UpdateZoneInput, "customerId">) => {
    return await updateZoneMutation({
      variables: {
        input: {
          ...input,
          customerId: selectedCustomerId,
        },
      },
    });
  };

  return { updateZone, data, loading };
};
