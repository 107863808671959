/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { useEffect, useMemo, useState } from "react";

import { useLazyQuery } from "@apollo/client";
import groupBy from "lodash/groupBy";

import {
  GetLicensePlatesByCustomerQuery,
  GetLicensePlatesByCustomerQueryVariables,
  LicensePlate,
  PaginatedLicensePlates,
} from "../../../../../API";
import { useCustomerIdGuard } from "../../../../../common/hooks/useCustomerIdGuard";
import useLicensePlateCategoryFromCache from "../../../../../common/hooks/useLicensePlateCategoryFromCache";
import { GET_LICENSE_PLATES_BY_CUSTOMER } from "../../../../../common/operations/queries";
import { errorNotification } from "../../../../../common/variables/notification";
import client from "../../../../../configs/apolloClient";
import { useLicensePlatesTableVariables } from "../variables/licensePlatesTable";
import { useGetPaginatedPlateCategories } from "./useGetPaginatedPlateCategories";

export interface ILicensePlatesRow extends LicensePlate {
  categoryName: string | null | undefined;
}

export type LicensePlateData = {
  [categoryName: string]: ILicensePlatesRow[];
} | null;

export const useGetPaginatedLicensePlates = () => {
  const selectedCustomerId = useCustomerIdGuard();
  const licensePlatesSearchVariable = useLicensePlatesTableVariables();

  const { categories } = useGetPaginatedPlateCategories();

  const { getCachedCategory } = useLicensePlateCategoryFromCache();

  const [getLicensePlates, { data }] = useLazyQuery<
    GetLicensePlatesByCustomerQuery,
    GetLicensePlatesByCustomerQueryVariables
  >(GET_LICENSE_PLATES_BY_CUSTOMER, {
    fetchPolicy: "network-only",
  });

  const [loading, setLoading] = useState(false);

  useEffect((): void => {
    if (selectedCustomerId) {
      setLoading(true);

      fetchLicensePlates(selectedCustomerId);
    }
  }, [selectedCustomerId]);

  const handleRequestError = async (): Promise<void> => {
    await client.cache.reset();

    errorNotification();
  };

  const fetchLicensePlates = (customerId: string, nextToken?: string): void => {
    getLicensePlates({
      variables: {
        input: {
          customerId,
          nextToken,
        },
      },
    })
      .then(async (response): Promise<void> => {
        if (response.data?.getLicensePlatesByCustomer.nextToken) {
          fetchLicensePlates(
            customerId,
            response.data?.getLicensePlatesByCustomer.nextToken
          );
        } else {
          setLoading(false);
        }

        if (response.error) {
          await handleRequestError();

          setLoading(false);
        }
      })
      .catch(async (): Promise<void> => {
        await handleRequestError();

        setLoading(false);
      });
  };

  const licensePlates: LicensePlateData = useMemo(() => {
    if (!data?.getLicensePlatesByCustomer || !categories) {
      return null;
    }

    // response contains all license plates therefore there will not be any empty categories here
    const response = data?.getLicensePlatesByCustomer as PaginatedLicensePlates;
    const filterValue =
      licensePlatesSearchVariable.filterValue.toLocaleLowerCase();

    // add categoryName to licensePlates
    const withCategoryName: ILicensePlatesRow[] = response?.items?.map(
      (item: LicensePlate) => {
        const cache = getCachedCategory(item.categoryId);

        return {
          ...item,
          categoryName: cache?.categoryName ?? "Unknown",
        };
      }
    );

    // filter out license plates
    const filtered =
      withCategoryName.filter((item): boolean => {
        if (filterValue === "") return true;

        return (
          (item.categoryName?.toLowerCase().includes(filterValue) ||
            item.licensePlate.toLowerCase().includes(filterValue) ||
            item.company?.toLowerCase().includes(filterValue) ||
            item.driverName?.toLowerCase().includes(filterValue)) ??
          false
        );
      }) || [];

    let result = groupBy(filtered, "categoryName") as LicensePlateData;

    // start process of adding in missing categories
    const setOfCategories = new Set();

    categories.items.forEach(category => {
      setOfCategories.add(category.categoryName);
    });

    for (const key in result) {
      if (setOfCategories.has(key)) {
        // If the key exists in the Set, remove it
        setOfCategories.delete(key);
      }
    }

    if (result === null) result = {};

    setOfCategories.forEach(categoryName => {
      // typescript thinks result could be null even with above guard
      if (result === null) result = {};

      // add empty category to result
      result[categoryName as string] = [];
    });

    // remove empty string key if it exists
    if (result[""] !== undefined) {
      // note: we should have not let this category be created in DDB
      delete result[""];
    }

    return result;
  }, [
    data?.getLicensePlatesByCustomer,
    categories,
    licensePlatesSearchVariable.filterValue,
  ]);

  return {
    licensePlates,
    categories,
    searchValue: licensePlatesSearchVariable.filterValue,
    loading,
  };
};
