import { Box, Tooltip, Typography, Zoom } from "@mui/material";
import { useConfirm } from "material-ui-confirm";

import DeleteIconButton from "../../../../../common/components/icons/DeleteIconButton";
import confirmDialogStyleOptions from "../../../../../common/helpers/confirmDialogParams";
import { useCustomerIdGuard } from "../../../../../common/hooks/useCustomerIdGuard";
import { IAlertsTableRow } from "../hooks/useAlertsTableRow";
import { useDeleteAlert } from "../hooks/useDeleteAlert";

type DeleteAlertContainerProps = {
  row: IAlertsTableRow;
};

const DeleteAlertContainer = ({
  row,
}: DeleteAlertContainerProps): JSX.Element => {
  const confirm = useConfirm();

  const { deleteAlert, loading } = useDeleteAlert();

  const customerId = useCustomerIdGuard();

  const handleClick = (): void => {
    confirm({
      title: `Are you sure you want to delete ${row.alertName} alert?`,
      content: (
        <Typography
          variant="body2Regular"
          color={theme => theme.palette.text.secondary}
        >
          If you delete the alert you won’t receive notifications anymore.
        </Typography>
      ),
      confirmationText: "Delete",
      cancellationText: "Cancel",
      ...confirmDialogStyleOptions,
      confirmationButtonProps: {
        ...confirmDialogStyleOptions.confirmationButtonProps,
        disabled: loading,
      },
    }).then(async (): Promise<void> => {
      if (customerId && row.rowId) {
        await deleteAlert({
          customerId: customerId as string,
          id: row.rowId as string,
          alertName: row.alertName as string,
        });
      } else {
        console.error("customerId or row.id is null or undefined");
      }
    });
  };

  return (
    <Tooltip
      TransitionComponent={Zoom}
      arrow
      disableInteractive
      placement="top"
      title="Delete"
    >
      <Box
        component="span"
        sx={{
          height: "32px",
        }}
      >
        <DeleteIconButton onClick={handleClick} />
      </Box>
    </Tooltip>
  );
};

export default DeleteAlertContainer;
