import { ApolloError, useMutation } from "@apollo/client";

import {
  DeleteAlertInput,
  DeleteAlertMutation,
  DeleteAlertMutationVariables,
} from "../../../../../API";
import { DELETE_ALERT } from "../../../../../common/operations/mutations";
import {
  errorNotification,
  successNotification,
} from "../../../../../common/variables/notification";

export const useDeleteAlert = () => {
  const [removeAlert, { data, loading }] = useMutation<
    DeleteAlertMutation,
    DeleteAlertMutationVariables
  >(DELETE_ALERT);

  const deleteAlert = async (input: DeleteAlertInput) => {
    await removeAlert({
      variables: {
        input,
      },
      onCompleted: response => {
        const { deleteAlert } = response;

        if (deleteAlert) {
          successNotification(`${deleteAlert.alertName} alert is deleted`);
        }
      },
      onError: (error: ApolloError): void => {
        errorNotification(
          `Something went wrong, ${input.alertName} alert isn’t deleted`
        );

        console.error(error.message);
      },
      update(cache) {
        const normalizedId = cache.identify({
          id: input.id,
          __typename: "Alert",
        });

        cache.evict({ id: normalizedId });

        cache.gc();
      },
    });
  };

  return { deleteAlert, data, loading };
};
