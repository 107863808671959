import Chip, { ChipProps } from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import { CSSObject, styled } from "@mui/material/styles";

const StyledFilledChip = styled(Chip)(
  ({ theme }): CSSObject => ({
    "&.MuiChip-root": {
      color: theme.palette.otherTextTertiary.main,
      fontSize: "0.75rem",
      fontWeight: 500,
      borderRadius: "10px",
      backgroundColor: theme.palette.otherBackground.main,
      border: `1px solid ${theme.palette.otherOutlineBorder.main}`,
      padding: "4px 8px",
      "& .MuiChip-label": {
        padding: "0",
        color: theme.palette.otherTextTertiary.main,
        fontSize: "0.75rem",
        fontWeight: 500,
      },
    },
  })
);

const ChipFilledOther = ({ ...props }: ChipProps): JSX.Element => {
  return (
    <StyledFilledChip
      label={
        <Typography
          variant="tag"
          sx={{
            color: (theme): string => theme.palette.otherTextTertiary.main,
          }}
        >
          {props.label}
        </Typography>
      }
      {...props}
    />
  );
};

export default ChipFilledOther;
