import { FC } from "react";

import LinearProgress from "@mui/material/LinearProgress";
import TableContainer from "@mui/material/TableContainer";
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridRowHeightReturnValue,
  GridRowsProp,
} from "@mui/x-data-grid";

import FullPageLoader from "../../../../../common/components/item/FullPageLoader";
import MediaCardDialog from "../../../../../common/components/media/MediaCardDialog";
import TableNoDataOverlay from "../../../../../common/components/table/TableNoDataOverlay";
import { dataGridServerStyles } from "../../../../../common/providers/theme/design-tokens/DataGrid/dataGridServer.styles";
import { DEFAULT_COLUMN_HEADER_HEIGHT } from "../../../../../common/variables/common";
import { TablePaperContainer } from "../../../../../leak-finder/components/VideosTable";
import { useRowClick } from "../../../hooks/useRowClick";
import {
  DEFAULT_EVENTS_LIMIT,
  DEFAULT_EVENTS_PAGE_SIZE_OPTIONS,
} from "./events.constants";

interface IEventsTableServerProps {
  rows: GridRowsProp;
  columns: GridColDef[];
  paginationModel: GridPaginationModel;
  loading: boolean;
  isActivePageChange: boolean;
  hasNextPage: boolean;
  onPaginationModelChange: (paginationModel: GridPaginationModel) => void;
}

const EventsTableServer: FC<IEventsTableServerProps> = ({
  rows,
  columns,
  paginationModel,
  loading,
  hasNextPage,
  isActivePageChange,
  onPaginationModelChange,
}: IEventsTableServerProps) => {
  const {
    open,
    mediaOutput,
    signedUrl,
    mediaOutputLoading,
    handleRowClick,
    closeDialog,
  } = useRowClick();

  return (
    <TableContainer component={TablePaperContainer}>
      {mediaOutputLoading && <FullPageLoader />}
      {open && (
        <MediaCardDialog
          fullWidth
          mediaOutput={mediaOutput}
          signedUrl={signedUrl}
          open={open}
          onClose={closeDialog}
        />
      )}
      <DataGrid
        sx={{
          ...dataGridServerStyles.sx,
          "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
            py: "8px",
          },
          "& .MuiButtonBase-root.MuiIconButton-root:last-child": {
            pointerEvents: isActivePageChange ? "auto" : "none",
            cursor: isActivePageChange ? "pointer" : "none",
            backgroundColor: "transparent",
            color: theme =>
              isActivePageChange ? "inherit" : theme.palette.action.disabled,
          },
        }}
        columnHeaderHeight={DEFAULT_COLUMN_HEADER_HEIGHT}
        rows={rows}
        columns={columns}
        slots={{
          loadingOverlay: () => <LinearProgress />,
          noRowsOverlay: TableNoDataOverlay,
        }}
        rowCount={-1}
        onRowClick={handleRowClick()}
        autoHeight
        getRowHeight={(): GridRowHeightReturnValue => 96}
        rowHeight={96}
        disableRowSelectionOnClick
        hideFooterSelectedRowCount
        getRowId={(row): string => row.rowId}
        loading={loading}
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={onPaginationModelChange}
        paginationMeta={{ hasNextPage }}
        pageSizeOptions={DEFAULT_EVENTS_PAGE_SIZE_OPTIONS}
        sortingOrder={["desc", "asc"]}
        initialState={{
          sorting: {
            sortModel: [
              {
                field: "timestamp",
                sort: "desc",
              },
            ],
          },
          pagination: {
            paginationModel: {
              pageSize: DEFAULT_EVENTS_LIMIT,
              page: 0,
            },
            rowCount: -1,
          },
        }}
      />
    </TableContainer>
  );
};

export default EventsTableServer;
