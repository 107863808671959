import { ApolloError, useMutation } from "@apollo/client";

import { DeleteNodeMutation, DeleteNodeMutationVariables } from "../../../API";
import { DELETE_NODE } from "../../../common/operations/mutations";
import {
  errorNotification,
  successNotification,
} from "../../../common/variables/notification";

export const useDeleteNode = () => {
  const [deleteNodeMutation, { data, loading }] = useMutation<
    DeleteNodeMutation,
    DeleteNodeMutationVariables
  >(DELETE_NODE);

  const deleteNode = async (id: string, rowId: string, nodeName: string) => {
    return await deleteNodeMutation({
      variables: {
        nodeId: id,
      },
      onCompleted: response => {
        if (response.deleteNode) {
          successNotification(`${nodeName} node is deleted`);
        }
      },
      onError: (error: ApolloError): void => {
        errorNotification(`Something went wrong, ${nodeName} isn't deleted`);

        console.error(error.message);
      },
      update(cache) {
        const normalizedId = cache.identify({ id: rowId, __typename: "Node" });

        cache.evict({ id: normalizedId });

        cache.gc();
      },
    });
  };

  return { deleteNode, data, loading };
};
