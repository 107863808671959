import { LinearProgress } from "@mui/material";
import { DataGrid, GridRowHeightReturnValue } from "@mui/x-data-grid";

import { dataGridServerStyles } from "../../../common/providers/theme/design-tokens/DataGrid/dataGridServer.styles";
import { DEFAULT_COLUMN_HEADER_HEIGHT } from "../../../common/variables/common";
import { NODES_TABLE_LIMIT } from "../hooks/useGetNodes";
import { useNodeTableColumns } from "../hooks/useNodeTableColumns";
import { useNodeTableRows } from "../hooks/useNodeTableRows";
import NodesTableNoRowsOverlay from "./NodesTableNoRowsOverlay";

const NodesTable = (): JSX.Element => {
  const { rows, loading } = useNodeTableRows();
  const { columns } = useNodeTableColumns();

  const heightOfNoRowsOverlay = "240px !important";
  const noContentOverlayParam = rows.length === 0 || loading;

  return (
    <DataGrid
      sx={{
        ...dataGridServerStyles.sx,
        ".MuiDataGrid-overlayWrapperInner, .MuiDataGrid-virtualScrollerContent":
          {
            height: noContentOverlayParam ? heightOfNoRowsOverlay : "",
          },
        width: "100%",
      }}
      rows={rows}
      columns={columns}
      slots={{
        loadingOverlay: () => <LinearProgress />,
        noRowsOverlay: (): JSX.Element => (
          <NodesTableNoRowsOverlay loading={loading} />
        ),
      }}
      columnHeaderHeight={DEFAULT_COLUMN_HEADER_HEIGHT}
      autoHeight
      getRowHeight={(): GridRowHeightReturnValue => "auto"}
      disableColumnMenu
      disableRowSelectionOnClick
      hideFooterSelectedRowCount
      getRowId={(row: { rowId: string }): string => row.rowId as string}
      loading={loading}
      rowCount={rows.length}
      pageSizeOptions={[10, 25, 50, 100]}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: NODES_TABLE_LIMIT,
          },
        },
      }}
    />
  );
};

export default NodesTable;
