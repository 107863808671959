import { useParams } from "react-router-dom";

import { QueryResult, useLazyQuery } from "@apollo/client";
import dayjs from "dayjs";
import moment from "moment";

import {
  GetTimestreamDataQuery,
  GetTimestreamDataQueryVariables,
} from "../../../../../../API";
import { useDateRange } from "../../../../../../common/components/datePicker/modelsDatePickers";
import { useCustomerIdGuard } from "../../../../../../common/hooks/useCustomerIdGuard";
import { GET_TIMESTREAM_DATA } from "../../../../../../common/operations/queries";
import { convertDateToUTC } from "../../../../../../common/utils/timestampUtils";
import { parseQueryResult } from "../../../../../../common/utils/timestreamHelper";
import { errorNotification } from "../../../../../../common/variables/notification";
import client from "../../../../../../configs/apolloClient";
import { useEventsFilters } from "../../common/hooks/useEventsFilters";
import {
  EVENTS_DATE_FORMAT,
  EVENTS_EXPORT_LIMIT,
} from "../../common/events.constants";
import {
  ILiquidLeakTableRow,
  IParsedLiquidLeakEvent,
} from "../../common/events.models";

export const useGetLiquidLeakEventsToExport = () => {
  const selectedCustomerId = useCustomerIdGuard();
  const { model } = useParams();
  const dates = useDateRange();

  const startDate = dates?.from
    ? dayjs(convertDateToUTC(dates.from)).format(EVENTS_DATE_FORMAT)
    : null;
  const endDate = dates?.to
    ? dayjs(convertDateToUTC(dates.to)).format(EVENTS_DATE_FORMAT)
    : null;

  const { locationVariable, tagLocationsVariable } = useEventsFilters();

  const locationId = locationVariable?.value ?? "";

  let locations: Array<string> = [];

  if (locationId) {
    locations = [locationId ?? ""];
  } else if (tagLocationsVariable) {
    locations = tagLocationsVariable
      .map(l => l?.value ?? "")
      .filter(f => f !== "");
  }

  const [getTimestreamData, { loading, fetchMore }] = useLazyQuery<
    GetTimestreamDataQuery,
    GetTimestreamDataQueryVariables
  >(GET_TIMESTREAM_DATA, {
    fetchPolicy: "network-only",
  });

  const handleRequestError = async (): Promise<void> => {
    await client.cache.reset();

    errorNotification();
  };

  const fetchTimestreamData = async (
    offset?: number,
    limit?: number,
    nextToken?: string
  ): Promise<ILiquidLeakTableRow[]> => {
    const aggEventsData = await getTimestreamData({
      variables: {
        input: {
          customerId: selectedCustomerId,
          model: model ?? "",
          dateRange: JSON.stringify({ startDate, endDate }),
          locations,
          offset: offset ?? 0,
          limit: limit ?? EVENTS_EXPORT_LIMIT,
          nextToken,
        },
      },
    })
      .then(
        async (
          response
        ): Promise<
          QueryResult<GetTimestreamDataQuery, GetTimestreamDataQueryVariables>
        > => {
          const parsedData = JSON.parse(
            response?.data?.getTimestreamData as string
          );

          if (parsedData.NextToken) {
            const fetchMoreResults = await fetchMore({
              variables: {
                input: {
                  customerId: selectedCustomerId,
                  model: model ?? "",
                  dateRange: JSON.stringify({ startDate, endDate }),
                  locations,
                  offset,
                  limit,
                  nextToken,
                },
              },
            });

            const existingDataParsed = JSON.parse(
              response?.data?.getTimestreamData as string
            );

            const newDataParsed = JSON.parse(
              fetchMoreResults?.data?.getTimestreamData as string
            );

            const merged = {
              getTimestreamData: {
                ...existingDataParsed,
                Rows: [...existingDataParsed.Rows, ...newDataParsed.Rows],
                NextToken: newDataParsed.NextToken,
                QueryId: newDataParsed.QueryId,
              },
            };

            return {
              ...response,
              data: {
                getTimestreamData: JSON.stringify(merged.getTimestreamData),
              },
            };
          }

          if (response.error) {
            await handleRequestError();
          }

          return response;
        }
      )
      .catch(async (): Promise<void> => {
        await handleRequestError();
      });

    const parsedData = JSON.parse(
      aggEventsData?.data?.getTimestreamData as string
    );

    const parsedResult = parseQueryResult(parsedData);

    const mappedData: ILiquidLeakTableRow[] = mapData(parsedResult);

    return mappedData;
  };

  const mapData = (
    parsedData: IParsedLiquidLeakEvent[]
  ): ILiquidLeakTableRow[] => {
    const mappedData: ILiquidLeakTableRow[] =
      parsedData?.map((item: IParsedLiquidLeakEvent, index: number) => ({
        rowIndex: index + 1,
        rowId: item?.id ?? "",
        location: item?.location_id ?? "",
        zone: item?.zone_id ?? "",
        timestamp: moment.utc(item?.time).local().format(EVENTS_DATE_FORMAT),
        leaks: item?.leaks,
        volume: item?.leak_volume ?? "",
        mediaOutput: item?.mediaOutput ?? "",
        keyFrame: item?.keyFrame ?? "",
        cameraId: item?.camera_id ?? "",
      })) ?? [];

    return mappedData;
  };

  return { loading, fetchTimestreamData };
};
