import { Box, IconButton, Typography } from "@mui/material";
import LocationIdSelect from "../../../common/components/layout/components/LocationIdSelect";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import groupByPointer, { aggregateVolume } from "../helpers/groupByPointer";
import CircularLoading from "../../../common/components/progress/CircularLoading";
import StatisticsNoDataView from "./StatisticsNoDataView";
import { AutocompleteOptionType } from "../../../common/models/autocomplete";
import { IStatisticsResponseModel } from "../models/chart";
import GLVLocationList from "../../../common/components/select/GLVMultiSelect/GLVLocationList";
import { GasLeakContainerSteps } from "./GasLeakContainer";
import useLocationFromCache from "../../../common/hooks/useLocationFromCache";

type DetailedInformationViewProps = {
  locationVariable: AutocompleteOptionType;
  handleLocationChange: (locationValue: AutocompleteOptionType) => void;
  setStepsHandler: (step: number) => void;
  convertInstances: IStatisticsResponseModel[];
  loading: boolean;
  showNoData: boolean;
  setDeviceHandler: (device: IStatisticsResponseModel) => void;
};

const DetailedInformationView: React.FC<DetailedInformationViewProps> = ({
  locationVariable,
  handleLocationChange,
  setStepsHandler,
  convertInstances,
  loading,
  showNoData,
  setDeviceHandler,
}): JSX.Element => {
  const onChangeHandler = (
    step: GasLeakContainerSteps,
    device: IStatisticsResponseModel
  ): void => {
    setDeviceHandler(device);

    setStepsHandler(step);
  };

  const { getCachedLocation } = useLocationFromCache();

  const groupedByPointer = groupByPointer(convertInstances, "location").map(
    (elem): any => {
      const locationName = elem.location.includes("#L#")
        ? getCachedLocation(elem.location ?? "")?.name ?? ""
        : elem.location;

      return {
        location: locationName,
        data: aggregateVolume(elem.data),
      };
    }
  );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <IconButton
            onClick={(): void =>
              setStepsHandler(GasLeakContainerSteps.EmissionVolumeChart)
            }
            sx={{
              cursor: "pointer",
            }}
          >
            <ChevronLeftIcon />
          </IconButton>

          <Typography sx={{ marginRight: "1em" }} variant="h5">
            Detailed Information
          </Typography>
        </Box>

        <Box
          sx={{
            width: "180px",
          }}
        >
          <LocationIdSelect
            size="small"
            label="All location"
            setDefault={false}
            location={locationVariable}
            setLocation={handleLocationChange}
          />
        </Box>
      </Box>

      <Box>
        <GLVLocationList
          onChangeHandler={onChangeHandler}
          groupedData={groupedByPointer}
        />
      </Box>

      {loading && (
        <Box sx={{ height: "250px" }}>
          <CircularLoading />
        </Box>
      )}

      {showNoData && <StatisticsNoDataView />}
    </>
  );
};

export default DetailedInformationView;
