import { useQuery } from "@apollo/client";

import {
  GetLicensePlateQuery,
  GetLicensePlateQueryVariables,
} from "../../../../../API";
import { GET_LICENSE_PLATE } from "../../../../../common/operations/queries";

export const useGetLicensePlateDetails = (options: {
  variables: GetLicensePlateQueryVariables;
  skip?: boolean;
}) => {
  const { data, loading, refetch } = useQuery<
    GetLicensePlateQuery,
    GetLicensePlateQueryVariables
  >(GET_LICENSE_PLATE, {
    ...options,
  });

  return { data, loading, refetch };
};
