import { useTheme } from "@mui/material";

const HardHatDataHubIcon = (): JSX.Element => {
  const theme = useTheme();

  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="40"
        height="40"
        rx="8"
        fill={theme.palette.otherBackground.main}
      />
      <g clipPath="url(#clip0_667_96961)">
        <path
          d="M30.8497 24.9658H30.3115V22.9977C30.3115 18.7629 27.6826 14.9222 23.7511 13.3817V12.908C23.7511 12.163 23.1449 11.5568 22.3999 11.5568H17.5732C16.8282 11.5568 16.222 12.163 16.222 12.908V13.3817C12.2902 14.9222 9.66165 18.7632 9.66165 22.9979V24.9661H9.12318C8.57655 24.9658 8.13187 25.4107 8.13187 25.9574V27.4247C8.13187 27.9714 8.57655 28.416 9.12318 28.416H30.8497C31.3963 28.416 31.8412 27.9714 31.8412 27.4247V25.9574C31.8412 25.4107 31.3966 24.9658 30.8497 24.9658ZM10.1885 22.9979C10.1885 19.0487 12.5977 15.4604 16.222 13.9502V17.4913C16.222 17.6367 16.3401 17.7547 16.4855 17.7547C16.6309 17.7547 16.7489 17.6367 16.7489 17.4913V13.5634V12.908C16.7489 12.4536 17.1188 12.0837 17.5732 12.0837H19.7231V20.0745C19.7231 20.22 19.8411 20.338 19.9866 20.338C20.132 20.338 20.25 20.22 20.25 20.0745V12.0837H22.3999C22.8546 12.0837 23.2242 12.4536 23.2242 12.908V13.5632V17.491C23.2242 17.6364 23.342 17.7545 23.4876 17.7545C23.6333 17.7545 23.7511 17.6364 23.7511 17.491V13.9499C27.3754 15.4602 29.7846 19.0485 29.7846 22.9977V24.9658H10.1885V22.9979ZM31.3144 27.4247C31.3144 27.6808 31.106 27.8892 30.8497 27.8892H9.12318C8.86712 27.8892 8.65874 27.6808 8.65874 27.4247V25.9574C8.65874 25.701 8.86712 25.4927 9.12318 25.4927H9.92482H30.048H30.8497C31.106 25.4927 31.3144 25.701 31.3144 25.9574V27.4247Z"
          fill={theme.palette.otherBackground.main}
          stroke={theme.palette.text.primary}
        />
      </g>
      <defs>
        <clipPath id="clip0_667_96961">
          <rect
            width="24"
            height="24"
            fill={theme.palette.otherBackgroundLight.main}
            transform="translate(8 8)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HardHatDataHubIcon;
