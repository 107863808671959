import { Box } from "@mui/material";
import { type CSSProperties } from "@mui/material/styles/createMixins";
import { styled } from "@mui/material/styles";

export const Body = styled(Box)(
  ({ theme }): CSSProperties => ({
    backgroundColor: theme.palette.otherBackground.main,
    width: "100%",
    "min-height": "100vh",
    display: "flex",
    "flex-direction": "column",
  })
);
