import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { useLazyQuery } from "@apollo/client";

import type {
  GetLocationByIdQuery,
  GetLocationByIdQueryVariables,
} from "../../../../../API";
import { useCustomerIdGuard } from "../../../../../common/hooks/useCustomerIdGuard";
import { GET_LOCATION_BY_ID } from "../../../../../common/operations/queries";
import { errorNotification } from "../../../../../common/variables/notification";
import client from "../../../../../configs/apolloClient";
import {
  locationFormVariables,
  useLocationFormVariables,
} from "../variables/locationData";

export interface useGetDevicesInterface {
  customerId?: string;
  locationId?: string;
}

export const useGetLocationById = () => {
  const selectedCustomerId = useCustomerIdGuard();
  const form = useLocationFormVariables();

  let { locationId: locationIdSearchParameter } = useParams();
  const windowPath = window.location.hash && `C${window.location.hash}`;

  locationIdSearchParameter = windowPath.includes("#L#") ? windowPath : "";

  useEffect(() => {
    fetchLocation(locationIdSearchParameter as string);
  }, [form.rowId, selectedCustomerId, locationIdSearchParameter]);

  const handleRequestError = () => {
    client.cache
      .reset()
      .then(() => {
        errorNotification();
      })
      .catch(error => {
        console.error(error);
      });
  };

  const [getLocationById, { data, loading }] = useLazyQuery<
    GetLocationByIdQuery,
    GetLocationByIdQueryVariables
  >(GET_LOCATION_BY_ID, {
    fetchPolicy: "network-only",
  });

  const fetchLocation = (locationId: string) => {
    getLocationById({
      variables: {
        customerId: selectedCustomerId,
        locationId,
      },
    })
      .then(response => {
        if (response.data?.getLocationById) {
          try {
            const location = response.data.getLocationById;
            const locationData = JSON.parse(location.locationData as string);

            locationFormVariables({
              rowId: location.id as string,
              locationName: location.name as string,
              referenceId: location.referenceId as string,
              latitude: locationData?.lat as string,
              longitude: locationData?.lon as string,
              tags: location.tags ?? [],
              timeZone: location.timeZone ?? "",
            });
          } catch (error) {
            console.error(error);
          }
        }

        if (response.error) {
          handleRequestError();
        }
      })
      .catch(e => {
        console.error(e);

        handleRequestError();
      });
  };

  return { data, loading };
};
