import Typography from "@mui/material/Typography";

import RouterLink from "../../../../common/components/RouterLink";
import { RouteEnum } from "../../../../common/models/enums";
import FireDataHubIcon from "../icons/FireDataHubIcon";
import DataHubGridItem from "./DataHubGridItem";
import { IModelGridItemProps } from "./GasLeakGridItem";

const FireGridItem = ({ nonAdmin }: IModelGridItemProps): JSX.Element => {
  return (
    <RouterLink to={nonAdmin ? RouteEnum.SensoryFire : RouteEnum.Fire}>
      <DataHubGridItem>
        <FireDataHubIcon />
        <Typography variant="body2Bold">Fire</Typography>
      </DataHubGridItem>
    </RouterLink>
  );
};

export default FireGridItem;
