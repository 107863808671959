import { GridRowModel } from "@mui/x-data-grid";
import { useConfirm } from "material-ui-confirm";

import DeleteIconButton from "../../../../../../../common/components/icons/DeleteIconButton";
import confirmDialogStyleOptions from "../../../../../../../common/helpers/confirmDialogParams";
import {
  ThresholdColumnState,
  ThresholdColumnStateEnum,
} from "../hooks/useGetZonesByDeviceTableColumns";
import { IZonesByDeviceTableRow } from "../hooks/useGetZonesByDeviceTableRow";
import { useUpdateZoneThresholdValue } from "../hooks/useUpdateZoneThresholdValue";

type DeleteThresholdValueContainerProps = {
  row: GridRowModel<IZonesByDeviceTableRow>;
  setMode: React.Dispatch<React.SetStateAction<ThresholdColumnState>>;
};

const DeleteThresholdValueContainer = ({
  row,
  setMode,
}: DeleteThresholdValueContainerProps) => {
  const confirm = useConfirm();

  const { updateZoneThreshold } = useUpdateZoneThresholdValue();

  const onDeleteThresholdHandler = () => {
    confirm({
      title: `Confirm deleting the threshold for ${row.name}`,
      description: `After you delete the threshold for ${row.name} zone, alerts will be sent without a threshold limit.`,
      confirmationText: "Delete",
      cancellationText: "Cancel",
      ...confirmDialogStyleOptions,
      confirmationButtonProps: {
        ...confirmDialogStyleOptions.confirmationButtonProps,
      },
    }).then(() => {
      updateZoneThreshold(row.deviceId, row.id, row.name, "0");

      setMode(ThresholdColumnStateEnum.READ_MODE);
    });
  };

  return <DeleteIconButton onClick={onDeleteThresholdHandler} />;
};

export default DeleteThresholdValueContainer;
