import { ChangeEvent } from "react";

import { TextField } from "@mui/material";

import {
  licensePlatesTableVariable,
  useLicensePlatesTableVariables,
} from "../variables/licensePlatesTable";

const LicensePlatesSearchInput = () => {
  const licensePlateTableVariables = useLicensePlatesTableVariables();

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    licensePlatesTableVariable({
      filterValue: e.target.value,
    });
  };

  return (
    <TextField
      label="Filter"
      value={licensePlateTableVariables.filterValue}
      onChange={handleInputChange}
      size="small"
    />
  );
};

export default LicensePlatesSearchInput;
