import { FC } from "react";

import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { useTheme } from "@mui/material/styles";

const CloseIconButton: FC<SvgIconProps> = props => {
  const theme = useTheme();

  return (
    <SvgIcon
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        cursor: "pointer",
        color: theme.palette.otherBackgroundLight.main,
        fontSize: "2rem",
      }}
      {...props}
    >
      <rect
        x="0.5"
        y="0.5"
        width="31"
        height="31"
        rx="7.5"
        fill={theme.palette.otherBackgroundLight.main}
      />
      <rect
        x="0.5"
        y="0.5"
        width="31"
        height="31"
        rx="7.5"
        stroke={theme.palette.otherOutlineBorder.main}
      />
      <path
        d="M21 11L11 21M21 21L11 11"
        stroke={theme.palette.error.main}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
};

export default CloseIconButton;
