import { gql, useMutation } from "@apollo/client";

import {
  AddZoneThresholdMutation,
  AddZoneThresholdMutationVariables,
} from "../../../../../../../API";
import { useCustomerIdGuard } from "../../../../../../../common/hooks/useCustomerIdGuard";
import { ADD_ZONE_THRESHOLD } from "../../../../../../../common/operations/mutations";

export const useCreateZoneThreshold = () => {
  const selectedCustomerId = useCustomerIdGuard();

  const [addZoneThreshold, { data, loading }] = useMutation<
    AddZoneThresholdMutation,
    AddZoneThresholdMutationVariables
  >(ADD_ZONE_THRESHOLD);

  const createZoneThreshold = (name: string, value: number) => {
    return addZoneThreshold({
      variables: {
        input: {
          customerId: selectedCustomerId,
          name: name,
          value: value,
        },
      },
      update(cache, response) {
        cache.modify({
          fields: {
            getZonesThresholdsByCustomer(existing) {
              const newZoneThresholdRef = cache.writeFragment({
                data: response.data?.addZoneThreshold,
                fragment: gql`
                  fragment NewZoneThreshold on ZoneThreshold {
                    id
                    name
                    value
                  }
                `,
              });

              if (!existing) {
                return response?.data;
              }

              return {
                ...existing,
                items: [...existing.items, newZoneThresholdRef],
              };
            },
          },
        });
      },
    });
  };

  return { createZoneThreshold, data, loading };
};
