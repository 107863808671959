import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Thumbnail from "../media/Thumbnail";
import DeleteZoneContainer from "../../../pages/devices/components/DeleteZoneContainer";
import DeleteIconButton from "../icons/DeleteIconButton";
import { Zone } from "../../../API";
import RouterLink from "../RouterLink";
import ZoneStatusToggle from "./ZoneStatusToggle";

interface ExtendedZone extends Zone {
  deviceName: string;
  nodeId: string;
  hasZoom: boolean;
}

interface ZoneCardProps {
  datum: ExtendedZone;
  link: string;
}

const ZoneCard: React.FC<ZoneCardProps> = ({ datum, link }): JSX.Element => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        "&:hover .delete-zone-button": {
          display: "block",
        },
      }}
    >
      <Box sx={{ position: "relative" }}>
        <RouterLink sx={{ whiteSpace: "pre-wrap" }} to={link}>
          <Box
            sx={{
              width: "244px",
              display: "flex",
              alignItems: "center",
              overflow: "hidden",
              justifyContent: "center",
              backgroundColor: "otherDisabledBackground.main",
              border: 2,
              borderColor: "otherOutlineBorder.main",
              borderRadius: "8px",
              height: "182px",
            }}
          >
            <Thumbnail zoneCard mediaOutput={datum.keyFrame ?? ""} />
          </Box>
        </RouterLink>

        <Box
          className="delete-zone-button"
          sx={{
            display: "none",
            position: "absolute",
            top: "10px",
            cursor: "pointer",
            right: "10px",
          }}
        >
          <DeleteZoneContainer
            serviceId={datum.serviceId ?? ""}
            noRedirection
            zoneId={datum.id}
            zoneName={datum.name}
            zoneDeviceId={datum.deviceId}
          >
            <DeleteIconButton />
          </DeleteZoneContainer>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <RouterLink to={link} title={datum.name} />
        <ZoneStatusToggle params={datum} />
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "6px",
        }}
      >
        <Typography variant="inputLabel">Pan:</Typography>
        <Typography variant="body2Regular">{datum.pan}</Typography>
        <Typography variant="inputLabel">Tilt:</Typography>
        <Typography variant="body2Regular">{datum.tilt}</Typography>
        {datum.hasZoom && (
          <>
            <Typography variant="inputLabel">Zoom:</Typography>
            <Typography variant="body2Regular">{datum.zoom}</Typography>
          </>
        )}
      </Box>
    </Box>
  );
};

export default ZoneCard;
