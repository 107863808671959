import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { ReactElement } from "react";

import { Box, Tooltip, Typography } from "@mui/material";
import {
  GridActionsCellItem,
  GridActionsCellItemProps,
  GridColDef,
} from "@mui/x-data-grid";
import isEmpty from "lodash/isEmpty";

import ChipFilledOther from "../../../common/components/chip/ChipFilledOther";
import ChipFilledPrimary from "../../../common/components/chip/ChipFilledPrimary";
import RouterLink from "../../../common/components/RouterLink";
import AddModelButton from "../components/AddModelStyledButton";
import DeleteDeviceContainer from "../components/DeleteDeviceContainer";
import DeviceModelContainer from "../components/DeviceModelContainer";

import EditIconButton from "../../../common/components/icons/EditIconButton";
import InfoIcon from "../../../common/components/icons/InfoIcon";

export const useDeviceTableColumns = (): { columns: GridColDef[] } => {
  const columns: GridColDef[] = [
    {
      field: "rowId",
      headerName: "#",
      hideable: false,
      flex: 1,
      maxWidth: 50,
      disableColumnMenu: true,
      renderCell: (params): JSX.Element | null =>
        params ? (
          <Typography variant="body2">
            {parseInt(params.row.sequenceNumber)}
          </Typography>
        ) : null,
    },
    {
      field: "name",
      headerName: "DEVICE ID",
      hideable: false,
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params): JSX.Element | null =>
        params ? (
          <RouterLink
            to={`/device/${encodeURIComponent(params.row.rowId)}`}
            title={params.row.name}
            state={{ deviceDatum: params.row }}
          />
        ) : null,
    },
    {
      field: "models",
      headerName: "MODEL",
      hideable: false,
      width: 320,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params): JSX.Element | null => {
        const showAddModelButton =
          params?.row.modelsToRun?.length > params?.row.models.length;

        if (isEmpty(params?.row.models)) {
          return (
            <RouterLink
              to={"/device/" + encodeURIComponent(params.row.rowId)}
              state={{ deviceDatum: params.row }}
            >
              <AddModelButton disableRipple startIcon={<AddRoundedIcon />}>
                Add model
              </AddModelButton>
            </RouterLink>
          );
        }

        return (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              padding: "0.5em 0",
              gap: "0.5em",
            }}
          >
            {params?.row && <DeviceModelContainer device={params.row} />}
            <Box
              sx={{
                visibility: showAddModelButton ? "visible" : "hidden",
              }}
            >
              <RouterLink
                to={"/device/" + encodeURIComponent(params.row.rowId)}
                state={{ deviceDatum: params.row }}
              >
                <AddModelButton
                  className="addModelButton"
                  disableRipple
                  startIcon={<AddRoundedIcon />}
                />
              </RouterLink>
            </Box>
          </Box>
        );
      },
    },
    {
      field: "node",
      headerName: "NODE",
      hideable: false,
      sortable: true,
      sortComparator: (a, b): number => a.localeCompare(b),
      flex: 1,
      minWidth: 200,
      disableColumnMenu: true,
      valueGetter: (node: {
        id: string | null;
        name: string | null;
      }): string => {
        if (!node?.id) {
          return "";
        }

        const nodeName = node?.name ?? node?.id.split("#N#").at(-1);

        return nodeName ?? "";
      },
      renderCell: (params): JSX.Element | null => {
        if (!params.row) {
          return null;
        }

        const node =
          params.row.node.name ?? params.row.node.id.split("#N#").at(-1);

        return <Typography variant="body2">{node}</Typography>;
      },
    },
    {
      field: "isOnline",
      renderHeader: () => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "0.5em",
            }}
          >
            <Typography sx={{ fontSize: "12px" }}>STATUS</Typography>

            <Tooltip
              sx={{
                display: "flex",
                alignItems: "center",
              }}
              title="Only models of online devices are monitored"
            >
              <Box>
                <InfoIcon />
              </Box>
            </Tooltip>
          </Box>
        );
      },
      hideable: false,
      sortable: false,
      flex: 1,
      maxWidth: 120,
      disableColumnMenu: true,
      renderCell: (params): JSX.Element | null => {
        if (!params.row) {
          return null;
        }

        const isOnline = params?.row.isOnline;

        return (
          <>
            {isOnline ? (
              <ChipFilledPrimary label="Online" />
            ) : (
              <ChipFilledOther label="Offline" />
            )}
          </>
        );
      },
    },
    {
      field: "actions",
      type: "actions",
      width: 100,
      getActions: (params): ReactElement<GridActionsCellItemProps>[] => [
        <GridActionsCellItem
          key={`edit-${params.row?.rowId}`}
          icon={
            <RouterLink
              to={`/device/edit/${encodeURIComponent(params.row.rowId)}`}
              state={{ deviceDatum: params.row, useBackToDevicesPage: true }}
            >
              <EditIconButton />
            </RouterLink>
          }
          label="Edit"
          className="actionButton"
          disableRipple
        />,
        <GridActionsCellItem
          key={`delete-${params.row?.rowId}`}
          icon={<DeleteDeviceContainer row={params.row} />}
          label="Delete"
          className="actionButton"
          disableRipple
        />,
      ],
    },
  ];

  return { columns };
};
