import Typography from "@mui/material/Typography";
import { GridColDef } from "@mui/x-data-grid";

import S3Image from "../../../../../../common/components/media/S3Image";
import { parseEventMediaPath } from "../../../../../../common/helpers/formatJsonString";
import useLocationFromCache from "../../../../../../common/hooks/useLocationFromCache";
import { formatTimestamp } from "../../../../../../common/utils/timestampUtils";
import { ITlEventsTableRow } from "../../common/events.models";

export const useGetTlmTableColumns = () => {
  const { getCachedLocationName } = useLocationFromCache();

  const columns: GridColDef<ITlEventsTableRow>[] = [
    {
      field: "rowIndex",
      headerName: "#",
      width: 50,
      hideable: false,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params): JSX.Element | null => {
        return <Typography variant="body2">{params.row.rowIndex}</Typography>;
      },
    },
    {
      field: "mediaOutput",
      headerName: "Snapshot",
      flex: 1,
      minWidth: 160,
      maxWidth: 160,
      hideable: false,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params): JSX.Element | null => {
        const mediaOutput = parseEventMediaPath(params?.row?.mediaOutput);

        return (
          <S3Image
            sx={{
              borderRadius: "4px",
            }}
            width={120}
            height={90}
            s3Key={mediaOutput}
          />
        );
      },
    },
    {
      field: "location",
      headerName: "Location",
      flex: 1,
      minWidth: 160,
      hideable: false,
      disableColumnMenu: true,
      sortable: false,
      valueGetter: (loactionId: string): string => {
        const locationName = getCachedLocationName(loactionId);

        return locationName;
      },
      renderCell: (params): JSX.Element | null => {
        const locationName = getCachedLocationName(params?.row?.location);

        return <Typography variant="body2">{locationName}</Typography>;
      },
    },
    // {
    //   field: "data",
    //   headerName: "Tanks",
    //   flex: 1,
    //   minWidth: 200,
    //   disableColumnMenu: true,
    //   hideable: false,
    //   sortable: false,
    //   renderCell: (params): JSX.Element | null => {
    //     if (!params?.row?.tanksData) {
    //       return null;
    //     }

    //     try {
    //       const tanks = JSON.parse(params?.row?.tanksData);
    //       console.log("tanks", tanks);
    //     } catch (error) {
    //       console.log("Error while parsing tanks:", (error as Error).message);
    //     }

    //     return (
    //       <Typography variant="body2">
    //         {JSON.stringify(params.row.tanksData)}
    //       </Typography>
    //     );
    //   },
    // },
    {
      field: "cameraId",
      headerName: "DEVICE ID",
      flex: 1,
      minWidth: 160,
      hideable: false,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params): JSX.Element | null =>
        params ? (
          <Typography variant="body2Bold">{params?.row.cameraId}</Typography>
        ) : null,
    },
    {
      field: "timestamp",
      headerName: "Timestamp",
      flex: 1,
      minWidth: 180,
      hideable: false,
      disableColumnMenu: true,
      sortable: false,
      valueFormatter: (timestamp: string): string => {
        return formatTimestamp(timestamp);
      },
      renderCell: (params): JSX.Element | null =>
        params ? (
          <Typography variant="body2">
            {formatTimestamp(params?.row.timestamp)}
          </Typography>
        ) : null,
    },
    {
      // Need to keep this column hidden and purely for exporting to CSV
      field: "tanksData",
      headerName: "",
      flex: 1,
      maxWidth: 1,
      hideable: false,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params): JSX.Element | null => {
        if (!params) {
          return null;
        }

        const tankNumbers = params?.row.tanksData;

        return (
          <Typography
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              display: "none",
            }}
            variant="body2"
          >
            {tankNumbers}
          </Typography>
        );
      },
    },
  ];

  return { columns };
};
