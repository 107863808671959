import TableNoData from "../../../../../common/components/table/TableNoData";
import { useGetAddNewAlertPath } from "../hooks/useGetAddNewAlertPath";

const NoAlertsAdded = (): JSX.Element => {
  const addNewAlertPath = useGetAddNewAlertPath();

  return (
    <TableNoData
      title="There is no added alerts right now"
      description="Add alerts to receive them"
      redirectPath={addNewAlertPath}
      addButtonTitle="New alert"
    />
  );
};

export default NoAlertsAdded;
