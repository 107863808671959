import { Button, CSSObject, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";

import { TimePeriod } from "../../../API";

const PeriodButton = styled(Button)(
  ({ theme }): CSSObject => ({
    borderRadius: "0.5em",
    borderColor: theme.palette.otherOutlineBorder.main,
    color: theme.palette.otherTextTertiary.main,
    cursor: "pointer",

    "&:hover, &.active": {
      borderColor: theme.palette.text.primary,
      color: theme.palette.text.primary,
      cursor: "pointer",
    },
  })
);

interface ITimePeriodFilterProps {
  activePeriod: TimePeriod;
  setActivePeriod: (period: TimePeriod) => void;
}

const TimePeriodFilter = ({
  activePeriod,
  setActivePeriod,
}: ITimePeriodFilterProps): JSX.Element => {
  const isActivePeriod = (period: TimePeriod): boolean =>
    period === activePeriod;

  const isActive1dPeriod = isActivePeriod(TimePeriod.ONE_DAY);
  const isActive1wPeriod = isActivePeriod(TimePeriod.ONE_WEEK);
  const isActive1mPeriod = isActivePeriod(TimePeriod.ONE_MONTH);
  const isActiveAllTimePeriod = isActivePeriod(TimePeriod.ALL_TIME);

  return (
    <Stack direction="row" spacing={2}>
      <PeriodButton
        variant="outlined"
        size="small"
        className={isActive1dPeriod ? "active" : ""}
        onClick={(): void => setActivePeriod(TimePeriod.ONE_DAY)}
      >
        1d
      </PeriodButton>
      <PeriodButton
        variant="outlined"
        size="small"
        className={isActive1wPeriod ? "active" : ""}
        onClick={(): void => setActivePeriod(TimePeriod.ONE_WEEK)}
      >
        1W
      </PeriodButton>

      <PeriodButton
        variant="outlined"
        size="small"
        className={isActive1mPeriod ? "active" : ""}
        onClick={(): void => setActivePeriod(TimePeriod.ONE_MONTH)}
      >
        1M
      </PeriodButton>

      <PeriodButton
        variant="outlined"
        size="small"
        className={isActiveAllTimePeriod ? "active" : ""}
        onClick={(): void => setActivePeriod(TimePeriod.ALL_TIME)}
      >
        All time
      </PeriodButton>
    </Stack>
  );
};

export default TimePeriodFilter;
