import SettingsIcon from "@mui/icons-material/Settings";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Theme } from "@mui/material/styles";

import usePlateManagementNavigationRoute from "../../../../../common/hooks/usePlateManagementNavigationRoute";
import { RouteEnum } from "../../../../../common/models/enums";

const PlatesManagementButton = (): JSX.Element => {
  const { getNavigationRoute } = usePlateManagementNavigationRoute();

  const href = getNavigationRoute(
    RouteEnum.RecentActivitiesPlatesManagement,
    RouteEnum.SensoryRecentActivitiesPlatesManagement
  );

  return (
    <Button
      sx={{
        backgroundColor: "transparent",
        "&:hover": {
          background: "none",
        },
      }}
      variant="text"
      size="small"
      disableRipple
      href={href}
      startIcon={
        <SettingsIcon
          sx={{
            color: (theme: Theme): string => theme.palette.text.secondary,
          }}
        />
      }
    >
      <Typography
        variant="buttonMedium"
        color={(theme: Theme): string => theme.palette.text.secondary}
      >
        Plates Management
      </Typography>
    </Button>
  );
};

export default PlatesManagementButton;
