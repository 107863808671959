import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useForm } from "react-hook-form";

import FullPageLoader from "../../../common/components/item/FullPageLoader";
import { useUpdateUserSettings } from "../../../common/hooks/useUpdateUserSettings";
import { RouteEnum } from "../../../common/models/enums";
import StyledLoadingButton from "../../../common/providers/theme/design-tokens/LoadingButton/StyledLoadingButton";
import {
  errorNotification,
  successNotification,
} from "../../../common/variables/notification";
import UserPreferencesSection from "../../../pages/system-settings/tabs/user-preferences/components/UserPreferencesSection";
import { useLazyGetUser } from "../../hooks/useLazyGetUser";
import { UnitsEnum } from "../UploadVideoGroupPage";
import LeakFinderUserPreferencesForm from "./components/LeakFinderUserPreferencesForm";
import LeakFinderVideoSpeedForm from "./components/LeakFinderVideoSpeedForm";
import BreadcrumbNavigation from "../../../common/components/tabs/BreadcrumbNavigation";

export interface ILeakFinderUserPreferencesForm {
  cameraFov?: number;
  windspeedUnit?: UnitsEnum.MPH | UnitsEnum.KPH;
  userId: string;
  videoSpeed: number;
  userRole: string;
}

const LeakFinderUserPreferencesPage = () => {
  const navigate = useNavigate();

  const { loading: userInfoLoading, getParsedUserInfo } = useLazyGetUser();

  const { loading, updateUser } = useUpdateUserSettings();

  const getUserInfo = async () => {
    const response = await getParsedUserInfo();

    return {
      cameraFov: response?.cameraFov,
      videoSpeed: response?.videoSpeed,
      windspeedUnit: response?.windspeedUnit as UnitsEnum,
      userId: response.userId,
      userRole: response.userRole,
    };
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ILeakFinderUserPreferencesForm>({
    defaultValues: async () => await getUserInfo(),
  });

  const onSubmit = async (data: ILeakFinderUserPreferencesForm) => {
    const newSettings = {
      defaultCameraFov: +(data.cameraFov ?? 0),
      defaultVideoSpeed: +(data.videoSpeed ?? 0),
      defaultWindspeedUnit: data.windspeedUnit,
    };

    updateUser(newSettings)
      .then((response): void => {
        if (response?.data?.updateUserSettings) {
          successNotification("User preferences updated successfully");
        }
      })
      .catch(error => {
        errorNotification(error.message);
      });
  };

  const handleCancel = () => {
    navigate(RouteEnum.LeakFinderClientUploads);
  };

  if (loading || userInfoLoading) {
    return <FullPageLoader />;
  }

  const breadcrumbItems = [
    { label: "Client uploads", path: RouteEnum.LeakFinderClientUploads },
    {
      label: "User preferences",
    },
  ];

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ marginBottom: "1em" }}>
        <BreadcrumbNavigation items={breadcrumbItems} />
      </Box>
      <Typography variant="h3" sx={{ paddingBottom: "1.5em" }}>
        User Preferences
      </Typography>
      <Box
        sx={{
          maxWidth: "680px",
          width: "100%",
        }}
      >
        <UserPreferencesSection title="Default Values">
          <LeakFinderUserPreferencesForm control={control} errors={errors} />
        </UserPreferencesSection>
        <br />
        <UserPreferencesSection title="Video speed">
          <LeakFinderVideoSpeedForm control={control} errors={errors} />
        </UserPreferencesSection>
        <Stack spacing={2} direction="row-reverse" sx={{ paddingTop: 3 }}>
          <StyledLoadingButton
            sx={{
              marginTop: "1.5em",
            }}
            type="submit"
            variant="contained"
            color="primary"
            size="small"
          >
            Save changes
          </StyledLoadingButton>
          <StyledLoadingButton
            sx={{
              marginTop: "1.5em",
            }}
            size="small"
            variant="outlined"
            color="inherit"
            onClick={handleCancel}
          >
            Back
          </StyledLoadingButton>
        </Stack>
      </Box>
    </form>
  );
};

export default LeakFinderUserPreferencesPage;
