import { ChangeEvent, useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import MakeSelect from "../../../common/components/layout/components/MakeSelect";
import NodeSelect from "../../../common/components/select/NodeSelect/NodeSelect";
import { AutocompleteOptionType } from "../../../common/models/autocomplete";
import { useSelectedLocationDropdown } from "../../../common/variables/selectedLocationDropdown";
import DeviceDynamicFields from "../devices-create/DeviceDynamicFields";
import {
  defaultDeviceFormValidationState,
  deviceFormRules,
  deviceFormValidationStateVariable,
  deviceFormVariables,
  useDeviceFormValidationState,
  useDeviceFormVariables,
} from "../variables/devices";
import LocationIdSelect from "./../../../common/components/layout/components/LocationIdSelect";
import { Stack, Switch } from "@mui/material";
import { DeviceData } from "../../../API";

interface ICreateDeviceFormProps {
  showLocationSelect?: boolean;
  editMode?: boolean;
}

const DeviceForm = ({
  showLocationSelect,
  editMode,
}: ICreateDeviceFormProps): JSX.Element => {
  const theme = useTheme();
  const selectedLocation = useSelectedLocationDropdown();
  const deviceForm = useDeviceFormVariables();
  const validation = useDeviceFormValidationState();

  const [forceTCP, setForceTCP] = useState(false);

  const [recordVideo, setRecordVideo] = useState(true);

  const handleForceTcpChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForceTCP(event.target.checked);
  };

  const handleEnableRecordingChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRecordVideo(event.target.checked);
  };

  const setLocation = (value: AutocompleteOptionType): void => {
    deviceFormVariables({
      ...deviceForm,
      isDirty: true,
      location: value,
    });
  };

  const setDeviceName = (event: ChangeEvent<HTMLInputElement>): void => {
    deviceFormValidationStateVariable({
      ...validation,
      deviceName: { ...defaultDeviceFormValidationState.deviceName },
    });

    const deviceName = event.target.value;

    deviceFormVariables({
      ...deviceForm,
      isDirty: true,
      deviceName,
    });

    let hasError = !deviceName.match(deviceFormRules.deviceName.pattern);
    let errorMessage = "";

    if (hasError) {
      errorMessage = "Valid characters: A-Z, a-z, 0-9, _, -, .";
    }

    // NOTE: This rule is handled on submit
    if (hasError && !deviceName) {
      hasError = false;

      errorMessage = "";
    }

    deviceFormValidationStateVariable({
      ...validation,
      deviceName: {
        hasError,
        errorMessage,
      },
    });
  };

  const setDeviceType = (value: string): void => {
    const parsedValue = value !== "" ? JSON.parse(value) : "";

    const newDeviceType = value !== "" ? parsedValue.model : "";
    const newDeviceTypeShortName = value !== "" ? parsedValue.shortName : "";
    const newDeviceTypeHasPt = value !== "" ? parsedValue.hasPanTilt : false;
    const newDeviceTypeHasZoom = value !== "" ? parsedValue.hasZoom : false;
    const rtspTemplate = value !== "" ? parsedValue.rtspTemplate : "";
    const defaultUserPwd = value !== "" ? parsedValue.defaultUserPwd : "";

    let sourceVideo = `rtsp://${rtspTemplate}`;

    if (parsedValue.defaultUserPwd) {
      sourceVideo = `rtsp://${defaultUserPwd}@${rtspTemplate}`;

      if (deviceForm.cameraIpAddress) {
        sourceVideo = `rtsp://${defaultUserPwd}@${deviceForm.cameraIpAddress}${rtspTemplate}`;
      }
    }

    const defaultDeviceName =
      selectedLocation?.value.split("L#")[1] + "-" + newDeviceTypeShortName;
    const rtspHost = `rtsp://<nodeIP>/${defaultDeviceName}`;

    deviceFormVariables({
      ...deviceForm,
      isDirty: true,
      deviceType: newDeviceType ?? "",
      deviceTypeShortName: newDeviceTypeShortName ?? "",
      deviceMakePanTiltValue: newDeviceTypeHasPt ?? false,
      deviceMakeHasZoomValue: newDeviceTypeHasZoom ?? false,
      hasZoom: newDeviceTypeHasZoom,
      deviceName: defaultDeviceName,
      rtspTemplate,
      defaultUserPwd,
      deviceData: {
        ...deviceForm.deviceData,
        sourceVideo,
        rtspHost,
        hasPanTilt: newDeviceTypeHasPt,
        hasZoom: newDeviceTypeHasZoom,
      },
    });
  };

  const setNodeId = (value: string, title = "", nodeIP = ""): void => {
    const nodeIPPart = nodeIP ?? "<nodeIP>";

    const rtspHost = `rtsp://${nodeIPPart}/${deviceForm.deviceName}`;

    deviceFormVariables({
      ...deviceForm,
      isDirty: true,
      node: {
        ...deviceForm.node,
        id: value,
        name: title,
      },
      deviceData: {
        ...deviceForm.deviceData,
        rtspHost,
      },
    });
  };

  const setCameraIpAddress = (event: ChangeEvent<HTMLInputElement>): void => {
    const cameraIpAddress = event.target.value.replaceAll(" ", "");

    const rtspTemplate = deviceForm.rtspTemplate;
    let sourceVideoValue = deviceForm.deviceData?.sourceVideo;

    if (deviceForm.defaultUserPwd) {
      sourceVideoValue = `rtsp://${deviceForm.defaultUserPwd}@${cameraIpAddress}${rtspTemplate}`;
    } else {
      sourceVideoValue = `rtsp://${cameraIpAddress}${rtspTemplate}`;
    }

    deviceFormVariables({
      ...deviceForm,
      isDirty: true,
      cameraIpAddress,
      deviceData: {
        ...deviceForm.deviceData,
        sourceVideo: sourceVideoValue,
      },
    });

    deviceFormValidationStateVariable({
      ...validation,
      cameraIpAddress: {
        hasError: false,
        errorMessage: "",
      },
    });
  };

  const setDeviceConfig = (
    name: Exclude<keyof DeviceData, "__typename">,
    value: string | null | boolean
  ): void => {
    const updatedDeviceData = { ...deviceForm.deviceData };

    if (value === null) {
      delete updatedDeviceData[name];
    } else {
      if (typeof value === "string") {
        (updatedDeviceData[name] as string) = value.replaceAll(" ", "");
      } else if (typeof value === "boolean") {
        (updatedDeviceData[name] as boolean) = value;
      }
    }

    deviceFormVariables({
      ...deviceForm,
      isDirty: true,
      deviceData: updatedDeviceData,
    });
  };

  useEffect(() => {
    let sourceVideoValue;
    let rtspTemplate = "";

    const firstSymbolOfRtsp = deviceForm.rtspTemplate
      ? deviceForm.rtspTemplate[0]
      : "";

    if (
      deviceForm.rtspTemplate &&
      (firstSymbolOfRtsp === ":" || firstSymbolOfRtsp === "/")
    ) {
      rtspTemplate = deviceForm.rtspTemplate;
    }

    if (deviceForm.defaultUserPwd) {
      sourceVideoValue = `rtsp://${deviceForm.defaultUserPwd}@${deviceForm.cameraIpAddress}${rtspTemplate}`;
    } else {
      sourceVideoValue = `rtsp://${deviceForm.cameraIpAddress}${rtspTemplate}`;
    }

    setDeviceConfig("sourceVideo", sourceVideoValue);
  }, [
    deviceForm.defaultUserPwd,
    deviceForm.cameraIpAddress,
    deviceForm.rtspTemplate,
    deviceForm.hasPanTilt,
    deviceForm.hasZoom,
  ]);

  useEffect(() => {
    if (forceTCP) {
      setDeviceConfig("protocol", "tcp");
    } else {
      setDeviceConfig("protocol", null);
    }
  }, [forceTCP]);

  useEffect(() => {
    if (recordVideo) {
      setDeviceConfig("hasRecordVideo", true);
    } else {
      setDeviceConfig("hasRecordVideo", false);
    }
  }, [recordVideo, deviceForm.cameraIpAddress]);

  useEffect(() => {
    let rtspHost = `rtsp://<nodeIP>/${deviceForm.deviceName}`;

    if (deviceForm.deviceData.rtspHost) {
      const indexOfLastDash =
        typeof deviceForm.deviceData.rtspHost === "string"
          ? deviceForm.deviceData.rtspHost.lastIndexOf("/")
          : 0;

      const getFirstPart =
        typeof deviceForm.deviceData.rtspHost === "string"
          ? deviceForm.deviceData.rtspHost.slice(0, indexOfLastDash)
          : "";

      rtspHost = `${getFirstPart}/${deviceForm.deviceName}`;
    }

    setDeviceConfig("rtspHost", rtspHost);
  }, [
    deviceForm.deviceName,
    deviceForm.hasPanTilt,
    deviceForm.node?.id,
    deviceForm.hasZoom,
  ]);

  return (
    <Grid container justifyContent="center" direction="column" spacing={2}>
      {showLocationSelect && (
        <Grid item>
          <LocationIdSelect
            disableClearable
            setDefault
            hasError={validation?.location.hasError}
            location={deviceForm.location}
            setLocation={setLocation}
          />
        </Grid>
      )}
      {!showLocationSelect && (
        <Grid item>
          <Typography variant="body1">Location</Typography>
          <Box
            sx={{
              color: theme.palette.text.primary,
              fontWeight: "bold",
            }}
          >
            {selectedLocation?.title}
          </Box>
        </Grid>
      )}
      <Grid item>
        <MakeSelect
          label="Device model"
          hasError={validation.deviceType.hasError}
          makeId={deviceForm.deviceType}
          disabled={editMode}
          onMakeChange={setDeviceType}
        />
      </Grid>

      {deviceForm.deviceType && (
        <Grid item>
          <Grid item>
            <TextField
              margin="dense"
              error={validation?.deviceName.hasError && !deviceForm.deviceName}
              helperText={
                validation?.deviceName.hasError
                  ? validation?.deviceName.errorMessage
                  : "Recommended to use the camera type as the Device ID"
              }
              fullWidth
              label="Device ID"
              value={deviceForm.deviceName}
              onChange={setDeviceName}
            />
          </Grid>
          <Grid item>
            <NodeSelect
              disableClearable
              label="Device Management Node"
              hasError={validation?.node.hasError}
              nodeId={deviceForm.node?.id ?? ""}
              onNodeChange={setNodeId}
            />
          </Grid>

          <Grid item>
            <TextField
              margin="dense"
              error={
                validation?.cameraIpAddress.hasError &&
                !deviceForm.cameraIpAddress
              }
              helperText={
                validation?.cameraIpAddress.hasError &&
                validation?.cameraIpAddress.errorMessage
              }
              fullWidth
              label="Camera IP Address"
              value={deviceForm.cameraIpAddress ?? ""}
              onChange={setCameraIpAddress}
            />
          </Grid>

          <Grid item>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>Force TCP</Typography>
              <Switch checked={forceTCP} onChange={handleForceTcpChange} />
            </Stack>
          </Grid>

          <Grid item>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>Enable Recording</Typography>
              <Switch
                checked={recordVideo}
                onChange={handleEnableRecordingChange}
              />
            </Stack>
          </Grid>

          <br />
          <Divider />
          <br />

          <DeviceDynamicFields
            deviceConfig={deviceForm.deviceData}
            setDeviceConfig={setDeviceConfig}
            hasPanTilt={Boolean(deviceForm.deviceMakePanTiltValue)}
            hasZoom={Boolean(deviceForm.deviceMakeHasZoomValue)}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default DeviceForm;
