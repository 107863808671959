import { ApolloError } from "@apollo/client";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { Button, Typography } from "@mui/material";
import { useConfirm } from "material-ui-confirm";

import StyledDialog from "../../../common/components/dialog/StyledDialog";
import { useDialog } from "../../../common/components/dialog/useDialog";
import confirmDialogStyleOptions from "../../../common/helpers/confirmDialogParams";
import { useCustomerIdGuard } from "../../../common/hooks/useCustomerIdGuard";
import {
  errorNotification,
  successNotification,
} from "../../../common/variables/notification";
import { useSelectedLocationDropdown } from "../../../common/variables/selectedLocationDropdown";
import client from "../../../configs/apolloClient";
import { useSyncShadow } from "../../model-manager/hooks/useSyncShadow";
import { useCreateDevice } from "../hooks/useCreateDevice";
import {
  defaultDeviceFormValidationState,
  defaultDeviceFormValues,
  deviceFormRules,
  deviceFormValidationStateVariable,
  deviceFormVariables,
  useDeviceFormVariables,
} from "../variables/devices";
import DeviceForm from "./DeviceForm";

const CreateDeviceDialogContainer = ({
  overlay = false,
}: {
  overlay?: boolean;
}): JSX.Element => {
  const confirm = useConfirm();
  const form = useDeviceFormVariables();
  const selectedCustomerId = useCustomerIdGuard();
  const selectedLocation = useSelectedLocationDropdown();
  const { syncShadowsForNode } = useSyncShadow();

  const { open, handleDialogOpen, handleDialogClose } = useDialog();

  const { createDevice, loading } = useCreateDevice();

  const openDialog = (): void => {
    deviceFormVariables(defaultDeviceFormValues);

    deviceFormValidationStateVariable(defaultDeviceFormValidationState);

    handleDialogOpen();
  };

  const closeDialog = (): void => {
    deviceFormVariables(defaultDeviceFormValues);

    deviceFormValidationStateVariable(defaultDeviceFormValidationState);

    handleDialogClose();
  };

  const confirmDialogClose = (): void => {
    confirm({
      title: "Are you sure you want to cancel Device creation?",
      confirmationText: "Cancel",
      cancellationText: "Back",
      ...confirmDialogStyleOptions,
      confirmationButtonProps: {
        ...confirmDialogStyleOptions.confirmationButtonProps,
        color: "primary",
      },
    }).then(async (): Promise<void> => {
      closeDialog();
    });
  };

  const saveDevice = async (): Promise<void> => {
    let validationState = {
      ...defaultDeviceFormValidationState,
    };

    const deviceId = `${form.location?.value ?? ""}#DE#${form.deviceName}`;

    const normalizedId = client.cache.identify({
      id: deviceId,
      __typename: "Device",
    });

    const extract = client.cache.extract();

    if (normalizedId && extract[normalizedId]?.name === form.deviceName) {
      validationState = {
        ...validationState,
        deviceName: {
          hasError: true,
          errorMessage: "Device ID already exists",
        },
      };
    }

    if (!form.node?.id) {
      validationState = {
        ...validationState,
        node: {
          hasError: true,
          errorMessage: "Node is required",
        },
      };
    }

    if (!form.deviceType) {
      validationState = {
        ...validationState,
        deviceType: {
          hasError: true,
          errorMessage: "Device Model is required",
        },
      };
    }

    if (!form.cameraIpAddress) {
      validationState = {
        ...validationState,
        cameraIpAddress: {
          hasError: true,
          errorMessage: "This field is required",
        },
      };
    }

    if (form.deviceData.hasPanTilt && !form.deviceData.panTiltIP) {
      validationState = {
        ...validationState,
        panTiltIP: {
          hasError: true,
          errorMessage: "Pan Tilt IP is required",
        },
      };
    }

    if (
      !form.deviceData.sourceVideo.match(deviceFormRules.sourceVideo.pattern)
    ) {
      validationState = {
        ...validationState,
        sourceVideo: {
          hasError: true,
          errorMessage: "Invalid Characters",
        },
      };
    }

    if (
      Object.values(validationState).some(
        (item): boolean => item?.hasError ?? false
      )
    ) {
      deviceFormValidationStateVariable(validationState);

      return;
    }

    try {
      form.deviceData.sourceVideo =
        typeof form.deviceData.sourceVideo === "string"
          ? form.deviceData.sourceVideo.replaceAll(" ", "")
          : "";

      form.deviceData.rtspHost =
        typeof form.deviceData.rtspHost === "string"
          ? form.deviceData.rtspHost.replaceAll(" ", "")
          : "";

      await createDevice({
        customerId: selectedCustomerId,
        locationId: selectedLocation?.value ?? "",
        locationName: selectedLocation?.title,
        name: form.deviceName,
        nodeId: form.node?.id ?? "",
        nodeName: form.node?.name,
        makeModelId: form.deviceType,
        cameraIpAddress: form.cameraIpAddress?.replaceAll(" ", ""),
        deviceData: form.deviceData ? JSON.stringify(form.deviceData) : null,
      });

      closeDialog();

      successNotification("Device has been successfully created!");

      await syncShadowsForNode(form.node?.id);
    } catch (error) {
      if (
        (error as ApolloError).message.includes(
          "The conditional request failed"
        )
      ) {
        errorNotification("Device ID already exists");
      } else {
        errorNotification((error as ApolloError).message);
      }

      console.error(error);
    }
  };

  return (
    <>
      <Button
        variant={overlay ? "contained" : "outlined"}
        size={overlay ? "medium" : "small"}
        startIcon={<AddOutlinedIcon />}
        onClick={openDialog}
      >
        <Typography variant="buttonMedium">New device</Typography>
      </Button>
      <StyledDialog
        open={open}
        title="Create a new device"
        onClose={confirmDialogClose}
        SubmitButtonProps={{
          loading,
          onSubmit: saveDevice,
        }}
      >
        <DeviceForm />
      </StyledDialog>
    </>
  );
};

export default CreateDeviceDialogContainer;
