import { memo, useEffect, useState } from "react";

import CardMediaVideoPlayerWithSpeedControls from "../../../pages/media-viewer/components/player/CardMediaVideoPlayerWithSpeedControls";
import { S3Helper } from "../../utils/s3helper";
import CircularLoading from "../progress/CircularLoading";
import { IS3MediaFile } from "./IS3MediaFileProps";

interface IS3VideoProps extends IS3MediaFile {
  controls?: boolean;
}

const S3VideoComponent = ({ s3Key, controls }: IS3VideoProps): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [mediaUrl, setMediaUrl] = useState("");

  useEffect((): void => {
    if (s3Key) {
      setLoading(true);

      let mediaUrl = s3Key;

      try {
        mediaUrl = JSON.parse(mediaUrl);
      } catch (error) {
        console.log(`s3Key is already parsed`);
      }

      S3Helper.getObject(mediaUrl)
        .then((result: string): void => {
          setMediaUrl(result);
        })
        .catch((error: Error): void => console.log("S3Video", error.message))
        .finally((): void => setLoading(false));
    }
  }, [s3Key]);

  return (
    <div>
      {loading && <CircularLoading />}
      {!loading && !mediaUrl && <div>No Video Available</div>}
      {!loading && mediaUrl && (
        <CardMediaVideoPlayerWithSpeedControls
          sx={{
            maxHeight: "calc(100vh - 64px)",
          }}
          controls={controls}
          src={mediaUrl}
          autoPlay
        />
      )}
    </div>
  );
};

const S3Video = memo(
  S3VideoComponent,
  (prevProps, nextProps): boolean => prevProps.s3Key === nextProps.s3Key
);

export default S3Video;
