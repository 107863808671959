import { useTheme } from "@mui/material/styles";

const SmokeDataHubIcon = (): JSX.Element => {
  const theme = useTheme();

  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="40"
        height="40"
        rx="8"
        fill={theme.palette.otherBackground.main}
      />
      <path
        d="M14.0939 29.9065C14.0939 29.9065 16.4438 27.7425 16.5939 25.9065C16.7506 23.9897 16.5939 23.9065 15.0933 21.4065C14.8169 20.946 13.5944 19.4065 13.0937 17.9065C12.4606 16.0096 13.5937 14.4065 14.0932 13.4065"
        stroke={theme.palette.text.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M24.5933 29.9065C24.5933 29.9065 27.0936 27.4823 27.0936 25.4065C27.0936 22.4065 24.9025 19.2546 24.0932 17.9065C23.2838 16.5584 23.0935 14.4065 24.5932 12.4065"
        stroke={theme.palette.text.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M20.5931 9.90649C17.5937 13.4065 17.5938 14.9065 18.0933 16.9065C18.5928 18.9065 22.0938 20.9065 22.0938 23.4065C22.0938 25.9065 20.0938 28.9065 19.0938 29.9065"
        stroke={theme.palette.text.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default SmokeDataHubIcon;
