import ArrowForwardIosIcon from "@mui/icons-material/CallMade";
import { Box, Button, Typography } from "@mui/material";
import Chart from "react-apexcharts";

import { TimePeriod } from "../../../API";
import { apexChartsTooltipStyles } from "../../../common/components/apexchart/StyledChart";
import AllLocationsFilterDropdownContainer from "../../../common/components/locations/AllLocationsFilterDropdownContainer";
import CircularLoading from "../../../common/components/progress/CircularLoading";
import { AutocompleteOptionType } from "../../../common/models/autocomplete";
import { GasLeakContainerSteps } from "./GasLeakContainer";
import StatisticsNoDataView from "./StatisticsNoDataView";
import TimePeriodFilter from "./TimePeriodFilter";

type GasLeakEmissionVolumeViewProps = {
  showNoData: boolean;
  activeTimePeriod: TimePeriod;
  handleTimePeriodChange: (timePeriod: TimePeriod) => void;
  locationVariable: AutocompleteOptionType;
  handleLocationChange: (locationValue: AutocompleteOptionType) => void;
  handleTagLocationsChange: (
    locations: AutocompleteOptionType[] | null
  ) => void;
  loading: boolean;
  setStepsHandler: (step: number) => void;
  options: ApexCharts.ApexOptions;
  series: ApexAxisChartSeries | ApexNonAxisChartSeries | undefined;
};

const GasLeakEmissionVolumeView: React.FC<GasLeakEmissionVolumeViewProps> = ({
  showNoData,
  activeTimePeriod,
  handleTimePeriodChange,
  handleLocationChange,
  handleTagLocationsChange,
  setStepsHandler,
  loading,
  options,
  series,
}): JSX.Element => {
  return (
    <>
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "0.5em",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography sx={{ marginRight: "1em" }} variant="h5">
              Gas leak emission volume
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              marginLeft: "auto",
            }}
          >
            <TimePeriodFilter
              activePeriod={activeTimePeriod}
              setActivePeriod={handleTimePeriodChange}
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            width: "180px",
          }}
        >
          <AllLocationsFilterDropdownContainer
            setLocation={handleLocationChange}
            setTagLocations={handleTagLocationsChange}
          />
        </Box>

        {!showNoData && !loading && (
          <Button
            onClick={(): void =>
              setStepsHandler(GasLeakContainerSteps.DetailedLocaleInformation)
            }
            sx={{ padding: "5px" }}
            variant="text"
            disableRipple
          >
            {
              <>
                <Typography
                  variant="subtitle1"
                  color="palette.primary.main"
                  component="div"
                  style={{ textTransform: "none" }}
                >
                  Detailed information
                </Typography>

                <ArrowForwardIosIcon
                  style={{ fontSize: "12px", marginLeft: "5px" }}
                />
              </>
            }
          </Button>
        )}
      </Box>

      {loading && (
        <Box sx={{ height: "250px" }}>
          <CircularLoading />
        </Box>
      )}

      {!loading && !showNoData && (
        <Box sx={apexChartsTooltipStyles}>
          <Chart options={options} series={series} height={300} />
        </Box>
      )}

      {showNoData && <StatisticsNoDataView />}
    </>
  );
};

export default GasLeakEmissionVolumeView;
