import { useState } from "react";

import { UpdateAlertStatusInput } from "../../../../../../API";
import StatusToggle from "../../../../../../common/components/item/StatusToggle";
import { useCustomerIdGuard } from "../../../../../../common/hooks/useCustomerIdGuard";
import {
  errorNotification,
  successNotification,
} from "../../../../../../common/variables/notification";
import { IAlertsTableRow } from "../../hooks/useAlertsTableRow";
import { useUpdateAlertStatus } from "../../hooks/useUpdateAlertStatus";
import { useUpdateUserAlertStatus } from "../../hooks/useUpdateUserAlertStatus";
import { AlertTypeEnum, AlertTypeField } from "../../variables/alerts";

interface IToggleAlertNotificationProps {
  row: IAlertsTableRow;
  alertType: AlertTypeEnum;
  notificationSettingsField: AlertTypeField;
  status: boolean;
  isAdminAccess: boolean;
  disabled?: boolean;
}

const ToggleAlertNotification = ({
  row,
  alertType,
  notificationSettingsField,
  status,
  isAdminAccess,
  disabled,
}: IToggleAlertNotificationProps): JSX.Element => {
  const selectedCustomerId = useCustomerIdGuard();

  const { updateAlert, loading } = isAdminAccess
    ? useUpdateAlertStatus()
    : useUpdateUserAlertStatus();

  const [isChecked, setIsChecked] = useState(status);

  const handleTurnOffClick = (): void => {
    setIsChecked(!isChecked);

    const input: UpdateAlertStatusInput = {
      customerId: selectedCustomerId,
      id: row?.rowId,
      notificationSettingsJson: JSON.stringify({
        ...row.notificationSettings,
        [notificationSettingsField]: !isChecked,
      }),
    };

    updateAlert(input)
      .then((response): void => {
        if (response.data?.updateAlertStatus) {
          successNotification(
            `${
              alertType === AlertTypeEnum.EMAIL ? "Email" : "Mobile SMS"
            } alert is switched ${status ? "off" : "on"} for ${row?.alertName}`
          );
        }
      })
      .catch((error): void => {
        errorNotification(
          `Something went wrong, email alerts are not switched ${
            status ? "off" : "on"
          } for ${row?.alertName}`
        );

        console.error(error);
      });
  };

  return (
    <StatusToggle
      loading={loading}
      disabled={loading || disabled}
      checked={!!isChecked}
      handleChange={handleTurnOffClick}
    />
  );
};

export default ToggleAlertNotification;
