import { useEffect, useMemo, useState } from "react";

import uniq from "lodash/uniq";

import {
  ModelType,
  StatisticsDataInput,
  TimePeriod,
  eventType,
} from "../../../API";
import ApexOptions from "../../../common/components/apexchart/ApexChartModels";
import useLocationFromCache from "../../../common/hooks/useLocationFromCache";
import {
  IChartProps,
  IMostActiveLocationsResponseModel,
  IStatisticsResponseModel,
} from "../models/chart";
import { useGetStatisticsData } from "./useGetStatisticsData";
import { useTheme } from "@mui/material";

export const useMostActiveLocationsChart = (
  timePeriod: TimePeriod
): IChartProps => {
  const theme = useTheme();

  const options: ApexOptions = {
    chart: {
      type: "bar",
      stacked: true,
      height: 300,
      toolbar: {
        show: false,
      },
      fontFamily: "Inter",
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          total: {
            enabled: true,
            offsetX: 0,
            style: {
              fontSize: "13px",
              fontWeight: 900,
            },
          },
        },
      },
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    theme: {
      mode: theme.palette.mode,
    },
    tooltip: {
      enabled: false,
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      showForNullSeries: false,
      showForZeroSeries: false,
      showForSingleSeries: true,
      fontFamily: "Inter",
    },
    colors: [
      "#F150FF", // agg
      "#EB5564", // fire
      "#735EC9", // gasleak
      "#3E8DF3", // hardhat
      "#F3B344", // liquidLeak
      "#4EB100", // smoke
      "#67E09C", // tlm
    ],
  };

  const filterParams = useMemo(
    (): Pick<
      StatisticsDataInput,
      "eventType" | "models" | "timePeriod" | "locations"
    > => ({
      eventType: eventType.totalSiteCounts,
      models: [],
      timePeriod,
      locations: null,
    }),
    [timePeriod]
  );

  const { data, loading, refetching, refetchChartData } =
    useGetStatisticsData<IStatisticsResponseModel[]>(filterParams);

  const { getCachedLocation } = useLocationFromCache();

  const [chartSeries, setChartProps] = useState<any>([]);
  const [chartOptions, setChartOptions] = useState<any>(options);

  useEffect(() => {
    const parsed = data;

    const series = generateChartSeries(parsed);
    const extendedOptions = generateChartCategories(parsed);

    setChartProps(series);

    setChartOptions(extendedOptions);
  }, [data, theme.palette.mode]);

  const generateChartSeries = (
    response: IMostActiveLocationsResponseModel[]
  ): ApexOptions["series"] => {
    const locations = uniq(response.map(s => s.location)) || [];

    const series = Object.values(ModelType).map((model: string) => ({
      name: model,
      data: locations.map(l => {
        const matchingEntry = response.find(
          entry => entry.model === model && l === entry.location
        );

        return matchingEntry ? parseInt(matchingEntry.record_count, 10) : null;
      }),
    }));

    return series;
  };

  const generateChartCategories = (
    response: IMostActiveLocationsResponseModel[]
  ): ApexOptions => {
    if (!Array.isArray(response) || response.length === 0) {
      return options;
    }

    const categories = uniq(response.map(s => s.location)).map(
      (location, index) => {
        const locationName = location.includes("#L#")
          ? getCachedLocation(location ?? "")?.name ?? ""
          : location;

        return `${index + 1}. ${locationName}`;
      }
    );

    const extendedOptions: ApexOptions = {
      ...options,
      xaxis: {
        categories,
        labels: {
          formatter: function (val) {
            const strValue = val.toString();
            const value = strValue.includes(".")
              ? (+strValue).toFixed(1)
              : strValue;

            return value;
          },
        },
      },
    };

    return extendedOptions;
  };

  return {
    series: chartSeries,
    options: chartOptions,
    loading,
    refetching,
    refetchChartData,
    showNoData:
      !loading && !refetching && data?.filter(d => d.record_count).length === 0,
  };
};
