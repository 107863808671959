import { Box, Grid, TextField } from "@mui/material";

import LocationIdSelect from "../../../common/components/layout/components/LocationIdSelect";
import { Control, Controller, FieldErrors } from "react-hook-form";
import { FormValues } from "../nodes-create/components/CreateNodeFormStep";
import { IP_MASK_PATTERN } from "../../../common/variables/common";

type NodeFormProps = {
  fullWidth?: boolean;
  control: Control<FormValues>;
  errors: FieldErrors<FormValues>;
};

const NodeForm = ({
  fullWidth,
  control,
  errors,
}: NodeFormProps): JSX.Element => {
  const sm = fullWidth ? 12 : 6;

  const locationInfo = "You can change the location only in the left menu";

  return (
    <Grid container rowSpacing={1} columnSpacing={3}>
      <Grid item xs={12} sm={sm}>
        <Controller
          name="nodeId"
          control={control}
          rules={{ required: "This field is required" }}
          render={({ field }) => (
            <TextField
              {...field}
              value={field.value || ""}
              margin="dense"
              sx={{
                height: "68px",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px",
                },
              }}
              label={"Node ID"}
              fullWidth
              error={!!errors.nodeId}
              helperText={errors.nodeId ? errors.nodeId.message : ""}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={sm}>
        <Controller
          name="referenceId"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              value={field.value || ""}
              margin="dense"
              sx={{
                height: "68px",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px",
                },
              }}
              label={"Reference ID (optional)"}
              fullWidth
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={sm}>
        <Controller
          name="location"
          control={control}
          rules={{ required: "This field is required. " + locationInfo }}
          render={({ field }) => (
            <Box sx={{ position: "relative", top: "8px" }}>
              <LocationIdSelect
                hasError={!!errors.location}
                label="Location"
                helperText={errors.location?.message ?? locationInfo}
                setDefault
                defaultValue={field?.value?.value}
                location={field.value ? field.value : null}
                disableClearable
                disabled
                setLocation={() => {}}
              />
            </Box>
          )}
        />
      </Grid>

      <Grid item xs={12} sm={sm}>
        <Controller
          name="nodeIP"
          control={control}
          rules={{
            required: "This field is required",
            pattern: {
              value: IP_MASK_PATTERN,
              message: "Please enter a valid IP address",
            },
          }}
          render={({ field }) => (
            <TextField
              {...field}
              value={field.value || ""}
              margin="dense"
              sx={{
                height: "68px",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px",
                },
              }}
              label={"IP address"}
              fullWidth
              error={!!errors.nodeIP}
              helperText={errors.nodeIP ? errors.nodeIP.message : ""}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default NodeForm;
