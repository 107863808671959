import { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import FullPageLoader from "../common/components/item/FullPageLoader";
import { useAuthenticatedUser } from "../common/hooks/useAuthenticatedUser";
import { RoleEnum, RouteEnum } from "../common/models/enums";
import ClientUploadsPage from "../leak-finder/ClientUploadsPage";
import AddNewClientPage from "../leak-finder/components/AddNewClientPage";
import EditClientPage from "../leak-finder/components/EditClientPage";
import EditGroupPage from "../leak-finder/components/EditGroupPage";
import { UploadVideoGroupPage } from "../leak-finder/pages/UploadVideoGroupPage";
import LeakFinderUserPreferencesPage from "../leak-finder/pages/user-preferences/LeakFinderUserPreferencesPage";
import OverviewModelPage from "../pages/devices/components/OverviewModelPage";
import LoginPage from "../pages/login/LoginPage";
import MediaViewerPage from "../pages/media-viewer/MediaViewerPage";
import CreatePlateNumberPage from "../pages/system-settings/tabs/plates-management/components/CreatePlateNumberPage";
import CreatePlateNumberPageSystemSettings from "../pages/system-settings/tabs/plates-management/components/CreatePlateNumberPageSystemSettings";
import PauseAlertsPage from "../pages/system-settings/tabs/plates-management/components/PauseAlertsPage";
import LeakFinderRoleBasedRoute from "./LeakFinderRoleBasedRoute";
import RoleBasedRoute from "./RoleBasedRoute";
import {
  getRolePath,
  isLocalEnvironment,
} from "../common/helpers/userLinkHelper";
import { AnyRouteHandler } from "./AnyRouteHandler";
import UnifiedLayout from "../common/components/layout/UnifiedLayout";

import CustomerSettingsPage from "../pages/customer-settings/CustomerSettingsPage";

import CreateAlertPage from "../pages/customer-settings/tabs/alert-management/create-alert/CreateAlertPage";
import CreateLocationPage from "../pages/customer-settings/tabs/locations/CreateLocationPage";
import EditLocationPage from "../pages/customer-settings/tabs/locations/EditLocationPage";

import AddModelPage from "../pages/devices/components/AddModelPage";

import DataHubPage from "../pages/data-hub/DataHubPage";
import DataHubDashboard from "../pages/data-hub/components/DataHubDashboard";
import DataHubPageContent from "../pages/data-hub/components/DataHubPageContent";
import DataHubLiveViewPageContent from "../pages/data-hub/components/liveview/DataHubLiveViewPageContent";
import ZoneList from "../pages/data-hub/components/liveview/components/zonelist/ZoneList";

import DeviceDetailPage from "../pages/devices/device-details/DeviceDetailPage";
import DevicesPage from "../pages/devices/DevicesPage";
import EditDevicePage from "../pages/devices/EditDevicePage";
import EditZonePage from "../pages/devices/components/EditZonePage";
import ZoneOverviewPage from "../pages/devices/components/ZoneOverviewPage";
import CreateDevicePage from "../pages/devices/devices-create/CreateDevicePage";
import CreateZonePage from "../pages/devices/zones-setup-page/CreateZonePage";

import EmailNotificationsPage from "../pages/email-notifications/EmailNotificationsPage";
import HumanValidatorPage from "../pages/human-validator/HumanValidatorPage";
import HvReviewPage from "../pages/hv-review/HvReviewPage";

import AzureIntegrationPage from "../pages/integrations/components/AzureIntegrationPage";
import EdgeIntegrationPage from "../pages/integrations/components/EdgeIntegrationPage";
import S3IntegrationPage from "../pages/integrations/components/S3IntegrationPage";

import ModelManagerPage from "../pages/model-manager/ModelManagerPage";
import CreateServicePage from "../pages/model-manager/service-create/CreateServicePage";

import NodesPage from "../pages/nodes/NodesPage";
import CreateNodePage from "../pages/nodes/nodes-create/CreateNodePage";

import NotAuthorizedPage from "../pages/not-authorized-page/NotAuthorizedPage";
import StatisticsPage from "../pages/statistics/StatisticsPage";
import SystemSettingsPage from "../pages/system-settings/SystemSettingsPage";
import DeploymentHealthPage from "../pages/deployment-health/DeploymentHealthPage";

import PlatesManagement from "../pages/system-settings/tabs/plates-management/PlatesManagement";
import EditPlateNumberPage from "../pages/system-settings/tabs/plates-management/components/EditPlateNumberPage";
import EditCategoryPage from "../pages/system-settings/tabs/plates-management/components/plate-category/EditCategoryPage";
import { useSetSharedSession } from "../common/hooks/useSetSharedSession";

const AppRoutes = () => {
  useSetSharedSession();

  const { route, role, user } = useAuthenticatedUser();

  /**
   * If in the process of setting up then wait
   */
  if (route === "idle") {
    return <FullPageLoader />;
  }

  const { location } = window;
  const isNotOnLoginPage = location.pathname !== RouteEnum.Login;

  if (!role && !user && isNotOnLoginPage) {
    isLocalEnvironment
      ? (window.location.href = RouteEnum.Login)
      : (window.location.href = RouteEnum.Administration);

    return null;
  }

  const userMainLink = getRolePath(role as RoleEnum);
  const element = <Navigate to={userMainLink} replace />;

  return (
    <Suspense fallback={<FullPageLoader />}>
      <Routes>
        <Route path="/" element={element} />

        {isLocalEnvironment && (
          <Route path={RouteEnum.Login} element={<LoginPage />} />
        )}

        <Route path={RouteEnum.Home} element={<NotAuthorizedPage />} />

        <Route element={<UnifiedLayout />}>
          <Route
            path={RouteEnum.MediaViewer}
            index
            element={
              <RoleBasedRoute
                allowedRoles={[
                  RoleEnum.ROOT,
                  RoleEnum.CC_OPS_ADMIN,
                  RoleEnum.CC_OPS,
                  RoleEnum.CC_SALES,
                  RoleEnum.PARTNER_ADMIN,
                  RoleEnum.CUSTOMER_ADMIN,
                  RoleEnum.CUSTOMER_HSE,
                  RoleEnum.CUSTOMER_SECURITY,
                  RoleEnum.LEAKFINDER_DIRECTOR,
                  RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                  RoleEnum.PARTNER_USER,
                  RoleEnum.CUSTOMER_OPS_TEAM,
                  RoleEnum.VERIFIER,
                ]}
              >
                <MediaViewerPage />
              </RoleBasedRoute>
            }
          />

          <Route>
            <Route
              path={RouteEnum.RecentActivities}
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    RoleEnum.ROOT,
                    RoleEnum.CC_OPS_ADMIN,
                    RoleEnum.CC_OPS,
                    RoleEnum.CC_SALES,
                    RoleEnum.PARTNER_ADMIN,
                    RoleEnum.CUSTOMER_ADMIN,
                    RoleEnum.CUSTOMER_HSE,
                    RoleEnum.CUSTOMER_SECURITY,
                  ]}
                >
                  <DataHubPage />
                </RoleBasedRoute>
              }
            >
              <Route
                index
                element={
                  <RoleBasedRoute
                    allowedRoles={[
                      RoleEnum.ROOT,
                      RoleEnum.CC_OPS_ADMIN,
                      RoleEnum.CC_OPS,
                      RoleEnum.CC_SALES,
                      RoleEnum.PARTNER_ADMIN,
                      RoleEnum.CUSTOMER_ADMIN,
                      RoleEnum.CUSTOMER_HSE,
                      RoleEnum.CUSTOMER_SECURITY,
                    ]}
                  >
                    <DataHubDashboard />
                  </RoleBasedRoute>
                }
              />
              <Route
                path={RouteEnum.LiveView}
                element={
                  <RoleBasedRoute
                    allowedRoles={[
                      RoleEnum.ROOT,
                      RoleEnum.CC_OPS_ADMIN,
                      RoleEnum.CC_OPS,
                      RoleEnum.CC_SALES,
                      RoleEnum.PARTNER_ADMIN,
                      RoleEnum.CUSTOMER_ADMIN,
                      RoleEnum.CUSTOMER_HSE,
                      RoleEnum.CUSTOMER_SECURITY,
                    ]}
                  >
                    <DataHubLiveViewPageContent />
                  </RoleBasedRoute>
                }
              />
              <Route
                path={RouteEnum.ZoneList}
                element={
                  <RoleBasedRoute
                    allowedRoles={[
                      RoleEnum.ROOT,
                      RoleEnum.CC_OPS_ADMIN,
                      RoleEnum.CC_OPS,
                      RoleEnum.CC_SALES,
                      RoleEnum.PARTNER_ADMIN,
                      RoleEnum.CUSTOMER_ADMIN,
                      RoleEnum.CUSTOMER_HSE,
                      RoleEnum.CUSTOMER_SECURITY,
                    ]}
                  >
                    <ZoneList />
                  </RoleBasedRoute>
                }
              />
              <Route
                path={RouteEnum.CreateZone}
                element={
                  <RoleBasedRoute
                    allowedRoles={[
                      RoleEnum.ROOT,
                      RoleEnum.CC_OPS_ADMIN,
                      RoleEnum.CC_OPS,
                      RoleEnum.CC_SALES,
                      RoleEnum.PARTNER_ADMIN,
                      RoleEnum.CUSTOMER_ADMIN,
                      RoleEnum.CUSTOMER_HSE,
                      RoleEnum.CUSTOMER_SECURITY,
                    ]}
                  >
                    <CreateZonePage />
                  </RoleBasedRoute>
                }
              />
              <Route
                path={`${RouteEnum.RecentActivities}/:model`}
                element={
                  <RoleBasedRoute
                    allowedRoles={[
                      RoleEnum.ROOT,
                      RoleEnum.CC_OPS_ADMIN,
                      RoleEnum.CC_OPS,
                      RoleEnum.CC_SALES,
                      RoleEnum.PARTNER_ADMIN,
                      RoleEnum.CUSTOMER_ADMIN,
                      RoleEnum.CUSTOMER_HSE,
                      RoleEnum.CUSTOMER_SECURITY,
                    ]}
                  >
                    <DataHubPageContent />
                  </RoleBasedRoute>
                }
              />
            </Route>
            <Route
              path="/nodes/create"
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    RoleEnum.ROOT,
                    RoleEnum.CC_OPS_ADMIN,
                    RoleEnum.CC_OPS,
                    RoleEnum.CC_SALES,

                    RoleEnum.CUSTOMER_ADMIN,
                  ]}
                >
                  <CreateNodePage />
                </RoleBasedRoute>
              }
            />
            <Route
              path="/nodes"
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    RoleEnum.ROOT,
                    RoleEnum.CC_OPS_ADMIN,
                    RoleEnum.CC_OPS,
                    RoleEnum.CC_SALES,
                  ]}
                >
                  <NodesPage />
                </RoleBasedRoute>
              }
            />

            <Route
              path="/devices/create"
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    RoleEnum.ROOT,
                    RoleEnum.CC_OPS_ADMIN,
                    RoleEnum.CC_OPS,
                    RoleEnum.CC_SALES,
                    RoleEnum.PARTNER_ADMIN,
                    RoleEnum.CUSTOMER_ADMIN,
                  ]}
                >
                  <CreateDevicePage />
                </RoleBasedRoute>
              }
            />
            <Route
              path="/devices/zone-setup"
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    RoleEnum.ROOT,
                    RoleEnum.CC_OPS_ADMIN,
                    RoleEnum.CC_OPS,
                    RoleEnum.CC_SALES,
                    RoleEnum.PARTNER_ADMIN,
                    RoleEnum.CUSTOMER_ADMIN,
                  ]}
                >
                  <CreateZonePage />
                </RoleBasedRoute>
              }
            />

            <Route
              path="/devices/:locationId"
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    RoleEnum.ROOT,
                    RoleEnum.CC_OPS_ADMIN,
                    RoleEnum.CC_OPS,
                    RoleEnum.CC_SALES,
                    RoleEnum.PARTNER_ADMIN,
                    RoleEnum.CUSTOMER_ADMIN,
                  ]}
                >
                  <DevicesPage />
                </RoleBasedRoute>
              }
            />

            <Route
              path="/devices/:nodeId"
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    RoleEnum.ROOT,
                    RoleEnum.CC_OPS_ADMIN,
                    RoleEnum.CC_OPS,
                    RoleEnum.CC_SALES,
                    RoleEnum.PARTNER_ADMIN,
                    RoleEnum.CUSTOMER_ADMIN,
                  ]}
                >
                  <DevicesPage />
                </RoleBasedRoute>
              }
            />

            <Route
              path="/device/:deviceId/create-zone"
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    RoleEnum.ROOT,
                    RoleEnum.CC_OPS_ADMIN,
                    RoleEnum.CC_OPS,
                    RoleEnum.CC_SALES,
                    RoleEnum.PARTNER_ADMIN,
                    RoleEnum.CUSTOMER_ADMIN,
                  ]}
                >
                  <CreateZonePage />
                </RoleBasedRoute>
              }
            />

            <Route
              path="/device/:deviceId/:zoneId/edit-zone"
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    RoleEnum.ROOT,
                    RoleEnum.CC_OPS_ADMIN,
                    RoleEnum.CC_OPS,
                    RoleEnum.CC_SALES,
                    RoleEnum.PARTNER_ADMIN,
                    RoleEnum.CUSTOMER_ADMIN,
                  ]}
                >
                  <EditZonePage />
                </RoleBasedRoute>
              }
            />

            <Route
              path="/device/:deviceId/:zoneId"
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    RoleEnum.ROOT,
                    RoleEnum.CC_OPS_ADMIN,
                    RoleEnum.CC_OPS,
                    RoleEnum.CC_SALES,
                    RoleEnum.PARTNER_ADMIN,
                    RoleEnum.CUSTOMER_ADMIN,
                  ]}
                >
                  <ZoneOverviewPage />
                </RoleBasedRoute>
              }
            />

            <Route
              path="/device/:deviceId/add-model/:modelToRun"
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    RoleEnum.ROOT,
                    RoleEnum.CC_OPS_ADMIN,
                    RoleEnum.CC_OPS,
                    RoleEnum.CC_SALES,
                    RoleEnum.PARTNER_ADMIN,
                    RoleEnum.CUSTOMER_ADMIN,
                  ]}
                >
                  <AddModelPage />
                </RoleBasedRoute>
              }
            />

            <Route
              path="/device/:deviceId/model/:modelType"
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    RoleEnum.ROOT,
                    RoleEnum.CC_OPS_ADMIN,
                    RoleEnum.CC_OPS,
                    RoleEnum.CC_SALES,
                    RoleEnum.PARTNER_ADMIN,
                    RoleEnum.CUSTOMER_ADMIN,
                  ]}
                >
                  <OverviewModelPage />
                </RoleBasedRoute>
              }
            />

            <Route
              path="/device/:deviceId"
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    RoleEnum.ROOT,
                    RoleEnum.CC_OPS_ADMIN,
                    RoleEnum.CC_OPS,
                    RoleEnum.CC_SALES,
                    RoleEnum.PARTNER_ADMIN,
                    RoleEnum.CUSTOMER_ADMIN,
                  ]}
                >
                  <DeviceDetailPage />
                </RoleBasedRoute>
              }
            />
            <Route
              path="/device/edit/:deviceId"
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    RoleEnum.ROOT,
                    RoleEnum.CC_OPS_ADMIN,
                    RoleEnum.CC_OPS,
                    RoleEnum.CC_SALES,
                    RoleEnum.PARTNER_ADMIN,
                    RoleEnum.CUSTOMER_ADMIN,
                  ]}
                >
                  <EditDevicePage />
                </RoleBasedRoute>
              }
            />
            <Route
              path="/devices"
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    RoleEnum.ROOT,
                    RoleEnum.CC_OPS_ADMIN,
                    RoleEnum.CC_OPS,
                    RoleEnum.CC_SALES,
                    RoleEnum.PARTNER_ADMIN,
                    RoleEnum.CUSTOMER_ADMIN,
                  ]}
                >
                  <DevicesPage />
                </RoleBasedRoute>
              }
            />

            <Route
              path={RouteEnum.Statistics}
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    RoleEnum.ROOT,
                    RoleEnum.CC_OPS_ADMIN,
                    RoleEnum.CC_OPS,
                    RoleEnum.CC_SALES,
                    RoleEnum.PARTNER_ADMIN,
                    RoleEnum.CUSTOMER_ADMIN,
                    RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                    RoleEnum.PARTNER_USER,
                    RoleEnum.CUSTOMER_OPS_TEAM,
                    RoleEnum.CUSTOMER_HSE,
                    RoleEnum.CUSTOMER_SECURITY,
                  ]}
                >
                  <StatisticsPage />
                </RoleBasedRoute>
              }
            />

            <Route
              path="/model"
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    RoleEnum.ROOT,
                    RoleEnum.CC_OPS_ADMIN,
                    RoleEnum.CC_OPS,
                    RoleEnum.CC_SALES,
                    RoleEnum.PARTNER_ADMIN,
                    RoleEnum.CUSTOMER_ADMIN,
                    // RoleEnum.CUSTOMER_OPERATOR_FOREMAN, removed for now
                  ]}
                >
                  <ModelManagerPage />
                </RoleBasedRoute>
              }
            />
            <Route
              path="/model/create"
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    RoleEnum.ROOT,
                    RoleEnum.CC_OPS_ADMIN,
                    RoleEnum.CC_OPS,
                    RoleEnum.CC_SALES,
                    // RoleEnum.PARTNER_ADMIN,
                    // RoleEnum.CUSTOMER_ADMIN,
                    // RoleEnum.CUSTOMER_OPERATOR_FOREMAN, removed for now
                  ]}
                >
                  <CreateServicePage />
                </RoleBasedRoute>
              }
            />

            <Route
              path="/deployment-health"
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    RoleEnum.ROOT,
                    RoleEnum.CC_OPS_ADMIN,
                    RoleEnum.CC_OPS,
                    RoleEnum.CC_SALES,
                    RoleEnum.PARTNER_ADMIN,
                    RoleEnum.CUSTOMER_ADMIN,
                  ]}
                >
                  <DeploymentHealthPage />
                </RoleBasedRoute>
              }
            />

            <Route
              path={RouteEnum.CustomerSettings}
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    RoleEnum.ROOT,
                    RoleEnum.CC_OPS_ADMIN,
                    RoleEnum.CC_OPS,
                    RoleEnum.CC_SALES,
                    RoleEnum.PARTNER_ADMIN,
                    RoleEnum.CUSTOMER_ADMIN,
                    RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                    RoleEnum.PARTNER_USER,
                    RoleEnum.CUSTOMER_OPS_TEAM,
                    RoleEnum.CUSTOMER_HSE,
                    RoleEnum.CUSTOMER_SECURITY,
                  ]}
                >
                  <CustomerSettingsPage />
                </RoleBasedRoute>
              }
            >
              <Route path=":tabId" element={<CustomerSettingsPage />} />
            </Route>

            <Route
              path={`${RouteEnum.CustomerSettings}/${RouteEnum.CreateAlert}`}
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    RoleEnum.ROOT,
                    RoleEnum.CC_OPS_ADMIN,
                    RoleEnum.CC_OPS,
                    RoleEnum.CC_SALES,
                    RoleEnum.PARTNER_ADMIN,
                    RoleEnum.CUSTOMER_ADMIN,
                    RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                    RoleEnum.PARTNER_USER,
                    RoleEnum.CUSTOMER_OPS_TEAM,
                    RoleEnum.CUSTOMER_HSE,
                    RoleEnum.CUSTOMER_SECURITY,
                  ]}
                >
                  <CreateAlertPage />
                </RoleBasedRoute>
              }
            />

            <Route
              path="/manage-locations/create"
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    RoleEnum.ROOT,
                    RoleEnum.CC_OPS_ADMIN,
                    RoleEnum.CC_OPS,
                    RoleEnum.CC_SALES,
                    RoleEnum.PARTNER_ADMIN,
                    RoleEnum.CUSTOMER_ADMIN,
                  ]}
                >
                  <CreateLocationPage />
                </RoleBasedRoute>
              }
            />
            <Route
              path="/manage-locations/edit/:locationId"
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    RoleEnum.ROOT,
                    RoleEnum.CC_OPS_ADMIN,
                    RoleEnum.CC_OPS,
                    RoleEnum.CC_SALES,
                    RoleEnum.PARTNER_ADMIN,
                    RoleEnum.CUSTOMER_ADMIN,
                  ]}
                >
                  <EditLocationPage />
                </RoleBasedRoute>
              }
            />

            <Route
              path="/system-settings"
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    RoleEnum.ROOT,
                    RoleEnum.CC_OPS_ADMIN,
                    RoleEnum.CC_OPS,
                    RoleEnum.CC_SALES,
                    RoleEnum.PARTNER_ADMIN,
                    RoleEnum.CUSTOMER_ADMIN,
                    RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                    RoleEnum.PARTNER_USER,
                    RoleEnum.CUSTOMER_OPS_TEAM,
                    RoleEnum.CUSTOMER_HSE,
                    RoleEnum.CUSTOMER_SECURITY,
                  ]}
                >
                  <SystemSettingsPage />
                </RoleBasedRoute>
              }
            >
              <Route path=":tabId" element={<SystemSettingsPage />} />
            </Route>

            <Route
              path={RouteEnum.IntegrationsAzure}
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    RoleEnum.ROOT,
                    RoleEnum.CC_OPS_ADMIN,
                    RoleEnum.CC_OPS,
                    RoleEnum.CC_SALES,
                    RoleEnum.PARTNER_ADMIN,
                    RoleEnum.CUSTOMER_ADMIN,
                  ]}
                >
                  <AzureIntegrationPage />
                </RoleBasedRoute>
              }
            />

            <Route
              path={RouteEnum.IntegrationsS3}
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    RoleEnum.ROOT,
                    RoleEnum.CC_OPS_ADMIN,
                    RoleEnum.CC_OPS,
                    RoleEnum.CC_SALES,
                    RoleEnum.PARTNER_ADMIN,
                    RoleEnum.CUSTOMER_ADMIN,
                  ]}
                >
                  <S3IntegrationPage />
                </RoleBasedRoute>
              }
            />
            <Route
              path={RouteEnum.IntegrationsEdge}
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    RoleEnum.ROOT,
                    RoleEnum.CC_OPS_ADMIN,
                    RoleEnum.CC_OPS,
                    RoleEnum.CC_SALES,
                    RoleEnum.PARTNER_ADMIN,
                    RoleEnum.CUSTOMER_ADMIN,
                  ]}
                >
                  <EdgeIntegrationPage />
                </RoleBasedRoute>
              }
            />

            <Route
              path="/email-notifications"
              element={
                <RoleBasedRoute allowedRoles={[RoleEnum.ROOT]}>
                  <EmailNotificationsPage />
                </RoleBasedRoute>
              }
            />

            <Route
              path={`${RouteEnum.RecentActivitiesPlatesManagement}`}
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    RoleEnum.ROOT,
                    RoleEnum.PARTNER_ADMIN,
                    RoleEnum.CUSTOMER_ADMIN,
                    RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                    RoleEnum.CC_OPS_ADMIN,
                    RoleEnum.CC_OPS,
                    RoleEnum.CC_SALES,
                  ]}
                >
                  <PlatesManagement />
                </RoleBasedRoute>
              }
            />

            <Route
              path={`${RouteEnum.RecentActivitiesPlatesManagementPauseAlerts}`}
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    RoleEnum.ROOT,
                    RoleEnum.PARTNER_ADMIN,
                    RoleEnum.CUSTOMER_ADMIN,
                    RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                    RoleEnum.CUSTOMER_OPS_TEAM,
                    RoleEnum.CUSTOMER_HSE,
                    RoleEnum.CUSTOMER_SECURITY,
                  ]}
                >
                  <PauseAlertsPage />
                </RoleBasedRoute>
              }
            />

            <Route
              path={RouteEnum.RecentActivitiesPlatesManagementCreatePlateNumber}
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    RoleEnum.ROOT,
                    RoleEnum.PARTNER_ADMIN,
                    RoleEnum.CUSTOMER_ADMIN,
                    RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                    RoleEnum.CUSTOMER_OPS_TEAM,
                    RoleEnum.CUSTOMER_HSE,
                    RoleEnum.CUSTOMER_SECURITY,
                    RoleEnum.CC_PROCTORS,
                  ]}
                >
                  <CreatePlateNumberPage />
                </RoleBasedRoute>
              }
            />
            <Route
              path={RouteEnum.SystemSettingsPlatesManagementCreatePlateNumber}
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    RoleEnum.ROOT,

                    RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                    RoleEnum.CUSTOMER_OPS_TEAM,
                    RoleEnum.CUSTOMER_HSE,
                    RoleEnum.CUSTOMER_SECURITY,
                  ]}
                >
                  <CreatePlateNumberPageSystemSettings />
                </RoleBasedRoute>
              }
            />

            <Route
              path={RouteEnum.RecentActivitiesPlatesManagementEditPlateNumber}
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    RoleEnum.ROOT,
                    RoleEnum.PARTNER_ADMIN,
                    RoleEnum.CUSTOMER_ADMIN,

                    RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                    RoleEnum.CUSTOMER_OPS_TEAM,
                    RoleEnum.CUSTOMER_HSE,
                    RoleEnum.CUSTOMER_SECURITY,
                  ]}
                >
                  <EditPlateNumberPage />
                </RoleBasedRoute>
              }
            />

            <Route
              path={RouteEnum.RecentActivitiesPlatesManagementEditCategory}
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    RoleEnum.ROOT,
                    RoleEnum.PARTNER_ADMIN,
                    RoleEnum.CUSTOMER_ADMIN,
                    RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                    RoleEnum.CUSTOMER_OPS_TEAM,
                    RoleEnum.CUSTOMER_HSE,
                    RoleEnum.CUSTOMER_SECURITY,
                  ]}
                >
                  <EditCategoryPage />
                </RoleBasedRoute>
              }
            />
          </Route>

          <Route>
            <Route
              path="/sensory"
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    RoleEnum.ROOT,
                    RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                    RoleEnum.PARTNER_USER,
                    RoleEnum.CUSTOMER_OPS_TEAM,
                    RoleEnum.CUSTOMER_HSE,
                    RoleEnum.CUSTOMER_SECURITY,
                    RoleEnum.VERIFIER,
                  ]}
                >
                  <DataHubPage />
                </RoleBasedRoute>
              }
            >
              <Route
                path={RouteEnum.SensoryRecentActivities}
                index
                element={
                  <RoleBasedRoute
                    allowedRoles={[
                      RoleEnum.ROOT,
                      RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                      RoleEnum.PARTNER_USER,
                      RoleEnum.CUSTOMER_OPS_TEAM,
                      RoleEnum.CUSTOMER_HSE,
                      RoleEnum.CUSTOMER_SECURITY,
                      RoleEnum.VERIFIER,
                    ]}
                  >
                    <DataHubDashboard />
                  </RoleBasedRoute>
                }
              />
              <Route
                path={`${RouteEnum.SensoryRecentActivities}/:model`}
                element={
                  <RoleBasedRoute
                    allowedRoles={[
                      RoleEnum.ROOT,
                      RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                      RoleEnum.PARTNER_USER,
                      RoleEnum.CUSTOMER_OPS_TEAM,
                      RoleEnum.CUSTOMER_HSE,
                      RoleEnum.CUSTOMER_SECURITY,
                      RoleEnum.VERIFIER,
                    ]}
                  >
                    <DataHubPageContent />
                  </RoleBasedRoute>
                }
              />
              <Route
                path="/sensory/live-view"
                element={
                  <RoleBasedRoute
                    allowedRoles={[
                      RoleEnum.ROOT,
                      RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                      RoleEnum.CUSTOMER_OPS_TEAM,
                      RoleEnum.PARTNER_USER,
                      RoleEnum.CUSTOMER_HSE,
                      RoleEnum.CUSTOMER_SECURITY,
                    ]}
                  >
                    <DataHubLiveViewPageContent />
                  </RoleBasedRoute>
                }
              />
              <Route
                path="/sensory/live-view/zones-list"
                element={
                  <RoleBasedRoute
                    allowedRoles={[
                      RoleEnum.ROOT,
                      RoleEnum.CC_OPS_ADMIN,
                      RoleEnum.CC_OPS,
                      RoleEnum.CC_SALES,
                      RoleEnum.PARTNER_ADMIN,
                      RoleEnum.CUSTOMER_ADMIN,
                      RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                      RoleEnum.PARTNER_USER,
                      RoleEnum.CUSTOMER_OPS_TEAM,
                      RoleEnum.CUSTOMER_HSE,
                      RoleEnum.CUSTOMER_SECURITY,
                    ]}
                  >
                    <ZoneList />
                  </RoleBasedRoute>
                }
              />
              <Route
                path="/sensory/deployment-health"
                element={
                  <RoleBasedRoute
                    allowedRoles={[
                      RoleEnum.ROOT,
                      RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                      RoleEnum.PARTNER_USER,
                    ]}
                  >
                    <DeploymentHealthPage />
                  </RoleBasedRoute>
                }
              />
              <Route
                path="/sensory/statistics"
                element={
                  <RoleBasedRoute
                    allowedRoles={[
                      RoleEnum.ROOT,
                      RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                      RoleEnum.PARTNER_USER,
                    ]}
                  >
                    <StatisticsPage />
                  </RoleBasedRoute>
                }
              />
            </Route>

            <Route
              path="/sensory/system-settings"
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    RoleEnum.ROOT,
                    RoleEnum.CC_OPS_ADMIN,
                    RoleEnum.CC_OPS,
                    RoleEnum.CC_SALES,
                    RoleEnum.PARTNER_ADMIN,
                    RoleEnum.CUSTOMER_ADMIN,
                    RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                    RoleEnum.PARTNER_USER,
                    RoleEnum.CUSTOMER_OPS_TEAM,
                    RoleEnum.CUSTOMER_HSE,
                    RoleEnum.CUSTOMER_SECURITY,
                  ]}
                >
                  <SystemSettingsPage />
                </RoleBasedRoute>
              }
            >
              <Route path=":tabId" element={<SystemSettingsPage />} />
            </Route>

            <Route
              path={`${RouteEnum.SensoryEditLocation}:locationId`}
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    RoleEnum.ROOT,
                    RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                    RoleEnum.PARTNER_USER,
                    RoleEnum.CUSTOMER_OPS_TEAM,
                    RoleEnum.CUSTOMER_HSE,
                    RoleEnum.CUSTOMER_SECURITY,
                    RoleEnum.CC_PROCTORS,
                  ]}
                >
                  <EditLocationPage />
                </RoleBasedRoute>
              }
            />

            <Route
              path={RouteEnum.SensoryIntegrationsAzure}
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    RoleEnum.ROOT,
                    RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                    RoleEnum.PARTNER_USER,
                    RoleEnum.CUSTOMER_OPS_TEAM,
                    RoleEnum.CUSTOMER_HSE,
                    RoleEnum.CUSTOMER_SECURITY,
                    RoleEnum.CC_PROCTORS,
                  ]}
                >
                  <AzureIntegrationPage />
                </RoleBasedRoute>
              }
            />

            <Route
              path={RouteEnum.SensoryIntegrationsS3}
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    RoleEnum.ROOT,
                    RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                    RoleEnum.PARTNER_USER,
                    RoleEnum.CUSTOMER_OPS_TEAM,
                    RoleEnum.CUSTOMER_HSE,
                    RoleEnum.CUSTOMER_SECURITY,
                    RoleEnum.CC_PROCTORS,
                  ]}
                >
                  <S3IntegrationPage />
                </RoleBasedRoute>
              }
            />
            <Route
              path={RouteEnum.SensoryIntegrationsEdge}
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    RoleEnum.ROOT,
                    RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                    RoleEnum.PARTNER_USER,
                    RoleEnum.CUSTOMER_OPS_TEAM,
                    RoleEnum.CUSTOMER_HSE,
                    RoleEnum.CUSTOMER_SECURITY,
                    RoleEnum.CC_PROCTORS,
                  ]}
                >
                  <EdgeIntegrationPage />
                </RoleBasedRoute>
              }
            />

            <Route
              path={`${RouteEnum.SensoryRecentActivitiesPlatesManagement}`}
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    RoleEnum.ROOT,
                    RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                    RoleEnum.CUSTOMER_OPS_TEAM,
                    RoleEnum.CUSTOMER_HSE,
                    RoleEnum.CUSTOMER_SECURITY,
                  ]}
                >
                  <PlatesManagement />
                </RoleBasedRoute>
              }
            />

            <Route
              path={
                RouteEnum.SensoryRecentActivitiesPlatesManagementCreatePlateNumber
              }
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    RoleEnum.ROOT,
                    RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                    RoleEnum.CUSTOMER_OPS_TEAM,
                    RoleEnum.CUSTOMER_HSE,
                    RoleEnum.CUSTOMER_SECURITY,
                    RoleEnum.CC_PROCTORS,
                  ]}
                >
                  <CreatePlateNumberPage />
                </RoleBasedRoute>
              }
            />
            <Route
              path={
                RouteEnum.SensorySystemSettingsPlatesManagementCreatePlateNumber
              }
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    RoleEnum.ROOT,
                    RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                    RoleEnum.CUSTOMER_OPS_TEAM,
                    RoleEnum.CUSTOMER_HSE,
                    RoleEnum.CUSTOMER_SECURITY,
                  ]}
                >
                  <CreatePlateNumberPageSystemSettings />
                </RoleBasedRoute>
              }
            />

            <Route
              path={
                RouteEnum.SensoryRecentActivitiesPlatesManagementEditPlateNumber
              }
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    RoleEnum.ROOT,
                    RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                    RoleEnum.CUSTOMER_OPS_TEAM,
                    RoleEnum.CUSTOMER_HSE,
                    RoleEnum.CUSTOMER_SECURITY,
                  ]}
                >
                  <EditPlateNumberPage />
                </RoleBasedRoute>
              }
            />

            <Route
              path={
                RouteEnum.SensoryRecentActivitiesPlatesManagementEditCategory
              }
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    RoleEnum.ROOT,
                    RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                    RoleEnum.CUSTOMER_OPS_TEAM,
                    RoleEnum.CUSTOMER_HSE,
                    RoleEnum.CUSTOMER_SECURITY,
                  ]}
                >
                  <EditCategoryPage />
                </RoleBasedRoute>
              }
            />

            <Route
              path={`${RouteEnum.SensorySystemSettings}/${RouteEnum.CreateAlert}`}
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    RoleEnum.ROOT,
                    RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                    RoleEnum.PARTNER_USER,
                    RoleEnum.CUSTOMER_OPS_TEAM,
                    RoleEnum.CUSTOMER_HSE,
                    RoleEnum.CUSTOMER_SECURITY,
                  ]}
                >
                  <CreateAlertPage />
                </RoleBasedRoute>
              }
            />
          </Route>

          <Route>
            <Route
              path="/human-validator"
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    RoleEnum.ROOT,
                    RoleEnum.CC_OPS_ADMIN,
                    RoleEnum.CC_PROCTORS,
                  ]}
                >
                  <HumanValidatorPage />
                </RoleBasedRoute>
              }
            />
            <Route
              path="/hv-review"
              element={
                <RoleBasedRoute
                  allowedRoles={[RoleEnum.ROOT, RoleEnum.CC_OPS_ADMIN]}
                >
                  <HvReviewPage />
                </RoleBasedRoute>
              }
            />
          </Route>

          <Route>
            <Route
              path={RouteEnum.LeakFinderClientUploads}
              element={
                <LeakFinderRoleBasedRoute
                  allowedRoles={[
                    RoleEnum.ROOT,
                    RoleEnum.LEAKFINDER_DIRECTOR,
                    RoleEnum.CC_SALES,
                    RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                  ]}
                >
                  <ClientUploadsPage />
                </LeakFinderRoleBasedRoute>
              }
            />
            <Route
              path={RouteEnum.LeakFinderClientUploadsCreate}
              element={
                <LeakFinderRoleBasedRoute
                  allowedRoles={[
                    RoleEnum.ROOT,
                    RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                    RoleEnum.LEAKFINDER_DIRECTOR,
                    RoleEnum.CC_SALES,
                  ]}
                >
                  <AddNewClientPage />
                </LeakFinderRoleBasedRoute>
              }
            />
            <Route
              path={RouteEnum.LeakFinderClientUploadsEditClient}
              element={
                <LeakFinderRoleBasedRoute
                  allowedRoles={[
                    RoleEnum.ROOT,
                    RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                    RoleEnum.LEAKFINDER_DIRECTOR,
                    RoleEnum.CC_SALES,
                  ]}
                >
                  <EditClientPage />
                </LeakFinderRoleBasedRoute>
              }
            />
            <Route
              path={RouteEnum.LeakFinderUploadNewVideoGroupPage}
              element={
                <LeakFinderRoleBasedRoute
                  allowedRoles={[
                    RoleEnum.ROOT,
                    RoleEnum.LEAKFINDER_DIRECTOR,
                    RoleEnum.CC_SALES,
                    RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                  ]}
                >
                  <UploadVideoGroupPage />
                </LeakFinderRoleBasedRoute>
              }
            />

            <Route
              path={RouteEnum.LeakFinderClientUploadsEditGroup}
              element={
                <LeakFinderRoleBasedRoute
                  allowedRoles={[
                    RoleEnum.ROOT,
                    RoleEnum.LEAKFINDER_DIRECTOR,
                    RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                  ]}
                >
                  <EditGroupPage />
                </LeakFinderRoleBasedRoute>
              }
            />

            <Route
              path={RouteEnum.LeakFinderUserPreferences}
              element={
                <LeakFinderRoleBasedRoute
                  allowedRoles={[
                    RoleEnum.ROOT,
                    RoleEnum.LEAKFINDER_DIRECTOR,
                    RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                  ]}
                >
                  <LeakFinderUserPreferencesPage />
                </LeakFinderRoleBasedRoute>
              }
            />
          </Route>
        </Route>

        <Route
          path="*"
          element={<AnyRouteHandler userMainLink={userMainLink} />}
        />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
