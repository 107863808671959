import { gql } from "@apollo/client";

import { Category } from "../../API";
import client from "../../configs/apolloClient";

const useLicensePlateCategoryFromCache = () => {
  const getCachedCategory = (categoryId: string): Category | null => {
    const normalizedId =
      client.cache.identify({
        id: categoryId,
        __typename: "Category",
      }) ?? "";

    const category = client.cache.readFragment<Category>({
      id: normalizedId,
      fragment: gql`
        fragment CategoryFragment on Category {
          id
          categoryName
        }
      `,
    });

    return category;
  };

  return { getCachedCategory };
};

export default useLicensePlateCategoryFromCache;
