import { useTheme } from "@mui/material/styles";

const DeploymentHealthMenuIcon = (): JSX.Element => {
  const theme = useTheme();

  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.6665 15.9999C7.6665 12.8572 7.6665 11.2859 8.64281 10.3096C9.61913 9.33325 11.1905 9.33325 14.3332 9.33325H15.5832C18.7259 9.33325 20.2972 9.33325 21.2735 10.3096C22.2498 11.2859 22.2498 12.8572 22.2498 15.9999C22.2498 19.1426 22.2498 20.714 21.2735 21.6903C20.2972 22.6666 18.7259 22.6666 15.5832 22.6666H14.3332C11.1905 22.6666 9.61913 22.6666 8.64281 21.6903C7.6665 20.714 7.6665 19.1426 7.6665 15.9999Z"
        stroke={theme.palette.text.primary}
        strokeWidth="1.5"
      />
      <path
        d="M22.6665 14.3333C23.4522 14.3333 23.845 14.3333 24.0891 14.5773C24.3332 14.8214 24.3332 15.2142 24.3332 15.9999C24.3332 16.7856 24.3332 17.1784 24.0891 17.4225C23.845 17.6666 23.4522 17.6666 22.6665 17.6666V14.3333Z"
        stroke={theme.palette.text.primary}
        strokeWidth="1.5"
      />
      <path
        d="M15.5833 13.5L13.5 16H16.4167L14.3333 18.5"
        stroke={theme.palette.text.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DeploymentHealthMenuIcon;
