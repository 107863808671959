import { Box, Typography } from "@mui/material";

import AllLocationsEventsFilterDropdownContainer from "../../../../common/components/locations/recent-events/AllLocationsEventsFilterDropdownContainer";
import { AutocompleteOptionType } from "../../../../common/models/autocomplete";
import {
  selectedDataHubLocationTagVariable,
  selectedDataHubLocationVariable,
} from "../dataHub.variable";
import RecentEventsServerTableContainer from "../models/recentEvents/RecentEventsServerTableContainer";

const RecentGridItem = (): JSX.Element => {
  const handleLocationChange = (
    locationValue: AutocompleteOptionType
  ): void => {
    selectedDataHubLocationVariable(locationValue);
  };

  const handleTagChange = (
    tagLocations: AutocompleteOptionType[] | null
  ): void => {
    selectedDataHubLocationTagVariable(tagLocations);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "1em",
          padding: "1em",
        }}
      >
        <Typography variant="h3">Recent activities</Typography>
        <Box
          sx={{
            marginLeft: "auto",
            minWidth: "288px",
            display: "flex",
            alignItems: "center",
            gap: "1em",
          }}
        >
          <AllLocationsEventsFilterDropdownContainer
            enhancedCapabilities
            setLocation={handleLocationChange}
            setTagLocations={handleTagChange}
            ignoreDefaultValue
          />
        </Box>
      </Box>
      <RecentEventsServerTableContainer />
    </>
  );
};

export default RecentGridItem;
