import { useCallback } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { GridColDef } from "@mui/x-data-grid";

import S3Image from "../../../../../../common/components/media/S3Image";
import TableCellTruncated from "../../../../../../common/components/table/TableCellTruncated";
import useLocationFromCache from "../../../../../../common/hooks/useLocationFromCache";
import { formatTimestamp } from "../../../../../../common/utils/timestampUtils";

export const useGetRecentEventsTableColumns = () => {
  const { getCachedLocationName } = useLocationFromCache();

  const columns: GridColDef[] = [
    {
      field: "rowIndex",
      headerName: "#",
      width: 50,
      hideable: false,
      disableColumnMenu: true,
      sortable: false,
      type: "number",
      renderCell: (params): JSX.Element | null => {
        return <Typography variant="body2">{params.row.rowIndex}</Typography>;
      },
    },
    {
      field: "keyFrame",
      headerName: "Snapshot",
      flex: 1,
      minWidth: 320,
      width: 320,
      display: "flex",
      align: "center",
      hideable: false,
      disableColumnMenu: true,
      sortable: false,
      cellClassName: "keyFrameCell",
      renderCell: (params): JSX.Element | null => {
        const keyFrame = params?.row?.keyFrame;
        let parsedKeyFrame = keyFrame;

        try {
          parsedKeyFrame = JSON.parse(keyFrame);
        } catch (error) {
          console.log("keyframe does not need parsing");
        }

        const memoizedCallback = useCallback((): void => {
          params?.row?.onKeyFrameClick && params?.row?.onKeyFrameClick(params);
        }, [params?.row]);

        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "0.75em",
            }}
          >
            <S3Image
              sx={{
                borderRadius: "4px",
              }}
              s3Key={parsedKeyFrame}
              width={120}
              height={90}
              onClick={memoizedCallback}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "0.25em",
              }}
            >
              <Typography variant="buttonMedium" className="model">
                {params?.row?.model}
              </Typography>
              <Typography variant="caption">
                {formatTimestamp(params?.row.timestamp)}
              </Typography>
            </Box>
          </Box>
        );
      },
    },
    {
      field: "location",
      headerName: "Location",
      flex: 1,
      minWidth: 160,
      hideable: false,
      disableColumnMenu: true,
      sortable: false,
      valueGetter: (locationId?: string | null): string => {
        const locationName = getCachedLocationName(locationId);

        return locationName;
      },
      renderCell: (params): JSX.Element | null => {
        const locationName = getCachedLocationName(params?.row?.location);

        return (
          <TableCellTruncated
            tooltipText={locationName}
            text={locationName}
            TypographyProps={{
              variant: "body2",
            }}
          />
        );
      },
    },
    {
      field: "cameraId",
      headerName: "DEVICE ID",
      flex: 1,
      minWidth: 160,
      hideable: false,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params): JSX.Element | null =>
        params ? (
          <TableCellTruncated
            tooltipText={params?.row.cameraId}
            text={params?.row.cameraId}
            TypographyProps={{
              variant: "body2Bold",
            }}
          />
        ) : null,
    },
    {
      field: "timestamp",
      headerName: "Timestamp",
      flex: 1,
      minWidth: 180,
      hideable: false,
      disableColumnMenu: true,
      sortable: false,
      type: "dateTime",
      valueGetter: (timestamp: string): Date => {
        return new Date(formatTimestamp(timestamp));
      },
      renderCell: (params): JSX.Element | null => {
        const date = formatTimestamp(params?.row.timestamp);

        return <TableCellTruncated tooltipText={date} text={date} />;
      },
    },
    {
      field: "zoneName",
      headerName: "Zone",
      flex: 1,
      minWidth: 160,
      hideable: false,
      disableColumnMenu: true,
      sortable: false,
      valueGetter: (zoneName?: string | null): string => {
        return zoneName ?? "";
      },
      renderCell: (params): JSX.Element | null => {
        const zoneName = params.row.zoneName ?? "";

        return <TableCellTruncated tooltipText={zoneName} text={zoneName} />;
      },
    },
  ];

  return { columns };
};
