import { useMemo } from "react";

import dayjs from "dayjs";

import {
  ModelType,
  StatisticsDataInput,
  TimePeriod,
  eventType,
} from "../../../API";
import ApexOptions from "../../../common/components/apexchart/ApexChartModels";
import convertDatesHelper from "../helpers/convertDatesHelper";
import { IChartProps, IStatisticsResponseModel } from "../models/chart";
import { useGetStatisticsData } from "./useGetStatisticsData";
import { DEFAULT_STATISTICS_DATE_FORMAT } from "./useAllAlertsChart";
import { useTheme } from "@mui/material";

export const usePPEInfractionsChart = (
  timePeriod: TimePeriod,
  locations: string[] | null
): IChartProps => {
  const theme = useTheme();

  const filterParams = useMemo(
    (): Pick<
      StatisticsDataInput,
      "eventType" | "models" | "timePeriod" | "locations"
    > => ({
      eventType: eventType.PPEInfractions,
      models: [ModelType.hardhat],
      locations,
      timePeriod,
    }),
    [timePeriod, theme.palette.mode]
  );

  const { data, loading, refetching, refetchChartData } =
    useGetStatisticsData<IStatisticsResponseModel[]>(filterParams);

  const convertDates = convertDatesHelper(data, timePeriod);
  const convertValues = data.map((element): number | null =>
    element.record_count ? +Number(element.record_count).toFixed(3) : null
  );

  const options: ApexOptions = useMemo(
    (): ApexOptions => ({
      chart: {
        type: "line",
        stacked: false,
        width: "100%",
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        fontFamily: "Inter",
      },
      theme: {
        mode: theme.palette.mode,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },
      markers:
        convertValues.length === 1
          ? {
              size: 4,
              colors: ["#F3B344"],
            }
          : {},
      legend: {
        position: "top",
        horizontalAlign: "left",
      },
      colors: ["#F3B344"],
      yaxis: {
        opposite: true,
      },
      xaxis: {
        tooltip: { enabled: false },
        categories: convertDates,
      },
      tooltip: {
        followCursor: true,
        custom: ({ series, seriesIndex, dataPointIndex, w }) => {
          const hoverXaxis = w.globals.seriesX[seriesIndex][dataPointIndex];
          const hoverIndexes = w.globals.seriesX.map((seriesX: any[]) => {
            return seriesX.findIndex((xData: any) => xData === hoverXaxis);
          });

          let hoverList = "";

          hoverIndexes.forEach(
            (hoverIndex: number, seriesEachIndex: string | number) => {
              if (hoverIndex >= 0) {
                hoverList += `
                  <div class="apexcharts-tooltip-series-group apexcharts-active" style="display: flex;">
                    <span class="apexcharts-tooltip-marker" style="background-color: ${
                      w.globals.markers.colors[seriesEachIndex]
                    };"></span>
                    <div class="apexcharts-tooltip-text">
                      <div class="apexcharts-tooltip-y-group">
                        <span class="apexcharts-tooltip-text-y-label">Total attendances: </span>
                        <span class="apexcharts-tooltip-text-y-value">${
                          w.globals.yLabelFormatters[0](
                            series[seriesEachIndex][hoverIndex]
                          ) ?? 0
                        }</span>
                      </div>
                    </div>
                  </div>
                `;
              }
            }
          );

          const date1 = dayjs(new Date(hoverXaxis)).format(
            DEFAULT_STATISTICS_DATE_FORMAT
          );

          const date2 = dayjs(
            new Date(w.globals.labels[dataPointIndex])
          ).format(DEFAULT_STATISTICS_DATE_FORMAT);

          const date3 = w.globals.categoryLabels[dataPointIndex];

          let date;

          if (!date1.includes("70")) {
            date = date1;
          }

          if (!date && !date2.includes("70")) {
            date = date2;
          }

          if (!date && !date3.includes("70")) {
            date = date3;
          }

          if (
            dayjs(new Date()).format(DEFAULT_STATISTICS_DATE_FORMAT) === date
          ) {
            date = "Today";
          }

          return `
            ${hoverList}
            <hr>
            <div class="tooltip-date">${date}</div>
          `;
        },
      },
    }),
    [data, theme.palette.mode]
  );

  const series: ApexAxisChartSeries = useMemo(
    (): ApexAxisChartSeries => [
      {
        name: "Gasleak",
        data: convertValues,
      },
    ],
    [convertValues]
  );

  return {
    series,
    options,
    loading,
    refetching,
    refetchChartData,
    showNoData:
      !loading && !refetching && data?.filter(d => d.record_count).length === 0,
  };
};

export default usePPEInfractionsChart;
