import { ApolloError, useMutation } from "@apollo/client";

import {
  UpdateCustomerInput,
  UpdateCustomerMutation,
  UpdateCustomerMutationVariables,
} from "../../API";
import { UPDATE_CUSTOMER } from "../../common/operations/mutations";

export const useUpdateCustomer = () => {
  const [updateCustomerMutation, { data, loading }] = useMutation<
    UpdateCustomerMutation,
    UpdateCustomerMutationVariables
  >(UPDATE_CUSTOMER);

  const updateCustomer = async (input: UpdateCustomerInput) => {
    return await updateCustomerMutation({
      variables: {
        input,
      },
      onCompleted: response => {
        if (response.updateCustomer) {
          console.log("completed update customer");
        }
      },
      onError: (error: ApolloError): void => {
        console.error(error);
      },
    });
  };

  return { updateCustomer, data, loading };
};
