import Box from "@mui/material/Box";

interface IStatusCircleProps {
  color: string;
  width?: string;
  height?: string;
}

const StatusCircle = ({
  color,
  width = "10px",
  height = "10px",
}: IStatusCircleProps): JSX.Element => {
  return (
    <Box
      sx={{
        width,
        height,
        borderRadius: "50%",
        backgroundColor: color,
      }}
    />
  );
};

export default StatusCircle;
