import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Box, IconButton, Typography } from "@mui/material";
import Chart from "react-apexcharts";

import ReactDatePicker from "../../../common/components/datePicker/ReactDatePicker";
import CircularLoading from "../../../common/components/progress/CircularLoading";
import StatisticsNoDataView from "./StatisticsNoDataView";

type PolarAreaChartProps = {
  locationText: string;
  loading: boolean;
  totalEmissionsCount: number;
  showNoData: boolean;
  options: ApexCharts.ApexOptions;
  series: ApexAxisChartSeries | ApexNonAxisChartSeries | undefined;
  setStepsHandler: (step: number) => void;
};

const PolarAreaChart: React.FC<PolarAreaChartProps> = ({
  locationText,
  loading,
  showNoData,
  totalEmissionsCount,
  options,
  series,
  setStepsHandler,
}): JSX.Element => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <IconButton
            onClick={(): void => setStepsHandler(1)}
            sx={{
              cursor: "pointer",
            }}
          >
            <ChevronLeftIcon />
          </IconButton>

          <Typography variant="h5">{locationText}</Typography>
        </Box>

        <Box sx={{ minWidth: "240px" }}>
          <ReactDatePicker placeholderText="All time" />
        </Box>
      </Box>

      {!loading && !showNoData && (
        <Box sx={{ marginTop: "20px" }}>
          <Box
            sx={{
              display: "flex",
              gap: "1em",
              width: "100%",
              justifyContent: "flex-end",
              paddingRight: "24px",
            }}
          >
            <Typography variant="body2Regular">Total emissions</Typography>

            <Typography variant="body2Bold">{` ${totalEmissionsCount}`}</Typography>
          </Box>

          <Chart
            options={options}
            series={series}
            type={"polarArea"}
            height={300}
          />
        </Box>
      )}

      {loading && (
        <Box sx={{ height: "300px" }}>
          <CircularLoading />
        </Box>
      )}

      {showNoData && <StatisticsNoDataView />}
    </>
  );
};

export default PolarAreaChart;
