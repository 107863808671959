import EventsTableServer from "../common/EventsTableServer";
import { useGetFireEventsTableColumns } from "./hooks/useGetFireEventsTableColumns";
import { useGetFireEventsTableRows } from "./hooks/useGetFireEventsTableRows";

const FireEventsServetTableContainer = (): JSX.Element => {
  const { columns } = useGetFireEventsTableColumns();

  const {
    rows,
    loading,
    handlePaginationModelChange,
    hasNextPage,
    isActivePageChange,
    paginationModel,
  } = useGetFireEventsTableRows();

  return (
    <EventsTableServer
      columns={columns}
      rows={rows}
      loading={loading}
      onPaginationModelChange={handlePaginationModelChange}
      hasNextPage={hasNextPage}
      isActivePageChange={isActivePageChange}
      paginationModel={paginationModel}
    />
  );
};

export default FireEventsServetTableContainer;
