import { useTheme } from "@mui/material";

const LiquidLeakDataHubIcon = (): JSX.Element => {
  const theme = useTheme();

  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="40"
        height="40"
        rx="8"
        fill={theme.palette.otherBackground.main}
      />
      <path
        d="M18 25.8333C18 28.1345 16.2091 30 14 30C11.7909 30 10 28.1345 10 25.8333C10 24.3935 11.5659 22.4718 12.7382 21.2349C13.4322 20.5026 14.5678 20.5026 15.2618 21.2349C16.4341 22.4718 18 24.3935 18 25.8333Z"
        stroke={theme.palette.text.primary}
        strokeWidth="1.5"
      />
      <path
        d="M30 25.8333C30 28.1345 28.2091 30 26 30C23.7909 30 22 28.1345 22 25.8333C22 24.3935 23.5659 22.4718 24.7382 21.2349C25.4322 20.5026 26.5678 20.5026 27.2618 21.2349C28.4341 22.4718 30 24.3935 30 25.8333Z"
        stroke={theme.palette.text.primary}
        strokeWidth="1.5"
      />
      <path
        d="M24 15.8333C24 18.1345 22.2091 20 20 20C17.7909 20 16 18.1345 16 15.8333C16 14.3935 17.5659 12.4718 18.7382 11.2349C19.4322 10.5026 20.5678 10.5026 21.2618 11.2349C22.4341 12.4718 24 14.3935 24 15.8333Z"
        stroke={theme.palette.text.primary}
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default LiquidLeakDataHubIcon;
