import React from "react";

import { Box, ListItemOwnProps, ListItemText } from "@mui/material";
import {
  ListItemButtonStyled,
  ListItemIconStyled,
  ListItemStyled,
} from "./LeftSideBar/LeftSideBarBase";

export const TOP_BAR_HEIGHT = 64;

interface MenuItemProps {
  onClick: () => void;
  label: string;
  icon: JSX.Element;
  iconFilled: JSX.Element;
  selected: boolean;
  open: boolean;
  listItemStyledProps?: ListItemOwnProps;
}

const MenuItem: React.FC<MenuItemProps> = ({
  onClick,
  label,
  icon,
  iconFilled,
  selected,
  open,
  listItemStyledProps,
}) => {
  return (
    <ListItemStyled disablePadding onClick={onClick} {...listItemStyledProps}>
      <ListItemButtonStyled selected={selected} open={open}>
        <ListItemIconStyled>
          <Box className="defaultMenuIcon" sx={{ display: "inline-flex" }}>
            {icon}
          </Box>

          <Box className="filledMenuIcon" sx={{ display: "none" }}>
            {iconFilled}
          </Box>
        </ListItemIconStyled>

        <ListItemText
          primary={label}
          primaryTypographyProps={{ variant: "body2Regular" }}
          sx={{ opacity: open ? 1 : 0 }}
        />
      </ListItemButtonStyled>
    </ListItemStyled>
  );
};

export default MenuItem;
