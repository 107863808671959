function convertToUTC(localTimeStr: string) {
  const [hours, minutes] = localTimeStr.split(":").map(Number);

  const now = new Date();

  const localDate = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    hours,
    minutes
  );

  const utcHours = localDate.getUTCHours().toString().padStart(2, "0");
  const utcMinutes = localDate.getUTCMinutes().toString().padStart(2, "0");

  return `${utcHours}:${utcMinutes}`;
}

export { convertToUTC };
