import { useSubscription } from "@apollo/client";

import type { ListenToServiceHealthDataSubscription } from "../../../API";
import { LISTEN_TO_SERVICE_HEALTH_DATA } from "../../../common/operations/subscriptions";
import { useCustomerIdGuard } from "../../../common/hooks/useCustomerIdGuard";
import { isNetworkHealthOnline } from "../../../common/helpers/isNetworkHealthOnline";

export const useListenToServiceHealthDataChange = () => {
  const customerId = useCustomerIdGuard();

  const { data, loading, error } =
    useSubscription<ListenToServiceHealthDataSubscription>(
      LISTEN_TO_SERVICE_HEALTH_DATA,
      {
        fetchPolicy: "no-cache",
        variables: {
          customerId,
        },
      }
    );

  let isOnline = false;

  try {
    if (data?.listenToServiceHealthData?.healthData) {
      const parsedHealthData = JSON.parse(
        data.listenToServiceHealthData.healthData
      );

      isOnline = isNetworkHealthOnline(parsedHealthData.lastAlive);
    }
  } catch (err) {
    console.error("Failed to parse service health data JSON:", err);
  }

  return { data, loading, error, isOnline };
};
