import { ApolloError, gql, useMutation } from "@apollo/client";

import {
  UpdateCategory,
  UpdateCategoryMutation,
  UpdateCategoryMutationVariables,
} from "../../../../../API";
import { UPDATE_CATEGORY } from "../../../../../common/operations/mutations";
import {
  errorNotification,
  successNotification,
} from "../../../../../common/variables/notification";

export const useUpdateCategory = () => {
  const [updateCategoryMutation, { data, loading }] = useMutation<
    UpdateCategoryMutation,
    UpdateCategoryMutationVariables
  >(UPDATE_CATEGORY);

  const updateCategory = (input: UpdateCategory) => {
    return updateCategoryMutation({
      variables: {
        input,
      },
      onCompleted: response => {
        if (response.updateCategory) {
          successNotification();
        }
      },
      onError: (error: ApolloError): void => {
        errorNotification(error.message);

        console.error(error.message);
      },
      update: (cache, response): void => {
        cache.modify({
          fields: {
            getCategoriesByCustomer(existing) {
              const newCategoryRef = cache.writeFragment({
                data: response.data?.updateCategory,
                fragment: gql`
                  fragment NewCategory on Category {
                    id
                    categoryName
                    isAlertEnabled
                  }
                `,
              });

              if (!existing) {
                return response?.data;
              }

              return {
                ...existing,
                items: [...existing.items, newCategoryRef],
              };
            },
          },
        });
      },
    });
  };

  return { updateCategory, data, loading };
};
