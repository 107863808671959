import { useEffect } from "react";

import { useLazyQuery } from "@apollo/client";

import {
  GetDevicesMakesQuery,
  GetDevicesMakesQueryVariables,
} from "../../../../../API";
import { GET_DEVICES_MAKES } from "../../../../../common/operations/queries";
import { errorNotification } from "../../../../../common/variables/notification";
import client from "../../../../../configs/apolloClient";
import { DEFAULT_LIMIT_COUNT } from "../../../../../common/variables/common";

export const DEVICES_MAKES_TABLE_LIMIT = 10;

interface IUseGetLazyPaginatedDevicesMakes {
  data?: GetDevicesMakesQuery;
  loading: boolean;
}

export const useGetLazyPaginatedDevicesMakes =
  (): IUseGetLazyPaginatedDevicesMakes => {
    useEffect((): void => {
      fetchDevicesMakes(DEFAULT_LIMIT_COUNT);
    }, []);

    const [getDevicesMakes, { data, loading }] = useLazyQuery<
      GetDevicesMakesQuery,
      GetDevicesMakesQueryVariables
    >(GET_DEVICES_MAKES, {
      fetchPolicy: "network-only",
    });

    const handleRequestError = async (): Promise<void> => {
      await client.cache.reset();

      errorNotification();
    };

    const fetchDevicesMakes = (limit: number, nextToken?: string): void => {
      getDevicesMakes({
        variables: {
          limit,
          nextToken,
        },
      })
        .then(async (response): Promise<void> => {
          if (response.data?.getDevicesMakes.nextToken) {
            fetchDevicesMakes(
              DEFAULT_LIMIT_COUNT,
              response.data?.getDevicesMakes.nextToken
            );
          }

          if (response.error) {
            await handleRequestError();
          }
        })
        .catch(async (): Promise<void> => {
          await handleRequestError();
        });
    };

    return { data, loading };
  };
