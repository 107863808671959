import { Breadcrumbs, Typography } from "@mui/material";

import RouterLink from "../../../../common/components/RouterLink";
import { useCreateNodeActiveStepIndex } from "../../variables/createNode.variable";

const CreateNodeBreadcrumb = (): JSX.Element => {
  const activeStep = useCreateNodeActiveStepIndex();

  return (
    <Breadcrumbs
      sx={{
        marginBottom: "1.5em",
      }}
      aria-label="breadcrumb"
    >
      <RouterLink to="/nodes">
        <Typography color="otherTextTertiary.main">Nodes</Typography>
      </RouterLink>
      <Typography
        color={activeStep === 0 ? "text.primary" : "otherTextTertiary.main"}
      >
        Add new node
      </Typography>
    </Breadcrumbs>
  );
};

export default CreateNodeBreadcrumb;
