import { useTheme } from "@mui/material";

const FireDataHubIcon = (): JSX.Element => {
  const theme = useTheme();

  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="40"
        height="40"
        rx="8"
        fill={theme.palette.otherBackground.main}
      />
      <path
        d="M28 21.1111C28 28.2222 21.9556 30 18.9333 30C16.2889 30 11 28.2222 11 21.1111C11 18.3295 12.4615 16.4614 13.86 15.3945C14.6384 14.8007 15.6304 15.392 15.7302 16.366L15.816 17.2038C15.9205 18.2241 16.8493 19.0606 17.7093 18.5017C19.3938 17.4071 20 14.7752 20 13.3333V13.0097C20 11.58 21.4438 10.6598 22.6023 11.4977C25.1653 13.3513 28 16.5843 28 21.1111Z"
        stroke={theme.palette.text.primary}
        strokeWidth="1.5"
      />
      <path
        d="M16 26.4445C16 29.2889 18.4889 30 19.7333 30C20.8222 30 23 29.2889 23 26.4445C23 25.3435 22.4107 24.6002 21.8404 24.1713C21.4424 23.872 20.8828 24.1408 20.7459 24.6196C20.5675 25.2437 19.9228 25.636 19.5944 25.0759C19.2941 24.5638 19.2941 23.7957 19.2941 23.3334C19.2941 22.6968 18.6539 22.2847 18.1389 22.6589C17.1065 23.4091 16 24.6815 16 26.4445Z"
        stroke={theme.palette.text.primary}
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default FireDataHubIcon;
