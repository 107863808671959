import { useTheme } from "@mui/material/styles";

const EditIconButtonNoBorder = ({
  onClick,
  disabled,
}: {
  onClick?: () => void;
  disabled?: boolean;
}): JSX.Element => {
  const theme = useTheme();

  const handleClick = (event: React.MouseEvent<SVGSVGElement>) => {
    if (!disabled && onClick) {
      onClick();
    }
  };

  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="editIcon"
      style={{
        cursor: "pointer",
        opacity: disabled ? 0.5 : 1,
      }}
      onClick={handleClick}
    >
      <path
        d="M17.9666 9.39896L18.739 8.62656C20.0187 7.34681 22.0936 7.34681 23.3734 8.62656C24.6531 9.90632 24.6531 11.9812 23.3734 13.261L22.601 14.0334M17.9666 9.39896C17.9666 9.39896 18.0631 11.0403 19.5114 12.4886C20.9596 13.9368 22.601 14.0334 22.601 14.0334M17.9666 9.39896L10.8655 16.5C10.3846 16.981 10.1441 17.2215 9.93725 17.4866C9.69328 17.7994 9.48412 18.1378 9.31346 18.4959C9.16878 18.7995 9.06123 19.1221 8.84614 19.7674L7.93468 22.5018M22.601 14.0334L15.4999 21.1344C15.019 21.6154 14.7785 21.8558 14.5133 22.0627C14.2005 22.3066 13.8621 22.5158 13.504 22.6865C13.2004 22.8311 12.8778 22.9387 12.2325 23.1538L9.4981 24.0652M9.4981 24.0652L8.8297 24.288C8.51215 24.3939 8.16205 24.3112 7.92536 24.0746C7.68867 23.8379 7.60603 23.4878 7.71188 23.1702L7.93468 22.5018M9.4981 24.0652L7.93468 22.5018"
        stroke={theme.palette.primary.main}
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default EditIconButtonNoBorder;
