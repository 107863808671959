import { useMemo } from "react";

import {
  type ApolloQueryResult,
  type FetchMoreQueryOptions,
  type NetworkStatus,
  type OperationVariables,
  useQuery,
} from "@apollo/client";

import type {
  GetEventsPendingHumanValidationQuery,
  GetEventsPendingHumanValidationQueryVariables,
  HumanValidationEvent,
} from "../../../API";
import { GET_EVENTS_PENDING_HV } from "../../../common/operations/queries";
import { errorNotification } from "../../../common/variables/notification";
import client from "../../../configs/apolloClient";

interface GetHumanValidatorTableInterface {
  pendingEvents: HumanValidationEvent[];
  nextToken?: string | null;
  loading: boolean;
  networkStatus: NetworkStatus;
  fetchMore: (
    fetchMoreOptions: FetchMoreQueryOptions<
      GetEventsPendingHumanValidationQueryVariables,
      GetEventsPendingHumanValidationQuery
    > & {
      updateQuery?: (
        previousQueryResult: GetEventsPendingHumanValidationQuery,
        options: {
          fetchMoreResult: GetEventsPendingHumanValidationQuery;
          variables: OperationVariables;
        }
      ) => GetEventsPendingHumanValidationQuery;
    }
  ) => Promise<ApolloQueryResult<GetEventsPendingHumanValidationQuery>>;
}

export const useGetHumanValidatorTable =
  (): GetHumanValidatorTableInterface => {
    const handleRequestError = (): void => {
      client.cache
        .reset()
        .then((): void => {
          errorNotification();
        })
        .catch((error): void => {
          console.error(error);
        });
    };

    const { data, loading, fetchMore, networkStatus } = useQuery<
      GetEventsPendingHumanValidationQuery,
      GetEventsPendingHumanValidationQueryVariables
    >(GET_EVENTS_PENDING_HV, {
      notifyOnNetworkStatusChange: true,
      onError: handleRequestError,
    });

    const pendingEvents: HumanValidationEvent[] = useMemo(
      (): HumanValidationEvent[] =>
        (data?.getEventsPendingHumanValidation
          .items as HumanValidationEvent[]) || [],
      [data?.getEventsPendingHumanValidation.items]
    );

    const nextToken: string | null | undefined = useMemo(
      (): string | null | undefined =>
        data?.getEventsPendingHumanValidation.nextToken,
      [data?.getEventsPendingHumanValidation.items]
    );

    return { pendingEvents, nextToken, loading, networkStatus, fetchMore };
  };
