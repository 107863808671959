import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import "./styles/main.css";

import ItemDetailHeader from "../../common/components/item/ItemDetailHeader";
import AllAlertsContainer from "./components/AllAlertsContainer";
import GasLeakChartContainer from "./components/GasLeakContainer";
import MostActiveLocationsContainer from "./components/MostActiveLocationsContainer";
import PPEInfractionsContainer from "./components/PPEInfractionsContainer";
import VisitorsContainer from "./components/VisitorsContainer";

const StatisticsPage = (): JSX.Element => {
  return (
    <Box
      sx={{
        gap: "1.5em 1.5em",
        display: "grid",
      }}
    >
      <ItemDetailHeader name="Statistics" />

      <Grid container alignItems="center" justifyContent="center" spacing={3}>
        <Grid item xs={12} sm={12}>
          <AllAlertsContainer />
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <GasLeakChartContainer />
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <PPEInfractionsContainer />
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <VisitorsContainer />
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <MostActiveLocationsContainer />
        </Grid>
      </Grid>
    </Box>
  );
};

export default StatisticsPage;
