import { useMemo } from "react";

import moment from "moment";

import { GridRowsProp } from "@mui/x-data-grid";
import { EVENTS_DATE_FORMAT } from "../../common/events.constants";
import {
  ILiquidLeakTableRow,
  IParsedLiquidLeakEvent,
} from "../../common/events.models";
import { useGetTimestreamDataServer } from "../../common/hooks/useGetTimestreamDataServer";
import { getRowIndex } from "../../common/events.helpers";

export const useGetLiquidLeakEventsTableRows = () => {
  const {
    parsedResult,
    loading,
    paginationModel,
    handlePaginationModelChange,
    hasNextPage,
    isActivePageChange,
  } = useGetTimestreamDataServer();

  const rows = useMemo((): GridRowsProp<ILiquidLeakTableRow> => {
    const mappedData: ILiquidLeakTableRow[] =
      parsedResult?.map((item: IParsedLiquidLeakEvent, index: number) => ({
        rowIndex: getRowIndex(paginationModel, index),
        rowId: item?.id ?? "",
        location: item?.location_id,
        zone: item?.zone_id,
        timestamp: moment.utc(item?.time).local().format(EVENTS_DATE_FORMAT),
        leaks: item?.leaks,
        volume: item?.leak_volume,
        mediaOutput: item?.mediaOutput,
        keyFrame: item?.keyFrame,
        cameraId: item?.camera_id,
      })) ?? [];

    return mappedData;
  }, [parsedResult, paginationModel]);

  return {
    rows,
    loading,
    paginationModel,
    handlePaginationModelChange,
    hasNextPage,
    isActivePageChange,
  };
};
