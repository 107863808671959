import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Box, Tab, Tabs } from "@mui/material";

import TabPanel from "../../common/components/tabs/TabPanel";
import { useAuthenticatedUser } from "../../common/hooks/useAuthenticatedUser";
import { RouteEnum } from "../../common/models/enums";
import {
  CustomerSettingsTabsEnum,
  CustomerSettingsTabsList,
} from "../../common/variables/tabsSettings";
import AlertManagementTab from "./tabs/alert-management/AlertManagementTab";
import LocationsPage from "./tabs/locations/LocationsPage";

const CustomerSettingsTabs = (): JSX.Element => {
  const { role } = useAuthenticatedUser();
  const { tabId = CustomerSettingsTabsEnum.MANAGE_LOCATIONS } = useParams<{
    tabId: CustomerSettingsTabsEnum;
  }>();
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] =
    useState<CustomerSettingsTabsEnum>(tabId);

  useEffect((): void => {
    if (role !== "") {
      const allowedTabs = CustomerSettingsTabsList.filter((tab): boolean => {
        return tab.allowedRoles.includes(role);
      });

      const isURLTabAllowed = allowedTabs.find(tab => tab.id === selectedTab);

      setSelectedTab(isURLTabAllowed?.id ?? allowedTabs[0].id);
    }
  }, [role]);

  const handleChange = (
    _event: React.SyntheticEvent,
    newValue: CustomerSettingsTabsEnum
  ): void => {
    navigate(newValue);

    setSelectedTab(newValue);
  };

  const allowedTabs = CustomerSettingsTabsList.filter((tab): boolean => {
    return tab.allowedRoles.includes(role);
  });

  const tabs = allowedTabs.map((tab): JSX.Element => {
    return (
      <Tab
        key={tab.id}
        sx={{
          textTransform: "none",
        }}
        label={tab.label}
        value={tab.id}
      />
    );
  });

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{ borderBottom: 1, borderColor: "divider", marginBottom: "1em" }}
      >
        <Tabs
          onChange={handleChange}
          value={selectedTab}
          variant="scrollable"
          scrollButtons="auto"
        >
          {tabs}
        </Tabs>
      </Box>

      <TabPanel value={selectedTab} index={RouteEnum.ManageLocations}>
        <LocationsPage />
      </TabPanel>

      <TabPanel
        value={selectedTab}
        index={RouteEnum.AlertManagement}
        sx={{ padding: 0 }}
      >
        <AlertManagementTab />
      </TabPanel>
    </Box>
  );
};

export default CustomerSettingsTabs;
