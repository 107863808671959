import {
  type ApolloError,
  type FetchResult,
  type MutationFunctionOptions,
  useMutation,
} from "@apollo/client";

import type {
  TakeEventToValidateMutation,
  TakeEventToValidateMutationVariables,
} from "../../../API";
import { TAKE_EVENT_TO_VALIDATE } from "../../../common/operations/mutations";

interface TakeToValidateInterface {
  data?: TakeEventToValidateMutation | null;
  loading: boolean;
  error?: ApolloError;
  takeToValidate: (
    options?: MutationFunctionOptions<
      TakeEventToValidateMutation,
      TakeEventToValidateMutationVariables
    >
  ) => Promise<FetchResult<TakeEventToValidateMutation>>;
}

export const useTakeToValidate = (): TakeToValidateInterface => {
  const [takeToValidate, { data, loading, error }] = useMutation<
    TakeEventToValidateMutation,
    TakeEventToValidateMutationVariables
  >(TAKE_EVENT_TO_VALIDATE);

  return { data, loading, error, takeToValidate };
};
