import { useMemo } from "react";

import BreadcrumbNavigation from "../../../../../../../common/components/tabs/BreadcrumbNavigation";
import { useAuthenticatedUser } from "../../../../../../../common/hooks/useAuthenticatedUser";
import { RouteEnum } from "../../../../../../../common/models/enums";
import { ALERTS_ADMIN_ACCESS } from "../../../AlertManagementTab";

const AlertPageBreadcrumb = () => {
  const { role } = useAuthenticatedUser();

  const isAdminUser = ALERTS_ADMIN_ACCESS.includes(role);

  const breadcrumbItems = useMemo(
    () => [
      {
        label: isAdminUser ? "Customer settings" : "System settings",
        path: isAdminUser
          ? `${RouteEnum.CustomerSettings}/${RouteEnum.AlertManagement}`
          : `${RouteEnum.SensorySystemSettings}/${RouteEnum.AlertManagement}`,
      },
      {
        label: "Add new alert",
      },
    ],
    []
  );

  return <BreadcrumbNavigation items={breadcrumbItems} />;
};

export default AlertPageBreadcrumb;
