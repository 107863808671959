import { useAuthenticatedUser } from "../../../../../common/hooks/useAuthenticatedUser";
import { RouteEnum } from "../../../../../common/models/enums";
import { ALERTS_ADMIN_ACCESS } from "../AlertManagementTab";

export const useGetAddNewAlertPath = () => {
  const { role } = useAuthenticatedUser();

  const isAdminUser = ALERTS_ADMIN_ACCESS.includes(role);

  return isAdminUser
    ? `${RouteEnum.CustomerSettings}/${RouteEnum.CreateAlert}`
    : `${RouteEnum.SensorySystemSettings}/${RouteEnum.CreateAlert}`;
};
