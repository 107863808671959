import { ApolloError, gql, useMutation } from "@apollo/client";

import {
  UpdateZoneThresholdItemMutation,
  UpdateZoneThresholdItemMutationVariables,
} from "../../../../../../../API";
import { UPDATE_ZONE_THRESHOLD_ITEM } from "../../../../../../../common/operations/mutations";
import {
  errorNotification,
  successNotification,
} from "../../../../../../../common/variables/notification";

export const useUpdateZoneThresholdItem = () => {
  const [updateZoneThresholdItemMutation, { data, loading }] = useMutation<
    UpdateZoneThresholdItemMutation,
    UpdateZoneThresholdItemMutationVariables
  >(UPDATE_ZONE_THRESHOLD_ITEM);

  const updateZoneThresholdItem = (name: string, value: number, id: string) => {
    return updateZoneThresholdItemMutation({
      variables: {
        input: {
          name,
          value,
          id,
        },
      },
      onCompleted: response => {
        if (response.updateZoneThresholdItem) {
          successNotification("Changes are saved");
        }
      },
      onError: (error: ApolloError): void => {
        errorNotification("Something went wrong, changes are not saved");

        console.error(error.message);
      },
      update: (cache, response): void => {
        cache.modify({
          fields: {
            getZoneThresholdByCustomer(existing) {
              const newZoneThresholdRef = cache.writeFragment({
                data: response.data?.updateZoneThresholdItem,
                fragment: gql`
                  fragment NewZoneThreshold on ZoneThreshold {
                    id
                    name
                    value
                  }
                `,
              });

              if (!existing) {
                return response?.data;
              }

              return {
                ...existing,
                items: [...existing.items, newZoneThresholdRef],
              };
            },
          },
        });
      },
    });
  };

  return { updateZoneThresholdItem, data, loading };
};
