import { ApolloError, useMutation } from "@apollo/client";

import {
  DeleteLicensePlateMutation,
  DeleteLicensePlateMutationVariables,
} from "../../../../../API";
import { DELETE_LICENSE_PLATE } from "../../../../../common/operations/mutations";
import {
  errorNotification,
  successNotification,
} from "../../../../../common/variables/notification";

export const useDeleteLicensePlate = () => {
  const [deleteLicensePlateMutation, { data, loading }] = useMutation<
    DeleteLicensePlateMutation,
    DeleteLicensePlateMutationVariables
  >(DELETE_LICENSE_PLATE);

  const deleteLicensePlate = async (
    categoryId: string,
    licensePlateId: string
  ) => {
    return await deleteLicensePlateMutation({
      variables: {
        input: {
          categoryId: categoryId,
          licensePlateId: licensePlateId,
        },
      },
      onCompleted: response => {
        if (response.deleteLicensePlate) {
          const lp = response.deleteLicensePlate?.licensePlate;

          successNotification("Plate " + lp + " is deleted");
        }
      },
      update(cache) {
        const normalizedId = cache.identify({
          id: licensePlateId,
          __typename: "LicensePlate",
        });

        cache.evict({ id: normalizedId });

        cache.gc();
      },
      onError: (error: ApolloError): void => {
        errorNotification(error.message);
      },
    });
  };

  return { deleteLicensePlate, data, loading };
};
