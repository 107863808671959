export enum EVENT_VALIDATE_STATUS_ENUM {
  REAL = "REAL",
  NOT_REAL = "NOT_REAL",
}

export enum VALIDATION_SETTINGS_ENUM {
  ALERT = "ALERT",
  NO_ALERT = "NO_ALERT",
}

export type EVENT_VALIDATE_STATUS =
  | EVENT_VALIDATE_STATUS_ENUM.REAL
  | EVENT_VALIDATE_STATUS_ENUM.REAL
  | null;
