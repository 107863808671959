import { useState } from "react";

import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  Box,
  ButtonGroup,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  GridColDef,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
} from "@mui/x-data-grid";

import InfoIcon from "../../../../../../../common/components/icons/InfoIcon";
import S3Image from "../../../../../../../common/components/media/S3Image";
import ZoneStatusToggle from "../../../../../../../common/components/zones/ZoneStatusToggle";
import { parseEventMediaPath } from "../../../../../../../common/helpers/formatJsonString";
import { useAuthenticatedUser } from "../../../../../../../common/hooks/useAuthenticatedUser";
import { selectedKeyframeVariable } from "../../../../../../../common/variables/selectedKeyframe";
import ZoneThresholdRowContainer from "../components/ZoneThresholdRowContainer";
import { ADMIN_ACCESS } from "../components/ZoneListTableContainer";
import { IZonesByDeviceTableRow } from "./useGetZonesByDeviceTableRow";
import ZoneAlertsToggle from "../../../../../../../common/components/zones/ZoneAlertsToggle";

export enum ThresholdColumnStateEnum {
  READ_MODE = "readMode",
  EDIT_MODE = "editMode",
  ADD_MODE = "addMode",
}

export type ThresholdColumnState =
  | ThresholdColumnStateEnum.ADD_MODE
  | ThresholdColumnStateEnum.EDIT_MODE
  | ThresholdColumnStateEnum.READ_MODE;

export const useGetZonesByDeviceTableColumns = (): {
  columns: GridColDef[];
  rowModesModel: GridRowModesModel;
  setRowModesModelChange: (newRowModesModel: GridRowModesModel) => void;
} => {
  const { role } = useAuthenticatedUser();

  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  const setRowModesModelChange = (
    newRowModesModel: GridRowModesModel
  ): void => {
    setRowModesModel(newRowModesModel);
  };

  const resetEditMode = (id: GridRowId, ignoreModifications: boolean): void => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications },
    });
  };

  const handleCancelClick =
    (id: GridRowId): (() => void) =>
    (): void => {
      resetEditMode(id, true);
    };

  const handleSaveClick =
    (id: GridRowId): (() => void) =>
    (): void => {
      resetEditMode(id, false);
    };

  const handleRowClick = (params: GridRenderCellParams): void => {
    selectedKeyframeVariable(params?.row?.keyFrame ?? "");
  };

  const hasAdminAccess = ADMIN_ACCESS.includes(role);

  const nonAdminColumns: GridColDef<IZonesByDeviceTableRow>[] = [
    {
      field: "keyFrame",
      flex: 1,
      minWidth: 100,
      width: 100,
      hideable: false,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params): JSX.Element | null => {
        const keyFrame = parseEventMediaPath(params?.row?.keyFrame ?? "");

        return (
          <Box
            onClick={() => handleRowClick(params)}
            sx={{ cursor: "pointer" }}
          >
            {params?.row?.keyFrame && (
              <S3Image
                sx={{
                  borderRadius: "4px",
                }}
                width={64}
                height={48}
                s3Key={keyFrame}
              />
            )}
          </Box>
        );
      },
    },
    {
      field: "name",
      headerName: "Zone",
      flex: 1,
      minWidth: 180,
      hideable: false,
      disableColumnMenu: true,
      editable: true,
      renderCell: (params): JSX.Element | null =>
        params ? (
          <Typography variant="body2Bold">{params?.row.name}</Typography>
        ) : null,
    },
    {
      field: "pan",
      headerName: "Pan",
      flex: 1,
      minWidth: 80,
      hideable: false,
      disableColumnMenu: true,
      editable: hasAdminAccess,
      renderCell: (params): JSX.Element | null =>
        params ? (
          <Typography variant="body2">{params?.row.pan}</Typography>
        ) : null,
    },
    {
      field: "tilt",
      headerName: " Tilt",
      flex: 1,
      minWidth: 80,
      hideable: false,
      disableColumnMenu: true,
      editable: hasAdminAccess,
      renderCell: (params): JSX.Element | null =>
        params ? (
          <Typography variant="body2">{params?.row.tilt}</Typography>
        ) : null,
    },
    {
      field: "zoom",
      headerName: " Zoom",
      flex: 1,
      minWidth: 80,
      hideable: false,
      disableColumnMenu: true,
      editable: hasAdminAccess,
      renderCell: (params): JSX.Element | null =>
        params ? (
          <Typography variant="body2">{params?.row.zoom}</Typography>
        ) : null,
    },
    {
      field: "threshold",
      headerName: "Threshold",
      renderHeader: () => {
        return (
          <Box
            sx={{
              display: "flex",
              gap: "0.5em",
            }}
          >
            <Typography variant="body2">Threshold</Typography>
            <Tooltip title="You can manage thresholds on zones to limit alerts that will send">
              <Box>
                <InfoIcon />
              </Box>
            </Tooltip>
          </Box>
        );
      },
      disableColumnMenu: true,
      hideable: false,
      sortable: false,
      minWidth: 300,
      renderCell: (params): JSX.Element => (
        <ZoneThresholdRowContainer row={params.row} />
      ),
    },
    {
      field: "status",
      headerName: "Zone Status",
      flex: 1,
      minWidth: 120,
      hideable: false,
      disableColumnMenu: true,
      editable: true,
      sortable: false,
      type: "boolean",
      renderCell: (params: GridRenderEditCellParams) => {
        if (params) {
          return <ZoneStatusToggle params={params.row} />;
        }
      },
    },
    {
      field: "shouldNotify",
      headerName: "Alerts",
      flex: 1,
      minWidth: 100,
      hideable: false,
      disableColumnMenu: true,
      editable: true,
      sortable: false,
      type: "boolean",
      renderCell: (params: GridRenderEditCellParams) => {
        if (params) {
          return <ZoneAlertsToggle params={params.row} />;
        }
      },
    },
    {
      field: "isAlerting",
      headerName: "Active Event",
      flex: 1,
      minWidth: 150,
      hideable: false,
      disableColumnMenu: true,
      editable: hasAdminAccess,
      renderCell: (params): JSX.Element | null => {
        const timeOfEvent = params?.row?.alertData?.eventId;

        const date = new Date(timeOfEvent);

        const localReadableTime = date.toLocaleString();

        let message = params?.row?.alertData?.isAlerting
          ? `Started: ${localReadableTime}`
          : "No active event";

        if (
          (params?.row?.alertData &&
            Object.keys(params?.row?.alertData)?.length) ??
          0 === 0
        ) {
          message = "";
        }

        return <Typography variant="body2">{message}</Typography>;
      },
    },
  ];

  if (hasAdminAccess) {
    return {
      columns: [
        ...nonAdminColumns,
        {
          field: "action",
          headerName: "",
          disableColumnMenu: true,
          hideable: false,
          sortable: false,
          type: "actions",
          renderCell: (params): JSX.Element => {
            const activeRow = rowModesModel[params.id]?.mode;

            return (
              <ButtonGroup variant="text" color="secondary">
                {activeRow && (
                  <>
                    <IconButton
                      aria-label="cancel"
                      onClick={handleCancelClick(params.id)}
                    >
                      <CloseRoundedIcon />
                    </IconButton>
                    <IconButton
                      aria-label="save"
                      onClick={handleSaveClick(params.id)}
                    >
                      <CheckRoundedIcon />
                    </IconButton>
                  </>
                )}
              </ButtonGroup>
            );
          },
        },
      ],
      rowModesModel,
      setRowModesModelChange,
    };
  }

  return { columns: nonAdminColumns, rowModesModel, setRowModesModelChange };
};
