import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Box, Tab, Tabs } from "@mui/material";

import TabPanel from "../../common/components/tabs/TabPanel";
import { useAuthenticatedUser } from "../../common/hooks/useAuthenticatedUser";
import {
  AdminRoles,
  SystemSettingsTabsEnum,
  SystemSettingsTabsList,
} from "../../common/variables/tabsSettings";
import AlertManagementTab from "../customer-settings/tabs/alert-management/AlertManagementTab";
import LocationsPage from "../customer-settings/tabs/locations/LocationsPage";
import IntegrationsPage from "../integrations/IntegrationsPage";
import DevicesMakes from "./tabs/devices/DevicesMakes";
import PlatesManagementSystemSettings from "./tabs/plates-management/PlatesManagementSystemSettings";
import UserPreferencesTab from "./tabs/user-preferences/UserPreferencesTab";
import EditCustomerPage from "./tabs/edit-customer/components/EditCustomerPage";

const SystemSettingsTabs = (): JSX.Element => {
  const { role } = useAuthenticatedUser();
  const { tabId = SystemSettingsTabsEnum.DEVICES } = useParams<{
    tabId: SystemSettingsTabsEnum;
  }>();
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState<SystemSettingsTabsEnum>(tabId);

  const isItAdminRole = AdminRoles.includes(role);

  useEffect((): void => {
    if (role !== "") {
      const allowedTabs = SystemSettingsTabsList.filter((tab): boolean => {
        return tab.allowedRoles.includes(role);
      });

      const isURLTabAllowed = allowedTabs.find(tab => tab.id === selectedTab);

      setSelectedTab(isURLTabAllowed?.id ?? allowedTabs[0].id);
    }
  }, [role]);

  const handleChange = (
    _event: React.SyntheticEvent,
    newValue: SystemSettingsTabsEnum
  ): void => {
    navigate(newValue);

    setSelectedTab(newValue);
  };

  const allowedTabs = SystemSettingsTabsList.filter((tab): boolean => {
    return tab.allowedRoles.includes(role);
  });

  const tabs = allowedTabs.map((tab): JSX.Element => {
    return (
      <Tab
        key={tab.id}
        sx={{
          textTransform: "none",
        }}
        label={tab.label}
        value={tab.id}
      />
    );
  });

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{ borderBottom: 1, borderColor: "divider", marginBottom: "1.5em" }}
      >
        <Tabs
          onChange={handleChange}
          value={selectedTab}
          variant="scrollable"
          scrollButtons="auto"
        >
          {tabs}
        </Tabs>
      </Box>

      {!isItAdminRole && (
        <TabPanel
          value={selectedTab}
          index={SystemSettingsTabsEnum.MANAGE_LOCATIONS}
        >
          <LocationsPage />
        </TabPanel>
      )}

      <TabPanel
        value={selectedTab}
        index={SystemSettingsTabsEnum.USER_PREFERENCES}
      >
        <UserPreferencesTab />
      </TabPanel>

      <TabPanel
        value={selectedTab}
        index={SystemSettingsTabsEnum.ALERT_MANAGEMENT}
        sx={{ padding: 0 }}
      >
        <AlertManagementTab />
      </TabPanel>

      <TabPanel value={selectedTab} index={SystemSettingsTabsEnum.DEVICES}>
        <DevicesMakes />
      </TabPanel>

      <TabPanel
        value={selectedTab}
        index={SystemSettingsTabsEnum.INTEGRATIONS}
        sx={{ padding: 0 }}
      >
        <IntegrationsPage />
      </TabPanel>

      <TabPanel
        value={selectedTab}
        index={SystemSettingsTabsEnum.EDIT_CUSTOMER}
      >
        <EditCustomerPage />
      </TabPanel>

      <TabPanel
        value={selectedTab}
        index={SystemSettingsTabsEnum.PLATES_MANAGEMENT}
      >
        <PlatesManagementSystemSettings />
      </TabPanel>
    </Box>
  );
};

export default SystemSettingsTabs;
