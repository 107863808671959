import { MouseEvent, useState } from "react";

import { GridRowParams, MuiEvent } from "@mui/x-data-grid";

import { S3Helper } from "../../../common/utils/s3helper";

interface IUseRowClick {
  open: boolean;
  mediaOutputLoading: boolean;
  mediaOutput: string;
  signedUrl: string;
  closeDialog: () => void;
  handleRowClick: (
    key?: string
  ) => (
    params: GridRowParams,
    event: MuiEvent<React.MouseEvent<HTMLElement>>
  ) => void;
}

export const useRowClick = (): IUseRowClick => {
  const [open, setOpen] = useState(false);
  const [mediaLoading, setMediaLoading] = useState(false);
  const [mediaOutput, setMediaOutput] = useState("");
  const [signedUrl, setSignedUrl] = useState("");

  const closeDialog = (): void => {
    setMediaOutput("");

    setOpen(false);
  };

  const handleRowClick =
    (key = "mediaOutput") =>
    (params: GridRowParams, event: MuiEvent<MouseEvent>) => {
      if (event.defaultMuiPrevented) {
        return;
      }

      const mediaUrl = params?.row?.[key];

      setMediaLoading(true);

      setMediaOutput(mediaUrl ?? "");

      S3Helper.getObject(mediaUrl)
        .then((signedUrl: string): void => {
          setSignedUrl(signedUrl);

          setMediaLoading(false);

          setOpen(true);
        })
        .catch((error: Error): void => {
          console.log("S3Video", error.message);

          setMediaOutput("");

          setSignedUrl("");
        })
        .finally((): void => setMediaLoading(false));
    };

  return {
    open,
    mediaOutputLoading: mediaLoading,
    mediaOutput,
    signedUrl,
    closeDialog,
    handleRowClick,
  };
};
