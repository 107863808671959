import { FC } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import StyledDialog from "../../../../../../common/components/dialog/StyledDialog";
import StyledLoadingButton from "../../../../../../common/providers/theme/design-tokens/LoadingButton/StyledLoadingButton";
import PlateNumberForm from "../PlateNumberForm";
import { IPlateNumberForm } from "../../variables/plateNumberData";
import { useCreateLicensePlate } from "../../hooks/useCreateLicensePlate";

interface IAddPlateToCategoryDialog {
  isOpened: boolean;
  closeDialog: () => void;
  categoryName: string;
  categoryId: string;
}

const AddPlateToCategoryDialog: FC<IAddPlateToCategoryDialog> = ({
  isOpened,
  closeDialog,
  categoryName,
  categoryId,
}) => {
  const { createLicensePlate, loading } = useCreateLicensePlate();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IPlateNumberForm>();

  const handleDialogClose = (): void => {
    closeDialog();
  };

  const onSubmit: SubmitHandler<IPlateNumberForm> = async (
    data
  ): Promise<void> => {
    await createLicensePlate(
      data.plateNumber,
      categoryId,
      data.companyName,
      data.driverName
    );

    handleDialogClose();
  };

  return (
    <StyledDialog
      open={isOpened}
      title="Add new plate number"
      onClose={handleDialogClose}
      showSubmitButton={false}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <PlateNumberForm
          control={control}
          errors={errors}
          preSelectedCategory={categoryName}
        />

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "2em",
            gap: "1em",
          }}
        >
          <Button
            disabled={loading}
            variant="outlined"
            color="secondary"
            onClick={closeDialog}
          >
            Cancel
          </Button>
          <StyledLoadingButton
            loading={loading}
            type={"submit"}
            variant="contained"
            color="primary"
            onClick={handleSubmit(onSubmit)}
          >
            Add new plate number
          </StyledLoadingButton>
        </Box>
      </form>
    </StyledDialog>
  );
};

export default AddPlateToCategoryDialog;
