import { useTheme } from "@mui/material/styles";

const StatisticsMenuIcon = (): JSX.Element => {
  const theme = useTheme();

  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.3332 24.3334H15.9998C12.0715 24.3334 10.1073 24.3334 8.88689 23.113C7.6665 21.8926 7.6665 19.9285 7.6665 16.0001V7.66675"
        stroke={theme.palette.text.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M21.8335 11.8333L19.2351 15.1053C18.8371 15.6065 18.6381 15.8571 18.4096 15.9792C18.0584 16.167 17.6386 16.1761 17.2795 16.0038C17.0459 15.8917 16.8362 15.65 16.4168 15.1666C15.9974 14.6832 15.7877 14.4415 15.5541 14.3294C15.195 14.157 14.7752 14.1662 14.424 14.354C14.1955 14.4761 13.9965 14.7267 13.5985 15.2279L11 18.4999"
        stroke={theme.palette.text.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default StatisticsMenuIcon;
