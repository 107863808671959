import { Box, Grid } from "@mui/material";

import { useGetNonAdminAccess } from "../../../common/hooks/useGetNonAdminAccess";
import DeploymentHealthGridItem from "./dashboard/DeploymentHealthGridItem";
import GasLeakGridItem from "./dashboard/GasLeakGridItem";
import GateGuardGridItem from "./dashboard/GateGuardGridItem";
import HardHatDataGridItem from "./dashboard/HardHatDataGridItem";
import LiquidLeakGridItem from "./dashboard/LiquidLeakGridItem";
import LiveViewPtControlGridItem from "./dashboard/LiveViewPtControlGridItem";
import LocationsMapGridItem from "./dashboard/LocationsMapGridItem";
import RecentGridItem from "./dashboard/RecentGridItem";
import SensoryConfigurationGridItem from "./dashboard/SensoryConfigurationGridItem";
import TlmDataGridItem from "./dashboard/TlmDataGridItem";
import FireGridItem from "./dashboard/FireGridItem";
import SmokeGridItem from "./dashboard/SmokeGridItem";

const DataHubDashboard = (): JSX.Element => {
  const { nonAdminView } = useGetNonAdminAccess();

  const sm = nonAdminView ? 6 : 4;

  return (
    <>
      {!nonAdminView && (
        <Grid container alignItems="center" justifyContent="center" spacing={3}>
          <Grid item xs={12} sm={sm}>
            <SensoryConfigurationGridItem />
          </Grid>
          <Grid item xs={12} sm={sm}>
            <DeploymentHealthGridItem />
          </Grid>
          <Grid item xs={12} sm={sm}>
            <LiveViewPtControlGridItem />
          </Grid>
        </Grid>
      )}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} lg={8}>
          <RecentGridItem />
        </Grid>
        <Grid item xs={12} sm={12} lg={4}>
          <Box marginBottom="1em">
            <LocationsMapGridItem />
          </Box>

          <Box
            sx={{
              display: "grid",
              gap: 2,
            }}
          >
            <GasLeakGridItem nonAdmin={nonAdminView} />
            {/* <FireSmokeGridItem nonAdmin={nonAdminView} /> */}
            <FireGridItem nonAdmin={nonAdminView} />
            <SmokeGridItem nonAdmin={nonAdminView} />
            <HardHatDataGridItem nonAdmin={nonAdminView} />
            <GateGuardGridItem nonAdmin={nonAdminView} />
            <TlmDataGridItem nonAdmin={nonAdminView} />
            <LiquidLeakGridItem nonAdmin={nonAdminView} />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default DataHubDashboard;
