import { FC } from "react";

import MenuItem from "@mui/material/MenuItem";

interface AllLocationsFilterDropdownMenuItemProps {
  entityName: string;
  entityId: string;
  onMenuItemClick: (name: string, id: string) => void;
}

const AllLocationsFilterDropdownMenuItem: FC<
  AllLocationsFilterDropdownMenuItemProps
> = ({ entityName, entityId, onMenuItemClick }) => {
  return (
    <MenuItem
      sx={{
        borderLeft: "2px solid transparent",
        "&:hover": {
          borderLeft: theme => `2px solid ${theme.palette.primary.main}`,
          backgroundColor: theme => theme.palette.primary.shades?.["8p"],
        },
      }}
      onClick={() => onMenuItemClick(entityName, entityId)}
    >
      {entityName}
    </MenuItem>
  );
};

export default AllLocationsFilterDropdownMenuItem;
