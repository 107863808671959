import { useEffect } from "react";

import SetFavicon from "./SetFavicon";
import withApolloProvider from "./common/providers/ApolloProvider";
import withAuthenticatorProvider from "./common/providers/AuthenticatorProvider";
import withNotificationMessageProvider from "./common/providers/NotificationMessageProvider";
import withThemeProvider from "./common/providers/theme/ThemeProvider";
import { S3Helper } from "./common/utils/s3helper";
import AppRoutes from "./routes/AppRoutes";

const App = (): JSX.Element => {
  useEffect((): void => {
    const initS3 = async (): Promise<void> => {
      if (!S3Helper.initialized) {
        await S3Helper.setAWSConfig();
      }
    };

    initS3();
  }, [S3Helper.initialized]);

  return (
    <>
      <AppRoutes />
      <SetFavicon />
    </>
  );
};

export default withApolloProvider(
  withAuthenticatorProvider(
    withThemeProvider(withNotificationMessageProvider(App))
  )
);
