import Typography from "@mui/material/Typography";

import RouterLink from "../../../../common/components/RouterLink";
import { RouteEnum } from "../../../../common/models/enums";
import LiquidLeakDataHubIcon from "./../icons/LiquidLeakDataHubIcon";
import DataHubGridItem from "./DataHubGridItem";
import { IModelGridItemProps } from "./GasLeakGridItem";

const LiquidLeakGridItem = ({ nonAdmin }: IModelGridItemProps): JSX.Element => {
  return (
    <RouterLink
      to={nonAdmin ? RouteEnum.SensoryLiquidLeak : RouteEnum.LiquidLeak}
    >
      <DataHubGridItem>
        <LiquidLeakDataHubIcon />
        <Typography variant="body2Bold">Liquid Leak</Typography>
      </DataHubGridItem>
    </RouterLink>
  );
};

export default LiquidLeakGridItem;
