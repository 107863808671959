import { FC, Fragment, SyntheticEvent, useMemo } from "react";

import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

import { useGetPaginatedLicensePlates } from "../../../../pages/system-settings/tabs/plates-management/hooks/useGetPaginatedLicensePlates";
import { AutocompleteOptionType } from "../../../models/autocomplete";

interface ILicensePlatesDropdownProps {
  selectedValue: AutocompleteOptionType;
  setSelectedValue: (value: AutocompleteOptionType) => void;
}

const LicensePlatesFilterDropdown: FC<ILicensePlatesDropdownProps> = ({
  selectedValue,
  setSelectedValue,
}) => {
  const { licensePlates, loading: licensePlatesLoading } =
    useGetPaginatedLicensePlates();

  const handleOnChange = async (
    _event: SyntheticEvent<Element, Event>,
    optionValue: AutocompleteOptionType
  ): Promise<void> => {
    setSelectedValue(optionValue);
  };

  const options = useMemo<AutocompleteOptionType[]>(() => {
    const filteredLicensePlates: any[] = [];

    if (licensePlates) {
      for (const key in licensePlates) {
        if (Array.isArray(licensePlates[key])) {
          licensePlates[key].forEach(plate => {
            filteredLicensePlates.push(plate);
          });
        }
      }
    }

    const licensePlatesOptions =
      filteredLicensePlates.map(plate => {
        if (
          selectedValue?.value &&
          !selectedValue?.title &&
          selectedValue?.value === plate?.licensePlate
        ) {
          selectedValue.title = plate.licensePlate;
        }

        return {
          title: plate?.licensePlate ?? "",
          value: plate?.licensePlate ?? "",
        };
      }) ?? [];

    return licensePlatesOptions;
  }, [licensePlates, selectedValue]);

  return (
    <Autocomplete
      size="small"
      fullWidth
      loading={licensePlatesLoading}
      options={options}
      getOptionLabel={(option: AutocompleteOptionType): string =>
        option?.title ?? ""
      }
      isOptionEqualToValue={(option, optionValue): boolean =>
        option?.value === "" ? true : option?.value === optionValue?.value
      }
      value={selectedValue}
      onChange={handleOnChange}
      popupIcon={<ExpandMoreOutlinedIcon />}
      renderOption={(props, option): JSX.Element => (
        <Fragment key={option?.value}>
          {option?.value && (
            <Box {...props} component="li">
              {option?.title}
            </Box>
          )}
        </Fragment>
      )}
      ListboxProps={{
        style: {
          maxHeight: "362px", // or 363px
        },
      }}
      renderInput={(params): JSX.Element => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <TextField
            sx={{ height: "60px" }}
            {...params}
            variant="outlined"
            label="Plate Number"
          />
        </Box>
      )}
    />
  );
};

export default LicensePlatesFilterDropdown;
