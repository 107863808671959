import { Fragment, useEffect, useMemo, type SyntheticEvent } from "react";

import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import isArray from "lodash/isArray";

import { useLazyGetPaginatedLocations } from "../../../../pages/customer-settings/tabs/locations/hooks/useLazyGetPaginatedLocations";
import type {
  AutocompleteOptionsType,
  AutocompleteOptionType,
  IDisableClearableOption,
} from "../../../models/autocomplete";
import { useTheme } from "@mui/material";

interface LocationIdSelectProps {
  setLocation: (locationValue: AutocompleteOptionType) => void;
  location: AutocompleteOptionType;
  defaultValue?: string;
  helperText?: string;
  setDefault?: boolean;
  disableClearable?: boolean;
  hasError?: boolean;
  disabled?: boolean;
  label?: string;
  size?: "small" | "medium";
}

const LocationIdSelect = ({
  setLocation,
  location,
  defaultValue,
  helperText,
  setDefault,
  disableClearable,
  hasError,
  disabled,
  label = "Select Location",
  size = "medium",
}: LocationIdSelectProps): JSX.Element => {
  const { locations, loading } = useLazyGetPaginatedLocations();

  useEffect((): void => {
    const isLocationsDataArrray = isArray(locations);

    if (setDefault === false && !defaultValue) {
      setLocation(null);
    } else if (isLocationsDataArrray && setDefault && defaultValue) {
      const locationItem =
        locations?.find((l): boolean => l?.id === defaultValue) ?? null;
      const defaultOption = {
        title: locationItem?.name ?? "",
        value: locationItem?.id ?? "",
      };

      setLocation(defaultOption);
    } else if (isArray(locations) && setDefault) {
      setLocation({
        title: locations[0]?.name ?? "",
        value: locations[0]?.id ?? "",
      });
    } else if (isLocationsDataArrray) {
      const locationItem =
        locations?.find((l): boolean => l?.id === location?.value) ?? null;

      if (!locationItem) {
        setLocation({
          title: locations[0]?.name ?? "",
          value: locations[0]?.id ?? "",
        });
      } else {
        const defaultOption = {
          title: locationItem?.name ?? "",
          value: locationItem?.id ?? "",
        };

        setLocation(defaultOption);
      }
    }
  }, [locations, setDefault, defaultValue]);

  const handleOnchange = (
    _event: SyntheticEvent<Element, Event>,
    optionValue: AutocompleteOptionType
  ): void => {
    if (!optionValue) {
      setLocation({
        title: "",
        value: "",
      });

      return;
    }

    setLocation(optionValue);
  };

  const options =
    useMemo<AutocompleteOptionsType>((): IDisableClearableOption[] => {
      const defaultOption = [
        {
          title: "",
          value: "",
        },
      ];

      if (!Array.isArray(locations)) {
        return defaultOption;
      }

      return locations?.map((location): IDisableClearableOption => {
        return {
          title: location?.name ?? "",
          value: location?.id ?? "",
        };
      });
    }, [locations]);

  return (
    <Autocomplete
      size={size}
      fullWidth
      disabled={disabled}
      disableClearable={disableClearable}
      loading={loading}
      options={options}
      getOptionLabel={(option: AutocompleteOptionType): string =>
        option?.title ?? ""
      }
      isOptionEqualToValue={(option, optionValue): boolean =>
        option?.value === "" ? true : option?.value === optionValue?.value
      }
      value={location}
      onChange={handleOnchange}
      popupIcon={<ExpandMoreOutlinedIcon />}
      renderOption={(props, option): JSX.Element => {
        return (
          <Fragment key={option?.value}>
            {option?.value && (
              <Box {...props} component="li">
                {option?.title}
              </Box>
            )}
          </Fragment>
        );
      }}
      renderInput={(params): JSX.Element => {
        const theme = useTheme();

        const isErrorAndNoValue = hasError && !location?.value;
        const errorStyles = isErrorAndNoValue
          ? {
              color: theme.palette.error.main,
            }
          : {};

        return (
          <TextField
            {...params}
            error={isErrorAndNoValue}
            helperText={
              helperText ?? (isErrorAndNoValue ? "This field is required" : "")
            }
            variant="outlined"
            label={label}
            fullWidth
            InputLabelProps={{
              style: errorStyles,
            }}
            FormHelperTextProps={{
              style: errorStyles,
            }}
            sx={{
              "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: theme => theme.palette.error.main,
                },
            }}
          />
        );
      }}
    />
  );
};

export default LocationIdSelect;
