import { useTheme } from "@mui/material/styles";

const LocationIcon = () => {
  const theme = useTheme();

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33333 8.45307C3.33333 4.70522 6.3181 1.66699 9.99999 1.66699C13.6819 1.66699 16.6667 4.70522 16.6667 8.45307C16.6667 12.1715 14.5389 16.5107 11.2191 18.0624C10.4452 18.4241 9.55478 18.4241 8.78088 18.0624C5.4611 16.5107 3.33333 12.1715 3.33333 8.45307Z"
        stroke={theme.palette.otherTextTertiary.main}
        strokeWidth="1.5"
      />
      <ellipse
        cx="10"
        cy="8.33301"
        rx="2.5"
        ry="2.5"
        stroke={theme.palette.otherTextTertiary.main}
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default LocationIcon;
