import { useLocation, useNavigate } from "react-router-dom";

import { Box, Stack, Typography } from "@mui/material";

import { useCustomerIdGuard } from "../../../../common/hooks/useCustomerIdGuard";
import StyledLoadingButton from "../../../../common/providers/theme/design-tokens/LoadingButton/StyledLoadingButton";
import {
  errorNotification,
  successNotification,
} from "../../../../common/variables/notification";
import client from "../../../../configs/apolloClient";
import LocationForm from "./components/LocationForm";
import { useGetLocationById } from "./hooks/useGetLocationById";
import { useUpdateLocation } from "./hooks/useUpdateLocation";
import {
  defaultLocationFormValidationState,
  defaultLocationFormVariables,
  locationFormRules,
  locationFormValidationStateVariable,
  locationFormVariables,
  useLocationFormValidationState,
  useLocationFormVariables,
} from "./variables/locationData";
import BreadcrumbNavigation from "../../../../common/components/tabs/BreadcrumbNavigation";
import { RouteEnum } from "../../../../common/models/enums";
import PaperWrapperCentered from "../../../../common/components/item/PaperWrapperCentered";
import { TagInput, TagOfAssociation } from "../../../../API";

const EditLocationPage = (): JSX.Element => {
  const selectedCustomerId = useCustomerIdGuard();
  const navigate = useNavigate();
  const form = useLocationFormVariables();
  const validation = useLocationFormValidationState();
  const location = useLocation(); // this is react router dom location hook for getting react router dom state

  const { data } = useGetLocationById();

  const { updateLocation, loading } = useUpdateLocation();

  const handleCancel = (): void => {
    locationFormVariables(defaultLocationFormVariables);

    locationFormValidationStateVariable(defaultLocationFormValidationState);

    navigate(-1);
  };

  const saveLocation = (): void => {
    let validationState = {
      ...(validation ?? defaultLocationFormValidationState),
    };

    const key = form.locationName.toLowerCase().replace(/\s+/g, "_");
    const locationId = `${selectedCustomerId}#L#${key}`;

    const normalizedId =
      client.cache.identify({
        id: locationId,
        __typename: "Location",
      }) ?? "";

    const extract = client.cache.extract();

    if (normalizedId && extract[normalizedId]?.name === form.locationName) {
      if (form.locationName !== location.state.existingRowName) {
        validationState = {
          ...validationState,
          locationName: {
            hasError: true,
            errorMessage: "Location name already exists",
          },
        };
      }
    }

    if (!form.locationName) {
      validationState = {
        ...validationState,
        locationName: {
          hasError: true,
          errorMessage: "The field is required",
        },
      };
    }

    if (form.locationName.length > locationFormRules.locationName.maxLength) {
      validationState = {
        ...validationState,
        locationName: {
          hasError: true,
          errorMessage: `Location name cannot be longer than ${locationFormRules.locationName.maxLength} characters`,
        },
      };
    }

    if (!form.latitude) {
      validationState = {
        ...validationState,
        latitude: {
          hasError: true,
          errorMessage: "The field is required",
        },
      };
    }

    if (!form.longitude) {
      validationState = {
        ...validationState,
        longitude: {
          hasError: true,
          errorMessage: "The field is required",
        },
      };
    }

    if (!form.timeZone) {
      validationState = {
        ...validationState,
        timeZone: {
          hasError: true,
          errorMessage: "The field is required",
        },
      };
    }

    if (
      Object.values(validationState).some(
        (item): boolean => item?.hasError ?? false
      )
    ) {
      locationFormValidationStateVariable(validationState);

      return;
    }

    let tagsToDelete: Omit<TagOfAssociation, "__typename">[] = [];
    let tagsToCreate: TagInput[] = form.tags;

    const tagsOfLocation = data?.getLocationById.tags ?? [];

    tagsToDelete = tagsOfLocation.filter(
      tag => !form.tags.some(locationTag => locationTag.id === tag.id)
    );

    tagsToDelete = tagsToDelete.map(tag => ({
      id: tag.id,
      tagName: tag.tagName,
      associationId: tag.associationId,
    }));

    tagsToCreate = form.tags.filter(
      tag => !tagsOfLocation.some(locationTag => locationTag.id === tag.id)
    );

    tagsToCreate = tagsToCreate.map(tag => ({
      id: tag.id,
      tagName: tag.tagName,
    }));

    updateLocation({
      customerId: selectedCustomerId,
      locationId: form.rowId,
      // id: form.locationName,
      name: form.locationName,
      referenceId: form.referenceId,
      locationData: JSON.stringify({
        lat: form.latitude,
        lon: form.longitude,
      }),
      tags: tagsToCreate,
      tagsToDelete,
      timeZone: form.timeZone,
    })
      .then((): void => {
        successNotification(`Changes are saved`);

        locationFormVariables(defaultLocationFormVariables);

        locationFormValidationStateVariable(defaultLocationFormValidationState);

        navigate(-1);
      })
      .catch((error): void => {
        errorNotification();

        console.error(error);
      });
  };

  const locationPath = location.pathname.includes("sensory")
    ? RouteEnum.SensorySystemSettings
    : RouteEnum.CustomerSettings;

  const breadcrumbItems = [
    { label: "Customer settings", path: locationPath },
    {
      label: "Edit location",
    },
  ];

  return (
    <>
      <Box sx={{ marginBottom: "1em" }}>
        <BreadcrumbNavigation items={breadcrumbItems} />
      </Box>

      <Typography variant="h3" sx={{ paddingBottom: "1em" }}>
        Edit location
      </Typography>
      <PaperWrapperCentered>
        <LocationForm edit />
      </PaperWrapperCentered>
      <Stack direction="row-reverse" spacing={2} sx={{ paddingTop: 3 }}>
        <StyledLoadingButton
          sx={{
            marginTop: "1.5em",
          }}
          loading={loading}
          loadingPosition="start"
          variant="contained"
          color="primary"
          onClick={saveLocation}
        >
          Save changes
        </StyledLoadingButton>
        <StyledLoadingButton
          sx={{
            marginTop: "1.5em",
          }}
          loading={loading}
          loadingPosition="start"
          variant="outlined"
          color="inherit"
          onClick={handleCancel}
        >
          Cancel
        </StyledLoadingButton>
      </Stack>
    </>
  );
};

export default EditLocationPage;
