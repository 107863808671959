import {
  Avatar,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useState } from "react";
import Cookies from "js-cookie";
import { useConfirm } from "material-ui-confirm";
import { Auth } from "aws-amplify";

import { useGetUser } from "../../../hooks/useGetUser";
import LogOutHeaderIcon from "../../icons/LogOutHeaderIcon";
import { isLocalEnvironment } from "../../../helpers/userLinkHelper";
import { RouteEnum } from "../../../models/enums";
import CircularLoading from "../../progress/CircularLoading";
import confirmDialogStyleOptions from "../../../helpers/confirmDialogParams";

export const UserMenu: React.FC = () => {
  const { userInfo } = useGetUser();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const confirm = useConfirm();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = async () => {
    confirm({
      title: "Are you sure you want to log out?",
      confirmationText: "Confirm",
      cancellationText: "Cancel",
      ...confirmDialogStyleOptions,
    })
      .then(async () => {
        if (isLocalEnvironment) {
          await Auth.signOut();

          window.location.href = RouteEnum.Login;
        } else {
          await Auth.signOut({ global: true });

          Cookies.remove("amplify_config");

          window.location.href = RouteEnum.Administration;
        }
      })
      .catch(() => {
        handleClose();
      });
  };

  return (
    <Box>
      <IconButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <Avatar
          sx={{
            backgroundColor: theme =>
              open
                ? theme.palette.primary.shades?.["8p"]
                : theme.palette.otherOutlineBorder.main,
            color: theme => theme.palette.text.secondary,
          }}
        >
          <Typography variant="body2Bold">
            <>
              {userInfo?.firstName?.[0] ?? "U"}
              {userInfo?.lastName?.[0] ?? "U"}
            </>
          </Typography>
        </Avatar>
      </IconButton>

      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        elevation={0}
        sx={{
          p: 0,
          minWidth: "244px",
          borderRadius: "8px",
          ".MuiList-padding": {
            p: 0,
          },
          ".MuiPaper-root": {
            boxShadow: "none",
            borderRadius: "8px",
            border: theme =>
              `1px solid ${theme.palette.otherOutlineBorder.main}`,
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            minWidth: "244px",
            px: "1em",
            py: "0.5em",
          }}
        >
          {userInfo ? (
            <>
              <Typography variant="body2Bold">
                {userInfo.firstName} {userInfo.lastName}
              </Typography>

              <Typography sx={{ fontSize: "12px" }}>
                {userInfo.email}
              </Typography>
            </>
          ) : (
            <CircularLoading />
          )}
        </Box>

        <Divider />

        <MenuItem
          sx={{
            height: "44px",
            "&:hover": {
              backgroundColor: theme => theme.palette.primary.shades?.["8p"],
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                left: 0,
                top: 0,
                bottom: 0,
                width: 4,
                backgroundColor: theme => theme.palette.primary.main,
              },
            },
          }}
          onClick={handleSignOut}
        >
          <ListItemIcon>
            <LogOutHeaderIcon />
          </ListItemIcon>
          <ListItemText>Log out</ListItemText>
        </MenuItem>
      </Menu>
    </Box>
  );
};
