import { useEffect, useMemo, useState } from "react";

import { useTheme } from "@mui/material/styles";
import dayjs from "dayjs";

import {
  ModelType,
  StatisticsDataInput,
  TimePeriod,
  eventType,
} from "../../../API";
import ApexOptions from "../../../common/components/apexchart/ApexChartModels";
import {
  IChartProps,
  IStatisticsResponseModel,
  IVisitorsResponseModel,
} from "../models/chart";
import { DEFAULT_STATISTICS_DATE_FORMAT } from "./useAllAlertsChart";
import { useGetStatisticsData } from "./useGetStatisticsData";

export const useVisitorsChart = (
  timePeriod: TimePeriod,
  locations: string[] | null
): IChartProps => {
  const theme = useTheme();

  const filterParams = useMemo(
    (): Pick<
      StatisticsDataInput,
      "eventType" | "models" | "timePeriod" | "locations"
    > => ({
      eventType: eventType.visitors,
      models: [ModelType.agg],
      locations,
      timePeriod,
    }),
    [timePeriod]
  );

  const { data, loading, refetching, refetchChartData } =
    useGetStatisticsData<IStatisticsResponseModel[]>(filterParams);

  const options: ApexOptions = useMemo(() => {
    return {
      chart: {
        stacked: false,
        width: "100%",
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        fontFamily: "Inter",
      },
      theme: {
        mode: theme.palette.mode,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: data.length === 1 ? 4 : 2,
      },
      legend: {
        position: "top",
        horizontalAlign: "left",
      },
      colors: ["#F3B344"],
      yaxis: {
        opposite: true,
      },
    };
  }, [theme.palette.mode]);

  const [chartSeries, setChartProps] = useState<any>([]);
  const [chartOptions, setChartOptions] = useState<any>(options);

  useEffect(() => {
    const series = generateChartSeries(data);
    const extendedOptions = generateChartCategories(data);

    setChartProps(series);

    setChartOptions(extendedOptions);
  }, [data]);

  const generateChartSeries = (
    response: IVisitorsResponseModel[]
  ): ApexOptions["series"] => {
    if (!Array.isArray(response) || response.length === 0) {
      return [];
    }

    const seriesData = response.map(r => +r.record_count) as number[];

    const series = [
      {
        name: "Visitors",
        data: seriesData,
        type:
          seriesData.filter(el => el !== null)?.length === 1
            ? "scatter"
            : "line",
      },
    ];

    return series;
  };

  const generateChartCategories = (
    response: IVisitorsResponseModel[]
  ): ApexOptions => {
    if (!Array.isArray(response) || response.length === 0) {
      return options;
    }

    const categories = response.map(s =>
      dayjs(new Date(s.date)).format(DEFAULT_STATISTICS_DATE_FORMAT)
    );

    const extendedOptions: ApexOptions = {
      ...options,
      xaxis: {
        categories,
        type: "datetime",
        tickAmount: 12,
        tooltip: {
          enabled: false,
        },
        labels: {
          formatter: function (value) {
            return dayjs(new Date(value)).format(
              DEFAULT_STATISTICS_DATE_FORMAT
            );
          },
        },
      },
      tooltip: {
        shared: true,
        followCursor: true,
        onDatasetHover: {
          highlightDataSeries: true,
        },
        custom: ({ series, seriesIndex, dataPointIndex, w }) => {
          const hoverXaxis = w.globals.seriesX[seriesIndex][dataPointIndex];
          const hoverIndexes = w.globals.seriesX.map((seriesX: any[]) => {
            return seriesX.findIndex((xData: any) => xData === hoverXaxis);
          });

          let hoverList = "";

          hoverIndexes.forEach(
            (hoverIndex: number, seriesEachIndex: string | number) => {
              if (hoverIndex >= 0) {
                hoverList += `
                  <div class="apexcharts-tooltip-series-group apexcharts-active" style="display: flex;">
                    <span class="apexcharts-tooltip-marker" style="background-color: ${
                      w.globals.markers.colors[seriesEachIndex]
                    };"></span>
                    <div class="apexcharts-tooltip-text">
                      <div class="apexcharts-tooltip-y-group">
                        <span class="apexcharts-tooltip-text-y-label">Total attendances: </span>
                        <span class="apexcharts-tooltip-text-y-value">${
                          w.globals.yLabelFormatters[0](
                            series[seriesEachIndex][hoverIndex]
                          ) ?? 0
                        }</span>
                      </div>
                    </div>
                  </div>
                `;
              }
            }
          );

          const date1 = dayjs(new Date(hoverXaxis)).format(
            DEFAULT_STATISTICS_DATE_FORMAT
          );

          const date2 = dayjs(
            new Date(w.globals.labels[dataPointIndex])
          ).format(DEFAULT_STATISTICS_DATE_FORMAT);

          const date3 = w.globals.categoryLabels[dataPointIndex];

          let date;

          if (!date1.includes("70")) {
            date = date1;
          }

          if (!date && !date2.includes("70")) {
            date = date2;
          }

          if (!date && !date3.includes("70")) {
            date = date3;
          }

          if (
            dayjs(new Date()).format(DEFAULT_STATISTICS_DATE_FORMAT) === date
          ) {
            date = "Today";
          }

          return `
            ${hoverList}
            <hr>
            <div class="tooltip-date">${date}</div>
          `;
        },
      },
    };

    return extendedOptions;
  };

  return {
    series: chartSeries,
    options: chartOptions,
    loading,
    refetching,
    refetchChartData,
    showNoData:
      !loading && !refetching && !data?.filter(d => d.record_count).length,
  };
};
