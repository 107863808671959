import { Tooltip, Zoom } from "@mui/material";
import { useConfirm } from "material-ui-confirm";

import DeleteIconButton from "../../../../../common/components/icons/DeleteIconButton";
import confirmDialogStyleOptions from "../../../../../common/helpers/confirmDialogParams";
import { useDeleteLicensePlate } from "../hooks/useDeleteLicensePlate";

const DeleteLicensePlateContainer = ({
  plateNumberId,
  plateNumber,
  category,
}: {
  plateNumberId: string;
  plateNumber: string;
  category: string;
}): JSX.Element => {
  const confirm = useConfirm();

  const { deleteLicensePlate } = useDeleteLicensePlate();

  const handleRowClick = (): void => {
    confirm({
      title: `Are you sure you want to delete the ${plateNumber} plate number?`,
      confirmationText: "Delete",
      cancellationText: "Cancel",
      ...confirmDialogStyleOptions,
      confirmationButtonProps: {
        ...confirmDialogStyleOptions.confirmationButtonProps,
      },
    }).then(async (): Promise<void> => {
      await deleteLicensePlate(category, plateNumberId);
    });
  };

  return (
    <Tooltip TransitionComponent={Zoom} title={"Delete"}>
      <span>
        <DeleteIconButton onClick={handleRowClick} />
      </span>
    </Tooltip>
  );
};

export default DeleteLicensePlateContainer;
