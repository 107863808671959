import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { CSSObject } from "@mui/material";

const AddModelButton = styled(Button)(
  ({ theme }): CSSObject => ({
    display: "flex",
    minWidth: "0",
    maxHeight: "28px",
    color: theme.palette.text.secondary,
    justifyContent: "flex-start",
    textTransform: "none",
    padding: "0",

    "&:hover .MuiButton-startIcon > *:first-of-type": {
      backgroundColor: theme.palette.primary.dark,
    },

    "& .MuiButton-startIcon": {
      color: theme.palette.otherBackgroundLight.main,
      margin: "0",
      padding: "0",

      "& > *:first-of-type": {
        borderRadius: "8px",
        fontSize: "20px",
        marginRight: "8px",
        height: "28px",
        width: "28px",
        backgroundColor: theme.palette.primary.main,
      },
    },
  })
);

export default AddModelButton;
