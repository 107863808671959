import Typography from "@mui/material/Typography";
import { GridColDef } from "@mui/x-data-grid";

import S3Image from "../../../../../../common/components/media/S3Image";
import { parseEventMediaPath } from "../../../../../../common/helpers/formatJsonString";
import useLocationFromCache from "../../../../../../common/hooks/useLocationFromCache";
import { formatTimestamp } from "../../../../../../common/utils/timestampUtils";
import { ILiquidLeakTableRow } from "../../common/events.models";

export const useGetLiquidLeakEventsTableColumns = () => {
  const { getCachedLocationName } = useLocationFromCache();

  const columns: GridColDef<ILiquidLeakTableRow>[] = [
    {
      field: "rowIndex",
      headerName: "#",
      width: 50,
      hideable: false,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params): JSX.Element | null => {
        return <Typography variant="body2">{params.row.rowIndex}</Typography>;
      },
    },
    {
      field: "keyFrame",
      headerName: "Snapshot",
      flex: 1,
      minWidth: 160,
      width: 160,
      hideable: false,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params): JSX.Element | null => {
        const keyFrame = parseEventMediaPath(params?.row?.keyFrame);

        return (
          <S3Image
            sx={{
              borderRadius: "4px",
            }}
            width={120}
            height={90}
            s3Key={keyFrame}
          />
        );
      },
    },
    {
      field: "location",
      headerName: "Location",
      flex: 1,
      minWidth: 160,
      hideable: false,
      disableColumnMenu: true,
      sortable: false,
      valueGetter: (locationId?: string | null): string => {
        const locationName = getCachedLocationName(locationId);

        return locationName;
      },
      renderCell: (params): JSX.Element | null => {
        const locationName = getCachedLocationName(params?.row?.location);

        return <Typography variant="body2">{locationName}</Typography>;
      },
    },
    {
      field: "cameraId",
      headerName: "DEVICE ID",
      flex: 1,
      minWidth: 160,
      hideable: false,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params): JSX.Element | null =>
        params ? (
          <Typography variant="body2Bold">{params?.row.cameraId}</Typography>
        ) : null,
    },
    {
      field: "timestamp",
      headerName: "Timestamp",
      flex: 1,
      minWidth: 180,
      hideable: false,
      disableColumnMenu: true,
      sortable: false,
      valueFormatter: (timestamp: string): string => {
        return formatTimestamp(timestamp);
      },
      renderCell: (params): JSX.Element | null =>
        params ? (
          <Typography variant="body2">
            {formatTimestamp(params?.row.timestamp)}
          </Typography>
        ) : null,
    },
  ];

  return { columns };
};
