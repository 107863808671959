import { useMemo, useRef, useState } from "react";

import { LinearProgress, TableContainer } from "@mui/material";
import {
  DataGrid,
  GridCellParams,
  GridRowHeightReturnValue,
  GridRowParams,
} from "@mui/x-data-grid";

import FullPageLoader from "../../../../../common/components/item/FullPageLoader";
import MediaCardDialog from "../../../../../common/components/media/MediaCardDialog";
import TableNoDataOverlay from "../../../../../common/components/table/TableNoDataOverlay";
import { dataGridServerStyles } from "../../../../../common/providers/theme/design-tokens/DataGrid/dataGridServer.styles";
import { S3Helper } from "../../../../../common/utils/s3helper";
import { DEFAULT_COLUMN_HEADER_HEIGHT } from "../../../../../common/variables/common";
import { TablePaperContainer } from "../../../../../leak-finder/components/VideosTable";
import { useGetRecentEventsTableColumns } from "./hooks/useGetRecentEventsTableColumns";
import {
  DEFAULT_RECENT_ACTIVITIES_PAGE_SIZE,
  DEFAULT_RECENT_ACTIVITIES_PAGE_SIZE_OPTIONS,
  IEventsTableRow,
  useGetRecentEventsTableRows,
} from "./hooks/useGetRecentEventsTableRows";

const RecentEventsTableServer = (): JSX.Element => {
  const { columns } = useGetRecentEventsTableColumns();

  const {
    rows,
    loading,
    paginationModel,
    handlePaginationModelChange,
    hasNextPage,
    isActivePageChange,
  } = useGetRecentEventsTableRows();

  const keyFrameRef = useRef(false);

  const [open, setOpen] = useState(false);
  const [mediaLoading, setMediaLoading] = useState(false);
  const [mediaOutput, setMediaOutput] = useState("");
  const [signedUrl, setSignedUrl] = useState("");

  const handleKeyFrameClick = (params: GridCellParams): void => {
    keyFrameRef.current = true;

    const mediaUrl = params?.row?.keyFrame;

    S3Helper.getObject(mediaUrl)
      .then((signedUrl: string): void => {
        setSignedUrl(signedUrl);

        setMediaOutput(mediaUrl ?? "");

        setMediaLoading(false);

        setOpen(true);
      })
      .catch((error: Error): void => {
        console.log("S3Video", error.message);

        setMediaOutput("");

        setSignedUrl("");
      })
      .finally((): void => setMediaLoading(false));
  };

  const handleMediaOutputClick = (params: GridRowParams): void => {
    // prevent the row click event from firing if the keyFrame was clicked
    if (keyFrameRef.current) {
      return;
    }

    const mediaUrl = params?.row?.mediaOutput;

    S3Helper.getObject(mediaUrl)
      .then((signedUrl: string): void => {
        setSignedUrl(signedUrl);

        setMediaOutput(mediaUrl ?? "");

        setMediaLoading(false);

        setOpen(true);
      })
      .catch((error: Error): void => {
        console.log("S3Video", error.message);

        setMediaOutput("");

        setSignedUrl("");
      })
      .finally((): void => setMediaLoading(false));
  };

  const handleCloseDialog = (): void => {
    setOpen(false);

    setMediaOutput("");

    // reset the keyFrameRef
    keyFrameRef.current = false;
  };

  const memoizedRows = useMemo((): IEventsTableRow[] => {
    return rows.map((row: IEventsTableRow) => {
      return {
        ...row,
        onKeyFrameClick: handleKeyFrameClick,
      };
    });
  }, [rows]);

  return (
    <TableContainer component={TablePaperContainer}>
      {mediaLoading && <FullPageLoader />}
      {open && (
        <MediaCardDialog
          fullWidth
          signedUrl={signedUrl}
          mediaOutput={mediaOutput}
          open={open}
          onClose={handleCloseDialog}
        />
      )}
      <DataGrid
        sx={{
          ...dataGridServerStyles.sx,
          "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
            py: "8px",
          },
          "& .MuiButtonBase-root.MuiIconButton-root:last-child": {
            pointerEvents: isActivePageChange ? "auto" : "none",
            cursor: isActivePageChange ? "pointer" : "none",
            backgroundColor: "transparent",
            color: theme =>
              isActivePageChange ? "inherit" : theme.palette.action.disabled,
          },
        }}
        columnHeaderHeight={DEFAULT_COLUMN_HEADER_HEIGHT}
        rows={memoizedRows}
        columns={columns}
        slots={{
          loadingOverlay: () => <LinearProgress />,
          noRowsOverlay: TableNoDataOverlay,
        }}
        rowCount={-1}
        onRowClick={handleMediaOutputClick}
        disableRowSelectionOnClick
        hideFooterSelectedRowCount
        getRowId={(row: { rowId: string }): string => row.rowId}
        getRowHeight={(): GridRowHeightReturnValue => 96}
        rowHeight={96}
        autoHeight
        loading={loading}
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={handlePaginationModelChange}
        paginationMeta={{ hasNextPage }}
        pageSizeOptions={DEFAULT_RECENT_ACTIVITIES_PAGE_SIZE_OPTIONS}
        sortingOrder={["desc", "asc"]}
        initialState={{
          sorting: {
            sortModel: [
              {
                field: "timestamp",
                sort: "desc",
              },
            ],
          },
          pagination: {
            paginationModel: {
              pageSize: DEFAULT_RECENT_ACTIVITIES_PAGE_SIZE,
              page: 0,
            },
            rowCount: -1,
          },
        }}
      />
    </TableContainer>
  );
};

export default RecentEventsTableServer;
