import { Box } from "@mui/material";

import CreateNodeBreadcrumb from "./components/CreateNodeBreadcrumb";
import CreateNodeStepperContainer from "./components/CreateNodeStepperContainer";

const CreateNodePage = (): JSX.Element => {
  return (
    <Box>
      <CreateNodeBreadcrumb />
      <CreateNodeStepperContainer />
    </Box>
  );
};

export default CreateNodePage;
