import { ApolloError, gql, useMutation } from "@apollo/client";

import {
  PaginatedDevicesMakes,
  UpdateDeviceMakeInput,
  UpdateDeviceMakeMutation,
  UpdateDeviceMakeMutationVariables,
} from "../../../../../API";
import { UPDATE_DEVICE_MAKE } from "../../../../../common/operations/mutations";
import {
  errorNotification,
  successNotification,
} from "../../../../../common/variables/notification";

export const useUpdateDeviceMake = () => {
  const [saveDeviceMake, { data, loading }] = useMutation<
    UpdateDeviceMakeMutation,
    UpdateDeviceMakeMutationVariables
  >(UPDATE_DEVICE_MAKE);

  const updateDeviceMake = async (input: UpdateDeviceMakeInput) => {
    return await saveDeviceMake({
      variables: {
        input,
      },
      onCompleted: response => {
        if (response.updateDeviceMake) {
          successNotification();
        }
      },
      onError: (error: ApolloError): void => {
        errorNotification(error.message);

        console.error(error.message);
      },
      update: (cache, response): void => {
        cache.modify({
          fields: {
            getDevicesMakes(existing: PaginatedDevicesMakes) {
              const newDeviceMakeRef = cache.writeFragment({
                data: response.data?.updateDeviceMake,
                fragment: gql`
                  fragment NewDeviceMake on DeviceMake {
                    id
                    model
                    name
                    rtspTemplate
                    type
                  }
                `,
              });

              if (!existing) {
                return response?.data;
              }

              return {
                ...existing,
                items: existing.items.map(item => {
                  if (item?.model !== response.data?.updateDeviceMake?.model) {
                    return item;
                  }

                  return newDeviceMakeRef;
                }),
              };
            },
          },
        });
      },
    });
  };

  return { updateDeviceMake, data, loading };
};
