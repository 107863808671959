import { useState } from "react";

import {
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
} from "@mui/material";

import { AutocompleteOptionType } from "../../../common/models/autocomplete";

import LocationIdSelect from "../../../common/components/layout/components/LocationIdSelect";
import DeviceSelect from "../../../common/components/select/DeviceSelect/DeviceSelect";
import NodeSelect from "../../../common/components/select/NodeSelect/NodeSelect";
import {
  ServiceTypeEnum,
  aiManagerVariable,
  useSelectedLocationAIManager,
  useSelectedServiceModel,
} from "../variables/modelManager";
import GasLeakForm from "./models/GasLeakForm";

interface ICreateServiceFormProps {
  showError?: boolean;
  disablePrefilledInputs?: boolean;
}

const CreateServiceForm = ({
  showError,
  disablePrefilledInputs,
}: ICreateServiceFormProps): JSX.Element => {
  const values = useSelectedServiceModel();
  const locationVariable = useSelectedLocationAIManager();

  const showAdvancedSection = [
    ServiceTypeEnum.LEAK,
    ServiceTypeEnum.LIQUID_LEAK,
  ].includes(values.serviceType as ServiceTypeEnum);

  const [showAdvancedParameters, setShowAdvancedParameters] = useState(false);

  const setSelectedServiceType = (event: SelectChangeEvent<string>): void => {
    aiManagerVariable({
      ...values,
      serviceType: event.target.value,
    });
  };

  const setLocation = (locationValue: AutocompleteOptionType): void => {
    aiManagerVariable({
      ...values,
      locationId: disablePrefilledInputs ? values.locationId : locationValue,
    });
  };

  const setDevice = (deviceValue: string): void => {
    aiManagerVariable({
      ...values,
      deviceId: deviceValue,
    });
  };

  const setNode = (value: string): void => {
    aiManagerVariable({
      ...values,
      nodeId: value,
    });
  };

  const handleSwitchChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setShowAdvancedParameters(event.target.checked);
  };

  return (
    <>
      <FormControlLabel
        sx={{
          marginLeft: 0,
        }}
        control={
          <Switch
            disableRipple
            disabled={!showAdvancedSection}
            checked={showAdvancedParameters}
            onChange={handleSwitchChange}
          />
        }
        label="Show advanced settings"
      />
      <Grid container spacing={2} paddingBottom="1em">
        <Grid item xs={12} sm={12}>
          <FormControl fullWidth sx={{ marginTop: "2em" }}>
            <InputLabel>Service Type</InputLabel>
            <Select
              fullWidth
              label="Service Type"
              error={showError && !values.serviceType}
              value={values.serviceType}
              onChange={setSelectedServiceType}
            >
              <MenuItem value={ServiceTypeEnum.LEAK}>
                {ServiceTypeEnum.LEAK}
              </MenuItem>
              <MenuItem value={ServiceTypeEnum.LIQUID_LEAK}>
                {ServiceTypeEnum.LIQUID_LEAK}
              </MenuItem>
              <MenuItem value={ServiceTypeEnum.HARD_HAT}>
                {ServiceTypeEnum.HARD_HAT}
              </MenuItem>
              <MenuItem value={ServiceTypeEnum.FIRE_SMOKE}>
                {ServiceTypeEnum.FIRE_SMOKE}
              </MenuItem>
              <MenuItem value={ServiceTypeEnum.TANK_LEVEL}>
                {ServiceTypeEnum.TANK_LEVEL}
              </MenuItem>
              <MenuItem value={ServiceTypeEnum.GATE_GUARD}>
                {ServiceTypeEnum.GATE_GUARD}
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <LocationIdSelect
            hasError={showError && values.locationId?.value === ""}
            disabled={disablePrefilledInputs}
            setDefault
            defaultValue={locationVariable?.value}
            location={values.locationId}
            setLocation={setLocation}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DeviceSelect
            disabled={disablePrefilledInputs}
            showError={showError && !values.deviceId}
            deviceId={values.deviceId}
            locationId={values.locationId?.value}
            onDeviceChange={setDevice}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <NodeSelect
            hasError={showError && !values.nodeId}
            nodeId={values.nodeId}
            disabled={disablePrefilledInputs}
            onNodeChange={setNode}
          />
        </Grid>
      </Grid>
      {showAdvancedSection && showAdvancedParameters && <GasLeakForm />}
    </>
  );
};

export default CreateServiceForm;
