import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useConfirm } from "material-ui-confirm";

import FullPageLoader from "../../../../../common/components/item/FullPageLoader";
import MainPaperWrapper from "../../../../../common/components/item/MainPaperWrapper";
import BreadcrumbNavigation from "../../../../../common/components/tabs/BreadcrumbNavigation";
import confirmDialogStyleOptions from "../../../../../common/helpers/confirmDialogParams";
import usePlateManagementNavigationRoute from "../../../../../common/hooks/usePlateManagementNavigationRoute";
import { RouteEnum } from "../../../../../common/models/enums";
import StyledLoadingButton from "../../../../../common/providers/theme/design-tokens/LoadingButton/StyledLoadingButton";
import {
  errorNotification,
  successNotification,
} from "../../../../../common/variables/notification";
import { useGetLicensePlateDetails } from "../hooks/useGetLicensePlate";
import { useUpdateLicensePlate } from "../hooks/useUpdateLicensePlate";
import { IPlateNumberForm } from "../variables/plateNumberData";
import PlateNumberForm from "./PlateNumberForm";

const EditPlateNumberPage = (): JSX.Element => {
  const navigate = useNavigate();
  const confirm = useConfirm();

  const { getNavigationRoute } = usePlateManagementNavigationRoute();

  const recentActivitiesNav = getNavigationRoute(
    RouteEnum.RecentActivities,
    RouteEnum.SensoryRecentActivities
  );

  const recentActivitiesPlatesManagementNav = getNavigationRoute(
    RouteEnum.RecentActivitiesPlatesManagement,
    RouteEnum.SensoryRecentActivitiesPlatesManagement
  );

  const gateGuardNav = getNavigationRoute(RouteEnum.Agg, RouteEnum.SensoryAgg);

  const [searchParams] = useSearchParams();

  const categoryId = searchParams.get("categoryId");
  const licensePlateId = searchParams.get("licensePlateId");

  const defaultValues = {
    plateNumber: "",
    companyName: "",
    driverName: "",
    category: {
      label: "",
      value: "",
    },
  };

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IPlateNumberForm>({ defaultValues });

  const { updateLicensePlate } = useUpdateLicensePlate();
  const { data, refetch } = useGetLicensePlateDetails({
    variables: {
      categoryId: categoryId ?? "",
      licensePlateId: licensePlateId ?? "",
    },
    skip: !licensePlateId || !categoryId,
  });

  useEffect(() => {
    if (licensePlateId && categoryId) {
      refetch();
    }
  }, [licensePlateId, categoryId, refetch]);

  useEffect(() => {
    if (data) {
      const licensePlate = data.getLicensePlate;

      setValue("category.value", licensePlate.categoryId);

      setValue("companyName", licensePlate.company ?? "");

      setValue("plateNumber", licensePlate.licensePlate);

      setValue("driverName", licensePlate.driverName ?? "");
    }
  }, [data]);

  const confirmCancel = (path = recentActivitiesPlatesManagementNav): void => {
    confirm({
      title: "Are you sure you want to cancel editing?",
      description: "If you leave, the new informations won’t be saved",
      confirmationText: "Leave",
      cancellationText: "Cancel",
      ...confirmDialogStyleOptions,
      confirmationButtonProps: {
        ...confirmDialogStyleOptions.confirmationButtonProps,
        color: "primary",
      },
    }).then((): void => {
      navigate(path);
    });
  };

  const handleCancel = (): void => {
    confirmCancel();
  };

  const onSubmit: SubmitHandler<IPlateNumberForm> = formData => {
    if (!data) return;

    const licensePlate = data.getLicensePlate;

    updateLicensePlate({
      licensePlateId: licensePlate.id,
      licensePlate: formData.plateNumber,
      categoryId: licensePlate.categoryId,
      newCategoryId: formData.category?.value ?? "",
      driverName: formData.driverName,
      company: formData.companyName,
    })
      .then((response): void => {
        if (!response.errors) {
          successNotification("Changes are saved");

          navigate(recentActivitiesPlatesManagementNav);
        }
      })
      .catch((error: Error): void => {
        errorNotification(error.message);

        console.error(error);
      });
  };

  if (!data) {
    return <FullPageLoader />;
  }

  const licensePlate = data.getLicensePlate;

  const breadcrumbItems = [
    {
      label: "Recent events",
      onClick: () => confirmCancel(recentActivitiesNav as RouteEnum),
    },
    {
      label: "Gate Guard events",
      onClick: () => confirmCancel(gateGuardNav as RouteEnum),
    },
    {
      label: "Plates Management",
      onClick: () => confirmCancel(),
    },
    {
      label: `Edit ${licensePlate.licensePlate}`,
    },
  ];

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <BreadcrumbNavigation items={breadcrumbItems} />

      <Typography variant="h3" sx={{ padding: "1em 0" }}>
        {`Edit ${licensePlate.licensePlate}`}
      </Typography>

      <MainPaperWrapper>
        <PlateNumberForm control={control} errors={errors} />
      </MainPaperWrapper>

      <Stack direction="row-reverse" spacing={2} sx={{ paddingTop: 3 }}>
        <StyledLoadingButton
          sx={{
            marginTop: "1.5em",
          }}
          type="submit"
          variant="contained"
          color="primary"
        >
          Save changes
        </StyledLoadingButton>

        <StyledLoadingButton
          sx={{
            marginTop: "1.5em",
          }}
          variant="outlined"
          color="inherit"
          onClick={handleCancel}
        >
          Back
        </StyledLoadingButton>
      </Stack>
    </form>
  );
};

export default EditPlateNumberPage;
