import ConstructionIcon from "@mui/icons-material/Construction";
import { Typography } from "@mui/material";

import RouterLink from "../../../../common/components/RouterLink";
import DataHubGridItem from "./DataHubGridItem";

const DeploymentHealthGridItem = (): JSX.Element => {
  return (
    <RouterLink to="/deployment-health">
      <DataHubGridItem
        sx={{
          height: { xs: "80px", sm: "120px", md: "120px", lg: "80px" },
        }}
      >
        <ConstructionIcon color="primary" />
        <Typography variant="body1">Deployment Health</Typography>
      </DataHubGridItem>
    </RouterLink>
  );
};

export default DeploymentHealthGridItem;
