import { gql, useMutation } from "@apollo/client";

import type {
  AddDeviceInput,
  AddDeviceMutation,
  AddDeviceMutationVariables,
} from "../../../API";
import { ADD_DEVICE } from "../../../common/operations/mutations";

export const useCreateDevice = () => {
  const [saveDevice, { data, loading }] = useMutation<
    AddDeviceMutation,
    AddDeviceMutationVariables
  >(ADD_DEVICE);

  const createDevice = async (input: AddDeviceInput) => {
    return await saveDevice({
      variables: {
        input,
      },
      update(cache, response) {
        cache.modify({
          fields: {
            getDevices(existing) {
              const newDeviceRef = cache.writeFragment({
                data: response.data?.addDevice,
                fragment: gql`
                  fragment NewDevice on Device {
                    customerId
                    deviceData
                    id
                    cameraIpAddress
                    locationId
                    locationName
                    makeModelId
                    name
                    nodeId
                    nodeName
                    status
                  }
                `,
              });

              if (!existing) {
                return response?.data;
              }

              return {
                ...existing,
                items: [...existing.items, newDeviceRef],
              };
            },
          },
        });
      },
    });
  };

  return { createDevice, data, loading };
};
