const AzureIcon = (): JSX.Element => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="8" fill="#F7F8FA" />
      <path
        d="M22.9111 34.7539C26.9352 34.0431 30.2589 33.4548 30.2971 33.4467L30.3665 33.432L26.5673 28.913C24.4778 26.4276 22.7682 24.3844 22.7682 24.3726C22.7682 24.3503 26.6911 13.5474 26.7132 13.5089C26.7205 13.4961 29.3902 18.1052 33.1847 24.6818C36.7371 30.8389 39.6659 35.9155 39.6931 35.963L39.7426 36.0495L27.6686 36.048L15.5945 36.0464L22.9111 34.7539ZM8.4209 33.3759C8.4209 33.3695 10.211 30.2619 12.399 26.4702L16.3771 19.5761L21.0131 15.6855C23.5629 13.5457 25.656 11.7923 25.6644 11.7892C25.6728 11.786 25.6392 11.8704 25.5899 11.9768C25.5405 12.0831 23.2751 16.942 20.5558 22.7743L15.6115 33.3784L12.0162 33.3829C10.0388 33.3854 8.4209 33.3822 8.4209 33.3759Z"
        fill="#0089D6"
      />
    </svg>
  );
};

export default AzureIcon;
