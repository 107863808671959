import { useState } from "react";

import { Box, Paper, Typography } from "@mui/material";
import isEqual from "lodash/isEqual";
import Chart from "react-apexcharts";

import { TimePeriod } from "../../../API";
import { apexChartsTooltipStyles } from "../../../common/components/apexchart/StyledChart";
import AllLocationsFilterDropdownContainer from "../../../common/components/locations/AllLocationsFilterDropdownContainer";
import CircularLoading from "../../../common/components/progress/CircularLoading";
import { AutocompleteOptionType } from "../../../common/models/autocomplete";
import { useLocationsFilterDropdown } from "../hooks/useLocationsFilterDropdown";
import usePPEInfractionsChart from "../hooks/usePPEInfractionsChart";
import StatisticsNoDataView from "./StatisticsNoDataView";
import TimePeriodFilter from "./TimePeriodFilter";

const PPEInfractionsContainer = (): JSX.Element => {
  const [activeTimePeriod, setActiveTimePeriod] = useState<TimePeriod>(
    TimePeriod.ONE_WEEK
  );

  const {
    location,
    handleLocationChange,
    tagLocations,
    handleTagLocationsChange,
  } = useLocationsFilterDropdown();

  const { series, options, loading, refetching, showNoData, refetchChartData } =
    usePPEInfractionsChart(
      activeTimePeriod,
      location?.value
        ? [location.value]
        : tagLocations?.map(l => l?.value ?? "") ?? null
    );

  const handleTimePeriodChange = (timePeriod: TimePeriod): void => {
    if (timePeriod !== activeTimePeriod) {
      setActiveTimePeriod(timePeriod);
    }
  };

  const setLocationFilter = (locationValue: AutocompleteOptionType): void => {
    if (locationValue?.value !== location?.value) {
      handleLocationChange(locationValue, activeTimePeriod, refetchChartData);
    }
  };

  const setTagFilter = (locations: AutocompleteOptionType[] | null): void => {
    if (!isEqual(locations, tagLocations)) {
      handleTagLocationsChange(locations, activeTimePeriod, refetchChartData);
    }
  };

  return (
    <Paper
      sx={{
        padding: "1.5em",
        width: "100%",
        height: "428px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          marginBottom: "1em",
        }}
      >
        <Typography variant="h5">Hard hat infractions</Typography>
        <Box
          sx={{
            display: "flex",
            marginLeft: "auto",
          }}
        >
          <TimePeriodFilter
            activePeriod={activeTimePeriod}
            setActivePeriod={handleTimePeriodChange}
          />
        </Box>
      </Box>
      <Box
        sx={{
          width: "180px",
        }}
      >
        <AllLocationsFilterDropdownContainer
          setLocation={setLocationFilter}
          setTagLocations={setTagFilter}
        />
      </Box>
      {(loading || refetching) && <CircularLoading />}
      {!loading && !showNoData && (
        <Box sx={apexChartsTooltipStyles}>
          <Chart options={options} series={series} height={300} />
        </Box>
      )}
      {showNoData && <StatisticsNoDataView />}
    </Paper>
  );
};

export default PPEInfractionsContainer;
