import { Outlet } from "react-router-dom";

import { Box } from "@mui/material";
import { useKeyboardEvent } from "./hooks/useKeyboardEvent";
import CommonTopBar from "./CommonTopBar";
import { Body } from "./styles";
import { TOP_BAR_HEIGHT } from "./MenuItem";
import { LeftSideBarSettingsType } from "./UnifiedLayout";
import LeftSideBar from "./LeftSideBar/LeftSideBar";

export const drawerWidth = 272;

export const drawerWidthClosed = 112;

interface BaseLayoutProps {
  open: boolean;
  onSetIsLeftSideBarOpen: () => void;
  showLeakFinderSettings?: boolean;
  showCustomerSelect?: boolean;
  leftSideBarSettings: LeftSideBarSettingsType;
}

export default function BaseLayout({
  open = false,
  onSetIsLeftSideBarOpen,
  leftSideBarSettings,
  showLeakFinderSettings = false,
  showCustomerSelect = false,
}: BaseLayoutProps) {
  useKeyboardEvent("[", () => {
    if (leftSideBarSettings.showLeftSideBar) {
      onSetIsLeftSideBarOpen();
    }
  });

  return (
    <Body>
      <CommonTopBar
        showCustomerSelect={showCustomerSelect}
        showLeakFinderSettings={showLeakFinderSettings}
      />

      {leftSideBarSettings.showLeftSideBar && (
        <LeftSideBar
          onSetIsLeftSideBarOpen={onSetIsLeftSideBarOpen}
          isLeftSideBarOpen={open}
          settings={leftSideBarSettings}
        />
      )}

      <Box
        component="main"
        sx={{
          flex: 1,
          paddingTop: `${TOP_BAR_HEIGHT}px`,
          paddingLeft: leftSideBarSettings.showLeftSideBar
            ? open
              ? `${drawerWidth}px`
              : `${drawerWidthClosed}px`
            : 0,
        }}
      >
        <Box sx={{ padding: "1.5em 2.5em" }}>
          <Outlet />
        </Box>
      </Box>
    </Body>
  );
}
