import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import { styled } from "@mui/material/styles";
import { CSSProperties } from "@mui/material/styles/createMixins";

import {
  MAIN_LOCATIONS_DROPDOWN_STORAGE_KEY,
  setLocationToLocalStorage,
} from "../../../helpers/getLocationFromLocalStorage";
import { AutocompleteOptionType } from "../../../models/autocomplete";
import { LeftMenuItems, RouteEnum } from "../../../models/enums";
import {
  selectedLocationDropdownVariable,
  selectedTagIdDropdownVariable,
  selectedTagLocationsDropdownVariable,
} from "../../../variables/selectedLocationDropdown";
import AIManagerMenuIcon from "../../icons/AIManagerMenuIcon";
import AIManagerMenuIconFilled from "../../icons/AIManagerMenuIconFilled";
import CustomerSettingsMenuIcon from "../../icons/CustomerSettingsMenuIcon";
import CustomerSettingsMenuIconFilled from "../../icons/CustomerSettingsMenuIconFilled";
import DataHubMenuIcon from "../../icons/DataHubMenuIcon";
import DeploymentHealthMenuIcon from "../../icons/DeploymentHealthMenuIcon";
import DeviceMenuIcon from "../../icons/DeviceMenuIcon";
import DeviceMenuIconFilled from "../../icons/DeviceMenuIconFilled";
import LiveViewMenuIcon from "../../icons/LiveViewMenuIcon";
import LiveViewMenuIconFilled from "../../icons/LiveViewMenuIconFilled";
import NodeMenuIcon from "../../icons/NodeMenuIcon";
import NodeMenuIconFilled from "../../icons/NodeMenuIconFilled";
import StatisticsMenuIcon from "../../icons/StatisticsMenuIcon";
import StatisticsMenuIconFilled from "../../icons/StatisticsMenuIconFilled";
import SystemSettingsMenuIcon from "../../icons/SystemSettingsMenuIcon";
import SystemSettingsMenuIconFilled from "../../icons/SystemSettingsMenuIconFilled";
import MenuItem from "../MenuItem";
import AllLocationsFilterDropdownContainer from "../../locations/AllLocationsFilterDropdownContainer";
import DataHubMenuIconFilled from "../../icons/DataHubMenuIconFilled";
import DeploymentHealthMenuIconFilled from "../../icons/DeploymentHealthMenuIconFilled";
import { useMemo } from "react";
import { LeftSideBarItem } from "../UnifiedLayout";

export const listItemButtonCssNoAddIcon = {
  "&.MuiListItemButton-root": {
    paddingBottom: 0,
    paddingTop: 0,
    marginTop: "4px",
    marginBottom: "4px",
  },
};

export const listItemTextCss = {
  fontWeight: 500,
};

export const ListStyled = styled(List)(
  (): CSSProperties => ({
    padding: "1.5em 2em",
    display: "flex",
    flexDirection: "column",
    height: "100%",
  })
);

export const ListItemStyled = styled(ListItem)(
  ({ theme }): CSSProperties => ({
    "&:hover": {
      backgroundColor: theme.palette.otherBackdropOverlay,
    },
  })
);
interface IProps {
  open: boolean;
}

export const ListItemButtonStyled = styled(ListItemButton, {
  shouldForwardProp: (propName: string): boolean => propName !== "open",
})<IProps>(
  ({ theme, open }): CSSProperties => ({
    height: "3em",
    padding: "0.5em",
    borderRadius: "33px",
    "&.MuiButtonBase-root.MuiListItemButton-root.MuiListItemButton-root": {
      paddingBottom: 0,
      paddingTop: 0,
      marginTop: 0,
      marginBottom: "4px",
    },
    "&:hover, &.Mui-selected": {
      borderRadius: "33px",
      padding: "0.5em",
      height: "3em",
      backgroundColor: open
        ? theme.palette.otherBackdropOverlay.main
        : "transparent",
      "& .defaultMenuIcon": {
        display: "none",
      },
      "& .filledMenuIcon": {
        display: "inline-flex",
      },
    },
    "&.Mui-selected": {
      backgroundColor: open
        ? theme.palette.otherBackdropOverlay.main
        : "transparent",
      "&:hover": {
        backgroundColor: open
          ? theme.palette.otherBackdropOverlay.main
          : "transparent",
      },
    },
  })
);

export const ListItemIconStyled = styled(ListItemIcon)(
  (): CSSProperties => ({
    minWidth: "32px",
    maxWidth: "32px",
    marginRight: "0.5em",
  })
);

export interface LeftSideBarBaseProps {
  open: boolean;
  settings: {
    showAllLocationsDropdown: boolean;
    leftSideBarItems: LeftSideBarItem[];
  };
}

const LeftSideBarBase = ({
  open,
  settings,
}: LeftSideBarBaseProps): JSX.Element => {
  const navigate = useNavigate();
  const pathname = window.location.pathname;

  const menuItems = useMemo(
    () => [
      {
        name: LeftMenuItems.DEVICES,
        icon: <DeviceMenuIcon />,
        iconFilled: <DeviceMenuIconFilled />,
      },
      {
        name: LeftMenuItems.AI_MANAGER,
        icon: <AIManagerMenuIcon />,
        iconFilled: <AIManagerMenuIconFilled />,
      },
      {
        name: LeftMenuItems.NODES,
        icon: <NodeMenuIcon />,
        iconFilled: <NodeMenuIconFilled />,
      },
      {
        name: LeftMenuItems.CUSTOMER_SETTINGS,
        icon: <CustomerSettingsMenuIcon />,
        iconFilled: <CustomerSettingsMenuIconFilled />,
      },
      {
        name: LeftMenuItems.RECENT_ACTIVITIES,
        icon: <DataHubMenuIcon />,
        iconFilled: <DataHubMenuIconFilled />,
      },
      {
        name: LeftMenuItems.LIVE_VIEW,
        icon: <LiveViewMenuIcon />,
        iconFilled: <LiveViewMenuIconFilled />,
      },
      {
        name: LeftMenuItems.DEPLOYMENT_HEALTH,
        icon: <DeploymentHealthMenuIcon />,
        iconFilled: <DeploymentHealthMenuIconFilled />,
      },
      {
        name: LeftMenuItems.STATISTICS,
        icon: <StatisticsMenuIcon />,
        iconFilled: <StatisticsMenuIconFilled />,
      },
      {
        name: LeftMenuItems.SYSTEM_SETTINGS,
        icon: <SystemSettingsMenuIcon />,
        iconFilled: <SystemSettingsMenuIconFilled />,
        showDivider: true,
      },
    ],
    []
  );

  const hideLocationDropdownCondition =
    pathname.includes(RouteEnum.SystemSettings) ||
    pathname.includes(RouteEnum.RecentActivities) ||
    pathname.includes(RouteEnum.DeploymentHealth) ||
    pathname.includes(RouteEnum.CustomerSettings) ||
    pathname.includes(RouteEnum.Statistics) ||
    pathname.startsWith("/manage-locations") ||
    pathname.includes("integrations") ||
    pathname.includes("/device/");

  const handleLocationChange = (
    locationValue: AutocompleteOptionType
  ): void => {
    selectedLocationDropdownVariable(locationValue);

    if (locationValue) {
      setLocationToLocalStorage(
        MAIN_LOCATIONS_DROPDOWN_STORAGE_KEY,
        locationValue
      );
    }
  };

  const handleTagLocationsChange = (
    tagLocations: AutocompleteOptionType[] | null
  ): void => {
    selectedTagLocationsDropdownVariable(tagLocations);
  };

  const handleTagIdChange = (tagId: string): void => {
    selectedTagIdDropdownVariable(tagId);
  };

  const locationsDropdownDisplayValue = hideLocationDropdownCondition
    ? "none"
    : "block";

  return (
    <>
      {settings.showAllLocationsDropdown && (
        <>
          <Box
            sx={{
              margin: "10px auto",
              minWidth: "220px",
              display: locationsDropdownDisplayValue,
            }}
          >
            <AllLocationsFilterDropdownContainer
              disableClearable
              removeBorder
              enhancedCapabilities
              menuOpened={open}
              setLocation={handleLocationChange}
              setTagLocations={handleTagLocationsChange}
              setTagId={handleTagIdChange}
            />
          </Box>
          <Divider flexItem />
        </>
      )}

      <ListStyled>
        {menuItems.map(item => {
          const roleBasedItem = settings.leftSideBarItems.find(
            roleItem => roleItem.name === item.name
          );

          const isPathCommon = pathname === RouteEnum.LiveView;

          let isMenuSelected =
            roleBasedItem && pathname.startsWith(roleBasedItem.path);

          if (roleBasedItem && isPathCommon) {
            isMenuSelected = pathname === roleBasedItem.path;
          }

          return (
            <>
              {roleBasedItem && (
                <>
                  {roleBasedItem.showDivider && (
                    <Divider
                      flexItem
                      component="li"
                      sx={{
                        marginBottom: "4px",
                      }}
                    />
                  )}

                  <MenuItem
                    key={item.name}
                    onClick={() => navigate(roleBasedItem.path)}
                    label={item.name}
                    icon={item.icon}
                    iconFilled={item.iconFilled}
                    selected={!!isMenuSelected}
                    open={open}
                    listItemStyledProps={{
                      sx: {
                        marginTop: roleBasedItem.isSeparatorOfBottomSection
                          ? "auto"
                          : 0,
                      },
                    }}
                  />
                </>
              )}
            </>
          );
        })}
      </ListStyled>
    </>
  );
};

export default LeftSideBarBase;
