import TextField from "@mui/material/TextField";
import { Control, Controller, FieldErrors } from "react-hook-form";

import { IAlertForm } from "../CreateAlertContainer";

interface ICustomerAlertFormProps {
  control: Control<IAlertForm>;
  errors: FieldErrors<IAlertForm>;
}

const CustomerAlertForm = ({ control, errors }: ICustomerAlertFormProps) => {
  return (
    <Controller
      name="alertName"
      control={control}
      rules={{
        required: "This field is required",
      }}
      render={({ field }) => (
        <TextField
          {...field}
          id="alertName"
          label="Alert name"
          fullWidth
          error={!!errors.alertName}
          helperText={errors.alertName ? errors.alertName.message : ""}
        />
      )}
    />
  );
};

export default CustomerAlertForm;
