import { Outlet, useLocation, useParams } from "react-router-dom";

import { Box, Stack } from "@mui/material";

import { ModelType } from "../../API";
import BreadcrumbNavigation from "../../common/components/tabs/BreadcrumbNavigation";
import { useGetNonAdminAccess } from "../../common/hooks/useGetNonAdminAccess";
import { RouteEnum } from "../../common/models/enums";
import { ServiceTypeEnum } from "../model-manager/variables/modelManager";
import DataHubPageTitle from "./components/DataHubPageTitle";

export const serviceTitleByType = {
  [ModelType.gasleak]: ServiceTypeEnum.LEAK,
  [ModelType.liquidleak]: ServiceTypeEnum.LIQUID_LEAK,
  [ModelType.hardhat]: ServiceTypeEnum.HARD_HAT,
  [ModelType.fire]: ServiceTypeEnum.FIRE,
  [ModelType.smoke]: ServiceTypeEnum.SMOKE,
  [ModelType.tlm]: ServiceTypeEnum.TANK_LEVEL,
  [ModelType.agg]: ServiceTypeEnum.GATE_GUARD,
};

const DataHubPage = (): JSX.Element => {
  const { pathname } = useLocation();
  const { model } = useParams();
  let modelName = serviceTitleByType[model as ModelType];

  const { nonAdminView } = useGetNonAdminAccess();

  const showPageTitle =
    pathname.includes(RouteEnum.GasLeak) ||
    pathname.includes(RouteEnum.FireSmoke) ||
    pathname.includes(RouteEnum.Fire) ||
    pathname.includes(RouteEnum.Smoke) ||
    pathname.includes(RouteEnum.HardHat) ||
    pathname.includes(RouteEnum.Agg) ||
    pathname.includes(RouteEnum.Tlm) ||
    pathname.includes(RouteEnum.LiquidLeak);

  if (modelName === ServiceTypeEnum.LEAK) {
    modelName = ServiceTypeEnum.GAS_LEAK;
  }

  const breadcrumbItems = [
    {
      label: "Recent events",
      path: nonAdminView
        ? RouteEnum.SensoryRecentActivities
        : RouteEnum.RecentActivities,
    },
    {
      label: modelName,
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: ".5em",
      }}
    >
      {showPageTitle && (
        <>
          <Stack spacing={0.5} direction="row" alignItems="center">
            <BreadcrumbNavigation items={breadcrumbItems} />
          </Stack>
          <DataHubPageTitle />
        </>
      )}
      <Outlet />
    </Box>
  );
};

export default DataHubPage;
