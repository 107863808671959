import { FC } from "react";

import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";

import CodeSnippetBox from "../box/CodeSnippetBox";
import InstructionList from "./InstructionList";

interface WrapperBoxProps {
  nodeScript: string;
  instructions: string[];
  notify: () => void;
}

const InstructionWrapperBox: FC<WrapperBoxProps> = ({
  nodeScript,
  notify,
  instructions,
}): JSX.Element => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        borderRadius: "0.5em",
        padding: "1em",
        gap: "1em",
        border: `1px solid ${theme.palette.otherOutlineBorder.main}`,
        backgroundColor: theme.palette.otherBackground.main,
      }}
    >
      <InstructionList
        instructions={instructions}
        indexOfChild={1}
        anotherChildren={
          <CodeSnippetBox nodeScript={nodeScript} notify={notify} />
        }
      />
    </Box>
  );
};

export default InstructionWrapperBox;
