/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const addAlertEmailNotification = /* GraphQL */ `
  mutation AddAlertEmailNotification($input: addAlertEmailNotificationInput) {
    addAlertEmailNotification(input: $input) {
      alertName
      alertType
      createdBy
      customService
      email
      id
      jsonObject
      location
      locationTag
      model
      node
      notificationSettingsJson
      phoneNumber
      subscribedLevelEntity
      tag
    }
  }
`;
export const addAlertSubscription = /* GraphQL */ `
  mutation AddAlertSubscription($input: AddAlertSubscriptionInput!) {
    addAlertSubscription(input: $input) {
      email
      entity
    }
  }
`;
export const addAlertWildCardSubscription = /* GraphQL */ `
  mutation AddAlertWildCardSubscription(
    $input: AddAlertWildCardSubscriptionInput!
  ) {
    addAlertWildCardSubscription(input: $input) {
      email
      entity
      wildCard
    }
  }
`;
export const addAnnotation = /* GraphQL */ `
  mutation AddAnnotation($input: AnnotationInput!) {
    addAnnotation(input: $input) {
      annotateTanks
      annotationType
      bottomHidden
      deviceId
      equipmentType
      id
      name
      polygon
      serviceId
      tankHeight
      tankNumber
      zoneId
    }
  }
`;
export const addCategory = /* GraphQL */ `
  mutation AddCategory($input: AddCategoryInput!) {
    addCategory(input: $input) {
      categoryName
      id
      isAlertEnabled
    }
  }
`;
export const addClient = /* GraphQL */ `
  mutation AddClient($input: AddClientInput!) {
    addClient(input: $input) {
      clientName
      customerId
      id
    }
  }
`;
export const addClientGroup = /* GraphQL */ `
  mutation AddClientGroup($input: AddClientGroupInput) {
    addClientGroup(input: $input) {
      clientId
      createdAt
      customerId
      groupName
      id
    }
  }
`;
export const addCustomerDTObject = /* GraphQL */ `
  mutation AddCustomerDTObject($input: DTObjectInput!) {
    addCustomerDTObject(input: $input) {
      filesInfo {
        contentType
        id
        key
        originalFileName
        useType
      }
      id
      isCustomerObject
      objectId
      objectManufacturerName
      objectName
      objectType
      version
    }
  }
`;
export const addDTObject = /* GraphQL */ `
  mutation AddDTObject($input: DTObjectInput!) {
    addDTObject(input: $input) {
      filesInfo {
        contentType
        id
        key
        originalFileName
        useType
      }
      id
      isCustomerObject
      objectId
      objectManufacturerName
      objectName
      objectType
      version
    }
  }
`;
export const addDTProject = /* GraphQL */ `
  mutation AddDTProject($input: AddDTProjectInput!) {
    addDTProject(input: $input) {
      configURL
      customerId
      displayName
      id
      kmzLayerURL
      lastModificationDate
      lastModifierUserName
      measurementSystem
      projectId
      withKMZLayer
    }
  }
`;
export const addDevice = /* GraphQL */ `
  mutation AddDevice($input: AddDeviceInput!) {
    addDevice(input: $input) {
      cameraIpAddress
      customerId
      deviceData {
        hasPanTilt
        hasRecordVideo
        hasZoom
        keyFrame
        panTiltIP
        protocol
        recentFrame
        rtspHost
        sourceVideo
      }
      healthData {
        isOnline
        lastAlive
        totalDowntime
        totalUptime
        uptimeDate
        uptimePercent
      }
      id
      locationId
      locationName
      makeModelId
      name
      nodeId
      nodeName
      status
    }
  }
`;
export const addDeviceMake = /* GraphQL */ `
  mutation AddDeviceMake($input: AddDeviceMakeInput!) {
    addDeviceMake(input: $input) {
      defaultUserPwd
      deviceTypeShortName
      hasPanTilt
      hasZoom
      id
      model
      modelsToRun
      name
      rtspTemplate
      type
    }
  }
`;
export const addDomain = /* GraphQL */ `
  mutation AddDomain($customerId: String!, $domain: String!) {
    addDomain(customerId: $customerId, domain: $domain) {
      customerId
    }
  }
`;
export const addLicensePlate = /* GraphQL */ `
  mutation AddLicensePlate($input: AddLicensePlateInput!) {
    addLicensePlate(input: $input) {
      categoryId
      company
      driverName
      id
      licensePlate
    }
  }
`;
export const addLocation = /* GraphQL */ `
  mutation AddLocation($input: LocationInput!) {
    addLocation(input: $input) {
      devices {
        cameraIpAddress
        customerId
        deviceData {
          hasPanTilt
          hasRecordVideo
          hasZoom
          keyFrame
          panTiltIP
          protocol
          recentFrame
          rtspHost
          sourceVideo
        }
        healthData {
          isOnline
          lastAlive
          totalDowntime
          totalUptime
          uptimeDate
          uptimePercent
        }
        id
        locationId
        locationName
        makeModelId
        name
        nodeId
        nodeName
        status
      }
      id
      locationData
      name
      referenceId
      tags {
        associationId
        id
        tagName
      }
      timeZone
    }
  }
`;
export const addNode = /* GraphQL */ `
  mutation AddNode($input: AddNodeInput!) {
    addNode(input: $input) {
      customerId
      deviceData
      devices {
        cameraIpAddress
        customerId
        deviceData {
          hasPanTilt
          hasRecordVideo
          hasZoom
          keyFrame
          panTiltIP
          protocol
          recentFrame
          rtspHost
          sourceVideo
        }
        healthData {
          isOnline
          lastAlive
          totalDowntime
          totalUptime
          uptimeDate
          uptimePercent
        }
        id
        locationId
        locationName
        makeModelId
        name
        nodeId
        nodeName
        status
      }
      id
      isOnline
      level
      locationId
      nodeIP
      nodeId
      nodeName
      onboardCommand
      referenceId
      serviceData
      tags {
        id
        tagName
      }
    }
  }
`;
export const addService = /* GraphQL */ `
  mutation AddService($input: AddServiceInput!) {
    addService(input: $input) {
      configuration
      customerId
      deviceId
      healthData
      id
      isRunning
      locationId
      nodeId
      serviceType
    }
  }
`;
export const addTag = /* GraphQL */ `
  mutation AddTag($input: AddTagInput!) {
    addTag(input: $input) {
      id
      tagName
    }
  }
`;
export const addZone = /* GraphQL */ `
  mutation AddZone($input: AddZoneInput!) {
    addZone(input: $input) {
      alertData
      deviceId
      exclusions
      id
      inspectTime
      keyFrame
      name
      nodeId
      pan
      serviceId
      shouldNotify
      status
      threshold {
        id
        name
        value
      }
      tilt
      zoom
    }
  }
`;
export const addZoneThreshold = /* GraphQL */ `
  mutation AddZoneThreshold($input: AddZoneThresholdInput!) {
    addZoneThreshold(input: $input) {
      id
      name
      value
    }
  }
`;
export const assignFirstRoleToUser = /* GraphQL */ `
  mutation AssignFirstRoleToUser(
    $customerId: String!
    $groupId: String!
    $roleId: String!
    $userId: String!
    $userPoolId: String
  ) {
    assignFirstRoleToUser(
      customerId: $customerId
      groupId: $groupId
      roleId: $roleId
      userId: $userId
      userPoolId: $userPoolId
    ) {
      customer_id
      email
      externalId
      firstName
      id
      lastName
      userSettings
      user_role
    }
  }
`;
export const batchAddCategory = /* GraphQL */ `
  mutation BatchAddCategory($input: [AddCategoryInput!]!) {
    batchAddCategory(input: $input) {
      categoryName
      id
      isAlertEnabled
    }
  }
`;
export const batchAddLicensePlate = /* GraphQL */ `
  mutation BatchAddLicensePlate($input: [AddLicensePlateInput!]!) {
    batchAddLicensePlate(input: $input) {
      categoryId
      company
      driverName
      id
      licensePlate
    }
  }
`;
export const batchAddTagAssociations = /* GraphQL */ `
  mutation BatchAddTagAssociations($input: BatchAddTagAssociationsInput) {
    batchAddTagAssociations(input: $input) {
      associationId
      id
      tagName
    }
  }
`;
export const batchAddVideos = /* GraphQL */ `
  mutation BatchAddVideos($input: AddVideosInput) {
    batchAddVideos(input: $input) {
      annotations
      clientId
      customerId
      fov
      geometry
      groupId
      id
      isVideoProcessed
      modelOutputS3Key
      notes
      outputMeasurement
      sourceS3Key
      videoMethod
      videoName
      videoState
      windspeed
    }
  }
`;
export const batchDeleteClientGroups = /* GraphQL */ `
  mutation BatchDeleteClientGroups($input: [BatchDeleteClientGroupsInput!]!) {
    batchDeleteClientGroups(input: $input) {
      clientId
      createdAt
      customerId
      groupName
      id
    }
  }
`;
export const batchDeleteVideos = /* GraphQL */ `
  mutation BatchDeleteVideos($input: [BatchDeleteVideosInput!]!) {
    batchDeleteVideos(input: $input) {
      groupId
      id
    }
  }
`;
export const changeNodeStatus = /* GraphQL */ `
  mutation ChangeNodeStatus($input: ChangeNodeStatusInput!) {
    changeNodeStatus(input: $input)
  }
`;
export const deactivateNodeOnboardScript = /* GraphQL */ `
  mutation DeactivateNodeOnboardScript($locationId: String!, $nodeId: String!) {
    deactivateNodeOnboardScript(locationId: $locationId, nodeId: $nodeId) {
      customerId
      deviceData
      devices {
        cameraIpAddress
        customerId
        deviceData {
          hasPanTilt
          hasRecordVideo
          hasZoom
          keyFrame
          panTiltIP
          protocol
          recentFrame
          rtspHost
          sourceVideo
        }
        healthData {
          isOnline
          lastAlive
          totalDowntime
          totalUptime
          uptimeDate
          uptimePercent
        }
        id
        locationId
        locationName
        makeModelId
        name
        nodeId
        nodeName
        status
      }
      id
      isOnline
      level
      locationId
      nodeIP
      nodeId
      nodeName
      onboardCommand
      referenceId
      serviceData
      tags {
        id
        tagName
      }
    }
  }
`;
export const deleteAlert = /* GraphQL */ `
  mutation DeleteAlert($input: DeleteAlertInput!) {
    deleteAlert(input: $input) {
      alertName
      alertType
      createdBy
      customService
      email
      id
      jsonObject
      location
      locationTag
      model
      node
      notificationSettingsJson
      phoneNumber
      subscribedLevelEntity
      tag
    }
  }
`;
export const deleteAllAnnotations = /* GraphQL */ `
  mutation DeleteAllAnnotations($input: DeleteAllAnnotationsInput!) {
    deleteAllAnnotations(input: $input) {
      id
      zoneId
    }
  }
`;
export const deleteAnnotation = /* GraphQL */ `
  mutation DeleteAnnotation($input: DeleteAnnotationInput!) {
    deleteAnnotation(input: $input) {
      annotateTanks
      annotationType
      bottomHidden
      deviceId
      equipmentType
      id
      name
      polygon
      serviceId
      tankHeight
      tankNumber
      zoneId
    }
  }
`;
export const deleteCategory = /* GraphQL */ `
  mutation DeleteCategory($input: DeleteCategory!) {
    deleteCategory(input: $input) {
      categoryName
      id
      isAlertEnabled
    }
  }
`;
export const deleteClient = /* GraphQL */ `
  mutation DeleteClient($input: DeleteClientInput!) {
    deleteClient(input: $input) {
      clientName
      customerId
      id
    }
  }
`;
export const deleteClientGroup = /* GraphQL */ `
  mutation DeleteClientGroup($input: DeleteClientGroupInput) {
    deleteClientGroup(input: $input) {
      clientId
      createdAt
      customerId
      groupName
      id
    }
  }
`;
export const deleteCustomerDTObject = /* GraphQL */ `
  mutation DeleteCustomerDTObject($input: DeleteDTObjectInput!) {
    deleteCustomerDTObject(input: $input) {
      filesInfo {
        contentType
        id
        key
        originalFileName
        useType
      }
      id
      isCustomerObject
      objectId
      objectManufacturerName
      objectName
      objectType
      version
    }
  }
`;
export const deleteDTProject = /* GraphQL */ `
  mutation DeleteDTProject($input: DeleteDTProjectInput!) {
    deleteDTProject(input: $input) {
      configURL
      customerId
      displayName
      id
      kmzLayerURL
      lastModificationDate
      lastModifierUserName
      measurementSystem
      projectId
      withKMZLayer
    }
  }
`;
export const deleteDevice = /* GraphQL */ `
  mutation DeleteDevice($customerId: String!, $deviceId: String!) {
    deleteDevice(customerId: $customerId, deviceId: $deviceId)
  }
`;
export const deleteDeviceMake = /* GraphQL */ `
  mutation DeleteDeviceMake($id: String!) {
    deleteDeviceMake(id: $id)
  }
`;
export const deleteLicensePlate = /* GraphQL */ `
  mutation DeleteLicensePlate($input: DeleteLicensePlate!) {
    deleteLicensePlate(input: $input) {
      categoryId
      company
      driverName
      id
      licensePlate
    }
  }
`;
export const deleteLocation = /* GraphQL */ `
  mutation DeleteLocation($customerId: String!, $locationId: String!) {
    deleteLocation(customerId: $customerId, locationId: $locationId)
  }
`;
export const deleteNode = /* GraphQL */ `
  mutation DeleteNode($nodeId: String!) {
    deleteNode(nodeId: $nodeId) {
      customerId
      deviceData
      devices {
        cameraIpAddress
        customerId
        deviceData {
          hasPanTilt
          hasRecordVideo
          hasZoom
          keyFrame
          panTiltIP
          protocol
          recentFrame
          rtspHost
          sourceVideo
        }
        healthData {
          isOnline
          lastAlive
          totalDowntime
          totalUptime
          uptimeDate
          uptimePercent
        }
        id
        locationId
        locationName
        makeModelId
        name
        nodeId
        nodeName
        status
      }
      id
      isOnline
      level
      locationId
      nodeIP
      nodeId
      nodeName
      onboardCommand
      referenceId
      serviceData
      tags {
        id
        tagName
      }
    }
  }
`;
export const deleteService = /* GraphQL */ `
  mutation DeleteService($input: DeleteServiceInput!) {
    deleteService(input: $input) {
      configuration
      customerId
      deviceId
      healthData
      id
      isRunning
      locationId
      nodeId
      serviceType
    }
  }
`;
export const deleteTag = /* GraphQL */ `
  mutation DeleteTag($input: DeleteTagInput!) {
    deleteTag(input: $input) {
      id
      tagName
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $customerId: String!
    $userId: String!
    $userPoolId: String!
  ) {
    deleteUser(
      customerId: $customerId
      userId: $userId
      userPoolId: $userPoolId
    ) {
      customer_id
      email
      externalId
      firstName
      id
      lastName
      userSettings
      user_role
    }
  }
`;
export const deleteVideo = /* GraphQL */ `
  mutation DeleteVideo($input: DeleteVideoInput!) {
    deleteVideo(input: $input) {
      annotations
      clientId
      customerId
      fov
      geometry
      groupId
      id
      isVideoProcessed
      modelOutputS3Key
      notes
      outputMeasurement
      sourceS3Key
      videoMethod
      videoName
      videoState
      windspeed
    }
  }
`;
export const deleteZone = /* GraphQL */ `
  mutation DeleteZone($input: DeleteZoneInput!) {
    deleteZone(input: $input) {
      alertData
      deviceId
      exclusions
      id
      inspectTime
      keyFrame
      name
      nodeId
      pan
      serviceId
      shouldNotify
      status
      threshold {
        id
        name
        value
      }
      tilt
      zoom
    }
  }
`;
export const deleteZoneThreshold = /* GraphQL */ `
  mutation DeleteZoneThreshold($id: ID!) {
    deleteZoneThreshold(id: $id) {
      id
      name
      value
    }
  }
`;
export const edgeClientMessenger = /* GraphQL */ `
  mutation EdgeClientMessenger($message: EdgeClientMessageInput) {
    edgeClientMessenger(message: $message) {
      id
      payload
      payloadType
      statusCode
      timestamp
      topic
    }
  }
`;
export const overruleHumanValidatedEvents = /* GraphQL */ `
  mutation OverruleHumanValidatedEvents($input: ValidateEventInput!) {
    overruleHumanValidatedEvents(input: $input) {
      audited_by
      auditorsExplanation
      customerId
      data
      eventTime
      explanation
      id
      locationId
      nodeId
      serviceId
      transitionInfo
      validated_by
      validationState
    }
  }
`;
export const publishNode = /* GraphQL */ `
  mutation PublishNode($input: publishNodeInput!) {
    publishNode(input: $input) {
      message
      nodeId
    }
  }
`;
export const sendToListenToHumanValidationEvents = /* GraphQL */ `
  mutation SendToListenToHumanValidationEvents(
    $humanValidationEvent: AWSJSON!
  ) {
    sendToListenToHumanValidationEvents(
      humanValidationEvent: $humanValidationEvent
    ) {
      audited_by
      auditorsExplanation
      customerId
      data
      eventTime
      explanation
      id
      locationId
      nodeId
      serviceId
      transitionInfo
      validated_by
      validationState
    }
  }
`;
export const sendToListenToNodeFiles = /* GraphQL */ `
  mutation SendToListenToNodeFiles($nodeFile: AWSJSON!) {
    sendToListenToNodeFiles(nodeFile: $nodeFile) {
      downloadURL
      file
      internalNodeId
    }
  }
`;
export const sendToListenToNodeMessages = /* GraphQL */ `
  mutation SendToListenToNodeMessages($nodeMessage: AWSJSON!) {
    sendToListenToNodeMessages(nodeMessage: $nodeMessage) {
      internalNodeId
      message
    }
  }
`;
export const setIsRunningServiceStatus = /* GraphQL */ `
  mutation SetIsRunningServiceStatus($input: SetServiceStatusInput!) {
    setIsRunningServiceStatus(input: $input) {
      configuration
      customerId
      deviceId
      healthData
      id
      isRunning
      locationId
      nodeId
      serviceType
    }
  }
`;
export const setPendingState = /* GraphQL */ `
  mutation SetPendingState($customerId: String, $eventId: String) {
    setPendingState(customerId: $customerId, eventId: $eventId) {
      audited_by
      auditorsExplanation
      customerId
      data
      eventTime
      explanation
      id
      locationId
      nodeId
      serviceId
      transitionInfo
      validated_by
      validationState
    }
  }
`;
export const syncShadow = /* GraphQL */ `
  mutation SyncShadow($nodeId: String!) {
    syncShadow(nodeId: $nodeId) {
      customerId
      deviceData
      devices {
        cameraIpAddress
        customerId
        deviceData {
          hasPanTilt
          hasRecordVideo
          hasZoom
          keyFrame
          panTiltIP
          protocol
          recentFrame
          rtspHost
          sourceVideo
        }
        healthData {
          isOnline
          lastAlive
          totalDowntime
          totalUptime
          uptimeDate
          uptimePercent
        }
        id
        locationId
        locationName
        makeModelId
        name
        nodeId
        nodeName
        status
      }
      id
      isOnline
      level
      locationId
      nodeIP
      nodeId
      nodeName
      onboardCommand
      referenceId
      serviceData
      tags {
        id
        tagName
      }
    }
  }
`;
export const takeEventToAudit = /* GraphQL */ `
  mutation TakeEventToAudit($customerId: String, $eventId: String) {
    takeEventToAudit(customerId: $customerId, eventId: $eventId) {
      audited_by
      auditorsExplanation
      customerId
      data
      eventTime
      explanation
      id
      locationId
      nodeId
      serviceId
      transitionInfo
      validated_by
      validationState
    }
  }
`;
export const takeEventToValidate = /* GraphQL */ `
  mutation TakeEventToValidate($customerId: String, $eventId: String) {
    takeEventToValidate(customerId: $customerId, eventId: $eventId) {
      audited_by
      auditorsExplanation
      customerId
      data
      eventTime
      explanation
      id
      locationId
      nodeId
      serviceId
      transitionInfo
      validated_by
      validationState
    }
  }
`;
export const updateAdminEmailAlert = /* GraphQL */ `
  mutation UpdateAdminEmailAlert($input: UpdateAdminEmailAlertInput!) {
    updateAdminEmailAlert(input: $input) {
      alertName
      alertType
      createdBy
      customService
      email
      id
      jsonObject
      location
      locationTag
      model
      node
      notificationSettingsJson
      phoneNumber
      subscribedLevelEntity
      tag
    }
  }
`;
export const updateAdminSystemAlert = /* GraphQL */ `
  mutation UpdateAdminSystemAlert($input: UpdateAdminSystemAlertInput!) {
    updateAdminSystemAlert(input: $input) {
      alertName
      alertType
      createdBy
      customService
      email
      id
      jsonObject
      location
      locationTag
      model
      node
      notificationSettingsJson
      phoneNumber
      subscribedLevelEntity
      tag
    }
  }
`;
export const updateAlert = /* GraphQL */ `
  mutation UpdateAlert($input: UpdateAlertInput!) {
    updateAlert(input: $input) {
      alertName
      alertType
      createdBy
      customService
      email
      id
      jsonObject
      location
      locationTag
      model
      node
      notificationSettingsJson
      phoneNumber
      subscribedLevelEntity
      tag
    }
  }
`;
export const updateAlertStatus = /* GraphQL */ `
  mutation UpdateAlertStatus($input: UpdateAlertStatusInput!) {
    updateAlertStatus(input: $input) {
      alertName
      alertType
      createdBy
      customService
      email
      id
      jsonObject
      location
      locationTag
      model
      node
      notificationSettingsJson
      phoneNumber
      subscribedLevelEntity
      tag
    }
  }
`;
export const updateAnnotation = /* GraphQL */ `
  mutation UpdateAnnotation($input: AnnotationInput!) {
    updateAnnotation(input: $input) {
      annotateTanks
      annotationType
      bottomHidden
      deviceId
      equipmentType
      id
      name
      polygon
      serviceId
      tankHeight
      tankNumber
      zoneId
    }
  }
`;
export const updateCategory = /* GraphQL */ `
  mutation UpdateCategory($input: UpdateCategory!) {
    updateCategory(input: $input) {
      categoryName
      id
      isAlertEnabled
    }
  }
`;
export const updateClient = /* GraphQL */ `
  mutation UpdateClient($input: UpdateClientInput!) {
    updateClient(input: $input) {
      clientName
      customerId
      id
    }
  }
`;
export const updateClientGroup = /* GraphQL */ `
  mutation UpdateClientGroup($input: UpdateClientGroupInput) {
    updateClientGroup(input: $input) {
      clientId
      createdAt
      customerId
      groupName
      id
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer($input: UpdateCustomerInput) {
    updateCustomer(input: $input) {
      aggAlertingData
      allowLeakFinder
      auditCustomers
      availableApps
      configuration
      domains
      externalId
      id
      name
      pointOfContact
      totalLeakFinderLimit
      usageLeakFinderCount
    }
  }
`;
export const updateCustomerDTObject = /* GraphQL */ `
  mutation UpdateCustomerDTObject($input: DTObjectInput!) {
    updateCustomerDTObject(input: $input) {
      filesInfo {
        contentType
        id
        key
        originalFileName
        useType
      }
      id
      isCustomerObject
      objectId
      objectManufacturerName
      objectName
      objectType
      version
    }
  }
`;
export const updateDTProject = /* GraphQL */ `
  mutation UpdateDTProject($input: UpdateDTProjectInput!) {
    updateDTProject(input: $input) {
      configURL
      customerId
      displayName
      id
      kmzLayerURL
      lastModificationDate
      lastModifierUserName
      measurementSystem
      projectId
      withKMZLayer
    }
  }
`;
export const updateDevice = /* GraphQL */ `
  mutation UpdateDevice($input: UpdateDeviceInput!) {
    updateDevice(input: $input) {
      cameraIpAddress
      customerId
      deviceData {
        hasPanTilt
        hasRecordVideo
        hasZoom
        keyFrame
        panTiltIP
        protocol
        recentFrame
        rtspHost
        sourceVideo
      }
      healthData {
        isOnline
        lastAlive
        totalDowntime
        totalUptime
        uptimeDate
        uptimePercent
      }
      id
      locationId
      locationName
      makeModelId
      name
      nodeId
      nodeName
      status
    }
  }
`;
export const updateDeviceHealth = /* GraphQL */ `
  mutation UpdateDeviceHealth(
    $deviceId: String!
    $isOnline: Boolean
    $locationId: String!
  ) {
    updateDeviceHealth(
      deviceId: $deviceId
      isOnline: $isOnline
      locationId: $locationId
    ) {
      cameraIpAddress
      customerId
      deviceData {
        hasPanTilt
        hasRecordVideo
        hasZoom
        keyFrame
        panTiltIP
        protocol
        recentFrame
        rtspHost
        sourceVideo
      }
      healthData {
        isOnline
        lastAlive
        totalDowntime
        totalUptime
        uptimeDate
        uptimePercent
      }
      id
      locationId
      locationName
      makeModelId
      name
      nodeId
      nodeName
      status
    }
  }
`;
export const updateDeviceMake = /* GraphQL */ `
  mutation UpdateDeviceMake($input: UpdateDeviceMakeInput!) {
    updateDeviceMake(input: $input) {
      defaultUserPwd
      deviceTypeShortName
      hasPanTilt
      hasZoom
      id
      model
      modelsToRun
      name
      rtspTemplate
      type
    }
  }
`;
export const updateLicensePlateWithNewCategory = /* GraphQL */ `
  mutation UpdateLicensePlateWithNewCategory($input: UpdateLicensePlate!) {
    updateLicensePlateWithNewCategory(input: $input) {
      categoryId
      company
      driverName
      id
      licensePlate
    }
  }
`;
export const updateLocation = /* GraphQL */ `
  mutation UpdateLocation($input: UpdateLocationInput!) {
    updateLocation(input: $input) {
      devices {
        cameraIpAddress
        customerId
        deviceData {
          hasPanTilt
          hasRecordVideo
          hasZoom
          keyFrame
          panTiltIP
          protocol
          recentFrame
          rtspHost
          sourceVideo
        }
        healthData {
          isOnline
          lastAlive
          totalDowntime
          totalUptime
          uptimeDate
          uptimePercent
        }
        id
        locationId
        locationName
        makeModelId
        name
        nodeId
        nodeName
        status
      }
      id
      locationData
      name
      referenceId
      tags {
        associationId
        id
        tagName
      }
      timeZone
    }
  }
`;
export const updateNode = /* GraphQL */ `
  mutation UpdateNode($input: UpdateNodeInput!) {
    updateNode(input: $input) {
      customerId
      deviceData
      devices {
        cameraIpAddress
        customerId
        deviceData {
          hasPanTilt
          hasRecordVideo
          hasZoom
          keyFrame
          panTiltIP
          protocol
          recentFrame
          rtspHost
          sourceVideo
        }
        healthData {
          isOnline
          lastAlive
          totalDowntime
          totalUptime
          uptimeDate
          uptimePercent
        }
        id
        locationId
        locationName
        makeModelId
        name
        nodeId
        nodeName
        status
      }
      id
      isOnline
      level
      locationId
      nodeIP
      nodeId
      nodeName
      onboardCommand
      referenceId
      serviceData
      tags {
        id
        tagName
      }
    }
  }
`;
export const updateNodeOnlineStatus = /* GraphQL */ `
  mutation UpdateNodeOnlineStatus($isOnline: Boolean, $nodeId: String!) {
    updateNodeOnlineStatus(isOnline: $isOnline, nodeId: $nodeId) {
      customerId
      deviceData
      devices {
        cameraIpAddress
        customerId
        deviceData {
          hasPanTilt
          hasRecordVideo
          hasZoom
          keyFrame
          panTiltIP
          protocol
          recentFrame
          rtspHost
          sourceVideo
        }
        healthData {
          isOnline
          lastAlive
          totalDowntime
          totalUptime
          uptimeDate
          uptimePercent
        }
        id
        locationId
        locationName
        makeModelId
        name
        nodeId
        nodeName
        status
      }
      id
      isOnline
      level
      locationId
      nodeIP
      nodeId
      nodeName
      onboardCommand
      referenceId
      serviceData
      tags {
        id
        tagName
      }
    }
  }
`;
export const updateProfile = /* GraphQL */ `
  mutation UpdateProfile($input: ProfileInput) {
    updateProfile(input: $input) {
      file
      id
      name
    }
  }
`;
export const updateService = /* GraphQL */ `
  mutation UpdateService($input: UpdateServiceInput!) {
    updateService(input: $input) {
      configuration
      customerId
      deviceId
      healthData
      id
      isRunning
      locationId
      nodeId
      serviceType
    }
  }
`;
export const updateServiceHealth = /* GraphQL */ `
  mutation UpdateServiceHealth(
    $isOnline: Boolean
    $nodeId: String!
    $serviceId: String!
  ) {
    updateServiceHealth(
      isOnline: $isOnline
      nodeId: $nodeId
      serviceId: $serviceId
    ) {
      configuration
      customerId
      deviceId
      healthData
      id
      isRunning
      locationId
      nodeId
      serviceType
    }
  }
`;
export const updateShadowNode = /* GraphQL */ `
  mutation UpdateShadowNode(
    $payload: AWSJSON
    $shadowName: String
    $thingName: String
  ) {
    updateShadowNode(
      payload: $payload
      shadowName: $shadowName
      thingName: $thingName
    ) {
      shadow
    }
  }
`;
export const updateUserAlert = /* GraphQL */ `
  mutation UpdateUserAlert($input: UpdateUserAlertInput!) {
    updateUserAlert(input: $input) {
      alertName
      alertType
      createdBy
      customService
      email
      id
      jsonObject
      location
      locationTag
      model
      node
      notificationSettingsJson
      phoneNumber
      subscribedLevelEntity
      tag
    }
  }
`;
export const updateUserSettings = /* GraphQL */ `
  mutation UpdateUserSettings($input: UserInput!) {
    updateUserSettings(input: $input) {
      customer_id
      email
      externalId
      firstName
      id
      lastName
      userSettings
      user_role
    }
  }
`;
export const updateVideoNotes = /* GraphQL */ `
  mutation UpdateVideoNotes($input: UpdateVideoNotesInput!) {
    updateVideoNotes(input: $input) {
      annotations
      clientId
      customerId
      fov
      geometry
      groupId
      id
      isVideoProcessed
      modelOutputS3Key
      notes
      outputMeasurement
      sourceS3Key
      videoMethod
      videoName
      videoState
      windspeed
    }
  }
`;
export const updateZone = /* GraphQL */ `
  mutation UpdateZone($input: UpdateZoneInput!) {
    updateZone(input: $input) {
      alertData
      deviceId
      exclusions
      id
      inspectTime
      keyFrame
      name
      nodeId
      pan
      serviceId
      shouldNotify
      status
      threshold {
        id
        name
        value
      }
      tilt
      zoom
    }
  }
`;
export const updateZoneThresholdItem = /* GraphQL */ `
  mutation UpdateZoneThresholdItem($input: UpdateZoneThresholdItemInput!) {
    updateZoneThresholdItem(input: $input) {
      id
      name
      value
    }
  }
`;
export const updateZoneThresholdValue = /* GraphQL */ `
  mutation UpdateZoneThresholdValue($input: UpdateZoneThresholdValueInput!) {
    updateZoneThresholdValue(input: $input) {
      alertData
      deviceId
      exclusions
      id
      inspectTime
      keyFrame
      name
      nodeId
      pan
      serviceId
      shouldNotify
      status
      threshold {
        id
        name
        value
      }
      tilt
      zoom
    }
  }
`;
export const uploadDTFile = /* GraphQL */ `
  mutation UploadDTFile(
    $customerId: String
    $file: UploadFileInput!
    $objectId: String!
  ) {
    uploadDTFile(customerId: $customerId, file: $file, objectId: $objectId) {
      key
      url
    }
  }
`;
export const uploadDTFiles = /* GraphQL */ `
  mutation UploadDTFiles(
    $customerId: String
    $files: [UploadFileInput!]!
    $objectId: String!
  ) {
    uploadDTFiles(customerId: $customerId, files: $files, objectId: $objectId) {
      key
      url
    }
  }
`;
export const validateEvent = /* GraphQL */ `
  mutation ValidateEvent($input: ValidateEventInput!) {
    validateEvent(input: $input) {
      audited_by
      auditorsExplanation
      customerId
      data
      eventTime
      explanation
      id
      locationId
      nodeId
      serviceId
      transitionInfo
      validated_by
      validationState
    }
  }
`;
