export function formatTimestamp(inputTimestamp: string): string {
  if (!inputTimestamp) {
    return "";
  }

  const date = new Date(inputTimestamp);

  const hours = date.getHours();
  const minutes = date.getMinutes();
  const amPm = hours >= 12 ? "PM" : "AM";

  const formattedTimestamp = `${hours % 12 || 12}:${
    minutes < 10 ? "0" : ""
  }${minutes} ${amPm} ${new Intl.DateTimeFormat("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  }).format(date)}`;

  return formattedTimestamp;
}

export function convertDateToUTC(localDate: Date | null) {
  if (!localDate) {
    return null;
  }

  // Get the local timezone offset in minutes.
  const offsetMinutes = localDate.getTimezoneOffset();

  // Convert local date to UTC by adding the offset
  // For GMT+3, offsetMinutes = -180, so we substract 180 minutes to the local date.
  // For GMT-8, offsetMinutes = 480, so we add 480 minutes to the local date.
  const utcTimestamp = new Date(localDate.getTime() + offsetMinutes * 60000);

  return utcTimestamp;
}
