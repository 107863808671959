import { ReactNode } from "react";

import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

const PaperWrapper = styled(Paper)(({ theme, sx }) => ({
  backgroundColor: theme.palette.otherDisabledBackground.main,
  border: `1px solid ${theme.palette.otherOutlineBorder.main}`,
  borderColor: theme.palette.otherOutlineBorder.main,
  borderRadius: "12px",
  padding: "0.5em 1em 0.5em 1em",
  sx,
}));

interface AlertToggleSectionProps {
  toggleChecked: boolean;
  hasError: boolean;
  title: string;
  ToggleComponent: ReactNode;
  children?: ReactNode;
}

const AlertToggleSection = ({
  toggleChecked,
  hasError,
  title,
  ToggleComponent,
  children,
}: AlertToggleSectionProps) => {
  return (
    <PaperWrapper
      elevation={0}
      sx={{
        borderColor: theme =>
          hasError
            ? theme.palette.error.main
            : theme.palette.otherOutlineBorder.main,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="body2Regular">{title}</Typography>
        {ToggleComponent}
      </Box>
      <Collapse in={toggleChecked}>{children}</Collapse>
    </PaperWrapper>
  );
};

export default AlertToggleSection;
