import { SyntheticEvent } from "react";

import type { AmplifyUser } from "@aws-amplify/ui";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Button, ButtonGroup } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";

import { EVENT_VALIDATE_STATUS_ENUM } from "../../human-validator/models";

export const useHvReviewTableColumns = (): {
  columns: GridColDef[];
} => {
  const { user } = useAuthenticator((context): AmplifyUser[] => [context.user]);

  const columns: GridColDef[] = [
    {
      field: "eventTime",
      headerName: "Datetime",
      hideable: false,
      flex: 1,
      valueGetter: (eventTime): string => {
        if (eventTime) {
          return "n/a";
        }

        return eventTime ? new Date(eventTime).toLocaleString() : "n/a";
      },
    },
    {
      field: "decision",
      headerName: "Event Decision",
      hideable: false,
      flex: 1,
      valueGetter: decision => {
        if (!decision) {
          return "n/a";
        }

        return decision === EVENT_VALIDATE_STATUS_ENUM.REAL
          ? "Alert Sent"
          : "No Alert";
      },
    },
    {
      field: "location",
      headerName: "Location",
      hideable: false,
      flex: 1,
      valueGetter: (value: string) => {
        if (!value) {
          return null;
        }

        return value?.split("#L#").at(-1);
      },
    },
    {
      field: "reviewer",
      headerName: "Reviewer",
      hideable: false,
      flex: 1,
      valueGetter: (): string | undefined => user.username,
    },
    {
      field: "explanation",
      headerName: "Reason",
      hideable: false,
    },
    {
      field: "action",
      headerName: "Actions",
      disableColumnMenu: true,
      hideable: false,
      sortable: false,
      renderCell: (): JSX.Element => {
        const onClick = (event: SyntheticEvent): void => {
          event?.stopPropagation(); // don't select this row after clicking
        };

        return (
          <ButtonGroup variant="text" color="secondary">
            <Button onClick={onClick}>AUDIT</Button>
          </ButtonGroup>
        );
      },
    },
  ];

  return { columns };
};
