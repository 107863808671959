import { useMemo } from "react";

import type { GridRowsProp } from "@mui/x-data-grid";

import { Device } from "../../../API";
import { useGetNodesByCustomer } from "../../../common/components/select/NodeSelect/useGetNodesByCustomer";
import { AutocompleteOptionType } from "../../../common/models/autocomplete";
import {
  useSelectedLocationDropdown,
  useSelectedTagLocationsDropdown,
} from "../../../common/variables/selectedLocationDropdown";
import { useListenToNodeOnlineStatusChange } from "../../deployment-health/hooks/useListenToNodeHealthDataChange";

export interface NodesTableRowsDataInterface {
  index?: number | null;
  rowId: any;
  devices: Device[] | null;
  nodeName?: string | null;
  location?: string | null;
  serviceData?: string | null;
  isOnline: boolean;
}

interface NodesTableRowsInterface {
  rows: GridRowsProp<NodesTableRowsDataInterface>;
  loading: boolean;
}

export const useNodeTableRows = (): NodesTableRowsInterface => {
  const selectedLocation = useSelectedLocationDropdown();
  const selectedTagLocations = useSelectedTagLocationsDropdown();

  const { data: nodesByCustomer, loading: getNodesByCustomerLoading } =
    useGetNodesByCustomer();

  const { data: nodeHealthData } = useListenToNodeOnlineStatusChange();

  const rowsByTagLocations = useMemo((): NodesTableRowsDataInterface[] => {
    const mappedData =
      selectedTagLocations?.length && !getNodesByCustomerLoading
        ? nodesByCustomer?.getNodesByCustomer?.items
            ?.map((item): NodesTableRowsDataInterface => {
              return {
                rowId: item?.id,
                nodeName: item?.nodeName,
                devices: item?.devices ?? [],
                nodeIP: item?.nodeIP,
                location: item?.locationId,
                referenceId: item?.referenceId,
                serviceData: item?.serviceData,
                ...item,
                isOnline: !!item?.isOnline,
              };
            })
            .filter((item): NodesTableRowsDataInterface => item) ?? []
        : [];

    let rows = [...mappedData];

    if (selectedTagLocations?.length) {
      rows = rows?.filter((item: any) => {
        const nameOfLocation = item.location;

        const locations = selectedTagLocations.map(
          (tag: AutocompleteOptionType) => {
            if (nameOfLocation.includes("#L#")) {
              return tag?.value?.toLowerCase() ?? "";
            }

            return tag?.title?.toLowerCase() ?? "";
          }
        );

        const condition = locations.includes(nameOfLocation.toLowerCase());

        return condition;
      });
    }

    if (selectedTagLocations && selectedTagLocations.length === 0) {
      rows = [];
    }

    if (!selectedTagLocations) {
      rows = mappedData;
    }

    return rows.map((item, i) => ({ ...item, index: i + 1 }));
  }, [
    nodesByCustomer?.getNodesByCustomer?.items,
    getNodesByCustomerLoading,
    selectedTagLocations,
    nodesByCustomer,
  ]);

  const rowsByLocation = useMemo((): NodesTableRowsDataInterface[] => {
    const mappedData =
      selectedLocation?.value && !getNodesByCustomerLoading
        ? nodesByCustomer?.getNodesByCustomer?.items
            ?.map((item, index: number): NodesTableRowsDataInterface => {
              return {
                index: index + 1,
                rowId: item?.id,
                devices: item?.devices ?? [],
                nodeName: item?.nodeName,
                location: item?.locationId,
                nodeIP: item?.nodeIP,
                referenceId: item?.referenceId,
                serviceData: item?.serviceData,
                ...item,
                isOnline: !!item?.isOnline,
              };
            })
            .filter((item): NodesTableRowsDataInterface => item) ?? []
        : [];

    let rows = [...mappedData];

    if (selectedLocation?.value) {
      rows = rows?.filter(item => {
        const nameOfLocation = item.location ?? "";

        if (nameOfLocation?.includes("#L#")) {
          return selectedLocation?.value === nameOfLocation;
        }

        return (
          selectedLocation?.title.toLowerCase() ===
          nameOfLocation.toLocaleLowerCase()
        );
      });
    }

    return rows.map((item, i) => ({ ...item, index: i + 1 }));
  }, [
    nodesByCustomer?.getNodesByCustomer?.items,
    getNodesByCustomerLoading,
    selectedLocation?.value,
    nodesByCustomer,
  ]);

  const rows = selectedLocation?.value ? rowsByLocation : rowsByTagLocations;

  const memoizedRows =
    useMemo(() => {
      const isNodeDataFromSubscription =
        nodeHealthData?.listenToNodeOnlineStatus;

      if (!isNodeDataFromSubscription) return rows;

      const updatedRows = rows.map(node => {
        if (isNodeDataFromSubscription.id === node?.rowId) {
          return {
            ...node,
            isOnline: isNodeDataFromSubscription.isOnline ?? false,
          };
        }

        return { ...node };
      });

      return updatedRows;
    }, [nodeHealthData, rows]) ?? [];

  return { rows: memoizedRows, loading: getNodesByCustomerLoading };
};
