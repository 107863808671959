import { useEffect } from "react";
import { createPortal } from "react-dom";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

import RouterLink from "../../../../../../common/components/RouterLink";
import S3Image from "../../../../../../common/components/media/S3Image";
import { useGetNonAdminAccess } from "../../../../../../common/hooks/useGetNonAdminAccess";
import { RouteEnum } from "../../../../../../common/models/enums";
import {
  selectedKeyframeVariable,
  useSelectedKeyframe,
} from "../../../../../../common/variables/selectedKeyframe";
import ZoneListTableContainer from "./components/ZoneListTableContainer";

const ZoneList = (): JSX.Element => {
  const element = document.getElementById("back-button");
  const theme = useTheme();

  const { nonAdminView } = useGetNonAdminAccess();

  const selectedKeyframe = useSelectedKeyframe();

  useEffect(() => {
    return () => {
      selectedKeyframeVariable("");
    };
  }, []);

  return (
    <>
      {element &&
        createPortal(
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1em",
            }}
          >
            <RouterLink
              to={nonAdminView ? RouteEnum.SensoryLiveView : RouteEnum.LiveView}
            >
              <IconButton
                sx={{
                  color: theme.palette.text.primary,
                  cursor: "pointer",
                }}
              >
                <ChevronLeftIcon />
              </IconButton>
            </RouterLink>
            <Typography variant="h5">Zones</Typography>
          </Box>,
          element as HTMLElement
        )}
      <Grid
        container
        spacing={{
          xs: 0,
          sm: 0,
          md: 3,
          lg: 3,
          xl: 3,
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            py: {
              xs: 2,
            },
          }}
        >
          <S3Image
            s3Key={selectedKeyframe}
            sx={{
              width: "100%",
              maxWidth: "500px",
              height: "100%",
              maxHeight: "400px",
              borderRadius: "12px",
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <ZoneListTableContainer />
        </Grid>
      </Grid>
    </>
  );
};

export default ZoneList;
