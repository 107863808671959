import { useTheme } from "@mui/material/styles";

const NodeMenuIcon = (): JSX.Element => {
  const theme = useTheme();

  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="16.0002"
        cy="22.6667"
        r="1.66667"
        stroke={theme.palette.text.primary}
        strokeWidth="1.5"
      />
      <circle
        cx="10.9998"
        cy="11.0001"
        r="0.833333"
        stroke={theme.palette.text.primary}
        strokeWidth="1.5"
      />
      <path
        d="M15.2502 21.0001C15.2502 21.4143 15.5859 21.7501 16.0002 21.7501C16.4144 21.7501 16.7502 21.4143 16.7502 21.0001H15.2502ZM16.0002 7.66675L16.6242 7.25072C16.4851 7.04207 16.2509 6.91675 16.0002 6.91675C15.7494 6.91675 15.5152 7.04207 15.3761 7.25072L16.0002 7.66675ZM17.0428 10.5828C17.2726 10.9274 17.7382 11.0206 18.0829 10.7908C18.4275 10.561 18.5206 10.0954 18.2909 9.75072L17.0428 10.5828ZM13.7095 9.75072C13.4797 10.0954 13.5728 10.561 13.9175 10.7908C14.2621 11.0206 14.7278 10.9274 14.9575 10.5828L13.7095 9.75072ZM15.3761 8.08277L17.0428 10.5828L18.2909 9.75072L16.6242 7.25072L15.3761 8.08277ZM15.3761 7.25072L13.7095 9.75072L14.9575 10.5828L16.6242 8.08277L15.3761 7.25072ZM16.7502 21.0001V18.5001H15.2502V21.0001H16.7502ZM16.7502 18.5001V7.66675H15.2502V18.5001H16.7502Z"
        fill={theme.palette.text.primary}
      />
      <path
        d="M11 11.8333V13.9653C11 14.6827 11.4591 15.3196 12.1396 15.5465L14.8604 16.4534C15.5409 16.6802 16 17.3171 16 18.0345V18.4999"
        stroke={theme.palette.text.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M21 14.3333V16.4653C21 17.1827 20.5409 17.8196 19.8604 18.0465L17.1396 18.9534C16.4591 19.1802 16 19.8171 16 20.5345V20.9999"
        stroke={theme.palette.text.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M20.1665 13.5001C20.1665 13.1072 20.1665 12.9108 20.2885 12.7888C20.4106 12.6667 20.607 12.6667 20.9998 12.6667C21.3927 12.6667 21.5891 12.6667 21.7111 12.7888C21.8332 12.9108 21.8332 13.1072 21.8332 13.5001C21.8332 13.8929 21.8332 14.0893 21.7111 14.2114C21.5891 14.3334 21.3927 14.3334 20.9998 14.3334C20.607 14.3334 20.4106 14.3334 20.2885 14.2114C20.1665 14.0893 20.1665 13.8929 20.1665 13.5001Z"
        stroke={theme.palette.text.primary}
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default NodeMenuIcon;
