import { makeVar, useReactiveVar } from "@apollo/client";

import { AutocompleteOptionType } from "../models/autocomplete";

export const selectedLocationDropdownVariable =
  makeVar<AutocompleteOptionType>(null);

export const useSelectedLocationDropdown = () =>
  useReactiveVar(selectedLocationDropdownVariable);

export const selectedTagLocationsDropdownVariable = makeVar<
  AutocompleteOptionType[] | null
>(null);

export const useSelectedTagLocationsDropdown = () =>
  useReactiveVar(selectedTagLocationsDropdownVariable);

export const selectedTagIdDropdownVariable = makeVar<string | null>(null);

export const useSelectedTagIdDropdown = () =>
  useReactiveVar(selectedTagIdDropdownVariable);
