import { makeVar, useReactiveVar } from "@apollo/client";

export interface LicensePlateTableParameters {
  filterValue: string;
}

export const licensePlatesTableVariable = makeVar<LicensePlateTableParameters>({
  filterValue: "",
});

export const useLicensePlatesTableVariables = () =>
  useReactiveVar(licensePlatesTableVariable);
