import { FC, MouseEventHandler } from "react";

import ClearIcon from "@mui/icons-material/Clear";
import ExpandMore from "@mui/icons-material/ExpandMore";
import InputAdornment from "@mui/material/InputAdornment";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";

import LocationIcon from "./LocationIcon";

interface AllLocationsFilterDropdownProps {
  selectedValue: string;
  open: boolean;
  children: React.ReactNode;
  handleDropdownOpen: () => void;
  handleDropdownClose: () => void;
  handleOnBackdropClick: MouseEventHandler<HTMLDivElement>;
  handleClear: () => void;
  showPlaceHolder?: boolean;
  disableClearable?: boolean;
  menuOpened?: boolean;
  removeBorder?: boolean;
  size?: "small" | "medium";
  hasError?: boolean;
}

const AllLocationsFilterDropdown: FC<AllLocationsFilterDropdownProps> = ({
  children,
  selectedValue,
  open,
  handleDropdownOpen,
  handleDropdownClose,
  handleOnBackdropClick,
  handleClear,
  showPlaceHolder,
  disableClearable,
  menuOpened = true,
  removeBorder,
  size,
  hasError,
}) => {
  return (
    <Select
      sx={{
        maxWidth: menuOpened ? "none" : "120px",
        ".MuiBackdrop-root": {
          cursor: "auto",
        },
        ".MuiSelect-select": {
          paddingLeft: menuOpened ? "0.5em" : "1em",
        },
        ".MuiOutlinedInput-notchedOutline": {
          borderStyle: removeBorder ? "none" : "solid",
        },
        ".MuiList-root": {
          paddingBottom: "0",
        },
        ".MuiPaper-root": {
          borderRadius: "0.5em",
          boxShadow: "none",
          border: theme => `1px solid ${theme.palette.otherOutlineBorder.main}`,
        },
        "& input": {
          maxWidth: "100%",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      }}
      error={hasError}
      size={size}
      displayEmpty
      value={selectedValue}
      open={open}
      onOpen={handleDropdownOpen}
      onClose={handleDropdownClose}
      onClick={handleOnBackdropClick}
      MenuProps={{ autoFocus: false, disablePortal: true }}
      IconComponent={ExpandMore}
      inputProps={{
        renderValue: () => {
          if (showPlaceHolder) {
            return (
              <Typography
                sx={{
                  color: theme => theme.palette.otherTextTertiary.main,
                }}
              >
                All locations
              </Typography>
            );
          }

          return selectedValue ?? "";
        },
      }}
      startAdornment={menuOpened && <LocationIcon />}
      endAdornment={
        selectedValue &&
        !disableClearable && (
          <InputAdornment
            position="end"
            sx={{
              cursor: "pointer",
              position: "absolute",
              right: "36px",
            }}
          >
            <ClearIcon onClick={handleClear} />
          </InputAdornment>
        )
      }
    >
      {children}
    </Select>
  );
};

export default AllLocationsFilterDropdown;
