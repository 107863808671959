import { useMemo } from "react";

import { useQuery } from "@apollo/client";

import {
  GetUserByIdQuery,
  GetUserByIdQueryVariables,
  UserSettingsInput,
} from "../../API";
import { useAuthenticatedUser } from "./useAuthenticatedUser";
import { GET_USER_BY_ID } from "../operations/queries";
import {
  DEFAULT_CAMERA_FOV,
  DEFAULT_VIDEO_SPEED,
  DEFAULT_WINDSPEED_UNIT,
} from "../../leak-finder/hooks/useLazyGetUser";

export const useGetUser = () => {
  const { user } = useAuthenticatedUser();

  const userId = `U#${user?.attributes?.sub as string}`;

  const { data, loading, refetch } = useQuery<
    GetUserByIdQuery,
    GetUserByIdQueryVariables
  >(GET_USER_BY_ID, {
    variables: {
      userId,
    },
    skip: !userId,
  });

  const memoizedValue = useMemo(() => {
    let userSettings: UserSettingsInput | null = null;

    try {
      if (data?.getUserById.userSettings) {
        const settings = JSON.parse(data?.getUserById.userSettings);

        userSettings = {
          defaultCameraFov: settings?.defaultCameraFov ?? DEFAULT_CAMERA_FOV,
          defaultWindspeedUnit:
            settings?.defaultWindspeedUnit ?? DEFAULT_WINDSPEED_UNIT,
          defaultVideoSpeed: settings?.defaultVideoSpeed ?? DEFAULT_VIDEO_SPEED,
        };

        return {
          ...data?.getUserById,
          userSettings: userSettings,
        };
      }
    } catch (error) {
      console.error(error);

      return {
        ...data?.getUserById,
        userSettings: null,
      };
    }
  }, [data]);

  return { userInfo: memoizedValue, loading, refetch };
};
