import { useNavigate } from "react-router-dom";

import { Stack, Typography } from "@mui/material";

import EditIconButtonWithText from "../../../common/components/icons/EditIconButtonWithText";
import BreadcrumbNavigation from "../../../common/components/tabs/BreadcrumbNavigation";
import { IDeviceTableRow } from "../hooks/useDeviceTableRows";
import { deviceFormVariables } from "../variables/devices";
import DeleteDeviceContainer from "./DeleteDeviceContainer";

interface DeviceDetailPageHeaderProps {
  deviceDetails: IDeviceTableRow;
}

const DeviceDetailPageHeader = ({
  deviceDetails,
}: DeviceDetailPageHeaderProps): JSX.Element => {
  const navigate = useNavigate();

  const breadcrumbItems = [
    { label: "Devices", path: "/devices" },
    {
      label: deviceDetails.name,
    },
  ];

  const handleEditDevice = (): void => {
    deviceFormVariables({
      isDirty: false,
      deviceData: deviceDetails?.deviceData,
      deviceName: deviceDetails?.name ?? "",
      deviceType: deviceDetails?.makeModelId ?? "",
      cameraIpAddress: deviceDetails?.cameraIpAddress ?? "",
      location: {
        value: deviceDetails?.location.id ?? "",
        title: deviceDetails?.location.name ?? "",
      },
      node: {
        id: deviceDetails?.node.id ?? "",
        name: deviceDetails?.node.name ?? "",
      },
    });

    navigate(`/device/edit/${encodeURIComponent(deviceDetails?.rowId)}`);
  };

  return (
    <Stack spacing={3} direction="column" alignItems="left">
      <Stack spacing={0.5} direction="row" alignItems="center">
        <BreadcrumbNavigation items={breadcrumbItems} />
      </Stack>
      <Stack spacing={0.5} direction="row" alignItems="center">
        <Typography variant="h3" padding={0} sx={{ flexGrow: 1 }}>
          {deviceDetails?.name}
        </Typography>
        <DeleteDeviceContainer row={deviceDetails} />
        <EditIconButtonWithText onClick={handleEditDevice} />
      </Stack>
    </Stack>
  );
};

export default DeviceDetailPageHeader;
