import { useBase64 } from "../../../../../common/hooks/useBase64";
import useLocationFromCache from "../../../../../common/hooks/useLocationFromCache";
import { formatTimestamp } from "../../../../../common/utils/timestampUtils";
import { errorNotification } from "../../../../../common/variables/notification";
import useCSVDownloader from "../../../../../leak-finder/hooks/useCSVDownloader";
import useConvertArrayToCSV from "../../../../../leak-finder/hooks/useConvertArrayToCSV";
import { useGetGasLeakEventsToExport } from "../gasleak/hooks/useGetGasLeakEventsToExport";

const useExportGasleakData = () => {
  // const [loading, setLoading] = useState(false);
  const { getBase64MediaViewerPageLink } = useBase64();

  const { downloadCSV } = useCSVDownloader();

  const { convertArrayToCSV } = useConvertArrayToCSV();
  const { getCachedLocation } = useLocationFromCache();

  const { fetchTimestreamData, loading } = useGetGasLeakEventsToExport();

  const gasleakColumns = [
    { field: "location", headerName: "Location" },
    { field: "zone", headerName: "Zone" },
    { field: "timestamp", headerName: "Timestamp" },
    { field: "rate", headerName: "Rate (MCF/d)" },
    { field: "cameraId", headerName: "Device ID" },
    { field: "volume", headerName: "Volume (MCF)" },
    { field: "mediaOutput", headerName: "Media" },
  ];

  const exportGasleakData = async () => {
    try {
      const gasLeakRows = await fetchTimestreamData();

      if (gasLeakRows.length > 0) {
        const updatedRows = gasLeakRows.map(row => {
          const newRow = { ...row };
          const locationParts = newRow.location.includes("#L#")
            ? getCachedLocation(newRow.location ?? "")?.name ?? ""
            : newRow.location;

          if (locationParts.length > 1) {
            newRow.location = locationParts;
          }

          newRow.timestamp = formatTimestamp(newRow.timestamp);

          if (newRow.rate) {
            const rateAsNumber = parseFloat(newRow.rate);

            newRow.rate = isNaN(rateAsNumber)
              ? ""
              : (rateAsNumber * 86400).toString();
          } else {
            newRow.rate = "";
          }

          if (newRow.volume) {
            const volumeAsNumber = parseFloat(newRow.volume);

            newRow.volume = isNaN(volumeAsNumber)
              ? ""
              : volumeAsNumber.toString();
          } else {
            newRow.volume = "";
          }

          if (newRow.mediaOutput) {
            newRow.mediaOutput = getBase64MediaViewerPageLink(
              newRow.mediaOutput
            );
          }

          return newRow;
        });

        const csvString = convertArrayToCSV(updatedRows, gasleakColumns);

        downloadCSV(csvString, `gasleak_events.csv`);
      }
    } catch (error) {
      errorNotification("No data available to export");

      console.error("Error exporting gas leak data:", error);
    }
  };

  return { downloadGasleakData: exportGasleakData, loading };
};

export default useExportGasleakData;
