import { LeftMenuItems, RouteEnum } from "../../../../models/enums";
import { LeftSideBarItem } from "../../UnifiedLayout";

export const CustomerOperatorForemanItems: LeftSideBarItem[] = [
  {
    name: LeftMenuItems.RECENT_ACTIVITIES,
    path: RouteEnum.SensoryRecentActivities,
  },
  {
    name: LeftMenuItems.LIVE_VIEW,
    path: RouteEnum.SensoryLiveView,
  },
  {
    name: LeftMenuItems.DEPLOYMENT_HEALTH,
    path: RouteEnum.SensoryDeploymentHealth,
  },
  {
    name: LeftMenuItems.STATISTICS,
    path: RouteEnum.SensoryStatistics,
    showDivider: true,
  },
  {
    name: LeftMenuItems.SYSTEM_SETTINGS,
    path: RouteEnum.SensorySystemSettings,
    isSeparatorOfBottomSection: true,
  },
];
