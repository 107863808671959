import { ApolloError, useMutation } from "@apollo/client";

import {
  DeleteDeviceMakeMutation,
  DeleteDeviceMakeMutationVariables,
} from "../../../../../API";
import { DELETE_DEVICE_MAKE } from "../../../../../common/operations/mutations";
import {
  errorNotification,
  successNotification,
} from "../../../../../common/variables/notification";

export const useDeleteDeviceMake = () => {
  const [saveDeviceMake, { data, loading }] = useMutation<
    DeleteDeviceMakeMutation,
    DeleteDeviceMakeMutationVariables
  >(DELETE_DEVICE_MAKE);

  const deleteDeviceMake = async (id: string) => {
    return await saveDeviceMake({
      variables: {
        id,
      },
      onCompleted: response => {
        if (response.deleteDeviceMake) {
          successNotification();
        }
      },
      onError: (error: ApolloError): void => {
        errorNotification(error.message);

        console.error(error.message);
      },
      update(cache) {
        const normalizedId = cache.identify({ id, __typename: "DeviceMake" });

        cache.evict({ id: normalizedId });

        cache.gc();
      },
    });
  };

  return { deleteDeviceMake, data, loading };
};
