import { Box } from "@mui/material";

import ZoneCard from "./ZoneCard";
import { Zone } from "../../../API";

type ZonesContainerProps = {
  zones: Array<Zone | null>;
  deviceName: string;
  nodeId: string;
  hasZoom: boolean;
};

const ZonesContainer: React.FC<ZonesContainerProps> = ({
  zones,
  deviceName,
  nodeId,
  hasZoom,
}): JSX.Element => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        gap: "16px",
      }}
    >
      {zones.map((datum): JSX.Element | null => {
        if (datum === null) {
          return null;
        } else {
          const link = `${encodeURIComponent(
            String(datum?.id)
          )}?deviceName=${deviceName}&nodeId=${encodeURIComponent(nodeId)}`;

          return (
            <ZoneCard
              key={datum.name}
              datum={{ ...datum, deviceName, nodeId, hasZoom }}
              link={link}
            />
          );
        }
      })}
    </Box>
  );
};

export default ZonesContainer;
