import { useMemo } from "react";

import { NetworkStatus, useQuery } from "@apollo/client";

import {
  DateRange,
  GetStatisticsDataQuery,
  GetStatisticsDataQueryVariables,
  StatisticsDataInput,
  TimePeriod,
  eventType,
} from "../../../API";
import { useDateRange } from "../../../common/components/datePicker/modelsDatePickers";
import { useCustomerIdGuard } from "../../../common/hooks/useCustomerIdGuard";
import { GET_STATISTICS_DATA } from "../../../common/operations/queries";
import { parseQueryResult } from "../../../common/utils/timestreamHelper";
import { IStatisticsResponseModel } from "../models/chart";

export const useGetStatisticsData = <T>(
  input: Pick<
    StatisticsDataInput,
    "eventType" | "models" | "timePeriod" | "locations"
  >
) => {
  const selectedCustomerId = useCustomerIdGuard();
  const dateRange = useDateRange();

  const defaultInput = {
    customerId: selectedCustomerId,
    ...input,
  };

  const { data, loading, refetch, networkStatus } = useQuery<
    GetStatisticsDataQuery,
    GetStatisticsDataQueryVariables
  >(GET_STATISTICS_DATA, {
    variables: { input: defaultInput },
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    skip: !selectedCustomerId,
  });

  const refetchWithChosenValues = () => {
    refetchChartData(input.locations, input.timePeriod ?? TimePeriod.ONE_WEEK);
  };

  const refetchChartData = (
    locations?: string[] | null,
    timePeriod?: TimePeriod
  ) => {
    const inputModel = {
      customerId: selectedCustomerId,
      eventType: input.eventType,
      locations,
      timePeriod,
      models: input.models,
    };

    refetch({ input: inputModel });
  };

  const refetchWithSpecificData = (deviceData: IStatisticsResponseModel) => {
    const inputModel = {
      customerId: selectedCustomerId,
      eventType: eventType.deviceOverview,
      locations: input.locations,
      timePeriod: input.timePeriod,
      deviceName: deviceData.deviceName,
      date: `${deviceData.date}`,
      dateRange: dateRange as DateRange,
    };

    refetch({ input: inputModel });
  };

  const parsed: T = useMemo(
    () =>
      data?.getStatisticsData
        ? parseQueryResult(JSON.parse(data.getStatisticsData ?? ""))
        : [],
    [data?.getStatisticsData]
  );

  return {
    data: parsed,
    loading,
    refetching: networkStatus === NetworkStatus.refetch,
    refetchChartData,
    refetchWithSpecificData,
    refetchWithChosenValues,
  };
};
