import {
  ChangeEvent,
  FC,
  MouseEventHandler,
  useEffect,
  useRef,
  useState,
} from "react";

import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import FormControl from "@mui/material/FormControl";

import { Location, Tag } from "../../../../API";
import client from "../../../../configs/apolloClient";
import LocationForm from "../../../../pages/customer-settings/tabs/locations/components/LocationForm";
import { useLazyGetPaginatedLocations } from "../../../../pages/customer-settings/tabs/locations/hooks/useLazyGetPaginatedLocations";
import { useSaveLocation } from "../../../../pages/customer-settings/tabs/locations/hooks/useSaveLocation";
import {
  defaultLocationFormValidationState,
  defaultLocationFormVariables,
  locationFormRules,
  locationFormValidationStateVariable,
  locationFormVariables,
  useLocationFormValidationState,
  useLocationFormVariables,
} from "../../../../pages/customer-settings/tabs/locations/variables/locationData";
import { useLazyGetPaginatedAllTagsByCustomer } from "../../../../pages/system-settings/tags/hooks/useLazyGetPaginatedAllTagsByCustomer";
import { useCustomerIdGuard } from "../../../hooks/useCustomerIdGuard";
import {
  AutocompleteOptionType,
  IDisableClearableOption,
} from "../../../models/autocomplete";
import StyledLoadingButton from "../../../providers/theme/design-tokens/LoadingButton/StyledLoadingButton";
import {
  errorNotification,
  successNotification,
} from "../../../variables/notification";
import StyledDialog from "../../dialog/StyledDialog";
import { useDialog } from "../../dialog/useDialog";
import SearchInput from "../../search/SearchInput";
import AllLocationsFilterDropdown from "../AllLocationsFilterDropdown";
import AllLocationsFilterDropdownMenuItem from "../AllLocationsFilterDropdownMenuItem";
import CollapseButton from "../CollapseButton";
import {
  EVENTS_LOCATIONS_DROPDOWN_STORAGE_KEY,
  getLocationFromLocalStorageByEventsStorageKey,
  setLocationToLocalStorage,
} from "../../../helpers/getLocationFromLocalStorage";
import { useCustomerExternalIdGuard } from "../../../hooks/useCustomerExternalIdGuard";

interface AllLocationsFilterDropdownProps {
  setLocation: (locationValue: AutocompleteOptionType) => void;
  setTagLocations?: (locationValue: AutocompleteOptionType[] | null) => void;
  setTagId?: (tagId: string) => void;
  disableClearable?: boolean;
  removeBorder?: boolean;
  enhancedCapabilities?: boolean;
  menuOpened?: boolean;
  size?: "small" | "medium";
  ignoreDefaultValue?: boolean;
  hasError?: boolean;
}

const AllLocationsEventsFilterDropdownContainer: FC<
  AllLocationsFilterDropdownProps
> = ({
  setLocation,
  setTagLocations = () => {},
  setTagId = () => {},
  size = "small",
  disableClearable,
  removeBorder,
  enhancedCapabilities,
  menuOpened,
  ignoreDefaultValue,
  hasError,
}): JSX.Element => {
  const selectedCustomerId = useCustomerIdGuard();
  const customerExternalId = useCustomerExternalIdGuard();

  const form = useLocationFormVariables();
  const validation = useLocationFormValidationState();

  const { locations, loading: locationsLoading } =
    useLazyGetPaginatedLocations();

  const { tags, loading: tagsLoading } = useLazyGetPaginatedAllTagsByCustomer();

  const [selectedValue, setSelectedValue] = useState("");

  const loading = locationsLoading || tagsLoading;

  useEffect(() => {
    const parsedValue = getLocationFromLocalStorageByEventsStorageKey();

    if ((!locations.length || !enhancedCapabilities) && !parsedValue) {
      setSelectedValue("");

      setLocation(null);

      setTagLocations(null);

      return;
    }

    let foundValue: IDisableClearableOption | null = null;

    const foundLocation = locations.find(
      location => location.id === parsedValue?.value
    );

    const foundTag = tags.find(tag => tag.id === parsedValue?.value);

    if (foundLocation) {
      foundValue = {
        title: foundLocation.name,
        value: foundLocation.id,
      };

      setLocation(foundValue);
    }

    if (foundTag) {
      foundValue = {
        title: `#${foundTag.tagName}`,
        value: foundTag.id,
      };

      handleTagMenuItem(foundValue.title, foundValue.value);
    }

    if (foundValue) {
      setSelectedValue(foundValue.title);
    } else if (ignoreDefaultValue) {
      setSelectedValue("");

      setLocation(null);

      setTagLocations(null);
    } else {
      const defaultLocation = {
        title: locations[0]?.name ?? "",
        value: locations[0]?.id ?? "",
      };

      setLocation(defaultLocation);

      setSelectedValue(defaultLocation.title ?? "");
    }
  }, [loading, selectedCustomerId, locations, tags]);

  useEffect(() => {
    return () => {
      if (selectedValue) {
        handleClear();

        client.cache.reset();
      }
    };
  }, []);

  const [searchValue, setSearchValue] = useState("");

  const handleClear = (): void => {
    setLocationToLocalStorage(EVENTS_LOCATIONS_DROPDOWN_STORAGE_KEY);

    setLocation(null);

    setTagLocations(null);

    setSelectedValue("");

    setSearchValue("");
  };

  const [locationsCollapseOpened, setLocationsCollapseOpened] = useState(true);

  const [tagsCollapseOpened, setTagsCollapseOpened] = useState(true);

  const [open, setOpen] = useState(false);

  const collapseClickedRef = useRef(false);

  const handleLocationsCollapse = (): void => {
    collapseClickedRef.current = true;

    setLocationsCollapseOpened(!locationsCollapseOpened);
  };

  const handleTagsCollapse = (): void => {
    collapseClickedRef.current = true;

    setTagsCollapseOpened(!tagsCollapseOpened);
  };

  const handleSearchValueChange = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    setSearchValue(event.target.value);
  };

  const clearSearchValue = (): void => {
    setSearchValue("");
  };

  const handleDropdownOpen = (): void => {
    setOpen(true);

    collapseClickedRef.current = true;
  };

  const handleDropdownClose = (): void => {
    if (!collapseClickedRef.current) {
      setOpen(false);

      setSearchValue("");
    }
  };

  const handleLocationMenuItemClick = (
    title?: string | null,
    value?: string | null
  ): void => {
    setOpen(false);

    setTagId("");

    setSelectedValue(title ?? "");

    setTagLocations(null);

    setLocation({
      title: title ?? "",
      value: value ?? "",
    });

    setLocationToLocalStorage(EVENTS_LOCATIONS_DROPDOWN_STORAGE_KEY, {
      title: title ?? "",
      value: value ?? "",
    });
  };

  const handleTagMenuItem = (title: string, value: string): void => {
    setOpen(false);

    const selectedTagName = title.replace("#", "");

    if (title) {
      setLocationToLocalStorage(EVENTS_LOCATIONS_DROPDOWN_STORAGE_KEY, {
        title,
        value,
      });
    }

    const tagLocations = locations
      .filter(l => {
        return l?.tags?.some(
          tag => tag.tagName.toLowerCase() === selectedTagName.toLowerCase()
        );
      })
      .map(l => {
        return {
          title: l?.name ?? "",
          value: l?.id ?? "",
        };
      });

    setSelectedValue(title ?? "");

    setLocation(null);

    setTagLocations(tagLocations);

    setTagId(value);
  };

  const handleOnBackdropClick: MouseEventHandler<HTMLElement> = (
    event
  ): void => {
    const className = (event.target as HTMLElement)?.getAttribute("class");

    if (!className) {
      return;
    }

    if (className.includes("MuiBackdrop-root")) {
      setOpen(false);

      setSearchValue("");
    }
  };

  const {
    open: openCreateLocationDialog,
    handleDialogOpen,
    handleDialogClose,
  } = useDialog();

  const [saveLocationItem, { loading: saveLocationLoading }] =
    useSaveLocation();

  const openDialog = (): void => {
    handleDialogOpen();

    locationFormVariables(defaultLocationFormVariables);

    locationFormValidationStateVariable(defaultLocationFormValidationState);
  };

  const closeDialog = (): void => {
    handleDialogClose();

    locationFormVariables(defaultLocationFormVariables);

    locationFormValidationStateVariable(defaultLocationFormValidationState);
  };

  const handleSubmit = (): void => {
    let validationState = {
      ...(validation ?? defaultLocationFormValidationState),
    };

    const key = form.locationName.toLowerCase().replace(/\s+/g, "_");
    const locationId = `${selectedCustomerId}#L#${key}`;

    const normalizedId = client.cache.identify({
      id: locationId,
      __typename: "Location",
    });

    const extract = client.cache.extract();

    if (normalizedId && extract[normalizedId]?.name === form.locationName) {
      validationState = {
        ...validationState,
        locationName: {
          hasError: true,
          errorMessage: "Location name already exists",
        },
      };
    }

    if (!form.locationName) {
      validationState = {
        ...validationState,
        locationName: {
          hasError: true,
          errorMessage: "The field is required",
        },
      };
    }

    if (form.locationName.length > locationFormRules.locationName.maxLength) {
      validationState = {
        ...validationState,
        locationName: {
          hasError: true,
          errorMessage: `Location name cannot be longer than ${locationFormRules.locationName.maxLength} characters`,
        },
      };
    }

    if (!form.latitude) {
      validationState = {
        ...validationState,
        latitude: {
          hasError: true,
          errorMessage: "The field is required",
        },
      };
    }

    if (!form.longitude) {
      validationState = {
        ...validationState,
        longitude: {
          hasError: true,
          errorMessage: "The field is required",
        },
      };
    }

    if (!form.timeZone) {
      validationState = {
        ...validationState,
        timeZone: {
          hasError: true,
          errorMessage: "The field is required",
        },
      };
    }

    if (
      Object.values(validationState).some(
        (item): boolean => item?.hasError ?? false
      )
    ) {
      locationFormValidationStateVariable(validationState);

      return;
    }

    saveLocationItem({
      input: {
        customerExternalId,
        customerId: selectedCustomerId,
        id: form.locationName,
        name: form.locationName,
        locationData: JSON.stringify({
          lat: form.latitude,
          lon: form.longitude,
        }),
        tags: form.tags.map(({ tagName, id }) => ({
          tagName,
          id,
        })),
        timeZone: form.timeZone,
      },
    })
      .then((response): void => {
        handleDialogClose();

        locationFormVariables(defaultLocationFormVariables);

        locationFormValidationStateVariable(defaultLocationFormValidationState);

        successNotification(`${response.data?.addLocation?.name} is added`);
      })
      .catch((error): void => {
        errorNotification(error.message);

        console.error(error);
      });
  };

  const mappedTags = tags.map(tag => {
    return {
      ...tag,
      tagName: `#${tag?.tagName}`,
    };
  });

  return (
    <FormControl sx={{ width: "100%" }}>
      <AllLocationsFilterDropdown
        selectedValue={selectedValue}
        open={open}
        handleDropdownOpen={handleDropdownOpen}
        handleDropdownClose={handleDropdownClose}
        handleOnBackdropClick={handleOnBackdropClick}
        handleClear={handleClear}
        disableClearable={disableClearable}
        removeBorder={removeBorder}
        menuOpened={menuOpened}
        size={size}
        showPlaceHolder={!selectedValue}
        hasError={hasError}
      >
        <SearchInput
          sx={{
            padding: "0.5em -1em",
            marginLeft: "1em",
          }}
          variant="standard"
          size="small"
          value={searchValue}
          searchValue={searchValue}
          handleSearchValueChange={handleSearchValueChange}
          clearSearchValue={clearSearchValue}
        />

        <Box
          sx={{
            maxHeight: "300px",
            overflowY: "auto",
          }}
        >
          <CollapseButton
            label="Locations"
            opened={locationsCollapseOpened}
            handleCollapse={handleLocationsCollapse}
          />
          <Collapse in={locationsCollapseOpened}>
            {locations
              .filter((l: Location) => {
                const locationName = l?.name ?? "";

                return locationName
                  .toLowerCase()
                  .includes(searchValue.toLowerCase());
              })
              .map((l: Location) => {
                return (
                  <AllLocationsFilterDropdownMenuItem
                    key={l.id}
                    entityId={l.id ?? ""}
                    entityName={l.name ?? ""}
                    onMenuItemClick={handleLocationMenuItemClick}
                  />
                );
              })}
          </Collapse>

          <CollapseButton
            label="Tags"
            opened={tagsCollapseOpened}
            handleCollapse={handleTagsCollapse}
          />
          <Collapse in={tagsCollapseOpened}>
            {mappedTags
              .filter(tag => {
                const tagName = tag?.tagName ?? "";

                return tagName
                  .toLowerCase()
                  .includes(searchValue.toLowerCase());
              })
              .map((tag: Tag) => (
                <AllLocationsFilterDropdownMenuItem
                  key={tag.id}
                  entityId={tag.id ?? ""}
                  entityName={tag.tagName ?? ""}
                  onMenuItemClick={handleTagMenuItem}
                />
              ))}
          </Collapse>
        </Box>

        {enhancedCapabilities && (
          <Box
            sx={{
              width: "100%",
              height: "36px",
              display: "flex",
              justifyContent: "flex-end",
              boxShadow: "0px 4px 20px 0px #0816331A",
            }}
          >
            <Button
              sx={{
                height: "36px",
                textTransform: "none",
                padding: "8px",
                color: (theme): string => theme.palette.text.primary,
                backgroundColor: "inherit",
                "&:hover": {
                  backgroundColor: "inherit",
                },
                borderRadius: 0,
              }}
              disableRipple
              variant="text"
              startIcon={<AddIcon />}
              color="primary"
              onClick={openDialog}
            >
              New location
            </Button>
          </Box>
        )}
      </AllLocationsFilterDropdown>

      {/* <Select
        sx={{
          ".MuiSelect-select": {
            paddingLeft: menuOpened ? "0.5em" : "1em",
          },
          ".MuiOutlinedInput-notchedOutline": {
            borderStyle: removeBorder ? "none" : "solid",
          },
          "& input": {
            maxWidth: menuOpened ? "none" : "70px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        }}
        size={size}
        displayEmpty
        value={selectedValue}
        open={open}
        onOpen={handleDropdownOpen}
        onClose={handleDropdownClose}
        onClick={handleOnBackdropClick}
        MenuProps={{ autoFocus: false, disablePortal: true }}
        IconComponent={ExpandMore}
        inputProps={{
          renderValue: () => {
            if (
              !setDefaultLocation &&
              !selectedValue &&
              locations.length &&
              !loading
            ) {
              return (
                <Typography
                  sx={{
                    color: theme => theme.palette.otherTextTertiary.main,
                  }}
                >
                  All locations
                </Typography>
              );
            }

            return location?.title ?? "";
          },
        }}
        startAdornment={menuOpened && <LocationIcon />}
        endAdornment={
          selectedValue &&
          !disableClearable && (
            <InputAdornment
              position="end"
              sx={{
                cursor: "pointer",
                position: "absolute",
                right: "36px",
              }}
            >
              <ClearIcon onClick={handleClear} />
            </InputAdornment>
          )
        }
      >
        <SearchInput
          sx={{
            padding: "0.5em -1em",
            marginLeft: "1em",
          }}
          variant="standard"
          size="small"
          value={searchValue}
          searchValue={searchValue}
          handleSearchValueChange={handleSearchValueChange}
          clearSearchValue={clearSearchValue}
        />

        <Box
          sx={{
            maxHeight: "300px",
            overflowY: "auto",
          }}
        >
          <CollapseButton
            label="Locations"
            opened={locationsCollapseOpened}
            handleCollapse={handleLocationsCollapse}
          />
          <Collapse in={locationsCollapseOpened}>
            {locations
              .filter((l: Location) => {
                const locationName = l?.name ?? "";

                return locationName
                  .toLowerCase()
                  .includes(searchValue.toLowerCase());
              })
              .map((l: Location) => {
                return (
                  <AllLocationsFilterDropdownMenuItem
                    key={l.id}
                    entityId={l.id ?? ""}
                    entityName={l.name ?? ""}
                    onMenuItemClick={handleLocationMenuItemClick}
                  />
                );
              })}
          </Collapse>

          <CollapseButton
            label="Tags"
            opened={tagsCollapseOpened}
            handleCollapse={handleTagsCollapse}
          />
          <Collapse in={tagsCollapseOpened}>
            {mappedTags
              .filter(tag => {
                const tagName = tag?.tagName ?? "";

                return tagName
                  .toLowerCase()
                  .includes(searchValue.toLowerCase());
              })
              .map((tag: Tag) => (
                <AllLocationsFilterDropdownMenuItem
                  key={tag.id}
                  entityId={tag.id ?? ""}
                  entityName={tag.tagName ?? ""}
                  onMenuItemClick={handleTagMenuItemClick}
                />
              ))}
          </Collapse>
        </Box>

        <Box
          sx={{
            width: "100%",
            height: "36px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            sx={{
              height: "36px",
              textTransform: "none",
              padding: "8px",
              color: (theme): string => theme.palette.text.primary,
              backgroundColor: (theme): string =>
                theme.palette.otherBackgroundLight.main,
              "&:hover": {
                backgroundColor: (theme): string =>
                  theme.palette.otherBackgroundLight.main,
              },
              borderRadius: 0,
            }}
            disableRipple
            variant="text"
            startIcon={<AddIcon />}
            color="primary"
            onClick={openDialog}
          >
            New Location
          </Button>
        </Box>
      </Select> */}

      <StyledDialog
        open={openCreateLocationDialog}
        title="Add new location "
        onClose={closeDialog}
        showSubmitButton={false}
      >
        <LocationForm fullWidthInput />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "2em",
            gap: "1em",
          }}
        >
          <Button
            disabled={saveLocationLoading}
            variant="outlined"
            color="secondary"
            onClick={closeDialog}
          >
            Cancel
          </Button>
          <StyledLoadingButton
            loading={saveLocationLoading}
            loadingPosition="start"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            Add new location
          </StyledLoadingButton>
        </Box>
      </StyledDialog>
    </FormControl>
  );
};

export default AllLocationsEventsFilterDropdownContainer;
