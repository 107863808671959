import { useTheme } from "@mui/material";

type CustomCheckboxProps = {
  checked: boolean;
};

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({ checked = false }) => {
  const theme = useTheme();

  return checked ? (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1"
        y="1.5"
        width="16"
        height="16"
        rx="4"
        fill={theme.palette.primary.main}
      />
      <path
        d="M6.08325 10.025L7.91659 12.125L12.4999 6.875"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="2"
        y="2.5"
        width="14"
        height="14"
        rx="3"
        stroke={theme.palette.otherStandardInputLine.main}
        strokeWidth="2"
      />
    </svg>
  );
};

export default CustomCheckbox;
