import { useEffect, useRef, useState } from "react";

import Typography from "@mui/material/Typography";
import mapboxgl from "mapbox-gl";

import { Location } from "../../../../API";
import { useCustomerIdGuard } from "../../../../common/hooks/useCustomerIdGuard";
import { useLazyGetPaginatedLocations } from "../../../customer-settings/tabs/locations/hooks/useLazyGetPaginatedLocations";
import LocationMapGridItem from "./LocationMapGridItem";
import "./mapboxStyle/mapboxStyle.css";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";

// todo: why is our accessToken in our FE code?
mapboxgl.accessToken =
  "pk.eyJ1IjoiY2xlYW5jb25uZWN0IiwiYSI6ImNsbHBwYjJwYzA3a2gzcW52bm9mMTlreGIifQ.jaZkgUqSAcQ6ZDt703OlsA";

const DEGREE_SYMBOL = "°";

const LON_DEFAULT_VALUE = 0; // Default longitude
const LAT_DEFAULT_VALUE = 0; // Default latitude
const ZOOM_DEFAULT_VALUE = 8;

const isValidCoordinates = (lon: number, lat: number): boolean => {
  if (
    typeof lon !== "number" ||
    typeof lat !== "number" ||
    isNaN(lon) ||
    isNaN(lat)
  )
    return false;

  if (lon < -180 || lon > 180 || lat < -90 || lat > 90) return false;

  return true;
};

const convertCoordinatesToDecimalDegrees = (
  lonStr: string,
  latStr: string
): {
  lon: number;
  lat: number;
} => {
  let lon = LON_DEFAULT_VALUE;
  let lat = LAT_DEFAULT_VALUE;

  if (!lonStr || !latStr) return { lon, lat };

  if (lonStr.includes(DEGREE_SYMBOL)) {
    const parts = lonStr.split(/[°'"]/);

    // Extract the values from the match
    const degreesLon = parseInt(parts[0]);
    const minutesLon = parseInt(parts[1]);
    const secondsLon = parseFloat(parts[2]);

    // Calculate the decimal degrees
    lon = degreesLon + minutesLon / 60 + secondsLon / 3600;
  } else {
    lon = parseFloat(lonStr);
  }

  if (latStr.includes(DEGREE_SYMBOL)) {
    const parts = latStr.split(/[°'"]/);

    // Extract the values from the match
    const degreesLat = parseInt(parts[0]);
    const minutesLat = parseInt(parts[1]);
    const secondsLat = parseFloat(parts[2]);

    // Calculate the decimal degrees
    lat = degreesLat + minutesLat / 60 + secondsLat / 3600;
  } else {
    lat = parseFloat(latStr);
  }

  lon = Number(lon.toFixed(4));

  lat = Number(lat.toFixed(4));

  return { lon, lat };
};

const LocationsMapGridItem = (): JSX.Element => {
  const theme = useTheme();

  const mapContainer = useRef<HTMLDivElement | null>(null);
  const map = useRef<mapboxgl.Map | null>(null);

  // TODO: FIX DEFAULT LAT LON TO BE FIRST LOCATION ITEM VALUES
  const [lng, setLng] = useState<number>(LON_DEFAULT_VALUE);
  const [lat, setLat] = useState<number>(LAT_DEFAULT_VALUE);
  const [zoom, setZoom] = useState<number>(ZOOM_DEFAULT_VALUE);

  const selectedCustomerId = useCustomerIdGuard();

  const { locations, loading } = useLazyGetPaginatedLocations();

  useEffect((): void => {
    const locationItems = locations;
    const firstLocation =
      locationItems?.length > 0 ? locationItems[0] : undefined;

    if (mapContainer.current && locationItems) {
      // default view to first location coordinates
      if (firstLocation && firstLocation?.locationData) {
        const locationData = JSON.parse(firstLocation?.locationData ?? "");

        if (
          mapContainer.current &&
          isValidCoordinates(Number(locationData.lon), Number(locationData.lat))
        ) {
          map.current = new mapboxgl.Map({
            attributionControl: false,
            container: mapContainer.current,
            style:
              theme.palette.mode === "light"
                ? "mapbox://styles/mapbox/streets-v12"
                : "mapbox://styles/mapbox/dark-v11",
            center: [
              Number(locationData.lon) ?? 60,
              Number(locationData.lat) ?? 60,
            ], // default to 60,60 coordinates
            zoom: zoom,
          });
        } else {
          console.log("no valid coordinates and mapContainer");
        }
      }

      // setup handlers
      if (map.current) {
        map.current.on("click", (e): void => {
          const coordinates = e.lngLat;
          const lon = coordinates.lng;
          const lat = coordinates.lat;

          map.current?.setCenter([lon, lat]);

          setLng(parseFloat(map.current?.getCenter().lng.toFixed(4) ?? ""));

          setLat(parseFloat(map.current?.getCenter().lat.toFixed(4) ?? ""));

          setZoom(parseFloat(map.current?.getZoom().toFixed(2) ?? ""));
        });

        map.current.on("move", (): any => {
          setLng(parseFloat(map.current?.getCenter().lng.toFixed(4) ?? ""));

          setLat(parseFloat(map.current?.getCenter().lat.toFixed(4) ?? ""));

          setZoom(parseFloat(map.current?.getZoom().toFixed(2) ?? ""));
        });

        // Loop through each location and create a marker
        if (locationItems) {
          locationItems.forEach((location): any => {
            if (!location?.locationData) {
              console.log("NO LOCATION DATA");

              return;
            }

            const locationData = JSON.parse(location?.locationData ?? "{}");
            const markerLng = parseFloat(locationData.lon);
            const markerLat = parseFloat(locationData.lat);

            if (map.current && isValidCoordinates(markerLng, markerLat)) {
              const coordinates = convertCoordinatesToDecimalDegrees(
                locationData.lon,
                locationData.lat
              );

              const popup = new mapboxgl.Popup()
                .setHTML(
                  `
                    <h5
                      style="margin: 0.5em;"
                    >
                      ${location?.name}
                    </h5>
                    <p
                      style="margin: 0;"
                    >
                      ${coordinates.lat}; ${coordinates.lon}
                    </p>
                  `
                )
                .setMaxWidth("300px");

              new mapboxgl.Marker()
                .setLngLat([markerLng, markerLat])
                .setPopup(popup)
                .addTo(map.current);
            }
          });
        }
      }
    }
  }, [locations, loading, selectedCustomerId, theme.palette.mode]);

  useEffect((): void => {
    if (!locations) return;

    if (locations.length === 0) return;

    if (map.current) {
      try {
        const locationItems = locations;
        const validLocationData = locationItems?.find(
          (location): Location | undefined => {
            const locationData = JSON.parse(location?.locationData ?? "{}");
            const { lon, lat } = locationData;
            const coordinates = convertCoordinatesToDecimalDegrees(lon, lat);

            const isValid = isValidCoordinates(
              coordinates.lon,
              coordinates.lat
            );

            if (isValid) return location ?? undefined;
          }
        );

        if (validLocationData) {
          const locationData = JSON.parse(
            validLocationData?.locationData ?? "{}"
          );
          const { lon, lat } = locationData;
          const coordinates = convertCoordinatesToDecimalDegrees(lon, lat);

          // Fit map to a bounding box
          if (isValidCoordinates(coordinates.lon, coordinates.lat)) {
            const bounds = map.current?.getBounds();
            const swCorner = bounds?.getSouthWest();
            const neCorner = bounds?.getNorthEast();

            if (swCorner && neCorner) {
              const div = document.createElement("div");

              div.style.width = "300px";

              div.onclick = (): void => {
                map.current?.fitBounds(
                  [
                    [swCorner?.lng ?? 0, swCorner?.lat ?? 0], // southwestern corner of the bounds
                    [neCorner?.lng ?? 0, swCorner?.lat ?? 0], // northeastern corner of the bounds
                  ],
                  {
                    maxZoom: ZOOM_DEFAULT_VALUE - 2, // zoom out two levels
                    center: [coordinates.lon, coordinates.lat],
                    padding: 40,
                  }
                );
              };

              div.click();
            }
          }
        }
      } catch (error) {
        console.error("ERROR while centering mapbox:", error);
      }
    }
  }, [locations, loading]);

  return (
    <LocationMapGridItem>
      <Box
        sx={{
          "& .map-container": {
            "& .mapboxgl-popup-content": {
              background: theme.palette.otherDisabledBackground.main,
            },
            "& .mapboxgl-popup-tip": {
              borderTopColor: theme.palette.otherDisabledBackground.main,
            },
          },
        }}
      >
        <Typography variant="h5">Locations Map</Typography>
        <div ref={mapContainer} className="map-container"></div>

        <div>
          Latitude: {lat.toFixed(4)} | Longitude: {lng.toFixed(4)}
          <br />
          Locations: {locations.length || 0}
        </div>
      </Box>
    </LocationMapGridItem>
  );
};

export default LocationsMapGridItem;
