import { useLazyQuery } from "@apollo/client";

import {
  GetUserByIdQuery,
  GetUserByIdQueryVariables,
  UserSettingsInput,
} from "../../API";
import { GET_USER_BY_ID } from "../../common/operations/queries";

export const DEFAULT_CAMERA_FOV = 27.5;

export const DEFAULT_WINDSPEED_UNIT = "mph";

export const DEFAULT_VIDEO_SPEED = 2;

export interface IParsedUserInfo {
  cameraFov: number;
  windspeedUnit: string;
  videoSpeed: number;
  userId: string;
  userRole: string;
}

export const useLazyGetUser = () => {
  const [getUser, { loading }] = useLazyQuery<
    GetUserByIdQuery,
    GetUserByIdQueryVariables
  >(GET_USER_BY_ID, {
    fetchPolicy: "network-only",
  });

  const fetchUser = async () => {
    return await getUser();
  };

  const getParsedUserInfo = async (): Promise<IParsedUserInfo> => {
    const response = await fetchUser();

    const data = response.data;

    let userSettings: UserSettingsInput | null = null;

    try {
      if (data?.getUserById.userSettings) {
        const settings = JSON.parse(data?.getUserById.userSettings);

        userSettings = {
          defaultCameraFov: settings?.defaultCameraFov ?? DEFAULT_CAMERA_FOV,
          defaultWindspeedUnit:
            settings?.defaultWindspeedUnit ?? DEFAULT_WINDSPEED_UNIT,
          defaultVideoSpeed: settings?.defaultVideoSpeed ?? DEFAULT_VIDEO_SPEED,
        };
      }
    } catch (error) {
      console.error(error);
    }

    return {
      cameraFov: userSettings?.defaultCameraFov ?? DEFAULT_CAMERA_FOV,
      windspeedUnit:
        userSettings?.defaultWindspeedUnit ?? DEFAULT_WINDSPEED_UNIT,
      videoSpeed: userSettings?.defaultVideoSpeed ?? DEFAULT_VIDEO_SPEED,
      userId: data?.getUserById.id as string,
      userRole: data?.getUserById.user_role as string,
    };
  };

  return { fetchUser, getParsedUserInfo, loading };
};
