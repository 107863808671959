import { useEffect, useState } from "react";

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import CircleArrowDown from "../../../../../../common/components/icons/CircleArrowDown";
import CircleArrowLeft from "../../../../../../common/components/icons/CircleArrowLeft";
import CircleArrowRight from "../../../../../../common/components/icons/CircleArrowRight";
import CircleArrowUp from "../../../../../../common/components/icons/CircleArrowUp";
import CloseDialogIcon from "../../../../../../common/components/icons/CloseDialogIcon";
import { useGetNonAdminAccess } from "../../../../../../common/hooks/useGetNonAdminAccess";
import { useStream } from "../../../../../../common/hooks/useStream";
import { RouteEnum } from "../../../../../../common/models/enums";
import { PAN_TILT_PATTERN } from "../../../../../../common/variables/common";
import { selectedKeyframeVariable } from "../../../../../../common/variables/selectedKeyframe";
import { selectedStreamObjectVariable } from "../../../../../../common/variables/selectedStreamObject";
import { usePublishNode } from "../../../../../devices/hooks/usePublishNode";
import {
  ICreateZoneValidationState,
  useZoneFormValidation,
} from "../../../../../devices/zones-setup-page/hooks/useZoneFormValidation";
import HLSStreamPlayer from "../cameraView/HLSStreamPlayer";
import RouterLink from "./../../../../../../common/components/RouterLink";
import PTZoneListTableContainer from "./ptzonelist/PTZoneListTableContainer";
import {
  selectedZoneVariable,
  useSelectedZoneVariable,
} from "./ptzonelist/zones.variable";
import { DeviceData } from "../../../../../../API";

interface IDeviceImageListItemBarTitleProps {
  deviceName: string;
  deviceId: string;
  nodeId: string;
  locationName: string;
  deviceData: DeviceData;
}

const DeviceImageListItemBarTitle = ({
  deviceName,
  deviceId,
  nodeId,
  locationName,
  deviceData,
}: IDeviceImageListItemBarTitleProps): JSX.Element => {
  const { nonAdminView } = useGetNonAdminAccess();
  const selectedZone = useSelectedZoneVariable();

  const { publishNode } = usePublishNode();
  const { getStreamName } = useStream();

  const [open, setOpen] = useState(false);
  const [ptOpen, setPtOpen] = useState(false);
  const [pan, setPan] = useState("");
  const [tilt, setTilt] = useState("");
  const [zoom, setZoom] = useState("");
  const [isReady, setIsReady] = useState(true);
  const [currentDevice, setCurrentDevice] = useState("");

  const { setValidationState, validatePanTilt, validateZoom, validationState } =
    useZoneFormValidation();

  const [disabled, setDisabled] = useState(false);

  useEffect((): void => {
    setPan(selectedZone.pan);

    setTilt(selectedZone.tilt);

    setZoom(selectedZone.zoom);
  }, [selectedZone.pan, selectedZone.tilt, selectedZone.zoom]);

  const handleLiveViewOnClick = (): void => {
    if (!isReady) return;

    // todo: come up with a better unique naming scheme
    setCurrentDevice(deviceId);

    const streamName = getStreamName(nodeId, deviceId);

    publishNode({
      message: JSON.stringify({
        TARGET: "KINESIS_STREAM",
        ACTION: "KINESIS_STREAM_START",
        streamName,
        deviceName,
      }),
      nodeId,
    }).then((): void => {
      selectedStreamObjectVariable({ streamName, deviceName, nodeId });

      setOpen(true);
    });
  };

  const handleOpenPtControlsOnClick = (): void => {
    publishNode({
      message: JSON.stringify({
        TARGET: "PANTILT",
        ACTION: "START_PT_CONTROL",
        device_name: deviceName,
      }),
      nodeId,
    }).then((): void => {
      setPtOpen(true);
    });
  };

  const handleClose = (): void => {
    setOpen(false);

    setIsReady(false);

    setPtOpen(false);

    selectedZoneVariable({ zoneName: "", pan: "", tilt: "", zoom: "" });

    const streamName = getStreamName(nodeId, deviceId);

    publishNode({
      message: JSON.stringify({
        TARGET: "KINESIS_STREAM",
        ACTION: "KINESIS_STREAM_END",
        streamName,
        deviceName,
      }),
      nodeId,
    }).then((): void => {
      setIsReady(true);

      selectedStreamObjectVariable(null);
    });

    if (ptOpen) {
      publishNode({
        message: JSON.stringify({
          TARGET: "PANTILT",
          ACTION: "STOP_PT_CONTROL",
          device_name: deviceName,
        }),
        nodeId,
      });
    }
  };

  const movePtUp = (): void => {
    console.log("UP");

    selectedKeyframeVariable("");

    selectedZoneVariable({ zoneName: "", pan: pan, tilt: tilt, zoom: zoom });

    publishNode({
      message: JSON.stringify({
        TARGET: "PANTILT",
        ACTION: "MOVEUP_PT",
        device_name: deviceName,
      }),
      nodeId,
    });
  };

  const movePtDown = (): void => {
    console.log("DOWN");

    selectedKeyframeVariable("");

    selectedZoneVariable({ zoneName: "", pan: pan, tilt: tilt, zoom: zoom });

    publishNode({
      message: JSON.stringify({
        TARGET: "PANTILT",
        ACTION: "MOVEDOWN_PT",
        device_name: deviceName,
      }),
      nodeId,
    });
  };

  const movePtLeft = (): void => {
    console.log("LEFT");

    selectedKeyframeVariable("");

    selectedZoneVariable({ zoneName: "", pan: pan, tilt: tilt, zoom: zoom });

    publishNode({
      message: JSON.stringify({
        TARGET: "PANTILT",
        ACTION: "MOVELEFT_PT",
        device_name: deviceName,
      }),
      nodeId,
    });
  };

  const movePtRight = (): void => {
    console.log("RIGHT");

    selectedKeyframeVariable("");

    selectedZoneVariable({ zoneName: "", pan: pan, tilt: tilt, zoom: zoom });

    publishNode({
      message: JSON.stringify({
        TARGET: "PANTILT",
        ACTION: "MOVERIGHT_PT",
        device_name: deviceName,
      }),
      nodeId,
    });
  };

  const handleGoToPt = (pan: string, tilt: string, zoom: string): void => {
    const isValid = validatePanTilt(pan, tilt);

    const isValidZoom = validateZoom(zoom);

    if (!isValid && !isValidZoom) {
      return;
    }

    selectedKeyframeVariable("");

    selectedZoneVariable({ zoneName: "", pan: pan, tilt: tilt, zoom: zoom });

    publishNode({
      message: JSON.stringify({
        TARGET: "PANTILT",
        ACTION: "GOTO_PT",
        device_name: deviceName,
        pan: pan,
        tilt: tilt,
        zoom: zoom,
      }),
      nodeId,
    });

    setDisabled(true);

    setTimeout((): void => {
      setDisabled(false);
    }, 10000);
  };

  const handlePanInputChange = (pan?: string): void => {
    selectedKeyframeVariable("");

    selectedZoneVariable({
      zoneName: "",
      pan: pan ?? "",
      tilt: tilt,
      zoom: zoom,
    });

    setValidationState(
      (prev): ICreateZoneValidationState => ({
        ...prev,
        pan: {
          hasError: false,
          errorMessage: "",
        },
        panValue: {
          hasError: false,
          errorMessage: "",
        },
      })
    );

    if (PAN_TILT_PATTERN.test(pan ?? "")) {
      setPan(pan ?? "");
    }
  };

  const handleTiltInputChange = (tilt?: string): void => {
    selectedKeyframeVariable("");

    selectedZoneVariable({
      zoneName: "",
      pan: pan,
      tilt: tilt ?? "",
      zoom: zoom,
    });

    setValidationState(
      (prev): ICreateZoneValidationState => ({
        ...prev,
        tilt: {
          hasError: false,
          errorMessage: "",
        },
        tiltValue: {
          hasError: false,
          errorMessage: "",
        },
      })
    );

    if (PAN_TILT_PATTERN.test(tilt ?? "")) {
      setTilt(tilt ?? "");
    }
  };

  const handleZoomInputChange = (zoom?: string): void => {
    selectedKeyframeVariable("");

    selectedZoneVariable({
      zoneName: "",
      pan: pan,
      tilt: tilt,
      zoom: zoom ?? "",
    });

    setValidationState(
      (prev): ICreateZoneValidationState => ({
        ...prev,
        zoom: {
          hasError: false,
          errorMessage: "",
        },
        zoomValue: {
          hasError: false,
          errorMessage: "",
        },
      })
    );

    setZoom(zoom ?? "");
  };

  const { hasZoom, hasPanTilt } = deviceData;

  return (
    <>
      <Dialog
        sx={{
          width: "100%",
        }}
        PaperProps={{
          sx: {
            maxHeight: "calc(100% - 174px)",
            height: "100%",
            maxWidth: "1360px",
          },
        }}
        open={open}
        maxWidth="xl"
        fullWidth
        onClose={handleClose}
      >
        <CloseDialogIcon onClose={handleClose} />
        <DialogContent
          sx={{
            display: "flex",
            padding: "0px",
            width: "100%",
            height: "100%",
            overflowY: "hidden",
          }}
        >
          <HLSStreamPlayer setDialogStyles />
          <Box
            sx={{
              padding: "2em",
              paddingBottom: 0,
              width: "394px",
              maxWidth: "394px",
              minWidth: "394px",
            }}
          >
            {ptOpen ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "0.5em",
                  }}
                >
                  <ErrorOutlineIcon sx={{ color: "warning.main" }} />
                  <Typography
                    sx={{ color: "warning.main", "& p": { margin: 0 } }}
                    variant="body2Regular"
                    component="span"
                  >
                    <p>All models are stopped while you</p>
                    <p>are running pt control</p>
                  </Typography>
                </Box>

                <Divider
                  sx={{
                    margin: "1.5em 0",
                  }}
                />

                {validationState.panValue.hasError && (
                  <Alert severity="error">
                    {validationState.panValue.errorMessage}
                  </Alert>
                )}
                {validationState.tiltValue.hasError && (
                  <Alert severity="error">
                    {validationState.tiltValue.errorMessage}
                  </Alert>
                )}
                {validationState.zoomValue.hasError && (
                  <Alert severity="error">
                    {validationState.zoomValue.errorMessage}
                  </Alert>
                )}

                <Box sx={{ display: "flex" }}>
                  <Table size="small" sx={{ width: "240px" }}>
                    <TableBody>
                      <TableRow
                        sx={{
                          "& td": {
                            border: 0,
                            padding: 0,
                          },
                          "& .small": {
                            width: "32px",
                            height: "32px",
                          },
                          "& .pan": {
                            paddingLeft: "1.5em",
                          },
                          "& .tilt": {
                            paddingLeft: "0.5em",
                          },
                        }}
                      >
                        <TableCell className="small"></TableCell>
                        <TableCell className="small">
                          <CircleArrowUp
                            width={32}
                            height={32}
                            fontSize="large"
                            onClick={movePtUp}
                          />
                        </TableCell>
                        <TableCell className="small"></TableCell>
                        <TableCell rowSpan={3} className="pan tilt">
                          <Box
                            sx={{
                              display: "flex",
                              marginLeft: "10px",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              variant="inputLabel"
                              sx={{ position: "absolute" }}
                              component="span"
                              color="otherTextTertiary.main"
                            >
                              Pan
                            </Typography>
                            <TextField
                              type="text"
                              error={validationState?.pan.hasError}
                              helperText={validationState?.pan.errorMessage}
                              InputProps={{ sx: { borderRadius: "8px" } }}
                              sx={{
                                minWidth: "72px",
                                maxWidth: "76px",
                                position: "relative",
                                left: "30px",
                                "& input": {
                                  borderRadius: "8px",
                                  height: "18px",
                                  "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button":
                                    {
                                      "-webkit-appearance": "none",
                                      margin: 0,
                                    },
                                },
                              }}
                              id="pan"
                              size="small"
                              variant="outlined"
                              margin="dense"
                              value={pan}
                              onChange={(e): void =>
                                handlePanInputChange(e.target.value)
                              }
                            />
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              marginLeft: "10px",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              variant="inputLabel"
                              sx={{ position: "absolute" }}
                              component="span"
                              color="otherTextTertiary.main"
                            >
                              Tilt
                            </Typography>
                            <TextField
                              type="text"
                              error={validationState?.tilt.hasError}
                              helperText={validationState?.tilt.errorMessage}
                              InputProps={{ sx: { borderRadius: "8px" } }}
                              sx={{
                                minWidth: "72px",
                                maxWidth: "76px",
                                position: "relative",
                                left: "30px",
                                "& input": {
                                  height: "18px",
                                  "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button":
                                    {
                                      "-webkit-appearance": "none",
                                      margin: 0,
                                    },
                                },
                              }}
                              id="tilt"
                              size="small"
                              variant="outlined"
                              margin="dense"
                              value={tilt}
                              onChange={(e): void =>
                                handleTiltInputChange(e.target.value)
                              }
                            />
                          </Box>
                          {hasZoom && (
                            <Box
                              sx={{
                                display: "flex",
                                marginLeft: "0px",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                variant="inputLabel"
                                sx={{ position: "absolute" }}
                                component="span"
                                color="otherTextTertiary.main"
                              >
                                Zoom
                              </Typography>
                              <TextField
                                type="text"
                                error={validationState?.zoom.hasError}
                                helperText={validationState?.zoom.errorMessage}
                                InputProps={{ sx: { borderRadius: "10px" } }}
                                sx={{
                                  minWidth: "72px",
                                  maxWidth: "76px",
                                  position: "relative",
                                  left: "40px",
                                  "& input": {
                                    height: "18px",
                                    "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button":
                                      {
                                        "-webkit-appearance": "none",
                                        margin: 0,
                                      },
                                  },
                                }}
                                id="zoom"
                                size="small"
                                variant="outlined"
                                margin="dense"
                                value={zoom}
                                onChange={(e): void =>
                                  handleZoomInputChange(e.target.value)
                                }
                              />
                            </Box>
                          )}
                        </TableCell>
                        <TableCell rowSpan={3}></TableCell>
                      </TableRow>

                      <TableRow
                        sx={{
                          "& td": {
                            border: 0,
                            padding: 0,
                          },
                          "& .small": {
                            width: "32px",
                            height: "32px",
                          },
                        }}
                      >
                        <TableCell className="small">
                          <CircleArrowLeft
                            width={32}
                            height={32}
                            fontSize="large"
                            onClick={movePtLeft}
                          />
                        </TableCell>
                        <TableCell className="small"></TableCell>
                        <TableCell className="small">
                          <CircleArrowRight
                            width={32}
                            height={32}
                            fontSize="large"
                            onClick={movePtRight}
                          />
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </TableRow>

                      <TableRow
                        sx={{
                          "& td": {
                            border: 0,
                            padding: 0,
                          },
                          "& .small": {
                            width: "32px",
                            height: "32px",
                          },
                        }}
                      >
                        <TableCell className="small"></TableCell>
                        <TableCell className="small">
                          <CircleArrowDown
                            width={32}
                            height={32}
                            fontSize="large"
                            onClick={movePtDown}
                          />
                        </TableCell>
                        <TableCell className="small"></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>

                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Tooltip
                      title={
                        !pan || !tilt || !zoom || disabled
                          ? "Please fill out all required fields"
                          : ""
                      }
                    >
                      <span>
                        <Button
                          variant="text"
                          disabled={!pan || !tilt || !zoom || disabled}
                          onClick={(): void => handleGoToPt(pan, tilt, zoom)}
                          size="small"
                        >
                          Apply
                        </Button>
                      </span>
                    </Tooltip>
                  </Box>
                </Box>

                <Divider
                  sx={{
                    margin: "1.5em 0",
                  }}
                />

                <Typography variant="body1Bold" component="span">
                  All Zones
                </Typography>

                <PTZoneListTableContainer
                  currentDeviceId={currentDevice}
                  nodeId={nodeId}
                  hasZoom={hasZoom}
                />
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <Table size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell
                        variant="head"
                        sx={{ border: 0, width: "80px" }}
                      >
                        <Typography variant="inputLabel" component="span">
                          DEVICE
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ border: 0 }}>
                        <Typography variant="body2Bold" component="span">
                          {deviceName}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        variant="head"
                        sx={{ border: 0, width: "80px" }}
                      >
                        <Typography variant="inputLabel" component="span">
                          LOCATION
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ border: 0 }}>
                        <Typography variant="body2Regular" component="span">
                          {locationName}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <Box
                  sx={{
                    marginTop: "auto",
                  }}
                >
                  {hasPanTilt && (
                    <>
                      <Button
                        sx={{
                          display: ptOpen ? "none" : "block",
                          color: "text.primary",
                          borderColor: "text.primary",
                          marginBottom: "1em",
                        }}
                        variant="outlined"
                        size="small"
                        onClick={handleOpenPtControlsOnClick}
                      >
                        <Typography variant="buttonMedium" component="span">
                          Open PT Controller
                        </Typography>
                      </Button>

                      <Box
                        sx={{
                          display: "flex",
                          gap: "0.5em",
                          paddingBottom: "2em",
                        }}
                      >
                        <ErrorOutlineIcon sx={{ color: "warning.main" }} />
                        <Typography
                          sx={{ color: "warning.main", "& p": { margin: 0 } }}
                          variant="body2Regular"
                          component="span"
                        >
                          <p>
                            All models are stopped while you are running pt
                            control
                          </p>
                        </Typography>
                      </Box>
                    </>
                  )}
                </Box>
              </Box>
            )}
          </Box>
        </DialogContent>
      </Dialog>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "1em 0.5em 0 0.5em",
        }}
      >
        <Typography
          variant="body1Bold"
          sx={{
            "&:hover": {
              cursor: isReady ? "pointer" : "not-allowed",
              color: (theme): string => theme.palette.primary.main,
            },
          }}
          onClick={handleLiveViewOnClick}
        >
          {deviceName}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: hasPanTilt ? "baseline" : "center",
          gap: "0.5em",
          margin: "1em 0.5em",
        }}
      >
        <Typography variant="inputLabel" component="span">
          Location
        </Typography>
        <Typography variant="body2Regular" component="span">
          {locationName}
        </Typography>
        <Button
          variant="text"
          sx={{
            marginLeft: "auto",
            padding: "2px 4px",
            height: "26px",
          }}
        >
          {hasPanTilt && (
            <RouterLink
              title="Zones List"
              to={
                nonAdminView
                  ? `${
                      RouteEnum.SensoryLiveViewZoneListShort
                    }/${encodeURIComponent(deviceId)}`
                  : `${RouteEnum.ZoneListShort}/${encodeURIComponent(deviceId)}`
              }
              state={{
                deviceId,
              }}
            />
          )}
        </Button>
      </Box>
    </>
  );
};

export default DeviceImageListItemBarTitle;
