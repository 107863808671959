import { useNavigate } from "react-router-dom";

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import Button from "@mui/material/Button";

import usePlateManagementNavigationRoute from "../../../../../common/hooks/usePlateManagementNavigationRoute";
import { RouteEnum } from "../../../../../common/models/enums";

const NewPlateNumberButtonSystemSettings = () => {
  const navigate = useNavigate();

  const { getNavigationRoute } = usePlateManagementNavigationRoute();

  const handleClick = (): void => {
    const navRoute = getNavigationRoute(
      RouteEnum.SystemSettingsPlatesManagementCreatePlateNumber,
      RouteEnum.SensorySystemSettingsPlatesManagementCreatePlateNumber
    );

    navigate(navRoute);
  };

  return (
    <Button
      variant="outlined"
      size="small"
      sx={{
        textTransform: "none",
        marginBottom: "0.5em",
      }}
      startIcon={<AddOutlinedIcon />}
      onClick={handleClick}
    >
      New plate number
    </Button>
  );
};

export default NewPlateNumberButtonSystemSettings;
