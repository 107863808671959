import { CC_ADMIN_ROLES } from "../models/enums";
import { useSelectedCustomerId } from "../variables/selectedCustomer";
import { useAuthenticatedUser } from "./useAuthenticatedUser";
import { useGetCustomers } from "./useGetCustomers";

export const useCustomerExternalIdGuard = () => {
  const selectedCustomerId = useSelectedCustomerId();

  const { customers } = useGetCustomers();

  const { customerId, role } = useAuthenticatedUser();

  const canRoleAccessMultipleCustomers = CC_ADMIN_ROLES.includes(role);

  if (!customers?.items.length) return "";

  const foundCustomer = customers.items.find(customer => {
    // If the user has a role that allows them to access multiple customers
    // and a customer is selected, use the selected customer ID
    if (canRoleAccessMultipleCustomers && selectedCustomerId) {
      return customer?.id === selectedCustomerId;
    }

    // If the user can only access a single customer, or no customer is selected,
    // use the user's customer ID from claims
    return customer?.id === customerId;
  });

  return foundCustomer?.externalId ?? "";
};
