export const DEFAULT_LIMIT_COUNT = 100;

export const DEFAULT_COLUMN_HEADER_HEIGHT = 40;

export const ONLINE_STATUS_THRESHOLD_MS = 900000; // 15 min

export const ZONE_NAME_PATTERN = /^[a-zA-Z0-9\-_. @#$%&*!]+(?<![\\/:*?"<>|])$/;

export const PAN_TILT_PATTERN = /^(?!-\.|\.-|\.)(?:-?\d*(?:\.\d{0,2})?|)$/;

export const IP_MASK_PATTERN =
  /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

export const Z_INDEX_PRIORITY = {
  HEADER_LEFT_BAR: 10,
  FULL_PAGE_LOADER: 9,
};
