import { makeVar } from "@apollo/client";
import { RoleEnum } from "../../../common/models/enums";
import type { PendingEventRow } from "../hooks/useHumanValidatorTableData";

export const AdminRoles = [RoleEnum.ROOT];

export const NonAdminRoles = [
  RoleEnum.CC_OPS_ADMIN,
  RoleEnum.CC_OPS,
  RoleEnum.CC_SALES,
  RoleEnum.CC_PROCTORS,
];

export interface HumanValidatorTableVariableInterface {
  tableData: PendingEventRow | null;
  showDetails: boolean;
}

export const humanValidatorTableVariable =
  makeVar<HumanValidatorTableVariableInterface>({
    tableData: null,
    showDetails: false,
  });

export const hvConnection = makeVar(true);
