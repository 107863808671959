import { ApolloError, gql, useMutation } from "@apollo/client";
import uniqBy from "lodash/uniqBy";

import type {
  AddLicensePlateInput,
  BatchAddLicensePlateMutation,
  BatchAddLicensePlateMutationVariables,
} from "../../../../../API";
import { BATCH_ADD_LICENSE_PLATE } from "../../../../../common/operations/mutations";
import { errorNotification } from "../../../../../common/variables/notification";

export const useCreateBatchLicensePlate = () => {
  const [saveBatchLicensePlate, { data, loading }] = useMutation<
    BatchAddLicensePlateMutation,
    BatchAddLicensePlateMutationVariables
  >(BATCH_ADD_LICENSE_PLATE);

  const batchCreateLicensePlate = async (input: AddLicensePlateInput[]) => {
    return await saveBatchLicensePlate({
      variables: {
        input,
      },
      onError: (error: ApolloError): void => {
        errorNotification(error.message);

        console.error(error.message);
      },
      // todo: need to update cache correctly if there are multiple additional emails; currently it only one email that is added to cache
      update(cache, response) {
        cache.modify({
          fields: {
            getLicensePlatesByCustomer(existing) {
              const newLicensePlatesRefs =
                response?.data?.batchAddLicensePlate?.map(lp => {
                  return cache.writeFragment({
                    data: lp,
                    fragment: gql`
                      fragment newLicensePlate on LicensePlate {
                        id
                        categoryId
                        company
                        driverName
                        licensePlate
                      }
                    `,
                  });
                }) ?? [];

              if (!existing) {
                return response?.data;
              }

              const newLicensePlates = [
                ...existing.items,
                ...newLicensePlatesRefs,
              ];

              const uniqLicensePlates = uniqBy(newLicensePlates, "__ref");

              return {
                ...existing,
                items: uniqLicensePlates,
              };
            },
          },
        });
      },
    });
  };

  return {
    batchCreateLicensePlate,
    data,
    loading,
  };
};
