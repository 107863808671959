import { useMemo } from "react";

import dayjs from "dayjs";

import {
  ModelType,
  StatisticsDataInput,
  TimePeriod,
  eventType,
} from "../../../API";
import ApexOptions from "../../../common/components/apexchart/ApexChartModels";
import useLocationFromCache from "../../../common/hooks/useLocationFromCache";
import convertDatesHelper from "../helpers/convertDatesHelper";
import groupByPointer from "../helpers/groupByPointer";
import { IChartProps, IStatisticsResponseModel } from "../models/chart";
import { DEFAULT_STATISTICS_DATE_FORMAT } from "./useAllAlertsChart";
import { useGetStatisticsData } from "./useGetStatisticsData";
import { GasLeakContainerSteps } from "../components/GasLeakContainer";
import { useTheme } from "@mui/material";

type UseGasLeakReturnType = IChartProps & {
  firstDay: string | Date;
  refetchWithChosenValues: () => void;
  totalEmissionsCount: number;
  refetchWithSpecificData: (deviceData: IStatisticsResponseModel) => void;
  isZonesData: boolean;
  convertInstances: IStatisticsResponseModel[];
};

export const useGasLeakEmissionsChart = (
  timePeriod: TimePeriod,
  activeStep: number,
  locations: string[] | null
): UseGasLeakReturnType => {
  const theme = useTheme();

  const { getCachedLocation } = useLocationFromCache();

  const filterParams = useMemo(
    (): Pick<
      StatisticsDataInput,
      "eventType" | "models" | "timePeriod" | "locations"
    > => ({
      eventType: eventType.gasVolumes,
      models: [ModelType.gasleak],
      locations,
      timePeriod,
    }),
    [timePeriod, location, theme.palette.mode]
  );

  const {
    data,
    loading,
    refetching,
    refetchWithSpecificData,
    refetchWithChosenValues,
    refetchChartData,
  } = useGetStatisticsData<IStatisticsResponseModel[]>(filterParams);

  const isZonesData = data.some(el => typeof el.zoneId === "string");
  const getTotal = data.filter(el => el.category === "Total");
  const convertValues = getTotal.map((element): number | null =>
    element.volume ? +Number(element.volume).toFixed(4) : 0
  );

  const convertDates = convertDatesHelper(getTotal, timePeriod);

  const convertInstances = data.filter(el => el.category === "Instance");
  const groupedData = groupByPointer(convertInstances, "date");

  const tooltipInfo = (hoverIndex: number) => {
    const result =
      groupedData[hoverIndex]?.data.map((el: IStatisticsResponseModel) => {
        const locationName = el.location.includes("#L#")
          ? getCachedLocation(el.location ?? "")?.name ?? ""
          : el.location;

        return `
          <span style="display: flex; justify-content: space-between;"><span style="margin-right: 15px;">${locationName}. ${
          el.deviceName
        }</span><span class="apexcharts-tooltip-text-y-value">${+Number(
          el.volume
        ).toFixed(4)}</span></span>
        `;
      }) ?? [];

    return result.join("");
  };

  const lineSeries: ApexAxisChartSeries = useMemo(
    (): ApexAxisChartSeries => [
      {
        name: "Gasleak",
        data: convertValues,
      },
    ],
    [convertValues]
  );

  const polarAreaSeries = data.map(
    el => +Number(el.totalVolume).toFixed(4) * 100000
  );

  const correctSeries = isZonesData ? polarAreaSeries : lineSeries;

  const totalEmissionsCount = +data
    .reduce(
      (acc, elem) => (acc += elem?.totalVolume ? +elem.totalVolume : 0),
      0
    )
    .toFixed(4);

  const polarZonesBoardInfo = data.map(
    el => `
    <div style="display: flex; justify-content: space-between">
      <div>${el.zoneId}</div>
      <div style="margin-left: 15px;">
        <b>${+Number(el.totalVolume).toFixed(4)}</b>
      </div>
    </div>
`
  );

  const options: ApexOptions = useMemo(
    (): ApexOptions => ({
      chart: {
        type: "polarArea",
        stacked: false,
        width: "100%",
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        fontFamily: "Inter",
      },
      dataLabels: {
        enabled: false,
      },
      theme: {
        mode: theme.palette.mode,
      },
      labels: isZonesData ? polarZonesBoardInfo : undefined,
      stroke: {
        colors: ["#F3B344"],
        curve: "smooth",
        width: 2,
      },

      yaxis: {
        opposite: true,
        labels: polarAreaSeries && {
          formatter: function (value) {
            const result =
              activeStep === GasLeakContainerSteps.PolarAreaChart
                ? (+value.toString() / 100000).toString()
                : value;

            return `${Number(result).toFixed(4)}`;
          },
        },
      },
      xaxis: {
        tooltip: { enabled: false },
        categories: convertDates,
        tickAmount: 10,
      },
      markers: {
        size: 4,
        colors: ["#F3B344"],
      },
      tooltip: {
        followCursor: false,
        y: {
          formatter: undefined,
          title: {
            formatter: seriesName => {
              const getFirstPartOfSeria = seriesName.split(
                '<div style="margin-left: 15px;">'
              );

              if (activeStep === GasLeakContainerSteps.PolarAreaChart) {
                return seriesName;
              }

              return `${getFirstPartOfSeria[0]}</div>`;
            },
          },
        },
        custom:
          activeStep === GasLeakContainerSteps.PolarAreaChart
            ? undefined
            : ({ series, seriesIndex, dataPointIndex, w }) => {
                const hoverXaxis =
                  w.globals.seriesX[seriesIndex][dataPointIndex];

                const hoverIndexes = w.globals.seriesX.map((seriesX: any[]) => {
                  return seriesX.findIndex(
                    (xData: any) => xData === hoverXaxis
                  );
                });
                let hoverList = "";

                hoverIndexes.forEach(
                  (hoverIndex: number, seriesEachIndex: string | number) => {
                    if (hoverIndex >= 0) {
                      hoverList += `
                  <div style="display: flex; flex-direction: column">
                    <div class="apexcharts-tooltip-text">
                      <div style="display: flex; justify-content: space-between;">
                        <span style="margin-right: 15px;" class="apexcharts-tooltip-text-y-label apexcharts-tooltip-text-y-value">Total gas leak </span>
                        <span class="apexcharts-tooltip-text-y-value">${
                          w.globals.yLabelFormatters[0](
                            series[seriesEachIndex][hoverIndex]
                          ) ?? 0
                        }</span>
                      </div>

                      <hr>
                        ${tooltipInfo(hoverIndex)}
                    </div>
                    <hr>
                  </div>
                `;
                    }
                  }
                );

                let date = w.globals.categoryLabels[dataPointIndex];

                if (
                  dayjs(new Date()).format(DEFAULT_STATISTICS_DATE_FORMAT) ===
                  date
                ) {
                  date = "Today";
                }

                return `
                  <div style="position: relative; z-index: 999;">
                    ${hoverList}
                    <div class="tooltip-date">${date}</div>
                  </div>
                `;
              },
      },
    }),
    [convertDates, theme.palette.mode]
  );

  return {
    options,
    series: correctSeries,
    loading,
    firstDay: data[0]?.date,
    refetching,
    showNoData: !loading && !refetching && data.length === 0,
    totalEmissionsCount,
    convertInstances,
    refetchChartData,
    isZonesData,
    refetchWithSpecificData,
    refetchWithChosenValues,
  };
};
