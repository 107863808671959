import { useState } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { GridRowModel } from "@mui/x-data-grid";

import AddIconButton from "../../../../../../../common/components/icons/AddIconButton";
import EditIconButton from "../../../../../../../common/components/icons/EditIconButton";
import {
  ThresholdColumnState,
  ThresholdColumnStateEnum,
} from "../hooks/useGetZonesByDeviceTableColumns";
import { IZonesByDeviceTableRow } from "../hooks/useGetZonesByDeviceTableRow";
import DeleteThresholdValueContainer from "./DeleteThresholdValueContainer";
import ZoneTypesSelect from "./zone-types-select/ZoneTypesSelect";

type ZoneThresholdRowContainerProps = {
  row: GridRowModel<IZonesByDeviceTableRow>;
};

const ZoneThresholdRowContainer: React.FC<ZoneThresholdRowContainerProps> = ({
  row,
}) => {
  const [mode, setMode] = useState<ThresholdColumnState>(
    ThresholdColumnStateEnum.READ_MODE
  );

  if (mode === ThresholdColumnStateEnum.ADD_MODE) {
    return (
      <ZoneTypesSelect
        zoneId={row.id}
        zoneName={row.name}
        deviceId={row.deviceId}
        threshold={null}
        setMode={setMode}
        addMode
      />
    );
  }

  if (mode === ThresholdColumnStateEnum.EDIT_MODE) {
    return (
      <ZoneTypesSelect
        zoneId={row.id}
        zoneName={row.name}
        deviceId={row.deviceId}
        threshold={row?.threshold}
        setMode={setMode}
      />
    );
  }

  if (
    !row.threshold.name &&
    (!row.threshold.value || row.threshold.value === "0")
  ) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          position: "relative",
          "&:hover .addButton": {
            visibility: "visible",
          },
        }}
      >
        <Typography variant="body2">-</Typography>

        <Box
          className="addButton"
          aria-label="add"
          onClick={() => setMode(ThresholdColumnStateEnum.ADD_MODE)}
          sx={{
            visibility: "hidden",
            display: "flex",
            alignItems: "center",
          }}
        >
          <AddIconButton />
        </Box>
      </Box>
    );
  }

  let title = row.threshold.value;

  if (row.threshold.name) {
    title = `${row.threshold.value} MCF/d (${row.threshold.name})`;
  }

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        "&:hover .actionsButtons": {
          visibility: "visible",
        },
      }}
    >
      <Typography variant="body2">{title}</Typography>

      <Box
        className="actionsButtons"
        sx={{ display: "flex", gap: ".5em", visibility: "hidden" }}
      >
        <EditIconButton
          onClick={() => {
            setMode(ThresholdColumnStateEnum.EDIT_MODE);
          }}
        />
        <DeleteThresholdValueContainer row={row} setMode={setMode} />
      </Box>
    </Box>
  );
};

export default ZoneThresholdRowContainer;
