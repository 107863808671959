import { useEffect, useState } from "react";

import Box from "@mui/material/Box";

import { useAuthenticatedUser } from "../../../../../../../common/hooks/useAuthenticatedUser";
import { RoleEnum } from "../../../../../../../common/models/enums";
import { selectedKeyframeVariable } from "../../../../../../../common/variables/selectedKeyframe";
import { useSyncShadow } from "../../../../../../model-manager/hooks/useSyncShadow";
import { useGetZonesByDeviceTableColumns } from "../hooks/useGetZonesByDeviceTableColumns";
import { useGetZonesByDeviceTableRow } from "../hooks/useGetZonesByDeviceTableRow";
import ZoneListTable from "./ZoneListTable";
import { useListenToZoneUpdates } from "../../../../../../devices/zones-setup-page/hooks/useListenToZoneChanges";

export const ADMIN_ACCESS = [
  RoleEnum.ROOT,
  RoleEnum.CC_OPS_ADMIN,
  RoleEnum.CC_OPS,
  RoleEnum.CC_SALES,
  RoleEnum.PARTNER_ADMIN,
  RoleEnum.CUSTOMER_ADMIN,
  RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
];

export const NON_ADMIN_ACCESS = [
  RoleEnum.PARTNER_USER,
  RoleEnum.CUSTOMER_OPS_TEAM,
  RoleEnum.CUSTOMER_HSE,
  RoleEnum.CUSTOMER_SECURITY,
];

const ZoneListTableContainer = (): JSX.Element | null => {
  const [hasZoneBeenUpdated, setHasZoneBeenUpdated] = useState(false);
  const { syncShadowsForNode } = useSyncShadow();
  const { role } = useAuthenticatedUser();

  const { rows, loading } = useGetZonesByDeviceTableRow();
  const { columns, rowModesModel, setRowModesModelChange } =
    useGetZonesByDeviceTableColumns();

  useListenToZoneUpdates();

  useEffect((): void => {
    if (rows) {
      selectedKeyframeVariable(rows[0]?.keyFrame ?? "");
    }
  }, [loading]);

  useEffect(() => {
    return (): void => {
      if (hasZoneBeenUpdated) {
        syncShadowsForNode(rows[0]?.nodeId); // assume that the nodeId for all the zones in the table are the same; grab nodeId from first zone
      }
    };
  }, [hasZoneBeenUpdated]);

  const hasAdminAccess = ADMIN_ACCESS.includes(role);
  const hasNonAdminAccess = NON_ADMIN_ACCESS.includes(role);

  if (!hasAdminAccess && !hasNonAdminAccess) {
    return <Box padding="1.5em">You do not have access</Box>;
  }

  const nonAdminProps = {
    rows,
    columns,
    editMode: true,
    loading,
  };

  const adminProps = {
    ...nonAdminProps,
    editMode: true,
    rowModesModel,
    setRowModesModelChange,
  };

  const props = hasAdminAccess ? adminProps : nonAdminProps;

  return (
    <ZoneListTable {...props} setHasZoneBeenUpdated={setHasZoneBeenUpdated} />
  );
};

export default ZoneListTableContainer;
