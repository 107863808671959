import { Paper, Stack } from "@mui/material";

import HvReviewTable from "./components/HvReviewTable";

const HvReviewPage = (): JSX.Element => {
  return (
    <Stack spacing={4} direction="column">
      <Paper sx={{ padding: "1em" }} elevation={3}>
        <HvReviewTable />
      </Paper>
    </Stack>
  );
};

export default HvReviewPage;
