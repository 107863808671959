import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import useLocationFromCache from "../../../../common/hooks/useLocationFromCache";
import { IDeviceTableRow } from "../../hooks/useDeviceTableRows";
import ChipFilledPrimary from "../../../../common/components/chip/ChipFilledPrimary";
import ChipFilledOther from "../../../../common/components/chip/ChipFilledOther";

interface DeviceDetailViewModeProps {
  deviceDatum: IDeviceTableRow;
}

const DeviceDetailViewMode = ({
  deviceDatum,
}: DeviceDetailViewModeProps): JSX.Element => {
  const { getCachedLocation } = useLocationFromCache();
  const locationCache = getCachedLocation(deviceDatum.location.id ?? "");

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Table
        size="small"
        sx={{
          "& td": {
            padding: "8px 0px",
          },
        }}
      >
        <TableBody>
          <TableRow>
            <TableCell variant="head" sx={{ border: 0, width: "140px" }}>
              <Typography variant="inputLabel" component="span">
                DEVICE STATUS
              </Typography>
            </TableCell>

            <TableCell sx={{ border: 0, width: "500px" }}>
              {deviceDatum.isOnline ? (
                <ChipFilledPrimary label="Online" />
              ) : (
                <ChipFilledOther label="Offline" />
              )}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell variant="head" sx={{ border: 0, width: "140px" }}>
              <Typography variant="inputLabel" component="span">
                DEVICE MODEL
              </Typography>
            </TableCell>

            <TableCell sx={{ border: 0, width: "500px" }}>
              <Typography variant="body2Regular" component="span">
                {deviceDatum?.makeModelId}
              </Typography>
            </TableCell>
          </TableRow>

          {deviceDatum?.deviceData && deviceDatum?.deviceData?.sourceVideo && (
            <TableRow>
              <TableCell variant="head" sx={{ border: 0, width: "140px" }}>
                <Typography variant="inputLabel" component="span">
                  RTSP SOURCE
                </Typography>
              </TableCell>

              <TableCell sx={{ border: 0, width: "500px" }}>
                <Typography variant="body2Regular" component="span">
                  {deviceDatum?.deviceData?.sourceVideo}
                </Typography>
              </TableCell>
            </TableRow>
          )}
          {deviceDatum?.deviceData && deviceDatum?.deviceData?.rtspHost && (
            <TableRow>
              <TableCell variant="head" sx={{ border: 0, width: "140px" }}>
                <Typography variant="inputLabel" component="span">
                  RTSP CLIENT
                </Typography>
              </TableCell>

              <TableCell sx={{ border: 0, width: "500px" }}>
                <Typography variant="body2Regular" component="span">
                  {deviceDatum?.deviceData?.rtspHost}
                </Typography>
              </TableCell>
            </TableRow>
          )}
          {deviceDatum?.deviceData && deviceDatum?.deviceData?.hasPanTilt && (
            <TableRow>
              <TableCell variant="head" sx={{ border: 0, width: "140px" }}>
                <Typography variant="inputLabel" component="span">
                  PAN/TILT
                </Typography>
              </TableCell>

              <TableCell sx={{ border: 0, width: "500px" }}>
                <Typography variant="body2Regular" component="span">
                  {deviceDatum?.deviceData?.hasPanTilt ? "Yes" : "No"}
                </Typography>
              </TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell variant="head" sx={{ border: 0, width: "140px" }}>
              <Typography variant="inputLabel" component="span">
                LOCATION
              </Typography>
            </TableCell>

            <TableCell sx={{ border: 0, width: "500px" }}>
              <Typography variant="body2Regular" component="span">
                {locationCache?.name}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head" sx={{ border: 0, width: "140px" }}>
              <Typography variant="inputLabel" component="span">
                NODE ID
              </Typography>
            </TableCell>

            <TableCell sx={{ border: 0, width: "500px" }}>
              <Typography variant="body2Regular" component="span">
                {deviceDatum?.node.name}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
};

export default DeviceDetailViewMode;
