import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import Button from "@mui/material/Button";
import { useDialog } from "../../../../../common/components/dialog/useDialog";
import CreateCategoryDialog from "./plate-category/CreateCategoryDialog";

const NewCategoryButton = () => {
  const {
    open: openCreateCategoryDialog,
    handleDialogOpen,
    handleDialogClose,
  } = useDialog();

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        sx={{
          textTransform: "none",
          marginBottom: "0.5em",
          marginRight: "0.5em",
        }}
        startIcon={<AddOutlinedIcon />}
        onClick={handleDialogOpen}
      >
        New category
      </Button>
      <CreateCategoryDialog
        isOpened={openCreateCategoryDialog}
        closeDialog={handleDialogClose}
      />
    </>
  );
};

export default NewCategoryButton;
