import { gql, useMutation } from "@apollo/client";

import type {
  AddAlertWildCardSubscriptionInput,
  AddAlertWildCardSubscriptionMutation,
  AddAlertWildCardSubscriptionMutationVariables,
} from "../../../API";
import { ADD_ALERT_WILDCARD_SUBSCRIPTION } from "../../../common/operations/mutations";

export const useCreateWildCardSubscription = () => {
  const [saveWildCardSubscription, { data, loading }] = useMutation<
    AddAlertWildCardSubscriptionMutation,
    AddAlertWildCardSubscriptionMutationVariables
  >(ADD_ALERT_WILDCARD_SUBSCRIPTION);

  const createWildCardSubscription = async (
    input: AddAlertWildCardSubscriptionInput
  ) => {
    return await saveWildCardSubscription({
      variables: {
        input,
      },
      update(cache, response) {
        cache.modify({
          fields: {
            getWildCardSubscription(existing) {
              const newWildCardSubscriptionRef = cache.writeFragment({
                data: response.data?.addAlertWildCardSubscription,
                fragment: gql`
                  fragment NewWildCardSubscription on WildCardSubscription {
                    email
                    entity
                    wildCard
                  }
                `,
              });

              if (!existing) {
                return response?.data;
              }

              return {
                ...existing,
                items: [...existing.items, newWildCardSubscriptionRef],
              };
            },
          },
        });
      },
    });
  };

  return { createWildCardSubscription, data, loading };
};
