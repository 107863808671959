import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { ApolloError } from "@apollo/client";
import {
  Alert,
  Box,
  Divider,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import { useConfirm } from "material-ui-confirm";

import CircleArrowDown from "../../../common/components/icons/CircleArrowDown";
import CircleArrowLeft from "../../../common/components/icons/CircleArrowLeft";
import CircleArrowRight from "../../../common/components/icons/CircleArrowRight";
import CircleArrowUp from "../../../common/components/icons/CircleArrowUp";
import confirmDialogStyleOptions from "../../../common/helpers/confirmDialogParams";
import useBeforeUnload from "../../../common/hooks/useBeforeUnload";
import StyledLoadingButton from "../../../common/providers/theme/design-tokens/LoadingButton/StyledLoadingButton";
import {
  errorNotification,
  successNotification,
} from "../../../common/variables/notification";
import HLSStreamPlayer from "../../data-hub/components/liveview/components/cameraView/HLSStreamPlayer";
import { IDeviceTableRow } from "../hooks/useDeviceTableRows";
import { usePublishNode } from "../hooks/usePublishNode";
import { useCreateZone } from "./hooks/useCreateZone";
import {
  ICreateZoneValidationState,
  useZoneFormValidation,
} from "./hooks/useZoneFormValidation";
import { PAN_TILT_PATTERN } from "../../../common/variables/common";
import createS3KeyForZone from "../../../common/helpers/createS3KeyForZone";
import { validateZoneName } from "../../../common/helpers/validateZoneName";

const CreateZoneContent = (): JSX.Element => {
  const confirm = useConfirm();
  const { state } = useLocation();
  const navigate = useNavigate();

  const device = state?.deviceDatum as IDeviceTableRow;
  const { deviceId = "" } = useParams<{ deviceId: string }>();

  const [minutes, setMinutes] = useState("02");
  const [seconds, setSeconds] = useState("00");
  const [zoneName, setZoneName] = useState("");
  const [pan, setPan] = useState<number | string>(0);
  const [tilt, setTilt] = useState<number | string>(0);
  const [zoom, setZoom] = useState<number | string>(100);

  const {
    setValidationState,
    validateCreateZoneForm,
    validatePanTilt,
    validateZoom,
    validationState,
  } = useZoneFormValidation();

  const [disabled, setDisabled] = useState(false);

  const { publishNode } = usePublishNode();
  const { createZone, loading } = useCreateZone();

  const deviceName = device?.name;
  // We determine the serviceId by looking at the first model assigned to the device.
  // This serviceId is used when pausing and resuming models when editing a zone/moving pt
  // This will lead to error if a device has multiple models
  // But typically ogi's only have one model running so it is fine for PROD but buggy for testing on DEV
  const serviceId = device?.models[0]?.serviceId;
  const nodeId = device?.node.id;

  const saveZone = (): void => {
    const isValid = validateCreateZoneForm(
      zoneName,
      pan,
      tilt,
      zoom,
      minutes,
      seconds
    );

    if (!isValid) {
      return;
    }

    const device_name = device?.name.replace("DE#", "");

    if (!deviceName) {
      errorNotification("Error with device name. Please try again.");

      return;
    }

    const validName = validateZoneName(zoneName);

    if (!validName) {
      return;
    }

    createZone({
      deviceId: device.rowId ?? "TODO",
      name: zoneName,
      pan: String(pan),
      tilt: String(tilt),
      zoom: String(zoom),
      status: true,
      shouldNotify: true,
      inspectTime: `${
        minutes.length === 1 ? "0" + minutes : minutes
      }:${seconds}`,
      serviceId: serviceId ?? "TODO",
      nodeId: nodeId,
      keyFrame: createS3KeyForZone(nodeId, zoneName, device_name),
    })
      .then((response): void => {
        if (response?.data) {
          // save image to s3
          saveImage();

          successNotification(`${zoneName} has been created successfully`);

          navigate(`/device/${encodeURIComponent(deviceId)}`);

          console.log(response);
        }

        if (response.errors) {
          errorNotification();
        }
      })
      .catch((error): void => {
        if (
          (error as ApolloError)?.message.startsWith(
            "The conditional request failed"
          )
        ) {
          errorNotification("Zone name already exists");

          return;
        }

        errorNotification();

        console.error(error);
      });
  };

  const saveImage = (): void => {
    const device_name = device?.name.replace("DE#", "");

    publishNode({
      message: JSON.stringify({
        ACTION: "SAVE_IMAGE",
        s3_key: createS3KeyForZone(nodeId, zoneName, device_name), // generate s3_key e.g C_cleanconnect/C_cleanconnect_L_tp_N_solartrailer-agx4/keyframes/tp-ch4_keyframe.jpg // also need to save it to zone item
        TARGET: "CAMERA_MANAGER",
        device_id: device_name,
      }),
      nodeId,
    }).catch((error): void => {
      errorNotification("Something went wrong when saving image to S3");

      console.error(error);
    });
  };

  const movePtUp = (): void => {
    publishNode({
      message: JSON.stringify({
        TARGET: "PANTILT",
        ACTION: "MOVEUP_PT",
        device_name: deviceName,
      }),
      nodeId,
    });
  };

  const movePtDown = (): void => {
    publishNode({
      message: JSON.stringify({
        TARGET: "PANTILT",
        ACTION: "MOVEDOWN_PT",
        device_name: deviceName,
      }),
      nodeId,
    });
  };

  const movePtLeft = (): void => {
    publishNode({
      message: JSON.stringify({
        TARGET: "PANTILT",
        ACTION: "MOVELEFT_PT",
        device_name: deviceName,
      }),
      nodeId,
    });
  };

  const movePtRight = (): void => {
    publishNode({
      message: JSON.stringify({
        TARGET: "PANTILT",
        ACTION: "MOVERIGHT_PT",
        device_name: deviceName,
      }),
      nodeId,
    });
  };

  const handleGoToPt = (
    pan: number | string,
    tilt: number | string,
    zoom: number | string
  ): void => {
    const isValid = validatePanTilt(pan, tilt);

    const isValidZoom = validateZoom(zoom);

    if (!isValid && !isValidZoom) {
      return;
    }

    publishNode({
      message: JSON.stringify({
        TARGET: "PANTILT",
        ACTION: "GOTO_PT",
        device_name: deviceName,
        pan: pan,
        tilt: tilt,
        zoom: zoom,
      }),
      nodeId,
    });

    setDisabled(true);

    setTimeout((): void => {
      setDisabled(false);
    }, 10000);
  };

  const handleZoneNameInputChange = (zoneName?: string): void => {
    setValidationState(
      (prev): ICreateZoneValidationState => ({
        ...prev,
        locationName: {
          hasError: false,
          errorMessage: "",
        },
      })
    );

    setZoneName(zoneName ?? "");
  };

  const handlePanInputChange = (pan = ""): void => {
    setValidationState(
      (prev): ICreateZoneValidationState => ({
        ...prev,
        pan: {
          hasError: false,
          errorMessage: "",
        },
        panValue: {
          hasError: false,
          errorMessage: "",
        },
      })
    );

    if (PAN_TILT_PATTERN.test(pan)) {
      setPan(pan);
    }
  };

  const handleTiltInputChange = (tilt = ""): void => {
    setValidationState(
      (prev): ICreateZoneValidationState => ({
        ...prev,
        tilt: {
          hasError: false,
          errorMessage: "",
        },
        tiltValue: {
          hasError: false,
          errorMessage: "",
        },
      })
    );

    if (PAN_TILT_PATTERN.test(tilt)) {
      setTilt(tilt);
    }
  };

  const handleZoomInputChange = (zoom = ""): void => {
    setValidationState(
      (prev): ICreateZoneValidationState => ({
        ...prev,
        zoom: {
          hasError: false,
          errorMessage: "",
        },
        zoomValue: {
          hasError: false,
          errorMessage: "",
        },
      })
    );

    setZoom(zoom);
  };

  const handleMinutesInputChange = (minutes?: string): void => {
    setValidationState(
      (prev): ICreateZoneValidationState => ({
        ...prev,
        minutes: {
          hasError: false,
          errorMessage: "",
        },
      })
    );

    setMinutes(minutes ?? "");
  };

  const handleSecondsInputChange = (seconds?: string): void => {
    setValidationState(
      (prev): ICreateZoneValidationState => ({
        ...prev,
        seconds: {
          hasError: false,
          errorMessage: "",
        },
      })
    );

    setSeconds(seconds ?? "");
  };

  const confirmCancel = (): void => {
    confirm({
      title: "Are you sure you want to cancel Zone creation?",
      confirmationText: "Cancel",
      cancellationText: "Back",
      ...confirmDialogStyleOptions,
      confirmationButtonProps: {
        ...confirmDialogStyleOptions.confirmationButtonProps,
        disabled: loading,
        color: "primary",
      },
    }).then(async (): Promise<void> => {
      navigate(`/device/${encodeURIComponent(deviceId)}`);
    });
  };

  // TODO: implement another way to handle url change
  // const confirmUrlChange = (path: string): void => {
  //   confirm({
  //     title: "If you leave, the new zone won't be saved.",
  //     content: "Go back to save the changes.",
  //     confirmationText: "Leave",
  //     cancellationText: "Cancel",
  //     ...confirmDialogStyleOptions,
  //     confirmationButtonProps: {
  //       ...confirmDialogStyleOptions.confirmationButtonProps,
  //       color: "primary",
  //     },
  //   }).then((): void => {
  //     resetValidationState();
  //     navigate(path);
  //   });
  // };

  // useUrlChange(confirmUrlChange);

  useBeforeUnload();

  return (
    <>
      <Paper
        sx={{
          padding: "2em",
          borderRadius: "12px",
          border: (theme): string =>
            `1px solid ${theme.palette.otherBackgroundLight.main}`,
        }}
        elevation={0}
      >
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <Stack direction="column" spacing={2}>
              <TextField
                margin="dense"
                label="Zone name"
                value={zoneName}
                error={validationState.locationName.hasError}
                helperText={validationState.locationName.errorMessage}
                onChange={(e): void =>
                  handleZoneNameInputChange(e.target.value)
                }
              />

              <Typography variant="subtitle1">
                Set up the camera position
              </Typography>

              {validationState.panValue.hasError && (
                <Alert severity="error">
                  {validationState.panValue.errorMessage}
                </Alert>
              )}
              {validationState.tiltValue.hasError && (
                <Alert severity="error">
                  {validationState.tiltValue.errorMessage}
                </Alert>
              )}
              {validationState.zoomValue.hasError && (
                <Alert severity="error">
                  {validationState.zoomValue.errorMessage}
                </Alert>
              )}

              <Box sx={{ display: "flex" }}>
                <Table size="small" sx={{ width: "240px" }}>
                  <TableBody>
                    <TableRow
                      sx={{
                        "& td": {
                          border: 0,
                          padding: 0,
                        },
                        "& .small": {
                          width: "32px",
                          height: "32px",
                        },
                        "& .pan": {
                          paddingLeft: "1.5em",
                        },
                        "& .tilt": {
                          paddingLeft: "0.5em",
                        },
                      }}
                    >
                      <TableCell className="small"></TableCell>
                      <TableCell className="small">
                        <CircleArrowUp
                          width={32}
                          height={32}
                          fontSize="large"
                          onClick={movePtUp}
                        />
                      </TableCell>
                      <TableCell className="small"></TableCell>
                      <TableCell rowSpan={3} className="pan tilt">
                        <Box
                          sx={{
                            display: "flex",
                            marginLeft: "10px",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            variant="inputLabel"
                            sx={{ position: "absolute" }}
                            component="span"
                            color="otherTextTertiary.main"
                          >
                            Pan
                          </Typography>
                          <TextField
                            type="text"
                            error={validationState?.pan.hasError}
                            helperText={validationState?.pan.errorMessage}
                            InputProps={{ sx: { borderRadius: "8px" } }}
                            sx={{
                              minWidth: "72px",
                              maxWidth: "76px",
                              position: "relative",
                              left: "30px",
                              "& input": {
                                borderRadius: "8px",
                                height: "18px",
                                "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button":
                                  {
                                    "-webkit-appearance": "none",
                                    margin: 0,
                                  },
                              },
                            }}
                            id="pan"
                            size="small"
                            variant="outlined"
                            margin="dense"
                            value={pan}
                            onChange={(e): void =>
                              handlePanInputChange(e.target.value)
                            }
                          />
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            marginLeft: "10px",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            variant="inputLabel"
                            sx={{ position: "absolute" }}
                            component="span"
                            color="otherTextTertiary.main"
                          >
                            Tilt
                          </Typography>
                          <TextField
                            type="text"
                            error={validationState?.tilt.hasError}
                            helperText={validationState?.tilt.errorMessage}
                            InputProps={{ sx: { borderRadius: "8px" } }}
                            sx={{
                              minWidth: "72px",
                              maxWidth: "76px",
                              position: "relative",
                              left: "30px",
                              "& input": {
                                height: "18px",
                                "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button":
                                  {
                                    "-webkit-appearance": "none",
                                    margin: 0,
                                  },
                              },
                            }}
                            id="tilt"
                            size="small"
                            variant="outlined"
                            margin="dense"
                            value={tilt}
                            onChange={(e): void =>
                              handleTiltInputChange(e.target.value)
                            }
                          />
                        </Box>
                        {device.deviceData.hasZoom && (
                          <Box
                            sx={{
                              display: "flex",
                              marginLeft: "0px",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              variant="inputLabel"
                              sx={{ position: "absolute" }}
                              component="span"
                              color="otherTextTertiary.main"
                            >
                              Zoom
                            </Typography>
                            <TextField
                              type="text"
                              error={validationState?.zoom.hasError}
                              helperText={validationState?.zoom.errorMessage}
                              InputProps={{ sx: { borderRadius: "10px" } }}
                              sx={{
                                minWidth: "72px",
                                maxWidth: "76px",
                                position: "relative",
                                left: "40px",
                                "& input": {
                                  height: "18px",
                                  "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button":
                                    {
                                      "-webkit-appearance": "none",
                                      margin: 0,
                                    },
                                },
                              }}
                              id="zoom"
                              size="small"
                              variant="outlined"
                              margin="dense"
                              value={zoom}
                              onChange={(e): void =>
                                handleZoomInputChange(e.target.value)
                              }
                            />
                          </Box>
                        )}
                      </TableCell>
                      <TableCell rowSpan={3}></TableCell>
                    </TableRow>

                    <TableRow
                      sx={{
                        "& td": {
                          border: 0,
                          padding: 0,
                        },
                        "& .small": {
                          width: "32px",
                          height: "32px",
                        },
                      }}
                    >
                      <TableCell className="small">
                        <CircleArrowLeft
                          width={32}
                          height={32}
                          fontSize="large"
                          onClick={movePtLeft}
                        />
                      </TableCell>
                      <TableCell className="small"></TableCell>
                      <TableCell className="small">
                        <CircleArrowRight
                          width={32}
                          height={32}
                          fontSize="large"
                          onClick={movePtRight}
                        />
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>

                    <TableRow
                      sx={{
                        "& td": {
                          border: 0,
                          padding: 0,
                        },
                        "& .small": {
                          width: "32px",
                          height: "32px",
                        },
                      }}
                    >
                      <TableCell className="small"></TableCell>
                      <TableCell className="small">
                        <CircleArrowDown
                          width={32}
                          height={32}
                          fontSize="large"
                          onClick={movePtDown}
                        />
                      </TableCell>
                      <TableCell className="small"></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableBody>
                </Table>

                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Button
                    variant="text"
                    disabled={!pan || !tilt || disabled}
                    onClick={(): void => handleGoToPt(pan, tilt, zoom)}
                    size="small"
                  >
                    Apply
                  </Button>
                </Box>
              </Box>

              <Divider flexItem />

              <Typography variant="subtitle1">
                Set inspection time for this zone
              </Typography>
              <Typography variant="caption">Min 30sec., Max 6 min</Typography>

              {validationState.minutes.hasError && (
                <Alert severity="error">
                  {validationState.minutes.errorMessage}
                </Alert>
              )}
              {validationState.seconds.hasError && (
                <Alert severity="error">
                  {validationState.seconds.errorMessage}
                </Alert>
              )}

              <Table
                size="small"
                sx={{
                  width: "148px",
                }}
              >
                <TableHead>
                  <TableRow
                    sx={{
                      "& th": {
                        border: 0,
                        padding: 0,
                        width: "72px",
                      },
                      "& .small": {
                        width: "4px",
                      },
                    }}
                  >
                    <TableCell>
                      <Typography variant="inputLabel">Minutes</Typography>
                    </TableCell>
                    <TableCell className="small"></TableCell>
                    <TableCell>
                      <Typography variant="inputLabel">Seconds</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    sx={{
                      "& td": {
                        border: 0,
                        padding: 0,
                        width: "72px",
                      },
                      "& .small": {
                        width: "4px",
                      },
                    }}
                  >
                    <TableCell>
                      <TextField
                        sx={{
                          width: "72px",
                          "& input": {
                            height: "18px",
                          },
                        }}
                        size="small"
                        variant="outlined"
                        margin="dense"
                        value={minutes}
                        error={validationState.minutes.hasError}
                        onChange={(e): void =>
                          handleMinutesInputChange(e.target.value ?? 0)
                        }
                      />
                    </TableCell>
                    <TableCell className="small">:</TableCell>
                    <TableCell>
                      <TextField
                        sx={{
                          width: "72px",
                          "& input": {
                            height: "18px",
                          },
                        }}
                        size="small"
                        variant="outlined"
                        margin="dense"
                        value={seconds}
                        error={validationState.seconds.hasError}
                        onChange={(e): void =>
                          handleSecondsInputChange(e.target.value)
                        }
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6}>
            <HLSStreamPlayer />
          </Grid>
        </Grid>
      </Paper>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "1em",
        }}
      >
        <StyledLoadingButton
          sx={{
            marginTop: "1.5em",
          }}
          loading={loading}
          loadingPosition="start"
          variant="outlined"
          color="inherit"
          onClick={confirmCancel}
        >
          Cancel
        </StyledLoadingButton>
        <StyledLoadingButton
          sx={{
            marginTop: "1.5em",
          }}
          loading={loading}
          loadingPosition="start"
          variant="contained"
          color="primary"
          onClick={saveZone}
        >
          Create zone
        </StyledLoadingButton>
      </Box>
    </>
  );
};

export default CreateZoneContent;
