import Box from "@mui/material/Box";
import List from "@mui/material/List";
import GLVLocationItem from "./GLVLocationItem";
import GLVDeviceItem from "./GLVDeviceItem";
import { IStatisticsResponseModel } from "../../../../pages/statistics/models/chart";
import { GasLeakContainerSteps } from "../../../../pages/statistics/components/GasLeakContainer";

type LocationListProps = {
  onChangeHandler: (
    step: GasLeakContainerSteps,
    device: IStatisticsResponseModel
  ) => void;
  groupedData: { location: string; data: IStatisticsResponseModel[] }[];
};

const GLVLocationList: React.FC<LocationListProps> = ({
  groupedData,
  onChangeHandler,
}): JSX.Element => {
  return (
    <Box sx={{ width: "100%", marginTop: "10px" }}>
      <List
        sx={{
          width: "100%",
          padding: 0,
        }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        {groupedData.map(
          (elem): JSX.Element => (
            <GLVLocationItem key={elem.location} location={elem.location}>
              {elem.data.map(
                (device): JSX.Element => (
                  <GLVDeviceItem
                    key={device.deviceName + String(Math.random() * 50 + 3)}
                    device={device}
                    onChangeHandler={onChangeHandler}
                  />
                )
              )}
            </GLVLocationItem>
          )
        )}
      </List>
    </Box>
  );
};

export default GLVLocationList;
