import { Box, Dialog, Paper, TextField, Typography } from "@mui/material";

import { useState } from "react";
import { SecretsManagerHelper } from "../../../common/utils/secretsmanager";
import { useCustomerIdGuard } from "../../../common/hooks/useCustomerIdGuard";
import BreadcrumbNavigation from "../../../common/components/tabs/BreadcrumbNavigation";
import { useLocation } from "react-router-dom";
import { RouteEnum } from "../../../common/models/enums";

const AzureIntegrationPage = (): JSX.Element => {
  const [containerName, setContainerName] = useState("");
  const [connectionString, setConnectionString] = useState("");
  const [isDisconnectModalVisible, setIsDisconnectModalVisible] =
    useState(false);
  const customerId = useCustomerIdGuard();
  const location = useLocation();

  const handleConnect = (): void => {
    console.log("handleConnect");
    const connectionStringSecret = {
      connectionString,
    };

    const containerNameSecret = {
      containerName,
    };

    // todo: if setSecret fails due to existing, we need to call putSecret
    // set connection string secret
    SecretsManagerHelper.setSecret({
      Name: `integration-${customerId.replace(
        "#",
        "_"
      )}-azure-connectionstring`,
      Description: "integration customer azure connectionstring",
      SecretString: JSON.stringify(connectionStringSecret),
    });

    // set container name
    SecretsManagerHelper.setSecret({
      Name: `integration-${customerId.replace("#", "_")}-azure-containername`,
      Description: "integration customer azure containername",
      SecretString: JSON.stringify(containerNameSecret),
    });
  };

  const integrationPath = location.pathname.includes("sensory")
    ? RouteEnum.SensoryIntegrations
    : RouteEnum.Integrations;

  const breadcrumbItems = [
    { label: "Integrations", path: integrationPath },
    {
      label: "Azure",
    },
  ];

  return (
    <>
      <Dialog
        open={isDisconnectModalVisible}
        onClose={(): void => setIsDisconnectModalVisible(false)}
      >
        <Typography variant="h3" marginBottom={2}>
          Are you sure you want to disconnect Azure integration?
        </Typography>
        <Typography marginBottom={2}>
          After you disconnect the integration you won&apos;t be receving any
          notifications from there. You can connect back at any time.
        </Typography>
        <button>Cancel</button>
        <button>Disconnect</button>
      </Dialog>

      <Box>
        <Box padding="0" marginBottom="1em">
          <BreadcrumbNavigation items={breadcrumbItems} />
          <Box
            style={{
              display: "flex",
              marginTop: "1em",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h3" marginBottom={2}>
              Azure
            </Typography>
            <button
              onClick={(): void => {
                setIsDisconnectModalVisible(true);
              }}
            >
              Disconnect
            </button>
          </Box>

          <Paper style={{ padding: 16 }}>
            <Typography style={{ fontWeight: "bold" }}>
              Connection Details
            </Typography>
            <Box display="flex" flexDirection="column">
              <TextField
                required
                id="container-name"
                label="Container name"
                value={containerName}
                onChange={(e: any): void => setContainerName(e.target.value)}
              />
              <TextField
                required
                id="connection-string"
                label="Connection string"
                value={connectionString}
                onChange={(e: any): void => setConnectionString(e.target.value)}
              />
            </Box>
            <Typography style={{ fontWeight: "bold" }}>
              Switch on notifications you &apos;d like to receive
            </Typography>
            <Box flexDirection="column" display="flex">
              <div>toggle button table</div>
              <button>toggleEventRoutersAlerts</button>
              <button>toggleNodeConfigAlerts</button>
            </Box>
            <Box>
              <button>Cancel</button>
              <button onClick={(): void => handleConnect()}>
                Connect Azure
              </button>
            </Box>
          </Paper>
        </Box>
      </Box>
    </>
  );
};

export default AzureIntegrationPage;
