import { Box, Tooltip, Typography, Zoom } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import { useConfirm } from "material-ui-confirm";

import DeleteIconButton from "../../../../../common/components/icons/DeleteIconButton";
import confirmDialogStyleOptions from "../../../../../common/helpers/confirmDialogParams";
import { useDeleteLocation } from "../hooks/useDeleteLocation";
import { Device } from "../../../../../API";

interface IDeleteLocationContainerProps {
  locationId: string;
  devices: Device[];
  name: string;
}

const DeleteLocationContainer = ({
  locationId,
  name,
  devices,
}: IDeleteLocationContainerProps): JSX.Element => {
  const confirm = useConfirm();

  const { deleteLocation, loading } = useDeleteLocation(name);
  const connectedDevices = devices;

  const handleClick = (): void => {
    confirm({
      title: `Are you sure you want to delete ${name} location?`,
      confirmationText: "Delete",
      cancellationText: "Cancel",
      ...confirmDialogStyleOptions,
      confirmationButtonProps: {
        ...confirmDialogStyleOptions.confirmationButtonProps,
        disabled: loading,
      },
    }).then(async (): Promise<void> => {
      await deleteLocation(locationId);
    });
  };

  const disabled = !isEmpty(connectedDevices);

  return (
    <Tooltip
      TransitionComponent={Zoom}
      arrow
      disableInteractive
      placement="left"
      title={
        disabled ? (
          <Box textAlign="center">
            <Typography variant="tooltip">
              You cannot delete the location if there are connected devices to
              it
            </Typography>
          </Box>
        ) : null
      }
    >
      <Box
        component="span"
        sx={{
          height: "32px",
        }}
      >
        <DeleteIconButton onClick={handleClick} disabled={disabled} />
      </Box>
    </Tooltip>
  );
};

export default DeleteLocationContainer;
