import { useMemo } from "react";
import { useQuery } from "@apollo/client";

import type {
  GetCustomerCountersQuery,
  GetCustomerCountersQueryVariables,
} from "../../API";
import { GET_CUSTOMER_COUNTERS } from "../../common/operations/queries";
import { useCustomerExternalIdGuard } from "../../common/hooks/useCustomerExternalIdGuard";

export const useGetCustomerCounters = () => {
  const customerExternalId = useCustomerExternalIdGuard();

  const { data, loading, error } = useQuery<
    GetCustomerCountersQuery,
    GetCustomerCountersQueryVariables
  >(GET_CUSTOMER_COUNTERS, {
    variables: {
      customerExternalId,
    },
    skip: !customerExternalId,
    fetchPolicy: "cache-and-network",
    onError: error => {
      console.error("GetCustomer", error);
    },
  });

  const customerCounters = useMemo(
    () => data?.getCustomerCounters,
    [data?.getCustomerCounters]
  );

  return { customerCounters, loading, error } as const;
};
