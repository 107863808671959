import {
  type FetchResult,
  type OperationVariables,
  useMutation,
} from "@apollo/client";

import type {
  ValidateEventMutation,
  ValidateEventMutationVariables,
} from "../../../../../web/src/API";
import { VALIDATE_EVENT } from "../../../common/operations/mutations";

export const useValidateEvent = (): [
  validateEventItem: ({
    input: { customerId, eventId, eventTime, explanation, real },
  }: ValidateEventMutationVariables) => Promise<
    FetchResult<ValidateEventMutation>
  >,
  variables: OperationVariables
] => {
  const [validateEvent, { data, loading, error }] = useMutation(VALIDATE_EVENT);

  const validateEventItem = async ({
    input: { customerId, eventId, eventTime, explanation, real, eventType },
  }: ValidateEventMutationVariables): Promise<
    FetchResult<ValidateEventMutation>
  > => {
    return await validateEvent({
      variables: {
        input: {
          customerId,
          eventId,
          eventTime,
          explanation,
          real,
          eventType,
        },
      },
    });
  };

  return [validateEventItem, { data, loading, error }];
};
