import { useState } from "react";

import {
  Box,
  Button,
  CircularProgress,
  Typography,
  useTheme,
} from "@mui/material";

import ContactsDialog from "./trial/ContactsDialog";
import { AllowLeakFinder } from "../../API";
import { useGetCustomerCounters } from "../hooks/useGetCustomerCounters";

const UploadLimitNotice = () => {
  const [limitNoticeDialogState, setLimitNoticeDialogState] = useState({
    reviewed: false,
    open: false,
  });

  const handleContactsDialogClose = (): void => {
    setLimitNoticeDialogState({ ...limitNoticeDialogState, open: false });
  };

  const handleContactsDialogOpen = () => {
    setLimitNoticeDialogState({
      reviewed: true,
      open: true,
    });
  };

  const theme = useTheme();

  const { customerCounters } = useGetCustomerCounters();

  if (customerCounters?.allowLeakFinder !== AllowLeakFinder.TRIAL_ACCESS) {
    return null;
  }

  let backgroundBarColor = theme.palette.otherTextTertiary.main;
  let runOutText = "";

  const { usageLeakFinderCount, totalLeakFinderLimit } = customerCounters;

  const usageLimit = usageLeakFinderCount || 0;
  const totalCount = totalLeakFinderLimit || 0;
  const isLimitRunOut = usageLimit <= totalCount;

  if (isLimitRunOut) {
    backgroundBarColor = theme.palette.primary.main;

    runOutText = `You run out ${usageLimit} free videos. \n`;

    if (!limitNoticeDialogState.reviewed) {
      handleContactsDialogOpen();
    }
  }

  const textNotice = (
    <Typography sx={{ color: theme.palette.primary.contrastText }}>
      You uploaded
      <Typography variant="body1Bold">
        {` ${totalCount}/${usageLimit} `}
      </Typography>
      free videos | Contact sales team to get unlimited access
    </Typography>
  );

  return (
    <>
      <Box
        id="upload-limit-notice"
        sx={{
          height: "50px",
          backgroundColor: backgroundBarColor,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {customerCounters && (
          <>
            <Box>{textNotice}</Box>

            <Button
              sx={{
                marginLeft: "1em",
                height: "32px",
                width: "120px",
                backgroundColor: theme.palette.primary.contrastText,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                ":hover": {
                  backgroundColor: theme.palette.primary.contrastText,
                  "@media (hover: none)": {
                    backgroundColor: theme.palette.primary.contrastText,
                  },
                },
              }}
              variant="outlined"
              onClick={handleContactsDialogOpen}
            >
              Contact sales
            </Button>
          </>
        )}

        {!customerCounters && (
          <CircularProgress size={14} color="secondary" thickness={6} />
        )}
      </Box>

      <ContactsDialog
        runOutText={runOutText}
        open={limitNoticeDialogState.open}
        handleClose={handleContactsDialogClose}
      />
    </>
  );
};

export default UploadLimitNotice;
