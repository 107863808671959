import { useEffect } from "react";

import { useLazyQuery } from "@apollo/client";

import {
  GetHumanValidatedEventsForAuditingQuery,
  GetHumanValidatedEventsForAuditingQueryVariables,
} from "../../../API";
import { GET_EVENTS_FOR_AUDITING_HV } from "../../../common/operations/queries";
import { errorNotification } from "../../../common/variables/notification";
import client from "../../../configs/apolloClient";
import { DEFAULT_LIMIT_COUNT } from "../../../common/variables/common";

export const AUDIT_TABLE_LIMIT = 10;

interface GetHumanValidatedEventsForAuditingInterface {
  data?: GetHumanValidatedEventsForAuditingQuery;
  loading: boolean;
}

export const useGetHumanValidatedEventsForAuditing =
  (): GetHumanValidatedEventsForAuditingInterface => {
    useEffect((): void => {
      fetchEventsForAuditing(DEFAULT_LIMIT_COUNT);
    }, []);

    const [getEventsForAuditing, { data, loading }] = useLazyQuery<
      GetHumanValidatedEventsForAuditingQuery,
      GetHumanValidatedEventsForAuditingQueryVariables
    >(GET_EVENTS_FOR_AUDITING_HV, {
      fetchPolicy: "network-only",
    });

    const handleRequestError = async (): Promise<void> => {
      await client.cache.reset();

      errorNotification();
    };

    const fetchEventsForAuditing = (
      limit: number,
      nextToken?: string
    ): void => {
      getEventsForAuditing({
        variables: {
          limit,
          nextToken,
        },
      })
        .then(async (response): Promise<void> => {
          if (response.data?.getHumanValidatedEventsForAuditing.nextToken) {
            fetchEventsForAuditing(
              DEFAULT_LIMIT_COUNT,
              response.data?.getHumanValidatedEventsForAuditing.nextToken
            );
          }

          if (response.error) {
            await handleRequestError();
          }
        })
        .catch(async (): Promise<void> => {
          await handleRequestError();
        });
    };

    return { data, loading };
  };
