import { useEffect, useMemo } from "react";

import { gql } from "@apollo/client";

import { ValidationState } from "../../../API";
import { notificationVariable } from "../../../common/variables/notification";
import client from "../../../configs/apolloClient";
import { useListenToHumanValidationEvents } from "../../human-validator/hooks/useListenToHumanValidationEvents";
import { useGetHumanValidatedEventsForAuditing } from "./useGetHumanValidatedEventsForAuditing";
import { DEFAULT_LIMIT_COUNT } from "../../../common/variables/common";

export interface IHvReviewTableRow {
  rowId?: string;
  eventTime?: string;
  media: any;
  decision?: ValidationState | null;
  location?: string | null;
  reviewer?: string | null;
  explanation?: string | null;
}

const validationStatesForAuditing = [
  ValidationState.REAL,
  ValidationState.NOT_REAL,
];

export const useHvReviewTableRows = () => {
  const { data: dataSub } = useListenToHumanValidationEvents();

  const { data, loading } = useGetHumanValidatedEventsForAuditing();

  useEffect((): void => {
    if (
      dataSub &&
      validationStatesForAuditing.includes(
        dataSub.listenToHumanValidationEvents
          ?.validationState as ValidationState
      )
    ) {
      client.cache.modify({
        fields: {
          getHumanValidatedEventsForAuditing(existing) {
            const newAuditItemRef = client.cache.writeFragment({
              data: dataSub.listenToHumanValidationEvents,
              fragment: gql`
                fragment NewEventForAuting on HumanValidationEvent {
                  audited_by
                  auditorsExplanation
                  customerId
                  data
                  eventTime
                  explanation
                  id
                  locationId
                  nodeId
                  serviceId
                  transitionInfo
                  validated_by
                  validationState
                }
              `,
            });

            if (!existing) {
              return {
                __typename: "PaginatedHumanValidationEvents",
                items: [newAuditItemRef],
                limit: DEFAULT_LIMIT_COUNT,
                nextToken: null,
              };
            }

            return {
              ...existing,
              items: [...existing.items, newAuditItemRef],
            };
          },
        },
      });

      notificationVariable({
        isOpen: true,
        message: `Event ${dataSub?.listenToHumanValidationEvents?.id} was validated`,
        severity: "info",
      });
    }
  }, [dataSub]);

  const rows = useMemo((): IHvReviewTableRow[] => {
    const tableData: IHvReviewTableRow[] =
      data?.getHumanValidatedEventsForAuditing?.items?.map(
        (item): IHvReviewTableRow => {
          let data: any;

          try {
            data = JSON.parse(item?.data as string);
          } catch (error) {
            data = null;
          }

          return {
            rowId: item?.id,
            eventTime: item?.eventTime,
            media: data?.mediaOutput,
            decision: item?.validationState,
            location: item?.locationId,
            reviewer: item?.validated_by,
            explanation: item?.explanation,
          };
        }
      ) ?? ([] as IHvReviewTableRow[]);

    return tableData;
  }, [data?.getHumanValidatedEventsForAuditing?.items]);

  return { rows, loading };
};
