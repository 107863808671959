import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useConfirm } from "material-ui-confirm";
import { SubmitHandler, useForm } from "react-hook-form";

import FullPageLoader from "../../../../../../common/components/item/FullPageLoader";
import MainPaperWrapper from "../../../../../../common/components/item/MainPaperWrapper";
import BreadcrumbNavigation from "../../../../../../common/components/tabs/BreadcrumbNavigation";
import confirmDialogStyleOptions from "../../../../../../common/helpers/confirmDialogParams";
import { useCustomerIdGuard } from "../../../../../../common/hooks/useCustomerIdGuard";
import usePlateManagementNavigationRoute from "../../../../../../common/hooks/usePlateManagementNavigationRoute";
import { RouteEnum } from "../../../../../../common/models/enums";
import StyledLoadingButton from "../../../../../../common/providers/theme/design-tokens/LoadingButton/StyledLoadingButton";
import {
  errorNotification,
  successNotification,
} from "../../../../../../common/variables/notification";
import { useGetCategory } from "../../hooks/useGetCategory";
import { useUpdateCategory } from "../../hooks/useUpdateCategory";
import CategoryForm, { IPlateCategoryForm } from "./CategoryForm";

const EditCategoryPage = () => {
  const { getNavigationRoute } = usePlateManagementNavigationRoute();

  const recentActivitiesPlatesManagementNav = getNavigationRoute(
    RouteEnum.RecentActivitiesPlatesManagement,
    RouteEnum.SensoryRecentActivitiesPlatesManagement
  );
  const recentActivitiesNav = getNavigationRoute(
    RouteEnum.RecentActivities,
    RouteEnum.SensoryRecentActivities
  );
  const gateGuardNav = getNavigationRoute(RouteEnum.Agg, RouteEnum.SensoryAgg);
  const navigate = useNavigate();
  const confirm = useConfirm();

  const selectedCustomerId = useCustomerIdGuard();

  const [searchParams] = useSearchParams();

  const categoryId = searchParams.get("categoryId") ?? "";

  const defaultValues = {
    category: "",
  };

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IPlateCategoryForm>({ defaultValues });

  const { updateCategory, loading: updateCategoryLoading } =
    useUpdateCategory();

  const {
    data,
    refetch,
    loading: getCategoryLoading,
  } = useGetCategory({
    categoryId: categoryId,
    customerId: selectedCustomerId,
  });

  useEffect(() => {
    if (selectedCustomerId && categoryId) {
      refetch();
    }
  }, [selectedCustomerId, categoryId]);

  useEffect(() => {
    if (data) {
      const categoryName = data.getCategoryById?.categoryName ?? "";

      setValue("category", categoryName);
    }
  }, [data?.getCategoryById]);

  const onSubmit: SubmitHandler<IPlateCategoryForm> = formData => {
    if (!data) return;

    const category = data.getCategoryById;

    updateCategory({
      categoryId: category?.id ?? "",
      categoryName: formData.category,
      customerId: selectedCustomerId,
      isAlertEnabled: category?.isAlertEnabled ?? false,
    })
      .then((response): void => {
        if (!response.errors) {
          successNotification("Changes are saved");

          navigate(recentActivitiesPlatesManagementNav);
        }
      })
      .catch((error: Error): void => {
        errorNotification(error.message);

        console.error(error);
      });
  };

  const confirmCancel = (path = recentActivitiesPlatesManagementNav): void => {
    confirm({
      title: "Are you sure you want to cancel editing?",
      description: "If you leave, the new informations won’t be saved",
      confirmationText: "Leave",
      cancellationText: "Cancel",
      ...confirmDialogStyleOptions,
      confirmationButtonProps: {
        ...confirmDialogStyleOptions.confirmationButtonProps,
        color: "primary",
      },
    }).then((): void => {
      navigate(path);
    });
  };

  const handleCancel = (): void => {
    confirmCancel();
  };

  const loading = getCategoryLoading || updateCategoryLoading;

  if (loading) {
    return <FullPageLoader />;
  }

  const category = data?.getCategoryById;
  const breadcrumbItems = [
    {
      label: "Recent events",
      onClick: () => confirmCancel(recentActivitiesNav as RouteEnum),
    },
    {
      label: "Gate Guard events",
      onClick: () => confirmCancel(gateGuardNav as RouteEnum),
    },
    {
      label: "Plates Management",
      onClick: () => confirmCancel(),
    },
    {
      label: `Edit ${category?.categoryName}`,
    },
  ];

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <BreadcrumbNavigation items={breadcrumbItems} />

      <Typography variant="h3" sx={{ padding: "1em 0" }}>
        {`Edit ${category?.categoryName}`}
      </Typography>

      <MainPaperWrapper>
        <CategoryForm control={control} errors={errors} />
      </MainPaperWrapper>

      <Stack direction="row-reverse" spacing={2} sx={{ paddingTop: 3 }}>
        <StyledLoadingButton
          sx={{
            marginTop: "1.5em",
          }}
          type="submit"
          variant="contained"
          color="primary"
        >
          Save changes
        </StyledLoadingButton>
        <StyledLoadingButton
          sx={{
            marginTop: "1.5em",
          }}
          variant="outlined"
          color="inherit"
          onClick={handleCancel}
        >
          Back
        </StyledLoadingButton>
      </Stack>
    </form>
  );
};

export default EditCategoryPage;
