import { makeVar, useReactiveVar } from "@apollo/client";

export const createNodeInitialActiveStepIndex = 0;

export const createNodeActiveStepIndexVariable = makeVar<number>(
  createNodeInitialActiveStepIndex
);

export const useCreateNodeActiveStepIndex = () => {
  return useReactiveVar(createNodeActiveStepIndexVariable);
};

export const nodeScriptInitialValue = "";

export const nodeScriptVariable = makeVar<string>(nodeScriptInitialValue);

export const useNodeScript = () => {
  return useReactiveVar(nodeScriptVariable);
};
