import { makeVar, useReactiveVar } from "@apollo/client";

import { AutocompleteOptionType } from "../../../common/models/autocomplete";

export const selectedDataHubLocationVariable =
  makeVar<AutocompleteOptionType>(null);

export const useSelectedDataHubLocation = () =>
  useReactiveVar(selectedDataHubLocationVariable);

export const selectedDataHubLocationTagVariable = makeVar<
  AutocompleteOptionType[] | null
>(null);

export const useSelectedDataHubTagLocations = () =>
  useReactiveVar(selectedDataHubLocationTagVariable);

export const selectedDataHubCompanyVariable =
  makeVar<AutocompleteOptionType>(null);

export const useSelectedDataHubCompany = () =>
  useReactiveVar(selectedDataHubCompanyVariable);

export const selectedDataHubCategoryVariable =
  makeVar<AutocompleteOptionType>(null);

export const useSelectedDataHubCategory = () =>
  useReactiveVar(selectedDataHubCategoryVariable);

export const selectedDataHubLicensePlateVariable =
  makeVar<AutocompleteOptionType>(null);

export const useSelectedDataHubLicensePlate = () =>
  useReactiveVar(selectedDataHubLicensePlateVariable);
