import { useNavigate } from "react-router-dom";

import { Stack, Typography } from "@mui/material";

import { Zone } from "../../../API";
import WarningBox from "../../../common/components/box/WarningBox";
import FullPageLoader from "../../../common/components/item/FullPageLoader";
import WarningMessage from "../../../common/components/stream/WarningMessage";
import ZoneHeader from "../../../common/components/zones/ZoneHeader";
import ZonesContainer from "../../../common/components/zones/ZonesContainer";
import { IDeviceTableRow } from "../hooks/useDeviceTableRows";
import { usePublishNode } from "../hooks/usePublishNode";
import MainPaperWrapper from "../../../common/components/item/MainPaperWrapper";
import { ViewMode } from "../../../common/components/live-view/LiveViewContainer";

interface IDeviceZonesProps {
  device: IDeviceTableRow;
  hasPanTilt?: boolean;
  currentViewMode?: ViewMode;
  zones?: Array<Zone | null>;
  loading?: boolean;
  precondition?: boolean;
}

const DeviceZones = ({
  device,
  hasPanTilt,
  currentViewMode,
  zones,
  loading,
  precondition,
}: IDeviceZonesProps): JSX.Element => {
  const navigate = useNavigate();

  const { publishNode } = usePublishNode();

  const handleAddZoneClick = (): void => {
    publishNode({
      message: JSON.stringify({
        TARGET: "PANTILT",
        ACTION: "START_PT_CONTROL",
        device_name: device?.name,
      }),
      nodeId: device?.node.id ?? "",
    }).then((): void => {
      navigate(`/device/${encodeURIComponent(device.rowId)}/create-zone`, {
        state: {
          deviceDatum: device,
        },
      });
    });
  };

  const showAlertIfModelIsNotRunning =
    precondition &&
    zones &&
    zones?.length > 0 &&
    !loading &&
    device.models.some(
      (model): boolean => !model.isRunning && model.serviceName === "Gas Leak"
    );

  return (
    <MainPaperWrapper>
      <Stack direction="column" sx={{ width: "100%" }} spacing={2}>
        <ZoneHeader
          device={device}
          hasPanTilt={hasPanTilt}
          currentViewMode={currentViewMode}
          title="All Zones"
          onAddZoneClick={
            precondition && device.isOnline ? handleAddZoneClick : undefined
          }
        />
        {showAlertIfModelIsNotRunning && (
          <WarningMessage showWarningIcon>
            Zones are not inspected till no model is running
          </WarningMessage>
        )}
        {!precondition && (
          <WarningMessage color="text.secondary">
            You will be able to add observing zones to the device after the
            model that need to be monitored is added
          </WarningMessage>
        )}

        {precondition && !zones?.length && !loading && (
          <>
            <Typography variant="body2Regular">
              Add zones to models to be able to set a tour
            </Typography>

            <WarningBox>
              After you create a zone you need to wait till the system retrieves
              key frames and then you will be able to add areas to it
            </WarningBox>
          </>
        )}

        {precondition && loading && <FullPageLoader onlyContent />}

        {precondition && !!zones?.length && (
          <ZonesContainer
            zones={zones}
            deviceName={device?.name}
            hasZoom={device?.deviceData?.hasZoom}
            nodeId={device?.node.id ?? ""}
          />
        )}
      </Stack>
    </MainPaperWrapper>
  );
};

export default DeviceZones;
