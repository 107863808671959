import { Box, Paper, Typography } from "@mui/material";

import AzureIcon from "./components/icons/AzureIcon";
import EdgeIcon from "./components/icons/EdgeIcon";
import S3Icon from "./components/icons/S3Icon";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetCustomer } from "../../common/hooks/useGetCustomer";
import { useCustomerIdGuard } from "../../common/hooks/useCustomerIdGuard";
import { useUpdateCustomer } from "../../common/hooks/useUpdateCustomer";
import { UpdateCustomerInput } from "../../API";
import { RouteEnum } from "../../common/models/enums";
import { useCustomerExternalIdGuard } from "../../common/hooks/useCustomerExternalIdGuard";

const IntegrationsPage = (): JSX.Element => {
  const navigate = useNavigate();
  const { updateCustomer } = useUpdateCustomer();
  const customerId = useCustomerIdGuard();
  const selectedCustomerExternalId = useCustomerExternalIdGuard();

  const { customer } = useGetCustomer();
  const location = useLocation();

  const { configuration } = customer ?? {};
  const { integrations } = JSON.parse(configuration ?? "{}") ?? {};
  const { s3, azure, edge } = integrations ?? {};

  const updateAzure = (e: any): void => {
    e.stopPropagation();

    console.log("updateAzure");

    updateCustomer({
      id: customerId,
      customerExternalId: selectedCustomerExternalId,
      configuration: JSON.stringify({
        integrations: {
          azure: {
            isIntegrationEnabled: true,
            isAlertsEnabled: true,
            isNodeConfigEnabled: true,
            isDeviceStatusEnabled: true,
            isNodeStatusEnabled: true,
          },
        },
      }),
    } as UpdateCustomerInput);
  };

  // todo: deletes old entry, we want to merge the map configuration
  const updateS3 = (e: any): void => {
    e.stopPropagation();

    updateCustomer({
      id: customerId,
      configuration: JSON.stringify({
        integrations: {
          s3: {
            isIntegrationEnabled: true,
            permissionType: "TRUSTS", // or can be "KEYS"
            isAlertsEnabled: true,
            isNodeConfigEnabled: true,
            isDeviceStatusEnabled: true,
            isNodeStatusEnabled: true,
          },
        },
      }),
    } as UpdateCustomerInput);
  };

  let azurePath = RouteEnum.IntegrationsAzure;
  let s3Path = RouteEnum.IntegrationsS3;
  let edgePath = RouteEnum.IntegrationsEdge;

  if (location.pathname.includes("sensory")) {
    azurePath = RouteEnum.SensoryIntegrationsAzure;

    s3Path = RouteEnum.SensoryIntegrationsS3;

    edgePath = RouteEnum.SensoryIntegrationsEdge;
  }

  // const updateEdge = (): void => {
  //   console.log("updateEdge");
  // };

  // todo: add integration status to each Paper component; shows that the integration is created and working
  // todo: add chevronRight to each Paper component; shows paper is clickable
  return (
    <Box>
      <Box padding="0" marginBottom="1em">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "1em",
          }}
        >
          <Typography variant="h5">Integrations</Typography>
        </Box>
        <Box>
          Connect other systems here where you’d like to receive notifications
          for all customers
        </Box>
        <Box>
          <Paper
            style={{
              padding: 16,
              margin: 10,
              alignItems: "center",
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
            }}
            onClick={(): void => navigate(azurePath)}
          >
            <AzureIcon />
            <Box marginLeft={2} fontWeight={"bold"}>
              Azure
            </Box>
            <Box marginLeft={2}>{azure ? "online" : "offline"}</Box>
            <Box marginLeft={2}>
              <button onClick={(e): void => updateAzure(e)}>updateAzure</button>
            </Box>
          </Paper>
          <Paper
            style={{
              padding: 16,
              margin: 10,
              alignItems: "center",
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
            }}
            onClick={(): void => navigate(s3Path)}
          >
            <S3Icon />
            <Box marginLeft={2} fontWeight={"bold"}>
              S3
            </Box>
            <Box marginLeft={2}>{s3 ? "online" : "offline"}</Box>
            <Box marginLeft={2}>
              <button onClick={(e): void => updateS3(e)}>updateS3</button>
            </Box>
          </Paper>
          <Paper
            style={{
              padding: 16,
              margin: 10,
              alignItems: "center",
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
            }}
            onClick={(): void => navigate(edgePath)}
          >
            <EdgeIcon />
            <Box marginLeft={2} fontWeight={"bold"}>
              Edge
            </Box>
            <Box marginLeft={2}>{edge ? "online" : "offline"}</Box>
          </Paper>
        </Box>
      </Box>
    </Box>
  );
};

export default IntegrationsPage;
