import { startTransition, useMemo } from "react";

import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Papa from "papaparse";

import {
  AddCategoryInput,
  AddLicensePlateInput,
  Category,
  LicensePlate,
} from "../../../../API";
import ImportIcon from "../../../../common/components/icons/ImportIcon";
import InfoIconGreen from "../../../../common/components/icons/InfoIconGreen";
import BreadcrumbNavigation from "../../../../common/components/tabs/BreadcrumbNavigation";
import { useCustomerIdGuard } from "../../../../common/hooks/useCustomerIdGuard";
import usePlateManagementNavigationRoute from "../../../../common/hooks/usePlateManagementNavigationRoute";
import { RouteEnum } from "../../../../common/models/enums";
import {
  errorNotification,
  successNotification,
} from "../../../../common/variables/notification";
import client from "../../../../configs/apolloClient";
import { HiddenInput } from "./HiddenInput";
import LicensePlatesSearchInput from "./components/LicensePlatesSearchInput";
import NewCategoryButton from "./components/NewCategoryButton";
import NewPlateNumberButton from "./components/NewPlateNumberButton";
import PlateCategoryCards from "./components/card/PlateCategoryCards";
import { useCreateBatchCategory } from "./hooks/useCreateBatchCategory";
import { useCreateBatchLicensePlate } from "./hooks/useCreateBatchLicensePlate";
import { IPlateNumberExportData } from "./variables/plateNumberData";

type MapType = {
  driverName: "Driver Name";
  licensePlate: "License Plate Number";
  company: "Company";
  categoryId: "Category Name";
};

const PlatesManagement = (): JSX.Element => {
  const customerId = useCustomerIdGuard();
  const { batchCreateLicensePlate } = useCreateBatchLicensePlate();
  const { batchCreateCategory } = useCreateBatchCategory();

  const { getNavigationRoute } = usePlateManagementNavigationRoute();

  const recentActivitiesNav = getNavigationRoute(
    RouteEnum.RecentActivities,
    RouteEnum.SensoryRecentActivities
  );
  const gateGuardNav = getNavigationRoute(RouteEnum.Agg, RouteEnum.SensoryAgg);
  const recentActivitiesPlatesManagementNav = getNavigationRoute(
    RouteEnum.RecentActivitiesPlatesManagement,
    RouteEnum.SensoryRecentActivitiesPlatesManagement
  );

  const breadcrumbItems = useMemo(
    () => [
      {
        label: "Recent events",
        path: recentActivitiesNav,
      },
      {
        label: "Gate Guard events",
        path: gateGuardNav,
      },
      {
        label: "Plates Management",
        path: recentActivitiesPlatesManagementNav,
      },
    ],
    []
  );

  function sleep(ms: number | undefined) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  function batchArray(
    array: (AddCategoryInput | AddLicensePlateInput)[],
    batchSize: number
  ) {
    const batches = [];

    for (let i = 0; i < array.length; i += batchSize) {
      batches.push(array.slice(i, i + batchSize));
    }

    return batches;
  }

  function doesNecessaryColumnsExist(obj: {
    "Driver Name": string | undefined;
    "License Plate Number": string | undefined;
    "Category Name": string | undefined;
    Company: string | undefined;
  }) {
    const requiredKeys = [
      "Driver Name",
      // "Company", // not necessary
      "License Plate Number",
      "Category Name",
    ];

    for (const key of requiredKeys) {
      // eslint-disable-next-line no-prototype-builtins
      if (!obj.hasOwnProperty(key)) {
        console.error("Incorrect column names");

        return false; // Key doesn't exist in the object
      }
    }

    return true; // All keys exist in the object
  }

  const isDatumInputValid = (licensePlateInput: {
    customerId?: string | null;
    licensePlate?: string | null;
    categoryId?: string | null;
    company?: string | null;
    driverName?: string | null;
  }) => {
    if (!licensePlateInput.licensePlate) return "Row has missing license plate";

    // check if plate is right format; one number, one letter
    const validLicensePlateRegex = /^(?=.*[A-Za-z])(?=.*\d).+$/;

    if (!validLicensePlateRegex.test(licensePlateInput.licensePlate))
      return "Row has invalid license plate format";

    if (!licensePlateInput.categoryId) return "Row has missing category name";

    return "";
  };

  // this function creates any categories that do not exist in cache
  const createNecessaryCategories = async (data: IPlateNumberExportData[]) => {
    const setOfCategoriesThatNeedToBeCreated = new Set<string>();

    data.forEach(async (datum: IPlateNumberExportData) => {
      const datumCategoryName = datum["Category Name"];

      if (!datumCategoryName) {
        return;
      }

      const cache = client.cache.extract();

      const cacheValues = Object.values(cache).filter(
        c => c?.__typename === "Category"
      ) as Category[];

      const category = cacheValues.find(
        (c): boolean => c.categoryName === datumCategoryName
      );

      // category not in cache
      if (!category) {
        setOfCategoriesThatNeedToBeCreated.add(datumCategoryName ?? "");
      }
    });

    const batchCreateCategoryInput: AddCategoryInput[] = [];

    setOfCategoriesThatNeedToBeCreated.forEach(datum => {
      batchCreateCategoryInput.push({
        customerId,
        categoryName: datum,
      });
    });

    if (batchCreateCategoryInput.length === 0) {
      return;
    }

    const batchSize = 25;
    const batches = batchArray(batchCreateCategoryInput, batchSize);

    batches.forEach(async batch => {
      await batchCreateCategory(batch);

      successNotification("Created categories from csv");
    });
  };

  const createLicensePlatesInDDB = async (data: IPlateNumberExportData[]) => {
    // create array to store all valid addLicensePlate inputs
    const totalListOfValidLicensePlates: {
      customerId: string;
      licensePlate: string;
      categoryId: string;
      company: string;
      driverName: string;
    }[] = [];
    const cache = client.cache.extract();

    const exceptionFound: string[] = [];

    const cacheValueLicensePlate = Object.values(cache).filter(
      c => c?.__typename === "LicensePlate"
    ) as LicensePlate[];

    const existingLicenses = cacheValueLicensePlate.map(lp => lp.licensePlate);

    const duplicates = data.filter(csvRow =>
      existingLicenses.includes(csvRow["License Plate Number"] ?? "")
    );

    const dataWithoutDuplicates = data.filter(
      csvRow => !existingLicenses.includes(csvRow["License Plate Number"] ?? "")
    );

    if (dataWithoutDuplicates.length === 0) {
      throw Error("No new licence plates added");
    }

    dataWithoutDuplicates.forEach(async (datum: IPlateNumberExportData) => {
      // makeshift map to translate columns to our preferred camelCase format
      const map: MapType = {
        driverName: "Driver Name",
        licensePlate: "License Plate Number",
        company: "Company",
        categoryId: "Category Name",
      };

      const datumCategoryName = datum[map["categoryId"]];

      const licensePlateInput = {
        customerId,
        licensePlate: datum[map["licensePlate"]] ?? "",
        company: datum[map["company"]] ?? "",
        driverName: datum[map["driverName"]] ?? "",
        categoryId: datum[map["categoryId"]] ?? "",
      };

      const errorMessage = isDatumInputValid(licensePlateInput);

      if (errorMessage) {
        exceptionFound.push(errorMessage);
      } else {
        // even though the plates are valid in this block, we will check for duplicates

        // reference cache for checking if category is already created
        const cache = client.cache.extract();

        const cacheValuesCategory = Object.values(cache).filter(
          c => c?.__typename === "Category"
        ) as Category[];

        // get corresponding category from cache to get Id
        const category = cacheValuesCategory.find(
          (c): boolean =>
            c.categoryName.toString() === datumCategoryName?.toString()
        );

        if (!category) {
          console.error("no category");

          return;
        }

        licensePlateInput.categoryId = category?.id as string;

        totalListOfValidLicensePlates.push(licensePlateInput);
      }
    });

    if (exceptionFound.length > 0) {
      throw new Error(exceptionFound[0]);
    }

    const batchSize = 25;
    const batches = batchArray(totalListOfValidLicensePlates, batchSize);

    batches.forEach(async batch => {
      await batchCreateLicensePlate(batch as AddLicensePlateInput[]);
    });

    return { totalListOfValidLicensePlates, duplicates, dataWithoutDuplicates };
  };

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e?.target?.files?.[0];

    if (!file) return;

    // parse CSV
    startTransition(() => {
      Papa.parse(file, {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        complete: async function (results: {
          data: {
            "Driver Name": string | undefined;
            "License Plate Number": string | undefined;
            "Category Name": string | undefined;
            Company: string | undefined;
          }[];
        }) {
          try {
            // get data from papaparse results
            const data = results.data;

            // check if headers are valid otherwise return
            if (!doesNecessaryColumnsExist(data[0])) {
              errorNotification("Necessary columns do not exist in CSV.");

              return;
            }

            // first iteration through data to populate array of not created yet categories
            await createNecessaryCategories(data); // TODO: HOW TO HANDLE BATCH CREATE CATEGORY FOR IMPORT

            // sleep allows cache to be updated with categories before creating license plates
            await sleep(2000);

            const { duplicates, dataWithoutDuplicates } =
              await createLicensePlatesInDDB(data);

            if (duplicates.length > 0) {
              const message =
                dataWithoutDuplicates
                  .map(el => el["License Plate Number"])
                  .join(", ") +
                " succesfully imported, also detected duplicates: " +
                duplicates.map(el => el["License Plate Number"]).join(", ");

              successNotification(message);
            } else {
              successNotification("Plates succesfully imported");
            }
          } catch (error) {
            const result = (error as Error).message;

            errorNotification(result ?? "Error ocured during import");
          }
        },
      });
    });
  };

  return (
    <Box>
      <BreadcrumbNavigation items={breadcrumbItems} />

      <Typography sx={{ margin: "1em 0" }} variant="h3">
        Plates Management
      </Typography>

      <Box sx={{ display: "flex", flex: 1 }}>
        <LicensePlatesSearchInput />
        <Box
          sx={{
            display: "flex",
            flex: 1,
            justifyContent: "flex-end",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flex: 1,
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              component="label"
            >
              <Box sx={{ marginTop: "4px" }}>
                <ImportIcon size={20} />
              </Box>
              <Typography
                sx={{
                  fontWeight: "bold",
                  cursor: "pointer",
                  marginRight: "8px",
                  marginLeft: "8px",
                  fontSize: 14,
                }}
              >
                Import List
              </Typography>
              <HiddenInput
                type="file"
                onChange={handleChange}
                accept=".csv"
                onClick={event => {
                  (event.target as HTMLInputElement).value = "";
                }}
              />
              <Box sx={{ marginTop: "5px", marginRight: "2em" }}>
                <Tooltip title='Please make sure your CSV columns are spelled, "Driver Name", "License Plate Number", "Company" and "Category Name"'>
                  <Box>
                    <InfoIconGreen size={20} />
                  </Box>
                </Tooltip>
              </Box>
            </Box>
          </Box>

          <NewCategoryButton />

          <NewPlateNumberButton />
        </Box>
      </Box>

      <PlateCategoryCards />
    </Box>
  );
};

export default PlatesManagement;
