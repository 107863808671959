import { useTheme } from "@mui/material/styles";

import { themeMode } from "../../providers/theme/MuiThemeProvider";

const CustomerSettingsMenuIconFilled = (): JSX.Element => {
  const theme = useTheme();

  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="16" fill={theme.palette.primary.main} />
      <circle
        cx="16.0001"
        cy="11.0003"
        r="3.33333"
        stroke={
          theme.palette.mode === themeMode.light
            ? theme.palette.otherBackgroundLight.main
            : theme.palette.text.primary
        }
        strokeWidth="1.5"
      />
      <ellipse
        cx="16.0001"
        cy="20.1663"
        rx="5.83333"
        ry="3.33333"
        stroke={
          theme.palette.mode === themeMode.light
            ? theme.palette.otherBackgroundLight.main
            : theme.palette.text.primary
        }
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default CustomerSettingsMenuIconFilled;
