import { ApolloError, gql, useMutation } from "@apollo/client";
import type { AddTagMutation, AddTagMutationVariables } from "../../../../API";
import { ADD_TAG } from "../../../../common/operations/mutations";
import { useCustomerIdGuard } from "../../../../common/hooks/useCustomerIdGuard";
import {
  errorNotification,
  successNotification,
} from "../../../../common/variables/notification";

export const useCreateTag = () => {
  const customerId = useCustomerIdGuard();

  const [createTagAction, { data, loading }] = useMutation<
    AddTagMutation,
    AddTagMutationVariables
  >(ADD_TAG);

  const createTag = async (tagName: string) => {
    return await createTagAction({
      variables: {
        input: { tagName, customerId },
      },
      onCompleted: response => {
        if (response.addTag) {
          successNotification("Location tag is created");
        }
      },
      onError: (error: ApolloError): void => {
        errorNotification(error.message);

        console.error(error.message);
      },
      update: (cache, { data }) => {
        cache.modify({
          fields: {
            getTagsByCustomer(existing) {
              const newTagRef = cache.writeFragment({
                data: data?.addTag,
                fragment: gql`
                  fragment NewTag on Tag {
                    id
                    tagName
                  }
                `,
              });

              if (!existing) {
                return data;
              }

              return {
                ...existing,
                items: [...existing.items, newTagRef],
              };
            },
          },
        });
      },
    });
  };

  return { createTag, data, loading };
};
