import { memo, useEffect, useState } from "react";

import { Box, Paper } from "@mui/material";
import isEqual from "lodash/isEqual";

import { TimePeriod } from "../../../API";
import {
  dateRangeVariable,
  useDateRange,
} from "../../../common/components/datePicker/modelsDatePickers";
import { AutocompleteOptionType } from "../../../common/models/autocomplete";
import { useGasLeakEmissionsChart } from "../hooks/useGasLeakEmissionsChart";
import { useLocationsFilterDropdown } from "../hooks/useLocationsFilterDropdown";
import { IStatisticsResponseModel } from "../models/chart";
import DetailedInformationView from "./DetailedInformationView";
import GasLeakEmissionVolumeView from "./GasLeakEmissionVolumeView";
import PolarAreaChart from "./PolarAreaChart";

export enum GasLeakContainerSteps {
  EmissionVolumeChart,
  DetailedLocaleInformation,
  PolarAreaChart,
}

const GasLeakChartContainerComponent = (): JSX.Element => {
  const [activeStep, setActiveStep] = useState(0);
  const [deviceData, setDeviceData] = useState<IStatisticsResponseModel | null>(
    null
  );

  const [activeTimePeriod, setActiveTimePeriod] = useState<TimePeriod>(
    TimePeriod.ONE_WEEK
  );

  const dateRange = useDateRange();

  const {
    location,
    handleLocationChange,
    tagLocations,
    handleTagLocationsChange,
  } = useLocationsFilterDropdown();

  const {
    series,
    options,
    loading,
    firstDay,
    showNoData,
    convertInstances,
    totalEmissionsCount,
    refetchWithSpecificData,
    refetchWithChosenValues,
    refetchChartData,
  } = useGasLeakEmissionsChart(
    activeTimePeriod,
    activeStep,
    location?.value
      ? [location.value]
      : tagLocations?.map(l => l?.value ?? "") ?? null
  );

  useEffect((): void => {
    if (deviceData && activeStep === 2) {
      refetchWithSpecificData(deviceData);
    }
  }, [dateRange]);

  useEffect((): void => {
    const attachDate = new Date();

    if (activeTimePeriod === TimePeriod.ONE_MONTH) {
      attachDate.setDate(attachDate.getDate() - 30);
    }

    if (activeTimePeriod === TimePeriod.ONE_WEEK) {
      attachDate.setDate(attachDate.getDate() - 7);
    }

    if (activeTimePeriod === TimePeriod.ONE_DAY) {
      attachDate.setDate(attachDate.getDate() - 1);
    }

    if (activeTimePeriod !== TimePeriod.ALL_TIME) {
      dateRangeVariable({
        from: new Date(attachDate),
        to: new Date(),
      });
    }
  }, [activeTimePeriod]);

  useEffect((): void => {
    if (
      activeTimePeriod === TimePeriod.ALL_TIME &&
      firstDay &&
      activeStep === GasLeakContainerSteps.DetailedLocaleInformation
    ) {
      if (dateRange?.from?.toString() !== new Date(firstDay).toString()) {
        dateRangeVariable({
          from: new Date(firstDay),
          to: new Date(),
        });
      }
    }
  }, [loading, activeStep]);

  useEffect(() => {
    return () => {
      if (dateRange?.from || dateRange?.to) {
        dateRangeVariable({
          from: null,
          to: null,
        });
      }
    };
  }, [dateRange]);

  const handleTimePeriodChange = (timePeriod: TimePeriod): void => {
    if (timePeriod !== activeTimePeriod) {
      setActiveTimePeriod(timePeriod);
    }
  };

  const setLocationFilter = (locationValue: AutocompleteOptionType): void => {
    if (locationValue?.value !== location?.value) {
      handleLocationChange(locationValue, activeTimePeriod, refetchChartData);
    }
  };

  const setTagFilter = (locations: AutocompleteOptionType[] | null): void => {
    if (!isEqual(locations, tagLocations)) {
      handleTagLocationsChange(locations, activeTimePeriod, refetchChartData);
    }
  };

  const setStepsHandler = (step: GasLeakContainerSteps): void => {
    if (
      activeStep === GasLeakContainerSteps.PolarAreaChart &&
      step === GasLeakContainerSteps.DetailedLocaleInformation
    ) {
      refetchWithChosenValues();
    }

    setActiveStep(step);
  };

  const setDeviceHandler = (device: IStatisticsResponseModel): void => {
    setDeviceData(device);

    refetchWithSpecificData(device);
  };

  const locationText =
    (deviceData && `${deviceData.location}. ${deviceData.deviceName}`) ?? "";

  return (
    <Paper
      sx={{
        overflowY:
          activeStep === GasLeakContainerSteps.DetailedLocaleInformation
            ? "auto"
            : "visible",
        padding: "1.5em",
        width: "100%",
        height: "428px",
      }}
    >
      <Box
        sx={{
          display:
            activeStep === GasLeakContainerSteps.EmissionVolumeChart
              ? "block"
              : "none",
        }}
      >
        <GasLeakEmissionVolumeView
          showNoData={showNoData}
          activeTimePeriod={activeTimePeriod}
          handleTimePeriodChange={handleTimePeriodChange}
          locationVariable={location}
          handleLocationChange={setLocationFilter}
          handleTagLocationsChange={setTagFilter}
          loading={loading}
          setStepsHandler={setStepsHandler}
          options={options}
          series={series}
        />
      </Box>

      <Box
        sx={{
          display:
            activeStep === GasLeakContainerSteps.DetailedLocaleInformation
              ? "block"
              : "none",
        }}
      >
        <DetailedInformationView
          loading={loading}
          showNoData={showNoData}
          locationVariable={location}
          handleLocationChange={setLocationFilter}
          setDeviceHandler={setDeviceHandler}
          setStepsHandler={setStepsHandler}
          convertInstances={convertInstances}
        />
      </Box>

      <Box>
        {activeStep === GasLeakContainerSteps.PolarAreaChart && (
          <PolarAreaChart
            loading={loading}
            showNoData={showNoData}
            totalEmissionsCount={totalEmissionsCount}
            setStepsHandler={setStepsHandler}
            locationText={locationText}
            options={options}
            series={series}
          />
        )}
      </Box>
    </Paper>
  );
};

const GasLeakChartContainer = memo(GasLeakChartContainerComponent);

export default GasLeakChartContainer;
