import Stack from "@mui/material/Stack";

import ClientUploadsHeader from "./ClientUploadsHeader";
import ClientUploadsTabs from "./ClientUploadsTabs";
import { useGetUser } from "../common/hooks/useGetUser";
import UploadLimitNotice from "./components/UploadLimitNotice";

const ClientUploadsPage = (): JSX.Element => {
  useGetUser();

  return (
    <Stack
      spacing={2}
      direction="column"
      sx={{
        paddingBottom: "1.5em",
        "#upload-limit-notice": {
          ml: "-2.25em",
          mr: "-2.25em",
        },
      }}
    >
      <UploadLimitNotice />
      <ClientUploadsHeader />
      <ClientUploadsTabs />
    </Stack>
  );
};

export default ClientUploadsPage;
