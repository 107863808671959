const LogoLight = (): JSX.Element => {
  return (
    <svg
      width="201"
      height="20"
      viewBox="0 0 201 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_21637_40085)">
        <path
          d="M4.40569 18.7875H0.555664L6.42199 1.73486H11.052L16.91 18.7875H13.06L8.8034 5.63166H8.67062L4.40569 18.7875ZM4.16506 12.0847H13.2591V14.899H4.16506V12.0847Z"
          fill="#06182D"
        />
        <path
          d="M25.8937 13.3415V5.99756H29.4285V18.787H26.0348V16.464H25.902C25.6144 17.2133 25.1359 17.8156 24.4666 18.2708C23.8028 18.726 22.9924 18.9536 22.0354 18.9536C21.1835 18.9536 20.434 18.7593 19.7868 18.3707C19.1395 17.9822 18.6334 17.4298 18.2683 16.7138C17.9088 15.9977 17.7262 15.14 17.7207 14.1409V5.99756H21.2554V13.5081C21.261 14.263 21.4629 14.8597 21.8612 15.2982C22.2594 15.7368 22.7932 15.956 23.4626 15.956C23.8885 15.956 24.2868 15.8589 24.6574 15.6646C25.028 15.4648 25.3267 15.1706 25.5535 14.782C25.7858 14.3934 25.8993 13.9133 25.8937 13.3415Z"
          fill="#06182D"
        />
        <path
          d="M37.8666 5.99822V8.66271H30.1914V5.99822H37.8666ZM31.9339 2.93408H35.4686V14.8576C35.4686 15.1851 35.5184 15.4405 35.618 15.6236C35.7175 15.8013 35.8558 15.9262 36.0328 15.9983C36.2154 16.0705 36.4256 16.1066 36.6634 16.1066C36.8294 16.1066 36.9953 16.0927 37.1613 16.0649C37.3272 16.0317 37.4545 16.0067 37.5431 15.99L38.099 18.6295C37.9219 18.685 37.6731 18.7489 37.3521 18.821C37.0313 18.8987 36.6413 18.9459 36.1822 18.9625C35.3303 18.9959 34.5835 18.8821 33.9419 18.6212C33.3057 18.3603 32.8106 17.9551 32.4566 17.4055C32.1026 16.856 31.9283 16.1621 31.9339 15.3239V2.93408Z"
          fill="#06182D"
        />
        <path
          d="M44.6223 19.0369C43.3334 19.0369 42.2189 18.7621 41.2786 18.2126C40.3435 17.6574 39.6217 16.8859 39.1129 15.8978C38.604 14.9041 38.3496 13.7523 38.3496 12.4423C38.3496 11.1212 38.604 9.96655 39.1129 8.97846C39.6217 7.98486 40.3435 7.21328 41.2786 6.66371C42.2189 6.10863 43.3334 5.83105 44.6223 5.83105C45.9112 5.83105 47.023 6.10863 47.9581 6.66371C48.8985 7.21328 49.6229 7.98486 50.1321 8.97846C50.6409 9.96655 50.8953 11.1212 50.8953 12.4423C50.8953 13.7523 50.6409 14.9041 50.1321 15.8978C49.6229 16.8859 48.8985 17.6574 47.9581 18.2126C47.023 18.7621 45.9112 19.0369 44.6223 19.0369ZM44.6391 16.2891C45.2253 16.2891 45.715 16.1226 46.1077 15.7895C46.5003 15.4509 46.7962 14.9902 46.9954 14.4074C47.2001 13.8245 47.3025 13.1611 47.3025 12.4173C47.3025 11.6735 47.2001 11.0101 46.9954 10.4273C46.7962 9.84444 46.5003 9.38369 46.1077 9.04508C45.715 8.70647 45.2253 8.53716 44.6391 8.53716C44.0469 8.53716 43.5493 8.70647 43.1455 9.04508C42.7472 9.38369 42.4457 9.84444 42.241 10.4273C42.0419 11.0101 41.9421 11.6735 41.9421 12.4173C41.9421 13.1611 42.0419 13.8245 42.241 14.4074C42.4457 14.9902 42.7472 15.4509 43.1455 15.7895C43.5493 16.1226 44.0469 16.2891 44.6391 16.2891Z"
          fill="#06182D"
        />
        <path
          d="M55.56 11.3932V18.7871H52.0254V5.99759H55.3941V8.25408H55.5435C55.8256 7.51023 56.2986 6.92183 56.9625 6.48886C57.626 6.05033 58.4311 5.83105 59.377 5.83105C60.2621 5.83105 61.0335 6.02537 61.6918 6.41391C62.3501 6.8025 62.8619 7.35757 63.2269 8.07922C63.5919 8.79528 63.7746 9.65013 63.7746 10.6438V18.7871H60.24V11.2766C60.2452 10.4939 60.0461 9.88328 59.6423 9.44476C59.2388 9.00069 58.6829 8.77864 57.9745 8.77864C57.4989 8.77864 57.0786 8.88132 56.7133 9.08673C56.3539 9.2921 56.0718 9.59187 55.8671 9.98597C55.6679 10.3746 55.5656 10.8436 55.56 11.3932Z"
          fill="#06182D"
        />
        <path
          d="M71.1516 19.0369C69.8627 19.0369 68.7482 18.7621 67.8079 18.2126C66.8732 17.6574 66.151 16.8859 65.6422 15.8978C65.1333 14.9041 64.8789 13.7523 64.8789 12.4423C64.8789 11.1212 65.1333 9.96655 65.6422 8.97846C66.151 7.98486 66.8732 7.21328 67.8079 6.66371C68.7482 6.10863 69.8627 5.83105 71.1516 5.83105C72.4405 5.83105 73.5523 6.10863 74.4874 6.66371C75.4278 7.21328 76.1525 7.98486 76.6614 8.97846C77.1702 9.96655 77.4246 11.1212 77.4246 12.4423C77.4246 13.7523 77.1702 14.9041 76.6614 15.8978C76.1525 16.8859 75.4278 17.6574 74.4874 18.2126C73.5523 18.7621 72.4405 19.0369 71.1516 19.0369ZM71.1684 16.2891C71.7546 16.2891 72.2443 16.1226 72.637 15.7895C73.0296 15.4509 73.3255 14.9902 73.5247 14.4074C73.7294 13.8245 73.8318 13.1611 73.8318 12.4173C73.8318 11.6735 73.7294 11.0101 73.5247 10.4273C73.3255 9.84444 73.0296 9.38369 72.637 9.04508C72.2443 8.70647 71.7546 8.53716 71.1684 8.53716C70.5766 8.53716 70.0786 8.70647 69.6748 9.04508C69.2765 9.38369 68.975 9.84444 68.7703 10.4273C68.5712 11.0101 68.4718 11.6735 68.4718 12.4173C68.4718 13.1611 68.5712 13.8245 68.7703 14.4074C68.975 14.9902 69.2765 15.4509 69.6748 15.7895C70.0786 16.1226 70.5766 16.2891 71.1684 16.2891Z"
          fill="#06182D"
        />
        <path
          d="M78.5547 18.7871V5.99759H81.9234V8.25408H82.0728C82.3385 7.50469 82.7808 6.91351 83.4006 6.48053C84.02 6.04756 84.7612 5.83105 85.6242 5.83105C86.4981 5.83105 87.2423 6.05033 87.8561 6.48886C88.4703 6.92183 88.8794 7.51023 89.0841 8.25408H89.2171C89.4771 7.52133 89.9471 6.9357 90.6274 6.49718C91.3134 6.05311 92.1241 5.83105 93.0588 5.83105C94.2479 5.83105 95.2133 6.21132 95.9545 6.9718C96.7013 7.72674 97.0746 8.79806 97.0746 10.1858V18.7871H93.5482V10.8852C93.5482 10.1747 93.3603 9.64181 92.9841 9.28655C92.6079 8.9313 92.1379 8.75367 91.5734 8.75367C90.9319 8.75367 90.4313 8.95904 90.0715 9.36982C89.7121 9.77505 89.5324 10.3107 89.5324 10.9768V18.7871H86.1054V10.8103C86.1054 10.1831 85.9257 9.68346 85.566 9.31152C85.2122 8.93962 84.7448 8.75367 84.1639 8.75367C83.7712 8.75367 83.417 8.85358 83.1017 9.0534C82.792 9.24771 82.5458 9.52248 82.3631 9.87773C82.1808 10.2274 82.0893 10.6382 82.0893 11.1101V18.7871H78.5547Z"
          fill="#06182D"
        />
        <path
          d="M104.457 19.0369C103.168 19.0369 102.053 18.7621 101.113 18.2126C100.178 17.6574 99.4557 16.8859 98.9468 15.8978C98.438 14.9041 98.1836 13.7523 98.1836 12.4423C98.1836 11.1212 98.438 9.96655 98.9468 8.97846C99.4557 7.98486 100.178 7.21328 101.113 6.66371C102.053 6.10863 103.168 5.83105 104.457 5.83105C105.745 5.83105 106.857 6.10863 107.792 6.66371C108.732 7.21328 109.457 7.98486 109.966 8.97846C110.475 9.96655 110.729 11.1212 110.729 12.4423C110.729 13.7523 110.475 14.9041 109.966 15.8978C109.457 16.8859 108.732 17.6574 107.792 18.2126C106.857 18.7621 105.745 19.0369 104.457 19.0369ZM104.473 16.2891C105.059 16.2891 105.549 16.1226 105.942 15.7895C106.334 15.4509 106.63 14.9902 106.83 14.4074C107.034 13.8245 107.136 13.1611 107.136 12.4173C107.136 11.6735 107.034 11.0101 106.83 10.4273C106.63 9.84444 106.334 9.38369 105.942 9.04508C105.549 8.70647 105.059 8.53716 104.473 8.53716C103.881 8.53716 103.383 8.70647 102.979 9.04508C102.581 9.38369 102.28 9.84444 102.075 10.4273C101.876 11.0101 101.776 11.6735 101.776 12.4173C101.776 13.1611 101.876 13.8245 102.075 14.4074C102.28 14.9902 102.581 15.4509 102.979 15.7895C103.383 16.1226 103.881 16.2891 104.473 16.2891Z"
          fill="#06182D"
        />
        <path
          d="M120.033 13.3415V5.99756H123.567V18.787H120.173V16.464H120.041C119.753 17.2133 119.275 17.8156 118.605 18.2708C117.942 18.726 117.131 18.9536 116.174 18.9536C115.322 18.9536 114.573 18.7593 113.925 18.3707C113.278 17.9822 112.772 17.4298 112.407 16.7138C112.048 15.9977 111.865 15.14 111.859 14.1409V5.99756H115.394V13.5081C115.4 14.263 115.602 14.8597 116 15.2982C116.398 15.7368 116.932 15.956 117.601 15.956C118.027 15.956 118.426 15.8589 118.796 15.6646C119.167 15.4648 119.465 15.1706 119.692 14.782C119.925 14.3934 120.038 13.9133 120.033 13.3415Z"
          fill="#06182D"
        />
        <path
          d="M135.83 9.64458L132.594 9.84444C132.539 9.56686 132.42 9.31707 132.238 9.09505C132.055 8.86745 131.815 8.68705 131.516 8.55381C131.222 8.41506 130.871 8.34566 130.462 8.34566C129.914 8.34566 129.452 8.46222 129.076 8.69537C128.7 8.92297 128.512 9.22825 128.512 9.61129C128.512 9.91661 128.634 10.1747 128.877 10.3856C129.121 10.5966 129.538 10.7659 130.13 10.8936L132.437 11.3599C133.676 11.6152 134.6 12.026 135.208 12.5922C135.817 13.1584 136.121 13.9022 136.121 14.8237C136.121 15.6618 135.875 16.3974 135.382 17.0302C134.896 17.663 134.226 18.157 133.374 18.5123C132.528 18.862 131.552 19.0369 130.445 19.0369C128.758 19.0369 127.414 18.6844 126.413 17.9794C125.417 17.2689 124.834 16.303 124.662 15.0818L128.139 14.8986C128.244 15.4149 128.498 15.809 128.902 16.081C129.306 16.3474 129.823 16.4806 130.454 16.4806C131.073 16.4806 131.571 16.3613 131.947 16.1226C132.329 15.8784 132.523 15.5647 132.528 15.1817C132.523 14.8597 132.387 14.5961 132.121 14.3907C131.856 14.1798 131.447 14.0188 130.893 13.9078L128.686 13.4665C127.442 13.2167 126.515 12.7837 125.907 12.1675C125.304 11.5514 125.002 10.7659 125.002 9.81111C125.002 8.98959 125.224 8.28182 125.666 7.68786C126.114 7.09391 126.742 6.63597 127.55 6.314C128.363 5.99204 129.314 5.83105 130.404 5.83105C132.014 5.83105 133.28 6.17244 134.204 6.85521C135.134 7.53798 135.676 8.46777 135.83 9.64458Z"
          fill="#06182D"
        />
        <path
          d="M143.427 19.0199C142.188 19.0199 141.084 18.8062 140.116 18.3788C139.153 17.9458 138.393 17.3518 137.834 16.5969C137.281 15.8364 136.996 14.9593 136.979 13.9657H140.597C140.619 14.382 140.755 14.7484 141.004 15.0648C141.258 15.3757 141.596 15.6171 142.016 15.7892C142.437 15.9613 142.91 16.0473 143.435 16.0473C143.983 16.0473 144.467 15.9502 144.887 15.7559C145.307 15.5616 145.636 15.2924 145.874 14.9483C146.112 14.6041 146.231 14.2072 146.231 13.7576C146.231 13.3024 146.104 12.8999 145.85 12.5502C145.601 12.1949 145.241 11.9174 144.771 11.7176C144.306 11.5177 143.753 11.4178 143.111 11.4178H141.527V8.77H143.111C143.653 8.77 144.132 8.67564 144.547 8.48691C144.967 8.29815 145.293 8.03726 145.526 7.7042C145.758 7.36558 145.874 6.97149 145.874 6.52183C145.874 6.09441 145.772 5.71973 145.567 5.39777C145.368 5.07026 145.086 4.81491 144.721 4.63173C144.362 4.44856 143.941 4.35697 143.46 4.35697C142.973 4.35697 142.528 4.44578 142.124 4.62341C141.72 4.79549 141.397 5.04251 141.153 5.36448C140.91 5.68644 140.78 6.06389 140.763 6.49687H137.32C137.336 5.51433 137.616 4.64838 138.158 3.89899C138.7 3.14962 139.43 2.56399 140.348 2.14212C141.272 1.71469 142.315 1.50098 143.477 1.50098C144.649 1.50098 145.675 1.71469 146.555 2.14212C147.435 2.56954 148.117 3.14685 148.604 3.87402C149.097 4.59567 149.34 5.40609 149.335 6.30537C149.34 7.26013 149.044 8.05671 148.447 8.69506C147.855 9.33344 147.083 9.73863 146.132 9.91071V10.044C147.382 10.2049 148.333 10.6407 148.986 11.3512C149.644 12.0562 149.971 12.9388 149.965 13.999C149.971 14.9704 149.691 15.8336 149.127 16.5886C148.568 17.3435 147.797 17.9374 146.812 18.3704C145.827 18.8034 144.699 19.0199 143.427 19.0199Z"
          fill="#06182D"
        />
        <path
          d="M157.901 19.0199C157.028 19.0143 156.184 18.8672 155.371 18.5786C154.563 18.29 153.839 17.8209 153.197 17.1714C152.555 16.522 152.046 15.6615 151.67 14.5902C151.3 13.5189 151.114 12.2033 151.114 10.6435C151.119 9.2113 151.283 7.9318 151.604 6.80496C151.93 5.67253 152.395 4.71223 152.998 3.924C153.606 3.13575 154.334 2.53624 155.18 2.12546C156.026 1.70914 156.975 1.50098 158.026 1.50098C159.16 1.50098 160.161 1.72302 161.029 2.1671C161.898 2.60563 162.595 3.20236 163.121 3.95729C163.652 4.71223 163.972 5.55875 164.083 6.49687H160.54C160.402 5.90291 160.111 5.43665 159.669 5.09804C159.226 4.75943 158.679 4.59012 158.026 4.59012C156.92 4.59012 156.079 5.07303 155.504 6.03892C154.934 7.00478 154.643 8.31761 154.632 9.97733H154.748C155.003 9.47219 155.346 9.04199 155.777 8.68673C156.214 8.32593 156.709 8.05113 157.263 7.8624C157.821 7.66813 158.41 7.57099 159.03 7.57099C160.037 7.57099 160.933 7.80969 161.718 8.28705C162.504 8.7589 163.123 9.40838 163.577 10.2354C164.031 11.0626 164.257 12.009 164.257 13.0748C164.257 14.2294 163.989 15.2563 163.453 16.1556C162.921 17.0549 162.178 17.7598 161.22 18.2705C160.269 18.7756 159.163 19.0254 157.901 19.0199ZM157.885 16.1889C158.438 16.1889 158.933 16.0557 159.37 15.7892C159.807 15.5228 160.15 15.162 160.399 14.7068C160.648 14.2516 160.772 13.7409 160.772 13.1747C160.772 12.6085 160.648 12.1006 160.399 11.651C160.156 11.2013 159.818 10.8433 159.387 10.5768C158.955 10.3104 158.463 10.1772 157.91 10.1772C157.495 10.1772 157.11 10.2549 156.756 10.4103C156.408 10.5657 156.101 10.7822 155.835 11.0598C155.575 11.3373 155.371 11.6593 155.221 12.0256C155.072 12.3865 154.997 12.7723 154.997 13.183C154.997 13.7326 155.122 14.235 155.371 14.6901C155.625 15.1453 155.968 15.5089 156.4 15.7809C156.837 16.0529 157.332 16.1889 157.885 16.1889Z"
          fill="#06182D"
        />
        <path
          d="M171.843 19.0206C170.67 19.0206 169.625 18.8042 168.706 18.3712C167.794 17.9382 167.069 17.3415 166.532 16.581C165.996 15.8205 165.716 14.949 165.694 13.9665H169.179C169.218 14.627 169.495 15.1627 170.009 15.5735C170.523 15.9842 171.135 16.1897 171.843 16.1897C172.407 16.1897 172.905 16.0647 173.336 15.8149C173.773 15.5596 174.113 15.2071 174.357 14.7575C174.606 14.3023 174.73 13.7805 174.73 13.1921C174.73 12.5926 174.603 12.0653 174.349 11.6101C174.099 11.1549 173.754 10.7996 173.311 10.5443C172.869 10.2889 172.363 10.1585 171.793 10.1529C171.295 10.1529 170.811 10.2557 170.341 10.461C169.876 10.6664 169.514 10.9467 169.254 11.302L166.059 10.7275L166.864 1.73486H177.253V4.68245H169.826L169.387 8.95392H169.486C169.785 8.53204 170.236 8.18234 170.839 7.9048C171.442 7.62722 172.116 7.48847 172.863 7.48847C173.887 7.48847 174.799 7.72995 175.601 8.21285C176.403 8.6958 177.037 9.35915 177.501 10.2029C177.966 11.0411 178.196 12.007 178.19 13.1005C178.196 14.2496 177.93 15.271 177.394 16.1646C176.863 17.0528 176.119 17.7522 175.162 18.2629C174.21 18.7681 173.104 19.0206 171.843 19.0206Z"
          fill="#06182D"
        />
        <path
          d="M181.079 19.004C180.531 19.004 180.061 18.8097 179.669 18.4211C179.281 18.027 179.088 17.5552 179.088 17.0056C179.088 16.4616 179.281 15.9953 179.669 15.6068C180.061 15.2182 180.531 15.0239 181.079 15.0239C181.61 15.0239 182.075 15.2182 182.473 15.6068C182.872 15.9953 183.071 16.4616 183.071 17.0056C183.071 17.372 182.977 17.7078 182.788 18.0131C182.606 18.3129 182.365 18.5544 182.067 18.7375C181.768 18.9152 181.439 19.004 181.079 19.004Z"
          fill="#06182D"
        />
        <path
          d="M188.439 19.0285C187.626 19.0285 186.901 18.887 186.265 18.6039C185.629 18.3152 185.125 17.8906 184.755 17.3299C184.39 16.7637 184.207 16.0588 184.207 15.215C184.207 14.5045 184.337 13.9078 184.597 13.4248C184.857 12.9419 185.211 12.5533 185.659 12.2591C186.107 11.9649 186.616 11.7429 187.186 11.593C187.761 11.4431 188.364 11.3376 188.995 11.2766C189.736 11.1989 190.333 11.1267 190.787 11.0601C191.241 10.9879 191.57 10.8825 191.775 10.7437C191.979 10.6049 192.081 10.3995 192.081 10.1275V10.0776C192.081 9.55022 191.915 9.14222 191.584 8.85358C191.257 8.56494 190.792 8.42061 190.189 8.42061C189.554 8.42061 189.047 8.56217 188.671 8.84526C188.295 9.1228 188.046 9.4725 187.924 9.89438L184.655 9.62794C184.821 8.8508 185.147 8.17913 185.634 7.61292C186.121 7.0412 186.749 6.60264 187.518 6.29736C188.292 5.98649 189.188 5.83105 190.206 5.83105C190.914 5.83105 191.592 5.91432 192.239 6.08085C192.892 6.24738 193.47 6.5055 193.973 6.85521C194.482 7.20492 194.883 7.65457 195.177 8.2041C195.469 8.74812 195.616 9.40033 195.616 10.1608V18.7871H192.264V17.0135H192.164C191.96 17.4132 191.686 17.7657 191.343 18.071C191 18.3707 190.588 18.6067 190.106 18.7787C189.625 18.9453 189.069 19.0285 188.439 19.0285ZM189.451 16.5805C189.971 16.5805 190.43 16.4779 190.829 16.2725C191.227 16.0615 191.539 15.7784 191.766 15.4232C191.993 15.0679 192.106 14.6655 192.106 14.2158V12.8586C191.996 12.9308 191.844 12.9974 191.65 13.0585C191.462 13.114 191.249 13.1667 191.011 13.2167C190.773 13.2611 190.535 13.3027 190.297 13.3416C190.06 13.3748 189.844 13.4054 189.65 13.4331C189.235 13.4942 188.873 13.5913 188.563 13.7246C188.253 13.8578 188.013 14.0382 187.841 14.2658C187.67 14.4878 187.584 14.7654 187.584 15.0985C187.584 15.5814 187.758 15.9505 188.107 16.2059C188.461 16.4557 188.909 16.5805 189.451 16.5805Z"
          fill="#06182D"
        />
        <path
          d="M197.19 18.7871V5.99761H200.725V18.7871H197.19ZM198.966 4.34898C198.44 4.34898 197.99 4.17413 197.614 3.82438C197.243 3.46914 197.058 3.04449 197.058 2.55045C197.058 2.06196 197.243 1.64286 197.614 1.29315C197.99 0.937886 198.44 0.760254 198.966 0.760254C199.491 0.760254 199.94 0.937886 200.31 1.29315C200.686 1.64286 200.874 2.06196 200.874 2.55045C200.874 3.04449 200.686 3.46914 200.31 3.82438C199.94 4.17413 199.491 4.34898 198.966 4.34898Z"
          fill="#06182D"
        />
        <path
          d="M78.2511 12.1466C78.2511 16.024 75.1188 19.1672 71.2551 19.1672C67.391 19.1672 64.2588 16.024 64.2588 12.1466C64.2588 8.26922 67.391 5.12598 71.2551 5.12598C75.1188 5.12598 78.2511 8.26922 78.2511 12.1466Z"
          fill="#009932"
        />
        <path
          d="M70.8393 15.8926H70.4246L70.8393 12.9786H69.3875C69.1469 12.9786 69.151 12.8454 69.2298 12.7038C69.3087 12.5623 69.2504 12.6705 69.2586 12.6539C69.794 11.7048 70.5987 10.2894 71.669 8.39941H72.0837L71.669 11.3134H73.1208C73.3241 11.3134 73.3532 11.4508 73.3159 11.5257L73.2867 11.5882C71.6522 14.4564 70.8393 15.8926 70.8393 15.8926Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_21637_40085">
          <rect width="201" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LogoLight;
