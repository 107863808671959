import { Box } from "@mui/material";

import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";

type ExpandBoxProps = {
  onClick: () => void;
  type: "more" | "less";
};

const ExpandBox: React.FC<ExpandBoxProps> = ({ onClick, type }) => {
  return (
    <Box onClick={onClick} sx={{ cursor: "pointer", display: "flex" }}>
      {type === "more" ? <ExpandMoreRoundedIcon /> : <ExpandLessRoundedIcon />}
    </Box>
  );
};

export default ExpandBox;
