import { useState } from "react";

import { Box, Paper, Typography } from "@mui/material";

import { TimePeriod } from "../../../API";
import StyledChart from "../../../common/components/apexchart/StyledChart";
import CircularLoading from "../../../common/components/progress/CircularLoading";
import { useMostActiveLocationsChart } from "../hooks/useMostActiveLocationsChart";
import StatisticsNoDataView from "./StatisticsNoDataView";
import TimePeriodFilter from "./TimePeriodFilter";

const MostActiveLocationsContainer = (): JSX.Element => {
  const [activeTimePeriod, setActiveTimePeriod] = useState<TimePeriod>(
    TimePeriod.ONE_WEEK
  );

  const handleTimePeriodChange = (timePeriod: TimePeriod): void => {
    if (timePeriod !== activeTimePeriod) {
      setActiveTimePeriod(timePeriod);
    }
  };

  const { series, options, loading, refetching, showNoData } =
    useMostActiveLocationsChart(activeTimePeriod);

  return (
    <Paper
      sx={{
        padding: "1.5em",
        width: "100%",
        height: "428px",
      }}
    >
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Typography variant="h5">Most active locations</Typography>
        <Box
          sx={{
            display: "flex",
            marginLeft: "auto",
          }}
        >
          <TimePeriodFilter
            activePeriod={activeTimePeriod}
            setActivePeriod={handleTimePeriodChange}
          />
        </Box>
      </Box>
      {(loading || refetching) && <CircularLoading />}
      {!loading && !showNoData && (
        <StyledChart
          options={options}
          series={series}
          height={300}
          type="bar"
        />
      )}
      {showNoData && <StatisticsNoDataView />}
    </Paper>
  );
};

export default MostActiveLocationsContainer;
