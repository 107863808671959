import { useQuery } from "@apollo/client";

import {
  GetCategoryByIdQuery,
  GetCategoryByIdQueryVariables,
  GetCategoryInput,
} from "../../../../../API";
import { GET_CATEGORY_BY_ID } from "../../../../../common/operations/queries";

export const useGetCategory = (input: GetCategoryInput) => {
  const { data, loading, refetch } = useQuery<
    GetCategoryByIdQuery,
    GetCategoryByIdQueryVariables
  >(GET_CATEGORY_BY_ID, {
    variables: {
      input,
    },
    skip: !input.categoryId || !input.customerId,
  });

  return { data, loading, refetch };
};
