import {
  type FetchResult,
  useMutation,
  type OperationVariables,
} from "@apollo/client";
import type {
  AddLocationMutation,
  AddLocationMutationVariables,
} from "../../../../../API";
import { ADD_LOCATION } from "../../../../../common/operations/mutations";

export const useSaveLocation = (): [
  saveLocationItem: ({
    input: { customerId, customerExternalId, locationData, name },
  }: AddLocationMutationVariables) => Promise<FetchResult<AddLocationMutation>>,
  variables: OperationVariables
] => {
  const [saveLocation, { data, loading, error }] = useMutation(ADD_LOCATION);

  const saveLocationItem = async ({
    input: {
      customerId,
      customerExternalId,
      locationData,
      name,
      referenceId,
      tags,
      timeZone,
    },
  }: AddLocationMutationVariables): Promise<
    FetchResult<AddLocationMutation>
  > => {
    return await saveLocation({
      variables: {
        input: {
          customerExternalId,
          customerId,
          locationData,
          referenceId,
          tags,
          name,
          timeZone,
        },
      },
      refetchQueries: ["GetLocations"],
    });
  };

  return [saveLocationItem, { data, loading, error }];
};
