import { LinearProgress } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

import TableNoDataOverlay from "../../../../../common/components/table/TableNoDataOverlay";
import { dataGridServerStyles } from "../../../../../common/providers/theme/design-tokens/DataGrid/dataGridServer.styles";
import { DEFAULT_COLUMN_HEADER_HEIGHT } from "../../../../../common/variables/common";
import { IAlertsTableRow } from "../hooks/useAlertsTableRow";
import { ALERTS_TABLE_LIMIT } from "../hooks/useGetLazyPaginatedAlerts";
import { IUserAlertsTableRow } from "../hooks/useUserAlertsTableRow";
import NoAlertsAdded from "./NoAlertsAdded";

interface IAlertsTableProps {
  rows: IUserAlertsTableRow[] | IAlertsTableRow[];
  columns: GridColDef[];
  loading: boolean;
}

const AlertsTable = ({
  rows,
  columns,
  loading,
}: IAlertsTableProps): JSX.Element => {
  if (!loading && !rows.length) {
    return <NoAlertsAdded />;
  }

  return (
    <DataGrid
      sx={dataGridServerStyles.sx}
      rows={rows}
      columns={columns}
      slots={{
        loadingOverlay: () => <LinearProgress />,
        noRowsOverlay: TableNoDataOverlay,
      }}
      columnHeaderHeight={DEFAULT_COLUMN_HEADER_HEIGHT}
      autoHeight
      disableRowSelectionOnClick
      hideFooterSelectedRowCount
      getRowId={(row: { rowId: string }): string => row.rowId}
      loading={loading}
      sortingOrder={["desc", "asc"]}
      pageSizeOptions={[10, 25, 50, 100]}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: ALERTS_TABLE_LIMIT,
          },
        },
      }}
    />
  );
};

export default AlertsTable;
