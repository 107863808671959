import { memo } from "react";

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { Controller } from "react-hook-form";

import DeleteIconButton from "../../../../../../../../common/components/icons/DeleteIconButton";
import StatusToggle from "../../../../../../../../common/components/item/StatusToggle";
import { IAlertFormBase } from "../AlertForm";
import AlertToggleSection from "./AlertToggleSection";

interface EmailAlertToggleSectionProps extends IAlertFormBase {
  everySwithcIsOff: boolean;
  additionalEmailsError: boolean;
  onAddAdditionalEmail: () => void;
  onDeleteAdditionalEmail: (index: number) => void;
}

const AdditionalEmailsAlertToggleSectionComponent = ({
  formValues,
  control,
  everySwithcIsOff,
  additionalEmailsError,
  onAddAdditionalEmail,
  onDeleteAdditionalEmail,
}: EmailAlertToggleSectionProps) => {
  return (
    <AlertToggleSection
      toggleChecked={formValues.isAdditionalEmailsToggleEnabled}
      title="Additional emails"
      hasError={everySwithcIsOff || additionalEmailsError}
      ToggleComponent={
        <Controller
          name="isAdditionalEmailsToggleEnabled"
          control={control}
          render={({ field }) => (
            <StatusToggle
              {...field}
              checked={field.value}
              handleChange={field.onChange}
            />
          )}
        />
      }
    >
      {formValues.isAdditionalEmailsToggleEnabled && (
        <>
          <Divider
            sx={{
              marginBottom: "1em",
            }}
          />
          {formValues.additionalEmails.map((_email, index): JSX.Element => {
            return (
              <Grid key={`email-${index}`} container spacing={3}>
                <Grid item xs={9} sm={10} md={6}>
                  <Controller
                    key={`additionalEmails.${index}.position`}
                    name={`additionalEmails.${index}.email`}
                    control={control}
                    render={({ field }) => {
                      return (
                        <TextField
                          {...field}
                          margin="dense"
                          fullWidth
                          label="Email"
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={3}
                  sm={2}
                  md={1}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <DeleteIconButton
                    onClick={() => onDeleteAdditionalEmail(index)}
                  />
                </Grid>
              </Grid>
            );
          })}
          <Box
            sx={{
              marginTop: "1em",
              display:
                formValues.additionalEmails.length >= 5 ? "none" : "block",
            }}
          >
            <Button
              startIcon={<AddOutlinedIcon />}
              variant="outlined"
              size="small"
              onClick={onAddAdditionalEmail}
            >
              Additional email
            </Button>
          </Box>
        </>
      )}
    </AlertToggleSection>
  );
};

const EmailAlertToggleSection = memo(
  AdditionalEmailsAlertToggleSectionComponent
);

export default EmailAlertToggleSection;
