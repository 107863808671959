import { Box, Paper } from "@mui/material";
import { useAuthenticatedUser } from "../../common/hooks/useAuthenticatedUser";
import CCAdminHumanValidatorTable from "./components/CCAdminHumanValidatorTable";

import HumanValidatorPageHeader from "./components/HumanValidatorPageHeader";
import HumanValidatorTable from "./components/HumanValidatorTable";
import HumanValidatorTableItemDetailsContent from "./components/HumanValidatorTableItemDetailsContent";
import { AdminRoles } from "./variables/humanValidatorTable";

const HumanValidatorPage = (): JSX.Element => {
  const { role } = useAuthenticatedUser();

  const isAdmin = AdminRoles.includes(role);

  const component = isAdmin ? (
    <CCAdminHumanValidatorTable />
  ) : (
    <HumanValidatorTable />
  );

  return (
    <Box sx={{ display: "flex" }}>
      <Box
        sx={{
          flexGrow: 1,
          backgroundColor: "transparent",
        }}
      >
        <Paper sx={{ padding: "1em", marginBottom: "2em" }}>
          <HumanValidatorPageHeader />
        </Paper>
        <Paper sx={{ padding: "1em" }}>{component}</Paper>
      </Box>
      <HumanValidatorTableItemDetailsContent />
    </Box>
  );
};

export default HumanValidatorPage;
