import { useNavigate } from "react-router-dom";

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { Box, Button, Stack, Typography } from "@mui/material";

const NodesPageHeader = (): JSX.Element => {
  const navigate = useNavigate();

  return (
    <Box padding="0" marginBottom="1em">
      <Stack spacing={4} direction="row" alignItems="center">
        <Typography variant="h3" padding={0} sx={{ flexGrow: 1 }}>
          Nodes
        </Typography>

        <Button
          variant="outlined"
          size="small"
          onClick={(): void => navigate("/nodes/create")}
          startIcon={<AddOutlinedIcon />}
        >
          New node
        </Button>
      </Stack>
    </Box>
  );
};

export default NodesPageHeader;
