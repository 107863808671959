import { FC } from "react";
import { useNavigate } from "react-router-dom";

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import { RouteEnum } from "../../models/enums";

interface TableNoDataProps {
  title: string;
  description: string;
  redirectPath: RouteEnum | string;
  addButtonTitle: string;
}

const TableNoData: FC<TableNoDataProps> = ({
  title,
  description,
  redirectPath,
  addButtonTitle,
}) => {
  const navigate = useNavigate();

  return (
    <Paper
      variant="outlined"
      elevation={0}
      sx={{
        width: "100%",
        height: "100%",
        minHeight: "200px",
        padding: "1.5em",
        borderRadius: "12px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography
        variant="h5"
        sx={{
          marginBottom: "0.25em",
          "& a": { color: "primary.dark" },
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="body1Regular"
        sx={{
          color: "text.secondary",
          marginBottom: "1.5em",
          "& a": { color: "primary.dark" },
        }}
      >
        {description}
      </Typography>

      <Button
        variant="contained"
        size="small"
        startIcon={<AddOutlinedIcon />}
        onClick={(): void => navigate(redirectPath)}
      >
        {addButtonTitle}
      </Button>
    </Paper>
  );
};

export default TableNoData;
