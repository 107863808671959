import { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import FullPageLoader from "../../../../common/components/item/FullPageLoader";
import {
  IParsedUserInfo,
  useLazyGetUser,
} from "../../../../leak-finder/hooks/useLazyGetUser";
import VideoSpeedSection from "./components/video-speed/VideoSpeedSection";

const UserPreferencesTab = () => {
  const { loading: userInfoLoading, getParsedUserInfo } = useLazyGetUser();

  const [userSettings, setUserSettings] = useState<IParsedUserInfo | null>(
    null
  );

  useEffect(() => {
    const getUserInfo = async () => {
      const response = await getParsedUserInfo();

      setUserSettings(response);
    };

    getUserInfo();
  }, []);

  return (
    <Box>
      {userInfoLoading && <FullPageLoader />}

      <Typography sx={{ margin: "1em 0" }} variant="h5">
        User preferences
      </Typography>

      <VideoSpeedSection userSettings={userSettings} />
    </Box>
  );
};

export default UserPreferencesTab;
