import React, { useEffect, useState } from "react";

import {
  Autocomplete,
  Chip,
  TextField,
  ListItemText,
  ListItem,
  IconButton,
  useTheme,
  Button,
  Box,
} from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import withCustomPaper from "../CustomPaperActionButton";
import DeleteIcon from "../../icons/DeleteIcon";
import CustomCheckbox from "../../icons/CustomCheckbox";
import { Controller, useForm } from "react-hook-form";
import StyledDialog from "../../dialog/StyledDialog";
import confirmDialogStyleOptions from "../../../helpers/confirmDialogParams";
import { useDialog } from "../../dialog/useDialog";
import StyledLoadingButton from "../../../providers/theme/design-tokens/LoadingButton/StyledLoadingButton";
import { useCreateTag } from "../../../../pages/system-settings/tags/hooks/useCreateTag";
import { Tag, TagOfAssociation } from "../../../../API";
import { useDeleteTag } from "../../../../pages/system-settings/tags/hooks/useDeleteTag";

type TagsManagementMultidropdownProps = {
  entityTags: TagOfAssociation[];
  onChange: (
    event: React.SyntheticEvent<Element, Event>,
    newValue: string[]
  ) => void;
  allTags: Tag[];
};

type FormValues = {
  tagName: string;
};

const TagsManagementMultidropdown: React.FC<
  TagsManagementMultidropdownProps
> = ({ entityTags, onChange, allTags }) => {
  useEffect(() => {
    const formatTagsToString = entityTags.map(tag => tag.tagName);

    setSelectedOptions(formatTagsToString);
  }, [entityTags]);

  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const theme = useTheme();

  const confirm = useConfirm();
  const { open, handleDialogClose, handleDialogOpen } = useDialog();

  const { deleteTag } = useDeleteTag();
  const { createTag } = useCreateTag();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormValues>();

  const onCloseDialog = () => {
    handleDialogClose();

    reset();
  };

  const onCreateTag = ({ tagName }: FormValues) => {
    createTag(tagName);

    onCloseDialog();
  };

  const handleOnCreate = () => {
    handleSubmit(onCreateTag)();
  };

  const customPaper = withCustomPaper({
    text: "New location tag",
    onClick: () => {
      handleDialogOpen();
    },
  });

  const onDeleteTagHandler = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    tagName: string
  ) => {
    event.stopPropagation();

    const foundTag = allTags.find(item => item.tagName === tagName);
    const newValues = selectedOptions.filter(item => item !== tagName);

    confirm({
      title: `Are you sure you want to delete the ${tagName} location tag?`,
      confirmationText: "Delete",
      cancellationText: "Cancel",
      content: "It will be deleted from all locations and can’t be restored",
      ...confirmDialogStyleOptions,
    }).then((): void => {
      if (foundTag?.id) deleteTag(foundTag.id, foundTag.tagName);

      setSelectedOptions(newValues);
    });
  };

  const onChangeTagHandler = (
    event: React.SyntheticEvent<Element, Event>,
    newValues: string[]
  ) => {
    onChange(event, newValues);

    setSelectedOptions(newValues);
  };

  const optionList = allTags.map(tag => tag.tagName);

  return (
    <>
      <Autocomplete
        value={selectedOptions}
        onChange={onChangeTagHandler}
        multiple
        size="medium"
        id="tags-filled"
        options={optionList}
        disableCloseOnSelect
        clearOnEscape
        PaperComponent={customPaper}
        popupIcon={<ExpandMoreIcon />}
        renderOption={(props, option, { selected }) => (
          <ListItem
            {...props}
            sx={{
              marginBottom: 0,
              paddingBottom: 0,
              "&:hover": {
                paddingLeft: "1em",
                ".deleteIcon": {
                  visibility: "visible",
                },
              },
            }}
          >
            <CustomCheckbox checked={selected} />
            <ListItemText sx={{ marginLeft: "0.5em" }} primary={option} />

            <IconButton
              className="deleteIcon"
              sx={{ visibility: "hidden" }}
              onClick={event => onDeleteTagHandler(event, option)}
            >
              <DeleteIcon width={20} height={20} />
            </IconButton>
          </ListItem>
        )}
        renderTags={(value, getTagProps): JSX.Element[] =>
          value.map(
            (option, index): JSX.Element => (
              <Chip
                sx={{
                  borderRadius: "8px",
                  border: `1px solid ${theme.palette.otherOutlineBorder.main}`,
                  backgroundColor: theme.palette.otherBackground.main,
                }}
                variant="outlined"
                label={`#${option}`}
                {...getTagProps({ index })}
                deleteIcon={<CloseIcon style={{ fontSize: "1.5em" }} />}
                key={option}
              />
            )
          )
        }
        renderInput={params => (
          <TextField
            {...params}
            variant="outlined"
            label="Select location tag(s) (optional)"
          />
        )}
      />
      <StyledDialog
        title="Add new location tag"
        open={open}
        onClose={onCloseDialog}
        showSubmitButton={false}
      >
        <Controller
          name="tagName"
          control={control}
          rules={{
            required: "This field is required",
            validate: value =>
              optionList.includes(value)
                ? "This tag name is already used"
                : true,
          }}
          render={({ field }) => (
            <TextField
              {...field}
              value={field.value || ""}
              margin="dense"
              sx={{
                height: "68px",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px",
                },
              }}
              label={"Location tag name"}
              fullWidth
              error={!!errors.tagName}
              helperText={errors.tagName ? errors.tagName.message : ""}
            />
          )}
        />
        <Box
          sx={{
            display: "flex",
            marginTop: "1.5em",
            justifyContent: "center",
            gap: "1em",
          }}
        >
          <Button variant="outlined" color="secondary" onClick={onCloseDialog}>
            Cancel
          </Button>
          <StyledLoadingButton
            loadingPosition="start"
            variant="contained"
            color="primary"
            onClick={handleOnCreate}
          >
            Add new tag
          </StyledLoadingButton>
        </Box>
      </StyledDialog>
    </>
  );
};

export default TagsManagementMultidropdown;
