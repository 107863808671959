import { memo } from "react";
import { useNavigate } from "react-router-dom";

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { Button, Paper, Typography } from "@mui/material";

import CircularLoading from "../../../common/components/progress/CircularLoading";
import { useSelectedLocationNodes } from "../variables/nodes";

interface INodesTableNoRowsOverlayProps {
  loading?: boolean;
}

const NodesTableNoRowsOverlayComponent = ({
  loading,
}: INodesTableNoRowsOverlayProps): JSX.Element => {
  const navigate = useNavigate();

  const selectedLocation = useSelectedLocationNodes();

  const handleAddNode = (): void => {
    navigate("/nodes/create");
  };

  return (
    <Paper
      sx={{
        height: "240px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {loading ? (
        <CircularLoading />
      ) : (
        <>
          <Typography variant="h4">
            There is no nodes{" "}
            {selectedLocation?.title ? `in ${selectedLocation?.title}` : ""}
          </Typography>
          <Typography variant="body1Regular" color="text.secondary">
            Add nodes to manage them
          </Typography>

          <Button
            sx={{
              marginTop: "24px",
            }}
            variant="contained"
            onClick={handleAddNode}
            startIcon={<AddOutlinedIcon />}
          >
            New node
          </Button>
        </>
      )}
    </Paper>
  );
};

const NodesTableNoRowsOverlay = memo(NodesTableNoRowsOverlayComponent);

export default NodesTableNoRowsOverlay;
