export function parseQueryResult(response) {
  if (!response) return [];

  const columnInfo = response.ColumnInfo;
  const rows = response.Rows;

  const result = rows.map(row => {
    return parseRow(columnInfo, row);
  });

  return result;
}

function parseRow(columnInfo, row) {
  const data = row.Data;
  const mappedObject = {};

  let i;

  for (i = 0; i < data.length; i++) {
    const info = columnInfo[i];
    const datum = data[i];

    mappedObject[info.Name] = datum.ScalarValue;
  }

  return mappedObject;
}
