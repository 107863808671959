import { useTheme } from "@mui/material/styles";

import { themeMode } from "../../providers/theme/MuiThemeProvider";

const AIManagerMenuIconFilled = (): JSX.Element => {
  const theme = useTheme();

  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="16" fill={theme.palette.primary.main} />
      <g clipPath="url(#clip0_395_10038)">
        <path
          d="M14.3332 24.3333C11.1905 24.3333 9.61913 24.3333 8.64281 23.357C7.6665 22.3807 7.6665 21.6427 7.6665 18.5"
          stroke={
            theme.palette.mode === themeMode.light
              ? theme.palette.otherBackgroundLight.main
              : theme.palette.text.primary
          }
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M24.3332 18.5C24.3332 21.6427 24.3332 22.3807 23.3569 23.357C22.3805 24.3333 20.8092 24.3333 17.6665 24.3333"
          stroke={
            theme.palette.mode === themeMode.light
              ? theme.palette.otherBackgroundLight.main
              : theme.palette.text.primary
          }
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M17.6665 7.66675C20.8092 7.66675 22.3805 7.66675 23.3569 8.64306C24.3332 9.61937 24.3332 10.3574 24.3332 13.5001"
          stroke={
            theme.palette.mode === themeMode.light
              ? theme.palette.otherBackgroundLight.main
              : theme.palette.text.primary
          }
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M14.3332 7.66675C11.1905 7.66675 9.61913 7.66675 8.64281 8.64306C7.6665 9.61937 7.6665 10.3574 7.6665 13.5001"
          stroke={
            theme.palette.mode === themeMode.light
              ? theme.palette.otherBackgroundLight.main
              : theme.palette.text.primary
          }
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M10.9102 17.7164C10.4144 17.1413 10.1665 16.8538 10.1665 15.9999C10.1665 15.1461 10.4144 14.8585 10.9102 14.2834C11.9001 13.1351 13.5604 11.8333 15.9998 11.8333C18.4393 11.8333 20.0995 13.1351 21.0895 14.2834C21.5853 14.8585 21.8332 15.1461 21.8332 15.9999C21.8332 16.8538 21.5853 17.1413 21.0895 17.7164C20.0995 18.8647 18.4393 20.1666 15.9998 20.1666C13.5604 20.1666 11.9001 18.8647 10.9102 17.7164Z"
          stroke={
            theme.palette.mode === themeMode.light
              ? theme.palette.otherBackgroundLight.main
              : theme.palette.text.primary
          }
          strokeWidth="1.5"
        />
        <circle
          cx="16.0002"
          cy="15.9999"
          r="1.66667"
          stroke={
            theme.palette.mode === themeMode.light
              ? theme.palette.otherBackgroundLight.main
              : theme.palette.text.primary
          }
          strokeWidth="1.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_395_10038">
          <rect
            x="6"
            y="6"
            width="20"
            height="20"
            rx="5"
            fill={theme.palette.otherBackgroundLight.main}
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AIManagerMenuIconFilled;
