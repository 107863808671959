import { TableCell, TableHead, TableRow } from "@mui/material";

const DeploymentHealthTableHeadRow = (): JSX.Element => {
  return (
    <TableHead
      sx={{
        "& .MuiTableCell-head": {
          fontWeight: 600,
        },
      }}
    >
      <TableRow>
        <TableCell
          sx={{
            width: "34px",
          }}
        ></TableCell>
        <TableCell
          sx={{
            width: "34px",
          }}
        ></TableCell>
        <TableCell>Location</TableCell>
        <TableCell>Nodes</TableCell>
        <TableCell>Device Status</TableCell>
        <TableCell>Model Status</TableCell>
      </TableRow>
    </TableHead>
  );
};

export default DeploymentHealthTableHeadRow;
