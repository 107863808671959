import { useGetForm } from "../../../common/hooks/useGetForm";
import DynamicTextInput from "../DynamicInputs/DynamicTextInput";
import DynamicNumberInput from "../DynamicInputs/DynamicNumberInput";
import DynamicDropdownInput from "../DynamicInputs/DynamicDropDownInput";
import DynamicQueryDropdownInput from "../DynamicInputs/DynamicQueryDropdownInput";

interface DynamicFieldsProps {
  serviceConfig: any;
  setServiceConfig: (serviceConfigValue: any) => void;
  selectedService: string;
}

const DynamicFields = ({
  serviceConfig,
  setServiceConfig,
  selectedService,
}: DynamicFieldsProps): JSX.Element => {
  const { form } = useGetForm(selectedService);

  // TODO (maybe?): Replace switch with Conditional Rendering with Enum.
  return (
    <>
      {form?.fields?.map((field): JSX.Element | null => {
        switch (field?.type) {
          case "STRING":
            return (
              <DynamicTextInput
                key={field.name}
                name={field.name}
                serviceConfig={serviceConfig}
                setServiceConfig={setServiceConfig}
              />
            );

          case "NUMBER":
            return (
              <DynamicNumberInput
                key={field.name}
                name={field.name}
                serviceConfig={serviceConfig}
                setServiceConfig={setServiceConfig}
              />
            );

          case "DROPDOWN":
            return (
              <DynamicDropdownInput
                key={field.name}
                name={field.name}
                entries={field.entries}
                serviceConfig={serviceConfig}
                setServiceConfig={setServiceConfig}
              />
            );

          case "DYNAMIC_DROPDOWN":
            return (
              <DynamicQueryDropdownInput
                key={field.name}
                name={field.name}
                itemToQuery={field.itemToQuery}
                serviceConfig={serviceConfig}
                setServiceConfig={setServiceConfig}
              />
            );

          default:
            break;
        }

        return null;
      })}
    </>
  );
};

export default DynamicFields;
