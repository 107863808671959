import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import Stack from "@mui/material/Stack";
import uniq from "lodash/uniq";
import { useConfirm } from "material-ui-confirm";
import { useFieldArray, useForm } from "react-hook-form";

import { addAlertEmailNotificationInput } from "../../../../../../../API";
import confirmDialogStyleOptions from "../../../../../../../common/helpers/confirmDialogParams";
import { useAuthenticatedUser } from "../../../../../../../common/hooks/useAuthenticatedUser";
import { useCustomerIdGuard } from "../../../../../../../common/hooks/useCustomerIdGuard";
import { AutocompleteOptionType } from "../../../../../../../common/models/autocomplete";
import { RouteEnum } from "../../../../../../../common/models/enums";
import StyledLoadingButton from "../../../../../../../common/providers/theme/design-tokens/LoadingButton/StyledLoadingButton";
import {
  errorNotification,
  successNotification,
} from "../../../../../../../common/variables/notification";
import { AlertManagementTabsEnum } from "../../../../../../../common/variables/tabsSettings";
import { ALERTS_ADMIN_ACCESS } from "../../../AlertManagementTab";
import { useCreateAlertEmailNotification } from "../../../hooks/useCreateAlertEmailNotification";
import { AlertTypeEnum } from "../../../variables/alerts";
import AlertForm from "./AlertForm";

interface IAlertEmailSection {
  position: number;
  email: string;
}

interface IAlertPhoneNumbersSection {
  position: number;
  phoneNumber: string;
}

export interface IAlertFormInput {
  subscribedLevelEntity: string;
  alertName: string;
  entity: string;
  systemAlert: boolean;
  emailAlert: boolean;
  smsAlert: boolean;
  additionalEmails: string[];
  additionalPhones: string[];
  locationId: string;
  tagId: string;
  model: string;
}

export interface IAlertForm {
  subscribedLevelEntity: string;
  alertName: string;
  entity: string;
  isEmailToggleEnabled: boolean;
  isAdditionalEmailsToggleEnabled: boolean;
  isSmsToggleEnabled: boolean;
  additionalEmails: IAlertEmailSection[];
  additionalPhones: IAlertPhoneNumbersSection[];
  locationId: string;
  tagId: string;
  model: string;
}

const CreateAlertContainer = () => {
  const navigate = useNavigate();

  const confirm = useConfirm();

  const { role, user } = useAuthenticatedUser();

  const selectedCustomerId = useCustomerIdGuard();

  const { createAlertEmailNotification, loading } =
    useCreateAlertEmailNotification();

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    clearErrors,
    formState: { errors, isSubmitted, isDirty },
  } = useForm<IAlertForm>({
    defaultValues: {
      additionalEmails: [],
      additionalPhones: [],
      alertName: "",
      entity: "",
      isEmailToggleEnabled: false,
      isAdditionalEmailsToggleEnabled: false,
      isSmsToggleEnabled: false,
      subscribedLevelEntity: "",
      locationId: "",
      tagId: "",
      model: "",
    },
  });

  const {
    fields: additionalEmailsFields,
    append: appendEmail,
    remove: removeEmail,
  } = useFieldArray({
    control,
    name: "additionalEmails",
  });

  const {
    fields: additionalPhones,
    append: appendPhoneNumber,
    update: updatePhoneNumber,
    remove: removePhoneNumber,
  } = useFieldArray({
    control,
    name: "additionalPhones",
  });

  const formValues = watch();

  const onSubmit = (): void => {
    const alertForm = watch();

    if (
      !alertForm.isEmailToggleEnabled &&
      !alertForm.isAdditionalEmailsToggleEnabled &&
      !alertForm.isSmsToggleEnabled
    ) {
      return;
    }

    const filteredEmails = uniq(
      alertForm.additionalEmails.filter((e): boolean => e.email !== "")
    ).map((e): string => e.email.toLowerCase());

    const filteredPhoneNumbers = uniq(
      alertForm.additionalPhones.filter(
        (phone): boolean => phone.phoneNumber !== ""
      )
    ).map((phone): string => phone.phoneNumber);

    if (alertForm.isAdditionalEmailsToggleEnabled && !filteredEmails.length) {
      return;
    }

    const input: addAlertEmailNotificationInput = {
      customerId: selectedCustomerId,
      alertName: alertForm.alertName,
      alertType: AlertTypeEnum.EMAIL,
      createdBy: user?.attributes?.email ?? "",
      email: user?.attributes?.email.toLowerCase(),
      location: alertForm.locationId,
      model: alertForm.model,
      tag: alertForm.tagId,
      notificationSettingsJson: JSON.stringify({
        isEmailEnabled: alertForm.isEmailToggleEnabled,
        isAdditionalEmailsEnabled: filteredEmails.length > 0,
        isSMSEnabled: filteredPhoneNumbers.length > 0,
      }),
      additionalEmails: filteredEmails,
      phoneNumber: "",
      additionalPhoneNumbers: filteredPhoneNumbers,
      node: "",
      customService: "",
      jsonObject: JSON.stringify({}),
      subscribedLevelEntity: alertForm.subscribedLevelEntity,
    };

    createAlertEmailNotification(input)
      .then((response): void => {
        if (response?.data) {
          successNotification(`${alertForm.alertName} alert is added`);
        }

        if (response.errors) {
          errorNotification(
            `Something went wrong, ${alertForm.alertName} alert is not added`
          );
        } else {
          let alertTab = AlertManagementTabsEnum.CUSTOMER;

          if (formValues.entity === "Location") {
            alertTab = AlertManagementTabsEnum.LOCATION;
          }

          if (formValues.entity === "Tag") {
            alertTab = AlertManagementTabsEnum.TAG;
          }

          if (formValues.entity === "Model") {
            alertTab = AlertManagementTabsEnum.MODEL;
          }

          navigate(breadcrumbItems[0].path ?? "", {
            state: {
              alertTab,
            },
          });
        }
      })
      .catch((error): void => {
        errorNotification(
          `Something went wrong, ${alertForm.alertName} alert is not added`
        );

        console.error(error);
      });
  };

  const confirmCancel = (path: string): void => {
    confirm({
      title: "Are you sure you want to cancel editing?",
      description: "Go back to save changes",
      confirmationText: "Cancel",
      cancellationText: "Back",
      ...confirmDialogStyleOptions,
      confirmationButtonProps: {
        ...confirmDialogStyleOptions.confirmationButtonProps,
        color: "primary",
      },
    }).then((): void => {
      navigate(path);
    });
  };

  const handleCancel = (): void => {
    const path = breadcrumbItems[0].path ?? "";

    isDirty ? confirmCancel(path) : navigate(path);
  };

  const isAdminUser = ALERTS_ADMIN_ACCESS.includes(role);

  const breadcrumbItems = useMemo(
    () => [
      {
        label: isAdminUser ? "Customer settings" : "System settings",
        path: isAdminUser
          ? `${RouteEnum.CustomerSettings}/${RouteEnum.AlertManagement}`
          : `${RouteEnum.SensorySystemSettings}/${RouteEnum.AlertManagement}`,
      },
      {
        label: "Add new alert",
      },
    ],
    []
  );

  const memoizedSetEntityCallback = useCallback(
    (value: string) => {
      setValue("subscribedLevelEntity", selectedCustomerId);

      setValue("entity", value);
    },
    [selectedCustomerId]
  );

  const memoizedSetLocationCallback = useCallback(
    (selectedLocation: AutocompleteOptionType) => {
      setValue("locationId", selectedLocation?.value ?? "");

      setValue("tagId", "");

      setValue("model", "");

      setValue("subscribedLevelEntity", selectedLocation?.value ?? "");

      if (selectedLocation?.value) {
        clearErrors("locationId");
      }
    },
    []
  );

  const memoizedSetLocationTagCallback = useCallback((tagId: string) => {
    if (!tagId) return;

    setValue("tagId", tagId);

    setValue("model", "");

    setValue("locationId", "");

    setValue("subscribedLevelEntity", tagId);

    clearErrors("locationId");
  }, []);

  const memoizedSetModelCallback = useCallback((newValue: string | null) => {
    if (!newValue) return;

    const alertForm = watch();

    const locationOrTag = alertForm.locationId
      ? alertForm.locationId
      : alertForm.tagId;

    setValue("model", newValue);

    setValue("subscribedLevelEntity", `${locationOrTag}#S#${newValue}`);
  }, []);

  const memoizedDeleteAdditionalEmailCallback = useCallback(
    (position: number) => {
      removeEmail(position);
    },
    []
  );

  const memoizedAddAdditionalEmailCallback = useCallback(() => {
    appendEmail({ email: "", position: additionalEmailsFields.length });
  }, [additionalEmailsFields]);

  const memoizedDeleteAdditionalPhoneNumberCallback = useCallback(
    (position: number) => {
      removePhoneNumber(position);
    },
    []
  );

  const memoizedAddAdditionalPhoneNumberCallback = useCallback(() => {
    appendPhoneNumber({ phoneNumber: "", position: additionalPhones.length });
  }, [additionalPhones]);

  const memoizedSetMobileNumberCallback = useCallback(
    (value: string, position: number) => {
      updatePhoneNumber(position, { phoneNumber: value, position });
    },
    []
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <AlertForm
        formValues={formValues}
        control={control}
        errors={errors}
        isSubmitted={isSubmitted}
        onEntityChange={memoizedSetEntityCallback}
        onLocationChange={memoizedSetLocationCallback}
        onLocationTagChange={memoizedSetLocationTagCallback}
        onModelChange={memoizedSetModelCallback}
        onAddAdditionalEmail={memoizedAddAdditionalEmailCallback}
        onDeleteAdditionalEmail={memoizedDeleteAdditionalEmailCallback}
        onAddAdditionalPhoneNumber={memoizedAddAdditionalPhoneNumberCallback}
        onDeleteAdditionalPhoneNumber={
          memoizedDeleteAdditionalPhoneNumberCallback
        }
        onPhoneNumberChange={memoizedSetMobileNumberCallback}
      />

      <Stack direction="row-reverse" spacing={2} sx={{ paddingTop: 3 }}>
        <StyledLoadingButton
          sx={{
            marginTop: "1.5em",
          }}
          type="submit"
          loading={loading}
          loadingPosition="start"
          variant="contained"
          color="primary"
        >
          Add new alert
        </StyledLoadingButton>
        <StyledLoadingButton
          sx={{
            marginTop: "1.5em",
          }}
          disabled={loading}
          variant="outlined"
          color="inherit"
          onClick={handleCancel}
        >
          Cancel
        </StyledLoadingButton>
      </Stack>
    </form>
  );
};

export default CreateAlertContainer;
