import { ReactElement } from "react";

import {
  GridActionsCellItem,
  GridActionsCellItemProps,
  GridColDef,
} from "@mui/x-data-grid";

import { Typography } from "@mui/material";

import ChipFilledOther from "../../../common/components/chip/ChipFilledOther";
import ChipFilledPrimary from "../../../common/components/chip/ChipFilledPrimary";
import DeleteNodeContainer from "../components/DeleteNodeContainer";
import NodeConnectedDevicesColumn from "../components/NodeConnectedDevicesColumn";

export const useNodeTableColumns = (): { columns: GridColDef[] } => {
  const columns: GridColDef[] = [
    {
      field: "index",
      headerName: "#",
      width: 20,
      maxWidth: 40,
      hideable: false,
      sortable: true,
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params): JSX.Element | null =>
        params ? (
          <Typography variant="body2Regular">{params?.row.index}</Typography>
        ) : null,
    },
    {
      field: "nodeName",
      headerName: "Node ID",
      minWidth: 250,
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params): JSX.Element | null =>
        params ? (
          <Typography variant="body2Regular">{params?.row.nodeName}</Typography>
        ) : null,
    },
    {
      field: "devices",
      headerName: "Connected Devices",
      flex: 1,
      minWidth: 350,
      disableColumnMenu: true,
      hideable: false,
      sortable: false,
      renderCell: (params): JSX.Element => {
        return <NodeConnectedDevicesColumn devices={params.row.devices} />;
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 100,
      disableColumnMenu: true,
      hideable: false,
      sortable: false,
      renderCell: (params): JSX.Element => {
        const isRunning = params.row?.isOnline;

        return (
          <>
            {isRunning ? (
              <ChipFilledPrimary label="Online" />
            ) : (
              <ChipFilledOther label="Offline" />
            )}
          </>
        );
      },
    },
    {
      field: "referenceId",
      headerName: "Reference ID",
      flex: 1,
      minWidth: 160,
      disableColumnMenu: true,
      hideable: false,
      sortable: false,
      renderCell: (params): JSX.Element | null => (
        <Typography variant="body2">{params?.row.referenceId}</Typography>
      ),
    },
    {
      field: "nodeIP",
      headerName: "IP Address",
      minWidth: 160,
      hideable: false,
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params): JSX.Element | null =>
        params ? (
          <Typography variant="body2Regular">{params?.row.nodeIP}</Typography>
        ) : null,
    },
    {
      field: "actions",
      type: "actions",
      flex: 1,
      minWidth: 80,
      cellClassName: "actions-cell",
      getActions: (params): ReactElement<GridActionsCellItemProps>[] => [
        <GridActionsCellItem
          key={`delete-${params.row?.rowId}`}
          icon={
            <DeleteNodeContainer
              devices={params.row.devices}
              nodeId={params.row.nodeId}
              rowId={params.row.rowId}
              name={params.row.nodeName}
            />
          }
          label="Delete"
          className="actionButton"
          disableRipple
        />,
      ],
    },
  ];

  return { columns };
};
