import Typography from "@mui/material/Typography";

import RouterLink from "../../../../common/components/RouterLink";
import GateGuardDataHubIcon from "../icons/GateGuardDataHubIcon";
import DataHubGridItem from "./DataHubGridItem";
import { IModelGridItemProps } from "./GasLeakGridItem";
import { RouteEnum } from "../../../../common/models/enums";

const GateGuardGridItem = ({ nonAdmin }: IModelGridItemProps): JSX.Element => {
  return (
    <RouterLink to={nonAdmin ? RouteEnum.SensoryAgg : RouteEnum.Agg}>
      <DataHubGridItem>
        <GateGuardDataHubIcon />
        <Typography variant="body2Bold">Gate Guard</Typography>
      </DataHubGridItem>
    </RouterLink>
  );
};

export default GateGuardGridItem;
