import CheckIcon from "@mui/icons-material/Check";
import { Paper, Typography } from "@mui/material";

import { useCustomerIdGuard } from "../../../common/hooks/useCustomerIdGuard";
import StyledLoadingButton from "../../../common/providers/theme/design-tokens/LoadingButton/StyledLoadingButton";
import { errorNotification } from "../../../common/variables/notification";
import { useSelectedLocationDropdown } from "../../../common/variables/selectedLocationDropdown";
import client from "../../../configs/apolloClient";
import DeviceForm from "../components/DeviceForm";
import { useCreateDevice } from "../hooks/useCreateDevice";
import {
  defaultDeviceFormValidationState,
  defaultDeviceFormValues,
  deviceFormValidationStateVariable,
  deviceFormVariables,
  useDeviceFormVariables,
} from "../variables/devices";
import { useSyncShadow } from "../../model-manager/hooks/useSyncShadow";

const CreateDevicePage = (): JSX.Element => {
  const form = useDeviceFormVariables();
  const selectedCustomerId = useCustomerIdGuard();
  const selectedLocation = useSelectedLocationDropdown();
  const { syncShadowsForNode } = useSyncShadow();

  const { createDevice, loading: createDeviceLoading } = useCreateDevice();

  const resetState = (): void => {
    deviceFormVariables({ ...defaultDeviceFormValues });

    deviceFormValidationStateVariable(defaultDeviceFormValidationState);
  };

  const saveDevice = async (): Promise<void> => {
    let validationState = {
      ...defaultDeviceFormValidationState,
    };

    const deviceId = `${form.location?.value ?? ""}#DE#${form.deviceName}`;

    const normalizedId = client.cache.identify({
      id: deviceId,
      __typename: "Device",
    });

    const extract = client.cache.extract();

    if (normalizedId && extract[normalizedId]?.name === form.deviceName) {
      validationState = {
        ...validationState,
        deviceName: {
          hasError: true,
          errorMessage: "Device ID already exists",
        },
      };
    }

    if (!form.deviceName) {
      validationState = {
        ...validationState,
        deviceName: {
          hasError: true,
          errorMessage: "This field is required",
        },
      };
    }

    if (!form.node?.id) {
      validationState = {
        ...validationState,
        node: {
          hasError: true,
          errorMessage: "Node is required",
        },
      };
    }

    if (!form.deviceType) {
      validationState = {
        ...validationState,
        deviceType: {
          hasError: true,
          errorMessage: "Device Model is required",
        },
      };
    }

    if (!form.cameraIpAddress) {
      validationState = {
        ...validationState,
        cameraIpAddress: {
          hasError: true,
          errorMessage: "This field is required",
        },
      };
    }

    if (
      Object.values(validationState).some(
        (item): boolean => item?.hasError ?? false
      )
    ) {
      deviceFormValidationStateVariable(validationState);

      return;
    }

    try {
      await createDevice({
        name: form.deviceName,
        customerId: selectedCustomerId,
        nodeId: form.node?.id as string,
        nodeName: form.node?.name,
        locationId: selectedLocation?.value as string,
        locationName: selectedLocation?.title,
        makeModelId: form.deviceType,
        cameraIpAddress: form.cameraIpAddress,
        deviceData: form.deviceData ? JSON.stringify(form.deviceData) : null,
      });

      await syncShadowsForNode(form.node?.id as string);

      resetState();
    } catch (error) {
      errorNotification("Something went wrong when creating device");

      console.error(error);
    }
  };

  const loading = createDeviceLoading;

  return (
    <Paper sx={{ padding: "1.5em" }}>
      <Typography variant="h5" sx={{ paddingBottom: "1em" }}>
        Create a new device
      </Typography>
      <DeviceForm showLocationSelect />
      <StyledLoadingButton
        fullWidth
        loading={loading}
        loadingPosition="start"
        startIcon={<CheckIcon />}
        variant="contained"
        color="success"
        onClick={saveDevice}
      >
        Create
      </StyledLoadingButton>
    </Paper>
  );
};

export default CreateDevicePage;
