import { FC } from "react";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import ListItemText from "@mui/material/ListItemText";

interface CollapseButtonProps {
  handleCollapse: () => void;
  opened: boolean;
  label: string;
}

const CollapseButton: FC<CollapseButtonProps> = ({
  opened,
  label,
  handleCollapse,
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "35px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0.5em 1em",
        "&, &:hover": {
          cursor: "pointer",
          backgroundColor: theme => theme.palette.divider,
        },
      }}
      onClick={handleCollapse}
    >
      <ListItemText primary={label} />
      {opened ? (
        <ExpandLess
          sx={{
            color: theme => theme.palette.otherTextTertiary.main,
          }}
        />
      ) : (
        <ExpandMore
          sx={{
            color: theme => theme.palette.otherTextTertiary.main,
          }}
        />
      )}
    </Box>
  );
};

export default CollapseButton;
