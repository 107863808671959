import { useNavigate } from "react-router-dom";

import { Box, Button, Typography } from "@mui/material";
import { useConfirm } from "material-ui-confirm";

import InfoText from "../../../../common/components/box/InfoText";
import WarningBox from "../../../../common/components/box/WarningBox";
import InstructionWrapperBox from "../../../../common/components/instructions/InstructionWrapperBox";
import confirmDialogStyleOptions from "../../../../common/helpers/confirmDialogParams";
import useBeforeUnload from "../../../../common/hooks/useBeforeUnload";
import { notificationVariable } from "../../../../common/variables/notification";
import {
  createNodeActiveStepIndexVariable,
  createNodeInitialActiveStepIndex,
  nodeScriptInitialValue,
  nodeScriptVariable,
  useNodeScript,
} from "../../variables/createNode.variable";
import {
  defaultNodeFormValidationState,
  nodeFormValidationStateVariable,
} from "../../variables/nodes";
import SimplePaperWrapper from "../../../../common/components/item/SimplePaperWrapper";

type RunNodeScriptStep = {
  onBoardScript?: boolean;
  closeDialog?: () => void;
};

const RunNodeScriptStep = ({
  onBoardScript,
  closeDialog,
}: RunNodeScriptStep): JSX.Element => {
  const navigate = useNavigate();
  const confirm = useConfirm();

  const nodeScript = useNodeScript();

  useBeforeUnload();

  const instructions = [
    "Copy the code snippet below to your clipboard",
    "Paste the code snippet into a terminal running on the node device's terminal",
    "Run the code",
  ];

  const nextStepDialog = (): void => {
    confirm({
      title: "Make sure you run the node code before moving to the next step",
      confirmationText: "Sure, I've run it",
      cancellationText: "Back to node code",
      content:
        "If you leave this page you won’t be able to run the node code anymore",
      ...confirmDialogStyleOptions,
      confirmationButtonProps: {
        ...confirmDialogStyleOptions.confirmationButtonProps,
        color: "primary",
      },
    }).then(async (): Promise<void> => {
      if (closeDialog) {
        resetState();

        closeDialog();
      }
    });
  };

  const resetState = (): void => {
    nodeScriptVariable(nodeScriptInitialValue);

    nodeFormValidationStateVariable(defaultNodeFormValidationState);

    createNodeActiveStepIndexVariable(createNodeInitialActiveStepIndex);
  };

  const handleFinish = (): void => {
    if (onBoardScript && closeDialog) {
      nextStepDialog();

      return;
    }

    resetState();

    navigate("/nodes");
  };

  const notify = (): void => {
    navigator.clipboard
      .writeText(nodeScript)
      .then((): void => {
        notificationVariable({
          isOpen: true,
          severity: "success",
          message: "Script copied to clipboard!",
        });
      })
      .catch((error): void => console.error(error));
  };

  return (
    <Box>
      <Typography
        sx={{
          marginBottom: "1em",
        }}
        variant="h3"
      >
        Node is created. Run the node code
      </Typography>

      <SimplePaperWrapper sx={{ padding: "2em" }}>
        <Box sx={{ marginBottom: "1.5em" }}>
          <WarningBox>
            The link below can be copied only once. If you leave this page, you
            won’t be able to return to this code
          </WarningBox>
        </Box>

        <InfoText>
          The code provisioning can take some time. To begin the process of
          provisioning your node, follow these steps:
        </InfoText>

        <InstructionWrapperBox
          nodeScript={nodeScript}
          instructions={instructions}
          notify={notify}
        />
      </SimplePaperWrapper>

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "1em",
          marginTop: "2em",
        }}
      >
        <Button variant="outlined" color="secondary" onClick={handleFinish}>
          Finish
        </Button>
      </Box>
    </Box>
  );
};

export default RunNodeScriptStep;
