import { Box } from "@mui/material";

import DeploymentHealthPageHeader from "./components/DeploymentHealthPageHeader";
import DeploymentHealthTable from "./components/DeploymentHealthTable";

const DeploymentHealthPage = (): JSX.Element => {
  return (
    <Box>
      <DeploymentHealthPageHeader />
      <DeploymentHealthTable />
    </Box>
  );
};

export default DeploymentHealthPage;
