import { ApolloError, useMutation } from "@apollo/client";

import {
  DeleteZoneInput,
  DeleteZoneMutation,
  DeleteZoneMutationVariables,
} from "../../../../API";
import { DELETE_ZONE } from "../../../../common/operations/mutations";
import {
  errorNotification,
  successNotification,
} from "../../../../common/variables/notification";
import { useSyncShadow } from "../../../model-manager/hooks/useSyncShadow";

type DeleteZoneInputWithName = DeleteZoneInput & {
  name: string;
};

export const useDeleteZone = () => {
  const { syncShadowsForNode } = useSyncShadow();

  const [deleteZoneMutation, { data, loading }] = useMutation<
    DeleteZoneMutation,
    DeleteZoneMutationVariables
  >(DELETE_ZONE);
  const deleteZone = async ({
    deviceId,
    zoneId,
    serviceId,
    name,
  }: DeleteZoneInputWithName) => {
    return await deleteZoneMutation({
      variables: {
        input: {
          deviceId,
          zoneId,
          serviceId,
        },
      },
      onCompleted: response => {
        if (response.deleteZone) {
          successNotification(`${name} is deleted`);

          syncShadowsForNode(response.deleteZone.nodeId);
        }
      },
      onError: (error: ApolloError): void => {
        errorNotification(error.message);

        console.error(error.message);
      },
      update(cache) {
        const id = `Zone:${deviceId}#${zoneId}`;

        cache.evict({ id });

        cache.gc();
      },
    });
  };

  return { deleteZone, data, loading };
};
