import { useEffect } from "react";

import { NetworkStatus, useLazyQuery } from "@apollo/client";

import {
  GetServicesByLocationQuery,
  GetServicesByLocationQueryVariables,
} from "../../API";
import client from "../../configs/apolloClient";
import { GET_SERVICES_BY_LOCATION } from "../operations/queries";
import { errorNotification } from "../variables/notification";

export const useGetServicesByLocation = (selectedLocation?: string) => {
  useEffect(() => {
    if (selectedLocation) {
      fetchServices(selectedLocation);
    }
  }, [selectedLocation]);

  const handleRequestError = async (): Promise<void> => {
    await client.cache.reset();

    errorNotification();
  };

  const [getServices, { data, loading, refetch, networkStatus }] = useLazyQuery<
    GetServicesByLocationQuery,
    GetServicesByLocationQueryVariables
  >(GET_SERVICES_BY_LOCATION, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  const fetchServices = (locationId: string, nextToken?: string): void => {
    getServices({
      variables: {
        locationId,
        nextToken,
      },
    })
      .then(async (response): Promise<void> => {
        if (response.data?.getServicesByLocation.nextToken) {
          fetchServices(
            locationId,
            response.data?.getServicesByLocation.nextToken
          );
        }

        if (response.error) {
          await handleRequestError();
        }
      })
      .catch(async (): Promise<void> => {
        await handleRequestError();
      });
  };

  const fetchServicesByLocationId = async (
    locationId: string,
    nextToken?: string | null
  ): Promise<GetServicesByLocationQuery | undefined> => {
    try {
      let allResults: GetServicesByLocationQuery | undefined;
      let nextPageToken: string | null | undefined = nextToken;

      while (nextPageToken !== null) {
        const response = await refetch({
          locationId,
          nextToken: nextPageToken,
        });

        if (response.error) {
          await handleRequestError();

          return undefined;
        }

        if (response.data) {
          if (!allResults) {
            allResults = response.data;
          } else {
            // Append the new data to the existing results
            allResults.getServicesByLocation.items.push(
              ...response.data.getServicesByLocation.items
            );
          }

          nextPageToken = response.data.getServicesByLocation.nextToken ?? null;
        } else {
          nextPageToken = null;
        }
      }

      return allResults;
    } catch (error) {
      await handleRequestError();

      return undefined;
    }
  };

  return {
    data,
    loading: loading || networkStatus === NetworkStatus.refetch,
    refetchLoading: networkStatus === NetworkStatus.refetch,
    fetchServicesByLocationId,
  };
};
