import { ReactNode } from "react";

import { Typography } from "@mui/material";

const TypographyHeader5 = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  return <Typography variant="h5">{children}</Typography>;
};

export default TypographyHeader5;
