import { ReactElement, ReactNode, useMemo } from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Breakpoint, styled } from "@mui/material/styles";

import StyledLoadingButton from "../../providers/theme/design-tokens/LoadingButton/StyledLoadingButton";
import StyledDialogTitle from "./StyledDialogTitle";
import { Stack } from "@mui/material";
import ChipFilledWarning from "../chip/ChipFilledWarning";

interface IStyledDialog {
  open: boolean;
  title?: string;
  label?: string;
  submitButtonTitle?: string;
  onClose: () => void;
  fullScreen?: boolean;
  maxWidth?: Breakpoint | false;
  content?: ReactNode;
  children?: ReactNode;
  showSubmitButton?: boolean;
  disableEscapeKeyDown?: boolean;
  SubmitButtonProps?: {
    loading?: boolean;
    title?: string;
    icon?: ReactElement | JSX.Element;
    onSubmit?: () => void;
  };
}

const DialogWindow = styled(Dialog)(
  ({
    theme,
  }): {
    "& .MuiDialog-paper": {
      padding: string | number;
      borderRadius: string | number;
    };
    "& .MuiDialogContent-root": {
      padding: string | number;
      overflowY: string;
    };
    "& .MuiDialogActions-root": {
      padding: string | number;
    };
  } => ({
    "& .MuiDialog-paper": {
      padding: "48px 64px",
      borderRadius: "12px",
    },
    "& .MuiDialogContent-root": {
      padding: theme.spacing(0),
      overflowY: "initial",
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(3, 0, 0, 0),
    },
  })
);

const StyledDialogNew = ({
  open,
  onClose,
  title,
  label,
  submitButtonTitle,
  content,
  children,
  SubmitButtonProps,
  disableEscapeKeyDown,
  fullScreen = false,
  showSubmitButton = true,
  maxWidth = "sm",
}: IStyledDialog): JSX.Element => {
  const loading = SubmitButtonProps?.loading ?? false;
  const onSubmit = SubmitButtonProps?.onSubmit;

  const memoizedContent = useMemo(
    (): ReactNode => content ?? children,
    [content, children]
  );

  return (
    <DialogWindow
      onClose={onClose}
      open={open}
      maxWidth={maxWidth}
      fullScreen={fullScreen}
      fullWidth
      scroll="body"
      disableEscapeKeyDown={disableEscapeKeyDown}
    >
      {title && (
        <StyledDialogTitle onClose={onClose}>
          <Stack direction="column" justifyContent="center" alignItems="center">
            {title}
            {label && <ChipFilledWarning label={label} />}
          </Stack>
        </StyledDialogTitle>
      )}

      <DialogContent
        sx={{
          height: "100%",
        }}
      >
        {memoizedContent}
      </DialogContent>

      {showSubmitButton && (
        <Stack direction="row" justifyContent="center" alignItems="center">
          <DialogActions>
            <StyledLoadingButton
              loading={loading}
              loadingPosition="center"
              variant="outlined"
              color="secondary"
              onClick={onClose}
            >
              {"Cancel"}
            </StyledLoadingButton>
            <StyledLoadingButton
              loading={loading}
              loadingPosition="center"
              variant="contained"
              color="primary"
              onClick={onSubmit}
            >
              {submitButtonTitle}
            </StyledLoadingButton>
          </DialogActions>
        </Stack>
      )}
    </DialogWindow>
  );
};

export default StyledDialogNew;
