import { useEffect } from "react";

import { useLazyQuery } from "@apollo/client";

import {
  GetAllAlertsQuery,
  GetAllAlertsQueryVariables,
} from "../../../../../API";
import { useCustomerIdGuard } from "../../../../../common/hooks/useCustomerIdGuard";
import { GET_ALL_ALERTS } from "../../../../../common/operations/queries";
import { errorNotification } from "../../../../../common/variables/notification";
import client from "../../../../../configs/apolloClient";
import { DEFAULT_LIMIT_COUNT } from "../../../../../common/variables/common";

export const ALERTS_TABLE_LIMIT = 10;

export const useGetLazyPaginatedAlerts = () => {
  const selectedCustomerId = useCustomerIdGuard();

  useEffect(() => {
    if (selectedCustomerId) {
      client.cache.reset();

      fetchAllAlerts(selectedCustomerId);
    }
  }, [selectedCustomerId]);

  const [getAlerts, { data, loading }] = useLazyQuery<
    GetAllAlertsQuery,
    GetAllAlertsQueryVariables
  >(GET_ALL_ALERTS, {
    fetchPolicy: "network-only",
  });

  const handleRequestError = async () => {
    await client.cache.reset();

    errorNotification();
  };

  const fetchAllAlerts = (
    customerId: string,
    limit?: number,
    nextToken?: string
  ) => {
    getAlerts({
      variables: {
        customerId,
        limit: DEFAULT_LIMIT_COUNT,
        nextToken,
      },
    })
      .then(async response => {
        if (response.data?.getAllAlerts.nextToken) {
          fetchAllAlerts(
            customerId,
            limit,
            response.data?.getAllAlerts.nextToken
          );
        }

        if (response.error) {
          await handleRequestError();
        }
      })
      .catch(async () => {
        await handleRequestError();
      });
  };

  return { data, loading };
};
