import { useMemo, useState } from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Button, Skeleton, Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Dictionary, List } from "lodash";
import groupBy from "lodash/groupBy";
import isEmpty from "lodash/isEmpty";

import { Service } from "../../../API";
import { useGetServicesByCustomer } from "../../../common/components/select/ServiceSelect/useGetServicesByCustomer";
import TableNoDataOverlay from "../../../common/components/table/TableNoDataOverlay";
import useLocationFromCache from "../../../common/hooks/useLocationFromCache";
import { AutocompleteOptionType } from "../../../common/models/autocomplete";
import {
  errorNotification,
  successNotification,
} from "../../../common/variables/notification";
import { usePublishNode } from "../../devices/hooks/usePublishNode";
import { useSyncShadow } from "../hooks/useSyncShadow";
import {
  useSelectedLocationAIManager,
  useSelectedTagLocationAIManager,
} from "../variables/modelManager";
import DeleteServiceDialogContainer from "./DeleteServiceDialogContainer";
import UpdateServiceDialogContainer from "./UpdateServiceDialogContainer";

interface IModelManagerRowProps {
  locationId: string;
  locationName: string;
  models: Array<Service>;
}

const Row = (props: { row: IModelManagerRowProps }): JSX.Element => {
  const [open, setOpen] = useState(true);
  const { publishNode } = usePublishNode();
  const { syncShadowsForNode } = useSyncShadow();

  const { row } = props;

  const deployService = (model: any): void => {
    // TODO: currently only deploys based on serviceType assuming only one serviceType/modelType per NA
    publishNode({
      message: JSON.stringify({
        TARGET: "MODEL",
        ACTION: "START",
        model_name: model.serviceType, // Ex: "Gas Leak"
        instance_name: model.deviceId.split("#DE#").at(-1), // Ex: "gmailDevice1"
        DATA: {
          serviceType: model.serviceType,
        },
      }),
      nodeId: model.nodeId,
    })
      .then((response): void => {
        if (response.data) {
          successNotification();
        }
      })
      .catch((error): void => {
        errorNotification("Something went wrong when publishing node");

        console.error(error);
      });
  };

  const syncShadow = (model: any): void => {
    const nodeId = model.nodeId;

    syncShadowsForNode(nodeId);

    successNotification("Changes applied");
  };

  return (
    <>
      <TableRow
        onClick={(): void => setOpen(!open)}
        sx={{
          cursor: "pointer",
        }}
      >
        <TableCell component="th">
          <Typography variant="body1">{row.locationName}</Typography>
        </TableCell>
        <TableCell component="th" scope="row" sx={{ display: "flex" }}>
          <IconButton
            aria-label="expand row"
            size="small"
            sx={{
              marginLeft: "auto",
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1.25 }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell component="th">
                      <Typography>Type</Typography>
                    </TableCell>
                    <TableCell component="th">
                      <Typography>Node</Typography>
                    </TableCell>
                    <TableCell component="th">
                      <Typography />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.models.map((model): JSX.Element => {
                    const node = model.nodeId?.split("#N#").at(-1);

                    return (
                      <TableRow key={model.id}>
                        <TableCell>
                          <Typography variant="body2">
                            {model.serviceType}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">{node}</Typography>
                        </TableCell>
                        <TableCell>
                          <Stack direction="row" spacing={1}>
                            <UpdateServiceDialogContainer
                              nodeId={model.nodeId ?? ""}
                              // todo: modify service schema type to more easily return the serviceId
                              serviceId={`S#${model?.id?.split("S#")[1] ?? ""}`}
                            />
                            <DeleteServiceDialogContainer
                              deviceId={model.deviceId ?? ""}
                              nodeId={model.nodeId ?? ""}
                              // todo: modify service schema type to more easily return the serviceId
                              serviceId={`S#${model?.id?.split("S#")[1] ?? ""}`}
                              serviceType={model.serviceType ?? ""}
                            />
                            <Button
                              variant="contained"
                              onClick={(): void => deployService(model)}
                            >
                              Deploy
                            </Button>
                            <Button
                              variant="contained"
                              onClick={(): void => syncShadow(model)}
                            >
                              Apply Changes
                            </Button>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const ModelManagerTable = (): JSX.Element => {
  const { data, loading } = useGetServicesByCustomer();

  const locationVariable = useSelectedLocationAIManager();

  const tagLocationsVariable = useSelectedTagLocationAIManager();

  const { getCachedLocation } = useLocationFromCache();

  const memoizedData = useMemo((): Dictionary<Service[]> | null => {
    if (isEmpty(data?.getServices.items)) {
      return null;
    }

    let items = data?.getServices.items;

    if (locationVariable?.value) {
      items = items?.filter(
        (item): boolean => item?.locationId === locationVariable.value
      );
    }

    if (tagLocationsVariable?.length) {
      items = items?.filter((item): boolean => {
        const condition = tagLocationsVariable.some(
          (tag: AutocompleteOptionType) => item?.locationId === tag?.value
        );

        return condition;
      });
    }

    if (
      !locationVariable?.value &&
      tagLocationsVariable &&
      tagLocationsVariable.length === 0
    ) {
      items = [];
    }

    if (!locationVariable?.value && !tagLocationsVariable) {
      items = data?.getServices.items;
    }

    return groupBy<Service>(
      items as List<Service>,
      (x): string | null | undefined => x?.locationId
    );
  }, [data?.getServices.items, locationVariable?.value, tagLocationsVariable]);

  return (
    <Paper>
      <Table aria-label="collapsible table">
        <TableBody>
          {memoizedData &&
            !loading &&
            Object.entries(memoizedData).map((row): JSX.Element => {
              const [locationId, items] = row;

              const rowItem: IModelManagerRowProps = {
                locationId,
                locationName: getCachedLocation(locationId)?.name ?? "",
                models: items,
              };

              return <Row key={locationId} row={rowItem} />;
            })}

          {loading && (
            <TableRow>
              <TableCell colSpan={5}>
                {[...Array(5)].map(
                  (_, index): JSX.Element => (
                    <Skeleton
                      key={index}
                      variant="rectangular"
                      sx={{ my: 3 }}
                    />
                  )
                )}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {isEmpty(memoizedData) && !loading && <TableNoDataOverlay />}
    </Paper>
  );
};

export default ModelManagerTable;
