import { ApolloError, useMutation } from "@apollo/client";

import { DeleteTagMutation, DeleteTagMutationVariables } from "../../../../API";
import { DELETE_TAG } from "../../../../common/operations/mutations";
import { useCustomerIdGuard } from "../../../../common/hooks/useCustomerIdGuard";
import {
  errorNotification,
  successNotification,
} from "../../../../common/variables/notification";

export const useDeleteTag = () => {
  const customerId = useCustomerIdGuard();

  const [deleteTagMutation, { data, loading }] = useMutation<
    DeleteTagMutation,
    DeleteTagMutationVariables
  >(DELETE_TAG);

  const deleteTag = async (tagId: string, tagName: string) => {
    return await deleteTagMutation({
      variables: {
        input: { tagId, customerId },
      },
      onCompleted: response => {
        if (response.deleteTag) {
          successNotification(`${tagName} tag is deleted`);
        }
      },
      onError: (error: ApolloError): void => {
        errorNotification(error.message);

        console.error(error.message);
      },
      update(cache) {
        const normalizedId = cache.identify({ id: tagId, __typename: "Tag" });

        cache.evict({ id: normalizedId });

        cache.gc();
      },
    });
  };

  return { deleteTag, data, loading };
};
