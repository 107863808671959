import { useEffect } from "react";

import { SubmitHandler, useForm } from "react-hook-form";

import StyledDialogNew from "../../../../../../../common/components/dialog/StyledDialogNew";
import { useDialog } from "../../../../../../../common/components/dialog/useDialog";
import EditIconButtonNoBorder from "../../../../../../../common/components/icons/EditIconButtonNoBorder";
import FullPageLoader from "../../../../../../../common/components/item/FullPageLoader";
import {
  errorNotification,
  successNotification,
} from "../../../../../../../common/variables/notification";
import { useGetZoneThresholdById } from "../hooks/useGetZoneThresholdById";
import { useUpdateZoneThresholdItem } from "../hooks/useUpdateZoneThresholdItem";
import { IZoneThresholdForm } from "../variables/zoneThresholdData";
import EditZoneThresholdForm from "./EditZoneThresholdForm";

interface EditZoneThresholdDialogPros {
  thresholdId: string;
}

const EditZoneThresholdDialog = ({
  thresholdId,
}: EditZoneThresholdDialogPros) => {
  const defaultValues = {
    name: "",
    value: 0,
  };

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IZoneThresholdForm>({ defaultValues });

  const { open, handleDialogOpen, handleDialogClose } = useDialog();

  const { updateZoneThresholdItem, loading: updateZoneThresholdItemLoading } =
    useUpdateZoneThresholdItem();

  const { data, loading: getZoneThresholdLoading } =
    useGetZoneThresholdById(thresholdId);

  useEffect(() => {
    if (data) {
      const name = data.getZoneThresholdById?.name ?? "";
      const value = data.getZoneThresholdById?.value ?? 0;

      setValue("name", name);

      setValue("value", value);
    }
  }, [data?.getZoneThresholdById]);

  const openDialog = (): void => {
    handleDialogOpen();
  };

  const closeDialog = (): void => {
    handleDialogClose();
  };

  const onSubmit: SubmitHandler<IZoneThresholdForm> = formdata => {
    if (!data) return;

    if (errors.name || errors.value) {
      return;
    }

    const zoneThreshold = data.getZoneThresholdById;

    updateZoneThresholdItem(
      formdata.name,
      formdata.value,
      zoneThreshold?.id ?? ""
    )
      .then((result): void => {
        if (result.errors) {
          errorNotification("Something went wrong, changes aren’t saved");

          console.error(result.errors);

          return;
        }

        successNotification(`${formdata.name} is updated!`);

        closeDialog();
      })
      .catch((error: Error): void => {
        errorNotification(error.message);

        console.error(error);
      });
  };

  const loading = getZoneThresholdLoading || updateZoneThresholdItemLoading;

  if (loading) {
    return <FullPageLoader />;
  }

  return (
    <>
      <EditIconButtonNoBorder onClick={openDialog} />

      <StyledDialogNew
        open={open}
        title="Edit zone type"
        submitButtonTitle="Save changes"
        onClose={closeDialog}
        SubmitButtonProps={{
          loading,
          onSubmit: handleSubmit(onSubmit),
        }}
      >
        <EditZoneThresholdForm control={control} errors={errors} />
      </StyledDialogNew>
    </>
  );
};

export default EditZoneThresholdDialog;
