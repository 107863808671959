import { Paper } from "@mui/material";
import { styled } from "@mui/material/styles";

const DataHubGridItem = styled(Paper)((): any => ({
  height: "80px",
  padding: "1.5em",
  cursor: "pointer",
  display: "flex",
  gap: "1em",
  alignItems: "center",
  flexWrap: "wrap",
}));

export default DataHubGridItem;
