import { Navigate } from "react-router-dom";

// import { AllowLeakFinder } from "../API";
import FullPageLoader from "../common/components/item/FullPageLoader";
import { useAuthenticatedUser } from "../common/hooks/useAuthenticatedUser";
import { useGetCustomer } from "../common/hooks/useGetCustomer";
import { ROLES } from "../common/models/enums";

interface IAppRoutesProps {
  allowedRoles: string[];
  children: JSX.Element;
}

const LeakFinderRoleBasedRoute = ({
  allowedRoles,
  children,
}: IAppRoutesProps): JSX.Element => {
  const { user, authStatus, role } = useAuthenticatedUser();

  const { loading } = useGetCustomer();

  if (loading) {
    return <FullPageLoader />;
  }

  // if (customer && customer.allowLeakFinder === AllowLeakFinder.NONE) {
  //   return <Navigate to="/unauthorized" replace />;
  // } // DON'T FORGET

  if (!user || authStatus === "configuring") {
    return <FullPageLoader />;
  }

  const userRole = ROLES.find((r): boolean => r === role) ?? "";

  const allowed = allowedRoles?.includes(userRole);

  return allowed ? <>{children}</> : <Navigate to="/unauthorized" replace />;
};

export default LeakFinderRoleBasedRoute;
