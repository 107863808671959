import { Box } from "@mui/material";

import AllLocationsEventsFilterDropdownContainer from "../../../../../../common/components/locations/recent-events/AllLocationsEventsFilterDropdownContainer";
import { AutocompleteOptionType } from "../../../../../../common/models/autocomplete";
import {
  selectedDataHubLocationTagVariable,
  selectedDataHubLocationVariable,
  useSelectedDataHubLocation,
} from "../../../dataHub.variable";

const LocationSearch = (): JSX.Element => {
  const locationVariable = useSelectedDataHubLocation();

  const handleLocationChange = async (
    locationValue: AutocompleteOptionType
  ): Promise<void> => {
    if (locationValue?.value !== locationVariable?.value) {
      selectedDataHubLocationVariable(locationValue);
    }
  };

  const handleTagLocationsChange = async (
    tagLocations: AutocompleteOptionType[] | null
  ): Promise<void> => {
    selectedDataHubLocationTagVariable(tagLocations);
  };

  return (
    <>
      <Box
        sx={{
          maxWidth: "320px",
          marginBottom: "1em",
          marginLeft: "auto",
        }}
      >
        <AllLocationsEventsFilterDropdownContainer
          ignoreDefaultValue
          setLocation={handleLocationChange}
          setTagLocations={handleTagLocationsChange}
        />
      </Box>
    </>
  );
};

export default LocationSearch;
