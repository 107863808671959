import { useMutation } from "@apollo/client";

import type {
  addAlertEmailNotificationInput,
  AddAlertEmailNotificationMutation,
  AddAlertEmailNotificationMutationVariables,
} from "../../../../../API";
import { ADD_ALERT_EMAIL_NOTIFICATION } from "../../../../../common/operations/mutations";

export const useCreateAlertEmailNotification = () => {
  const [saveAlertEmailNotification, { data, loading }] = useMutation<
    AddAlertEmailNotificationMutation,
    AddAlertEmailNotificationMutationVariables
  >(ADD_ALERT_EMAIL_NOTIFICATION);

  const createAlertEmailNotification = async (
    input: addAlertEmailNotificationInput
  ) => {
    return await saveAlertEmailNotification({
      variables: {
        input,
      },
      refetchQueries: ["GetAllAlerts", "GetUserAlerts"],
    });
  };

  return { createAlertEmailNotification, data, loading };
};
