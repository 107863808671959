import {
  Box,
  ImageListItem,
  ImageListItemBar,
  Paper,
  Theme,
} from "@mui/material";

import { Device } from "../../../../../../API";
import S3Image from "../../../../../../common/components/media/S3Image";
import DeviceImageListItemBarTitle from "./DeviceImageListItemBarTitle";
import { useLazyGetPaginatedLocations } from "../../../../../customer-settings/tabs/locations/hooks/useLazyGetPaginatedLocations";

interface IDeviceImageListItemProps {
  device: Device;
}

const DeviceImageListItem = ({
  device,
}: IDeviceImageListItemProps): JSX.Element => {
  const { locations } = useLazyGetPaginatedLocations("cache-only");

  const location = device.locationId;
  const locationFromQuery = locations.find(l => l.id === location);

  return (
    <ImageListItem key={device.id}>
      <Paper
        elevation={0}
        sx={{
          borderRadius: "12px",
          border: (theme: Theme): string =>
            `1px solid ${theme.palette.divider}`,
          width: "100%",
          height: "100%",
          padding: "0.5em",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "220px",
            borderRadius: "8px",
            border: (theme): string =>
              `1px solid ${theme.palette.otherOutlineBorder.main}`,
          }}
        >
          <S3Image
            sx={{
              width: "100%",
              height: device.deviceData.recentFrame ? "100%" : "220px",
              maxHeight: "220px",
              borderRadius: "8px",
            }}
            s3Key={device.deviceData.recentFrame}
          />
        </Box>
        <ImageListItemBar
          title={
            <DeviceImageListItemBarTitle
              deviceName={device.name}
              deviceId={device.id}
              nodeId={device.nodeId}
              locationName={(locationFromQuery?.name as string) ?? "-"}
              deviceData={device.deviceData}
            />
          }
          position="below"
          sx={{
            padding: "0 0.5em",
          }}
        />
      </Paper>
    </ImageListItem>
  );
};

export default DeviceImageListItem;
