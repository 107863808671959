import Typography from "@mui/material/Typography";

import MainPaperWrapper from "../../../../../common/components/item/MainPaperWrapper";

const NoAlertsFound = () => {
  return (
    <MainPaperWrapper withBorder={false}>
      <Typography
        variant="body2Regular"
        color={theme => theme.palette.otherTextTertiary.main}
      >
        No alerts are found
      </Typography>
    </MainPaperWrapper>
  );
};

export default NoAlertsFound;
