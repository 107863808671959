import { useTheme } from "@mui/material";

const SearchIconSmall = () => {
  const theme = useTheme();

  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_11060_78151)">
        <circle
          cx="5.75"
          cy="5.75"
          r="4.75"
          stroke={theme.palette.otherTextTertiary.main}
          strokeWidth="1.5"
        />
        <path
          d="M9.25 9.25L11 11"
          stroke={theme.palette.otherTextTertiary.main}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_11060_78151">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SearchIconSmall;
