import { SxProps, Theme } from "@mui/material";
import { CSSObject, styled } from "@mui/material/styles";
import Chart from "react-apexcharts";

export const apexChartsTooltipStyles: SxProps<Theme> = {
  ".apexcharts-tooltip": {
    padding: "1em",
    borderRadius: "12px",
    border: (theme): string =>
      `1px solid ${theme.palette.otherOutlineBorder.main}`,
    boxShadow: "0px 4px 19px 0px rgba(7, 22, 39, 0.10)",
    backgroundColor: (theme): string => theme.palette.otherBackgroundLight.main,

    "& .tooltip-date": {
      color: (theme): string => theme.palette.text.secondary,
      fontWeight: 400,
      fontSize: "0.75em",
      textAlign: "center",
    },

    "& .apexcharts-tooltip-series-group": {
      padding: 0,
      paddingBottom: "1em",

      "& .apexcharts-tooltip-y-group span": {
        color: (theme): string => theme.palette.text.primary,
        fontWeight: 700,
      },
    },
    "& hr": {
      width: "100%",
      border: (theme): string => `1px solid ${theme.palette.otherDivider.main}`,
    },
  },
};

const StyledChart = styled(Chart)(
  ({ theme }): CSSObject => ({
    fontFamily: "Inter",
    "& .apexcharts-xaxis-label": {
      color: theme.palette.text.primary,
    },
    "& .apexcharts-yaxis-label": {
      color: theme.palette.text.primary,
    },
    "& .apexcharts-legend.apexcharts-align-left .apexcharts-legend-series": {
      borderRadius: "8px",
      cursor: "pointer",
      padding: "4px 10px",
      border: `1px solid ${theme.palette.text.primary}`,
      display: "flex",
      alignItems: "center",
      "& .apexcharts-legend-marker": {
        borderRadius: "50% !important",
      },
      "& .apexcharts-legend-text": {
        color: `${theme.palette.text.primary} !important`,
        fontSize: "0.75em !important",
      },
      "& .apexcharts-tooltip": {
        padding: "1em !important",
        borderRadius: "12px !important",
        border: `1px solid ${theme.palette.otherOutlineBorder.main}`,
        boxShadow: "0px 4px 19px 0px rgba(7, 22, 39, 0.10)",
        backgroundColor: theme.palette.otherBackgroundLight.main,
      },
    },
  })
);

export default StyledChart;
