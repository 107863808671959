function convertToLocalTime(utcTimeStr: string) {
  const [hours, minutes] = utcTimeStr.split(":").map(Number);

  const now = new Date();

  const utcDate = new Date(
    Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), hours, minutes)
  );

  const localHours = utcDate.getHours().toString().padStart(2, "0");
  const localMinutes = utcDate.getMinutes().toString().padStart(2, "0");

  return `${localHours}:${localMinutes}`;
}

export { convertToLocalTime };
