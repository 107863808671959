import { useMemo } from "react";

import moment from "moment";

import { GridRowsProp } from "@mui/x-data-grid";
import { EVENTS_DATE_FORMAT } from "../../common/events.constants";
import {
  IParsedTanksData,
  IParsedTlmEvent,
  ITanksData,
  ITlEventsTableRow,
} from "../../common/events.models";
import { useGetTimestreamDataServer } from "../../common/hooks/useGetTimestreamDataServer";
import { getRowIndex } from "../../common/events.helpers";

export const useGetTlmTableRows = () => {
  const {
    parsedResult,
    loading,
    paginationModel,
    handlePaginationModelChange,
    hasNextPage,
    isActivePageChange,
  } = useGetTimestreamDataServer();

  const rows = useMemo((): GridRowsProp<ITlEventsTableRow> => {
    const mappedData: ITlEventsTableRow[] =
      parsedResult?.map((item: IParsedTlmEvent, index: number) => {
        let tanksData = "";

        if (item.data) {
          try {
            const tanks: IParsedTanksData[] = JSON.parse(item.data);

            const mappedTanks: ITanksData[] = tanks.map(tank => ({
              TankID: tank.tank_id,
              TankNumber: tank.tank_number,
              TankLevel: tank.tank_level,
              LevelPercent: tank.level_percent,
            }));

            tanksData = JSON.stringify(mappedTanks, null, 2);
          } catch (e) {
            console.error("Error parsing tanks data:", e);
          }
        }

        return {
          rowIndex: getRowIndex(paginationModel, index),
          rowId: item?.id ?? "",
          location: item?.location_id ?? "",
          tanksData: tanksData,
          timestamp: moment.utc(item?.time).local().format(EVENTS_DATE_FORMAT),
          mediaOutput: item?.mediaOutput ?? "",
          keyFrame: item?.keyFrame,
          cameraId: item?.camera_id,
        };
      }) ?? [];

    return mappedData;
  }, [parsedResult, paginationModel]);

  return {
    rows,
    loading,
    paginationModel,
    handlePaginationModelChange,
    hasNextPage,
    isActivePageChange,
  };
};
