import { Grid, TextField, Typography } from "@mui/material";

import {
  ServiceTypeEnum,
  aiManagerVariable,
  useSelectedServiceModel,
} from "../../variables/modelManager";

const GasLeakForm = (): JSX.Element => {
  const values = useSelectedServiceModel();

  console.log("values", values);

  const handleFormChange = (key: string, value: string): void => {
    aiManagerVariable({
      ...values,
      [ServiceTypeEnum.LEAK]: {
        ...values[ServiceTypeEnum.LEAK],
        [key]: value,
      },
    });
  };

  return (
    <>
      <Typography variant="body1" paddingBottom="1em">
        Advanced Settings
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <TextField
            margin="dense"
            required
            fullWidth
            disabled
            id="docker-image"
            label="Docker Image"
            value={
              "534256877503.dkr.ecr.us-east-1.amazonaws.com/ccai-gasleak:latest"
            }
            onChange={(e): void =>
              handleFormChange("docker_image", e.target.value)
            }
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            margin="dense"
            required
            fullWidth
            disabled
            id="min_length_leak"
            label="Min Leak Length"
            value={values[ServiceTypeEnum.LEAK].min_length_leak}
            onChange={(e): void =>
              handleFormChange("min_length_leak", e.target.value)
            }
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            margin="dense"
            required
            fullWidth
            disabled
            id="gpu_id"
            label="GPU ID"
            value={values[ServiceTypeEnum.LEAK].gpu_id}
            onChange={(e): void => handleFormChange("gpu_id", e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            margin="dense"
            required
            fullWidth
            disabled
            id="file_dur"
            label="Video Duration"
            value={values[ServiceTypeEnum.LEAK].file_dur}
            onChange={(e): void => handleFormChange("file_dur", e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            margin="dense"
            required
            fullWidth
            disabled
            id="display_rate"
            label="Display Rate"
            value={values[ServiceTypeEnum.LEAK].display_rate}
            onChange={(e): void =>
              handleFormChange("display_rate", e.target.value)
            }
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            margin="dense"
            required
            fullWidth
            disabled
            id="calc_flow_rate"
            label="Calc Flow Rate"
            value={values[ServiceTypeEnum.LEAK].calc_flow_rate}
            onChange={(e): void =>
              handleFormChange("calc_flow_rate", e.target.value)
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

export default GasLeakForm;
