import { useState } from "react";

import { TimePeriod } from "../../../API";
import { AutocompleteOptionType } from "../../../common/models/autocomplete";

interface LocationsFilterDropdownHook {
  location: AutocompleteOptionType;
  handleLocationChange: (
    locationValue: AutocompleteOptionType,
    activeTimePeriod: TimePeriod,
    refetchChartData: () => void
  ) => void;

  tagLocations: AutocompleteOptionType[] | null;
  handleTagLocationsChange: (
    locations: AutocompleteOptionType[] | null,
    activeTimePeriod: TimePeriod,
    refetchChartData: () => void
  ) => void;
}

export const useLocationsFilterDropdown = (): LocationsFilterDropdownHook => {
  const [location, setLocation] = useState<AutocompleteOptionType>(null);

  const [tagLocations, setTagLocations] = useState<
    AutocompleteOptionType[] | null
  >(null);

  const handleLocationChange = (
    locationValue: AutocompleteOptionType,
    activeTimePeriod: TimePeriod,
    refetchChartData: (
      locations?: string[] | null,
      timePeriod?: TimePeriod
    ) => void
  ): void => {
    if (locationValue?.value !== location?.value) {
      setLocation(locationValue);

      refetchChartData(
        locationValue?.value ? [locationValue?.value] : null,
        activeTimePeriod
      );
    }
  };

  const handleTagLocationsChange = (
    locations: AutocompleteOptionType[] | null,
    activeTimePeriod: TimePeriod,
    refetchChartData: (locations?: string[], timePeriod?: TimePeriod) => void
  ): void => {
    setTagLocations(locations);

    if (!locations) {
      return;
    }

    refetchChartData(
      locations?.map(l => l?.value ?? ""),
      activeTimePeriod
    );
  };

  return {
    location,
    handleLocationChange,
    tagLocations,
    handleTagLocationsChange,
  };
};
