import { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";

import { useLazyQuery } from "@apollo/client";

import {
  GetZonesThresholdsByCustomerQuery,
  GetZonesThresholdsByCustomerQueryVariables,
} from "../../../../../../../API";
import { useCustomerIdGuard } from "../../../../../../../common/hooks/useCustomerIdGuard";
import { GET_ZONES_THRESHOLDS_BY_CUSTOMER } from "../../../../../../../common/operations/queries";
import { errorNotification } from "../../../../../../../common/variables/notification";
import client from "../../../../../../../configs/apolloClient";

export const useGetPaginatedZonesThresholds = () => {
  const route = useLocation();
  const customerIdFromRouteState = route.state?.customerId as string;

  const selectedCustomerId = customerIdFromRouteState ?? useCustomerIdGuard();

  const [getZonesThresholds, { data, loading, fetchMore }] = useLazyQuery<
    GetZonesThresholdsByCustomerQuery,
    GetZonesThresholdsByCustomerQueryVariables
  >(GET_ZONES_THRESHOLDS_BY_CUSTOMER, {
    fetchPolicy: "network-only",
  });

  useEffect((): void => {
    if (selectedCustomerId) {
      fetchZonesThresholds(selectedCustomerId);
    }
  }, [selectedCustomerId]);

  const handleRequestError = async (): Promise<void> => {
    await client.cache.reset();

    errorNotification("Something went wrong, zone types cannot be displayed");
  };

  const fetchZonesThresholds = (customerId: string): void => {
    getZonesThresholds({
      variables: {
        input: {
          customerId,
        },
      },
    })
      .then(async (response): Promise<void> => {
        if (response.data?.getZonesThresholdsByCustomer.nextToken) {
          fetchMore({
            variables: {
              input: {
                customerId,
                nextToken: response.data.getZonesThresholdsByCustomer.nextToken,
              },
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              if (!fetchMoreResult) {
                return prev;
              }

              return {
                getZonesThresholdsByCustomer: {
                  ...fetchMoreResult.getZonesThresholdsByCustomer,
                  items: [
                    ...prev.getZonesThresholdsByCustomer.items,
                    ...fetchMoreResult.getZonesThresholdsByCustomer.items,
                  ],
                },
              };
            },
          });
        }

        if (response.error) {
          await handleRequestError();
        }
      })
      .catch(async (): Promise<void> => {
        await handleRequestError();
      });
  };

  const zonesThresholds = useMemo(
    () => data?.getZonesThresholdsByCustomer,
    [data?.getZonesThresholdsByCustomer]
  );

  return { zonesThresholds, loading };
};
