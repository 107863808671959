import { useEffect } from "react";
import { createPortal } from "react-dom";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Box, Grid, IconButton, Typography, useTheme } from "@mui/material";

import RouterLink from "../../../../common/components/RouterLink";
import { useGetNonAdminAccess } from "../../../../common/hooks/useGetNonAdminAccess";
import { RouteEnum } from "../../../../common/models/enums";
import {
  selectedStreamObjectVariable,
  useSelectedStreamObject,
} from "../../../../common/variables/selectedStreamObject";
import { usePublishNode } from "../../../devices/hooks/usePublishNode";
import DevicesImageList from "./components/devices/DevicesImageList";
import LocationSearch from "./components/locations/LocationSearch";

const DataHubLiveViewPageContent = (): JSX.Element => {
  const element = document.getElementById("back-button");
  const theme = useTheme();

  const { nonAdminView } = useGetNonAdminAccess();

  const { publishNode } = usePublishNode();
  const selectedStreamObject = useSelectedStreamObject();

  useEffect((): void => {
    if (selectedStreamObject) {
      const { streamName, deviceName, nodeId } = selectedStreamObject;

      publishNode({
        message: JSON.stringify({
          TARGET: "KINESIS_STREAM",
          ACTION: "KINESIS_STREAM_END",
          streamName,
          deviceName,
        }),
        nodeId,
      });

      selectedStreamObjectVariable(null);
    }
  }, []);

  return (
    <>
      {element &&
        createPortal(
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1em",
            }}
          >
            <RouterLink
              to={
                nonAdminView
                  ? RouteEnum.SensoryRecentActivities
                  : RouteEnum.RecentActivities
              }
            >
              <IconButton
                sx={{
                  color: theme.palette.text.primary,
                  cursor: "pointer",
                }}
              >
                <ChevronLeftIcon />
              </IconButton>
            </RouterLink>
            <Typography variant="h5">Recent activities</Typography>
          </Box>,
          element as HTMLElement
        )}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box>
            <LocationSearch />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <DevicesImageList />
        </Grid>
      </Grid>
    </>
  );
};

export default DataHubLiveViewPageContent;
