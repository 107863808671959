import Typography from "@mui/material/Typography";

import RouterLink from "../../../../common/components/RouterLink";
import { RouteEnum } from "../../../../common/models/enums";
import GasLeakDataHubIcon from "../icons/GasLeakDataHubIcon";
import DataHubGridItem from "./DataHubGridItem";

export interface IModelGridItemProps {
  nonAdmin: boolean;
}

const GasLeakGridItem = ({ nonAdmin }: IModelGridItemProps): JSX.Element => {
  return (
    <RouterLink to={nonAdmin ? RouteEnum.SensoryGasLeak : RouteEnum.GasLeak}>
      <DataHubGridItem>
        <GasLeakDataHubIcon />
        <Typography variant="body2Bold">Gas Leak</Typography>
      </DataHubGridItem>
    </RouterLink>
  );
};

export default GasLeakGridItem;
