import { Box, Stack, Typography } from "@mui/material";

import CreateDeviceDialogContainer from "./CreateDeviceDialogContainer";

const DevicesPageHeader = (): JSX.Element => {
  return (
    <Box padding="0" marginBottom="1em">
      <Stack spacing={4} direction="row" alignItems="center">
        <Typography variant="h3" padding={0} sx={{ flexGrow: 1 }}>
          Devices
        </Typography>
        <CreateDeviceDialogContainer />
      </Stack>
    </Box>
  );
};

export default DevicesPageHeader;
