import Box from "@mui/material/Box";

import { Category } from "../../../../../../API";
import FullPageLoader from "../../../../../../common/components/item/FullPageLoader";
import { useCustomerIdGuard } from "../../../../../../common/hooks/useCustomerIdGuard";
import { useGetPaginatedLicensePlates } from "../../hooks/useGetPaginatedLicensePlates";
import PlateCategoryCard from "./PlateCategoryCard";
import { useMemo } from "react";
import EmptyPlatesCard from "./EmptyPlatesCards";
import isEmpty from "lodash/isEmpty";

const PlateCategoryCards = () => {
  const selectedCustomerId = useCustomerIdGuard();

  const { licensePlates, categories, searchValue, loading } =
    useGetPaginatedLicensePlates();

  const unknownCategoryMemoized = useMemo(() => {
    return {
      id: `${selectedCustomerId}#CG#UNKNOWN`,
      categoryName: "Unknown",
      isAlertEnabled: true,
      __typename: "Category",
    };
  }, [selectedCustomerId]);

  if (loading) {
    return <FullPageLoader />;
  }

  // if no licensePlates render emptyState card
  if (isEmpty(licensePlates)) {
    return <EmptyPlatesCard />;
  }

  const filteredCategories = Object.keys(licensePlates);

  return (
    <>
      {Object.entries(licensePlates).map(([categoryName, licenses]) => {
        const category =
          (categories.items?.find(
            (category): boolean => category.categoryName === categoryName
          ) as Category) ?? unknownCategoryMemoized;

        const opened =
          filteredCategories?.includes(categoryName) && searchValue !== "";

        return (
          <Box key={category.id} sx={{ margin: ".5em 0" }}>
            <PlateCategoryCard
              category={category}
              data={licenses}
              opened={opened}
              isUnknownCategory={categoryName === "UNKNOWN"}
            />
          </Box>
        );
      })}
    </>
  );
};

export default PlateCategoryCards;
