import { FC, ReactNode } from "react";

import Typography from "@mui/material/Typography";

type InfoTextProps = {
  children: ReactNode;
};

const InfoText: FC<InfoTextProps> = ({ children }): JSX.Element => {
  return (
    <Typography
      variant="body2Regular"
      sx={{
        color: "text.secondary",
        display: "inline-block",
        marginBottom: "1.5em",
      }}
    >
      {children}
    </Typography>
  );
};

export default InfoText;
