import { ApolloError, useMutation } from "@apollo/client";

import {
  DeleteLocationMutation,
  DeleteLocationMutationVariables,
} from "../../../../../API";
import { useCustomerIdGuard } from "../../../../../common/hooks/useCustomerIdGuard";
import { DELETE_LOCATION } from "../../../../../common/operations/mutations";
import {
  errorNotification,
  successNotification,
} from "../../../../../common/variables/notification";

export const useDeleteLocation = (name: string) => {
  const selectedCustomerId = useCustomerIdGuard();

  const [deleteLocationMutation, { data, loading }] = useMutation<
    DeleteLocationMutation,
    DeleteLocationMutationVariables
  >(DELETE_LOCATION);

  const deleteLocation = async (id: string) => {
    return await deleteLocationMutation({
      variables: {
        customerId: selectedCustomerId,
        locationId: id,
      },
      onCompleted: response => {
        if (response.deleteLocation) {
          successNotification(`${name} is deleted`);
        }
      },
      onError: (error: ApolloError): void => {
        errorNotification(error.message);

        console.error(error.message);
      },
      update(cache) {
        const normalizedId = cache.identify({ id, __typename: "Location" });

        cache.evict({ id: normalizedId });

        cache.gc();
      },
    });
  };

  return { deleteLocation, data, loading };
};
