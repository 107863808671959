import { FC } from "react";

import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

interface CodeSnippetBoxProps {
  nodeScript: string;
  notify: () => void;
}

const CodeSnippetBox: FC<CodeSnippetBoxProps> = ({
  nodeScript,
  notify,
}): JSX.Element => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        backgroundColor: theme.palette.otherBackgroundLight.main,
        border: "1px solid",
        borderColor: theme.palette.otherOutlineBorder.main,
        padding: "1em",
        margin: "0.75em 0",
        borderRadius: "0.5em",
      }}
      onClick={notify}
    >
      <Typography
        component="div"
        noWrap
        variant="body2Regular"
        sx={{
          color: "text.primary",
        }}
      >
        {nodeScript}
      </Typography>

      <Button
        sx={{
          borderRadius: 40,
          display: "flex",
          gap: "0.25rem",
          height: "32px",
          marginLeft: "4px",
          alignItems: "center",
          textTransform: "none",
          justifyContent: "center",
          border: "1px solid",
          minWidth: "fit-content",
          padding: "4px 12px",
          color: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
        }}
      >
        <ContentCopyOutlinedIcon fontSize="small" />
        <Typography variant="buttonMedium">Copy to clipboard</Typography>
      </Button>
    </Box>
  );
};

export default CodeSnippetBox;
