import { FC, useEffect, useState } from "react";

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Zoom from "@mui/material/Zoom";
import Highlighter from "react-highlight-words";

import { AllowLeakFinder, Client, Group } from "../../API";
import RouterLink from "../../common/components/RouterLink";
import SimpleActionButton from "../../common/components/button/SimpleActionButton";
import EditIconButton from "../../common/components/icons/EditIconButton";
import { RouteEnum } from "../../common/models/enums";
import {
  VideosTableSelectionDispatch,
  VideosTableSelectionState,
  removeGroupAction,
  resetVideosTableSelectionStateAction,
} from "../store/useVideosTableSelectionReducer";
import { useClientUploadsSearchVariable } from "../variables/clientUploads";
import DeleteClientContainer from "./DeleteClientContainer";
import GroupCards from "./GroupCards";
import SelectedRowsFooter from "./footer/SelectedRowsFooter";
import { useGetCustomerCounters } from "../hooks/useGetCustomerCounters";

interface IClientCardProps {
  client: Client;
  groups: Group[];
  opened: boolean;
  deleteGroup: (clientId: string, groupId: string) => void;
  state: VideosTableSelectionState;
  dispatch: VideosTableSelectionDispatch;
}

const ClientCard: FC<IClientCardProps> = ({
  client,
  groups,
  opened,
  deleteGroup,
  state,
  dispatch,
}) => {
  const clientUploadsSearchValue = useClientUploadsSearchVariable();

  const { customerCounters } = useGetCustomerCounters();

  const [expanded, setExpanded] = useState(false);

  const deleteGroupHandler = (clientId: string, groupId: string) => {
    deleteGroup(clientId, groupId);

    dispatch(removeGroupAction({ clientId, groupId }));
  };

  useEffect(() => {
    if (opened !== expanded && clientUploadsSearchValue !== "") {
      setExpanded(opened);
    }
  }, [opened]);

  useEffect(() => {
    if (!expanded) {
      dispatch(resetVideosTableSelectionStateAction());
    }
  }, [expanded]);

  useEffect(() => {
    return () => {
      dispatch(resetVideosTableSelectionStateAction());
    };
  }, []);

  const toggleAccordion = () => {
    if (!isGroupEmpty) {
      setExpanded(prev => !prev);
    }
  };

  const queryParams = new URLSearchParams({
    clientId: client.id,
  }).toString();

  const editClientLink = `${RouteEnum.LeakFinderClientUploadsEditClient}?${queryParams}`;

  const addNewVideoGroupLink = `${RouteEnum.LeakFinderUploadNewVideoGroupPage}?${queryParams}`;

  const isGroupEmpty = !groups || groups.length === 0;

  let isVideoButtonDisabled = true;

  const tooltipTitle =
    "You have no free videos left, please contact sales team to get unlimited access to add more videos";

  const countOfUsage = customerCounters?.usageLeakFinderCount ?? 0;
  const videoLimit = customerCounters?.totalLeakFinderLimit ?? 0;

  switch (customerCounters?.allowLeakFinder) {
    case AllowLeakFinder.TRIAL_ACCESS:
      isVideoButtonDisabled = !(videoLimit - countOfUsage);
      break;

    case AllowLeakFinder.FULL_ACCESS:
      isVideoButtonDisabled = false;
      break;

    default:
      break;
  }

  return (
    <Box
      sx={{
        padding: "1em 1.5em",
        borderRadius: "12px",
      }}
    >
      <Box
        sx={{
          height: "32px",
          display: "flex",
          alignItems: "center",
          "&:hover .client-card-actions": {
            display: "flex",
          },
        }}
      >
        {!expanded && (
          <KeyboardArrowRightRoundedIcon
            onClick={toggleAccordion}
            sx={{
              cursor: isGroupEmpty ? "default" : "pointer",
              color: isGroupEmpty
                ? theme => theme.palette.otherBackground.main
                : "inherit",
            }}
          />
        )}
        {expanded && (
          <KeyboardArrowUpRoundedIcon
            onClick={toggleAccordion}
            sx={{
              cursor: isGroupEmpty ? "default" : "pointer",
              color: isGroupEmpty
                ? theme => theme.palette.otherBackground.main
                : "inherit",
            }}
          />
        )}
        <Box
          onClick={toggleAccordion}
          sx={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            flex: 1,
            gap: "0.25em",
          }}
        >
          <Typography variant="body2Bold">
            <Highlighter
              searchWords={[clientUploadsSearchValue]}
              autoEscape
              textToHighlight={client.clientName}
            />
          </Typography>
        </Box>
        <Box
          sx={{
            display: expanded ? "flex" : "none",
            ml: "auto",
            cursor: "pointer",
            pointerEvents: "auto",
            height: "32px",
          }}
          className="client-card-actions"
        >
          {isVideoButtonDisabled ? (
            <Tooltip title={tooltipTitle}>
              <Box sx={{ marginRight: "0.5em" }}>
                <SimpleActionButton
                  sx={{
                    "& .MuiButton-startIcon": {
                      marginRight: "0",
                    },
                  }}
                  icon={<AddOutlinedIcon />}
                  text="Video"
                  disabled={true}
                />
              </Box>
            </Tooltip>
          ) : (
            <RouterLink sx={{ marginRight: "0.5em" }} to={addNewVideoGroupLink}>
              <SimpleActionButton
                sx={{
                  "& .MuiButton-startIcon": {
                    marginRight: "0",
                  },
                }}
                icon={<AddOutlinedIcon />}
                text="Video"
                disabled={false}
              />
            </RouterLink>
          )}
          <RouterLink sx={{ marginRight: "0.5em" }} to={editClientLink}>
            <Tooltip TransitionComponent={Zoom} title={"Edit"}>
              <span>
                <EditIconButton />
              </span>
            </Tooltip>
          </RouterLink>

          <DeleteClientContainer row={client} />
        </Box>
      </Box>
      <SelectedRowsFooter deleteGroup={deleteGroupHandler} state={state} />
      {expanded && (
        <GroupCards
          groups={groups}
          deleteGroup={deleteGroupHandler}
          clientId={client.id}
          clientName={client.clientName}
          state={state}
          dispatch={dispatch}
        />
      )}
    </Box>
  );
};

export default ClientCard;
