import {
  Box,
  Divider,
  Drawer as MuiDrawer,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled, Theme, CSSObject } from "@mui/material/styles";

import KeyboardDoubleArrowLeftRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowLeftRounded";
import KeyboardDoubleArrowRightRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowRightRounded";

import { LeftSideBarItem } from "../UnifiedLayout";
import { TOP_BAR_HEIGHT } from "../MenuItem";
import { drawerWidth, drawerWidthClosed } from "../BaseLayout";
import LeftSideBarBase from "./LeftSideBarBase";
import { Z_INDEX_PRIORITY } from "../../../variables/common";

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `${drawerWidthClosed}px`,
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (propName: string): boolean => propName !== "open",
})(({ theme, open }) => ({
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export interface LeftSideBarProps {
  settings: {
    showAllLocationsDropdown: boolean;
    leftSideBarItems: LeftSideBarItem[];
  };
  onSetIsLeftSideBarOpen: () => void;
  isLeftSideBarOpen: boolean;
}

const LeftSideBar = ({
  settings,
  onSetIsLeftSideBarOpen,
  isLeftSideBarOpen,
}: LeftSideBarProps): JSX.Element => {
  return (
    <>
      <Tooltip
        title={
          <Typography variant="tooltip">
            <span>{isLeftSideBarOpen ? "Collapse" : "Expand"}</span>
            <span>{"  "}</span>
            <Box
              component="span"
              sx={{
                width: "16px",
                height: "16px",
                padding: "2px 8px",
                borderRadius: "2px",
                background: "rgba(7, 22, 39, 0.50)",
                color: (theme: Theme): string =>
                  theme.palette.otherBackgroundLight.main,
              }}
            >
              [
            </Box>
          </Typography>
        }
        placement="right"
      >
        <IconButton
          onClick={onSetIsLeftSideBarOpen}
          sx={{
            boxShadow: "none",
            backgroundColor: (theme: Theme): string =>
              theme.palette.otherBackgroundLight.main,
            border: (theme: Theme): string =>
              `1px solid ${theme.palette.primary.main}`,
            width: "32px",
            height: "32px",
            padding: 0,
            borderRadius: "50%",
            position: "absolute",
            top: `${TOP_BAR_HEIGHT + 15}px`,
            left: isLeftSideBarOpen
              ? `${drawerWidth - 18}px`
              : `${drawerWidthClosed - 18}px`,
            zIndex: Z_INDEX_PRIORITY.HEADER_LEFT_BAR + 1,
            "&:hover": {
              backgroundColor: (theme: Theme): string =>
                theme.palette.primary.main,
              "& svg": {
                color: (theme: Theme): string =>
                  theme.palette.otherBackgroundLight.main,
              },
            },
          }}
        >
          {isLeftSideBarOpen ? (
            <KeyboardDoubleArrowLeftRoundedIcon
              sx={{
                color: (theme: Theme): string => theme.palette.primary.main,
              }}
            />
          ) : (
            <KeyboardDoubleArrowRightRoundedIcon
              sx={{
                color: (theme: Theme): string => theme.palette.primary.main,
              }}
            />
          )}
        </IconButton>
      </Tooltip>

      <Drawer
        variant="permanent"
        open={isLeftSideBarOpen}
        PaperProps={{
          sx: {
            width: isLeftSideBarOpen ? drawerWidth : drawerWidthClosed,
            boxShadow: "none",
            outline: 0,
            backgroundImage: "none",
            display: "flex",
            flexDirection: "column",
            position: "fixed",
            left: 0,
            top: `${TOP_BAR_HEIGHT - 2}px`,
            height: `calc(100% - ${TOP_BAR_HEIGHT - 2}px)`,
            borderRight: (theme: Theme): string =>
              `2px solid ${theme.palette.otherOutlineBorder.main}`,
            backgroundColor: (theme: Theme): string =>
              theme.palette.otherBackground.main,
            transition: "width 250ms ease-in-out 0s",
            zIndex: Z_INDEX_PRIORITY.HEADER_LEFT_BAR,
          },
        }}
      >
        <Divider />

        <LeftSideBarBase settings={settings} open={isLeftSideBarOpen} />
      </Drawer>
    </>
  );
};

export default LeftSideBar;
