import { FC, memo } from "react";

import Grid from "@mui/material/Grid";

import {
  IModelOption,
  allModelsOptions,
} from "../../../../common/components/select/ServiceSelect/ModelsToRunMultiselect";

import { IDeviceTableRow, IModel } from "../../hooks/useDeviceTableRows";
import { useServiceActions } from "../../hooks/useServiceActions";
import DeviceModelItem from "./DeviceModelItem";
import { useLocation } from "react-router-dom";
import { ServiceTypeEnum } from "../../../model-manager/variables/modelManager";
import { deviceDataVariable } from "../../variables/devices";

interface IDeviceModelProps {
  device: IDeviceTableRow;
}

const DeviceModelsComponent: FC<IDeviceModelProps> = ({
  device,
}): JSX.Element => {
  const location = useLocation();
  const deviceModels = device?.models ?? [];

  location.state = { deviceId: device.rowId };

  const { modelsToRun } = device;

  return (
    <Grid gap={2} container spacing={4}>
      {modelsToRun.map((value: string): JSX.Element => {
        const model = allModelsOptions.find(
          (option): boolean => option.value === value
        );

        deviceModels.map(deviceModel => {
          if (deviceModel.serviceName === ServiceTypeEnum.GAS_LEAK) {
            deviceModel.serviceName = ServiceTypeEnum.LEAK;
          }

          return deviceModel;
        });

        const deviceModel = deviceModels.find(
          (deviceModel): boolean => deviceModel.serviceName === model?.name
        ) as IModel;

        const { changeModelStatusAction, deleteModelAction, serviceLoading } =
          useServiceActions({
            deviceModel,
            device,
          });

        const onChangeModelStatusHandler = (
          newStatus: boolean
        ): Promise<void> => {
          return changeModelStatusAction(!newStatus);
        };

        const onDeleteModelHandler = async (): Promise<void> => {
          const deletedModelId = await deleteModelAction();

          if (!deletedModelId) return;

          const filteredModels = deviceModels.filter(
            (model): boolean => model.serviceId !== deletedModelId
          );

          deviceDataVariable({ ...device, models: filteredModels });
        };

        if (!model) return <></>;

        const { updateStatusLoading, deleteModelLoading } = serviceLoading;

        const runningLeakModel = deviceModels.find(
          m =>
            m.isRunning &&
            (m.serviceName === ServiceTypeEnum.LEAK ||
              m.serviceName === ServiceTypeEnum.GAS_LEAK)
        );

        const runningLiquidLeakModel = deviceModels.find(
          m => m.isRunning && m.serviceName === ServiceTypeEnum.LIQUID_LEAK
        );

        let runningLeakModels: string[] = [];

        if (runningLeakModel) {
          runningLeakModels = [runningLeakModel.serviceName];
        }

        if (runningLiquidLeakModel) {
          runningLeakModels = [runningLiquidLeakModel.serviceName];
        }

        if (runningLeakModel && runningLiquidLeakModel) {
          runningLeakModels = [
            runningLeakModel.serviceName,
            runningLiquidLeakModel.serviceName,
          ];
        }

        return (
          <Grid key={value}>
            <DeviceModelItem
              updateStatusLoading={updateStatusLoading}
              deleteModelLoading={deleteModelLoading}
              deviceWithPanTilt={device.deviceData.hasPanTilt}
              model={model as IModelOption}
              currentModel={deviceModel as IModel}
              onStatusChange={onChangeModelStatusHandler}
              onDeleteModel={onDeleteModelHandler}
              runningLeakModels={runningLeakModels}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

const DeviceModels = memo(DeviceModelsComponent);

export default DeviceModels;
