import type { Device, Location, TagOfAssociation } from "../../../../../API";
import { useMemo } from "react";
import { AutocompleteOptionType } from "../../../../../common/models/autocomplete";
import {
  useLocationsTableSelectedFilterVariable,
  useLocationsTableSelectedTagLoactionsVariable,
} from "../variables/locationsTable";
import { useLazyGetPaginatedLocations } from "./useLazyGetPaginatedLocations";

export interface LocationRowInterface {
  rowId: string;
  name: string;
  coordinates?: any;
  stopMerrickId?: any;
  tags: TagOfAssociation[];
  referenceId: string;
  devices: Device[];
  sequenceNumber: number;
  timeZone: string | null | undefined;
}

export interface LocationsTableDataInterface {
  rows: LocationRowInterface[];
  loading: boolean;
}

export const useLocationsTableData = (): LocationsTableDataInterface => {
  const locationVariable = useLocationsTableSelectedFilterVariable();
  const tagLocationsVariable = useLocationsTableSelectedTagLoactionsVariable();

  const { locations, loading } = useLazyGetPaginatedLocations();

  const rows = useMemo(() => {
    const mappedData = locations?.map(
      (item: Location, index): LocationRowInterface => {
        let locationData = null;

        if (item.locationData) {
          locationData = JSON.parse(item.locationData);
        }

        return {
          rowId: item.id ?? "",
          name: item.name ?? "",
          sequenceNumber: index + 1,
          devices: item.devices as Device[],
          coordinates: {
            lat: locationData?.lat,
            lon: locationData?.lon,
          },
          referenceId: item.referenceId ?? "",
          stopMerrickId: locationData?.stopMerrickId,
          tags: item.tags ?? [],
          timeZone: item?.timeZone,
        };
      }
    );

    let rows = [...mappedData];

    if (locationVariable?.value) {
      rows = rows.filter(item => {
        return item.rowId === locationVariable.value;
      });
    }

    if (tagLocationsVariable?.length) {
      rows = rows?.filter(item => {
        const nameOfLocation = item.rowId;

        const locations = tagLocationsVariable.map(
          (tag: AutocompleteOptionType) => {
            if (nameOfLocation.includes("#L#")) {
              return tag?.value?.toLowerCase() ?? "";
            }

            return tag?.title?.toLowerCase() ?? "";
          }
        );

        const condition = locations.includes(nameOfLocation.toLowerCase());

        return condition;
      });
    }

    if (
      !locationVariable?.value &&
      tagLocationsVariable &&
      tagLocationsVariable.length === 0
    ) {
      rows = [];
    }

    if (!locationVariable?.value && !tagLocationsVariable) {
      rows = mappedData;
    }

    return rows.map((item, i) => ({ ...item, sequenceNumber: i + 1 }));
  }, [locationVariable, tagLocationsVariable, locations]);

  return { rows, loading };
};
