import { useLazyQuery } from "@apollo/client";

import { GetZoneQuery, GetZoneQueryVariables } from "./../../../API";
import { GET_ZONE } from "../../../common/operations/queries";
import { useCustomerIdGuard } from "../../../common/hooks/useCustomerIdGuard";

export const useGetZone = (deviceId: string, zoneId: string) => {
  const selectedCustomerId = useCustomerIdGuard();
  const [getZone, { data, loading }] = useLazyQuery<
    GetZoneQuery,
    GetZoneQueryVariables
  >(GET_ZONE, {
    variables: {
      // temporary fix until we fix DDB schemas with new C# prefix for querying zones, annotations, etc. (this would involve adding the prefix for device and service creation)
      deviceId: selectedCustomerId + "#" + deviceId,
      zoneId,
      customerId: selectedCustomerId,
    },
    fetchPolicy: "network-only",
  });

  return { data, loading, getZone };
};
