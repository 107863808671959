import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Auth } from "aws-amplify";

interface IUseCurrentUserSession {
  authenticated: boolean;
  isLoading: boolean;
  customerId: string;
  role: string | null;
}

export const useCurrentUserSession = (): IUseCurrentUserSession => {
  const navigate = useNavigate();

  const [authenticated, setAuthenticated] = useState(false);
  const [customerId, setCustomerId] = useState("");
  const [role, setRole] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const resetState = (): void => {
    setAuthenticated(false);

    setRole(null);

    setCustomerId("");
  };

  useEffect(() => {
    setIsLoading(true);

    Auth.currentSession()
      .then(user => {
        if (user) {
          const userClaims = user.getIdToken().payload;
          const role = userClaims.role;
          const customer = userClaims.customer_id ?? "";

          if (role) {
            setAuthenticated(true);

            setRole(role?.replace("_", "#") ?? "");

            setCustomerId(customer?.replace("_", "#") ?? "");
          } else {
            resetState();

            navigate("/unauthorized", { replace: true });
          }
        }
      })
      .catch(err => {
        resetState();

        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return { authenticated, role, customerId, isLoading } as const;
};
