import { useTheme } from "@mui/material/styles";

const InfoIcon = (): JSX.Element => {
  const theme = useTheme();

  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_15665_107697)">
        <circle
          cx="8.99364"
          cy="8.99999"
          r="7.49559"
          stroke={theme.palette.primary.main}
          strokeWidth="1.34921"
        />
        <path
          d="M8.99414 12.7476V8.2502"
          stroke={theme.palette.primary.main}
          strokeWidth="1.34921"
          strokeLinecap="round"
        />
        <mask id="path-3-inside-1_15665_107697" fill="white">
          <ellipse
            cx="0.749559"
            cy="0.749559"
            rx="0.749559"
            ry="0.749559"
            transform="matrix(1 0 0 -1 8.24414 6.75146)"
          />
        </mask>
        <ellipse
          cx="0.749559"
          cy="0.749559"
          rx="0.749559"
          ry="0.749559"
          transform="matrix(1 0 0 -1 8.24414 6.75146)"
          fill={theme.palette.primary.main}
        />
        <path
          d="M8.84379 6.00191C8.84379 6.0847 8.9109 6.15182 8.9937 6.15182V4.35288C9.90443 4.35288 10.6427 5.09117 10.6427 6.00191H8.84379ZM8.9937 6.15182C9.07649 6.15182 9.14361 6.0847 9.14361 6.00191H7.34467C7.34467 5.09117 8.08297 4.35288 8.9937 4.35288V6.15182ZM9.14361 6.00191C9.14361 5.91911 9.07649 5.85199 8.9937 5.85199V7.65094C8.08297 7.65094 7.34467 6.91264 7.34467 6.00191H9.14361ZM8.9937 5.85199C8.9109 5.85199 8.84379 5.91911 8.84379 6.00191H10.6427C10.6427 6.91264 9.90443 7.65094 8.9937 7.65094V5.85199Z"
          fill={theme.palette.primary.main}
          mask="url(#path-3-inside-1_15665_107697)"
        />
      </g>
      <defs>
        <clipPath id="clip0_15665_107697">
          <rect
            width="17.9894"
            height="17.9894"
            fill="white"
            transform="translate(0 0.00537109)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default InfoIcon;
