import { useLazyQuery } from "@apollo/client";
import { GET_SEND_EMAIL } from "../operations/queries";
import { GetSendEmailQuery, GetSendEmailQueryVariables } from "../../API";
import { errorNotification } from "../variables/notification";
import client from "../../configs/apolloClient";

interface IUseGetSendEmail {
  data?: GetSendEmailQuery;
  loading: boolean;
  fetchSendEmail: (email: string) => void;
}

export const useTestSendEmail = (): IUseGetSendEmail => {
  const [getSendEmail, { data, loading }] = useLazyQuery<
    GetSendEmailQuery,
    GetSendEmailQueryVariables
  >(GET_SEND_EMAIL, {
    fetchPolicy: "network-only",
    refetchWritePolicy: "merge",
  });

  const handleRequestError = async (): Promise<void> => {
    await client.cache.reset();

    errorNotification();
  };

  const fetchSendEmail = (email: string): void => {
    getSendEmail({
      variables: {
        email,
      },
    })
      .then(async (response): Promise<void> => {
        if (response.data?.getSendEmail) {
          fetchSendEmail(email);
        }

        if (response.error) {
          console.error(response.error);

          await handleRequestError();
        }
      })
      .catch(async (): Promise<void> => {
        await handleRequestError();
      });
  };

  return { data, loading, fetchSendEmail };
};
