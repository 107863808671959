import { ReactNode } from "react";

import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";

const PaperWrapperCentered = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const theme = useTheme();

  return (
    <Paper
      elevation={0}
      sx={{
        border: `1px solid ${theme.palette.otherOutlineBorder.main}`,
        padding: "2em",
        borderRadius: "12px",
        display: "flex",
        flex: "1",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      {children}
    </Paper>
  );
};

export default PaperWrapperCentered;
