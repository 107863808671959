import { Box, Tooltip, Typography, Zoom } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import { useConfirm } from "material-ui-confirm";

import DeleteIconButton from "../../../common/components/icons/DeleteIconButton";
import confirmDialogStyleOptions from "../../../common/helpers/confirmDialogParams";
import { useDeleteNode } from "../hooks/useDeleteNode";
import { Device } from "../../../API";

interface IDeleteNodeContainerProps {
  nodeId: string;
  rowId: string;
  name: string;
  devices: Device[];
}

const DeleteNodeContainer = ({
  nodeId,
  devices,
  rowId,
  name,
}: IDeleteNodeContainerProps): JSX.Element => {
  const confirm = useConfirm();

  const { deleteNode, loading } = useDeleteNode();

  const handleClick = (): void => {
    confirm({
      title: `Are you sure you want to delete ${name} node?`,
      confirmationText: "Delete",
      cancellationText: "Cancel",
      ...confirmDialogStyleOptions,
      confirmationButtonProps: {
        ...confirmDialogStyleOptions.confirmationButtonProps,
        disabled: loading,
      },
    }).then(async (): Promise<void> => {
      await deleteNode(nodeId, rowId, name);
    });
  };

  const disabled = !isEmpty(devices);

  return (
    <Tooltip
      TransitionComponent={Zoom}
      arrow
      disableInteractive
      placement="left"
      title={
        disabled ? (
          <Box textAlign="center">
            <Typography variant="tooltip">
              You cannot delete the node if there are connected devices to it
            </Typography>
          </Box>
        ) : null
      }
    >
      <Box
        component="span"
        sx={{
          height: "32px",
        }}
      >
        <DeleteIconButton onClick={handleClick} disabled={disabled} />
      </Box>
    </Tooltip>
  );
};

export default DeleteNodeContainer;
