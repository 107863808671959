import { TextField } from "@mui/material";

interface DynamicNumberInputProps {
  name: string;
  serviceConfig: any;
  setServiceConfig: (serviceConfigValue: any) => void;
}

const DynamicNumberInput = ({
  name,
  serviceConfig,
  setServiceConfig,
}: DynamicNumberInputProps): JSX.Element => {
  return (
    <TextField
      type="number"
      margin="dense"
      required
      fullWidth
      id={name + "-id"}
      label={name}
      value={serviceConfig[name]}
      onChange={(e): void =>
        setServiceConfig((prevServiceConfig: any): void => {
          return {
            ...prevServiceConfig,
            [name]: e.target.value,
          };
        })
      }
    />
  );
};

export default DynamicNumberInput;
