import { FC } from "react";

import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { Control, Controller, FieldErrors } from "react-hook-form";

import { zoneThresholdFormRules } from "../variables/zoneThresholdData";

export interface IZoneThresholdForm {
  name: string;
  value: number;
}

interface IZoneThresholdFormProps {
  control: Control<IZoneThresholdForm, any>;
  errors: FieldErrors<IZoneThresholdForm>;
}

const EditZoneThresholdForm: FC<IZoneThresholdFormProps> = ({
  control,
  errors,
}) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Controller
          name="name"
          control={control}
          rules={{
            required: "This field is required",
            pattern: {
              value: zoneThresholdFormRules.name.pattern,
              message: "Valid characters: A-Z, a-z, 0-9",
            },
          }}
          render={({ field }) => (
            <TextField
              {...field}
              value={field.value ?? ""}
              sx={{ height: "60px" }}
              size="medium"
              label="Zone type name"
              fullWidth
              error={!!errors.name}
              helperText={errors.name ? errors.name.message : ""}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="value"
          control={control}
          rules={{
            required: "This field is required",
            min: { value: 0.01, message: "Value must be greater than 0" },
            pattern: {
              value: zoneThresholdFormRules.value.pattern,
              message: "",
            },
          }}
          render={({ field }) => (
            <TextField
              {...field}
              value={field.value ?? ""}
              size="medium"
              fullWidth
              label="Zone threshold"
              variant="outlined"
              type="number"
              error={!!errors.value}
              helperText={errors.value ? errors.value.message : ""}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">MCF/d</InputAdornment>
                ),
                inputProps: { min: 0.01, step: 0.01 },
              }}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default EditZoneThresholdForm;
