function convertTo12Hour(militaryTime: string) {
  const [hour, minute] = militaryTime.split(":").map(Number);

  const period = hour >= 12 ? "PM" : "AM";

  const hour12 = hour % 12 || 12;

  return `${hour12}:${minute < 10 ? "0" : ""}${minute}${period}`;
}

export { convertTo12Hour };
