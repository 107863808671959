import { FC } from "react";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import { styled, useTheme } from "@mui/material/styles";
import Highlighter from "react-highlight-words";

import RouterLink from "../../../../../../common/components/RouterLink";
import EditIconButton from "../../../../../../common/components/icons/EditIconButton";
import usePlateManagementNavigationRoute from "../../../../../../common/hooks/usePlateManagementNavigationRoute";
import { RouteEnum } from "../../../../../../common/models/enums";
import { ILicensePlatesRow } from "../../hooks/useGetPaginatedLicensePlates";
import { useLicensePlatesTableVariables } from "../../variables/licensePlatesTable";
import DeleteLicensePlateContainer from "../DeleteLicensePlateContainer";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#e1e6ef",
    color: "#637288",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    borderBottom: "1px solid #e0e0e0",
    "&:last-child td, &:last-child th": { border: 0 },
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.common.white,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export interface ILicensePlatesTableProps {
  data: ILicensePlatesRow[];
}

const LicensePlatesTable: FC<ILicensePlatesTableProps> = ({ data }) => {
  const theme = useTheme();
  const licensePlatesSearchVariable = useLicensePlatesTableVariables();

  const { getNavigationRoute } = usePlateManagementNavigationRoute();

  return (
    <TableContainer
      sx={{
        border: `1px solid ${theme.palette.otherOutlineBorder.main}`,
        borderRadius: "12px",
      }}
      elevation={0}
      component={Paper}
    >
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <StyledTableCell>#</StyledTableCell>
            <StyledTableCell>Plate Number</StyledTableCell>
            <StyledTableCell>Driver</StyledTableCell>
            <StyledTableCell>Company</StyledTableCell>
            <StyledTableCell></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((lp, index) => {
            const queryParams = new URLSearchParams({
              categoryId: lp.categoryId,
              licensePlateId: lp.id,
            }).toString();

            const editPlateLink = getNavigationRoute(
              `${RouteEnum.RecentActivitiesPlatesManagementEditPlateNumber}?${queryParams}`,
              `${RouteEnum.SensoryRecentActivitiesPlatesManagementEditPlateNumber}?${queryParams}`
            );

            return (
              <StyledTableRow key={lp.id}>
                <StyledTableCell sx={{ width: "60px" }}>
                  {index + 1}
                </StyledTableCell>
                <StyledTableCell>
                  <Highlighter
                    searchWords={[licensePlatesSearchVariable.filterValue]}
                    autoEscape
                    textToHighlight={lp.licensePlate}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <Highlighter
                    searchWords={[licensePlatesSearchVariable.filterValue]}
                    autoEscape
                    textToHighlight={lp.driverName ?? ""}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <Highlighter
                    searchWords={[licensePlatesSearchVariable.filterValue]}
                    autoEscape
                    textToHighlight={lp.company ?? ""}
                  />
                </StyledTableCell>
                <StyledTableCell
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <RouterLink sx={{ marginRight: "0.5em" }} to={editPlateLink}>
                    <Tooltip TransitionComponent={Zoom} title={"Edit"}>
                      <span>
                        <EditIconButton />
                      </span>
                    </Tooltip>
                  </RouterLink>

                  <DeleteLicensePlateContainer
                    plateNumberId={lp.id}
                    plateNumber={lp.licensePlate}
                    category={lp.categoryId}
                  />
                </StyledTableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default LicensePlatesTable;
