import Box from "@mui/material/Box";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Control, FieldErrors } from "react-hook-form";

import MainPaperWrapper from "../../../../../../../common/components/item/MainPaperWrapper";
import { AutocompleteOptionType } from "../../../../../../../common/models/autocomplete";
import { IAlertForm } from "./CreateAlertContainer";
import AdditionalEmailsAlertToggleSection from "./alert-configs/AdditionalEmailsAlertToggleSection";
import EmailAlertToggleSection from "./alert-configs/EmailAlertToggleSection";
import AlertEntitiesRadioGroup from "./entities/AlertEntitiesRadioGroup";
import CustomerAlertForm from "./entities/CustomerAlertForm";
import LocationAlertForm from "./entities/LocationAlertForm";
import ModelAlertForm from "./entities/ModelAlertForm";

export interface IAlertFormBase {
  formValues: IAlertForm;
  control: Control<IAlertForm>;
}
interface IAlertFormProps extends IAlertFormBase {
  errors: FieldErrors<IAlertForm>;
  isSubmitted: boolean;
  onEntityChange: (value: string) => void;
  onLocationChange: (location: AutocompleteOptionType) => void;
  onLocationTagChange: (tagId: string) => void;
  onModelChange: (value: string | null) => void;
  onAddAdditionalEmail: () => void;
  onDeleteAdditionalEmail: (postion: number) => void;
  onAddAdditionalPhoneNumber: () => void;
  onDeleteAdditionalPhoneNumber: (postion: number) => void;
  onPhoneNumberChange: (value: string, position: number) => void;
}

const AlertForm = ({
  formValues,
  control,
  errors,
  isSubmitted,
  onEntityChange,
  onLocationChange,
  onLocationTagChange,
  onModelChange,
  onAddAdditionalEmail,
  onDeleteAdditionalEmail,
  onAddAdditionalPhoneNumber,
  onDeleteAdditionalPhoneNumber,
  onPhoneNumberChange,
}: IAlertFormProps) => {
  const everySwithcIsOff =
    !formValues.isEmailToggleEnabled &&
    !formValues.isAdditionalEmailsToggleEnabled &&
    !formValues.isSmsToggleEnabled &&
    isSubmitted;

  const entityError = !formValues.entity && errors.entity;

  const additionalEmailsError =
    formValues.isAdditionalEmailsToggleEnabled &&
    !formValues.additionalEmails.filter(x => x.email !== "").length &&
    isSubmitted;

  return (
    <Stack gap="0.75em">
      <MainPaperWrapper>
        <Box width="100%">
          <Typography variant="body2Bold">Select alert type</Typography>
          <Box display="flex" gap="2em" flexDirection="column" width="100%">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6}>
                <AlertEntitiesRadioGroup
                  control={control}
                  entityError={entityError as FieldErrors<IAlertForm>["entity"]}
                  onEntityChange={onEntityChange}
                />
              </Grid>
            </Grid>
            {formValues.entity && (
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={6}>
                  <CustomerAlertForm control={control} errors={errors} />
                </Grid>
              </Grid>
            )}
            {formValues.entity === "Location" && (
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={6}>
                  <LocationAlertForm
                    formValues={formValues}
                    control={control}
                    errors={errors}
                    onLocationChange={onLocationChange}
                    onLocationTagChange={onLocationTagChange}
                  />
                </Grid>
              </Grid>
            )}
            {formValues.entity === "Model" && (
              <>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={6}>
                    <LocationAlertForm
                      formValues={formValues}
                      control={control}
                      errors={errors}
                      onLocationChange={onLocationChange}
                      onLocationTagChange={onLocationTagChange}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={6}>
                    <ModelAlertForm
                      control={control}
                      errors={errors}
                      onModelChange={onModelChange}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </Box>
        </Box>
      </MainPaperWrapper>
      <MainPaperWrapper>
        <Box width="100%">
          <Typography variant="body2Bold" mb="1em" component="div">
            Where do you want to receive alerts?
          </Typography>
          <Stack gap="0.25em">
            <EmailAlertToggleSection
              formValues={formValues}
              control={control}
              everySwithcIsOff={everySwithcIsOff}
            />
            <AdditionalEmailsAlertToggleSection
              formValues={formValues}
              control={control}
              everySwithcIsOff={everySwithcIsOff}
              additionalEmailsError={additionalEmailsError}
              onAddAdditionalEmail={onAddAdditionalEmail}
              onDeleteAdditionalEmail={onDeleteAdditionalEmail}
            />
            {/* <SmsAlertToggleSection
              control={control}
              everySwithcIsOff={everySwithcIsOff}
              formValues={formValues}
              onAddAdditionalPhoneNumber={onAddAdditionalPhoneNumber}
              onDeleteAdditionalPhoneNumber={onDeleteAdditionalPhoneNumber}
              onPhoneNumberChange={onPhoneNumberChange}
            /> */}
          </Stack>
          {everySwithcIsOff && (
            <FormHelperText error>
              It’s required to switch on at least one option
            </FormHelperText>
          )}
          {additionalEmailsError && (
            <FormHelperText error>
              It's required to add additional email
            </FormHelperText>
          )}
        </Box>
      </MainPaperWrapper>
    </Stack>
  );
};

export default AlertForm;
