import { useEffect } from "react";

import CustomStepper from "../../../../common/components/instructions/CustomStepper";
import {
  createNodeActiveStepIndexVariable,
  createNodeInitialActiveStepIndex,
  nodeScriptInitialValue,
  nodeScriptVariable,
  useCreateNodeActiveStepIndex,
} from "../../variables/createNode.variable";
import {
  defaultNodeFormValidationState,
  nodeFormValidationStateVariable,
  nodeFormVariables,
  useNodeFormVariables,
} from "../../variables/nodes";
import CreateNodeFormStep from "./CreateNodeFormStep";
import RunNodeScriptStep from "./RunNodeScriptStep";

const steps = ["Add new node", "Run the node code"];

type NodeStepperContainerProps = {
  onBoardScript?: boolean;
  closeDialog?: () => void;
};

const CreateNodeStepperContainer = ({
  onBoardScript,
  closeDialog,
}: NodeStepperContainerProps): JSX.Element => {
  const activeStep = useCreateNodeActiveStepIndex();
  const nodeForm = useNodeFormVariables();

  useEffect((): (() => void) => {
    return (): void => {
      nodeScriptVariable(nodeScriptInitialValue);

      nodeFormVariables({
        ...nodeForm,
        nodeName: "",
      });

      nodeFormValidationStateVariable(defaultNodeFormValidationState);

      createNodeActiveStepIndexVariable(createNodeInitialActiveStepIndex);
    };
  }, []);

  return (
    <>
      <CustomStepper steps={steps} activeStep={activeStep} />

      {activeStep === 0 && (
        <CreateNodeFormStep
          onBoardScript={onBoardScript}
          closeDialog={closeDialog}
        />
      )}

      {activeStep === 1 && (
        <RunNodeScriptStep
          onBoardScript={onBoardScript}
          closeDialog={closeDialog}
        />
      )}
    </>
  );
};

export default CreateNodeStepperContainer;
