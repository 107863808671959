import Typography from "@mui/material/Typography";

import RouterLink from "../../../../common/components/RouterLink";
import { RouteEnum } from "../../../../common/models/enums";
import SmokeDataHubIcon from "../icons/SmokeDataHubIcon";
import DataHubGridItem from "./DataHubGridItem";
import { IModelGridItemProps } from "./GasLeakGridItem";

const SmokeGridItem = ({ nonAdmin }: IModelGridItemProps): JSX.Element => {
  return (
    <RouterLink to={nonAdmin ? RouteEnum.SensorySmoke : RouteEnum.Smoke}>
      <DataHubGridItem>
        <SmokeDataHubIcon />
        <Typography variant="body2Bold">Smoke</Typography>
      </DataHubGridItem>
    </RouterLink>
  );
};

export default SmokeGridItem;
