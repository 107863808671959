import { IStatisticsResponseModel } from "../models/chart";

function groupByPointer<T extends keyof IStatisticsResponseModel>(
  data: IStatisticsResponseModel[],
  pointer: T
): Array<
  { [P in T]: IStatisticsResponseModel[T] } & {
    data: IStatisticsResponseModel[];
  }
> {
  const grouped: { [key: string]: IStatisticsResponseModel[] } = {};

  data.forEach(item => {
    const key = item[pointer];
    const groupKey = String(key);

    (grouped[groupKey] = grouped[groupKey] || []).push(item);
  });

  return Object.keys(grouped).map(groupKey => {
    return {
      [pointer]: groupKey as unknown as IStatisticsResponseModel[T],
      data: grouped[groupKey],
    };
  }) as Array<
    { [P in T]: IStatisticsResponseModel[T] } & {
      data: IStatisticsResponseModel[];
    }
  >;
}

export function aggregateVolume(
  data: IStatisticsResponseModel[]
): IStatisticsResponseModel[] {
  const volumeMap = new Map<string, number>();

  data.forEach(entry => {
    const volume = parseFloat(entry.volume);

    if (!isNaN(volume)) {
      const currentTotal = volumeMap.get(entry.deviceName as string) ?? 0;

      volumeMap.set(
        entry.deviceName as string,
        +(currentTotal + volume).toFixed(4)
      );
    }
  });

  const aggregatedData: IStatisticsResponseModel[] = [];

  volumeMap.forEach((totalVolume, deviceName) => {
    const entry = data.find(e => e.deviceName === deviceName);

    if (entry) {
      aggregatedData.push({ ...entry, volume: totalVolume.toString() });
    }
  });

  return aggregatedData;
}

export default groupByPointer;
