import {
  AutocompleteOptionType,
  IDisableClearableOption,
} from "../models/autocomplete";

export const MAIN_LOCATIONS_DROPDOWN_STORAGE_KEY = "location&tag";

export const EVENTS_LOCATIONS_DROPDOWN_STORAGE_KEY = "eventsLocation&tag";

export const getLocationFromLocalStorage = (
  key: string
): AutocompleteOptionType | null => {
  const locationAndTag = localStorage.getItem(key);

  if (!locationAndTag) {
    return null;
  }

  try {
    const selectedLocation = JSON.parse(locationAndTag);

    return { title: selectedLocation?.title, value: selectedLocation?.value };
  } catch (e) {
    return null;
  }
};

export const getLocationFromLocalStorageByMainStorageKey = ():
  | AutocompleteOptionType
  | IDisableClearableOption
  | null => {
  return getLocationFromLocalStorage(MAIN_LOCATIONS_DROPDOWN_STORAGE_KEY);
};

export const getLocationFromLocalStorageByEventsStorageKey = ():
  | AutocompleteOptionType
  | IDisableClearableOption
  | null => {
  return getLocationFromLocalStorage(EVENTS_LOCATIONS_DROPDOWN_STORAGE_KEY);
};

export const setLocationToLocalStorage = (
  key: string,
  value?: AutocompleteOptionType | null
): void => {
  if (!value) {
    localStorage.removeItem(key);

    return;
  }

  localStorage.setItem(key, JSON.stringify(value));
};
