import { useEffect } from "react";

import { useLazyQuery } from "@apollo/client";

import {
  GetUserAlertsQuery,
  GetUserAlertsQueryVariables,
} from "../../../../../API";
import { useAuthenticatedUser } from "../../../../../common/hooks/useAuthenticatedUser";
import { useCustomerIdGuard } from "../../../../../common/hooks/useCustomerIdGuard";
import { GET_USER_ALERTS } from "../../../../../common/operations/queries";
import { errorNotification } from "../../../../../common/variables/notification";
import client from "../../../../../configs/apolloClient";
import { DEFAULT_LIMIT_COUNT } from "../../../../../common/variables/common";

export const ALERTS_TABLE_LIMIT = 10;

export const useGetLazyPaginatedUserAlerts = () => {
  const selectedCustomerId = useCustomerIdGuard();
  const { user } = useAuthenticatedUser();

  useEffect(() => {
    if (selectedCustomerId && user?.username) {
      fetchAllAlerts(selectedCustomerId, user?.username);
    }
  }, [selectedCustomerId, user?.username]);

  const [getAlerts, { data, loading }] = useLazyQuery<
    GetUserAlertsQuery,
    GetUserAlertsQueryVariables
  >(GET_USER_ALERTS, {
    fetchPolicy: "network-only",
  });

  const handleRequestError = async () => {
    await client.cache.reset();

    errorNotification();
  };

  const fetchAllAlerts = (
    customerId: string,
    email: string,
    limit?: number,
    nextToken?: string
  ) => {
    getAlerts({
      variables: {
        customerId,
        email,
        limit: DEFAULT_LIMIT_COUNT,
        nextToken,
      },
    })
      .then(async response => {
        if (response.data?.getUserAlerts.nextToken) {
          fetchAllAlerts(
            customerId,
            email,
            limit,
            response.data?.getUserAlerts.nextToken
          );
        }

        if (response.error) {
          await handleRequestError();
        }
      })
      .catch(async () => {
        await handleRequestError();
      });
  };

  return { data, loading };
};
