import { MouseEvent } from "react";

import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Theme, useTheme } from "@mui/material/styles";
import _without from "lodash/without";

import FireDataHubIcon from "../../../../pages/data-hub/components/icons/FireDataHubIcon";
import GateGuardDataHubIcon from "../../../../pages/data-hub/components/icons/GateGuardDataHubIcon";
import HardHatDataHubIcon from "../../../../pages/data-hub/components/icons/HardHatDataHubIcon";
import LiquidLeakDataHubIcon from "../../../../pages/data-hub/components/icons/LiquidLeakDataHubIcon";
import TlmDataHubIcon from "../../../../pages/data-hub/components/icons/TlmDataHubIcon";
import SmokeDataHubIcon from "../../../../pages/data-hub/components/icons/SmokeDataHubIcon";

interface IModelsToRunMultiselectProps {
  hasPanTilt: boolean;
  models: string[];
  setModels: (models: string[]) => void;
}

export interface IModelOption {
  name: string;
  value: string;
  icon?: JSX.Element;
}

export const allModelsOptions: IModelOption[] = [
  { name: "Leak", value: "gasleak", icon: <LiquidLeakDataHubIcon /> },
  { name: "Liquid Leak", value: "liquidleak", icon: <LiquidLeakDataHubIcon /> },
  { name: "Fire/Smoke", value: "firesmoke", icon: <FireDataHubIcon /> },
  { name: "Fire", value: "fire", icon: <FireDataHubIcon /> },
  { name: "Smoke", value: "smoke", icon: <SmokeDataHubIcon /> },
  { name: "Hard Hat", value: "hardhat", icon: <HardHatDataHubIcon /> },
  { name: "Gate Guard", value: "agg", icon: <GateGuardDataHubIcon /> },
  { name: "Tank Level", value: "tlm", icon: <TlmDataHubIcon /> },
];

const getStyles = (
  name: string,
  models: readonly string[],
  theme: Theme
): object => {
  return {
    fontWeight:
      models.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
};

const ModelsToRunMultiselect = ({
  models,
  setModels,
}: IModelsToRunMultiselectProps): JSX.Element => {
  const theme = useTheme();

  const handleChange = (event: SelectChangeEvent<typeof models>): void => {
    const {
      target: { value },
    } = event;

    setModels(typeof value === "string" ? value.split(",") : value);
  };

  const handleDelete = (e: MouseEvent, value: string): void => {
    e.preventDefault();
    const newModelsList = _without(models, value);

    setModels(newModelsList);
  };

  return (
    <FormControl
      sx={{
        minWidth: 120,
        width: "100%",
      }}
    >
      <InputLabel id="multiple-chip-label">Models to run*</InputLabel>
      <Select
        labelId="multiple-chip-label"
        multiple
        value={models}
        onChange={handleChange}
        input={<OutlinedInput label="Models to run*" />}
        renderValue={(selected): JSX.Element => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((value: string): JSX.Element => {
              const name = allModelsOptions.find(
                (model): boolean => model.value === value
              )?.name;

              return (
                <Chip
                  key={value}
                  label={name}
                  clickable
                  deleteIcon={
                    <CancelOutlinedIcon
                      sx={{ cursor: "pointer" }}
                      onMouseDown={(event): void => event.stopPropagation()}
                    />
                  }
                  onDelete={(e): void => handleDelete(e, value)}
                />
              );
            })}
          </Box>
        )}
      >
        {allModelsOptions.map(
          (option): JSX.Element => (
            <MenuItem
              key={option.value}
              value={option.value}
              style={getStyles(option.name, models, theme)}
            >
              {option.name}
            </MenuItem>
          )
        )}
      </Select>
    </FormControl>
  );
};

export default ModelsToRunMultiselect;
