import { IFormValidationState } from "../../../../../../../common/models/formValidation";

export interface IZoneThresholdForm {
  name: string;
  value: number;
}

export const defaultZoneThresholdFormVariables = {
  name: "",
  value: 0,
};

interface IZoneThresholdFormValidationState {
  name: IFormValidationState;
  value: IFormValidationState;
}

export const defaultZoneThresholdFormValidationState: IZoneThresholdFormValidationState =
  {
    name: {
      hasError: false,
      errorMessage: "",
    },
    value: {
      hasError: false,
      errorMessage: "",
    },
  };

export const zoneThresholdFormRules = {
  name: {
    required: true,
    pattern: /^(?!^ +$)[a-zA-Z0-9]+(?:\s[a-zA-Z0-9]+)*$/,
  },
  value: {
    required: true,
    pattern: /^-?\d*(\.\d+)?$/,
  },
};
