import { SyntheticEvent } from "react";

import ExpandMore from "@mui/icons-material/ExpandMore";
import Autocomplete, { AutocompleteProps } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

interface ServiceTypeSelectProps {
  value: string | null;
  onChange: (
    event: SyntheticEvent<Element, Event>,
    value: string | null
  ) => void;
  AutocompleteProps?: Omit<
    AutocompleteProps<string, false, false, false>,
    "renderInput" | "options"
  >;
  hasError?: boolean;
  errorMessage?: string;
}

const options: string[] = [
  "fire",
  "smoke",
  "gasleak",
  "hardhat",
  "liquidleak",
  "agg",
];

function ServiceTypeSelect({
  value,
  onChange,
  AutocompleteProps,
  hasError,
}: ServiceTypeSelectProps): JSX.Element {
  const props = AutocompleteProps ?? {};

  return (
    <Autocomplete
      value={value}
      onChange={onChange}
      popupIcon={<ExpandMore />}
      options={options}
      getOptionLabel={option => option} // Customizing the option label
      renderInput={params => (
        <TextField
          {...params}
          error={hasError}
          label="Model"
          variant="outlined"
        />
      )}
      {...props}
    />
  );
}

export default ServiceTypeSelect;
