import { useMemo } from "react";

import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";

import { useDevicesMakesTableRows } from "../../../../pages/system-settings/tabs/devices/hooks/useDevicesMakesTableRows";
import { IDisableClearableOption } from "../../../models/autocomplete";

interface IMakeSelect {
  makeId: string | null;
  label?: string;
  hasError?: boolean;
  disabled?: boolean;
  onMakeChange: (value: string) => void;
}

const MakeSelect = ({
  makeId,
  label,
  hasError,
  disabled,
  onMakeChange,
}: IMakeSelect): JSX.Element => {
  const { rows, loading } = useDevicesMakesTableRows();

  const options = useMemo((): IDisableClearableOption[] => {
    return rows?.map((item): IDisableClearableOption => {
      return {
        title: `${item.name}, ${item.model} (${item.type})`,
        value:
          JSON.stringify({
            model: item.model,
            shortName: item.deviceTypeShortName,
            hasPanTilt: item.hasPanTilt,
            hasZoom: item.hasZoom,
            defaultUserPwd: item?.defaultUserPwd,
            rtspTemplate: item?.rtspTemplate,
          }) ?? "",
      };
    });
  }, [rows]);

  const memoizedValue = useMemo((): IDisableClearableOption | null => {
    if (!makeId) {
      return null;
    }

    const result =
      options.find((option): boolean => {
        const model = JSON.parse(option?.value).model;

        return model === makeId;
      }) ?? null;

    return result;
  }, [makeId, options]);

  return (
    <Autocomplete
      fullWidth
      disabled={disabled}
      disableClearable
      loading={loading}
      value={memoizedValue as IDisableClearableOption}
      onChange={(
        _event: any,
        newValue: IDisableClearableOption | null
      ): void => {
        onMakeChange(newValue?.value ?? "");
      }}
      getOptionLabel={(option: IDisableClearableOption): string =>
        option?.title ?? ""
      }
      isOptionEqualToValue={(option, optionValue): boolean => {
        return option && option?.value === ""
          ? true
          : option?.value === optionValue?.value;
      }}
      options={options}
      renderInput={(params): JSX.Element => (
        <TextField
          {...params}
          error={hasError && !memoizedValue?.value}
          helperText={
            hasError && !memoizedValue?.value ? "This field is required" : ""
          }
          label={label ?? "Make/Model"}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default MakeSelect;
