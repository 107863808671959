import { ZONE_NAME_PATTERN } from "../variables/common";
import { errorNotification } from "../variables/notification";

export function validateZoneName(zoneName: string): boolean {
  const validName = ZONE_NAME_PATTERN.test(zoneName);

  if (!validName) {
    errorNotification(
      "Valid characters: a-z, A-Z, 0-9, -, _, ., !, @, #, $, %, &"
    );

    return false;
  }

  return validName;
}
