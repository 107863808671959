import { Box } from "@mui/material";

import LocationsPageHeader from "./components/LocationsPageHeader";
import LocationsTable from "./components/LocationsTable";

const LocationsPage = (): JSX.Element => {
  return (
    <Box>
      <LocationsPageHeader />
      <LocationsTable />
    </Box>
  );
};

export default LocationsPage;
