import { ChangeEvent, FC } from "react";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { ICreateZoneValidationState } from "../../../pages/devices/zones-setup-page/hooks/useZoneFormValidation";

type TimeInputFieldsProps = {
  validation: ICreateZoneValidationState;
  minutes: string;
  seconds: string;
  setMinutesHandler: (e: ChangeEvent<HTMLInputElement>) => void;
  setSecondsHandler: (e: ChangeEvent<HTMLInputElement>) => void;
};

const TimeInputFields: FC<TimeInputFieldsProps> = ({
  minutes,
  seconds,
  validation,
  setMinutesHandler,
  setSecondsHandler,
}): JSX.Element => {
  return (
    <>
      <Typography sx={{ margin: "32px 0 8px 0" }} variant="subtitle1">
        Set inspection time for this zone
      </Typography>

      <Typography sx={{ marginBottom: "8px" }} variant="subtitle2">
        Min 30 sec Max 6 min
      </Typography>

      {validation.minutes.hasError && (
        <Alert severity="error">{validation.minutes.errorMessage}</Alert>
      )}
      {validation.seconds.hasError && (
        <Alert severity="error">{validation.seconds.errorMessage}</Alert>
      )}

      <Box sx={{ display: "flex" }}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box>
            <Typography variant="inputLabel">Minutes</Typography>
          </Box>

          <TextField
            type="number"
            value={minutes}
            error={validation.minutes.hasError}
            onChange={setMinutesHandler}
            InputProps={{
              sx: { borderRadius: "8px" },
            }}
            sx={{
              width: "72px",
              "& input": {
                height: "18px",
                "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
                  "-webkit-appearance": "none",
                  margin: 0,
                },
              },
            }}
            id="tilt"
            size="small"
            variant="outlined"
            margin="dense"
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            position: "relative",
            marginLeft: "10px",
            flexDirection: "column",
          }}
        >
          <Box sx={{ position: "absolute", bottom: "12px", left: "-6px" }}>
            :
          </Box>

          <Box>
            <Typography variant="inputLabel">Seconds</Typography>
          </Box>

          <TextField
            type="number"
            value={seconds}
            error={validation.seconds.hasError}
            onChange={setSecondsHandler}
            InputProps={{
              sx: { borderRadius: "8px" },
            }}
            sx={{
              width: "72px",
              "& input": {
                height: "18px",
                "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
                  "-webkit-appearance": "none",
                  margin: 0,
                },
              },
            }}
            id="tilt"
            size="small"
            variant="outlined"
            margin="dense"
          />
        </Box>
      </Box>
    </>
  );
};

export default TimeInputFields;
