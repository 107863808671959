import { useMemo } from "react";

import { AutocompleteOptionType } from "../../../../../common/models/autocomplete";
import { AlertManagementTabsEnum } from "../../../../../common/variables/tabsSettings";
import { useGetLazyPaginatedAlerts } from "./useGetLazyPaginatedAlerts";

export interface IAlertNotificationSettings {
  isEmailEnabled: boolean;
  isSMSEnabled: boolean;
  isSystemEnabled: boolean;
}

export interface IAlertsTableRow {
  index: string;
  rowId: string;
  alertName: string;
  location: string;
  model: string;
  tag: string;
  alertType: string;
  email: string;
  node?: string;
  customService?: string;
  jsonObject?: string | null;
  phoneNumber: string;
  subscribedLevelEntity: string;
  notificationSettings: IAlertNotificationSettings;
  notificationsDiasbled: boolean;
  recipient: {
    email: string;
    phoneNumber: string;
  };
}

export interface IUseAlertsTableRow {
  rows: IAlertsTableRow[];
  customerAlertsCount: number;
  locationAlertsCount: number;
  modelAlertsCount: number;
  loading: boolean;
}

export const filterCustomerAlerts = (row: IAlertsTableRow): boolean => {
  return (
    row.subscribedLevelEntity.includes("C#") &&
    !row.subscribedLevelEntity.includes("L#") &&
    !row.subscribedLevelEntity.includes("T#") &&
    !row.subscribedLevelEntity.includes("S#")
  );
};

export const filterLocationAlerts = (row: IAlertsTableRow): boolean =>
  row.subscribedLevelEntity.includes("C#") &&
  (row.subscribedLevelEntity.includes("L#") ||
    row.subscribedLevelEntity.includes("T#")) &&
  !row.subscribedLevelEntity.includes("S#");

export const filterModelAlerts = (row: IAlertsTableRow): boolean =>
  row.subscribedLevelEntity.includes("C#") &&
  row.subscribedLevelEntity.includes("S#");

export const useAlertsTableRow = (
  selectedTab?: AlertManagementTabsEnum,
  selectedLocations?: AutocompleteOptionType[] | null,
  searchValue?: string,
  selectedModel?: string
): IUseAlertsTableRow => {
  const { data, loading } = useGetLazyPaginatedAlerts();

  const rows = useMemo((): IAlertsTableRow[] => {
    try {
      return (
        data?.getAllAlerts.items?.map((item, index) => {
          const notificationSettings = JSON.parse(
            item?.notificationSettingsJson ?? "{}"
          );

          return {
            index: (index + 1).toString(),
            rowId: item?.id ?? "",
            alertName: item?.alertName ?? "",
            location: item?.location ?? "",
            model: item?.model ?? "",
            tag: item?.tag ?? "",
            alertType: item?.alertType ?? "",
            email: item?.email ?? "",
            node: item?.node ?? "",
            customService: item?.customService ?? "",
            jsonObject: item?.jsonObject,
            phoneNumber: item?.phoneNumber ?? "",
            subscribedLevelEntity: item?.subscribedLevelEntity ?? "",
            notificationSettings: {
              isEmailEnabled: notificationSettings.isEmailEnabled ?? false,
              isSMSEnabled: notificationSettings.isSMSEnabled ?? false,
              isSystemEnabled: notificationSettings.isSystemEnabled ?? false,
            },
            notificationsDiasbled: Object.values(notificationSettings).every(
              n => !n
            ),
            recipient: {
              email: item?.email ?? "",
              phoneNumber: item?.phoneNumber ?? "",
            },
          };
        }) ?? []
      );
    } catch (error) {
      console.error(error);

      return [];
    }
  }, [data?.getAllAlerts.items]);

  const customerAlertsCount = useMemo(() => {
    return rows.filter(filterCustomerAlerts).length;
  }, [rows]);

  const locationAlertsCount = useMemo(() => {
    return rows.filter(filterLocationAlerts).length;
  }, [rows]);

  const modelAlertsCount = useMemo(() => {
    return rows.filter(filterModelAlerts).length;
  }, [rows]);

  const filteredItemsBySelectedTab = useMemo(() => {
    const filteredData = rows
      .filter(row => {
        if (!selectedTab) {
          return true;
        }

        if (selectedTab === AlertManagementTabsEnum.CUSTOMER) {
          return filterCustomerAlerts(row);
        }

        if (selectedTab === AlertManagementTabsEnum.LOCATION) {
          return filterLocationAlerts(row);
        }

        if (selectedTab === AlertManagementTabsEnum.MODEL) {
          return filterModelAlerts(row);
        }

        return true;
      })
      .map((row, index) => ({
        ...row,
        index: (index + 1).toString(),
      }));

    return filteredData;
  }, [rows, selectedTab]);

  const filteredItemsBySelectedLocations = useMemo(() => {
    if (!selectedLocations || !selectedLocations.length) {
      return filteredItemsBySelectedTab;
    }

    return filteredItemsBySelectedTab.filter(row => {
      return selectedLocations.some(location => {
        return row.location === location?.value;
      });
    });
  }, [filteredItemsBySelectedTab, selectedLocations]);

  const filteredItemsBySearchValue = useMemo(() => {
    if (!searchValue) {
      return filteredItemsBySelectedLocations;
    }

    return filteredItemsBySelectedLocations.filter(row => {
      return row.alertName.toLowerCase().includes(searchValue.toLowerCase());
    });
  }, [filteredItemsBySelectedLocations, searchValue]);

  const filteredItemsBySelectedModel = useMemo(() => {
    if (!selectedModel) {
      return filteredItemsBySearchValue;
    }

    return filteredItemsBySearchValue.filter(row => {
      return row.model === selectedModel;
    });
  }, [filteredItemsBySearchValue, selectedModel]);

  const mappedRows = useMemo(() => {
    return filteredItemsBySelectedModel.map((row, index) => ({
      ...row,
      index: (index + 1).toString(),
    }));
  }, [filteredItemsBySelectedModel]);

  return {
    rows: mappedRows,
    customerAlertsCount,
    locationAlertsCount,
    modelAlertsCount,
    loading,
  };
};
