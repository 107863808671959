import { useNavigate } from "react-router-dom";

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { RouteEnum } from "../common/models/enums";
import LeakFinderSearchInput from "./components/search/LeakFinderSearchInput";

const ClientUploadsHeader = () => {
  const navigate = useNavigate();

  return (
    <Box padding="0" marginBottom="1em">
      <Stack spacing={4} direction="row" alignItems="center">
        <Typography variant="h3" sx={{ flexGrow: 1 }}>
          Client Uploads
        </Typography>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "1.5em",
            marginLeft: "auto",
          }}
        >
          <LeakFinderSearchInput />
          <Button
            sx={{
              marginLeft: "auto",
            }}
            variant="outlined"
            size="small"
            startIcon={<AddOutlinedIcon />}
            onClick={(): void =>
              navigate(RouteEnum.LeakFinderClientUploadsCreate)
            }
          >
            New client
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};

export default ClientUploadsHeader;
