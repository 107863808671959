import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import {
  DeviceData,
  DeviceHealthData,
  GetServicesByLocationQuery,
} from "../../../API";
import { useGetServicesByLocation } from "../../../common/hooks/useGetServicesByLocation";
import useLocationFromCache from "../../../common/hooks/useLocationFromCache";
import { AutocompleteOptionType } from "../../../common/models/autocomplete";
import {
  useSelectedLocationDropdown,
  useSelectedTagIdDropdown,
  useSelectedTagLocationsDropdown,
} from "../../../common/variables/selectedLocationDropdown";
import { ServiceTypeEnum } from "../../model-manager/variables/modelManager";
import { useGetLazyPaginatedDevicesMakes } from "../../system-settings/tabs/devices/hooks/useGetLazyPaginatedDevicesMakes";
import { DisplayNameInput } from "../variables/devices";
import { useGetDevices } from "./useGetDevices";
import { useListenToDeviceHealthDataChange } from "../../deployment-health/hooks/useListenToDeviceHealthDataChange";

export interface IModel {
  serviceName: ServiceTypeEnum;
  serviceId: string;
  nodeId: string;
  isRunning?: boolean;
  configuration?: string;
}

export interface IDeviceTableRow {
  rowId: string;
  name: string;
  location: DisplayNameInput;
  node: DisplayNameInput;
  makeModelId: string;
  models: IModel[];
  modelsToRun: string[];
  deviceData: Omit<DeviceData, "__typename">;
  healthData: Omit<DeviceHealthData, "__typename">;
  isOnline: boolean;
  cameraIpAddress: string;
  rtspTemplate: string;
}

interface IUseDeviceTableRows {
  rows: IDeviceTableRow[];
  loading: boolean;
}

export const useDeviceTableRows = (): IUseDeviceTableRows => {
  const selectedLocation = useSelectedLocationDropdown();
  const selectedTagLocations = useSelectedTagLocationsDropdown();
  const selectedTagId = useSelectedTagIdDropdown();

  const { data: deviceHealthData } = useListenToDeviceHealthDataChange();

  const currentTagIdRef = useRef("");

  const { data, loading: deviceLoading, refetch } = useGetDevices();
  const {
    data: services,
    loading: servicesLoading,
    refetchLoading,
    fetchServicesByLocationId,
  } = useGetServicesByLocation(selectedLocation?.value);

  const { getCachedLocation } = useLocationFromCache();
  const { data: devicesMakesData } = useGetLazyPaginatedDevicesMakes();

  const isLoading = deviceLoading || servicesLoading || refetchLoading;

  const rowsByLocation: IDeviceTableRow[] = useMemo(() => {
    const mappedData = !isLoading
      ? data?.getDevices?.items?.map((item): any => {
          const models =
            services?.getServicesByLocation?.items
              ?.filter(service => {
                // note: item is device i.e. item.id will always be DE#<>
                // legacy service item's deviceId field has no C# prefix e.g. DE#<>
                const doesLegacyIdsMatch = service?.deviceId === item?.id;
                // service item's deviceId field has C# prefix e.g. C#<>DE#<>; must remove before comparison
                const doesIdsMatch =
                  `DE#${service?.deviceId?.split("DE#")[1]}` === item?.id;

                return doesLegacyIdsMatch || doesIdsMatch;
              })
              .map(service => ({
                serviceName: service?.serviceType,
                serviceId: service?.id,
                nodeId: service?.nodeId,
                isRunning: service?.isRunning,
              })) ?? [];

          const cachedLocation = getCachedLocation(item?.locationId ?? "");

          const foundDeviceMake = devicesMakesData?.getDevicesMakes.items.find(
            el => el?.model === item?.makeModelId
          );

          return {
            rowId: item?.id as string,
            name: item?.name as string,
            location: {
              id: item?.locationId as string,
              name: cachedLocation?.name as string,
            },
            node: {
              id: item?.nodeId as string,
              name: item?.nodeName as string,
            },
            modelsToRun: foundDeviceMake?.modelsToRun,
            makeModelId: item?.makeModelId as string,
            models,
            deviceData: item?.deviceData,
            healthData: item?.healthData ?? "{}",
            isOnline: Boolean(item?.healthData?.isOnline),
            cameraIpAddress: item?.cameraIpAddress as string,
          };
        }) ?? []
      : [];

    let rows = [...mappedData];

    if (selectedLocation?.value) {
      rows = rows?.filter((item: any) => {
        const nameOfLocation = item.location.id;

        return nameOfLocation === selectedLocation?.value;
      });
    }

    return rows.map((item, i) => ({ ...item, sequenceNumber: i + 1 }));
  }, [
    data?.getDevices?.items,
    services?.getServicesByLocation?.items,
    window.location.hash,
    isLoading,
    selectedLocation?.value,
  ]);

  const [servicesByTagLocations, setServicesByTagLocations] = useState<
    (GetServicesByLocationQuery | undefined)[]
  >([]);

  const fetchServices = useCallback(async () => {
    const tagLocations = selectedTagLocations ?? [];

    if (!isLoading && currentTagIdRef.current !== selectedTagId) {
      try {
        const results = await Promise.all(
          tagLocations.map(location =>
            fetchServicesByLocationId(location?.value ?? "")
          )
        );

        setServicesByTagLocations(results.filter(Boolean));

        currentTagIdRef.current = selectedTagId ?? "";
      } catch (error) {
        console.error("Error fetching services:", error);

        setServicesByTagLocations([]);
      }
    }
  }, [isLoading, selectedTagLocations, selectedTagId]);

  useEffect(() => {
    fetchServices();
  }, [fetchServices]);

  const rowsByTagLocations: IDeviceTableRow[] = useMemo(() => {
    const mappedData =
      !isLoading && selectedTagLocations?.length
        ? data?.getDevices?.items?.map((item): IDeviceTableRow => {
            const models =
              servicesByTagLocations
                ?.filter(service => {
                  // note: item is device i.e. item.id will always be DE#<>
                  // legacy service item's deviceId field has no C# prefix e.g. DE#<>
                  const serviceByDeviceMatch =
                    service?.getServicesByLocation?.items.find(
                      s =>
                        s?.deviceId === item?.id ||
                        `DE#${s?.deviceId?.split("DE#")[1]}` === item?.id
                    );

                  return serviceByDeviceMatch !== undefined;
                })
                .map(service => {
                  const serviceByDeviceMatch =
                    service?.getServicesByLocation?.items.find(
                      s =>
                        s?.deviceId === item?.id ||
                        `DE#${s?.deviceId?.split("DE#")[1]}` === item?.id
                    );

                  return {
                    serviceName: serviceByDeviceMatch?.serviceType,
                    serviceId: serviceByDeviceMatch?.id,
                    nodeId: serviceByDeviceMatch?.nodeId,
                    isRunning: serviceByDeviceMatch?.isRunning,
                  };
                }) ?? [];

            const cachedLocation = getCachedLocation(item?.locationId ?? "");

            const foundDeviceMake =
              devicesMakesData?.getDevicesMakes.items.find(
                el => el?.model === item?.makeModelId
              );

            return {
              rowId: item?.id as string,
              name: item?.name as string,
              location: {
                id: item?.locationId as string,
                name: cachedLocation?.name as string,
              },
              node: {
                id: item?.nodeId as string,
                name: item?.nodeName as string,
              },
              modelsToRun: foundDeviceMake?.modelsToRun as [string],
              makeModelId: item?.makeModelId as string,
              models: models as IModel[],
              deviceData: item!.deviceData,
              healthData: item!.healthData,
              rtspTemplate: foundDeviceMake?.rtspTemplate ?? "",
              isOnline: Boolean(item?.healthData?.isOnline),
              cameraIpAddress: item?.cameraIpAddress as string,
            };
          }) ?? []
        : [];

    let rows = [...mappedData];

    if (selectedTagLocations?.length) {
      rows = rows?.filter((item: any) => {
        const nameOfLocation = item.location.id;

        const locations = selectedTagLocations.map(
          (tag: AutocompleteOptionType) => {
            if (nameOfLocation.includes("#L#")) {
              return tag?.value?.toLowerCase() ?? "";
            }

            return tag?.title?.toLowerCase() ?? "";
          }
        );

        return locations.includes(nameOfLocation.toLowerCase());
      });
    }

    if (selectedTagLocations && selectedTagLocations.length === 0) {
      rows = [];
    }

    if (!selectedTagLocations) {
      rows = mappedData;
    }

    return rows.map((item, i) => ({ ...item, sequenceNumber: i + 1 }));
  }, [
    data?.getDevices?.items,
    services?.getServicesByLocation?.items,
    isLoading,
    selectedTagLocations,
    servicesLoading,
    servicesByTagLocations,
  ]);

  const rows = selectedLocation?.value ? rowsByLocation : rowsByTagLocations;

  const memoizedDevicesRows =
    useMemo(() => {
      const isDeviceDataFromSubscription =
        deviceHealthData?.listenToDeviceHealthData;

      if (!isDeviceDataFromSubscription) return rows;

      const updatedRows = rows.map(device => {
        if (isDeviceDataFromSubscription.id === device?.rowId) {
          if (isDeviceDataFromSubscription?.healthData?.isOnline === false) {
            refetch();
          }

          return {
            ...device,
            isOnline: Boolean(
              isDeviceDataFromSubscription?.healthData?.isOnline
            ),
          };
        }

        return { ...device };
      });

      return updatedRows;
    }, [deviceHealthData, rows]) ?? [];

  return {
    rows: memoizedDevicesRows,
    loading: isLoading,
  };
};
