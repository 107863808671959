import { useNavigate } from "react-router-dom";

import { Tooltip, Zoom } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import { useConfirm } from "material-ui-confirm";

import DeleteIconButton from "../../../common/components/icons/DeleteIconButton";
import DeleteIconButtonWithText from "../../../common/components/icons/DeleteIconButtonWithText";
import confirmDialogStyleOptions from "../../../common/helpers/confirmDialogParams";
import { useDeleteDevice } from "../hooks/useDeleteDevice";
import { useSyncShadow } from "../../model-manager/hooks/useSyncShadow";
import { IDeviceTableRow } from "../hooks/useDeviceTableRows";

interface DeleteDeviceContainerProps {
  row: IDeviceTableRow;
}

const DeleteDeviceContainer = ({
  row,
}: DeleteDeviceContainerProps): JSX.Element => {
  const confirm = useConfirm();
  const navigate = useNavigate();
  const { syncShadowsForNode } = useSyncShadow();

  const { deleteDevice, loading } = useDeleteDevice(row?.name);

  const handleRowClick = (): void => {
    confirm({
      title: `Are you sure you want to delete ${row?.name}?`,
      confirmationText: "Delete",
      cancellationText: "Cancel",
      ...confirmDialogStyleOptions,
      confirmationButtonProps: {
        ...confirmDialogStyleOptions.confirmationButtonProps,
        disabled: loading,
      },
    }).then(async (): Promise<void> => {
      await deleteDevice(row?.rowId, row?.location?.id);

      await syncShadowsForNode(row?.node?.id);
    });
  };

  const handleDeviceDetailsPageClick = (): void => {
    confirm({
      title: `Are you sure you want to delete ${row.name}?`,
      confirmationText: "Delete",
      cancellationText: "Cancel",
      ...confirmDialogStyleOptions,
      confirmationButtonProps: {
        ...confirmDialogStyleOptions.confirmationButtonProps,
        disabled: loading,
      },
    }).then(async (): Promise<void> => {
      await deleteDevice(row.rowId, row.location?.id);

      navigate(`/devices`);
    });
  };

  const disabled = !isEmpty(row?.models);

  // Check if the URL contains "DE#"
  const isDeviceDetailPage = window.location.href.includes(
    encodeURIComponent("DE#")
  );

  return (
    <Tooltip
      TransitionComponent={Zoom}
      title={disabled ? "Device has assigned models" : null}
    >
      <span>
        {isDeviceDetailPage ? (
          <DeleteIconButtonWithText
            onClick={handleDeviceDetailsPageClick}
            disabled={disabled}
          />
        ) : (
          <DeleteIconButton onClick={handleRowClick} disabled={disabled} />
        )}
      </span>
    </Tooltip>
  );
};

export default DeleteDeviceContainer;
