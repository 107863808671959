import { makeVar, useReactiveVar } from "@apollo/client";
import { IFormValidationState } from "../../../../../common/models/formValidation";
import { TagOfAssociation } from "../../../../../API";

interface ILocationForm {
  locationName: string;
  referenceId: string;
  latitude: string;
  longitude: string;
  tags: TagOfAssociation[];
  rowId: string;
  timeZone: string;
}

export const defaultLocationFormVariables = {
  locationName: "",
  referenceId: "",
  latitude: "",
  longitude: "",
  tags: [],
  rowId: "",
  timeZone: "",
};

interface ILocationFormValidationState {
  locationName: IFormValidationState;
  latitude: IFormValidationState;
  longitude: IFormValidationState;
  timeZone: IFormValidationState;
}

export const defaultLocationFormValidationState: ILocationFormValidationState =
  {
    locationName: {
      hasError: false,
      errorMessage: "",
    },
    latitude: {
      hasError: false,
      errorMessage: "",
    },
    longitude: {
      hasError: false,
      errorMessage: "",
    },
    timeZone: {
      hasError: false,
      errorMessage: "",
    },
  };

export const locationFormRules = {
  locationName: {
    required: true,
    unique: true,
    pattern: `^[A-Za-z0-9 _-]+$`,
    maxLength: 50,
  },
  latitude: {
    required: true,
    pattern: `^[-+]?\\d+(\\.\\d+)?$`, // Only allows decimal or integer numbers
  },
  longitude: {
    required: true,
    pattern: `^[-+]?\\d+(\\.\\d+)?$`,
  },
  timeZone: {
    required: true,
    pattern: `^([a-zA-Z0-9()]+\\s?)+$`,
  },
};

export const locationFormVariables = makeVar<ILocationForm>(
  defaultLocationFormVariables
);

export const useLocationFormVariables = () =>
  useReactiveVar(locationFormVariables);

export const locationFormValidationStateVariable =
  makeVar<ILocationFormValidationState>(defaultLocationFormValidationState);

export const useLocationFormValidationState = () =>
  useReactiveVar(locationFormValidationStateVariable);
