import { useNavigate } from "react-router-dom";

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { Box, Button, Stack, Typography } from "@mui/material";

import AllLocationsFilterDropdownContainer from "../../../../../common/components/locations/AllLocationsFilterDropdownContainer";
import { AutocompleteOptionType } from "../../../../../common/models/autocomplete";
import {
  locationsTableSelectedFilterVariable,
  locationsTableSelectedTagLocationsVariable,
} from "../variables/locationsTable";

const LocationsPageHeader = (): JSX.Element => {
  const navigate = useNavigate();

  const handleLocationChange = (
    locationValue: AutocompleteOptionType
  ): void => {
    locationsTableSelectedFilterVariable(locationValue);
  };

  const handleTagLocationsChange = (
    tagLocations: AutocompleteOptionType[] | null
  ): void => {
    locationsTableSelectedTagLocationsVariable(tagLocations);
  };

  return (
    <Box padding="0" marginBottom="1em">
      <Stack
        spacing={4}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h3" padding={0}>
          Manage locations
        </Typography>
        <Stack direction="row" spacing={4} alignItems="center">
          <Box sx={{ minWidth: "260px" }}>
            <AllLocationsFilterDropdownContainer
              setLocation={handleLocationChange}
              setTagLocations={handleTagLocationsChange}
            />
          </Box>
          <Button
            variant="outlined"
            size="small"
            startIcon={<AddOutlinedIcon />}
            sx={{
              whiteSpace: "nowrap",
            }}
            onClick={(): void => navigate("/manage-locations/create")}
          >
            New location
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default LocationsPageHeader;
