import { RoleEnum } from "../models/enums";
import { useAuthenticatedUser } from "./useAuthenticatedUser";

export const useGetNonAdminAccess = () => {
  const { role } = useAuthenticatedUser();

  const nonAdminView = [
    RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
    RoleEnum.PARTNER_USER,
    RoleEnum.CUSTOMER_OPS_TEAM,
    RoleEnum.CUSTOMER_HSE,
    RoleEnum.CUSTOMER_SECURITY,
  ].includes(role);

  return { nonAdminView };
};
