import { useEffect } from "react";

import { useAuthenticator } from "@aws-amplify/ui-react";

import { AmplifyAuthStatus } from "../models/amplify";
import { RoleEnum } from "../models/enums";

export const useAuthenticatedUser = () => {
  // NOTE: To prevent undesired re-renders, you can pass a function to useAuthenticator that takes
  // in Authenticator context and returns an array of desired context values.
  // The hook will only trigger re-render if any of the array values change.
  // https://ui.docs.amplify.aws/react/connected-components/authenticator/advanced#prevent-re-renders
  const { user, route, authStatus } = useAuthenticator(context => [
    context.user,
    context.route,
    context.authStatus,
  ]);

  const payload = user?.getSignInUserSession()?.getIdToken()?.payload;

  const hasToken = !!payload;

  // NOTE: Sets the return URL in local storage so that the user can be redirected back to it after login
  useEffect(() => {
    if (
      !hasToken &&
      window.location.pathname !== "/login" &&
      route === "idle"
    ) {
      const returnUrl = window.location.pathname;

      localStorage.setItem("returnUrl", returnUrl);
    }

    if (hasToken && authStatus === AmplifyAuthStatus.AUTHENTICATED) {
      localStorage.removeItem("returnUrl");
    }
  }, [hasToken, route, authStatus]);

  const role: RoleEnum = payload?.role ?? RoleEnum.NONE;
  const customerId = payload?.customerId;

  return {
    authenticated: route === AmplifyAuthStatus.AUTHENTICATED,
    authStatus,
    user,
    route,
    customerId: customerId?.replace("_", "#") ?? "",
    role,
    hasToken,
  };
};
