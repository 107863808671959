import { useEffect, useMemo } from "react";

import { useLazyQuery, WatchQueryFetchPolicy } from "@apollo/client";

import type {
  GetLocationsQuery,
  GetLocationsQueryVariables,
  Location,
} from "../../../../../API";
import { useCustomerIdGuard } from "../../../../../common/hooks/useCustomerIdGuard";
import { GET_LOCATIONS } from "../../../../../common/operations/queries";
import { errorNotification } from "../../../../../common/variables/notification";
import client from "../../../../../configs/apolloClient";
import { DEFAULT_LIMIT_COUNT } from "../../../../../common/variables/common";

export const LIMIT = 25;

interface LazyGetPaginatedLocationsInterface {
  locations: Location[];
  loading: boolean;
}

export const useLazyGetPaginatedLocations = (
  policy: WatchQueryFetchPolicy = "cache-and-network"
): LazyGetPaginatedLocationsInterface => {
  const selectedCustomerId = useCustomerIdGuard();

  const [getLocations, { data, loading }] = useLazyQuery<
    GetLocationsQuery,
    GetLocationsQueryVariables
  >(GET_LOCATIONS, {
    fetchPolicy: policy,
  });

  useEffect((): void => {
    if (selectedCustomerId) {
      fetchLocations(selectedCustomerId);
    }
  }, [selectedCustomerId]);

  const handleRequestError = async (): Promise<void> => {
    await client.cache.reset();

    errorNotification();
  };

  const fetchLocations = (customerId?: string, nextToken?: string): void => {
    getLocations({
      variables: {
        limit: DEFAULT_LIMIT_COUNT,
        customerId: customerId ?? "",
        nextToken,
      },
    })
      .then(async (response): Promise<void> => {
        if (response.data?.getLocations.nextToken) {
          fetchLocations(customerId, response.data?.getLocations.nextToken);
        }

        if (response.error) {
          await handleRequestError();
        }
      })
      .catch(async (): Promise<void> => {
        await handleRequestError();
      });
  };

  const locations: Location[] = useMemo(
    (): Location[] => (data?.getLocations.items as Location[]) || [],
    [data?.getLocations.items]
  );

  return { locations, loading };
};
