import { Navigate } from "react-router-dom";

import { useAuthenticatedUser } from "../common/hooks/useAuthenticatedUser";
import { ROLES } from "../common/models/enums";
import FullPageLoader from "../common/components/item/FullPageLoader";

interface IAppRoutesProps {
  allowedRoles: string[];
  children: JSX.Element;
}

const RoleBasedRoute = ({
  allowedRoles,
  children,
}: IAppRoutesProps): JSX.Element => {
  const { user, authStatus, role } = useAuthenticatedUser();

  if (!user || authStatus === "configuring") {
    return <FullPageLoader />;
  }

  const userRole = ROLES.find((r): boolean => r === role) ?? "";

  const allowed = allowedRoles?.includes(userRole);

  return allowed ? <>{children}</> : <Navigate to="/unauthorized" replace />;
};

export default RoleBasedRoute;
