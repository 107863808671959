import { ApolloError, gql, useMutation } from "@apollo/client";

import {
  UserSettingsInput,
  PaginatedUsers,
  UpdateUserSettingsMutation,
  UpdateUserSettingsMutationVariables,
} from "../../API";
import { UPDATE_USER_SETTINGS } from "../operations/mutations";
import {
  errorNotification,
  successNotification,
} from "../variables/notification";

export const useUpdateUserSettings = () => {
  const [updateRole, { data, loading }] = useMutation<
    UpdateUserSettingsMutation,
    UpdateUserSettingsMutationVariables
  >(UPDATE_USER_SETTINGS);

  const updateUser = (userSettings?: UserSettingsInput | null) => {
    return updateRole({
      variables: {
        input: {
          userSettings,
        },
      },
      onCompleted: response => {
        if (response.updateUserSettings) {
          successNotification();
        }
      },
      onError: (error: ApolloError): void => {
        errorNotification(error.message);

        console.error(error.message);
      },
      update: (cache, response): void => {
        cache.modify({
          fields: {
            getUsersByCompany(existing: PaginatedUsers) {
              const newUserRef = cache.writeFragment({
                data: response.data?.updateUserSettings,
                fragment: gql`
                  fragment NewUser on User {
                    customer_id
                    email
                    firstName
                    id
                    lastName
                    user_role
                    userSettings
                  }
                `,
              });

              if (!existing) {
                return response?.data;
              }

              return {
                ...existing,
                items: existing.items.map(item => {
                  if (item?.id !== response.data?.updateUserSettings?.id) {
                    return item;
                  }

                  return newUserRef;
                }),
              };
            },
          },
        });
      },
    });
  };

  return { updateUser, data, loading };
};
