import { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Stack, Typography } from "@mui/material";
import { useConfirm } from "material-ui-confirm";

import BreadcrumbNavigation from "../../common/components/tabs/BreadcrumbNavigation";
import confirmDialogStyleOptions from "../../common/helpers/confirmDialogParams";
import useBeforeUnload from "../../common/hooks/useBeforeUnload";
import { useCustomerIdGuard } from "../../common/hooks/useCustomerIdGuard";
import useLocationFromCache from "../../common/hooks/useLocationFromCache";
// import useUrlChange from "../../common/hooks/useUrlChange";
import FullPageLoader from "../../common/components/item/FullPageLoader";
import SimplePaperWrapper from "../../common/components/item/SimplePaperWrapper";
import StyledLoadingButton from "../../common/providers/theme/design-tokens/LoadingButton/StyledLoadingButton";
import { successNotification } from "../../common/variables/notification";
import client from "../../configs/apolloClient";
import { useGetZonesByDevice } from "../data-hub/components/liveview/components/zonelist/hooks/useGetZonesByDevice";
import { useSyncShadow } from "../model-manager/hooks/useSyncShadow";
import DeviceDetailsForm from "./components/DeviceDetailsForm";
import useGetDeviceByUrl from "./hooks/useGetDeviceByUrl";
import { useUpdateDevice } from "./hooks/useUpdateDevice";
import {
  defaultDeviceFormValidationState,
  defaultDeviceFormValues,
  deviceFormRules,
  deviceFormValidationStateVariable,
  deviceFormVariables,
  useDeviceFormVariables,
} from "./variables/devices";

const EditDevicePage = (): JSX.Element => {
  const { state } = useLocation();
  const confirm = useConfirm();
  const navigate = useNavigate();
  const { syncShadowsForNode } = useSyncShadow();

  const form = useDeviceFormVariables();
  const selectedCustomerId = useCustomerIdGuard();

  const {
    data: deviceDatum,
    fetchDevice,
    loading: getDeviceLoading,
  } = useGetDeviceByUrl();

  const { updateDevice, loading } = useUpdateDevice();
  const { data: zonesData } = useGetZonesByDevice();

  const zones = zonesData?.getZonesByDevice.items ?? [];

  const { getCachedLocation } = useLocationFromCache();

  const useBackToDevicesPage = state?.useBackToDevicesPage ?? false;

  const isDirty = useRef(form.isDirty);

  useEffect((): void => {
    fetchDevice();
  }, []);

  useEffect((): void => {
    isDirty.current = form.isDirty;
  }, [form.isDirty]);

  const resetEditDeviceForm = (): void => {
    deviceFormVariables(defaultDeviceFormValues);

    deviceFormValidationStateVariable(defaultDeviceFormValidationState);
  };

  const isCancelConfirmed = useRef(false);

  const confirmCancel = (): void => {
    if (!isDirty.current) {
      resetEditDeviceForm();

      if (useBackToDevicesPage) {
        navigate(`/devices`);

        return;
      }

      navigate(
        `/device/${encodeURIComponent(deviceDatum?.getDeviceById?.id ?? "")}`
      );

      return;
    }

    confirm({
      title: "Are you sure you want to cancel Device editing?",
      confirmationText: "Cancel",
      cancellationText: "Back",
      ...confirmDialogStyleOptions,
      confirmationButtonProps: {
        ...confirmDialogStyleOptions.confirmationButtonProps,
        disabled: loading,
        color: "primary",
      },
    }).then((): void => {
      isCancelConfirmed.current = true;

      resetEditDeviceForm();

      if (useBackToDevicesPage) {
        navigate(`/devices`);

        return;
      }

      navigate(
        `/device/${encodeURIComponent(deviceDatum?.getDeviceById?.id ?? "")}`
      );
    });
  };

  // TODO: implement another way to handle url change
  // const confirmUrlChange = (path: string): void => {
  //   if (!isDirty.current) {
  //     resetEditDeviceForm();
  //     navigate(path);

  //     return;
  //   }

  //   if (isCancelConfirmed.current) {
  //     resetEditDeviceForm();
  //     navigate(path);

  //     return;
  //   }

  //   confirm({
  //     title: "If you leave, the information about the device won't be saved.",
  //     content: "Go back to save the changes.",
  //     confirmationText: "Leave",
  //     cancellationText: "Cancel",
  //     ...confirmDialogStyleOptions,
  //     confirmationButtonProps: {
  //       ...confirmDialogStyleOptions.confirmationButtonProps,
  //       color: "primary",
  //     },
  //   }).then((): void => {
  //     resetEditDeviceForm();
  //     navigate(path);
  //   });
  // };

  // useUrlChange(confirmUrlChange);
  useBeforeUnload();

  const saveDevice = async (): Promise<void> => {
    let validationState = {
      ...defaultDeviceFormValidationState,
    };

    if (deviceDatum?.getDeviceById?.name !== form.deviceName) {
      const deviceId = `${form.location?.value ?? ""}#DE#${form.deviceName}`;

      const normalizedId = client.cache.identify({
        id: deviceId,
        __typename: "Device",
      });

      const extract = client.cache.extract();

      if (normalizedId && extract[normalizedId]?.name === form.deviceName) {
        validationState = {
          ...validationState,
          deviceName: {
            hasError: true,
            errorMessage: "Device name already exists",
          },
        };
      }
    }

    if (!form.deviceName) {
      validationState = {
        ...validationState,
        deviceName: {
          hasError: true,
          errorMessage: "This field is required",
        },
      };
    }

    if (!form.node?.id) {
      validationState = {
        ...validationState,
        node: {
          hasError: true,
          errorMessage: "Node is required",
        },
      };
    }

    if (!form.deviceType) {
      validationState = {
        ...validationState,
        deviceType: {
          hasError: true,
          errorMessage: "Device Model is required",
        },
      };
    }

    if (!form.cameraIpAddress) {
      validationState = {
        ...validationState,
        cameraIpAddress: {
          hasError: true,
          errorMessage: "This field is required",
        },
      };
    }

    // Validate panTiltIP if panTilt is TRUE
    if (form.deviceData.hasPanTilt && !form.deviceData.panTiltIP) {
      validationState = {
        ...validationState,
        panTiltIP: {
          hasError: true,
          errorMessage: "Pan Tilt IP is required",
        },
      };
    } else {
      validationState = {
        ...validationState,
        panTiltIP: {
          hasError: false,
          errorMessage: "",
        },
      };
    }

    if (
      !form.deviceData.sourceVideo.match(deviceFormRules.sourceVideo.pattern)
    ) {
      validationState = {
        ...validationState,
        sourceVideo: {
          hasError: true,
          errorMessage: "Invalid Characters",
        },
      };
    }

    if (
      Object.values(validationState).some(
        (item): boolean => item?.hasError ?? false
      )
    ) {
      deviceFormValidationStateVariable(validationState);

      return;
    }

    const deviceDataInput = { ...form.deviceData };

    updateDevice({
      customerId: selectedCustomerId,
      locationName: deviceDatum?.getDeviceById?.locationName ?? "", // TODO: get location name from apollo cache
      deviceId: deviceDatum?.getDeviceById?.id ?? "",
      name: form.deviceName,
      nodeId: form.node?.id as string,
      nodeName: form.node?.name,
      makeModelId: form.deviceType,
      cameraIpAddress: form.cameraIpAddress,
      deviceData: JSON.stringify(deviceDataInput),
    })
      .then((response): void => {
        if (response?.data?.updateDevice) {
          successNotification("Device updated successfully");

          const locationCache = getCachedLocation(
            response.data?.updateDevice?.locationId
          );

          resetEditDeviceForm();

          if (useBackToDevicesPage) {
            navigate(`/devices`);

            return;
          }

          navigate(
            `/device/${encodeURIComponent(
              deviceDatum?.getDeviceById?.id ?? ""
            )}`,
            {
              state: {
                deviceDatum: {
                  ...deviceDatum,
                  rowId: response.data?.updateDevice.id,
                  name: response.data?.updateDevice?.name,
                  location: {
                    id: response.data?.updateDevice?.locationId,
                    name: locationCache?.name ?? "",
                  },
                  node: {
                    id: response.data?.updateDevice?.nodeId,
                    name: response.data?.updateDevice?.nodeName,
                  },
                  makeModelId: response.data?.updateDevice.makeModelId,
                  deviceData: response.data?.updateDevice.deviceData,
                  status: response.data?.updateDevice.status,
                  cameraIpAddress: response.data?.updateDevice.cameraIpAddress,
                },
              },
            }
          );
        }
      })
      .finally((): void => {
        syncShadowsForNode(form.node?.id as string); // assume that the nodeId for all the zones in the table are the same; grab nodeId from first zone
      });
  };

  if (getDeviceLoading || loading || !deviceDatum) {
    return <FullPageLoader />;
  }

  const deviceName = deviceDatum.getDeviceById?.name ?? "";

  const breadcrumbItems = [
    { label: "Devices", path: "/devices" },
    {
      label: deviceName,
      path:
        "/device/" + encodeURIComponent(deviceDatum?.getDeviceById?.id ?? ""),
    },
    { label: "Edit " + deviceName },
  ];

  return (
    <>
      <Stack spacing={0.5} direction="row" alignItems="center">
        <BreadcrumbNavigation items={breadcrumbItems} />
      </Stack>
      <Typography
        variant="h4"
        sx={{
          paddingTop: "1.5em",
          paddingBottom: "0.5em",
        }}
      >
        Edit {deviceDatum?.getDeviceById?.name}
      </Typography>
      <SimplePaperWrapper sx={{ padding: "2em" }}>
        <DeviceDetailsForm disablePtToggle={zones.length > 0} />
      </SimplePaperWrapper>
      <Stack direction="row-reverse" spacing={2} sx={{ paddingTop: 3 }}>
        <StyledLoadingButton
          sx={{
            marginTop: "1.5em",
          }}
          loading={loading}
          loadingPosition="start"
          variant="contained"
          color="primary"
          onClick={saveDevice}
        >
          Save changes
        </StyledLoadingButton>
        <StyledLoadingButton
          sx={{
            marginTop: "1.5em",
          }}
          loading={loading}
          loadingPosition="start"
          variant="outlined"
          color="inherit"
          onClick={confirmCancel}
        >
          Cancel
        </StyledLoadingButton>
      </Stack>
    </>
  );
};

export default EditDevicePage;
