import { ChangeEvent, FC, useEffect, useState } from "react";

import { styled } from "@mui/material/styles";
import Switch, { SwitchProps } from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";

import { Category } from "../../../../../../API";
import { useCustomerIdGuard } from "../../../../../../common/hooks/useCustomerIdGuard";
import {
  errorNotification,
  successNotification,
} from "../../../../../../common/variables/notification";
import { useUpdateCategory } from "../../hooks/useUpdateCategory";

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

interface ICategoryAlertingSwitchProps {
  category: Category;
}

const CategoryAlertingSwitch: FC<ICategoryAlertingSwitchProps> = ({
  category,
}) => {
  const selectedCustomerId = useCustomerIdGuard();

  const { updateCategory } = useUpdateCategory();

  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (category && category.isAlertEnabled !== checked) {
      setChecked(category.isAlertEnabled);
    }
  }, [category?.isAlertEnabled]);

  const handleSwitchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;

    setChecked(isChecked);

    updateCategory({
      categoryId: category?.id ?? "",
      categoryName: category?.categoryName,
      customerId: selectedCustomerId,
      isAlertEnabled: isChecked,
    })
      .then((response): void => {
        if (!response.errors) {
          successNotification("Changes are saved");
        }
      })
      .catch((error: Error): void => {
        errorNotification();

        console.error(error);
      });
  };

  return (
    <Tooltip
      TransitionComponent={Zoom}
      title={
        checked
          ? "Stop receiving alerts for this category"
          : "Start receiving alerts for this category"
      }
    >
      <div>
        <IOSSwitch
          sx={{ m: 0.5 }}
          checked={checked}
          onChange={handleSwitchChange}
        />
      </div>
    </Tooltip>
  );
};

export default CategoryAlertingSwitch;
