import { useMemo } from "react";
import { useQuery } from "@apollo/client";

import type { GetCustomerQuery, GetCustomerQueryVariables } from "../../API";
import { GET_CUSTOMER } from "../operations/queries";
import { useCustomerIdGuard } from "./useCustomerIdGuard";
import { useAuthenticatedUser } from "./useAuthenticatedUser";
import { RoleEnum } from "../models/enums";
import { useGetCustomers } from "./useGetCustomers";

export const useGetCustomer = () => {
  const customerId = useCustomerIdGuard();

  const { customerId: auditorCustomerId, role } = useAuthenticatedUser();

  const customerIdValue =
    role === RoleEnum.VERIFIER ? auditorCustomerId : customerId; // TODO:customerIdValue should be id of external auth db. Maybe we don't need this GET_CUSTOMER query if we already have cashed customers

  const { customers, loading: getCustomersLoading } = useGetCustomers();

  const foundCustomerExternalId = customers?.items?.find(
    c => c?.id === customerIdValue
  );

  const customerExternalId = foundCustomerExternalId?.externalId ?? "";

  const {
    data,
    loading: getCustomerLoading,
    refetch,
  } = useQuery<GetCustomerQuery, GetCustomerQueryVariables>(GET_CUSTOMER, {
    skip: !customerExternalId,
    variables: {
      customerExternalId,
    },
    fetchPolicy: "no-cache",
    onError: error => {
      console.error("GetCustomer", error);
    },
  });

  const customer = useMemo(
    () => data?.getCustomer,
    [data?.getCustomer, customers]
  );

  return {
    customer,
    loading: getCustomerLoading || getCustomersLoading,
    refetch,
  } as const;
};
