import { useTheme } from "@mui/material";

const GasLeakDataHubIcon = (): JSX.Element => {
  const theme = useTheme();

  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="40"
        height="40"
        rx="8"
        fill={theme.palette.otherBackground.main}
      />
      <path
        d="M11 16H17.5C18.8807 16 20 14.8807 20 13.5C20 12.1193 18.8807 11 17.5 11C16.1193 11 15 12.1193 15 13.5V13.8571"
        stroke={theme.palette.text.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M12 22H26.5C28.433 22 30 23.567 30 25.5C30 27.433 28.433 29 26.5 29C24.567 29 23 27.433 23 25.5V25"
        stroke={theme.palette.text.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M10 19H26.5C28.433 19 30 17.433 30 15.5C30 13.567 28.433 12 26.5 12C24.567 12 23 13.567 23 15.5V16"
        stroke={theme.palette.text.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default GasLeakDataHubIcon;
