import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";

interface DeviceHasPanTiltCheckboxInputProps {
  label: string;
  field: string;
  hasPanTilt: boolean;
  setPanTilt: (key: string, value: boolean) => void;
}

const DeviceHasPanTiltCheckboxInput = ({
  label,
  field,
  hasPanTilt,
  setPanTilt,
}: DeviceHasPanTiltCheckboxInputProps): JSX.Element | null => {
  const handleEntryChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ): void => {
    setPanTilt(field, checked);
  };

  return (
    <FormGroup>
      <FormControlLabel
        sx={{
          cursor: "pointer",
        }}
        control={
          <Checkbox
            checked={hasPanTilt}
            onChange={handleEntryChange}
            name="panTilt"
          />
        }
        label={label}
      />
    </FormGroup>
  );
};

export default DeviceHasPanTiltCheckboxInput;
