import { ChangeEvent, FC, useEffect, useState, useMemo } from "react";

import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";

import MakeSelect from "../../../common/components/layout/components/MakeSelect";
import useLocationFromCache from "../../../common/hooks/useLocationFromCache";
import {
  defaultDeviceFormValidationState,
  deviceFormRules,
  deviceFormValidationStateVariable,
  deviceFormVariables,
  useDeviceFormValidationState,
  useDeviceFormVariables,
} from "../variables/devices";
import DeviceDynamicRadioButtonInput from "./DynamicInputs/DeviceDynamicRadioButtonInput";
import DeviceDynamicTextInput from "./DynamicInputs/DeviceDynamicTextInput";
import { Stack, Switch, Typography } from "@mui/material";

interface IDeviceDetailsFormProps {
  disablePtToggle?: boolean;
}

const DeviceDetailsForm: FC<IDeviceDetailsFormProps> = ({
  disablePtToggle,
}) => {
  const deviceForm = useDeviceFormVariables();
  const validation = useDeviceFormValidationState();
  const { getCachedLocation } = useLocationFromCache();
  const location = getCachedLocation(deviceForm.location?.value ?? "");
  const [forceTCP, setForceTCP] = useState(
    deviceForm.deviceData.protocol === "tcp"
  );

  const [hasRecordVideo, setHasRecordVideo] = useState(
    deviceForm.deviceData.hasRecordVideo
  );

  const setDeviceName = (event: ChangeEvent<HTMLInputElement>): void => {
    deviceFormValidationStateVariable({
      ...validation,
      deviceName: { ...defaultDeviceFormValidationState.deviceName },
    });

    const deviceName = event.target.value;

    deviceFormVariables({
      ...deviceForm,
      isDirty: true,
      deviceName,
    });

    let hasError = !deviceName.match(deviceFormRules.deviceName.pattern);
    let errorMessage = "";

    if (hasError) {
      errorMessage = "Valid characters: A-Z, a-z, 0-9, -";
    }

    // NOTE: This rule is handled on submit
    if (hasError && !deviceName) {
      hasError = false;

      errorMessage = "";
    }

    deviceFormValidationStateVariable({
      ...validation,
      deviceName: {
        hasError,
        errorMessage,
      },
    });
  };

  const setDeviceType = (value: string): void => {
    deviceFormVariables({
      ...deviceForm,
      isDirty: true,
      deviceType: value,
    });
  };

  const setDeviceConfig = (name: string, value: any): void => {
    deviceFormVariables({
      ...deviceForm,
      isDirty: true,
      deviceData: {
        ...deviceForm.deviceData,
        [name]: value,
      },
    });
  };

  const setCameraIpAddress = (event: ChangeEvent<HTMLInputElement>): void => {
    const cameraIpAddress = event.target.value.replaceAll(" ", "");

    const rtspTemplate = deviceForm.rtspTemplate;
    let sourceVideoValue = deviceForm.deviceData?.sourceVideo;

    if (deviceForm.defaultUserPwd) {
      sourceVideoValue = `rtsp://${deviceForm.defaultUserPwd}@${cameraIpAddress}${rtspTemplate}`;
    } else {
      sourceVideoValue = `rtsp://${cameraIpAddress}${rtspTemplate}`;
    }

    deviceFormVariables({
      ...deviceForm,
      isDirty: true,
      cameraIpAddress,
      deviceData: {
        ...deviceForm.deviceData,
        sourceVideo: sourceVideoValue,
      },
    });

    deviceFormValidationStateVariable({
      ...validation,
      cameraIpAddress: {
        hasError: false,
        errorMessage: "",
      },
    });
  };

  const handleForceTcpChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;

    setForceTCP(isChecked);

    setDeviceConfig("protocol", isChecked ? "tcp" : "udp");
  };

  const handleEnableRecordingChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.target.checked;

    setHasRecordVideo(isChecked);

    setDeviceConfig("hasRecordVideo", isChecked);
  };

  const deviceDataInput = useMemo(() => {
    return { ...deviceForm.deviceData };
  }, [deviceForm.deviceData]);

  const showPanTilt = useMemo(
    () => deviceForm.deviceMakePanTiltValue,
    [deviceForm.deviceMakePanTiltValue]
  );

  const showPanTiltIp = useMemo(
    () => deviceDataInput.hasPanTilt,
    [deviceDataInput.hasPanTilt]
  );

  useEffect(() => {
    setForceTCP(deviceForm.deviceData.protocol === "tcp");
  }, [deviceForm.deviceData.protocol]);

  useEffect(() => {
    setHasRecordVideo(deviceForm.deviceData.hasRecordVideo);
  }, [deviceForm.deviceData.hasRecordVideo]);

  return (
    <>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{
          "& .MuiFormControl-root": {
            marginTop: 0,
            marginBottom: "1em",
          },
        }}
      >
        <Grid item xs={6}>
          <TextField
            margin="dense"
            error={validation?.deviceName.hasError && !deviceForm.deviceName}
            fullWidth
            disabled
            label="Device ID"
            value={deviceForm.deviceName}
            onChange={setDeviceName}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            margin="dense"
            fullWidth
            disabled
            label="Location"
            value={location?.name ?? ""}
          />
        </Grid>
        <Grid item xs={6}>
          <MakeSelect
            disabled
            label="Device model"
            hasError={validation.deviceType.hasError}
            makeId={deviceForm.deviceType}
            onMakeChange={setDeviceType}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            margin="dense"
            fullWidth
            disabled
            label="Node"
            value={deviceForm.node?.name ?? ""}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            margin="dense"
            error={
              validation?.cameraIpAddress.hasError &&
              !deviceForm.cameraIpAddress
            }
            helperText={
              validation?.cameraIpAddress.hasError &&
              validation?.cameraIpAddress.errorMessage
            }
            fullWidth
            label="Camera IP Address"
            value={deviceForm.cameraIpAddress ?? ""}
            onChange={setCameraIpAddress}
          />
        </Grid>
        <Grid item>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography>Force TCP</Typography>
            <Switch checked={forceTCP} onChange={handleForceTcpChange} />
          </Stack>
        </Grid>
        <Grid item>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography>Enable Recording</Typography>
            <Switch
              checked={hasRecordVideo}
              onChange={handleEnableRecordingChange}
            />
          </Stack>
        </Grid>
      </Grid>
      <br />
      <Divider />
      <br />
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
          <DeviceDynamicTextInput
            key={"sourceVideo"}
            hasError={validation.sourceVideo.hasError}
            helperText={validation.sourceVideo.errorMessage}
            field={"sourceVideo"}
            label={"RTSP Source (Optional)"}
            deviceConfig={deviceForm.deviceData}
            setDeviceConfig={setDeviceConfig}
          />
        </Grid>
        <Grid item xs={6}>
          <DeviceDynamicTextInput
            key={"rtspHost"}
            hasError={false}
            field={"rtspHost"}
            label={"RTSP Client (Optional)"}
            deviceConfig={deviceForm.deviceData}
            setDeviceConfig={setDeviceConfig}
            disableInput
          />
        </Grid>
        {showPanTilt ? (
          <Grid item xs={6}>
            <DeviceDynamicRadioButtonInput
              key={"hasPanTilt"}
              field={"hasPanTilt"}
              label={"Enable Pan Tilt (Optional)"}
              entries={[
                { value: true, display: "Yes" },
                {
                  value: false,
                  display: (
                    <Tooltip
                      TransitionComponent={Zoom}
                      title={
                        disablePtToggle
                          ? "Device has assigned zones. Please remove them first"
                          : null
                      }
                    >
                      <Box
                        component="span"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "0.5em",
                        }}
                      >
                        <span>No</span>
                        {disablePtToggle && (
                          <HelpOutlineIcon fontSize="small" />
                        )}
                      </Box>
                    </Tooltip>
                  ),
                },
              ]}
              deviceConfig={deviceForm.deviceData}
              setDeviceConfig={setDeviceConfig}
              disabled={disablePtToggle}
            />
          </Grid>
        ) : (
          ""
        )}
        {showPanTiltIp ? (
          <Grid item xs={6}>
            <DeviceDynamicTextInput
              key={"panTiltIP"}
              hasError={validation.panTiltIP.hasError}
              helperText={validation.panTiltIP.errorMessage}
              field={"panTiltIP"}
              label={"Pan Tilt IP"}
              deviceConfig={deviceForm.deviceData}
              setDeviceConfig={setDeviceConfig}
            />
          </Grid>
        ) : (
          ""
        )}
      </Grid>
    </>
  );
};

export default DeviceDetailsForm;
