import { useEffect } from "react";

import { useLazyQuery } from "@apollo/client";

import { useCustomerIdGuard } from "../../../common/hooks/useCustomerIdGuard";
import { GET_DEVICES } from "../../../common/operations/queries";
import { DEFAULT_LIMIT_COUNT } from "../../../common/variables/common";
import { errorNotification } from "../../../common/variables/notification";
import { useSelectedLocationDropdown } from "../../../common/variables/selectedLocationDropdown";
import client from "../../../configs/apolloClient";
import {
  devicesLoadingVariable,
  useDevicesTableLoading,
} from "../variables/devices";
import type { GetDevicesQuery, GetDevicesQueryVariables } from "./../../../API";

export interface useGetDevicesInterface {
  locationId?: string;
  locationName?: string;
  nodeId?: string;
  nodeName?: string;
}

export const DEVICES_TABLE_LIMIT = 10;

export const useGetDevices = () => {
  const loading = useDevicesTableLoading();

  const locationVariable = useSelectedLocationDropdown();

  const locationId = locationVariable?.value;

  const selectedCustomerId = useCustomerIdGuard();

  useEffect(() => {
    if (selectedCustomerId) {
      devicesLoadingVariable(true);

      fetchDevices(selectedCustomerId, locationId);
    } else {
      devicesLoadingVariable(false);
    }
  }, [selectedCustomerId, locationId]);

  useEffect(() => {
    return () => {
      client.cache.evict({
        id: "ROOT_QUERY",
        fieldName: "getDevices",
        broadcast: false,
      });
    };
  }, []);

  const handleRequestError = () => {
    client.cache
      .reset()
      .then(() => {
        errorNotification();
      })
      .catch(error => {
        console.error(error);
      });
  };

  const [getDevices, { data, refetch }] = useLazyQuery<
    GetDevicesQuery,
    GetDevicesQueryVariables
  >(GET_DEVICES, {
    fetchPolicy: "network-only",
  });

  const fetchDevices = (
    customerId: string,
    locationId?: string | null,
    nextToken?: string
  ) => {
    getDevices({
      variables: {
        limit: DEFAULT_LIMIT_COUNT,
        customerId,
        locationId,
        nextToken,
      },
    })
      .then(response => {
        if (response.data?.getDevices.nextToken) {
          fetchDevices(
            customerId,
            locationId,
            response.data?.getDevices.nextToken
          );
        } else {
          devicesLoadingVariable(false);
        }

        if (response.error) {
          handleRequestError();

          devicesLoadingVariable(false);
        }
      })
      .catch(e => {
        console.error(e);

        handleRequestError();

        devicesLoadingVariable(false);
      });
  };

  return { data, loading, refetch };
};
