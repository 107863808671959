import EventsTableServer from "../common/EventsTableServer";
import { useGetSmokeEventsTableColumns } from "./hooks/useGetSmokeEventsTableColumns";
import { useGetSmokeEventsTableRows } from "./hooks/useGetSmokeEventsTableRows";

const SmokeEventsServerTableContainer = (): JSX.Element => {
  const { columns } = useGetSmokeEventsTableColumns();

  const {
    rows,
    loading,
    paginationModel,
    handlePaginationModelChange,
    hasNextPage,
    isActivePageChange,
  } = useGetSmokeEventsTableRows();

  return (
    <EventsTableServer
      columns={columns}
      rows={rows}
      loading={loading}
      onPaginationModelChange={handlePaginationModelChange}
      hasNextPage={hasNextPage}
      isActivePageChange={isActivePageChange}
      paginationModel={paginationModel}
    />
  );
};

export default SmokeEventsServerTableContainer;
