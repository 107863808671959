import { useEffect, useRef } from "react";
import moment from "moment";

import { Reference, gql, useReactiveVar } from "@apollo/client";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  Box,
  Card,
  CardHeader,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";

import TransitionUp from "../../../common/components/TransitionUp";
import ValidationForm from "./ValidationForm";

import Thumbnail from "../../../common/components/media/Thumbnail";
import {
  errorNotification,
  successNotification,
} from "../../../common/variables/notification";
import { useSetPendingState } from "../hooks/useSetPendingState";
import { humanValidatorTableVariable } from "../variables/humanValidatorTable";
import { ModelType } from "../../../API";

const HumanValidatorTableItemDetailsContent = (): JSX.Element => {
  const { showDetails, tableData } = useReactiveVar(
    humanValidatorTableVariable
  );

  let modelName = tableData?.model;

  if (tableData?.model && tableData?.model === ModelType.gasleak) {
    modelName = "leak";
  }

  const { setToPending } = useSetPendingState();

  const showDetailsRef = useRef(showDetails);

  const refreshTimeoutIdRef = useRef<number | null>(null);

  const refreshPage = () => {
    const timeoutId = window.setInterval(() => {
      if (!showDetailsRef.current) window.location.reload();
    }, 120000); // 120000 milliseconds = 2 minutes

    // Update the ref with the new timeout ID
    refreshTimeoutIdRef.current = timeoutId;
  };

  useEffect(() => {
    // Call refreshPage to start the refresh loop
    refreshPage();

    return () => {
      // Clear the timeout when the component unmounts to prevent memory leaks
      if (refreshTimeoutIdRef.current) {
        clearTimeout(refreshTimeoutIdRef.current);
      }
    };
  }, []);

  useEffect(() => {
    showDetailsRef.current = showDetails;
  }, [showDetails]);

  const handleClose = (): void => {
    humanValidatorTableVariable({
      showDetails: false,
      tableData: null,
    });
  };

  const handleSetToPending = (): void => {
    setToPending({
      variables: {
        customerId: tableData?.customerId,
        eventId: tableData?.eventId,
      },
      update: (cache, { data }): void => {
        cache.modify({
          fields: {
            // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
            getEventsPendingHumanValidation(existing) {
              const newEventRef = cache.writeFragment({
                data: data?.setPendingState,
                fragment: gql`
                  fragment NewHumanValidationEvent on HumanValidationEvent {
                    audited_by
                    auditorsExplanation
                    customerId
                    data
                    eventTime
                    explanation
                    id
                    locationId
                    nodeId
                    serviceId
                    transitionInfo
                    validated_by
                    validationState
                  }
                `,
              });

              if (!existing) {
                return data;
              }

              return {
                ...existing,
                items: existing.items.map(
                  (item: Reference): Reference | undefined => {
                    const normalizedId = cache.identify({
                      id: data?.setPendingState?.id,
                      __typename: "HumanValidationEvent",
                    });

                    if (item?.__ref !== normalizedId) {
                      return item;
                    }

                    return newEventRef;
                  }
                ),
              };
            },
          },
        });
      },
    })
      .then((): void => {
        successNotification("The event was returned to the Pending state");
      })
      .catch((error): void => {
        console.error("handleSetToPending error", error);

        errorNotification();
      })
      .finally((): void => {
        humanValidatorTableVariable({
          showDetails: false,
          tableData: null,
        });
      });
  };

  return (
    <Dialog
      fullScreen
      open={showDetails}
      onClose={handleClose}
      TransitionComponent={TransitionUp}
    >
      <DialogTitle>
        <CardHeader
          sx={{ padding: 0 }}
          action={
            <IconButton onClick={handleSetToPending}>
              <CloseRoundedIcon />
            </IconButton>
          }
          title="Details"
        />
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12} md={9}>
            <Thumbnail mediaOutput={tableData?.mediaOutput ?? ""} controls />
          </Grid>
          <Grid item xs={12} md={3}>
            <Box sx={{ overflowY: "auto" }}>
              <Card
                variant="outlined"
                sx={{
                  wordBreak: "break-all",
                  padding: "0.75em",
                  margin: "1em 0",
                  backgroundColor: "#ececec",
                }}
              >
                <Typography variant="body1">Model: {modelName}</Typography>
                <Typography variant="body1">
                  Date:{" "}
                  {moment
                    .utc(tableData?.date)
                    .local()
                    .format("YYYY-MM-DD HH:mm")}
                </Typography>
              </Card>

              <ValidationForm />
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default HumanValidatorTableItemDetailsContent;
