import EventsTableServer from "../common/EventsTableServer";
import { useGetLiquidLeakEventsTableColumns } from "./hooks/useGetLiquidLeakEventsTableColumns";
import { useGetLiquidLeakEventsTableRows } from "./hooks/useGetLiquidLeakEventsTableRows";

const LiquidLeakServerTableContainer = (): JSX.Element => {
  const { columns } = useGetLiquidLeakEventsTableColumns();

  const {
    rows,
    loading,
    paginationModel,
    handlePaginationModelChange,
    hasNextPage,
    isActivePageChange,
  } = useGetLiquidLeakEventsTableRows();

  return (
    <EventsTableServer
      columns={columns}
      rows={rows}
      loading={loading}
      onPaginationModelChange={handlePaginationModelChange}
      hasNextPage={hasNextPage}
      isActivePageChange={isActivePageChange}
      paginationModel={paginationModel}
    />
  );
};

export default LiquidLeakServerTableContainer;
