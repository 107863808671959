import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { NetworkStatus, useQuery } from "@apollo/client";
import { GridPaginationModel } from "@mui/x-data-grid";
import dayjs from "dayjs";

import {
  GetTimestreamDataQuery,
  GetTimestreamDataQueryVariables,
} from "../../../../../../API";
import { useDateRange } from "../../../../../../common/components/datePicker/modelsDatePickers";
import { useCustomerIdGuard } from "../../../../../../common/hooks/useCustomerIdGuard";
import { GET_TIMESTREAM_DATA } from "../../../../../../common/operations/queries";
import { convertDateToUTC } from "../../../../../../common/utils/timestampUtils";
import { parseQueryResult } from "../../../../../../common/utils/timestreamHelper";
import { DEFAULT_EVENTS_LIMIT, EVENTS_DATE_FORMAT } from "../events.constants";
import { LoadMoreType } from "../events.models";
import { useEventsFilters } from "./useEventsFilters";

export const useGetTimestreamDataServer = (pageSize?: number) => {
  const selectedCustomerId = useCustomerIdGuard();
  const { model } = useParams();
  const dates = useDateRange();

  const startDate = dates?.from
    ? dayjs(convertDateToUTC(dates.from)).format(EVENTS_DATE_FORMAT)
    : null;
  const endDate = dates?.to
    ? dayjs(convertDateToUTC(dates.to)).format(EVENTS_DATE_FORMAT)
    : null;

  const {
    categoryVariable,
    companyVariable,
    locationVariable,
    tagLocationsVariable,
    licensePlateVariable,
  } = useEventsFilters();

  const locationId = locationVariable?.value ?? "";
  const companyName = companyVariable?.value ?? "";
  const categoryName = categoryVariable?.title;
  const licensePlateValue = licensePlateVariable?.value ?? "";

  let locations: Array<string> | null = [];

  if (locationId) {
    locations = [locationId ?? ""];
  } else if (tagLocationsVariable) {
    locations = tagLocationsVariable
      .map(l => l?.value ?? "")
      .filter(f => f !== "");

    if (locations.length === 0) {
      locations = null;
    }
  }

  const { data, loading, fetchMore, networkStatus } = useQuery<
    GetTimestreamDataQuery,
    GetTimestreamDataQueryVariables
  >(GET_TIMESTREAM_DATA, {
    skip: !selectedCustomerId,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {
      input: {
        customerId: selectedCustomerId,
        model: model ?? "",
        dateRange: JSON.stringify({ startDate, endDate }),
        locations,
        categoryName,
        companyName,
        licensePlate: licensePlateValue,
        offset: 0,
        limit: pageSize ?? DEFAULT_EVENTS_LIMIT,
        nextToken: null,
      },
    },
  });

  const loadMore: LoadMoreType = (
    offset: number,
    limit: number,
    nextToken?: string | null
  ) => {
    fetchMore({
      variables: {
        input: {
          customerId: selectedCustomerId,
          model: model ?? "",
          dateRange: JSON.stringify({ startDate, endDate }),
          locations,
          categoryName,
          companyName,
          licensePlate: licensePlateValue,
          offset,
          limit,
          nextToken,
        },
      },
      // Add the updateQuery option
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        return { getTimestreamData: fetchMoreResult.getTimestreamData };
      },
    });
  };

  const parsedQueryResult = useMemo(
    () =>
      data?.getTimestreamData
        ? parseQueryResult(JSON.parse(data.getTimestreamData as string))
        : [],
    [data]
  );

  const flattenData = (oldArr: any) => {
    if (!oldArr) return null;

    if (!oldArr.Rows) return [];

    return {
      nextToken: oldArr?.NextToken,
    };
  };

  const formattedData: any | null = useMemo(
    () =>
      data?.getTimestreamData
        ? flattenData(JSON.parse(data.getTimestreamData as string))
        : [],
    [data]
  );

  /** Pagination model and Filters */
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: pageSize ?? DEFAULT_EVENTS_LIMIT,
  });

  const [filters, setFilters] = useState({
    startDate,
    endDate,
    locations,
    companyName,
    categoryName,
    licensePlateValue,
  });

  const handlePaginationModelChange = (model: GridPaginationModel) => {
    const page = model.pageSize !== paginationModel.pageSize ? 0 : model.page;

    setPaginationModel(prev => ({
      ...prev,
      page,
      pageSize: model.pageSize,
    }));

    if (loadMore) {
      const offset = page * model.pageSize;

      loadMore(offset, model.pageSize, formattedData?.nextToken);
    }
  };

  useEffect(() => {
    const locationsFilterChanged =
      filters.locations?.toString() !== locations?.toString();

    if (
      startDate !== filters.startDate ||
      endDate !== filters.endDate ||
      locationsFilterChanged ||
      companyName !== filters.companyName ||
      categoryName !== filters.categoryName ||
      licensePlateValue !== filters.licensePlateValue
    ) {
      setPaginationModel({
        page: 0,
        pageSize: pageSize ?? DEFAULT_EVENTS_LIMIT,
      });

      setFilters(prevState => {
        return {
          ...prevState,
          startDate,
          endDate,
          locations,
          companyName,
          categoryName,
          licensePlateValue,
        };
      });
    }
  }, [
    startDate,
    endDate,
    locationId,
    companyName,
    categoryName,
    licensePlateValue,
  ]);

  const hasNextPage = useMemo(() => {
    return formattedData?.nextToken !== undefined;
  }, [formattedData?.nextToken]);

  const isActivePageChange = useMemo(() => {
    return hasNextPage && !loading;
  }, [hasNextPage, loading]);

  return {
    parsedResult: parsedQueryResult,
    nextToken: formattedData?.nextToken,
    loading: loading || networkStatus === NetworkStatus.refetch,
    loadMore,

    paginationModel,
    handlePaginationModelChange,
    hasNextPage,
    isActivePageChange,
  } as const;
};
