import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import { Control, Controller, FieldErrors } from "react-hook-form";
import ServiceTypeSelect from "../../../../../../../../common/components/select/ServiceTypeSelect";
import { IAlertForm } from "../CreateAlertContainer";

interface IModelAlertFormProps {
  control: Control<IAlertForm>;
  errors: FieldErrors<IAlertForm>;
  onModelChange: (value: string | null) => void;
}

const ModelAlertForm = ({
  control,
  errors,
  onModelChange,
}: IModelAlertFormProps) => {
  return (
    <Controller
      name="model"
      control={control}
      rules={{
        required: "This field is required",
      }}
      render={({ field }) => (
        <FormControl
          error={!!errors.model}
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <ServiceTypeSelect
            {...field}
            value={field.value}
            onChange={(_event, value) => onModelChange(value)}
            hasError={!!errors.model}
          />
          {errors.model && (
            <FormHelperText error>{errors.model.message}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
};

export default ModelAlertForm;
